import Law from "../Law/Law";

const TermsOfService = () => {
  return (
    <div>
      <Law title={title} text={tos} />
    </div>
  );
};

export default TermsOfService;

const title = "利用規約";

const tos = `本利用規約（以下「本規約」といいます。）は、本サービスの提供条件、および、利用者（以下「ユーザー」といいます。）と言語研究開発合同会社（以下「当社」といいます。）との間の法律関係を定めています。本サービスのご利用に際しては、予め本規約を全てお読みいただいたうえで、本規約に同意いただく必要があります。
なお、本規約は、その制定時において、未実装（今後実装を検討する予定）の機能についても規定をしておりますのでご了承ください。

第１条 （本サービスの目的）
本サービスは、当社がインターネット回線を通じて提供するスマートアプリ、ウェブサイトその他の情報コンテンツを通じた語学学習サービス提供を円滑に実施し、登録ユーザーの皆様により充実した語学学習をいただくことを目的としています。

第２条 （本規約の適用範囲）
１     本規約は、本サービスの提供条件、および、ユーザーと当社の間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
２     当社が本サービスにかかる個別アプリに掲載する本サービスの利用に関するルールその他の規律は、本規約の一部を構成するものとし、それらが本規約の内容と矛盾抵触する場合には、個別アプリの表示が本規約に優先して適用されるものとします。

第３条 （定義）
　本規約に関する用語は次のとおり定義します。
（1） 「本サービス」とは、「RiceSpeak（ライスピーク）」を含む、当社が提供する全てのオンラインサービスを意味します。スマートフォンアプリのプラットフォームに限らず、Ｗｅｂブラウザ上で提供するサービスも含みます。
（2） 「個別アプリ」とは、本規約に基づいて当社が提供する個々のアプリを意味します。
（3） 「登録ユーザー」とは、本サービスの利用者を意味し、次条に定める手続によって本サービスの利用者登録がなされた個人を意味します。
（4） 「サービス利用契約」とは、本規約および当社とユーザーの間で締結する、本サービスの利用契約を意味します。
（5） 「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
（6） 「投稿コンテンツ」とは、登録ユーザーが個別アプリを通じて第三者に送信し、または公開したコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
（7） 「スマートフォン」とは、タブレット端末、パソコンその他を含む電気通信による情報の送受信が可能な端末を意味します。 

第４条 （登録）
１     本サービスの会員専用機能の利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報、必要書類（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し本サービスの利用の登録を申請することができます。
２     当社は、前項に基づいて登録申請を行った者（以下「登録申請者」といいます。）の登録を自動的に行い、登録が完了すると、ユーザーIDが付与されるとともに、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本規約に従って登録ユーザーとして本サービスを利用することができるようになります。
３     登録ユーザーが以下の各号のいずれかの事由に該当する場合、当社は、いつでも、その利用を停止し、登録を抹消し、および再登録を拒否することができるものとします。また、その理由について当社は当該登録ユーザーへ開示する義務を負いません。
（1） 当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
（2） 成年被後見人、被保佐人または被補助人のいずれかであり、後見人、保佐人または補助人の同意等を得ていなかった場合
（3） 反社会的勢力等（暴力団、暴力団員その他の反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
（4） 登録ユーザーが過去当社との契約に違反した者またはその関係者であると当社が判断した場合
（5） 第９条に定める利用条件（禁止事項）に違反した場合
（6） 第１０条に定める措置を受けたことがある場合
（7） 不当・不正の目的でサービスを利用するおそれがあるとき，その他登録ユーザーにサービスの提供が適当でないと当社が判断した場合

第５条 （登録事項の変更）
登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知しなければなりません。

第６条 （パスワードおよび登録ユーザーIDの管理）
１     登録ユーザーは、自己の責任において、本サービスに関するパスワードおよび登録ユーザーIDを適切に管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはなりません。
２     パスワードまたは登録ユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。

第７条 （利用料金）
本サービスの利用料金は個別アプリごとに定め、特定商取引に関する法律に基づく表示その他の方法によりわかりやすく明確に表示します。

第８条 （アプリ内ポイント）
１     個別アプリにおいて、当該アプリ内の決済手段としてのポイントその他のシステム（以下「ポイント等」といいます。）を実装している場合、登録ユーザーはポイント等を、当社から有償で購入できます。また、当社はその裁量により登録ユーザーへ無償でポイント等を付与できます。
２     登録ユーザーは、対価の有無に関わらず、当社以外の第三者からポイント等を譲り受けたり、第三者へポイント等を譲渡したりすることはできません。
３     登録ユーザーは、法に定められた場合を除き、購入したポイント等の払い戻しを受けられないこと、第４条第３項および第１０条により登録ユーザーのアカウントが停止・削除された場合等に、払い戻しを受けられないことについて、それぞれ同意します。

第９条 （禁止事項および投稿コンテンツの削除）
１     登録ユーザーは、本サービスの利用にあたり、以下の各号に該当する行為または該当すると当社が判断する行為をしてはなりません。
（1） 法令に違反する行為または犯罪行為に関連する行為
（2） 当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
（3） 公序良俗に反する行為
（4） 同一人による複数IDの取得行為
（5） IDないし本サービスを利用しうる地位の譲渡または譲受の申出、譲渡または譲受行為（金銭等の授受を問わない）
（6） 当社の許諾を受けない営業行為（人材募集，広告およびステルスマーケティングを含む）
（7） 本サービスに掲載された投稿コンテンツの対象となった第三者の業務を妨害し、人格を毀損する行為。
（8） 当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、信用その他の権利または利益を侵害する行為
（9） 本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の登録ユーザーに送信すること
ア      わいせつ、暴力的または残虐な表現を含む情報
イ      コンピュータウィルスその他の有害なプログラムを含む情報
ウ      当社、本サービスの他の登録ユーザーまたはその他の第三者の名誉または信用を毀損する表現を含む情報
エ      アフィリエイトリンクを含む情報
オ      差別を助長する表現を含む情報
カ      自殺、自傷行為を助長する表現を含む情報
キ      薬物の不適切な利用を助長する表現を含む情報
ク      反社会的な表現を含む情報
ケ      真実または反真実を問わず、チェーンメール、フェイクニュース等の第三者への情報の拡散を求める情報
コ      他人に不快感を与える表現を含む情報
サ      面識のない異性との出会い、勧誘を目的とした情報
シ      本サービスのネットワークまたはシステム等に過度な負荷をかける行為
ス      本サービスの運営を妨害するおそれのある行為
セ      当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為
ソ      実在する第三者または実在しない第三者に成りすます行為
タ      本サービスの他の利用者のIDまたはパスワードを利用する行為
チ      当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
ツ      本サービスの他の利用者の情報の収集
テ      当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
ト      反社会的勢力等への利益供与
ナ      前各号の行為を直接または間接に惹起し、または容易にする行為
ニ      その他、当社が不適切と判断する行為
２     登録ユーザーが前項各号に該当する行為をおこなった場合、当社は当該行為にかかる送信情報を削除することができ、次条に基づいて当該登録ユーザーについて本サービスの利用停止、登録抹消、もしくはサービス利用契約を解除することができます。 

第１０条 （利用停止および登録抹消）
１     当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿コンテンツを削除しもしくは当該ユーザーについて本サービスの利用停止、登録抹消、もしくはサービス利用契約を解除することができます。
（1） 本規約のいずれかの条項に違反した場合
（2） 登録事項に虚偽の事実があることが判明した場合
（3） 支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合
（4） ６ヶ月以上にわたり、個別アプリにログインして利用した実績がない場合
（5） 当社からの問い合わせその他の回答を求める連絡に対して、最初の連絡から７日間以上応答がない場合（登録ユーザーが当社に届出たメールアドレス宛てのメールが７日間にわたり不達になった場合を含む）
（6） 第４条第３項各号に該当する場合
（7） その他、当社が本サービスの利用、登録ユーザーとしての登録、またはサービス利用契約の継続を適当でないと判断した場合
２     当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について一切の責任を負いません。 

第１１条 （本サービスの停止）
１     当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、最大３ヶ月間（ただし(3)に該当する場合は、当該不可抗力にかかる事由が消失したときから最大３ヶ月間とします。）、本サービスの全部または一部の提供を停止または中断することができるものとします。
（1） 本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
（2） コンピューター、通信回線等が事故により停止した場合
（3） 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
（4） その他、当社が停止または中断を必要と判断した場合
２     当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。

第１２条 （権利帰属）
１     本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
２     ユーザーは、本サービスへ送信した情報について、自らが当該情報を本サービスを通じて公開しその他公衆送信することについての適法な権利を有していること、および投稿コンテンツが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとし、投稿コンテンツに関する一切の法的責任をユーザー自身が負うことに同意します。
３     ユーザーは、投稿コンテンツについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示および実行に関するライセンスを付与することに同意します。
４     ユーザーは、当社および当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。

第１３条 （退会）
１     登録ユーザーは、当社所定の方法で当社に通知することにより、本サービスから退会し、登録ユーザーとしての登録を抹消することができます。
２     退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
３     退会後の利用者情報の取扱いについては、第１８条の規定に従うものとします。

第１４条 （本サービスの内容の変更および終了）
１     当社は、当社の都合により、本サービスの一部ないし全部の内容を変更し、または提供を終了することができます。当社が本サービスの一部ないし全部の提供を終了する場合、やむを得ない場合を除き、当社は１ヶ月以上の告知期間を設けて登録ユーザーに対し本サービスを通じて事前に通知するものとします。
２     前条の場合は、当社は資金決済法に基づき必要な対応をとるものとします。
３     当社は、前項の場合を除き、第１項により当社が行った措置によって登録ユーザーに生じた損害について一切の責任を負いません。

第１５条 （無保証および免責）
１     当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。
２     当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、ユーザーが本サービスに送信したメッセージまたは情報の削除または消失、ユーザーの登録の抹消、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関してユーザーが被った損害（以下「ユーザー損害」といいます。）につき、賠償する責任を一切負わないものとします。
３     当社は、投稿コンテンツ及びリンク設定された外部ウェブサイトによって生じた損害（コンピュータウィルス感染被害などによる損害も含みます。）や、ユーザー同士のトラブル等に対し一切関与せず、当社に故意または重過失がある場合を除き、何らの責任を負わないものとします。
４     何らかの理由により当社が責任を負う場合であっても、当社は、ユーザーの被った損害につき、１万円を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないものとします。
５     本サービスに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、当社は一切責任を負いません。

第１６条 （通知・広告）
１     当社は本サービスを通じて、ユーザーに関連した商品やサービスに対する広告を提供できます。
２     当社はユーザーの端末に本サービスに対するアップデート、広報イベント、新しい語学学習アプリやサービス、その他語学習得に関する情報、これらに関連するメッセージを知らせるためにプッシュまたはローカル通知を送信できます。 

第１７条 （秘密保持）
登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取り扱うことを求めて開示した非公知かつ登録ユーザーが保持していない情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。

第１８条 （利用者情報の取扱い）
１     当社による登録ユーザーの利用者情報の取扱いについては、別途定める「プライバシーポリシー」によるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。
２     当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用および公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。

第１９条 （本規約等の変更）
当社は、本規約を変更できるものとします。当社は、本規約を変更した場合には、登録ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、登録ユーザーが本サービスを利用した場合または当社の定める期間内に登録抹消の手続をとらなかった場合には、登録ユーザーは、本規約の変更に同意したものとみなします。 

第２０条 （連絡および通知）
本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡または通知、および本規約の変更に関する通知その他当社から登録ユーザーに対する連絡または通知は、当社の定める方法で行うものとします。 

第２１条 （サービス利用契約上の地位の譲渡等）
１     登録ユーザーは、当社の書面による事前の承諾なく、サービス利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
２     当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本規約に基づく権利および義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。

第２２条 （分離可能性）
本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。

第２３条 （準拠法および管轄裁判所）
１     本規約およびサービス利用契約の準拠法は日本法とします。
２     本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。 

第２４条 （投稿）
１     ユーザーは、自身の投稿に関連する一切の法的責任を自己が負うことを確認し、これを承諾します。
２     ユーザーは、本サービスの範囲外または話題に関連性のない広告の投稿に対しては、相応の費用を負担することを承諾します。
３     ユーザーは、投稿した内容およびこれに含まれる知的財産権（著作権法第21条ないし第27条及び第28条に規定される権利も含む）およびその他の権利（これには第三者に対する再許諾の権利も含む）について、無償で当社に譲渡することを承諾します。ただし、投稿が当社の別途定める削除ガイドラインに該当する場合、投稿に関する知的財産権その他の権利、義務は一定期間ユーザーに留保されます。
４     ユーザーは、当社が指定する第三者に対して、一切の権利（再許諾権を含む）を許諾しないことを承諾します。
５     ユーザーは、当社、またはその指定する者に対して著作者人格権を一切行使しないことを承諾します。

以上

2021年8月10日制定
最終更新日：2023年7月16日`;
