import Law from "../Law/Law";

const PrivacyPolicy = () => {
  return (
    <div>
      <Law title={title} text={privacyPolicy} />
    </div>
  );
};

export default PrivacyPolicy;

const title = "プライバシーポリシー";
const privacyPolicy = `言語研究開発合同会社（以下「当社」といいます。）は、当社の提供するアプリ「RiceSpeak（ライスピーク）」によるサービス（以下「本サービス」といいます。）をご利用いただくお客様（以下「ユーザー」といいます。）の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定め、これを遵守します。
なお、本ポリシーは、その制定時において、未実装（今後実装を検討する予定）の機能についても規定をしておりますのでご了承ください。 

1.　収集する利用者情報及び収集方法
本ポリシーにおいて①「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）をいいます。②「ユーザー識別情報」とは、下記に記載の情報をいいます。
 ⑴  　ユーザーからご提供いただく情報ユーザーが本サービスを利用するために、ご提供いただくことのある情報は以下のとおりです。
・個人情報
・メールアドレス
・その他当社が定める入力フォームにユーザーが入力する情報
 ⑵  　外部連携サービスを通じてご提供いただく情報ユーザーが、本サービスを利用するにあたり、各種ソーシャルネットワークサービス等の外部サービス（以下「外部連携サービス」といいます。）とのアカウントリンク等の連携を許可した場合には、その際に、当社が外部サービスから自動的に取得することを個別に明示しご同意いただいた事項を、当該外部サービスから取得します。
 ⑶　ユーザーが本サービスを利用するにあたって、当社が収集する情報当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
・端末情報
・ログ情報
・Cookie及び匿名ID
・位置情報

2.　利用目的
当社は、取得した個人情報よびユーザー識別情報（以下「個人情報等」といいます。）を、以下の目的に利用いたします。ただし、個別に目的を示してご同意を頂いて提供を受けた個人情報等については、その目的を含むものとします。
・本人確認及び本サービス提供のため・本サービスの料金その他付加サービス利用料の決済及び請求のため
・本サービスに対するサポート提供のため・本サービスに関する広告を提供するため
・本サービス利用に関する調査、統計資料の作成、分析のため・ユーザーの利用実態に基づきイベント・キャンペーン等を実施するため・メールマガジン、各種告知、その他の連絡等の電子メールを送付するため 

3. 情報の管理
当社は、個人情報等の漏えい、滅失又はき損等を防止し、個人情報を安全に管理するための必要かつ適切な措置を実施するとともに、その継続的改善を行ってまいります。
当社は、個人情報等の取扱いの全部又は一部を業務委託先に委託する場合は、取扱いを委託した個人情報の安全管理が図られるよう、必要かつ適切な監督をいたします。 

4. 第三者提供
当社は、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ずに、第三者に提供することはありません。但し、次に掲げる場合はこの限りではありません。
(1) 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を外部委託する場合
(2) 合併その他の事由による事業承継に伴い個人情報が提供される場合
(3) 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
(4) その他、個人情報保護法その他の法令で認められる場合

5. 個人情報の開示
当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、事務手数料（1件あたり1,100円・税込）を頂戴しておりますので、あらかじめ御了承ください。 

6.　個人情報の訂正及び利用停止等
・当社は、ユーザーから、⑴個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び⑵あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
・当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
・個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、前2項の規定は適用されません。 

7.　個人情報等の取扱に関するお問い合わせ窓口
ご意見、ご質問等のお問い合わせは、下記の窓口までお願い致します。

〒104-0061 東京都中央区銀座一丁目22番11号 銀座大竹ビジデンス2階
言語研究開発合同会社
‍カスタマーサポート係
support@languagerd.com

以上

2021年8月10日制定`;
