export const localDataGeo = {
  countryCodeVSCountryName: {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua And Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "The Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Democratic Republic of the Congo",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire (Ivory Coast)",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    TL: "East Timor",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands",
    FO: "Faroe Islands",
    FJ: "Fiji Islands",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia The",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey and Alderney",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HK: "Hong Kong S.A.R.",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Laos",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macau S.A.R.",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    IM: "Man (Isle of)",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    BQ: "Bonaire, Sint Eustatius and Saba",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory Occupied",
    PA: "Panama",
    PG: "Papua new Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn Island",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russia",
    RW: "Rwanda",
    SH: "Saint Helena",
    KN: "Saint Kitts And Nevis",
    LC: "Saint Lucia",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent And The Grenadines",
    BL: "Saint-Barthelemy",
    MF: "Saint-Martin (French part)",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard And Jan Mayen Islands",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syria",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad And Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks And Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VA: "Vatican City State (Holy See)",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (US)",
    WF: "Wallis And Futuna Islands",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    XK: "Kosovo",
    CW: "Curaçao",
    SX: "Sint Maarten (Dutch part)",
  },
  countryCodeVSCities: {
    AF: [
      {
        name: "Badakhshan",
        countryCode: "AF",
        latitude: "36.73477250",
        longitude: "70.81199530",
        nameNative: null,
        label: "Badakhshan",
        cityCode: "AF_BDS",
      },
      {
        name: "Badghis",
        countryCode: "AF",
        latitude: "35.16713390",
        longitude: "63.76953840",
        nameNative: null,
        label: "Badghis",
        cityCode: "AF_BDG",
      },
      {
        name: "Baghlan",
        countryCode: "AF",
        latitude: "36.17890260",
        longitude: "68.74530640",
        nameNative: null,
        label: "Baghlan",
        cityCode: "AF_BGL",
      },
      {
        name: "Balkh",
        countryCode: "AF",
        latitude: "36.75506030",
        longitude: "66.89753720",
        nameNative: null,
        label: "Balkh",
        cityCode: "AF_BAL",
      },
      {
        name: "Bamyan",
        countryCode: "AF",
        latitude: "34.81000670",
        longitude: "67.82121040",
        nameNative: null,
        label: "Bamyan",
        cityCode: "AF_BAM",
      },
      {
        name: "Daykundi",
        countryCode: "AF",
        latitude: "33.66949500",
        longitude: "66.04635340",
        nameNative: null,
        label: "Daykundi",
        cityCode: "AF_DAY",
      },
      {
        name: "Farah",
        countryCode: "AF",
        latitude: "32.49532800",
        longitude: "62.26266270",
        nameNative: null,
        label: "Farah",
        cityCode: "AF_FRA",
      },
      {
        name: "Faryab",
        countryCode: "AF",
        latitude: "36.07956130",
        longitude: "64.90595500",
        nameNative: null,
        label: "Faryab",
        cityCode: "AF_FYB",
      },
      {
        name: "Ghazni",
        countryCode: "AF",
        latitude: "33.54505870",
        longitude: "68.41739720",
        nameNative: null,
        label: "Ghazni",
        cityCode: "AF_GHA",
      },
      {
        name: "Ghōr",
        countryCode: "AF",
        latitude: "34.09957760",
        longitude: "64.90595500",
        nameNative: null,
        label: "Ghōr",
        cityCode: "AF_GHO",
      },
      {
        name: "Helmand",
        countryCode: "AF",
        latitude: "39.29893610",
        longitude: "-76.61604720",
        nameNative: null,
        label: "Helmand",
        cityCode: "AF_HEL",
      },
      {
        name: "Herat",
        countryCode: "AF",
        latitude: "34.35286500",
        longitude: "62.20402870",
        nameNative: null,
        label: "Herat",
        cityCode: "AF_HER",
      },
      {
        name: "Jowzjan",
        countryCode: "AF",
        latitude: "36.89696920",
        longitude: "65.66585680",
        nameNative: null,
        label: "Jowzjan",
        cityCode: "AF_JOW",
      },
      {
        name: "Kabul",
        countryCode: "AF",
        latitude: "34.55534940",
        longitude: "69.20748600",
        nameNative: null,
        label: "Kabul",
        cityCode: "AF_KAB",
      },
      {
        name: "Kandahar",
        countryCode: "AF",
        latitude: "31.62887100",
        longitude: "65.73717490",
        nameNative: null,
        label: "Kandahar",
        cityCode: "AF_KAN",
      },
      {
        name: "Kapisa",
        countryCode: "AF",
        latitude: "34.98105720",
        longitude: "69.62145620",
        nameNative: null,
        label: "Kapisa",
        cityCode: "AF_KAP",
      },
      {
        name: "Khost",
        countryCode: "AF",
        latitude: "33.33384720",
        longitude: "69.93716730",
        nameNative: null,
        label: "Khost",
        cityCode: "AF_KHO",
      },
      {
        name: "Kunar",
        countryCode: "AF",
        latitude: "34.84658930",
        longitude: "71.09731700",
        nameNative: null,
        label: "Kunar",
        cityCode: "AF_KNR",
      },
      {
        name: "Kunduz Province",
        countryCode: "AF",
        latitude: "36.72855110",
        longitude: "68.86789820",
        nameNative: null,
        label: "Kunduz Province",
        cityCode: "AF_KDZ",
      },
      {
        name: "Laghman",
        countryCode: "AF",
        latitude: "34.68976870",
        longitude: "70.14558050",
        nameNative: null,
        label: "Laghman",
        cityCode: "AF_LAG",
      },
      {
        name: "Logar",
        countryCode: "AF",
        latitude: "34.01455180",
        longitude: "69.19239160",
        nameNative: null,
        label: "Logar",
        cityCode: "AF_LOG",
      },
      {
        name: "Nangarhar",
        countryCode: "AF",
        latitude: "34.17183130",
        longitude: "70.62167940",
        nameNative: null,
        label: "Nangarhar",
        cityCode: "AF_NAN",
      },
      {
        name: "Nimruz",
        countryCode: "AF",
        latitude: "31.02614880",
        longitude: "62.45041540",
        nameNative: null,
        label: "Nimruz",
        cityCode: "AF_NIM",
      },
      {
        name: "Nuristan",
        countryCode: "AF",
        latitude: "35.32502230",
        longitude: "70.90712360",
        nameNative: null,
        label: "Nuristan",
        cityCode: "AF_NUR",
      },
      {
        name: "Paktia",
        countryCode: "AF",
        latitude: "33.70619900",
        longitude: "69.38310790",
        nameNative: null,
        label: "Paktia",
        cityCode: "AF_PIA",
      },
      {
        name: "Paktika",
        countryCode: "AF",
        latitude: "32.26453860",
        longitude: "68.52471490",
        nameNative: null,
        label: "Paktika",
        cityCode: "AF_PKA",
      },
      {
        name: "Panjshir",
        countryCode: "AF",
        latitude: "38.88023910",
        longitude: "-77.17172380",
        nameNative: null,
        label: "Panjshir",
        cityCode: "AF_PAN",
      },
      {
        name: "Parwan",
        countryCode: "AF",
        latitude: "34.96309770",
        longitude: "68.81088490",
        nameNative: null,
        label: "Parwan",
        cityCode: "AF_PAR",
      },
      {
        name: "Samangan",
        countryCode: "AF",
        latitude: "36.31555060",
        longitude: "67.96428630",
        nameNative: null,
        label: "Samangan",
        cityCode: "AF_SAM",
      },
      {
        name: "Sar-e Pol",
        countryCode: "AF",
        latitude: "36.21662800",
        longitude: "65.93336000",
        nameNative: null,
        label: "Sar-e Pol",
        cityCode: "AF_SAR",
      },
      {
        name: "Takhar",
        countryCode: "AF",
        latitude: "36.66980130",
        longitude: "69.47845410",
        nameNative: null,
        label: "Takhar",
        cityCode: "AF_TAK",
      },
      {
        name: "Urozgan",
        countryCode: "AF",
        latitude: "32.92712870",
        longitude: "66.14152630",
        nameNative: null,
        label: "Urozgan",
        cityCode: "AF_URU",
      },
      {
        name: "Zabul",
        countryCode: "AF",
        latitude: "32.19187820",
        longitude: "67.18944880",
        nameNative: null,
        label: "Zabul",
        cityCode: "AF_ZAB",
      },
    ],
    AL: [
      {
        name: "Berat County",
        countryCode: "AL",
        latitude: "40.69530120",
        longitude: "20.04496620",
        nameNative: null,
        label: "Berat County",
        cityCode: "AL_01",
      },
      {
        name: "Berat District",
        countryCode: "AL",
        latitude: "40.70863770",
        longitude: "19.94373140",
        nameNative: null,
        label: "Berat District",
        cityCode: "AL_BR",
      },
      {
        name: "Bulqizë District",
        countryCode: "AL",
        latitude: "41.49425870",
        longitude: "20.21471570",
        nameNative: null,
        label: "Bulqizë District",
        cityCode: "AL_BU",
      },
      {
        name: "Delvinë District",
        countryCode: "AL",
        latitude: "39.94813640",
        longitude: "20.09558910",
        nameNative: null,
        label: "Delvinë District",
        cityCode: "AL_DL",
      },
      {
        name: "Devoll District",
        countryCode: "AL",
        latitude: "40.64473470",
        longitude: "20.95066360",
        nameNative: null,
        label: "Devoll District",
        cityCode: "AL_DV",
      },
      {
        name: "Dibër County",
        countryCode: "AL",
        latitude: "41.58881630",
        longitude: "20.23556470",
        nameNative: null,
        label: "Dibër County",
        cityCode: "AL_09",
      },
      {
        name: "Dibër District",
        countryCode: "AL",
        latitude: "41.58881630",
        longitude: "20.23556470",
        nameNative: null,
        label: "Dibër District",
        cityCode: "AL_DI",
      },
      {
        name: "Durrës County",
        countryCode: "AL",
        latitude: "41.50809720",
        longitude: "19.61631850",
        nameNative: null,
        label: "Durrës County",
        cityCode: "AL_02",
      },
      {
        name: "Durrës District",
        countryCode: "AL",
        latitude: "41.37065170",
        longitude: "19.52110630",
        nameNative: null,
        label: "Durrës District",
        cityCode: "AL_DR",
      },
      {
        name: "Elbasan County",
        countryCode: "AL",
        latitude: "41.12666720",
        longitude: "20.23556470",
        nameNative: null,
        label: "Elbasan County",
        cityCode: "AL_03",
      },
      {
        name: "Fier County",
        countryCode: "AL",
        latitude: "40.91913920",
        longitude: "19.66393090",
        nameNative: null,
        label: "Fier County",
        cityCode: "AL_04",
      },
      {
        name: "Fier District",
        countryCode: "AL",
        latitude: "40.72750400",
        longitude: "19.56275960",
        nameNative: null,
        label: "Fier District",
        cityCode: "AL_FR",
      },
      {
        name: "Gjirokastër County",
        countryCode: "AL",
        latitude: "40.06728740",
        longitude: "20.10452290",
        nameNative: null,
        label: "Gjirokastër County",
        cityCode: "AL_05",
      },
      {
        name: "Gjirokastër District",
        countryCode: "AL",
        latitude: "40.06728740",
        longitude: "20.10452290",
        nameNative: null,
        label: "Gjirokastër District",
        cityCode: "AL_GJ",
      },
      {
        name: "Gramsh District",
        countryCode: "AL",
        latitude: "40.86698730",
        longitude: "20.18493230",
        nameNative: null,
        label: "Gramsh District",
        cityCode: "AL_GR",
      },
      {
        name: "Has District",
        countryCode: "AL",
        latitude: "42.79013360",
        longitude: "-83.61220120",
        nameNative: null,
        label: "Has District",
        cityCode: "AL_HA",
      },
      {
        name: "Kavajë District",
        countryCode: "AL",
        latitude: "41.18445290",
        longitude: "19.56275960",
        nameNative: null,
        label: "Kavajë District",
        cityCode: "AL_KA",
      },
      {
        name: "Kolonjë District",
        countryCode: "AL",
        latitude: "40.33732620",
        longitude: "20.67946760",
        nameNative: null,
        label: "Kolonjë District",
        cityCode: "AL_ER",
      },
      {
        name: "Korçë County",
        countryCode: "AL",
        latitude: "40.59056700",
        longitude: "20.61689210",
        nameNative: null,
        label: "Korçë County",
        cityCode: "AL_06",
      },
      {
        name: "Korçë District",
        countryCode: "AL",
        latitude: "40.59056700",
        longitude: "20.61689210",
        nameNative: null,
        label: "Korçë District",
        cityCode: "AL_KO",
      },
      {
        name: "Krujë District",
        countryCode: "AL",
        latitude: "41.50947650",
        longitude: "19.77107320",
        nameNative: null,
        label: "Krujë District",
        cityCode: "AL_KR",
      },
      {
        name: "Kukës County",
        countryCode: "AL",
        latitude: "42.08074640",
        longitude: "20.41429230",
        nameNative: null,
        label: "Kukës County",
        cityCode: "AL_07",
      },
      {
        name: "Kukës District",
        countryCode: "AL",
        latitude: "42.08074640",
        longitude: "20.41429230",
        nameNative: null,
        label: "Kukës District",
        cityCode: "AL_KU",
      },
      {
        name: "Kurbin District",
        countryCode: "AL",
        latitude: "41.64126440",
        longitude: "19.70559500",
        nameNative: null,
        label: "Kurbin District",
        cityCode: "AL_KB",
      },
      {
        name: "Kuçovë District",
        countryCode: "AL",
        latitude: "40.78370630",
        longitude: "19.87823480",
        nameNative: null,
        label: "Kuçovë District",
        cityCode: "AL_KC",
      },
      {
        name: "Lezhë County",
        countryCode: "AL",
        latitude: "41.78137590",
        longitude: "19.80679160",
        nameNative: null,
        label: "Lezhë County",
        cityCode: "AL_08",
      },
      {
        name: "Lezhë District",
        countryCode: "AL",
        latitude: "41.78607300",
        longitude: "19.64607580",
        nameNative: null,
        label: "Lezhë District",
        cityCode: "AL_LE",
      },
      {
        name: "Librazhd District",
        countryCode: "AL",
        latitude: "41.18292320",
        longitude: "20.31747690",
        nameNative: null,
        label: "Librazhd District",
        cityCode: "AL_LB",
      },
      {
        name: "Lushnjë District",
        countryCode: "AL",
        latitude: "40.94198300",
        longitude: "19.69964280",
        nameNative: null,
        label: "Lushnjë District",
        cityCode: "AL_LU",
      },
      {
        name: "Mallakastër District",
        countryCode: "AL",
        latitude: "40.52733760",
        longitude: "19.78297910",
        nameNative: null,
        label: "Mallakastër District",
        cityCode: "AL_MK",
      },
      {
        name: "Malësi e Madhe District",
        countryCode: "AL",
        latitude: "42.42451730",
        longitude: "19.61631850",
        nameNative: null,
        label: "Malësi e Madhe District",
        cityCode: "AL_MM",
      },
      {
        name: "Mat District",
        countryCode: "AL",
        latitude: "41.59376750",
        longitude: "19.99732440",
        nameNative: null,
        label: "Mat District",
        cityCode: "AL_MT",
      },
      {
        name: "Mirditë District",
        countryCode: "AL",
        latitude: "41.76428600",
        longitude: "19.90205090",
        nameNative: null,
        label: "Mirditë District",
        cityCode: "AL_MR",
      },
      {
        name: "Peqin District",
        countryCode: "AL",
        latitude: "41.04709020",
        longitude: "19.75023840",
        nameNative: null,
        label: "Peqin District",
        cityCode: "AL_PQ",
      },
      {
        name: "Pogradec District",
        countryCode: "AL",
        latitude: "40.90153140",
        longitude: "20.65562890",
        nameNative: null,
        label: "Pogradec District",
        cityCode: "AL_PG",
      },
      {
        name: "Pukë District",
        countryCode: "AL",
        latitude: "42.04697720",
        longitude: "19.89609680",
        nameNative: null,
        label: "Pukë District",
        cityCode: "AL_PU",
      },
      {
        name: "Përmet District",
        countryCode: "AL",
        latitude: "40.23618370",
        longitude: "20.35173340",
        nameNative: null,
        label: "Përmet District",
        cityCode: "AL_PR",
      },
      {
        name: "Sarandë District",
        countryCode: "AL",
        latitude: "39.85921190",
        longitude: "20.02710010",
        nameNative: null,
        label: "Sarandë District",
        cityCode: "AL_SR",
      },
      {
        name: "Shkodër County",
        countryCode: "AL",
        latitude: "42.15037100",
        longitude: "19.66393090",
        nameNative: null,
        label: "Shkodër County",
        cityCode: "AL_10",
      },
      {
        name: "Shkodër District",
        countryCode: "AL",
        latitude: "42.06929850",
        longitude: "19.50325590",
        nameNative: null,
        label: "Shkodër District",
        cityCode: "AL_SH",
      },
      {
        name: "Skrapar District",
        countryCode: "AL",
        latitude: "40.53499460",
        longitude: "20.28322170",
        nameNative: null,
        label: "Skrapar District",
        cityCode: "AL_SK",
      },
      {
        name: "Tepelenë District",
        countryCode: "AL",
        latitude: "40.29666320",
        longitude: "20.01816730",
        nameNative: null,
        label: "Tepelenë District",
        cityCode: "AL_TE",
      },
      {
        name: "Tirana County",
        countryCode: "AL",
        latitude: "41.24275980",
        longitude: "19.80679160",
        nameNative: null,
        label: "Tirana County",
        cityCode: "AL_11",
      },
      {
        name: "Tirana District",
        countryCode: "AL",
        latitude: "41.32754590",
        longitude: "19.81869820",
        nameNative: null,
        label: "Tirana District",
        cityCode: "AL_TR",
      },
      {
        name: "Tropojë District",
        countryCode: "AL",
        latitude: "42.39821510",
        longitude: "20.16259550",
        nameNative: null,
        label: "Tropojë District",
        cityCode: "AL_TP",
      },
      {
        name: "Vlorë County",
        countryCode: "AL",
        latitude: "40.15009600",
        longitude: "19.80679160",
        nameNative: null,
        label: "Vlorë County",
        cityCode: "AL_12",
      },
      {
        name: "Vlorë District",
        countryCode: "AL",
        latitude: "40.46606680",
        longitude: "19.49135600",
        nameNative: null,
        label: "Vlorë District",
        cityCode: "AL_VL",
      },
    ],
    DZ: [
      {
        name: "Adrar",
        countryCode: "DZ",
        latitude: "26.41813100",
        longitude: "-0.60147170",
        nameNative: null,
        label: "Adrar",
        cityCode: "DZ_01",
      },
      {
        name: "Algiers",
        countryCode: "DZ",
        latitude: "36.69972940",
        longitude: "3.05761990",
        nameNative: null,
        label: "Algiers",
        cityCode: "DZ_16",
      },
      {
        name: "Annaba",
        countryCode: "DZ",
        latitude: "36.80205080",
        longitude: "7.52472430",
        nameNative: null,
        label: "Annaba",
        cityCode: "DZ_23",
      },
      {
        name: "Aïn Defla",
        countryCode: "DZ",
        latitude: "36.25094290",
        longitude: "1.93938150",
        nameNative: null,
        label: "Aïn Defla",
        cityCode: "DZ_44",
      },
      {
        name: "Aïn Témouchent",
        countryCode: "DZ",
        latitude: "35.29926980",
        longitude: "-1.13927920",
        nameNative: null,
        label: "Aïn Témouchent",
        cityCode: "DZ_46",
      },
      {
        name: "Batna",
        countryCode: "DZ",
        latitude: "35.59659540",
        longitude: "5.89871390",
        nameNative: null,
        label: "Batna",
        cityCode: "DZ_05",
      },
      {
        name: "Biskra",
        countryCode: "DZ",
        latitude: "34.84494370",
        longitude: "5.72485670",
        nameNative: null,
        label: "Biskra",
        cityCode: "DZ_07",
      },
      {
        name: "Blida",
        countryCode: "DZ",
        latitude: "36.53112300",
        longitude: "2.89762540",
        nameNative: null,
        label: "Blida",
        cityCode: "DZ_09",
      },
      {
        name: "Bordj Baji Mokhtar",
        countryCode: "DZ",
        latitude: "22.96633500",
        longitude: "-3.94727320",
        nameNative: null,
        label: "Bordj Baji Mokhtar",
        cityCode: "DZ_52",
      },
      {
        name: "Bordj Bou Arréridj",
        countryCode: "DZ",
        latitude: "36.07399250",
        longitude: "4.76302710",
        nameNative: null,
        label: "Bordj Bou Arréridj",
        cityCode: "DZ_34",
      },
      {
        name: "Boumerdès",
        countryCode: "DZ",
        latitude: "36.68395590",
        longitude: "3.62178020",
        nameNative: null,
        label: "Boumerdès",
        cityCode: "DZ_35",
      },
      {
        name: "Bouïra",
        countryCode: "DZ",
        latitude: "36.36918460",
        longitude: "3.90061940",
        nameNative: null,
        label: "Bouïra",
        cityCode: "DZ_10",
      },
      {
        name: "Béchar",
        countryCode: "DZ",
        latitude: "31.62380980",
        longitude: "-2.21624430",
        nameNative: null,
        label: "Béchar",
        cityCode: "DZ_08",
      },
      {
        name: "Béjaïa",
        countryCode: "DZ",
        latitude: "36.75152580",
        longitude: "5.05568370",
        nameNative: null,
        label: "Béjaïa",
        cityCode: "DZ_06",
      },
      {
        name: "Béni Abbès",
        countryCode: "DZ",
        latitude: "30.08310420",
        longitude: "-2.83450520",
        nameNative: null,
        label: "Béni Abbès",
        cityCode: "DZ_53",
      },
      {
        name: "Chlef",
        countryCode: "DZ",
        latitude: "36.16935150",
        longitude: "1.28910360",
        nameNative: null,
        label: "Chlef",
        cityCode: "DZ_02",
      },
      {
        name: "Constantine",
        countryCode: "DZ",
        latitude: "36.33739110",
        longitude: "6.66381200",
        nameNative: null,
        label: "Constantine",
        cityCode: "DZ_25",
      },
      {
        name: "Djanet",
        countryCode: "DZ",
        latitude: "23.83108720",
        longitude: "8.70046720",
        nameNative: null,
        label: "Djanet",
        cityCode: "DZ_56",
      },
      {
        name: "Djelfa",
        countryCode: "DZ",
        latitude: "34.67039560",
        longitude: "3.25037610",
        nameNative: null,
        label: "Djelfa",
        cityCode: "DZ_17",
      },
      {
        name: "El Bayadh",
        countryCode: "DZ",
        latitude: "32.71488240",
        longitude: "0.90566230",
        nameNative: null,
        label: "El Bayadh",
        cityCode: "DZ_32",
      },
      {
        name: "El M'ghair",
        countryCode: "DZ",
        latitude: "33.95405610",
        longitude: "5.13464180",
        nameNative: null,
        label: "El M'ghair",
        cityCode: "DZ_49",
      },
      {
        name: "El Menia",
        countryCode: "DZ",
        latitude: "31.36422500",
        longitude: "2.57844950",
        nameNative: null,
        label: "El Menia",
        cityCode: "DZ_50",
      },
      {
        name: "El Oued",
        countryCode: "DZ",
        latitude: "33.36781100",
        longitude: "6.85165110",
        nameNative: null,
        label: "El Oued",
        cityCode: "DZ_39",
      },
      {
        name: "El Tarf",
        countryCode: "DZ",
        latitude: "36.75766780",
        longitude: "8.30763430",
        nameNative: null,
        label: "El Tarf",
        cityCode: "DZ_36",
      },
      {
        name: "Ghardaïa",
        countryCode: "DZ",
        latitude: "32.49437410",
        longitude: "3.64446000",
        nameNative: null,
        label: "Ghardaïa",
        cityCode: "DZ_47",
      },
      {
        name: "Guelma",
        countryCode: "DZ",
        latitude: "36.46274440",
        longitude: "7.43308330",
        nameNative: null,
        label: "Guelma",
        cityCode: "DZ_24",
      },
      {
        name: "Illizi",
        countryCode: "DZ",
        latitude: "26.16900050",
        longitude: "8.48424650",
        nameNative: null,
        label: "Illizi",
        cityCode: "DZ_33",
      },
      {
        name: "In Guezzam",
        countryCode: "DZ",
        latitude: "20.38643230",
        longitude: "4.77893940",
        nameNative: null,
        label: "In Guezzam",
        cityCode: "DZ_58",
      },
      {
        name: "In Salah",
        countryCode: "DZ",
        latitude: "27.21492290",
        longitude: "1.84843960",
        nameNative: null,
        label: "In Salah",
        cityCode: "DZ_57",
      },
      {
        name: "Jijel",
        countryCode: "DZ",
        latitude: "36.71796810",
        longitude: "5.98325770",
        nameNative: null,
        label: "Jijel",
        cityCode: "DZ_18",
      },
      {
        name: "Khenchela",
        countryCode: "DZ",
        latitude: "35.42694040",
        longitude: "7.14601550",
        nameNative: null,
        label: "Khenchela",
        cityCode: "DZ_40",
      },
      {
        name: "Laghouat",
        countryCode: "DZ",
        latitude: "33.80783410",
        longitude: "2.86282940",
        nameNative: null,
        label: "Laghouat",
        cityCode: "DZ_03",
      },
      {
        name: "M'Sila",
        countryCode: "DZ",
        latitude: "35.71866460",
        longitude: "4.52334230",
        nameNative: null,
        label: "M'Sila",
        cityCode: "DZ_28",
      },
      {
        name: "Mascara",
        countryCode: "DZ",
        latitude: "35.39041250",
        longitude: "0.14949880",
        nameNative: null,
        label: "Mascara",
        cityCode: "DZ_29",
      },
      {
        name: "Mila",
        countryCode: "DZ",
        latitude: "36.36479570",
        longitude: "6.15269850",
        nameNative: null,
        label: "Mila",
        cityCode: "DZ_43",
      },
      {
        name: "Mostaganem",
        countryCode: "DZ",
        latitude: "35.95830540",
        longitude: "0.33718890",
        nameNative: null,
        label: "Mostaganem",
        cityCode: "DZ_27",
      },
      {
        name: "Médéa",
        countryCode: "DZ",
        latitude: "36.26370780",
        longitude: "2.75878570",
        nameNative: null,
        label: "Médéa",
        cityCode: "DZ_26",
      },
      {
        name: "Naama",
        countryCode: "DZ",
        latitude: "33.26673170",
        longitude: "-0.31286590",
        nameNative: null,
        label: "Naama",
        cityCode: "DZ_45",
      },
      {
        name: "Oran",
        countryCode: "DZ",
        latitude: "35.60823510",
        longitude: "-0.56360900",
        nameNative: null,
        label: "Oran",
        cityCode: "DZ_31",
      },
      {
        name: "Ouargla",
        countryCode: "DZ",
        latitude: "32.22648630",
        longitude: "5.72998210",
        nameNative: null,
        label: "Ouargla",
        cityCode: "DZ_30",
      },
      {
        name: "Ouled Djellal",
        countryCode: "DZ",
        latitude: "34.41782210",
        longitude: "4.96858430",
        nameNative: null,
        label: "Ouled Djellal",
        cityCode: "DZ_51",
      },
      {
        name: "Oum El Bouaghi",
        countryCode: "DZ",
        latitude: "35.86887890",
        longitude: "7.11082660",
        nameNative: null,
        label: "Oum El Bouaghi",
        cityCode: "DZ_04",
      },
      {
        name: "Relizane",
        countryCode: "DZ",
        latitude: "35.73834050",
        longitude: "0.75328090",
        nameNative: null,
        label: "Relizane",
        cityCode: "DZ_48",
      },
      {
        name: "Saïda",
        countryCode: "DZ",
        latitude: "34.84152070",
        longitude: "0.14560550",
        nameNative: null,
        label: "Saïda",
        cityCode: "DZ_20",
      },
      {
        name: "Sidi Bel Abbès",
        countryCode: "DZ",
        latitude: "34.68060240",
        longitude: "-1.09994950",
        nameNative: null,
        label: "Sidi Bel Abbès",
        cityCode: "DZ_22",
      },
      {
        name: "Skikda",
        countryCode: "DZ",
        latitude: "36.67211980",
        longitude: "6.83509990",
        nameNative: null,
        label: "Skikda",
        cityCode: "DZ_21",
      },
      {
        name: "Souk Ahras",
        countryCode: "DZ",
        latitude: "36.28010620",
        longitude: "7.93840330",
        nameNative: null,
        label: "Souk Ahras",
        cityCode: "DZ_41",
      },
      {
        name: "Sétif",
        countryCode: "DZ",
        latitude: "36.30733890",
        longitude: "5.56172790",
        nameNative: null,
        label: "Sétif",
        cityCode: "DZ_19",
      },
      {
        name: "Tamanghasset",
        countryCode: "DZ",
        latitude: "22.79029720",
        longitude: "5.51932680",
        nameNative: null,
        label: "Tamanghasset",
        cityCode: "DZ_11",
      },
      {
        name: "Tiaret",
        countryCode: "DZ",
        latitude: "35.37086890",
        longitude: "1.32178520",
        nameNative: null,
        label: "Tiaret",
        cityCode: "DZ_14",
      },
      {
        name: "Timimoun",
        countryCode: "DZ",
        latitude: "29.67890600",
        longitude: "0.50046080",
        nameNative: null,
        label: "Timimoun",
        cityCode: "DZ_54",
      },
      {
        name: "Tindouf",
        countryCode: "DZ",
        latitude: "27.80631190",
        longitude: "-5.72998210",
        nameNative: null,
        label: "Tindouf",
        cityCode: "DZ_37",
      },
      {
        name: "Tipasa",
        countryCode: "DZ",
        latitude: "36.54626500",
        longitude: "2.18432850",
        nameNative: null,
        label: "Tipasa",
        cityCode: "DZ_42",
      },
      {
        name: "Tissemsilt",
        countryCode: "DZ",
        latitude: "35.60537810",
        longitude: "1.81309800",
        nameNative: null,
        label: "Tissemsilt",
        cityCode: "DZ_38",
      },
      {
        name: "Tizi Ouzou",
        countryCode: "DZ",
        latitude: "36.70691100",
        longitude: "4.23333550",
        nameNative: null,
        label: "Tizi Ouzou",
        cityCode: "DZ_15",
      },
      {
        name: "Tlemcen",
        countryCode: "DZ",
        latitude: "34.67802840",
        longitude: "-1.36621600",
        nameNative: null,
        label: "Tlemcen",
        cityCode: "DZ_13",
      },
      {
        name: "Touggourt",
        countryCode: "DZ",
        latitude: "33.12484760",
        longitude: "5.78327150",
        nameNative: null,
        label: "Touggourt",
        cityCode: "DZ_55",
      },
      {
        name: "Tébessa",
        countryCode: "DZ",
        latitude: "35.12906910",
        longitude: "7.95928630",
        nameNative: null,
        label: "Tébessa",
        cityCode: "DZ_12",
      },
    ],
    AD: [
      {
        name: "Andorra la Vella",
        countryCode: "AD",
        latitude: "42.50631740",
        longitude: "1.52183550",
        nameNative: null,
        label: "Andorra la Vella",
        cityCode: "AD_07",
      },
      {
        name: "Canillo",
        countryCode: "AD",
        latitude: "42.59782490",
        longitude: "1.65663770",
        nameNative: null,
        label: "Canillo",
        cityCode: "AD_02",
      },
      {
        name: "Encamp",
        countryCode: "AD",
        latitude: "42.53597640",
        longitude: "1.58367730",
        nameNative: null,
        label: "Encamp",
        cityCode: "AD_03",
      },
      {
        name: "Escaldes-Engordany",
        countryCode: "AD",
        latitude: "42.49093790",
        longitude: "1.58869660",
        nameNative: null,
        label: "Escaldes-Engordany",
        cityCode: "AD_08",
      },
      {
        name: "La Massana",
        countryCode: "AD",
        latitude: "42.54562500",
        longitude: "1.51473920",
        nameNative: null,
        label: "La Massana",
        cityCode: "AD_04",
      },
      {
        name: "Ordino",
        countryCode: "AD",
        latitude: "42.59944330",
        longitude: "1.54023270",
        nameNative: null,
        label: "Ordino",
        cityCode: "AD_05",
      },
      {
        name: "Sant Julià de Lòria",
        countryCode: "AD",
        latitude: "42.45296310",
        longitude: "1.49182350",
        nameNative: null,
        label: "Sant Julià de Lòria",
        cityCode: "AD_06",
      },
    ],
    AO: [
      {
        name: "Bengo Province",
        countryCode: "AO",
        latitude: "-9.10422570",
        longitude: "13.72891670",
        nameNative: null,
        label: "Bengo Province",
        cityCode: "AO_BGO",
      },
      {
        name: "Benguela Province",
        countryCode: "AO",
        latitude: "-12.80037440",
        longitude: "13.91439900",
        nameNative: null,
        label: "Benguela Province",
        cityCode: "AO_BGU",
      },
      {
        name: "Bié Province",
        countryCode: "AO",
        latitude: "-12.57279070",
        longitude: "17.66888700",
        nameNative: null,
        label: "Bié Province",
        cityCode: "AO_BIE",
      },
      {
        name: "Cabinda Province",
        countryCode: "AO",
        latitude: "-5.02487490",
        longitude: "12.34638750",
        nameNative: null,
        label: "Cabinda Province",
        cityCode: "AO_CAB",
      },
      {
        name: "Cuando Cubango Province",
        countryCode: "AO",
        latitude: "-16.41808240",
        longitude: "18.80761950",
        nameNative: null,
        label: "Cuando Cubango Province",
        cityCode: "AO_CCU",
      },
      {
        name: "Cuanza Norte Province",
        countryCode: "AO",
        latitude: "-9.23985130",
        longitude: "14.65878210",
        nameNative: null,
        label: "Cuanza Norte Province",
        cityCode: "AO_CNO",
      },
      {
        name: "Cuanza Sul",
        countryCode: "AO",
        latitude: "-10.59519100",
        longitude: "15.40680790",
        nameNative: null,
        label: "Cuanza Sul",
        cityCode: "AO_CUS",
      },
      {
        name: "Cunene Province",
        countryCode: "AO",
        latitude: "-16.28022210",
        longitude: "16.15809370",
        nameNative: null,
        label: "Cunene Province",
        cityCode: "AO_CNN",
      },
      {
        name: "Huambo Province",
        countryCode: "AO",
        latitude: "-12.52682210",
        longitude: "15.59433880",
        nameNative: null,
        label: "Huambo Province",
        cityCode: "AO_HUA",
      },
      {
        name: "Huíla Province",
        countryCode: "AO",
        latitude: "-14.92805530",
        longitude: "14.65878210",
        nameNative: null,
        label: "Huíla Province",
        cityCode: "AO_HUI",
      },
      {
        name: "Luanda Province",
        countryCode: "AO",
        latitude: "-9.03508800",
        longitude: "13.26634790",
        nameNative: null,
        label: "Luanda Province",
        cityCode: "AO_LUA",
      },
      {
        name: "Lunda Norte Province",
        countryCode: "AO",
        latitude: "-8.35250220",
        longitude: "19.18800470",
        nameNative: null,
        label: "Lunda Norte Province",
        cityCode: "AO_LNO",
      },
      {
        name: "Lunda Sul Province",
        countryCode: "AO",
        latitude: "-10.28665780",
        longitude: "20.71224650",
        nameNative: null,
        label: "Lunda Sul Province",
        cityCode: "AO_LSU",
      },
      {
        name: "Malanje Province",
        countryCode: "AO",
        latitude: "-9.82511830",
        longitude: "16.91225100",
        nameNative: null,
        label: "Malanje Province",
        cityCode: "AO_MAL",
      },
      {
        name: "Moxico Province",
        countryCode: "AO",
        latitude: "-13.42935790",
        longitude: "20.33088140",
        nameNative: null,
        label: "Moxico Province",
        cityCode: "AO_MOX",
      },
      {
        name: "Uíge Province",
        countryCode: "AO",
        latitude: "-7.17367320",
        longitude: "15.40680790",
        nameNative: null,
        label: "Uíge Province",
        cityCode: "AO_UIG",
      },
      {
        name: "Zaire Province",
        countryCode: "AO",
        latitude: "-6.57334580",
        longitude: "13.17403480",
        nameNative: null,
        label: "Zaire Province",
        cityCode: "AO_ZAI",
      },
    ],
    AG: [
      {
        name: "Barbuda",
        countryCode: "AG",
        latitude: "17.62662420",
        longitude: "-61.77130280",
        nameNative: null,
        label: "Barbuda",
        cityCode: "AG_10",
      },
      {
        name: "Redonda",
        countryCode: "AG",
        latitude: "16.93841600",
        longitude: "-62.34551480",
        nameNative: null,
        label: "Redonda",
        cityCode: "AG_11",
      },
      {
        name: "Saint George Parish",
        countryCode: "AG",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint George Parish",
        cityCode: "AG_03",
      },
      {
        name: "Saint John Parish",
        countryCode: "AG",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint John Parish",
        cityCode: "AG_04",
      },
      {
        name: "Saint Mary Parish",
        countryCode: "AG",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Mary Parish",
        cityCode: "AG_05",
      },
      {
        name: "Saint Paul Parish",
        countryCode: "AG",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Paul Parish",
        cityCode: "AG_06",
      },
      {
        name: "Saint Peter Parish",
        countryCode: "AG",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Peter Parish",
        cityCode: "AG_07",
      },
      {
        name: "Saint Philip Parish",
        countryCode: "AG",
        latitude: "40.43682580",
        longitude: "-80.06855320",
        nameNative: null,
        label: "Saint Philip Parish",
        cityCode: "AG_08",
      },
    ],
    AR: [
      {
        name: "Buenos Aires",
        countryCode: "AR",
        latitude: "-37.20172850",
        longitude: "-59.84106970",
        nameNative: null,
        label: "Buenos Aires",
        cityCode: "AR_B",
      },
      {
        name: "Catamarca",
        countryCode: "AR",
        latitude: "-28.47158770",
        longitude: "-65.78772090",
        nameNative: null,
        label: "Catamarca",
        cityCode: "AR_K",
      },
      {
        name: "Chaco",
        countryCode: "AR",
        latitude: "-27.42571750",
        longitude: "-59.02437840",
        nameNative: null,
        label: "Chaco",
        cityCode: "AR_H",
      },
      {
        name: "Chubut",
        countryCode: "AR",
        latitude: "-43.29342460",
        longitude: "-65.11148180",
        nameNative: null,
        label: "Chubut",
        cityCode: "AR_U",
      },
      {
        name: "Ciudad Autónoma de Buenos Aires",
        countryCode: "AR",
        latitude: "-34.60368440",
        longitude: "-58.38155910",
        nameNative: null,
        label: "Ciudad Autónoma de Buenos Aires",
        cityCode: "AR_C",
      },
      {
        name: "Corrientes",
        countryCode: "AR",
        latitude: "-27.46921310",
        longitude: "-58.83063490",
        nameNative: null,
        label: "Corrientes",
        cityCode: "AR_W",
      },
      {
        name: "Córdoba",
        countryCode: "AR",
        latitude: "-31.39928760",
        longitude: "-64.26438420",
        nameNative: null,
        label: "Córdoba",
        cityCode: "AR_X",
      },
      {
        name: "Entre Ríos",
        countryCode: "AR",
        latitude: "-31.77466540",
        longitude: "-60.49564610",
        nameNative: null,
        label: "Entre Ríos",
        cityCode: "AR_E",
      },
      {
        name: "Formosa",
        countryCode: "AR",
        latitude: "-26.18948040",
        longitude: "-58.22428060",
        nameNative: null,
        label: "Formosa",
        cityCode: "AR_P",
      },
      {
        name: "Jujuy",
        countryCode: "AR",
        latitude: "-24.18433970",
        longitude: "-65.30217700",
        nameNative: null,
        label: "Jujuy",
        cityCode: "AR_Y",
      },
      {
        name: "La Pampa",
        countryCode: "AR",
        latitude: "-36.61475730",
        longitude: "-64.28392090",
        nameNative: null,
        label: "La Pampa",
        cityCode: "AR_L",
      },
      {
        name: "La Rioja",
        countryCode: "AR",
        latitude: "-29.41937930",
        longitude: "-66.85599320",
        nameNative: null,
        label: "La Rioja",
        cityCode: "AR_F",
      },
      {
        name: "Mendoza",
        countryCode: "AR",
        latitude: "-32.88945870",
        longitude: "-68.84583860",
        nameNative: null,
        label: "Mendoza",
        cityCode: "AR_M",
      },
      {
        name: "Misiones",
        countryCode: "AR",
        latitude: "-27.42692550",
        longitude: "-55.94670760",
        nameNative: null,
        label: "Misiones",
        cityCode: "AR_N",
      },
      {
        name: "Neuquén",
        countryCode: "AR",
        latitude: "-38.94587000",
        longitude: "-68.07309250",
        nameNative: null,
        label: "Neuquén",
        cityCode: "AR_Q",
      },
      {
        name: "Río Negro",
        countryCode: "AR",
        latitude: "-40.82614340",
        longitude: "-63.02663390",
        nameNative: null,
        label: "Río Negro",
        cityCode: "AR_R",
      },
      {
        name: "Salta",
        countryCode: "AR",
        latitude: "-24.79976880",
        longitude: "-65.41503670",
        nameNative: null,
        label: "Salta",
        cityCode: "AR_A",
      },
      {
        name: "San Juan",
        countryCode: "AR",
        latitude: "-31.53169760",
        longitude: "-68.56769620",
        nameNative: null,
        label: "San Juan",
        cityCode: "AR_J",
      },
      {
        name: "San Luis",
        countryCode: "AR",
        latitude: "-33.29620420",
        longitude: "-66.32949480",
        nameNative: null,
        label: "San Luis",
        cityCode: "AR_D",
      },
      {
        name: "Santa Cruz",
        countryCode: "AR",
        latitude: "-51.63528210",
        longitude: "-69.24743530",
        nameNative: null,
        label: "Santa Cruz",
        cityCode: "AR_Z",
      },
      {
        name: "Santa Fe",
        countryCode: "AR",
        latitude: "-31.58551090",
        longitude: "-60.72380160",
        nameNative: null,
        label: "Santa Fe",
        cityCode: "AR_S",
      },
      {
        name: "Santiago del Estero",
        countryCode: "AR",
        latitude: "-27.78335740",
        longitude: "-64.26416700",
        nameNative: null,
        label: "Santiago del Estero",
        cityCode: "AR_G",
      },
      {
        name: "Tierra del Fuego",
        countryCode: "AR",
        latitude: "-54.80539980",
        longitude: "-68.32420610",
        nameNative: null,
        label: "Tierra del Fuego",
        cityCode: "AR_V",
      },
      {
        name: "Tucumán",
        countryCode: "AR",
        latitude: "-26.82211270",
        longitude: "-65.21929030",
        nameNative: null,
        label: "Tucumán",
        cityCode: "AR_T",
      },
    ],
    AM: [
      {
        name: "Aragatsotn Region",
        countryCode: "AM",
        latitude: "40.33473010",
        longitude: "44.37482960",
        nameNative: null,
        label: "Aragatsotn Region",
        cityCode: "AM_AG",
      },
      {
        name: "Ararat Province",
        countryCode: "AM",
        latitude: "39.91394150",
        longitude: "44.72000040",
        nameNative: null,
        label: "Ararat Province",
        cityCode: "AM_AR",
      },
      {
        name: "Armavir Region",
        countryCode: "AM",
        latitude: "40.15546310",
        longitude: "44.03724460",
        nameNative: null,
        label: "Armavir Region",
        cityCode: "AM_AV",
      },
      {
        name: "Gegharkunik Province",
        countryCode: "AM",
        latitude: "40.35264260",
        longitude: "45.12604140",
        nameNative: null,
        label: "Gegharkunik Province",
        cityCode: "AM_GR",
      },
      {
        name: "Kotayk Region",
        countryCode: "AM",
        latitude: "40.54102140",
        longitude: "44.76901480",
        nameNative: null,
        label: "Kotayk Region",
        cityCode: "AM_KT",
      },
      {
        name: "Lori Region",
        countryCode: "AM",
        latitude: "40.96984520",
        longitude: "44.49001380",
        nameNative: null,
        label: "Lori Region",
        cityCode: "AM_LO",
      },
      {
        name: "Shirak Region",
        countryCode: "AM",
        latitude: "40.96308140",
        longitude: "43.81024610",
        nameNative: null,
        label: "Shirak Region",
        cityCode: "AM_SH",
      },
      {
        name: "Syunik Province",
        countryCode: "AM",
        latitude: "39.51331120",
        longitude: "46.33932340",
        nameNative: null,
        label: "Syunik Province",
        cityCode: "AM_SU",
      },
      {
        name: "Tavush Region",
        countryCode: "AM",
        latitude: "40.88662960",
        longitude: "45.33934900",
        nameNative: null,
        label: "Tavush Region",
        cityCode: "AM_TV",
      },
      {
        name: "Vayots Dzor Region",
        countryCode: "AM",
        latitude: "39.76419960",
        longitude: "45.33375280",
        nameNative: null,
        label: "Vayots Dzor Region",
        cityCode: "AM_VD",
      },
      {
        name: "Yerevan",
        countryCode: "AM",
        latitude: "40.18720230",
        longitude: "44.51520900",
        nameNative: null,
        label: "Yerevan",
        cityCode: "AM_ER",
      },
    ],
    AU: [
      {
        name: "Australian Capital Territory",
        countryCode: "AU",
        latitude: "-35.47346790",
        longitude: "149.01236790",
        nameNative: null,
        label: "Australian Capital Territory",
        cityCode: "AU_ACT",
      },
      {
        name: "New South Wales",
        countryCode: "AU",
        latitude: "-31.25321830",
        longitude: "146.92109900",
        nameNative: null,
        label: "New South Wales",
        cityCode: "AU_NSW",
      },
      {
        name: "Northern Territory",
        countryCode: "AU",
        latitude: "-19.49141080",
        longitude: "132.55096030",
        nameNative: null,
        label: "Northern Territory",
        cityCode: "AU_NT",
      },
      {
        name: "Queensland",
        countryCode: "AU",
        latitude: "-20.91757380",
        longitude: "142.70279560",
        nameNative: null,
        label: "Queensland",
        cityCode: "AU_QLD",
      },
      {
        name: "South Australia",
        countryCode: "AU",
        latitude: "-30.00023150",
        longitude: "136.20915470",
        nameNative: null,
        label: "South Australia",
        cityCode: "AU_SA",
      },
      {
        name: "Tasmania",
        countryCode: "AU",
        latitude: "-41.45451960",
        longitude: "145.97066470",
        nameNative: null,
        label: "Tasmania",
        cityCode: "AU_TAS",
      },
      {
        name: "Victoria",
        countryCode: "AU",
        latitude: "-36.48564230",
        longitude: "140.97794250",
        nameNative: null,
        label: "Victoria",
        cityCode: "AU_VIC",
      },
      {
        name: "Western Australia",
        countryCode: "AU",
        latitude: "-27.67281680",
        longitude: "121.62830980",
        nameNative: null,
        label: "Western Australia",
        cityCode: "AU_WA",
      },
    ],
    AT: [
      {
        name: "Burgenland",
        countryCode: "AT",
        latitude: "47.15371650",
        longitude: "16.26887970",
        nameNative: null,
        label: "Burgenland",
        cityCode: "AT_1",
      },
      {
        name: "Carinthia",
        countryCode: "AT",
        latitude: "46.72220300",
        longitude: "14.18058820",
        nameNative: null,
        label: "Carinthia",
        cityCode: "AT_2",
      },
      {
        name: "Lower Austria",
        countryCode: "AT",
        latitude: "48.10807700",
        longitude: "15.80495580",
        nameNative: null,
        label: "Lower Austria",
        cityCode: "AT_3",
      },
      {
        name: "Salzburg",
        countryCode: "AT",
        latitude: "47.80949000",
        longitude: "13.05501000",
        nameNative: null,
        label: "Salzburg",
        cityCode: "AT_5",
      },
      {
        name: "Styria",
        countryCode: "AT",
        latitude: "47.35934420",
        longitude: "14.46998270",
        nameNative: null,
        label: "Styria",
        cityCode: "AT_6",
      },
      {
        name: "Tyrol",
        countryCode: "AT",
        latitude: "47.25374140",
        longitude: "11.60148700",
        nameNative: null,
        label: "Tyrol",
        cityCode: "AT_7",
      },
      {
        name: "Upper Austria",
        countryCode: "AT",
        latitude: "48.02585400",
        longitude: "13.97236650",
        nameNative: null,
        label: "Upper Austria",
        cityCode: "AT_4",
      },
      {
        name: "Vienna",
        countryCode: "AT",
        latitude: "48.20817430",
        longitude: "16.37381890",
        nameNative: null,
        label: "Vienna",
        cityCode: "AT_9",
      },
      {
        name: "Vorarlberg",
        countryCode: "AT",
        latitude: "47.24974270",
        longitude: "9.97973730",
        nameNative: null,
        label: "Vorarlberg",
        cityCode: "AT_8",
      },
    ],
    AZ: [
      {
        name: "Absheron District",
        countryCode: "AZ",
        latitude: "40.36296930",
        longitude: "49.27368150",
        nameNative: null,
        label: "Absheron District",
        cityCode: "AZ_ABS",
      },
      {
        name: "Agdam District",
        countryCode: "AZ",
        latitude: "39.99318530",
        longitude: "46.99495620",
        nameNative: null,
        label: "Agdam District",
        cityCode: "AZ_AGM",
      },
      {
        name: "Agdash District",
        countryCode: "AZ",
        latitude: "40.63354270",
        longitude: "47.46743100",
        nameNative: null,
        label: "Agdash District",
        cityCode: "AZ_AGS",
      },
      {
        name: "Aghjabadi District",
        countryCode: "AZ",
        latitude: "28.78918410",
        longitude: "77.51607880",
        nameNative: null,
        label: "Aghjabadi District",
        cityCode: "AZ_AGC",
      },
      {
        name: "Agstafa District",
        countryCode: "AZ",
        latitude: "41.26559330",
        longitude: "45.51342910",
        nameNative: null,
        label: "Agstafa District",
        cityCode: "AZ_AGA",
      },
      {
        name: "Agsu District",
        countryCode: "AZ",
        latitude: "40.52833390",
        longitude: "48.36508350",
        nameNative: null,
        label: "Agsu District",
        cityCode: "AZ_AGU",
      },
      {
        name: "Astara District",
        countryCode: "AZ",
        latitude: "38.49378450",
        longitude: "48.69443650",
        nameNative: null,
        label: "Astara District",
        cityCode: "AZ_AST",
      },
      {
        name: "Babek District",
        countryCode: "AZ",
        latitude: "39.15076130",
        longitude: "45.44853680",
        nameNative: null,
        label: "Babek District",
        cityCode: "AZ_BAB",
      },
      {
        name: "Baku",
        countryCode: "AZ",
        latitude: "40.40926170",
        longitude: "49.86709240",
        nameNative: null,
        label: "Baku",
        cityCode: "AZ_BA",
      },
      {
        name: "Balakan District",
        countryCode: "AZ",
        latitude: "41.70375090",
        longitude: "46.40442130",
        nameNative: null,
        label: "Balakan District",
        cityCode: "AZ_BAL",
      },
      {
        name: "Barda District",
        countryCode: "AZ",
        latitude: "40.37065550",
        longitude: "47.13789090",
        nameNative: null,
        label: "Barda District",
        cityCode: "AZ_BAR",
      },
      {
        name: "Beylagan District",
        countryCode: "AZ",
        latitude: "39.77230730",
        longitude: "47.61541660",
        nameNative: null,
        label: "Beylagan District",
        cityCode: "AZ_BEY",
      },
      {
        name: "Bilasuvar District",
        countryCode: "AZ",
        latitude: "39.45988330",
        longitude: "48.55098130",
        nameNative: null,
        label: "Bilasuvar District",
        cityCode: "AZ_BIL",
      },
      {
        name: "Dashkasan District",
        countryCode: "AZ",
        latitude: "40.52022570",
        longitude: "46.07793040",
        nameNative: null,
        label: "Dashkasan District",
        cityCode: "AZ_DAS",
      },
      {
        name: "Fizuli District",
        countryCode: "AZ",
        latitude: "39.53786050",
        longitude: "47.30338770",
        nameNative: null,
        label: "Fizuli District",
        cityCode: "AZ_FUZ",
      },
      {
        name: "Ganja",
        countryCode: "AZ",
        latitude: "36.36873380",
        longitude: "-95.99857670",
        nameNative: null,
        label: "Ganja",
        cityCode: "AZ_GA",
      },
      {
        name: "Gobustan District",
        countryCode: "AZ",
        latitude: "40.53261040",
        longitude: "48.92737500",
        nameNative: null,
        label: "Gobustan District",
        cityCode: "AZ_QOB",
      },
      {
        name: "Goranboy District",
        countryCode: "AZ",
        latitude: "40.53805060",
        longitude: "46.59908910",
        nameNative: null,
        label: "Goranboy District",
        cityCode: "AZ_GOR",
      },
      {
        name: "Goychay",
        countryCode: "AZ",
        latitude: "40.62361680",
        longitude: "47.74030340",
        nameNative: null,
        label: "Goychay",
        cityCode: "AZ_GOY",
      },
      {
        name: "Goygol District",
        countryCode: "AZ",
        latitude: "40.55953780",
        longitude: "46.33149530",
        nameNative: null,
        label: "Goygol District",
        cityCode: "AZ_GYG",
      },
      {
        name: "Gədəbəy",
        countryCode: "AZ",
        latitude: "40.56996390",
        longitude: "45.81068830",
        nameNative: null,
        label: "Gədəbəy",
        cityCode: "AZ_GAD",
      },
      {
        name: "Hajigabul District",
        countryCode: "AZ",
        latitude: "40.03937700",
        longitude: "48.92025330",
        nameNative: null,
        label: "Hajigabul District",
        cityCode: "AZ_HAC",
      },
      {
        name: "Imishli District",
        countryCode: "AZ",
        latitude: "39.86946860",
        longitude: "48.06652180",
        nameNative: null,
        label: "Imishli District",
        cityCode: "AZ_IMI",
      },
      {
        name: "Ismailli District",
        countryCode: "AZ",
        latitude: "40.74299360",
        longitude: "48.21255560",
        nameNative: null,
        label: "Ismailli District",
        cityCode: "AZ_ISM",
      },
      {
        name: "Jabrayil District",
        countryCode: "AZ",
        latitude: "39.26455440",
        longitude: "46.96215620",
        nameNative: null,
        label: "Jabrayil District",
        cityCode: "AZ_CAB",
      },
      {
        name: "Jalilabad District",
        countryCode: "AZ",
        latitude: "39.20516320",
        longitude: "48.51006040",
        nameNative: null,
        label: "Jalilabad District",
        cityCode: "AZ_CAL",
      },
      {
        name: "Julfa District",
        countryCode: "AZ",
        latitude: "38.96049830",
        longitude: "45.62929390",
        nameNative: null,
        label: "Julfa District",
        cityCode: "AZ_CUL",
      },
      {
        name: "Kalbajar District",
        countryCode: "AZ",
        latitude: "40.10243290",
        longitude: "46.03648720",
        nameNative: null,
        label: "Kalbajar District",
        cityCode: "AZ_KAL",
      },
      {
        name: "Kangarli District",
        countryCode: "AZ",
        latitude: "39.38719400",
        longitude: "45.16398520",
        nameNative: null,
        label: "Kangarli District",
        cityCode: "AZ_KAN",
      },
      {
        name: "Khachmaz District",
        countryCode: "AZ",
        latitude: "41.45911680",
        longitude: "48.80206260",
        nameNative: null,
        label: "Khachmaz District",
        cityCode: "AZ_XAC",
      },
      {
        name: "Khizi District",
        countryCode: "AZ",
        latitude: "40.91094890",
        longitude: "49.07292640",
        nameNative: null,
        label: "Khizi District",
        cityCode: "AZ_XIZ",
      },
      {
        name: "Khojali District",
        countryCode: "AZ",
        latitude: "39.91325530",
        longitude: "46.79430500",
        nameNative: null,
        label: "Khojali District",
        cityCode: "AZ_XCI",
      },
      {
        name: "Kurdamir District",
        countryCode: "AZ",
        latitude: "40.36986510",
        longitude: "48.16446260",
        nameNative: null,
        label: "Kurdamir District",
        cityCode: "AZ_KUR",
      },
      {
        name: "Lachin District",
        countryCode: "AZ",
        latitude: "39.63834140",
        longitude: "46.54608530",
        nameNative: null,
        label: "Lachin District",
        cityCode: "AZ_LAC",
      },
      {
        name: "Lankaran District",
        countryCode: "AZ",
        latitude: "38.75286690",
        longitude: "48.84750150",
        nameNative: null,
        label: "Lankaran District",
        cityCode: "AZ_LA",
      },
      {
        name: "Lankaran",
        countryCode: "AZ",
        latitude: "38.75286690",
        longitude: "48.84750150",
        nameNative: null,
        label: "Lankaran",
        cityCode: "AZ_LAN",
      },
      {
        name: "Lerik District",
        countryCode: "AZ",
        latitude: "38.77361920",
        longitude: "48.41514830",
        nameNative: null,
        label: "Lerik District",
        cityCode: "AZ_LER",
      },
      {
        name: "Martuni",
        countryCode: "AZ",
        latitude: "39.79146930",
        longitude: "47.11008140",
        nameNative: null,
        label: "Martuni",
        cityCode: "AZ_XVD",
      },
      {
        name: "Masally District",
        countryCode: "AZ",
        latitude: "39.03407220",
        longitude: "48.65893540",
        nameNative: null,
        label: "Masally District",
        cityCode: "AZ_MAS",
      },
      {
        name: "Mingachevir",
        countryCode: "AZ",
        latitude: "40.77025630",
        longitude: "47.04960150",
        nameNative: null,
        label: "Mingachevir",
        cityCode: "AZ_MI",
      },
      {
        name: "Nakhchivan Autonomous Republic",
        countryCode: "AZ",
        latitude: "39.32568140",
        longitude: "45.49126480",
        nameNative: null,
        label: "Nakhchivan Autonomous Republic",
        cityCode: "AZ_NX",
      },
      {
        name: "Neftchala District",
        countryCode: "AZ",
        latitude: "39.38810520",
        longitude: "49.24137430",
        nameNative: null,
        label: "Neftchala District",
        cityCode: "AZ_NEF",
      },
      {
        name: "Oghuz District",
        countryCode: "AZ",
        latitude: "41.07279240",
        longitude: "47.46506720",
        nameNative: null,
        label: "Oghuz District",
        cityCode: "AZ_OGU",
      },
      {
        name: "Ordubad District",
        countryCode: "AZ",
        latitude: "38.90216220",
        longitude: "46.02376250",
        nameNative: null,
        label: "Ordubad District",
        cityCode: "AZ_ORD",
      },
      {
        name: "Qabala District",
        countryCode: "AZ",
        latitude: "40.92539250",
        longitude: "47.80161060",
        nameNative: null,
        label: "Qabala District",
        cityCode: "AZ_QAB",
      },
      {
        name: "Qakh District",
        countryCode: "AZ",
        latitude: "41.42068270",
        longitude: "46.93201840",
        nameNative: null,
        label: "Qakh District",
        cityCode: "AZ_QAX",
      },
      {
        name: "Qazakh District",
        countryCode: "AZ",
        latitude: "41.09710740",
        longitude: "45.35163310",
        nameNative: null,
        label: "Qazakh District",
        cityCode: "AZ_QAZ",
      },
      {
        name: "Quba District",
        countryCode: "AZ",
        latitude: "41.15642420",
        longitude: "48.41350210",
        nameNative: null,
        label: "Quba District",
        cityCode: "AZ_QBA",
      },
      {
        name: "Qubadli District",
        countryCode: "AZ",
        latitude: "39.27139960",
        longitude: "46.63543120",
        nameNative: null,
        label: "Qubadli District",
        cityCode: "AZ_QBI",
      },
      {
        name: "Qusar District",
        countryCode: "AZ",
        latitude: "41.42668860",
        longitude: "48.43455770",
        nameNative: null,
        label: "Qusar District",
        cityCode: "AZ_QUS",
      },
      {
        name: "Saatly District",
        countryCode: "AZ",
        latitude: "39.90955030",
        longitude: "48.35951220",
        nameNative: null,
        label: "Saatly District",
        cityCode: "AZ_SAT",
      },
      {
        name: "Sabirabad District",
        countryCode: "AZ",
        latitude: "39.98706630",
        longitude: "48.46925450",
        nameNative: null,
        label: "Sabirabad District",
        cityCode: "AZ_SAB",
      },
      {
        name: "Sadarak District",
        countryCode: "AZ",
        latitude: "39.71051140",
        longitude: "44.88642770",
        nameNative: null,
        label: "Sadarak District",
        cityCode: "AZ_SAD",
      },
      {
        name: "Salyan District",
        countryCode: "AZ",
        latitude: "28.35248110",
        longitude: "82.12784000",
        nameNative: null,
        label: "Salyan District",
        cityCode: "AZ_SAL",
      },
      {
        name: "Samukh District",
        countryCode: "AZ",
        latitude: "40.76046310",
        longitude: "46.40631810",
        nameNative: null,
        label: "Samukh District",
        cityCode: "AZ_SMX",
      },
      {
        name: "Shabran District",
        countryCode: "AZ",
        latitude: "41.22283760",
        longitude: "48.84573040",
        nameNative: null,
        label: "Shabran District",
        cityCode: "AZ_SBN",
      },
      {
        name: "Shahbuz District",
        countryCode: "AZ",
        latitude: "39.44521030",
        longitude: "45.65680090",
        nameNative: null,
        label: "Shahbuz District",
        cityCode: "AZ_SAH",
      },
      {
        name: "Shaki District",
        countryCode: "AZ",
        latitude: "41.11346620",
        longitude: "47.13169270",
        nameNative: null,
        label: "Shaki District",
        cityCode: "AZ_SAK",
      },
      {
        name: "Shaki",
        countryCode: "AZ",
        latitude: "41.19747530",
        longitude: "47.15712410",
        nameNative: null,
        label: "Shaki",
        cityCode: "AZ_SA",
      },
      {
        name: "Shamakhi District",
        countryCode: "AZ",
        latitude: "40.63187310",
        longitude: "48.63638010",
        nameNative: null,
        label: "Shamakhi District",
        cityCode: "AZ_SMI",
      },
      {
        name: "Shamkir District",
        countryCode: "AZ",
        latitude: "40.82881440",
        longitude: "46.01668790",
        nameNative: null,
        label: "Shamkir District",
        cityCode: "AZ_SKR",
      },
      {
        name: "Sharur District",
        countryCode: "AZ",
        latitude: "39.55363320",
        longitude: "44.98456800",
        nameNative: null,
        label: "Sharur District",
        cityCode: "AZ_SAR",
      },
      {
        name: "Shirvan",
        countryCode: "AZ",
        latitude: "39.94697070",
        longitude: "48.92239190",
        nameNative: null,
        label: "Shirvan",
        cityCode: "AZ_SR",
      },
      {
        name: "Shusha District",
        countryCode: "AZ",
        latitude: "39.75374380",
        longitude: "46.74647550",
        nameNative: null,
        label: "Shusha District",
        cityCode: "AZ_SUS",
      },
      {
        name: "Siazan District",
        countryCode: "AZ",
        latitude: "41.07838330",
        longitude: "49.11184770",
        nameNative: null,
        label: "Siazan District",
        cityCode: "AZ_SIY",
      },
      {
        name: "Sumqayit",
        countryCode: "AZ",
        latitude: "40.58547650",
        longitude: "49.63174110",
        nameNative: null,
        label: "Sumqayit",
        cityCode: "AZ_SM",
      },
      {
        name: "Tartar District",
        countryCode: "AZ",
        latitude: "40.34438750",
        longitude: "46.93765190",
        nameNative: null,
        label: "Tartar District",
        cityCode: "AZ_TAR",
      },
      {
        name: "Tovuz District",
        countryCode: "AZ",
        latitude: "40.99545230",
        longitude: "45.61659070",
        nameNative: null,
        label: "Tovuz District",
        cityCode: "AZ_TOV",
      },
      {
        name: "Ujar District",
        countryCode: "AZ",
        latitude: "40.50675250",
        longitude: "47.64896410",
        nameNative: null,
        label: "Ujar District",
        cityCode: "AZ_UCA",
      },
      {
        name: "Yardymli District",
        countryCode: "AZ",
        latitude: "38.90589170",
        longitude: "48.24961270",
        nameNative: null,
        label: "Yardymli District",
        cityCode: "AZ_YAR",
      },
      {
        name: "Yevlakh District",
        countryCode: "AZ",
        latitude: "40.61966380",
        longitude: "47.15003240",
        nameNative: null,
        label: "Yevlakh District",
        cityCode: "AZ_YEV",
      },
      {
        name: "Yevlakh",
        countryCode: "AZ",
        latitude: "40.61966380",
        longitude: "47.15003240",
        nameNative: null,
        label: "Yevlakh",
        cityCode: "AZ_YE",
      },
      {
        name: "Zangilan District",
        countryCode: "AZ",
        latitude: "39.08568990",
        longitude: "46.65247280",
        nameNative: null,
        label: "Zangilan District",
        cityCode: "AZ_ZAN",
      },
      {
        name: "Zaqatala District",
        countryCode: "AZ",
        latitude: "41.59068890",
        longitude: "46.72403730",
        nameNative: null,
        label: "Zaqatala District",
        cityCode: "AZ_ZAQ",
      },
      {
        name: "Zardab District",
        countryCode: "AZ",
        latitude: "40.21481140",
        longitude: "47.71494400",
        nameNative: null,
        label: "Zardab District",
        cityCode: "AZ_ZAR",
      },
    ],
    BS: [
      {
        name: "Acklins and Crooked Islands",
        countryCode: "BS",
        latitude: "22.36577080",
        longitude: "-74.05351260",
        nameNative: null,
        label: "Acklins and Crooked Islands",
        cityCode: "BS_AC",
      },
      {
        name: "Acklins",
        countryCode: "BS",
        latitude: "22.36577080",
        longitude: "-74.05351260",
        nameNative: null,
        label: "Acklins",
        cityCode: "BS_AK",
      },
      {
        name: "Berry Islands",
        countryCode: "BS",
        latitude: "25.62500420",
        longitude: "-77.82522030",
        nameNative: null,
        label: "Berry Islands",
        cityCode: "BS_BY",
      },
      {
        name: "Bimini",
        countryCode: "BS",
        latitude: "24.64153250",
        longitude: "-79.85062260",
        nameNative: null,
        label: "Bimini",
        cityCode: "BS_BI",
      },
      {
        name: "Black Point",
        countryCode: "BS",
        latitude: "41.39510240",
        longitude: "-71.46505560",
        nameNative: null,
        label: "Black Point",
        cityCode: "BS_BP",
      },
      {
        name: "Cat Island",
        countryCode: "BS",
        latitude: "30.22801360",
        longitude: "-89.10149330",
        nameNative: null,
        label: "Cat Island",
        cityCode: "BS_CI",
      },
      {
        name: "Central Abaco",
        countryCode: "BS",
        latitude: "26.35550290",
        longitude: "-77.14851630",
        nameNative: null,
        label: "Central Abaco",
        cityCode: "BS_CO",
      },
      {
        name: "Central Andros",
        countryCode: "BS",
        latitude: "24.46884820",
        longitude: "-77.97386500",
        nameNative: null,
        label: "Central Andros",
        cityCode: "BS_CS",
      },
      {
        name: "Central Eleuthera",
        countryCode: "BS",
        latitude: "25.13620370",
        longitude: "-76.14359150",
        nameNative: null,
        label: "Central Eleuthera",
        cityCode: "BS_CE",
      },
      {
        name: "Crooked Island",
        countryCode: "BS",
        latitude: "22.63909820",
        longitude: "-74.00650900",
        nameNative: null,
        label: "Crooked Island",
        cityCode: "BS_CK",
      },
      {
        name: "East Grand Bahama",
        countryCode: "BS",
        latitude: "26.65828230",
        longitude: "-78.22482910",
        nameNative: null,
        label: "East Grand Bahama",
        cityCode: "BS_EG",
      },
      {
        name: "Exuma",
        countryCode: "BS",
        latitude: "23.61925980",
        longitude: "-75.96954650",
        nameNative: null,
        label: "Exuma",
        cityCode: "BS_EX",
      },
      {
        name: "Freeport",
        countryCode: "BS",
        latitude: "42.29668610",
        longitude: "-89.62122710",
        nameNative: null,
        label: "Freeport",
        cityCode: "BS_FP",
      },
      {
        name: "Fresh Creek",
        countryCode: "BS",
        latitude: "40.65437560",
        longitude: "-73.89479390",
        nameNative: null,
        label: "Fresh Creek",
        cityCode: "BS_FC",
      },
      {
        name: "Governor's Harbour",
        countryCode: "BS",
        latitude: "25.19480960",
        longitude: "-76.24396220",
        nameNative: null,
        label: "Governor's Harbour",
        cityCode: "BS_GH",
      },
      {
        name: "Grand Cay",
        countryCode: "BS",
        latitude: "27.21626150",
        longitude: "-78.32305590",
        nameNative: null,
        label: "Grand Cay",
        cityCode: "BS_GC",
      },
      {
        name: "Green Turtle Cay",
        countryCode: "BS",
        latitude: "26.77471070",
        longitude: "-77.32957080",
        nameNative: null,
        label: "Green Turtle Cay",
        cityCode: "BS_GT",
      },
      {
        name: "Harbour Island",
        countryCode: "BS",
        latitude: "25.50011000",
        longitude: "-76.63405110",
        nameNative: null,
        label: "Harbour Island",
        cityCode: "BS_HI",
      },
      {
        name: "High Rock",
        countryCode: "BS",
        latitude: "46.68434150",
        longitude: "-121.90174610",
        nameNative: null,
        label: "High Rock",
        cityCode: "BS_HR",
      },
      {
        name: "Hope Town",
        countryCode: "BS",
        latitude: "26.50095040",
        longitude: "-76.99598720",
        nameNative: null,
        label: "Hope Town",
        cityCode: "BS_HT",
      },
      {
        name: "Inagua",
        countryCode: "BS",
        latitude: "21.06560660",
        longitude: "-73.32370800",
        nameNative: null,
        label: "Inagua",
        cityCode: "BS_IN",
      },
      {
        name: "Kemps Bay",
        countryCode: "BS",
        latitude: "24.02364000",
        longitude: "-77.54534900",
        nameNative: null,
        label: "Kemps Bay",
        cityCode: "BS_KB",
      },
      {
        name: "Long Island",
        countryCode: "BS",
        latitude: "40.78914200",
        longitude: "-73.13496100",
        nameNative: null,
        label: "Long Island",
        cityCode: "BS_LI",
      },
      {
        name: "Mangrove Cay",
        countryCode: "BS",
        latitude: "24.14814250",
        longitude: "-77.76809520",
        nameNative: null,
        label: "Mangrove Cay",
        cityCode: "BS_MC",
      },
      {
        name: "Marsh Harbour",
        countryCode: "BS",
        latitude: "26.52416530",
        longitude: "-77.09098090",
        nameNative: null,
        label: "Marsh Harbour",
        cityCode: "BS_MH",
      },
      {
        name: "Mayaguana District",
        countryCode: "BS",
        latitude: "22.40177140",
        longitude: "-73.06413960",
        nameNative: null,
        label: "Mayaguana District",
        cityCode: "BS_MG",
      },
      {
        name: "New Providence",
        countryCode: "BS",
        latitude: "40.69843480",
        longitude: "-74.40154050",
        nameNative: null,
        label: "New Providence",
        cityCode: "BS_NP",
      },
      {
        name: "Nichollstown and Berry Islands",
        countryCode: "BS",
        latitude: "25.72362340",
        longitude: "-77.83101040",
        nameNative: null,
        label: "Nichollstown and Berry Islands",
        cityCode: "BS_NB",
      },
      {
        name: "North Abaco",
        countryCode: "BS",
        latitude: "26.78716970",
        longitude: "-77.43577390",
        nameNative: null,
        label: "North Abaco",
        cityCode: "BS_NO",
      },
      {
        name: "North Andros",
        countryCode: "BS",
        latitude: "24.70638050",
        longitude: "-78.01953870",
        nameNative: null,
        label: "North Andros",
        cityCode: "BS_NS",
      },
      {
        name: "North Eleuthera",
        countryCode: "BS",
        latitude: "25.46475170",
        longitude: "-76.67592200",
        nameNative: null,
        label: "North Eleuthera",
        cityCode: "BS_NE",
      },
      {
        name: "Ragged Island",
        countryCode: "BS",
        latitude: "41.59743100",
        longitude: "-71.26020200",
        nameNative: null,
        label: "Ragged Island",
        cityCode: "BS_RI",
      },
      {
        name: "Rock Sound",
        countryCode: "BS",
        latitude: "39.01424430",
        longitude: "-95.67089890",
        nameNative: null,
        label: "Rock Sound",
        cityCode: "BS_RS",
      },
      {
        name: "Rum Cay District",
        countryCode: "BS",
        latitude: "23.68546760",
        longitude: "-74.83901620",
        nameNative: null,
        label: "Rum Cay District",
        cityCode: "BS_RC",
      },
      {
        name: "San Salvador Island",
        countryCode: "BS",
        latitude: "24.07755460",
        longitude: "-74.47600880",
        nameNative: null,
        label: "San Salvador Island",
        cityCode: "BS_SS",
      },
      {
        name: "San Salvador and Rum Cay",
        countryCode: "BS",
        latitude: "23.68546760",
        longitude: "-74.83901620",
        nameNative: null,
        label: "San Salvador and Rum Cay",
        cityCode: "BS_SR",
      },
      {
        name: "Sandy Point",
        countryCode: "BS",
        latitude: "39.01454640",
        longitude: "-76.39989250",
        nameNative: null,
        label: "Sandy Point",
        cityCode: "BS_SP",
      },
      {
        name: "South Abaco",
        countryCode: "BS",
        latitude: "26.06405910",
        longitude: "-77.26350380",
        nameNative: null,
        label: "South Abaco",
        cityCode: "BS_SO",
      },
      {
        name: "South Andros",
        countryCode: "BS",
        latitude: "23.97135560",
        longitude: "-77.60778650",
        nameNative: null,
        label: "South Andros",
        cityCode: "BS_SA",
      },
      {
        name: "South Eleuthera",
        countryCode: "BS",
        latitude: "24.77085620",
        longitude: "-76.21314740",
        nameNative: null,
        label: "South Eleuthera",
        cityCode: "BS_SE",
      },
      {
        name: "Spanish Wells",
        countryCode: "BS",
        latitude: "26.32505990",
        longitude: "-81.79803280",
        nameNative: null,
        label: "Spanish Wells",
        cityCode: "BS_SW",
      },
      {
        name: "West Grand Bahama",
        countryCode: "BS",
        latitude: "26.65944700",
        longitude: "-78.52065000",
        nameNative: null,
        label: "West Grand Bahama",
        cityCode: "BS_WG",
      },
    ],
    BH: [
      {
        name: "Capital Governorate",
        countryCode: "BH",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Capital Governorate",
        cityCode: "BH_13",
      },
      {
        name: "Central Governorate",
        countryCode: "BH",
        latitude: "26.14260930",
        longitude: "50.56532940",
        nameNative: null,
        label: "Central Governorate",
        cityCode: "BH_16",
      },
      {
        name: "Muharraq Governorate",
        countryCode: "BH",
        latitude: "26.26856530",
        longitude: "50.64825170",
        nameNative: null,
        label: "Muharraq Governorate",
        cityCode: "BH_15",
      },
      {
        name: "Northern Governorate",
        countryCode: "BH",
        latitude: "26.15519140",
        longitude: "50.48251730",
        nameNative: null,
        label: "Northern Governorate",
        cityCode: "BH_17",
      },
      {
        name: "Southern Governorate",
        countryCode: "BH",
        latitude: "25.93810180",
        longitude: "50.57568870",
        nameNative: null,
        label: "Southern Governorate",
        cityCode: "BH_14",
      },
    ],
    BD: [
      {
        name: "Bagerhat District",
        countryCode: "BD",
        latitude: "22.66024360",
        longitude: "89.78954780",
        nameNative: null,
        label: "Bagerhat District",
        cityCode: "BD_05",
      },
      {
        name: "Bahadia",
        countryCode: "BD",
        latitude: "23.78987120",
        longitude: "90.16714830",
        nameNative: null,
        label: "Bahadia",
        cityCode: "BD_33",
      },
      {
        name: "Bandarban District",
        countryCode: "BD",
        latitude: "21.83110020",
        longitude: "92.36863210",
        nameNative: null,
        label: "Bandarban District",
        cityCode: "BD_01",
      },
      {
        name: "Barguna District",
        countryCode: "BD",
        latitude: "22.09529150",
        longitude: "90.11206960",
        nameNative: null,
        label: "Barguna District",
        cityCode: "BD_02",
      },
      {
        name: "Barisal District",
        countryCode: "BD",
        latitude: "22.70220980",
        longitude: "90.36963160",
        nameNative: null,
        label: "Barisal District",
        cityCode: "BD_06",
      },
      {
        name: "Barisal Division",
        countryCode: "BD",
        latitude: "22.38111310",
        longitude: "90.33718890",
        nameNative: null,
        label: "Barisal Division",
        cityCode: "BD_A",
      },
      {
        name: "Bhola District",
        countryCode: "BD",
        latitude: "22.17853150",
        longitude: "90.71010230",
        nameNative: null,
        label: "Bhola District",
        cityCode: "BD_07",
      },
      {
        name: "Bogra District",
        countryCode: "BD",
        latitude: "24.85104020",
        longitude: "89.36972250",
        nameNative: null,
        label: "Bogra District",
        cityCode: "BD_03",
      },
      {
        name: "Brahmanbaria District",
        countryCode: "BD",
        latitude: "23.96081810",
        longitude: "91.11150140",
        nameNative: null,
        label: "Brahmanbaria District",
        cityCode: "BD_04",
      },
      {
        name: "Chandpur District",
        countryCode: "BD",
        latitude: "23.25131480",
        longitude: "90.85178460",
        nameNative: null,
        label: "Chandpur District",
        cityCode: "BD_09",
      },
      {
        name: "Chapai Nawabganj District",
        countryCode: "BD",
        latitude: "24.74131110",
        longitude: "88.29120690",
        nameNative: null,
        label: "Chapai Nawabganj District",
        cityCode: "BD_45",
      },
      {
        name: "Chittagong District",
        countryCode: "BD",
        latitude: "22.51501050",
        longitude: "91.75388170",
        nameNative: null,
        label: "Chittagong District",
        cityCode: "BD_10",
      },
      {
        name: "Chittagong Division",
        countryCode: "BD",
        latitude: "23.17931570",
        longitude: "91.98815270",
        nameNative: null,
        label: "Chittagong Division",
        cityCode: "BD_B",
      },
      {
        name: "Chuadanga District",
        countryCode: "BD",
        latitude: "23.61605120",
        longitude: "88.82630060",
        nameNative: null,
        label: "Chuadanga District",
        cityCode: "BD_12",
      },
      {
        name: "Comilla District",
        countryCode: "BD",
        latitude: "23.45756670",
        longitude: "91.18089960",
        nameNative: null,
        label: "Comilla District",
        cityCode: "BD_08",
      },
      {
        name: "Cox's Bazar District",
        countryCode: "BD",
        latitude: "21.56406260",
        longitude: "92.02821290",
        nameNative: null,
        label: "Cox's Bazar District",
        cityCode: "BD_11",
      },
      {
        name: "Dhaka District",
        countryCode: "BD",
        latitude: "23.81051400",
        longitude: "90.33718890",
        nameNative: null,
        label: "Dhaka District",
        cityCode: "BD_13",
      },
      {
        name: "Dhaka Division",
        countryCode: "BD",
        latitude: "23.95357420",
        longitude: "90.14949880",
        nameNative: null,
        label: "Dhaka Division",
        cityCode: "BD_C",
      },
      {
        name: "Dinajpur District",
        countryCode: "BD",
        latitude: "25.62791230",
        longitude: "88.63317580",
        nameNative: null,
        label: "Dinajpur District",
        cityCode: "BD_14",
      },
      {
        name: "Faridpur District",
        countryCode: "BD",
        latitude: "23.54239190",
        longitude: "89.63089210",
        nameNative: null,
        label: "Faridpur District",
        cityCode: "BD_15",
      },
      {
        name: "Feni District",
        countryCode: "BD",
        latitude: "22.94087840",
        longitude: "91.40666460",
        nameNative: null,
        label: "Feni District",
        cityCode: "BD_16",
      },
      {
        name: "Gaibandha District",
        countryCode: "BD",
        latitude: "25.32969280",
        longitude: "89.54296520",
        nameNative: null,
        label: "Gaibandha District",
        cityCode: "BD_19",
      },
      {
        name: "Gazipur District",
        countryCode: "BD",
        latitude: "24.09581710",
        longitude: "90.41251810",
        nameNative: null,
        label: "Gazipur District",
        cityCode: "BD_18",
      },
      {
        name: "Gopalganj District",
        countryCode: "BD",
        latitude: "26.48315840",
        longitude: "84.43655000",
        nameNative: null,
        label: "Gopalganj District",
        cityCode: "BD_17",
      },
      {
        name: "Habiganj District",
        countryCode: "BD",
        latitude: "24.47712360",
        longitude: "91.45065650",
        nameNative: null,
        label: "Habiganj District",
        cityCode: "BD_20",
      },
      {
        name: "Jamalpur District",
        countryCode: "BD",
        latitude: "25.08309260",
        longitude: "89.78532180",
        nameNative: null,
        label: "Jamalpur District",
        cityCode: "BD_21",
      },
      {
        name: "Jessore District",
        countryCode: "BD",
        latitude: "23.16340140",
        longitude: "89.21816640",
        nameNative: null,
        label: "Jessore District",
        cityCode: "BD_22",
      },
      {
        name: "Jhalokati District",
        countryCode: "BD",
        latitude: "22.57208000",
        longitude: "90.18696440",
        nameNative: null,
        label: "Jhalokati District",
        cityCode: "BD_25",
      },
      {
        name: "Jhenaidah District",
        countryCode: "BD",
        latitude: "23.54498730",
        longitude: "89.17260310",
        nameNative: null,
        label: "Jhenaidah District",
        cityCode: "BD_23",
      },
      {
        name: "Joypurhat District",
        countryCode: "BD",
        latitude: "25.09473490",
        longitude: "89.09449370",
        nameNative: null,
        label: "Joypurhat District",
        cityCode: "BD_24",
      },
      {
        name: "Khagrachari District",
        countryCode: "BD",
        latitude: "23.13217510",
        longitude: "91.94902100",
        nameNative: null,
        label: "Khagrachari District",
        cityCode: "BD_29",
      },
      {
        name: "Khulna District",
        countryCode: "BD",
        latitude: "22.67377350",
        longitude: "89.39665810",
        nameNative: null,
        label: "Khulna District",
        cityCode: "BD_27",
      },
      {
        name: "Khulna Division",
        countryCode: "BD",
        latitude: "22.80878160",
        longitude: "89.24671910",
        nameNative: null,
        label: "Khulna Division",
        cityCode: "BD_D",
      },
      {
        name: "Kishoreganj District",
        countryCode: "BD",
        latitude: "24.42604570",
        longitude: "90.98206680",
        nameNative: null,
        label: "Kishoreganj District",
        cityCode: "BD_26",
      },
      {
        name: "Kurigram District",
        countryCode: "BD",
        latitude: "25.80724140",
        longitude: "89.62947460",
        nameNative: null,
        label: "Kurigram District",
        cityCode: "BD_28",
      },
      {
        name: "Kushtia District",
        countryCode: "BD",
        latitude: "23.89069950",
        longitude: "89.10993680",
        nameNative: null,
        label: "Kushtia District",
        cityCode: "BD_30",
      },
      {
        name: "Lakshmipur District",
        countryCode: "BD",
        latitude: "22.94467440",
        longitude: "90.82819070",
        nameNative: null,
        label: "Lakshmipur District",
        cityCode: "BD_31",
      },
      {
        name: "Lalmonirhat District",
        countryCode: "BD",
        latitude: "25.99233980",
        longitude: "89.28472510",
        nameNative: null,
        label: "Lalmonirhat District",
        cityCode: "BD_32",
      },
      {
        name: "Madaripur District",
        countryCode: "BD",
        latitude: "23.23933460",
        longitude: "90.18696440",
        nameNative: null,
        label: "Madaripur District",
        cityCode: "BD_36",
      },
      {
        name: "Meherpur District",
        countryCode: "BD",
        latitude: "23.80519910",
        longitude: "88.67235780",
        nameNative: null,
        label: "Meherpur District",
        cityCode: "BD_39",
      },
      {
        name: "Moulvibazar District",
        countryCode: "BD",
        latitude: "24.30953440",
        longitude: "91.73149030",
        nameNative: null,
        label: "Moulvibazar District",
        cityCode: "BD_38",
      },
      {
        name: "Munshiganj District",
        countryCode: "BD",
        latitude: "23.49809310",
        longitude: "90.41266210",
        nameNative: null,
        label: "Munshiganj District",
        cityCode: "BD_35",
      },
      {
        name: "Mymensingh District",
        countryCode: "BD",
        latitude: "24.75385750",
        longitude: "90.40729190",
        nameNative: null,
        label: "Mymensingh District",
        cityCode: "BD_34",
      },
      {
        name: "Mymensingh Division",
        countryCode: "BD",
        latitude: "24.71362000",
        longitude: "90.45023680",
        nameNative: null,
        label: "Mymensingh Division",
        cityCode: "BD_H",
      },
      {
        name: "Naogaon District",
        countryCode: "BD",
        latitude: "24.91315970",
        longitude: "88.75309520",
        nameNative: null,
        label: "Naogaon District",
        cityCode: "BD_48",
      },
      {
        name: "Narail District",
        countryCode: "BD",
        latitude: "23.11629290",
        longitude: "89.58404040",
        nameNative: null,
        label: "Narail District",
        cityCode: "BD_43",
      },
      {
        name: "Narayanganj District",
        countryCode: "BD",
        latitude: "23.71466010",
        longitude: "90.56360900",
        nameNative: null,
        label: "Narayanganj District",
        cityCode: "BD_40",
      },
      {
        name: "Natore District",
        countryCode: "BD",
        latitude: "24.41024300",
        longitude: "89.00761770",
        nameNative: null,
        label: "Natore District",
        cityCode: "BD_44",
      },
      {
        name: "Netrokona District",
        countryCode: "BD",
        latitude: "24.81032840",
        longitude: "90.86564150",
        nameNative: null,
        label: "Netrokona District",
        cityCode: "BD_41",
      },
      {
        name: "Nilphamari District",
        countryCode: "BD",
        latitude: "25.84827980",
        longitude: "88.94141340",
        nameNative: null,
        label: "Nilphamari District",
        cityCode: "BD_46",
      },
      {
        name: "Noakhali District",
        countryCode: "BD",
        latitude: "22.87237890",
        longitude: "91.09731840",
        nameNative: null,
        label: "Noakhali District",
        cityCode: "BD_47",
      },
      {
        name: "Pabna District",
        countryCode: "BD",
        latitude: "24.15850500",
        longitude: "89.44807180",
        nameNative: null,
        label: "Pabna District",
        cityCode: "BD_49",
      },
      {
        name: "Panchagarh District",
        countryCode: "BD",
        latitude: "26.27087050",
        longitude: "88.59517510",
        nameNative: null,
        label: "Panchagarh District",
        cityCode: "BD_52",
      },
      {
        name: "Patuakhali District",
        countryCode: "BD",
        latitude: "22.22486320",
        longitude: "90.45475030",
        nameNative: null,
        label: "Patuakhali District",
        cityCode: "BD_51",
      },
      {
        name: "Pirojpur District",
        countryCode: "BD",
        latitude: "22.57907440",
        longitude: "89.97592640",
        nameNative: null,
        label: "Pirojpur District",
        cityCode: "BD_50",
      },
      {
        name: "Rajbari District",
        countryCode: "BD",
        latitude: "23.71513400",
        longitude: "89.58748190",
        nameNative: null,
        label: "Rajbari District",
        cityCode: "BD_53",
      },
      {
        name: "Rajshahi District",
        countryCode: "BD",
        latitude: "24.37330870",
        longitude: "88.60487160",
        nameNative: null,
        label: "Rajshahi District",
        cityCode: "BD_54",
      },
      {
        name: "Rajshahi Division",
        countryCode: "BD",
        latitude: "24.71057760",
        longitude: "88.94138650",
        nameNative: null,
        label: "Rajshahi Division",
        cityCode: "BD_E",
      },
      {
        name: "Rangamati Hill District",
        countryCode: "BD",
        latitude: "22.73241730",
        longitude: "92.29851340",
        nameNative: null,
        label: "Rangamati Hill District",
        cityCode: "BD_56",
      },
      {
        name: "Rangpur District",
        countryCode: "BD",
        latitude: "25.74679250",
        longitude: "89.25083350",
        nameNative: null,
        label: "Rangpur District",
        cityCode: "BD_55",
      },
      {
        name: "Rangpur Division",
        countryCode: "BD",
        latitude: "25.84833880",
        longitude: "88.94138650",
        nameNative: null,
        label: "Rangpur Division",
        cityCode: "BD_F",
      },
      {
        name: "Satkhira District",
        countryCode: "BD",
        latitude: "22.31548120",
        longitude: "89.11145250",
        nameNative: null,
        label: "Satkhira District",
        cityCode: "BD_58",
      },
      {
        name: "Shariatpur District",
        countryCode: "BD",
        latitude: "23.24232140",
        longitude: "90.43477110",
        nameNative: null,
        label: "Shariatpur District",
        cityCode: "BD_62",
      },
      {
        name: "Sherpur District",
        countryCode: "BD",
        latitude: "25.07462350",
        longitude: "90.14949040",
        nameNative: null,
        label: "Sherpur District",
        cityCode: "BD_57",
      },
      {
        name: "Sirajganj District",
        countryCode: "BD",
        latitude: "24.31411150",
        longitude: "89.56996150",
        nameNative: null,
        label: "Sirajganj District",
        cityCode: "BD_59",
      },
      {
        name: "Sunamganj District",
        countryCode: "BD",
        latitude: "25.07145350",
        longitude: "91.39916270",
        nameNative: null,
        label: "Sunamganj District",
        cityCode: "BD_61",
      },
      {
        name: "Sylhet District",
        countryCode: "BD",
        latitude: "24.89933570",
        longitude: "91.87004730",
        nameNative: null,
        label: "Sylhet District",
        cityCode: "BD_60",
      },
      {
        name: "Sylhet Division",
        countryCode: "BD",
        latitude: "24.70498110",
        longitude: "91.67606910",
        nameNative: null,
        label: "Sylhet Division",
        cityCode: "BD_G",
      },
      {
        name: "Tangail District",
        countryCode: "BD",
        latitude: "24.39174270",
        longitude: "89.99482570",
        nameNative: null,
        label: "Tangail District",
        cityCode: "BD_63",
      },
      {
        name: "Thakurgaon District",
        countryCode: "BD",
        latitude: "26.04183920",
        longitude: "88.42826160",
        nameNative: null,
        label: "Thakurgaon District",
        cityCode: "BD_64",
      },
    ],
    BB: [
      {
        name: "Christ Church",
        countryCode: "BB",
        latitude: "36.00604070",
        longitude: "-95.92112100",
        nameNative: null,
        label: "Christ Church",
        cityCode: "BB_01",
      },
      {
        name: "Saint Andrew",
        countryCode: "BB",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Andrew",
        cityCode: "BB_02",
      },
      {
        name: "Saint George",
        countryCode: "BB",
        latitude: "37.09652780",
        longitude: "-113.56841640",
        nameNative: null,
        label: "Saint George",
        cityCode: "BB_03",
      },
      {
        name: "Saint James",
        countryCode: "BB",
        latitude: "48.52356600",
        longitude: "-1.32378850",
        nameNative: null,
        label: "Saint James",
        cityCode: "BB_04",
      },
      {
        name: "Saint John",
        countryCode: "BB",
        latitude: "45.27331530",
        longitude: "-66.06330800",
        nameNative: null,
        label: "Saint John",
        cityCode: "BB_05",
      },
      {
        name: "Saint Joseph",
        countryCode: "BB",
        latitude: "39.76745780",
        longitude: "-94.84668100",
        nameNative: null,
        label: "Saint Joseph",
        cityCode: "BB_06",
      },
      {
        name: "Saint Lucy",
        countryCode: "BB",
        latitude: "38.76146250",
        longitude: "-77.44914390",
        nameNative: null,
        label: "Saint Lucy",
        cityCode: "BB_07",
      },
      {
        name: "Saint Michael",
        countryCode: "BB",
        latitude: "36.03597700",
        longitude: "-95.84905200",
        nameNative: null,
        label: "Saint Michael",
        cityCode: "BB_08",
      },
      {
        name: "Saint Peter",
        countryCode: "BB",
        latitude: "37.08271190",
        longitude: "-94.51712500",
        nameNative: null,
        label: "Saint Peter",
        cityCode: "BB_09",
      },
      {
        name: "Saint Philip",
        countryCode: "BB",
        latitude: "35.23311400",
        longitude: "-89.43640420",
        nameNative: null,
        label: "Saint Philip",
        cityCode: "BB_10",
      },
      {
        name: "Saint Thomas",
        countryCode: "BB",
        latitude: "18.33809650",
        longitude: "-64.89409460",
        nameNative: null,
        label: "Saint Thomas",
        cityCode: "BB_11",
      },
    ],
    BY: [
      {
        name: "Brest Region",
        countryCode: "BY",
        latitude: "52.52966410",
        longitude: "25.46064800",
        nameNative: null,
        label: "Brest Region",
        cityCode: "BY_BR",
      },
      {
        name: "Gomel Region",
        countryCode: "BY",
        latitude: "52.16487540",
        longitude: "29.13332510",
        nameNative: null,
        label: "Gomel Region",
        cityCode: "BY_HO",
      },
      {
        name: "Grodno Region",
        countryCode: "BY",
        latitude: "53.65999450",
        longitude: "25.34485710",
        nameNative: null,
        label: "Grodno Region",
        cityCode: "BY_HR",
      },
      {
        name: "Minsk Region",
        countryCode: "BY",
        latitude: "54.10678890",
        longitude: "27.41292450",
        nameNative: null,
        label: "Minsk Region",
        cityCode: "BY_MI",
      },
      {
        name: "Minsk",
        countryCode: "BY",
        latitude: "53.90060110",
        longitude: "27.55897200",
        nameNative: null,
        label: "Minsk",
        cityCode: "BY_HM",
      },
      {
        name: "Mogilev Region",
        countryCode: "BY",
        latitude: "53.51017910",
        longitude: "30.40064440",
        nameNative: null,
        label: "Mogilev Region",
        cityCode: "BY_MA",
      },
      {
        name: "Vitebsk Region",
        countryCode: "BY",
        latitude: "55.29598330",
        longitude: "28.75836270",
        nameNative: null,
        label: "Vitebsk Region",
        cityCode: "BY_VI",
      },
    ],
    BE: [
      {
        name: "Antwerp",
        countryCode: "BE",
        latitude: "51.21944750",
        longitude: "4.40246430",
        nameNative: null,
        label: "Antwerp",
        cityCode: "BE_VAN",
      },
      {
        name: "Brussels-Capital Region",
        countryCode: "BE",
        latitude: "50.85034630",
        longitude: "4.35172110",
        nameNative: null,
        label: "Brussels-Capital Region",
        cityCode: "BE_BRU",
      },
      {
        name: "East Flanders",
        countryCode: "BE",
        latitude: "51.03621010",
        longitude: "3.73731240",
        nameNative: null,
        label: "East Flanders",
        cityCode: "BE_VOV",
      },
      {
        name: "Flanders",
        countryCode: "BE",
        latitude: "51.01087060",
        longitude: "3.72646130",
        nameNative: null,
        label: "Flanders",
        cityCode: "BE_VLG",
      },
      {
        name: "Flemish Brabant",
        countryCode: "BE",
        latitude: "50.88154340",
        longitude: "4.56459700",
        nameNative: null,
        label: "Flemish Brabant",
        cityCode: "BE_VBR",
      },
      {
        name: "Hainaut",
        countryCode: "BE",
        latitude: "50.52570760",
        longitude: "4.06210170",
        nameNative: null,
        label: "Hainaut",
        cityCode: "BE_WHT",
      },
      {
        name: "Limburg",
        countryCode: "BE",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Limburg",
        cityCode: "BE_VLI",
      },
      {
        name: "Liège",
        countryCode: "BE",
        latitude: "50.63255740",
        longitude: "5.57966620",
        nameNative: null,
        label: "Liège",
        cityCode: "BE_WLG",
      },
      {
        name: "Luxembourg",
        countryCode: "BE",
        latitude: "49.81527300",
        longitude: "6.12958300",
        nameNative: null,
        label: "Luxembourg",
        cityCode: "BE_WLX",
      },
      {
        name: "Namur",
        countryCode: "BE",
        latitude: "50.46738830",
        longitude: "4.87198540",
        nameNative: null,
        label: "Namur",
        cityCode: "BE_WNA",
      },
      {
        name: "Wallonia",
        countryCode: "BE",
        latitude: "50.41756370",
        longitude: "4.45100660",
        nameNative: null,
        label: "Wallonia",
        cityCode: "BE_WAL",
      },
      {
        name: "Walloon Brabant",
        countryCode: "BE",
        latitude: "50.63324100",
        longitude: "4.52431500",
        nameNative: null,
        label: "Walloon Brabant",
        cityCode: "BE_WBR",
      },
      {
        name: "West Flanders",
        countryCode: "BE",
        latitude: "40.01793340",
        longitude: "-105.28067330",
        nameNative: null,
        label: "West Flanders",
        cityCode: "BE_VWV",
      },
    ],
    BZ: [
      {
        name: "Belize District",
        countryCode: "BZ",
        latitude: "17.56776790",
        longitude: "-88.40160410",
        nameNative: null,
        label: "Belize District",
        cityCode: "BZ_BZ",
      },
      {
        name: "Cayo District",
        countryCode: "BZ",
        latitude: "17.09844450",
        longitude: "-88.94138650",
        nameNative: null,
        label: "Cayo District",
        cityCode: "BZ_CY",
      },
      {
        name: "Corozal District",
        countryCode: "BZ",
        latitude: "18.13492380",
        longitude: "-88.24611830",
        nameNative: null,
        label: "Corozal District",
        cityCode: "BZ_CZL",
      },
      {
        name: "Orange Walk District",
        countryCode: "BZ",
        latitude: "17.76035300",
        longitude: "-88.86469800",
        nameNative: null,
        label: "Orange Walk District",
        cityCode: "BZ_OW",
      },
      {
        name: "Stann Creek District",
        countryCode: "BZ",
        latitude: "16.81166310",
        longitude: "-88.40160410",
        nameNative: null,
        label: "Stann Creek District",
        cityCode: "BZ_SC",
      },
      {
        name: "Toledo District",
        countryCode: "BZ",
        latitude: "16.24919230",
        longitude: "-88.86469800",
        nameNative: null,
        label: "Toledo District",
        cityCode: "BZ_TOL",
      },
    ],
    BJ: [
      {
        name: "Alibori Department",
        countryCode: "BJ",
        latitude: "10.96810930",
        longitude: "2.77798130",
        nameNative: null,
        label: "Alibori Department",
        cityCode: "BJ_AL",
      },
      {
        name: "Atakora Department",
        countryCode: "BJ",
        latitude: "10.79549310",
        longitude: "1.67606910",
        nameNative: null,
        label: "Atakora Department",
        cityCode: "BJ_AK",
      },
      {
        name: "Atlantique Department",
        countryCode: "BJ",
        latitude: "6.65883910",
        longitude: "2.22366670",
        nameNative: null,
        label: "Atlantique Department",
        cityCode: "BJ_AQ",
      },
      {
        name: "Borgou Department",
        countryCode: "BJ",
        latitude: "9.53408640",
        longitude: "2.77798130",
        nameNative: null,
        label: "Borgou Department",
        cityCode: "BJ_BO",
      },
      {
        name: "Collines Department",
        countryCode: "BJ",
        latitude: "8.30222970",
        longitude: "2.30244600",
        nameNative: null,
        label: "Collines Department",
        cityCode: "BJ_CO",
      },
      {
        name: "Donga Department",
        countryCode: "BJ",
        latitude: "9.71918670",
        longitude: "1.67606910",
        nameNative: null,
        label: "Donga Department",
        cityCode: "BJ_DO",
      },
      {
        name: "Kouffo Department",
        countryCode: "BJ",
        latitude: "7.00358940",
        longitude: "1.75388170",
        nameNative: null,
        label: "Kouffo Department",
        cityCode: "BJ_KO",
      },
      {
        name: "Littoral Department",
        countryCode: "BJ",
        latitude: "6.38069730",
        longitude: "2.44063870",
        nameNative: null,
        label: "Littoral Department",
        cityCode: "BJ_LI",
      },
      {
        name: "Mono Department",
        countryCode: "BJ",
        latitude: "37.92186080",
        longitude: "-118.95286450",
        nameNative: null,
        label: "Mono Department",
        cityCode: "BJ_MO",
      },
      {
        name: "Ouémé Department",
        countryCode: "BJ",
        latitude: "6.61481520",
        longitude: "2.49999180",
        nameNative: null,
        label: "Ouémé Department",
        cityCode: "BJ_OU",
      },
      {
        name: "Plateau Department",
        countryCode: "BJ",
        latitude: "7.34451410",
        longitude: "2.53960300",
        nameNative: null,
        label: "Plateau Department",
        cityCode: "BJ_PL",
      },
      {
        name: "Zou Department",
        countryCode: "BJ",
        latitude: "7.34692680",
        longitude: "2.06651970",
        nameNative: null,
        label: "Zou Department",
        cityCode: "BJ_ZO",
      },
    ],
    BM: [
      {
        name: "Devonshire Parish",
        countryCode: "BM",
        latitude: "32.30380620",
        longitude: "-64.76069540",
        nameNative: null,
        label: "Devonshire Parish",
        cityCode: "BM_DEV",
      },
      {
        name: "Hamilton Parish",
        countryCode: "BM",
        latitude: "32.34494320",
        longitude: "-64.72365000",
        nameNative: null,
        label: "Hamilton Parish",
        cityCode: "BM_HA",
      },
      {
        name: "Paget Parish",
        countryCode: "BM",
        latitude: "32.28107400",
        longitude: "-64.77847870",
        nameNative: null,
        label: "Paget Parish",
        cityCode: "BM_PAG",
      },
      {
        name: "Pembroke Parish",
        countryCode: "BM",
        latitude: "32.30076720",
        longitude: "-64.79626300",
        nameNative: null,
        label: "Pembroke Parish",
        cityCode: "BM_PEM",
      },
      {
        name: "Saint George's Parish",
        countryCode: "BM",
        latitude: "17.12577590",
        longitude: "-62.56198110",
        nameNative: null,
        label: "Saint George's Parish",
        cityCode: "BM_SGE",
      },
      {
        name: "Sandys Parish",
        countryCode: "BM",
        latitude: "32.29995280",
        longitude: "-64.86741030",
        nameNative: null,
        label: "Sandys Parish",
        cityCode: "BM_SAN",
      },
      {
        name: "Smith's Parish,",
        countryCode: "BM",
        latitude: "32.31339660",
        longitude: "-64.73105880",
        nameNative: null,
        label: "Smith's Parish,",
        cityCode: "BM_SMI",
      },
      {
        name: "Southampton Parish",
        countryCode: "BM",
        latitude: "32.25400950",
        longitude: "-64.82590580",
        nameNative: null,
        label: "Southampton Parish",
        cityCode: "BM_SOU",
      },
      {
        name: "Warwick Parish",
        countryCode: "BM",
        latitude: "32.26615340",
        longitude: "-64.80811980",
        nameNative: null,
        label: "Warwick Parish",
        cityCode: "BM_WAR",
      },
    ],
    BT: [
      {
        name: "Bumthang District",
        countryCode: "BT",
        latitude: "27.64183900",
        longitude: "90.67730460",
        nameNative: null,
        label: "Bumthang District",
        cityCode: "BT_33",
      },
      {
        name: "Chukha District",
        countryCode: "BT",
        latitude: "27.07843040",
        longitude: "89.47421770",
        nameNative: null,
        label: "Chukha District",
        cityCode: "BT_12",
      },
      {
        name: "Dagana District",
        countryCode: "BT",
        latitude: "27.03228610",
        longitude: "89.88793040",
        nameNative: null,
        label: "Dagana District",
        cityCode: "BT_22",
      },
      {
        name: "Gasa District",
        countryCode: "BT",
        latitude: "28.01858860",
        longitude: "89.92532330",
        nameNative: null,
        label: "Gasa District",
        cityCode: "BT_GA",
      },
      {
        name: "Haa District",
        countryCode: "BT",
        latitude: "27.26516690",
        longitude: "89.17059980",
        nameNative: null,
        label: "Haa District",
        cityCode: "BT_13",
      },
      {
        name: "Lhuntse District",
        countryCode: "BT",
        latitude: "27.82649890",
        longitude: "91.13530200",
        nameNative: null,
        label: "Lhuntse District",
        cityCode: "BT_44",
      },
      {
        name: "Mongar District",
        countryCode: "BT",
        latitude: "27.26170590",
        longitude: "91.28910360",
        nameNative: null,
        label: "Mongar District",
        cityCode: "BT_42",
      },
      {
        name: "Paro District",
        countryCode: "BT",
        latitude: "27.42859490",
        longitude: "89.41665160",
        nameNative: null,
        label: "Paro District",
        cityCode: "BT_11",
      },
      {
        name: "Pemagatshel District",
        countryCode: "BT",
        latitude: "27.00238200",
        longitude: "91.34692470",
        nameNative: null,
        label: "Pemagatshel District",
        cityCode: "BT_43",
      },
      {
        name: "Punakha District",
        countryCode: "BT",
        latitude: "27.69037160",
        longitude: "89.88793040",
        nameNative: null,
        label: "Punakha District",
        cityCode: "BT_23",
      },
      {
        name: "Samdrup Jongkhar District",
        countryCode: "BT",
        latitude: "26.80356820",
        longitude: "91.50392070",
        nameNative: null,
        label: "Samdrup Jongkhar District",
        cityCode: "BT_45",
      },
      {
        name: "Samtse District",
        countryCode: "BT",
        latitude: "27.02918320",
        longitude: "89.05615320",
        nameNative: null,
        label: "Samtse District",
        cityCode: "BT_14",
      },
      {
        name: "Sarpang District",
        countryCode: "BT",
        latitude: "26.93730410",
        longitude: "90.48799160",
        nameNative: null,
        label: "Sarpang District",
        cityCode: "BT_31",
      },
      {
        name: "Thimphu District",
        countryCode: "BT",
        latitude: "27.47122160",
        longitude: "89.63390410",
        nameNative: null,
        label: "Thimphu District",
        cityCode: "BT_15",
      },
      {
        name: "Trashigang District",
        countryCode: "BT",
        latitude: "27.25667950",
        longitude: "91.75388170",
        nameNative: null,
        label: "Trashigang District",
        cityCode: "BT_41",
      },
      {
        name: "Trongsa District",
        countryCode: "BT",
        latitude: "27.50022690",
        longitude: "90.50806340",
        nameNative: null,
        label: "Trongsa District",
        cityCode: "BT_32",
      },
      {
        name: "Tsirang District",
        countryCode: "BT",
        latitude: "27.03220700",
        longitude: "90.18696440",
        nameNative: null,
        label: "Tsirang District",
        cityCode: "BT_21",
      },
      {
        name: "Wangdue Phodrang District",
        countryCode: "BT",
        latitude: "27.45260460",
        longitude: "90.06749280",
        nameNative: null,
        label: "Wangdue Phodrang District",
        cityCode: "BT_24",
      },
      {
        name: "Zhemgang District",
        countryCode: "BT",
        latitude: "27.07697500",
        longitude: "90.82940020",
        nameNative: null,
        label: "Zhemgang District",
        cityCode: "BT_34",
      },
    ],
    BO: [
      {
        name: "Beni Department",
        countryCode: "BO",
        latitude: "-14.37827470",
        longitude: "-65.09577920",
        nameNative: null,
        label: "Beni Department",
        cityCode: "BO_B",
      },
      {
        name: "Chuquisaca Department",
        countryCode: "BO",
        latitude: "-20.02491440",
        longitude: "-64.14782360",
        nameNative: null,
        label: "Chuquisaca Department",
        cityCode: "BO_H",
      },
      {
        name: "Cochabamba Department",
        countryCode: "BO",
        latitude: "-17.56816750",
        longitude: "-65.47573600",
        nameNative: null,
        label: "Cochabamba Department",
        cityCode: "BO_C",
      },
      {
        name: "La Paz Department",
        countryCode: "BO",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "La Paz Department",
        cityCode: "BO_L",
      },
      {
        name: "Oruro Department",
        countryCode: "BO",
        latitude: "-18.57115790",
        longitude: "-67.76159830",
        nameNative: null,
        label: "Oruro Department",
        cityCode: "BO_O",
      },
      {
        name: "Pando Department",
        countryCode: "BO",
        latitude: "-10.79889010",
        longitude: "-66.99880110",
        nameNative: null,
        label: "Pando Department",
        cityCode: "BO_N",
      },
      {
        name: "Potosí Department",
        countryCode: "BO",
        latitude: "-20.62471300",
        longitude: "-66.99880110",
        nameNative: null,
        label: "Potosí Department",
        cityCode: "BO_P",
      },
      {
        name: "Santa Cruz Department",
        countryCode: "BO",
        latitude: "-16.74760370",
        longitude: "-62.07509980",
        nameNative: null,
        label: "Santa Cruz Department",
        cityCode: "BO_S",
      },
      {
        name: "Tarija Department",
        countryCode: "BO",
        latitude: "-21.58315950",
        longitude: "-63.95861110",
        nameNative: null,
        label: "Tarija Department",
        cityCode: "BO_T",
      },
    ],
    BA: [
      {
        name: "Bosnian Podrinje Canton",
        countryCode: "BA",
        latitude: "43.68749000",
        longitude: "18.82443940",
        nameNative: null,
        label: "Bosnian Podrinje Canton",
        cityCode: "BA_05",
      },
      {
        name: "Brčko District",
        countryCode: "BA",
        latitude: "44.84059440",
        longitude: "18.74215300",
        nameNative: null,
        label: "Brčko District",
        cityCode: "BA_BRC",
      },
      {
        name: "Canton 10",
        countryCode: "BA",
        latitude: "43.95341550",
        longitude: "16.94251870",
        nameNative: null,
        label: "Canton 10",
        cityCode: "BA_10",
      },
      {
        name: "Central Bosnia Canton",
        countryCode: "BA",
        latitude: "44.13818560",
        longitude: "17.68667140",
        nameNative: null,
        label: "Central Bosnia Canton",
        cityCode: "BA_06",
      },
      {
        name: "Federation of Bosnia and Herzegovina",
        countryCode: "BA",
        latitude: "43.88748970",
        longitude: "17.84279300",
        nameNative: null,
        label: "Federation of Bosnia and Herzegovina",
        cityCode: "BA_BIH",
      },
      {
        name: "Herzegovina-Neretva Canton",
        countryCode: "BA",
        latitude: "43.52651590",
        longitude: "17.76362100",
        nameNative: null,
        label: "Herzegovina-Neretva Canton",
        cityCode: "BA_07",
      },
      {
        name: "Posavina Canton",
        countryCode: "BA",
        latitude: "45.07520940",
        longitude: "18.37763040",
        nameNative: null,
        label: "Posavina Canton",
        cityCode: "BA_02",
      },
      {
        name: "Republika Srpska",
        countryCode: "BA",
        latitude: "44.72801860",
        longitude: "17.31481360",
        nameNative: null,
        label: "Republika Srpska",
        cityCode: "BA_SRP",
      },
      {
        name: "Sarajevo Canton",
        countryCode: "BA",
        latitude: "43.85125640",
        longitude: "18.29534420",
        nameNative: null,
        label: "Sarajevo Canton",
        cityCode: "BA_09",
      },
      {
        name: "Tuzla Canton",
        countryCode: "BA",
        latitude: "44.53434630",
        longitude: "18.69727970",
        nameNative: null,
        label: "Tuzla Canton",
        cityCode: "BA_03",
      },
      {
        name: "Una-Sana Canton",
        countryCode: "BA",
        latitude: "44.65031160",
        longitude: "16.31716290",
        nameNative: null,
        label: "Una-Sana Canton",
        cityCode: "BA_01",
      },
      {
        name: "West Herzegovina Canton",
        countryCode: "BA",
        latitude: "43.43692440",
        longitude: "17.38488310",
        nameNative: null,
        label: "West Herzegovina Canton",
        cityCode: "BA_08",
      },
      {
        name: "Zenica-Doboj Canton",
        countryCode: "BA",
        latitude: "44.21271090",
        longitude: "18.16046250",
        nameNative: null,
        label: "Zenica-Doboj Canton",
        cityCode: "BA_04",
      },
    ],
    BW: [
      {
        name: "Central District",
        countryCode: "BW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Central District",
        cityCode: "BW_CE",
      },
      {
        name: "Ghanzi District",
        countryCode: "BW",
        latitude: "-21.86523140",
        longitude: "21.85685860",
        nameNative: null,
        label: "Ghanzi District",
        cityCode: "BW_GH",
      },
      {
        name: "Kgalagadi District",
        countryCode: "BW",
        latitude: "-24.75502850",
        longitude: "21.85685860",
        nameNative: null,
        label: "Kgalagadi District",
        cityCode: "BW_KG",
      },
      {
        name: "Kgatleng District",
        countryCode: "BW",
        latitude: "-24.19704450",
        longitude: "26.23046160",
        nameNative: null,
        label: "Kgatleng District",
        cityCode: "BW_KL",
      },
      {
        name: "Kweneng District",
        countryCode: "BW",
        latitude: "-23.83672490",
        longitude: "25.28375850",
        nameNative: null,
        label: "Kweneng District",
        cityCode: "BW_KW",
      },
      {
        name: "Ngamiland",
        countryCode: "BW",
        latitude: "-19.19053210",
        longitude: "23.00119890",
        nameNative: null,
        label: "Ngamiland",
        cityCode: "BW_NG",
      },
      {
        name: "North-East District",
        countryCode: "BW",
        latitude: "37.58844610",
        longitude: "-94.68637820",
        nameNative: null,
        label: "North-East District",
        cityCode: "BW_NE",
      },
      {
        name: "North-West District",
        countryCode: "BW",
        latitude: "39.34463070",
        longitude: "-76.68542830",
        nameNative: null,
        label: "North-West District",
        cityCode: "BW_NW",
      },
      {
        name: "South-East District",
        countryCode: "BW",
        latitude: "31.21637980",
        longitude: "-82.35270440",
        nameNative: null,
        label: "South-East District",
        cityCode: "BW_SE",
      },
      {
        name: "Southern District",
        countryCode: "BW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Southern District",
        cityCode: "BW_SO",
      },
    ],
    BR: [
      {
        name: "Acre",
        countryCode: "BR",
        latitude: "-9.02379600",
        longitude: "-70.81199500",
        nameNative: "Acre",
        label: "Acre / Acre",
        cityCode: "BR_AC",
      },
      {
        name: "Alagoas",
        countryCode: "BR",
        latitude: "-9.57130580",
        longitude: "-36.78195050",
        nameNative: "Alagoas",
        label: "Alagoas / Alagoas",
        cityCode: "BR_AL",
      },
      {
        name: "Amapá",
        countryCode: "BR",
        latitude: "0.90199250",
        longitude: "-52.00295650",
        nameNative: "Amapá",
        label: "Amapá / Amapá",
        cityCode: "BR_AP",
      },
      {
        name: "Amazonas",
        countryCode: "BR",
        latitude: "-3.07000000",
        longitude: "-61.66000000",
        nameNative: "Amazonas",
        label: "Amazonas / Amazonas",
        cityCode: "BR_AM",
      },
      {
        name: "Bahia",
        countryCode: "BR",
        latitude: "-11.40987400",
        longitude: "-41.28085700",
        nameNative: "Bahia",
        label: "Bahia / Bahia",
        cityCode: "BR_BA",
      },
      {
        name: "Ceará",
        countryCode: "BR",
        latitude: "-5.49839770",
        longitude: "-39.32062410",
        nameNative: "Ceará",
        label: "Ceará / Ceará",
        cityCode: "BR_CE",
      },
      {
        name: "Distrito Federal",
        countryCode: "BR",
        latitude: "-15.79976540",
        longitude: "-47.86447150",
        nameNative: "Distrito Federal",
        label: "Distrito Federal / Distrito Federal",
        cityCode: "BR_DF",
      },
      {
        name: "Espírito Santo",
        countryCode: "BR",
        latitude: "-19.18342290",
        longitude: "-40.30886260",
        nameNative: "Espírito Santo",
        label: "Espírito Santo / Espírito Santo",
        cityCode: "BR_ES",
      },
      {
        name: "Goiás",
        countryCode: "BR",
        latitude: "-15.82703690",
        longitude: "-49.83622370",
        nameNative: "Goiás",
        label: "Goiás / Goiás",
        cityCode: "BR_GO",
      },
      {
        name: "Maranhão",
        countryCode: "BR",
        latitude: "-4.96094980",
        longitude: "-45.27441590",
        nameNative: "Maranhão",
        label: "Maranhão / Maranhão",
        cityCode: "BR_MA",
      },
      {
        name: "Mato Grosso do Sul",
        countryCode: "BR",
        latitude: "-20.77222950",
        longitude: "-54.78515310",
        nameNative: "Mato Grosso do Sul",
        label: "Mato Grosso do Sul / Mato Grosso do Sul",
        cityCode: "BR_MS",
      },
      {
        name: "Mato Grosso",
        countryCode: "BR",
        latitude: "-12.68187120",
        longitude: "-56.92109900",
        nameNative: "Mato Grosso",
        label: "Mato Grosso / Mato Grosso",
        cityCode: "BR_MT",
      },
      {
        name: "Minas Gerais",
        countryCode: "BR",
        latitude: "-18.51217800",
        longitude: "-44.55503080",
        nameNative: "Minas Gerais",
        label: "Minas Gerais / Minas Gerais",
        cityCode: "BR_MG",
      },
      {
        name: "Paraná",
        countryCode: "BR",
        latitude: "-25.25208880",
        longitude: "-52.02154150",
        nameNative: "Paraná",
        label: "Paraná / Paraná",
        cityCode: "BR_PR",
      },
      {
        name: "Paraíba",
        countryCode: "BR",
        latitude: "-7.23996090",
        longitude: "-36.78195050",
        nameNative: "Paraíba",
        label: "Paraíba / Paraíba",
        cityCode: "BR_PB",
      },
      {
        name: "Pará",
        countryCode: "BR",
        latitude: "-1.99812710",
        longitude: "-54.93061520",
        nameNative: "Pará",
        label: "Pará / Pará",
        cityCode: "BR_PA",
      },
      {
        name: "Pernambuco",
        countryCode: "BR",
        latitude: "-8.81371730",
        longitude: "-36.95410700",
        nameNative: "Pernambuco",
        label: "Pernambuco / Pernambuco",
        cityCode: "BR_PE",
      },
      {
        name: "Piauí",
        countryCode: "BR",
        latitude: "-7.71834010",
        longitude: "-42.72892360",
        nameNative: "Piauí",
        label: "Piauí / Piauí",
        cityCode: "BR_PI",
      },
      {
        name: "Rio Grande do Norte",
        countryCode: "BR",
        latitude: "-5.40258030",
        longitude: "-36.95410700",
        nameNative: "Rio Grande do Norte",
        label: "Rio Grande do Norte / Rio Grande do Norte",
        cityCode: "BR_RN",
      },
      {
        name: "Rio Grande do Sul",
        countryCode: "BR",
        latitude: "-30.03463160",
        longitude: "-51.21769860",
        nameNative: "Rio Grande do Sul",
        label: "Rio Grande do Sul / Rio Grande do Sul",
        cityCode: "BR_RS",
      },
      {
        name: "Rio de Janeiro",
        countryCode: "BR",
        latitude: "-22.90684670",
        longitude: "-43.17289650",
        nameNative: "Rio de Janeiro",
        label: "Rio de Janeiro / Rio de Janeiro",
        cityCode: "BR_RJ",
      },
      {
        name: "Rondônia",
        countryCode: "BR",
        latitude: "-11.50573410",
        longitude: "-63.58061100",
        nameNative: "Rondônia",
        label: "Rondônia / Rondônia",
        cityCode: "BR_RO",
      },
      {
        name: "Roraima",
        countryCode: "BR",
        latitude: "2.73759710",
        longitude: "-62.07509980",
        nameNative: "Roraima",
        label: "Roraima / Roraima",
        cityCode: "BR_RR",
      },
      {
        name: "Santa Catarina",
        countryCode: "BR",
        latitude: "-27.33000000",
        longitude: "-49.44000000",
        nameNative: "Santa Catarina",
        label: "Santa Catarina / Santa Catarina",
        cityCode: "BR_SC",
      },
      {
        name: "Sergipe",
        countryCode: "BR",
        latitude: "-10.57409340",
        longitude: "-37.38565810",
        nameNative: "Sergipe",
        label: "Sergipe / Sergipe",
        cityCode: "BR_SE",
      },
      {
        name: "São Paulo",
        countryCode: "BR",
        latitude: "-23.55051990",
        longitude: "-46.63330940",
        nameNative: "São Paulo",
        label: "São Paulo / São Paulo",
        cityCode: "BR_SP",
      },
      {
        name: "Tocantins",
        countryCode: "BR",
        latitude: "-10.17528000",
        longitude: "-48.29824740",
        nameNative: "Tocantins",
        label: "Tocantins / Tocantins",
        cityCode: "BR_TO",
      },
    ],
    BN: [
      {
        name: "Belait District",
        countryCode: "BN",
        latitude: "4.37507490",
        longitude: "114.61928990",
        nameNative: null,
        label: "Belait District",
        cityCode: "BN_BE",
      },
      {
        name: "Brunei-Muara District",
        countryCode: "BN",
        latitude: "4.93112060",
        longitude: "114.95168690",
        nameNative: null,
        label: "Brunei-Muara District",
        cityCode: "BN_BM",
      },
      {
        name: "Temburong District",
        countryCode: "BN",
        latitude: "4.62041280",
        longitude: "115.14148400",
        nameNative: null,
        label: "Temburong District",
        cityCode: "BN_TE",
      },
      {
        name: "Tutong District",
        countryCode: "BN",
        latitude: "4.71403730",
        longitude: "114.66679390",
        nameNative: null,
        label: "Tutong District",
        cityCode: "BN_TU",
      },
    ],
    BG: [
      {
        name: "Blagoevgrad Province",
        countryCode: "BG",
        latitude: "42.02086140",
        longitude: "23.09433560",
        nameNative: null,
        label: "Blagoevgrad Province",
        cityCode: "BG_01",
      },
      {
        name: "Burgas Province",
        countryCode: "BG",
        latitude: "42.50480000",
        longitude: "27.46260790",
        nameNative: null,
        label: "Burgas Province",
        cityCode: "BG_02",
      },
      {
        name: "Dobrich Province",
        countryCode: "BG",
        latitude: "43.57278600",
        longitude: "27.82728020",
        nameNative: null,
        label: "Dobrich Province",
        cityCode: "BG_08",
      },
      {
        name: "Gabrovo Province",
        countryCode: "BG",
        latitude: "42.86847000",
        longitude: "25.31688900",
        nameNative: null,
        label: "Gabrovo Province",
        cityCode: "BG_07",
      },
      {
        name: "Haskovo Province",
        countryCode: "BG",
        latitude: "41.93441780",
        longitude: "25.55546720",
        nameNative: null,
        label: "Haskovo Province",
        cityCode: "BG_26",
      },
      {
        name: "Kardzhali Province",
        countryCode: "BG",
        latitude: "41.63384160",
        longitude: "25.37766870",
        nameNative: null,
        label: "Kardzhali Province",
        cityCode: "BG_09",
      },
      {
        name: "Kyustendil Province",
        countryCode: "BG",
        latitude: "42.28687990",
        longitude: "22.69396350",
        nameNative: null,
        label: "Kyustendil Province",
        cityCode: "BG_10",
      },
      {
        name: "Lovech Province",
        countryCode: "BG",
        latitude: "43.13677980",
        longitude: "24.71393350",
        nameNative: null,
        label: "Lovech Province",
        cityCode: "BG_11",
      },
      {
        name: "Montana Province",
        countryCode: "BG",
        latitude: "43.40851480",
        longitude: "23.22575890",
        nameNative: null,
        label: "Montana Province",
        cityCode: "BG_12",
      },
      {
        name: "Pazardzhik Province",
        countryCode: "BG",
        latitude: "42.19275670",
        longitude: "24.33362260",
        nameNative: null,
        label: "Pazardzhik Province",
        cityCode: "BG_13",
      },
      {
        name: "Pernik Province",
        countryCode: "BG",
        latitude: "42.60519900",
        longitude: "23.03779160",
        nameNative: null,
        label: "Pernik Province",
        cityCode: "BG_14",
      },
      {
        name: "Pleven Province",
        countryCode: "BG",
        latitude: "43.41701690",
        longitude: "24.60667080",
        nameNative: null,
        label: "Pleven Province",
        cityCode: "BG_15",
      },
      {
        name: "Plovdiv Province",
        countryCode: "BG",
        latitude: "42.13540790",
        longitude: "24.74529040",
        nameNative: null,
        label: "Plovdiv Province",
        cityCode: "BG_16",
      },
      {
        name: "Razgrad Province",
        countryCode: "BG",
        latitude: "43.52717050",
        longitude: "26.52412280",
        nameNative: null,
        label: "Razgrad Province",
        cityCode: "BG_17",
      },
      {
        name: "Ruse Province",
        countryCode: "BG",
        latitude: "43.83559640",
        longitude: "25.96561440",
        nameNative: null,
        label: "Ruse Province",
        cityCode: "BG_18",
      },
      {
        name: "Shumen",
        countryCode: "BG",
        latitude: "43.27123980",
        longitude: "26.93612860",
        nameNative: null,
        label: "Shumen",
        cityCode: "BG_27",
      },
      {
        name: "Silistra Province",
        countryCode: "BG",
        latitude: "44.11471010",
        longitude: "27.26714540",
        nameNative: null,
        label: "Silistra Province",
        cityCode: "BG_19",
      },
      {
        name: "Sliven Province",
        countryCode: "BG",
        latitude: "42.68167020",
        longitude: "26.32285690",
        nameNative: null,
        label: "Sliven Province",
        cityCode: "BG_20",
      },
      {
        name: "Smolyan Province",
        countryCode: "BG",
        latitude: "41.57741480",
        longitude: "24.70108710",
        nameNative: null,
        label: "Smolyan Province",
        cityCode: "BG_21",
      },
      {
        name: "Sofia City Province",
        countryCode: "BG",
        latitude: "42.75701090",
        longitude: "23.45046830",
        nameNative: null,
        label: "Sofia City Province",
        cityCode: "BG_22",
      },
      {
        name: "Sofia Province",
        countryCode: "BG",
        latitude: "42.67344000",
        longitude: "23.83349370",
        nameNative: null,
        label: "Sofia Province",
        cityCode: "BG_23",
      },
      {
        name: "Stara Zagora Province",
        countryCode: "BG",
        latitude: "42.42577090",
        longitude: "25.63448550",
        nameNative: null,
        label: "Stara Zagora Province",
        cityCode: "BG_24",
      },
      {
        name: "Targovishte Province",
        countryCode: "BG",
        latitude: "43.24623490",
        longitude: "26.56912510",
        nameNative: null,
        label: "Targovishte Province",
        cityCode: "BG_25",
      },
      {
        name: "Varna Province",
        countryCode: "BG",
        latitude: "43.20464770",
        longitude: "27.91054880",
        nameNative: null,
        label: "Varna Province",
        cityCode: "BG_03",
      },
      {
        name: "Veliko Tarnovo Province",
        countryCode: "BG",
        latitude: "43.07565390",
        longitude: "25.61715000",
        nameNative: null,
        label: "Veliko Tarnovo Province",
        cityCode: "BG_04",
      },
      {
        name: "Vidin Province",
        countryCode: "BG",
        latitude: "43.99617390",
        longitude: "22.86795150",
        nameNative: null,
        label: "Vidin Province",
        cityCode: "BG_05",
      },
      {
        name: "Vratsa Province",
        countryCode: "BG",
        latitude: "43.21018060",
        longitude: "23.55292100",
        nameNative: null,
        label: "Vratsa Province",
        cityCode: "BG_06",
      },
      {
        name: "Yambol Province",
        countryCode: "BG",
        latitude: "42.48414940",
        longitude: "26.50352960",
        nameNative: null,
        label: "Yambol Province",
        cityCode: "BG_28",
      },
    ],
    BF: [
      {
        name: "Balé Province",
        countryCode: "BF",
        latitude: "11.78206020",
        longitude: "-3.01757120",
        nameNative: null,
        label: "Balé Province",
        cityCode: "BF_BAL",
      },
      {
        name: "Bam Province",
        countryCode: "BF",
        latitude: "13.44613300",
        longitude: "-1.59839590",
        nameNative: null,
        label: "Bam Province",
        cityCode: "BF_BAM",
      },
      {
        name: "Banwa Province",
        countryCode: "BF",
        latitude: "12.13230530",
        longitude: "-4.15137640",
        nameNative: null,
        label: "Banwa Province",
        cityCode: "BF_BAN",
      },
      {
        name: "Bazèga Province",
        countryCode: "BF",
        latitude: "11.97676920",
        longitude: "-1.44346900",
        nameNative: null,
        label: "Bazèga Province",
        cityCode: "BF_BAZ",
      },
      {
        name: "Boucle du Mouhoun Region",
        countryCode: "BF",
        latitude: "12.41660000",
        longitude: "-3.41955270",
        nameNative: null,
        label: "Boucle du Mouhoun Region",
        cityCode: "BF_01",
      },
      {
        name: "Bougouriba Province",
        countryCode: "BF",
        latitude: "10.87226460",
        longitude: "-3.33889170",
        nameNative: null,
        label: "Bougouriba Province",
        cityCode: "BF_BGR",
      },
      {
        name: "Boulgou",
        countryCode: "BF",
        latitude: "11.43367660",
        longitude: "-0.37483540",
        nameNative: null,
        label: "Boulgou",
        cityCode: "BF_BLG",
      },
      {
        name: "Cascades Region",
        countryCode: "BF",
        latitude: "10.40729920",
        longitude: "-4.56244260",
        nameNative: null,
        label: "Cascades Region",
        cityCode: "BF_02",
      },
      {
        name: "Centre",
        countryCode: "BF",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Centre",
        cityCode: "BF_03",
      },
      {
        name: "Centre-Est Region",
        countryCode: "BF",
        latitude: "11.52476740",
        longitude: "-0.14949880",
        nameNative: null,
        label: "Centre-Est Region",
        cityCode: "BF_04",
      },
      {
        name: "Centre-Nord Region",
        countryCode: "BF",
        latitude: "13.17244640",
        longitude: "-0.90566230",
        nameNative: null,
        label: "Centre-Nord Region",
        cityCode: "BF_05",
      },
      {
        name: "Centre-Ouest Region",
        countryCode: "BF",
        latitude: "11.87984660",
        longitude: "-2.30244600",
        nameNative: null,
        label: "Centre-Ouest Region",
        cityCode: "BF_06",
      },
      {
        name: "Centre-Sud Region",
        countryCode: "BF",
        latitude: "11.52289110",
        longitude: "-1.05861350",
        nameNative: null,
        label: "Centre-Sud Region",
        cityCode: "BF_07",
      },
      {
        name: "Comoé Province",
        countryCode: "BF",
        latitude: "10.40729920",
        longitude: "-4.56244260",
        nameNative: null,
        label: "Comoé Province",
        cityCode: "BF_COM",
      },
      {
        name: "Est Region",
        countryCode: "BF",
        latitude: "12.43655260",
        longitude: "0.90566230",
        nameNative: null,
        label: "Est Region",
        cityCode: "BF_08",
      },
      {
        name: "Ganzourgou Province",
        countryCode: "BF",
        latitude: "12.25376480",
        longitude: "-0.75328090",
        nameNative: null,
        label: "Ganzourgou Province",
        cityCode: "BF_GAN",
      },
      {
        name: "Gnagna Province",
        countryCode: "BF",
        latitude: "12.89749920",
        longitude: "0.07467670",
        nameNative: null,
        label: "Gnagna Province",
        cityCode: "BF_GNA",
      },
      {
        name: "Gourma Province",
        countryCode: "BF",
        latitude: "12.16244730",
        longitude: "0.67730460",
        nameNative: null,
        label: "Gourma Province",
        cityCode: "BF_GOU",
      },
      {
        name: "Hauts-Bassins Region",
        countryCode: "BF",
        latitude: "11.49420030",
        longitude: "-4.23333550",
        nameNative: null,
        label: "Hauts-Bassins Region",
        cityCode: "BF_09",
      },
      {
        name: "Houet Province",
        countryCode: "BF",
        latitude: "11.13204470",
        longitude: "-4.23333550",
        nameNative: null,
        label: "Houet Province",
        cityCode: "BF_HOU",
      },
      {
        name: "Ioba Province",
        countryCode: "BF",
        latitude: "11.05620340",
        longitude: "-3.01757120",
        nameNative: null,
        label: "Ioba Province",
        cityCode: "BF_IOB",
      },
      {
        name: "Kadiogo Province",
        countryCode: "BF",
        latitude: "12.34258970",
        longitude: "-1.44346900",
        nameNative: null,
        label: "Kadiogo Province",
        cityCode: "BF_KAD",
      },
      {
        name: "Komondjari Province",
        countryCode: "BF",
        latitude: "12.71265270",
        longitude: "0.67730460",
        nameNative: null,
        label: "Komondjari Province",
        cityCode: "BF_KMD",
      },
      {
        name: "Kompienga Province",
        countryCode: "BF",
        latitude: "11.52383620",
        longitude: "0.75328090",
        nameNative: null,
        label: "Kompienga Province",
        cityCode: "BF_KMP",
      },
      {
        name: "Kossi Province",
        countryCode: "BF",
        latitude: "12.96045800",
        longitude: "-3.90626880",
        nameNative: null,
        label: "Kossi Province",
        cityCode: "BF_KOS",
      },
      {
        name: "Koulpélogo Province",
        countryCode: "BF",
        latitude: "11.52476740",
        longitude: "0.14949880",
        nameNative: null,
        label: "Koulpélogo Province",
        cityCode: "BF_KOP",
      },
      {
        name: "Kouritenga Province",
        countryCode: "BF",
        latitude: "12.16318130",
        longitude: "-0.22446620",
        nameNative: null,
        label: "Kouritenga Province",
        cityCode: "BF_KOT",
      },
      {
        name: "Kourwéogo Province",
        countryCode: "BF",
        latitude: "12.70774950",
        longitude: "-1.75388170",
        nameNative: null,
        label: "Kourwéogo Province",
        cityCode: "BF_KOW",
      },
      {
        name: "Kénédougou Province",
        countryCode: "BF",
        latitude: "11.39193950",
        longitude: "-4.97665400",
        nameNative: null,
        label: "Kénédougou Province",
        cityCode: "BF_KEN",
      },
      {
        name: "Loroum Province",
        countryCode: "BF",
        latitude: "13.81298140",
        longitude: "-2.06651970",
        nameNative: null,
        label: "Loroum Province",
        cityCode: "BF_LOR",
      },
      {
        name: "Léraba Province",
        countryCode: "BF",
        latitude: "10.66487850",
        longitude: "-5.31025050",
        nameNative: null,
        label: "Léraba Province",
        cityCode: "BF_LER",
      },
      {
        name: "Mouhoun",
        countryCode: "BF",
        latitude: "12.14323810",
        longitude: "-3.33889170",
        nameNative: null,
        label: "Mouhoun",
        cityCode: "BF_MOU",
      },
      {
        name: "Nahouri Province",
        countryCode: "BF",
        latitude: "11.25022670",
        longitude: "-1.13530200",
        nameNative: null,
        label: "Nahouri Province",
        cityCode: "BF_NAO",
      },
      {
        name: "Namentenga Province",
        countryCode: "BF",
        latitude: "13.08125840",
        longitude: "-0.52578230",
        nameNative: null,
        label: "Namentenga Province",
        cityCode: "BF_NAM",
      },
      {
        name: "Nayala Province",
        countryCode: "BF",
        latitude: "12.69645580",
        longitude: "-3.01757120",
        nameNative: null,
        label: "Nayala Province",
        cityCode: "BF_NAY",
      },
      {
        name: "Nord Region, Burkina Faso",
        countryCode: "BF",
        latitude: "13.71825200",
        longitude: "-2.30244600",
        nameNative: null,
        label: "Nord Region, Burkina Faso",
        cityCode: "BF_10",
      },
      {
        name: "Noumbiel Province",
        countryCode: "BF",
        latitude: "9.84409460",
        longitude: "-2.97755580",
        nameNative: null,
        label: "Noumbiel Province",
        cityCode: "BF_NOU",
      },
      {
        name: "Oubritenga Province",
        countryCode: "BF",
        latitude: "12.70960870",
        longitude: "-1.44346900",
        nameNative: null,
        label: "Oubritenga Province",
        cityCode: "BF_OUB",
      },
      {
        name: "Oudalan Province",
        countryCode: "BF",
        latitude: "14.47190200",
        longitude: "-0.45023680",
        nameNative: null,
        label: "Oudalan Province",
        cityCode: "BF_OUD",
      },
      {
        name: "Passoré Province",
        countryCode: "BF",
        latitude: "12.88812210",
        longitude: "-2.22366670",
        nameNative: null,
        label: "Passoré Province",
        cityCode: "BF_PAS",
      },
      {
        name: "Plateau-Central Region",
        countryCode: "BF",
        latitude: "12.25376480",
        longitude: "-0.75328090",
        nameNative: null,
        label: "Plateau-Central Region",
        cityCode: "BF_11",
      },
      {
        name: "Poni Province",
        countryCode: "BF",
        latitude: "10.33259960",
        longitude: "-3.33889170",
        nameNative: null,
        label: "Poni Province",
        cityCode: "BF_PON",
      },
      {
        name: "Sahel Region",
        countryCode: "BF",
        latitude: "14.10008650",
        longitude: "-0.14949880",
        nameNative: null,
        label: "Sahel Region",
        cityCode: "BF_12",
      },
      {
        name: "Sanguié Province",
        countryCode: "BF",
        latitude: "12.15018610",
        longitude: "-2.69838680",
        nameNative: null,
        label: "Sanguié Province",
        cityCode: "BF_SNG",
      },
      {
        name: "Sanmatenga Province",
        countryCode: "BF",
        latitude: "13.35653040",
        longitude: "-1.05861350",
        nameNative: null,
        label: "Sanmatenga Province",
        cityCode: "BF_SMT",
      },
      {
        name: "Sissili Province",
        countryCode: "BF",
        latitude: "11.24412190",
        longitude: "-2.22366670",
        nameNative: null,
        label: "Sissili Province",
        cityCode: "BF_SIS",
      },
      {
        name: "Soum Province",
        countryCode: "BF",
        latitude: "14.09628410",
        longitude: "-1.36621600",
        nameNative: null,
        label: "Soum Province",
        cityCode: "BF_SOM",
      },
      {
        name: "Sourou Province",
        countryCode: "BF",
        latitude: "13.34180300",
        longitude: "-2.93757390",
        nameNative: null,
        label: "Sourou Province",
        cityCode: "BF_SOR",
      },
      {
        name: "Sud-Ouest Region",
        countryCode: "BF",
        latitude: "10.42314930",
        longitude: "-3.25836260",
        nameNative: null,
        label: "Sud-Ouest Region",
        cityCode: "BF_13",
      },
      {
        name: "Séno Province",
        countryCode: "BF",
        latitude: "14.00722340",
        longitude: "-0.07467670",
        nameNative: null,
        label: "Séno Province",
        cityCode: "BF_SEN",
      },
      {
        name: "Tapoa Province",
        countryCode: "BF",
        latitude: "12.24970720",
        longitude: "1.67606910",
        nameNative: null,
        label: "Tapoa Province",
        cityCode: "BF_TAP",
      },
      {
        name: "Tuy Province",
        countryCode: "BF",
        latitude: "38.88868400",
        longitude: "-77.00471900",
        nameNative: null,
        label: "Tuy Province",
        cityCode: "BF_TUI",
      },
      {
        name: "Yagha Province",
        countryCode: "BF",
        latitude: "13.35761570",
        longitude: "0.75328090",
        nameNative: null,
        label: "Yagha Province",
        cityCode: "BF_YAG",
      },
      {
        name: "Yatenga Province",
        countryCode: "BF",
        latitude: "13.62493440",
        longitude: "-2.38136210",
        nameNative: null,
        label: "Yatenga Province",
        cityCode: "BF_YAT",
      },
      {
        name: "Ziro Province",
        countryCode: "BF",
        latitude: "11.60949950",
        longitude: "-1.90992380",
        nameNative: null,
        label: "Ziro Province",
        cityCode: "BF_ZIR",
      },
      {
        name: "Zondoma Province",
        countryCode: "BF",
        latitude: "13.11659260",
        longitude: "-2.42087130",
        nameNative: null,
        label: "Zondoma Province",
        cityCode: "BF_ZON",
      },
      {
        name: "Zoundwéogo Province",
        countryCode: "BF",
        latitude: "11.61411740",
        longitude: "-0.98206680",
        nameNative: null,
        label: "Zoundwéogo Province",
        cityCode: "BF_ZOU",
      },
    ],
    BI: [
      {
        name: "Bubanza Province",
        countryCode: "BI",
        latitude: "-3.15724030",
        longitude: "29.37149090",
        nameNative: null,
        label: "Bubanza Province",
        cityCode: "BI_BB",
      },
      {
        name: "Bujumbura Mairie Province",
        countryCode: "BI",
        latitude: "-3.38841410",
        longitude: "29.34826460",
        nameNative: null,
        label: "Bujumbura Mairie Province",
        cityCode: "BI_BM",
      },
      {
        name: "Bujumbura Rural Province",
        countryCode: "BI",
        latitude: "-3.50901440",
        longitude: "29.46435900",
        nameNative: null,
        label: "Bujumbura Rural Province",
        cityCode: "BI_BL",
      },
      {
        name: "Bururi Province",
        countryCode: "BI",
        latitude: "-3.90068510",
        longitude: "29.51077080",
        nameNative: null,
        label: "Bururi Province",
        cityCode: "BI_BR",
      },
      {
        name: "Cankuzo Province",
        countryCode: "BI",
        latitude: "-3.15277880",
        longitude: "30.61998950",
        nameNative: null,
        label: "Cankuzo Province",
        cityCode: "BI_CA",
      },
      {
        name: "Cibitoke Province",
        countryCode: "BI",
        latitude: "-2.81028970",
        longitude: "29.18557850",
        nameNative: null,
        label: "Cibitoke Province",
        cityCode: "BI_CI",
      },
      {
        name: "Gitega Province",
        countryCode: "BI",
        latitude: "-3.49290510",
        longitude: "29.92779470",
        nameNative: null,
        label: "Gitega Province",
        cityCode: "BI_GI",
      },
      {
        name: "Karuzi Province",
        countryCode: "BI",
        latitude: "-3.13403470",
        longitude: "30.11273500",
        nameNative: null,
        label: "Karuzi Province",
        cityCode: "BI_KR",
      },
      {
        name: "Kayanza Province",
        countryCode: "BI",
        latitude: "-3.00779810",
        longitude: "29.64991620",
        nameNative: null,
        label: "Kayanza Province",
        cityCode: "BI_KY",
      },
      {
        name: "Kirundo Province",
        countryCode: "BI",
        latitude: "-2.57628820",
        longitude: "30.11273500",
        nameNative: null,
        label: "Kirundo Province",
        cityCode: "BI_KI",
      },
      {
        name: "Makamba Province",
        countryCode: "BI",
        latitude: "-4.32570620",
        longitude: "29.69626770",
        nameNative: null,
        label: "Makamba Province",
        cityCode: "BI_MA",
      },
      {
        name: "Muramvya Province",
        countryCode: "BI",
        latitude: "-3.28983980",
        longitude: "29.64991620",
        nameNative: null,
        label: "Muramvya Province",
        cityCode: "BI_MU",
      },
      {
        name: "Muyinga Province",
        countryCode: "BI",
        latitude: "-2.77935110",
        longitude: "30.29741990",
        nameNative: null,
        label: "Muyinga Province",
        cityCode: "BI_MY",
      },
      {
        name: "Mwaro Province",
        countryCode: "BI",
        latitude: "-3.50259180",
        longitude: "29.64991620",
        nameNative: null,
        label: "Mwaro Province",
        cityCode: "BI_MW",
      },
      {
        name: "Ngozi Province",
        countryCode: "BI",
        latitude: "-2.89582430",
        longitude: "29.88152030",
        nameNative: null,
        label: "Ngozi Province",
        cityCode: "BI_NG",
      },
      {
        name: "Rumonge Province",
        countryCode: "BI",
        latitude: "-3.97540490",
        longitude: "29.43880140",
        nameNative: null,
        label: "Rumonge Province",
        cityCode: "BI_RM",
      },
      {
        name: "Rutana Province",
        countryCode: "BI",
        latitude: "-3.87915230",
        longitude: "30.06652360",
        nameNative: null,
        label: "Rutana Province",
        cityCode: "BI_RT",
      },
      {
        name: "Ruyigi Province",
        countryCode: "BI",
        latitude: "-3.44620700",
        longitude: "30.25127280",
        nameNative: null,
        label: "Ruyigi Province",
        cityCode: "BI_RY",
      },
    ],
    KH: [
      {
        name: "Banteay Meanchey",
        countryCode: "KH",
        latitude: "13.75319140",
        longitude: "102.98961500",
        nameNative: null,
        label: "Banteay Meanchey",
        cityCode: "KH_1",
      },
      {
        name: "Battambang",
        countryCode: "KH",
        latitude: "13.02869710",
        longitude: "102.98961500",
        nameNative: null,
        label: "Battambang",
        cityCode: "KH_2",
      },
      {
        name: "Kampong Cham",
        countryCode: "KH",
        latitude: "12.09829180",
        longitude: "105.31311850",
        nameNative: null,
        label: "Kampong Cham",
        cityCode: "KH_3",
      },
      {
        name: "Kampong Chhnang",
        countryCode: "KH",
        latitude: "12.13923520",
        longitude: "104.56552730",
        nameNative: null,
        label: "Kampong Chhnang",
        cityCode: "KH_4",
      },
      {
        name: "Kampong Speu",
        countryCode: "KH",
        latitude: "11.61551090",
        longitude: "104.37919120",
        nameNative: null,
        label: "Kampong Speu",
        cityCode: "KH_5",
      },
      {
        name: "Kampong Thom",
        countryCode: "KH",
        latitude: "12.81674850",
        longitude: "103.84131040",
        nameNative: null,
        label: "Kampong Thom",
        cityCode: "KH_6",
      },
      {
        name: "Kampot",
        countryCode: "KH",
        latitude: "10.73253510",
        longitude: "104.37919120",
        nameNative: null,
        label: "Kampot",
        cityCode: "KH_7",
      },
      {
        name: "Kandal",
        countryCode: "KH",
        latitude: "11.22373830",
        longitude: "105.12589550",
        nameNative: null,
        label: "Kandal",
        cityCode: "KH_8",
      },
      {
        name: "Kep",
        countryCode: "KH",
        latitude: "10.53608900",
        longitude: "104.35591580",
        nameNative: null,
        label: "Kep",
        cityCode: "KH_23",
      },
      {
        name: "Koh Kong",
        countryCode: "KH",
        latitude: "11.57628040",
        longitude: "103.35872880",
        nameNative: null,
        label: "Koh Kong",
        cityCode: "KH_9",
      },
      {
        name: "Kratie",
        countryCode: "KH",
        latitude: "12.50436080",
        longitude: "105.96998780",
        nameNative: null,
        label: "Kratie",
        cityCode: "KH_10",
      },
      {
        name: "Mondulkiri",
        countryCode: "KH",
        latitude: "12.78794270",
        longitude: "107.10119310",
        nameNative: null,
        label: "Mondulkiri",
        cityCode: "KH_11",
      },
      {
        name: "Oddar Meanchey",
        countryCode: "KH",
        latitude: "14.16097380",
        longitude: "103.82162610",
        nameNative: null,
        label: "Oddar Meanchey",
        cityCode: "KH_22",
      },
      {
        name: "Pailin",
        countryCode: "KH",
        latitude: "12.90929620",
        longitude: "102.66755750",
        nameNative: null,
        label: "Pailin",
        cityCode: "KH_24",
      },
      {
        name: "Phnom Penh",
        countryCode: "KH",
        latitude: "11.55637380",
        longitude: "104.92820990",
        nameNative: null,
        label: "Phnom Penh",
        cityCode: "KH_12",
      },
      {
        name: "Preah Vihear",
        countryCode: "KH",
        latitude: "14.00857970",
        longitude: "104.84546190",
        nameNative: null,
        label: "Preah Vihear",
        cityCode: "KH_13",
      },
      {
        name: "Prey Veng",
        countryCode: "KH",
        latitude: "11.38024420",
        longitude: "105.50054830",
        nameNative: null,
        label: "Prey Veng",
        cityCode: "KH_14",
      },
      {
        name: "Pursat",
        countryCode: "KH",
        latitude: "12.27209560",
        longitude: "103.72891670",
        nameNative: null,
        label: "Pursat",
        cityCode: "KH_15",
      },
      {
        name: "Ratanakiri",
        countryCode: "KH",
        latitude: "13.85766070",
        longitude: "107.10119310",
        nameNative: null,
        label: "Ratanakiri",
        cityCode: "KH_16",
      },
      {
        name: "Siem Reap",
        countryCode: "KH",
        latitude: "13.33026600",
        longitude: "104.10013260",
        nameNative: null,
        label: "Siem Reap",
        cityCode: "KH_17",
      },
      {
        name: "Sihanoukville",
        countryCode: "KH",
        latitude: "10.75818990",
        longitude: "103.82162610",
        nameNative: null,
        label: "Sihanoukville",
        cityCode: "KH_18",
      },
      {
        name: "Stung Treng",
        countryCode: "KH",
        latitude: "13.57647300",
        longitude: "105.96998780",
        nameNative: null,
        label: "Stung Treng",
        cityCode: "KH_19",
      },
      {
        name: "Svay Rieng",
        countryCode: "KH",
        latitude: "11.14272200",
        longitude: "105.82902980",
        nameNative: null,
        label: "Svay Rieng",
        cityCode: "KH_20",
      },
      {
        name: "Tai Po District",
        countryCode: "KH",
        latitude: "22.45085000",
        longitude: "114.16422000",
        nameNative: null,
        label: "Tai Po District",
        cityCode: "KH_NTP",
      },
      {
        name: "Takeo",
        countryCode: "KH",
        latitude: "10.93215190",
        longitude: "104.79877100",
        nameNative: null,
        label: "Takeo",
        cityCode: "KH_21",
      },
    ],
    CM: [
      {
        name: "Adamawa",
        countryCode: "CM",
        latitude: "9.32647510",
        longitude: "12.39838530",
        nameNative: null,
        label: "Adamawa",
        cityCode: "CM_AD",
      },
      {
        name: "Centre",
        countryCode: "CM",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Centre",
        cityCode: "CM_CE",
      },
      {
        name: "East",
        countryCode: "CM",
        latitude: "39.01853360",
        longitude: "-94.27924110",
        nameNative: null,
        label: "East",
        cityCode: "CM_ES",
      },
      {
        name: "Far North",
        countryCode: "CM",
        latitude: "66.76134510",
        longitude: "124.12375300",
        nameNative: null,
        label: "Far North",
        cityCode: "CM_EN",
      },
      {
        name: "Littoral",
        countryCode: "CM",
        latitude: "48.46227570",
        longitude: "-68.51780710",
        nameNative: null,
        label: "Littoral",
        cityCode: "CM_LT",
      },
      {
        name: "North",
        countryCode: "CM",
        latitude: "37.09024000",
        longitude: "-95.71289100",
        nameNative: null,
        label: "North",
        cityCode: "CM_NO",
      },
      {
        name: "Northwest",
        countryCode: "CM",
        latitude: "36.37118570",
        longitude: "-94.19346060",
        nameNative: null,
        label: "Northwest",
        cityCode: "CM_NW",
      },
      {
        name: "South",
        countryCode: "CM",
        latitude: "37.63159500",
        longitude: "-97.34584090",
        nameNative: null,
        label: "South",
        cityCode: "CM_SU",
      },
      {
        name: "Southwest",
        countryCode: "CM",
        latitude: "36.19088130",
        longitude: "-95.88974480",
        nameNative: null,
        label: "Southwest",
        cityCode: "CM_SW",
      },
      {
        name: "West",
        countryCode: "CM",
        latitude: "37.03649890",
        longitude: "-95.67059870",
        nameNative: null,
        label: "West",
        cityCode: "CM_OU",
      },
    ],
    CA: [
      {
        name: "Alberta",
        countryCode: "CA",
        latitude: "53.93327060",
        longitude: "-116.57650350",
        nameNative: null,
        label: "Alberta",
        cityCode: "CA_AB",
      },
      {
        name: "British Columbia",
        countryCode: "CA",
        latitude: "53.72666830",
        longitude: "-127.64762050",
        nameNative: null,
        label: "British Columbia",
        cityCode: "CA_BC",
      },
      {
        name: "Manitoba",
        countryCode: "CA",
        latitude: "53.76086080",
        longitude: "-98.81387620",
        nameNative: null,
        label: "Manitoba",
        cityCode: "CA_MB",
      },
      {
        name: "New Brunswick",
        countryCode: "CA",
        latitude: "46.56531630",
        longitude: "-66.46191640",
        nameNative: null,
        label: "New Brunswick",
        cityCode: "CA_NB",
      },
      {
        name: "Newfoundland and Labrador",
        countryCode: "CA",
        latitude: "53.13550910",
        longitude: "-57.66043640",
        nameNative: null,
        label: "Newfoundland and Labrador",
        cityCode: "CA_NL",
      },
      {
        name: "Northwest Territories",
        countryCode: "CA",
        latitude: "64.82554410",
        longitude: "-124.84573340",
        nameNative: null,
        label: "Northwest Territories",
        cityCode: "CA_NT",
      },
      {
        name: "Nova Scotia",
        countryCode: "CA",
        latitude: "44.68198660",
        longitude: "-63.74431100",
        nameNative: null,
        label: "Nova Scotia",
        cityCode: "CA_NS",
      },
      {
        name: "Nunavut",
        countryCode: "CA",
        latitude: "70.29977110",
        longitude: "-83.10757700",
        nameNative: null,
        label: "Nunavut",
        cityCode: "CA_NU",
      },
      {
        name: "Ontario",
        countryCode: "CA",
        latitude: "51.25377500",
        longitude: "-85.32321400",
        nameNative: null,
        label: "Ontario",
        cityCode: "CA_ON",
      },
      {
        name: "Prince Edward Island",
        countryCode: "CA",
        latitude: "46.51071200",
        longitude: "-63.41681360",
        nameNative: null,
        label: "Prince Edward Island",
        cityCode: "CA_PE",
      },
      {
        name: "Quebec",
        countryCode: "CA",
        latitude: "52.93991590",
        longitude: "-73.54913610",
        nameNative: null,
        label: "Quebec",
        cityCode: "CA_QC",
      },
      {
        name: "Saskatchewan",
        countryCode: "CA",
        latitude: "52.93991590",
        longitude: "-106.45086390",
        nameNative: null,
        label: "Saskatchewan",
        cityCode: "CA_SK",
      },
      {
        name: "Yukon",
        countryCode: "CA",
        latitude: "35.50672150",
        longitude: "-97.76254410",
        nameNative: null,
        label: "Yukon",
        cityCode: "CA_YT",
      },
    ],
    CV: [
      {
        name: "Barlavento Islands",
        countryCode: "CV",
        latitude: "16.82368450",
        longitude: "-23.99348810",
        nameNative: null,
        label: "Barlavento Islands",
        cityCode: "CV_B",
      },
      {
        name: "Boa Vista",
        countryCode: "CV",
        latitude: "38.74346600",
        longitude: "-120.73042970",
        nameNative: null,
        label: "Boa Vista",
        cityCode: "CV_BV",
      },
      {
        name: "Brava",
        countryCode: "CV",
        latitude: "40.98977780",
        longitude: "-73.68357150",
        nameNative: null,
        label: "Brava",
        cityCode: "CV_BR",
      },
      {
        name: "Maio Municipality",
        countryCode: "CV",
        latitude: "15.20030980",
        longitude: "-23.16797930",
        nameNative: null,
        label: "Maio Municipality",
        cityCode: "CV_MA",
      },
      {
        name: "Mosteiros",
        countryCode: "CV",
        latitude: "37.89043480",
        longitude: "-25.82075560",
        nameNative: null,
        label: "Mosteiros",
        cityCode: "CV_MO",
      },
      {
        name: "Paul",
        countryCode: "CV",
        latitude: "37.06250000",
        longitude: "-95.67706800",
        nameNative: null,
        label: "Paul",
        cityCode: "CV_PA",
      },
      {
        name: "Porto Novo",
        countryCode: "CV",
        latitude: "6.49685740",
        longitude: "2.62885230",
        nameNative: null,
        label: "Porto Novo",
        cityCode: "CV_PN",
      },
      {
        name: "Praia",
        countryCode: "CV",
        latitude: "14.93305000",
        longitude: "-23.51332670",
        nameNative: null,
        label: "Praia",
        cityCode: "CV_PR",
      },
      {
        name: "Ribeira Brava Municipality",
        countryCode: "CV",
        latitude: "16.60707390",
        longitude: "-24.20338430",
        nameNative: null,
        label: "Ribeira Brava Municipality",
        cityCode: "CV_RB",
      },
      {
        name: "Ribeira Grande de Santiago",
        countryCode: "CV",
        latitude: "14.98302980",
        longitude: "-23.65617250",
        nameNative: null,
        label: "Ribeira Grande de Santiago",
        cityCode: "CV_RS",
      },
      {
        name: "Ribeira Grande",
        countryCode: "CV",
        latitude: "37.82103690",
        longitude: "-25.51481370",
        nameNative: null,
        label: "Ribeira Grande",
        cityCode: "CV_RG",
      },
      {
        name: "Sal",
        countryCode: "CV",
        latitude: "26.59581220",
        longitude: "-80.20450830",
        nameNative: null,
        label: "Sal",
        cityCode: "CV_SL",
      },
      {
        name: "Santa Catarina do Fogo",
        countryCode: "CV",
        latitude: "14.93091040",
        longitude: "-24.32225770",
        nameNative: null,
        label: "Santa Catarina do Fogo",
        cityCode: "CV_CF",
      },
      {
        name: "Santa Catarina",
        countryCode: "CV",
        latitude: "-27.24233920",
        longitude: "-50.21885560",
        nameNative: null,
        label: "Santa Catarina",
        cityCode: "CV_CA",
      },
      {
        name: "Santa Cruz",
        countryCode: "CV",
        latitude: "36.97411710",
        longitude: "-122.03079630",
        nameNative: null,
        label: "Santa Cruz",
        cityCode: "CV_CR",
      },
      {
        name: "Sotavento Islands",
        countryCode: "CV",
        latitude: "15.00000000",
        longitude: "-24.00000000",
        nameNative: null,
        label: "Sotavento Islands",
        cityCode: "CV_S",
      },
      {
        name: "São Domingos",
        countryCode: "CV",
        latitude: "15.02861650",
        longitude: "-23.56392200",
        nameNative: null,
        label: "São Domingos",
        cityCode: "CV_SD",
      },
      {
        name: "São Filipe",
        countryCode: "CV",
        latitude: "14.89516790",
        longitude: "-24.49456360",
        nameNative: null,
        label: "São Filipe",
        cityCode: "CV_SF",
      },
      {
        name: "São Lourenço dos Órgãos",
        countryCode: "CV",
        latitude: "15.05378410",
        longitude: "-23.60856120",
        nameNative: null,
        label: "São Lourenço dos Órgãos",
        cityCode: "CV_SO",
      },
      {
        name: "São Miguel",
        countryCode: "CV",
        latitude: "37.78041100",
        longitude: "-25.49704660",
        nameNative: null,
        label: "São Miguel",
        cityCode: "CV_SM",
      },
      {
        name: "São Vicente",
        countryCode: "CV",
        latitude: "-23.96071570",
        longitude: "-46.39620220",
        nameNative: null,
        label: "São Vicente",
        cityCode: "CV_SV",
      },
      {
        name: "Tarrafal de São Nicolau",
        countryCode: "CV",
        latitude: "16.56364980",
        longitude: "-24.35494200",
        nameNative: null,
        label: "Tarrafal de São Nicolau",
        cityCode: "CV_TS",
      },
      {
        name: "Tarrafal",
        countryCode: "CV",
        latitude: "15.27605780",
        longitude: "-23.74840770",
        nameNative: null,
        label: "Tarrafal",
        cityCode: "CV_TA",
      },
    ],
    CF: [
      {
        name: "Bamingui-Bangoran Prefecture",
        countryCode: "CF",
        latitude: "8.27334550",
        longitude: "20.71224650",
        nameNative: null,
        label: "Bamingui-Bangoran Prefecture",
        cityCode: "CF_BB",
      },
      {
        name: "Bangui",
        countryCode: "CF",
        latitude: "4.39467350",
        longitude: "18.55818990",
        nameNative: null,
        label: "Bangui",
        cityCode: "CF_BGF",
      },
      {
        name: "Basse-Kotto Prefecture",
        countryCode: "CF",
        latitude: "4.87193190",
        longitude: "21.28450250",
        nameNative: null,
        label: "Basse-Kotto Prefecture",
        cityCode: "CF_BK",
      },
      {
        name: "Haut-Mbomou Prefecture",
        countryCode: "CF",
        latitude: "6.25371340",
        longitude: "25.47335540",
        nameNative: null,
        label: "Haut-Mbomou Prefecture",
        cityCode: "CF_HM",
      },
      {
        name: "Haute-Kotto Prefecture",
        countryCode: "CF",
        latitude: "7.79643790",
        longitude: "23.38235450",
        nameNative: null,
        label: "Haute-Kotto Prefecture",
        cityCode: "CF_HK",
      },
      {
        name: "Kémo Prefecture",
        countryCode: "CF",
        latitude: "5.88677940",
        longitude: "19.37832060",
        nameNative: null,
        label: "Kémo Prefecture",
        cityCode: "CF_KG",
      },
      {
        name: "Lobaye Prefecture",
        countryCode: "CF",
        latitude: "4.35259810",
        longitude: "17.47951730",
        nameNative: null,
        label: "Lobaye Prefecture",
        cityCode: "CF_LB",
      },
      {
        name: "Mambéré-Kadéï",
        countryCode: "CF",
        latitude: "4.70556530",
        longitude: "15.96998780",
        nameNative: null,
        label: "Mambéré-Kadéï",
        cityCode: "CF_HS",
      },
      {
        name: "Mbomou Prefecture",
        countryCode: "CF",
        latitude: "5.55683700",
        longitude: "23.76328280",
        nameNative: null,
        label: "Mbomou Prefecture",
        cityCode: "CF_MB",
      },
      {
        name: "Nana-Grébizi Economic Prefecture",
        countryCode: "CF",
        latitude: "7.18486070",
        longitude: "19.37832060",
        nameNative: null,
        label: "Nana-Grébizi Economic Prefecture",
        cityCode: "CF_KB",
      },
      {
        name: "Nana-Mambéré Prefecture",
        countryCode: "CF",
        latitude: "5.69321350",
        longitude: "15.21948080",
        nameNative: null,
        label: "Nana-Mambéré Prefecture",
        cityCode: "CF_NM",
      },
      {
        name: "Ombella-M'Poko Prefecture",
        countryCode: "CF",
        latitude: "5.11888250",
        longitude: "18.42760470",
        nameNative: null,
        label: "Ombella-M'Poko Prefecture",
        cityCode: "CF_MP",
      },
      {
        name: "Ouaka Prefecture",
        countryCode: "CF",
        latitude: "6.31682160",
        longitude: "20.71224650",
        nameNative: null,
        label: "Ouaka Prefecture",
        cityCode: "CF_UK",
      },
      {
        name: "Ouham Prefecture",
        countryCode: "CF",
        latitude: "7.09091100",
        longitude: "17.66888700",
        nameNative: null,
        label: "Ouham Prefecture",
        cityCode: "CF_AC",
      },
      {
        name: "Ouham-Pendé Prefecture",
        countryCode: "CF",
        latitude: "6.48509840",
        longitude: "16.15809370",
        nameNative: null,
        label: "Ouham-Pendé Prefecture",
        cityCode: "CF_OP",
      },
      {
        name: "Sangha-Mbaéré",
        countryCode: "CF",
        latitude: "3.43686070",
        longitude: "16.34637910",
        nameNative: null,
        label: "Sangha-Mbaéré",
        cityCode: "CF_SE",
      },
      {
        name: "Vakaga Prefecture",
        countryCode: "CF",
        latitude: "9.51132960",
        longitude: "22.23840170",
        nameNative: null,
        label: "Vakaga Prefecture",
        cityCode: "CF_VK",
      },
    ],
    TD: [
      {
        name: "Bahr el Gazel",
        countryCode: "TD",
        latitude: "14.77022660",
        longitude: "16.91225100",
        nameNative: null,
        label: "Bahr el Gazel",
        cityCode: "TD_BG",
      },
      {
        name: "Batha Region",
        countryCode: "TD",
        latitude: "13.93717750",
        longitude: "18.42760470",
        nameNative: null,
        label: "Batha Region",
        cityCode: "TD_BA",
      },
      {
        name: "Borkou",
        countryCode: "TD",
        latitude: "17.86888450",
        longitude: "18.80761950",
        nameNative: null,
        label: "Borkou",
        cityCode: "TD_BO",
      },
      {
        name: "Ennedi Region",
        countryCode: "TD",
        latitude: "17.54145780",
        longitude: "21.85685860",
        nameNative: null,
        label: "Ennedi Region",
        cityCode: "TD_EN",
      },
      {
        name: "Ennedi-Est",
        countryCode: "TD",
        latitude: "16.34204960",
        longitude: "23.00119890",
        nameNative: null,
        label: "Ennedi-Est",
        cityCode: "TD_EE",
      },
      {
        name: "Ennedi-Ouest",
        countryCode: "TD",
        latitude: "18.97756300",
        longitude: "21.85685860",
        nameNative: null,
        label: "Ennedi-Ouest",
        cityCode: "TD_EO",
      },
      {
        name: "Guéra Region",
        countryCode: "TD",
        latitude: "11.12190150",
        longitude: "18.42760470",
        nameNative: null,
        label: "Guéra Region",
        cityCode: "TD_GR",
      },
      {
        name: "Hadjer-Lamis",
        countryCode: "TD",
        latitude: "12.45772730",
        longitude: "16.72346390",
        nameNative: null,
        label: "Hadjer-Lamis",
        cityCode: "TD_HL",
      },
      {
        name: "Kanem Region",
        countryCode: "TD",
        latitude: "14.87812620",
        longitude: "15.40680790",
        nameNative: null,
        label: "Kanem Region",
        cityCode: "TD_KA",
      },
      {
        name: "Lac Region",
        countryCode: "TD",
        latitude: "13.69153770",
        longitude: "14.10013260",
        nameNative: null,
        label: "Lac Region",
        cityCode: "TD_LC",
      },
      {
        name: "Logone Occidental Region",
        countryCode: "TD",
        latitude: "8.75967600",
        longitude: "15.87600400",
        nameNative: null,
        label: "Logone Occidental Region",
        cityCode: "TD_LO",
      },
      {
        name: "Logone Oriental Region",
        countryCode: "TD",
        latitude: "8.31499490",
        longitude: "16.34637910",
        nameNative: null,
        label: "Logone Oriental Region",
        cityCode: "TD_LR",
      },
      {
        name: "Mandoul Region",
        countryCode: "TD",
        latitude: "8.60309100",
        longitude: "17.47951730",
        nameNative: null,
        label: "Mandoul Region",
        cityCode: "TD_MA",
      },
      {
        name: "Mayo-Kebbi Est Region",
        countryCode: "TD",
        latitude: "9.40460390",
        longitude: "14.84546190",
        nameNative: null,
        label: "Mayo-Kebbi Est Region",
        cityCode: "TD_ME",
      },
      {
        name: "Mayo-Kebbi Ouest Region",
        countryCode: "TD",
        latitude: "10.41130140",
        longitude: "15.59433880",
        nameNative: null,
        label: "Mayo-Kebbi Ouest Region",
        cityCode: "TD_MO",
      },
      {
        name: "Moyen-Chari Region",
        countryCode: "TD",
        latitude: "9.06399980",
        longitude: "18.42760470",
        nameNative: null,
        label: "Moyen-Chari Region",
        cityCode: "TD_MC",
      },
      {
        name: "N'Djamena",
        countryCode: "TD",
        latitude: "12.13484570",
        longitude: "15.05574150",
        nameNative: null,
        label: "N'Djamena",
        cityCode: "TD_ND",
      },
      {
        name: "Ouaddaï Region",
        countryCode: "TD",
        latitude: "13.74847600",
        longitude: "20.71224650",
        nameNative: null,
        label: "Ouaddaï Region",
        cityCode: "TD_OD",
      },
      {
        name: "Salamat Region",
        countryCode: "TD",
        latitude: "10.96916010",
        longitude: "20.71224650",
        nameNative: null,
        label: "Salamat Region",
        cityCode: "TD_SA",
      },
      {
        name: "Sila Region",
        countryCode: "TD",
        latitude: "12.13074000",
        longitude: "21.28450250",
        nameNative: null,
        label: "Sila Region",
        cityCode: "TD_SI",
      },
      {
        name: "Tandjilé Region",
        countryCode: "TD",
        latitude: "9.66257290",
        longitude: "16.72346390",
        nameNative: null,
        label: "Tandjilé Region",
        cityCode: "TD_TA",
      },
      {
        name: "Tibesti Region",
        countryCode: "TD",
        latitude: "21.36500310",
        longitude: "16.91225100",
        nameNative: null,
        label: "Tibesti Region",
        cityCode: "TD_TI",
      },
      {
        name: "Wadi Fira Region",
        countryCode: "TD",
        latitude: "15.08924160",
        longitude: "21.47528510",
        nameNative: null,
        label: "Wadi Fira Region",
        cityCode: "TD_WF",
      },
    ],
    CL: [
      {
        name: "Aisén del General Carlos Ibañez del Campo",
        countryCode: "CL",
        latitude: "-46.37834500",
        longitude: "-72.30076230",
        nameNative: null,
        label: "Aisén del General Carlos Ibañez del Campo",
        cityCode: "CL_AI",
      },
      {
        name: "Antofagasta",
        countryCode: "CL",
        latitude: "-23.83691040",
        longitude: "-69.28775350",
        nameNative: null,
        label: "Antofagasta",
        cityCode: "CL_AN",
      },
      {
        name: "Arica y Parinacota",
        countryCode: "CL",
        latitude: "-18.59404850",
        longitude: "-69.47845410",
        nameNative: null,
        label: "Arica y Parinacota",
        cityCode: "CL_AP",
      },
      {
        name: "Atacama",
        countryCode: "CL",
        latitude: "-27.56605580",
        longitude: "-70.05031400",
        nameNative: null,
        label: "Atacama",
        cityCode: "CL_AT",
      },
      {
        name: "Biobío",
        countryCode: "CL",
        latitude: "-37.44644280",
        longitude: "-72.14161320",
        nameNative: null,
        label: "Biobío",
        cityCode: "CL_BI",
      },
      {
        name: "Coquimbo",
        countryCode: "CL",
        latitude: "-30.54018100",
        longitude: "-70.81199530",
        nameNative: null,
        label: "Coquimbo",
        cityCode: "CL_CO",
      },
      {
        name: "La Araucanía",
        countryCode: "CL",
        latitude: "-38.94892100",
        longitude: "-72.33111300",
        nameNative: null,
        label: "La Araucanía",
        cityCode: "CL_AR",
      },
      {
        name: "Libertador General Bernardo O'Higgins",
        countryCode: "CL",
        latitude: "-34.57553740",
        longitude: "-71.00223110",
        nameNative: null,
        label: "Libertador General Bernardo O'Higgins",
        cityCode: "CL_LI",
      },
      {
        name: "Los Lagos",
        countryCode: "CL",
        latitude: "-41.91977790",
        longitude: "-72.14161320",
        nameNative: null,
        label: "Los Lagos",
        cityCode: "CL_LL",
      },
      {
        name: "Los Ríos",
        countryCode: "CL",
        latitude: "-40.23102170",
        longitude: "-72.33111300",
        nameNative: null,
        label: "Los Ríos",
        cityCode: "CL_LR",
      },
      {
        name: "Magallanes y de la Antártica Chilena",
        countryCode: "CL",
        latitude: "-52.20643160",
        longitude: "-72.16850010",
        nameNative: null,
        label: "Magallanes y de la Antártica Chilena",
        cityCode: "CL_MA",
      },
      {
        name: "Maule",
        countryCode: "CL",
        latitude: "-35.51636030",
        longitude: "-71.57239530",
        nameNative: null,
        label: "Maule",
        cityCode: "CL_ML",
      },
      {
        name: "Región Metropolitana de Santiago",
        countryCode: "CL",
        latitude: "-33.43755450",
        longitude: "-70.65048960",
        nameNative: null,
        label: "Región Metropolitana de Santiago",
        cityCode: "CL_RM",
      },
      {
        name: "Tarapacá",
        countryCode: "CL",
        latitude: "-20.20287990",
        longitude: "-69.28775350",
        nameNative: null,
        label: "Tarapacá",
        cityCode: "CL_TA",
      },
      {
        name: "Valparaíso",
        countryCode: "CL",
        latitude: "-33.04723800",
        longitude: "-71.61268850",
        nameNative: null,
        label: "Valparaíso",
        cityCode: "CL_VS",
      },
      {
        name: "Ñuble",
        countryCode: "CL",
        latitude: "-36.72257430",
        longitude: "-71.76224810",
        nameNative: null,
        label: "Ñuble",
        cityCode: "CL_NB",
      },
    ],
    CN: [
      {
        name: "Anhui",
        countryCode: "CN",
        latitude: "30.60067730",
        longitude: "117.92490020",
        nameNative: "安徽",
        label: "安徽 / Anhui",
        cityCode: "CN_AH",
      },
      {
        name: "Beijing",
        countryCode: "CN",
        latitude: "39.90419990",
        longitude: "116.40739630",
        nameNative: "北京",
        label: "北京 / Beijing",
        cityCode: "CN_BJ",
      },
      {
        name: "Chongqing",
        countryCode: "CN",
        latitude: "29.43158610",
        longitude: "106.91225100",
        nameNative: "重庆",
        label: "重庆 / Chongqing",
        cityCode: "CN_CQ",
      },
      {
        name: "Fujian",
        countryCode: "CN",
        latitude: "26.48368420",
        longitude: "117.92490020",
        nameNative: "福建",
        label: "福建 / Fujian",
        cityCode: "CN_FJ",
      },
      {
        name: "Gansu",
        countryCode: "CN",
        latitude: "35.75183260",
        longitude: "104.28611160",
        nameNative: "甘肃",
        label: "甘肃 / Gansu",
        cityCode: "CN_GS",
      },
      {
        name: "Guangdong",
        countryCode: "CN",
        latitude: "23.37903330",
        longitude: "113.76328280",
        nameNative: "广东",
        label: "广东 / Guangdong",
        cityCode: "CN_GD",
      },
      {
        name: "Guangxi Zhuang",
        countryCode: "CN",
        latitude: "23.72475990",
        longitude: "108.80761950",
        nameNative: "广西壮族",
        label: "广西壮族 / Guangxi Zhuang",
        cityCode: "CN_GX",
      },
      {
        name: "Guizhou",
        countryCode: "CN",
        latitude: "26.84296450",
        longitude: "107.29028390",
        nameNative: "贵州",
        label: "贵州 / Guizhou",
        cityCode: "CN_GZ",
      },
      {
        name: "Hainan",
        countryCode: "CN",
        latitude: "19.56639470",
        longitude: "109.94968600",
        nameNative: "海南",
        label: "海南 / Hainan",
        cityCode: "CN_HI",
      },
      {
        name: "Hebei",
        countryCode: "CN",
        latitude: "37.89565940",
        longitude: "114.90422080",
        nameNative: "河北",
        label: "河北 / Hebei",
        cityCode: "CN_HE",
      },
      {
        name: "Heilongjiang",
        countryCode: "CN",
        latitude: "47.12164720",
        longitude: "128.73823100",
        nameNative: "黑龙江",
        label: "黑龙江 / Heilongjiang",
        cityCode: "CN_HL",
      },
      {
        name: "Henan",
        countryCode: "CN",
        latitude: "34.29043020",
        longitude: "113.38235450",
        nameNative: "河南",
        label: "河南 / Henan",
        cityCode: "CN_HA",
      },
      {
        name: "Hong Kong SAR",
        countryCode: "CN",
        latitude: "22.31930390",
        longitude: "114.16936110",
        nameNative: "香港特别行政区",
        label: "香港特别行政区 / Hong Kong SAR",
        cityCode: "CN_HK",
      },
      {
        name: "Hubei",
        countryCode: "CN",
        latitude: "30.73781180",
        longitude: "112.23840170",
        nameNative: "湖北",
        label: "湖北 / Hubei",
        cityCode: "CN_HB",
      },
      {
        name: "Hunan",
        countryCode: "CN",
        latitude: "36.73412940",
        longitude: "-95.93449020",
        nameNative: "湖南",
        label: "湖南 / Hunan",
        cityCode: "CN_HN",
      },
      {
        name: "Inner Mongolia",
        countryCode: "CN",
        latitude: "43.37822000",
        longitude: "115.05948150",
        nameNative: "内蒙古",
        label: "内蒙古 / Inner Mongolia",
        cityCode: "CN_NM",
      },
      {
        name: "Jiangsu",
        countryCode: "CN",
        latitude: "33.14017150",
        longitude: "119.78892480",
        nameNative: "江苏",
        label: "江苏 / Jiangsu",
        cityCode: "CN_JS",
      },
      {
        name: "Jiangxi",
        countryCode: "CN",
        latitude: "27.08745640",
        longitude: "114.90422080",
        nameNative: "江西",
        label: "江西 / Jiangxi",
        cityCode: "CN_JX",
      },
      {
        name: "Jilin",
        countryCode: "CN",
        latitude: "43.83788300",
        longitude: "126.54957200",
        nameNative: "吉林",
        label: "吉林 / Jilin",
        cityCode: "CN_JL",
      },
      {
        name: "Liaoning",
        countryCode: "CN",
        latitude: "41.94365430",
        longitude: "122.52903760",
        nameNative: "辽宁",
        label: "辽宁 / Liaoning",
        cityCode: "CN_LN",
      },
      {
        name: "Macau SAR",
        countryCode: "CN",
        latitude: "22.19874500",
        longitude: "113.54387300",
        nameNative: "澳门特别行政区",
        label: "澳门特别行政区 / Macau SAR",
        cityCode: "CN_MO",
      },
      {
        name: "Ningxia Huizu",
        countryCode: "CN",
        latitude: "37.19873100",
        longitude: "106.15809370",
        nameNative: "宁夏回族",
        label: "宁夏回族 / Ningxia Huizu",
        cityCode: "CN_NX",
      },
      {
        name: "Qinghai",
        countryCode: "CN",
        latitude: "35.74479800",
        longitude: "96.40773580",
        nameNative: "青海",
        label: "青海 / Qinghai",
        cityCode: "CN_QH",
      },
      {
        name: "Shaanxi",
        countryCode: "CN",
        latitude: "35.39399080",
        longitude: "109.18800470",
        nameNative: "陕西",
        label: "陕西 / Shaanxi",
        cityCode: "CN_SN",
      },
      {
        name: "Shandong",
        countryCode: "CN",
        latitude: "37.80060640",
        longitude: "-122.26999180",
        nameNative: "山东",
        label: "山东 / Shandong",
        cityCode: "CN_SD",
      },
      {
        name: "Shanghai",
        countryCode: "CN",
        latitude: "31.23041600",
        longitude: "121.47370100",
        nameNative: "上海",
        label: "上海 / Shanghai",
        cityCode: "CN_SH",
      },
      {
        name: "Shanxi",
        countryCode: "CN",
        latitude: "37.24256490",
        longitude: "111.85685860",
        nameNative: "山西",
        label: "山西 / Shanxi",
        cityCode: "CN_SX",
      },
      {
        name: "Sichuan",
        countryCode: "CN",
        latitude: "30.26380320",
        longitude: "102.80547530",
        nameNative: "四川",
        label: "四川 / Sichuan",
        cityCode: "CN_SC",
      },
      {
        name: "Taiwan",
        countryCode: "CN",
        latitude: "23.69781000",
        longitude: "120.96051500",
        nameNative: null,
        label: "Taiwan",
        cityCode: "CN_TW",
      },
      {
        name: "Tianjin",
        countryCode: "CN",
        latitude: "39.12522910",
        longitude: "117.01534350",
        nameNative: "天津",
        label: "天津 / Tianjin",
        cityCode: "CN_TJ",
      },
      {
        name: "Xinjiang",
        countryCode: "CN",
        latitude: "42.52463570",
        longitude: "87.53958550",
        nameNative: "新疆维吾尔",
        label: "新疆维吾尔 / Xinjiang",
        cityCode: "CN_XJ",
      },
      {
        name: "Xizang",
        countryCode: "CN",
        latitude: "30.15336050",
        longitude: "88.78786780",
        nameNative: "西藏",
        label: "西藏 / Xizang",
        cityCode: "CN_XZ",
      },
      {
        name: "Yunnan",
        countryCode: "CN",
        latitude: "24.47528470",
        longitude: "101.34310580",
        nameNative: "云南",
        label: "云南 / Yunnan",
        cityCode: "CN_YN",
      },
      {
        name: "Zhejiang",
        countryCode: "CN",
        latitude: "29.14164320",
        longitude: "119.78892480",
        nameNative: "浙江",
        label: "浙江 / Zhejiang",
        cityCode: "CN_ZJ",
      },
    ],
    CO: [
      {
        name: "Amazonas",
        countryCode: "CO",
        latitude: "-1.44291230",
        longitude: "-71.57239530",
        nameNative: null,
        label: "Amazonas",
        cityCode: "CO_AMA",
      },
      {
        name: "Antioquia",
        countryCode: "CO",
        latitude: "7.19860640",
        longitude: "-75.34121790",
        nameNative: null,
        label: "Antioquia",
        cityCode: "CO_ANT",
      },
      {
        name: "Arauca",
        countryCode: "CO",
        latitude: "6.54730600",
        longitude: "-71.00223110",
        nameNative: null,
        label: "Arauca",
        cityCode: "CO_ARA",
      },
      {
        name: "Archipiélago de San Andrés, Providencia y Santa Catalina",
        countryCode: "CO",
        latitude: "12.55673240",
        longitude: "-81.71852530",
        nameNative: null,
        label: "Archipiélago de San Andrés, Providencia y Santa Catalina",
        cityCode: "CO_SAP",
      },
      {
        name: "Atlántico",
        countryCode: "CO",
        latitude: "10.69661590",
        longitude: "-74.87410450",
        nameNative: null,
        label: "Atlántico",
        cityCode: "CO_ATL",
      },
      {
        name: "Bogotá D.C.",
        countryCode: "CO",
        latitude: "4.28204150",
        longitude: "-74.50270420",
        nameNative: null,
        label: "Bogotá D.C.",
        cityCode: "CO_DC",
      },
      {
        name: "Bolívar",
        countryCode: "CO",
        latitude: "8.67043820",
        longitude: "-74.03001220",
        nameNative: null,
        label: "Bolívar",
        cityCode: "CO_BOL",
      },
      {
        name: "Boyacá",
        countryCode: "CO",
        latitude: "5.45451100",
        longitude: "-73.36200300",
        nameNative: null,
        label: "Boyacá",
        cityCode: "CO_BOY",
      },
      {
        name: "Caldas",
        countryCode: "CO",
        latitude: "5.29826000",
        longitude: "-75.24790610",
        nameNative: null,
        label: "Caldas",
        cityCode: "CO_CAL",
      },
      {
        name: "Caquetá",
        countryCode: "CO",
        latitude: "0.86989200",
        longitude: "-73.84190630",
        nameNative: null,
        label: "Caquetá",
        cityCode: "CO_CAQ",
      },
      {
        name: "Casanare",
        countryCode: "CO",
        latitude: "5.75892690",
        longitude: "-71.57239530",
        nameNative: null,
        label: "Casanare",
        cityCode: "CO_CAS",
      },
      {
        name: "Cauca",
        countryCode: "CO",
        latitude: "2.70498130",
        longitude: "-76.82596520",
        nameNative: null,
        label: "Cauca",
        cityCode: "CO_CAU",
      },
      {
        name: "Cesar",
        countryCode: "CO",
        latitude: "9.33729480",
        longitude: "-73.65362090",
        nameNative: null,
        label: "Cesar",
        cityCode: "CO_CES",
      },
      {
        name: "Chocó",
        countryCode: "CO",
        latitude: "5.25280330",
        longitude: "-76.82596520",
        nameNative: null,
        label: "Chocó",
        cityCode: "CO_CHO",
      },
      {
        name: "Cundinamarca",
        countryCode: "CO",
        latitude: "5.02600300",
        longitude: "-74.03001220",
        nameNative: null,
        label: "Cundinamarca",
        cityCode: "CO_CUN",
      },
      {
        name: "Córdoba",
        countryCode: "CO",
        latitude: "8.04929300",
        longitude: "-75.57405000",
        nameNative: null,
        label: "Córdoba",
        cityCode: "CO_COR",
      },
      {
        name: "Guainía",
        countryCode: "CO",
        latitude: "2.58539300",
        longitude: "-68.52471490",
        nameNative: null,
        label: "Guainía",
        cityCode: "CO_GUA",
      },
      {
        name: "Guaviare",
        countryCode: "CO",
        latitude: "2.04392400",
        longitude: "-72.33111300",
        nameNative: null,
        label: "Guaviare",
        cityCode: "CO_GUV",
      },
      {
        name: "Huila",
        countryCode: "CO",
        latitude: "2.53593490",
        longitude: "-75.52766990",
        nameNative: null,
        label: "Huila",
        cityCode: "CO_HUI",
      },
      {
        name: "La Guajira",
        countryCode: "CO",
        latitude: "11.35477430",
        longitude: "-72.52048270",
        nameNative: null,
        label: "La Guajira",
        cityCode: "CO_LAG",
      },
      {
        name: "Magdalena",
        countryCode: "CO",
        latitude: "10.41130140",
        longitude: "-74.40566120",
        nameNative: null,
        label: "Magdalena",
        cityCode: "CO_MAG",
      },
      {
        name: "Meta",
        countryCode: "CO",
        latitude: "39.76732580",
        longitude: "-104.97535950",
        nameNative: null,
        label: "Meta",
        cityCode: "CO_MET",
      },
      {
        name: "Nariño",
        countryCode: "CO",
        latitude: "1.28915100",
        longitude: "-77.35794000",
        nameNative: null,
        label: "Nariño",
        cityCode: "CO_NAR",
      },
      {
        name: "Norte de Santander",
        countryCode: "CO",
        latitude: "7.94628310",
        longitude: "-72.89880690",
        nameNative: null,
        label: "Norte de Santander",
        cityCode: "CO_NSA",
      },
      {
        name: "Putumayo",
        countryCode: "CO",
        latitude: "0.43595060",
        longitude: "-75.52766990",
        nameNative: null,
        label: "Putumayo",
        cityCode: "CO_PUT",
      },
      {
        name: "Quindío",
        countryCode: "CO",
        latitude: "4.46101910",
        longitude: "-75.66735600",
        nameNative: null,
        label: "Quindío",
        cityCode: "CO_QUI",
      },
      {
        name: "Risaralda",
        countryCode: "CO",
        latitude: "5.31584750",
        longitude: "-75.99276520",
        nameNative: null,
        label: "Risaralda",
        cityCode: "CO_RIS",
      },
      {
        name: "Santander",
        countryCode: "CO",
        latitude: "6.64370760",
        longitude: "-73.65362090",
        nameNative: null,
        label: "Santander",
        cityCode: "CO_SAN",
      },
      {
        name: "Sucre",
        countryCode: "CO",
        latitude: "8.81397700",
        longitude: "-74.72328300",
        nameNative: null,
        label: "Sucre",
        cityCode: "CO_SUC",
      },
      {
        name: "Tolima",
        countryCode: "CO",
        latitude: "4.09251680",
        longitude: "-75.15453810",
        nameNative: null,
        label: "Tolima",
        cityCode: "CO_TOL",
      },
      {
        name: "Valle del Cauca",
        countryCode: "CO",
        latitude: "3.80088930",
        longitude: "-76.64127120",
        nameNative: null,
        label: "Valle del Cauca",
        cityCode: "CO_VAC",
      },
      {
        name: "Vaupés",
        countryCode: "CO",
        latitude: "0.85535610",
        longitude: "-70.81199530",
        nameNative: null,
        label: "Vaupés",
        cityCode: "CO_VAU",
      },
      {
        name: "Vichada",
        countryCode: "CO",
        latitude: "4.42344520",
        longitude: "-69.28775350",
        nameNative: null,
        label: "Vichada",
        cityCode: "CO_VID",
      },
    ],
    KM: [
      {
        name: "Anjouan",
        countryCode: "KM",
        latitude: "-12.21381450",
        longitude: "44.43706060",
        nameNative: null,
        label: "Anjouan",
        cityCode: "KM_A",
      },
      {
        name: "Grande Comore",
        countryCode: "KM",
        latitude: "-11.71673380",
        longitude: "43.36807880",
        nameNative: null,
        label: "Grande Comore",
        cityCode: "KM_G",
      },
      {
        name: "Mohéli",
        countryCode: "KM",
        latitude: "-12.33773760",
        longitude: "43.73340890",
        nameNative: null,
        label: "Mohéli",
        cityCode: "KM_M",
      },
    ],
    CG: [
      {
        name: "Bouenza Department",
        countryCode: "CG",
        latitude: "-4.11280790",
        longitude: "13.72891670",
        nameNative: null,
        label: "Bouenza Department",
        cityCode: "CG_11",
      },
      {
        name: "Brazzaville",
        countryCode: "CG",
        latitude: "-4.26335970",
        longitude: "15.24288530",
        nameNative: null,
        label: "Brazzaville",
        cityCode: "CG_BZV",
      },
      {
        name: "Cuvette Department",
        countryCode: "CG",
        latitude: "-0.28774460",
        longitude: "16.15809370",
        nameNative: null,
        label: "Cuvette Department",
        cityCode: "CG_8",
      },
      {
        name: "Cuvette-Ouest Department",
        countryCode: "CG",
        latitude: "0.14475500",
        longitude: "14.47233010",
        nameNative: null,
        label: "Cuvette-Ouest Department",
        cityCode: "CG_15",
      },
      {
        name: "Kouilou Department",
        countryCode: "CG",
        latitude: "-4.14284130",
        longitude: "11.88917210",
        nameNative: null,
        label: "Kouilou Department",
        cityCode: "CG_5",
      },
      {
        name: "Likouala Department",
        countryCode: "CG",
        latitude: "2.04392400",
        longitude: "17.66888700",
        nameNative: null,
        label: "Likouala Department",
        cityCode: "CG_7",
      },
      {
        name: "Lékoumou Department",
        countryCode: "CG",
        latitude: "-3.17038200",
        longitude: "13.35872880",
        nameNative: null,
        label: "Lékoumou Department",
        cityCode: "CG_2",
      },
      {
        name: "Niari Department",
        countryCode: "CG",
        latitude: "-3.18427000",
        longitude: "12.25479190",
        nameNative: null,
        label: "Niari Department",
        cityCode: "CG_9",
      },
      {
        name: "Plateaux Department",
        countryCode: "CG",
        latitude: "-2.06800880",
        longitude: "15.40680790",
        nameNative: null,
        label: "Plateaux Department",
        cityCode: "CG_14",
      },
      {
        name: "Pointe-Noire",
        countryCode: "CG",
        latitude: "-4.76916230",
        longitude: "11.86636200",
        nameNative: null,
        label: "Pointe-Noire",
        cityCode: "CG_16",
      },
      {
        name: "Pool Department",
        countryCode: "CG",
        latitude: "-3.77626280",
        longitude: "14.84546190",
        nameNative: null,
        label: "Pool Department",
        cityCode: "CG_12",
      },
      {
        name: "Sangha Department",
        countryCode: "CG",
        latitude: "1.46623280",
        longitude: "15.40680790",
        nameNative: null,
        label: "Sangha Department",
        cityCode: "CG_13",
      },
    ],
    CD: [
      {
        name: "Bas-Uélé",
        countryCode: "CD",
        latitude: "3.99010090",
        longitude: "24.90422080",
        nameNative: null,
        label: "Bas-Uélé",
        cityCode: "CD_BU",
      },
      {
        name: "Haut-Katanga",
        countryCode: "CD",
        latitude: "-10.41020750",
        longitude: "27.54958460",
        nameNative: null,
        label: "Haut-Katanga",
        cityCode: "CD_HK",
      },
      {
        name: "Haut-Lomami",
        countryCode: "CD",
        latitude: "-7.70527520",
        longitude: "24.90422080",
        nameNative: null,
        label: "Haut-Lomami",
        cityCode: "CD_HL",
      },
      {
        name: "Haut-Uélé",
        countryCode: "CD",
        latitude: "3.58451540",
        longitude: "28.29943500",
        nameNative: null,
        label: "Haut-Uélé",
        cityCode: "CD_HU",
      },
      {
        name: "Ituri",
        countryCode: "CD",
        latitude: "1.59576820",
        longitude: "29.41793240",
        nameNative: null,
        label: "Ituri",
        cityCode: "CD_IT",
      },
      {
        name: "Kasaï Central",
        countryCode: "CD",
        latitude: "-8.44045910",
        longitude: "20.41659340",
        nameNative: null,
        label: "Kasaï Central",
        cityCode: "CD_KC",
      },
      {
        name: "Kasaï Oriental",
        countryCode: "CD",
        latitude: "-6.03362300",
        longitude: "23.57285010",
        nameNative: null,
        label: "Kasaï Oriental",
        cityCode: "CD_KE",
      },
      {
        name: "Kasaï",
        countryCode: "CD",
        latitude: "-5.04719790",
        longitude: "20.71224650",
        nameNative: null,
        label: "Kasaï",
        cityCode: "CD_KS",
      },
      {
        name: "Kinshasa",
        countryCode: "CD",
        latitude: "-4.44193110",
        longitude: "15.26629310",
        nameNative: null,
        label: "Kinshasa",
        cityCode: "CD_KN",
      },
      {
        name: "Kongo Central",
        countryCode: "CD",
        latitude: "-5.23656850",
        longitude: "13.91439900",
        nameNative: null,
        label: "Kongo Central",
        cityCode: "CD_BC",
      },
      {
        name: "Kwango",
        countryCode: "CD",
        latitude: "-6.43374090",
        longitude: "17.66888700",
        nameNative: null,
        label: "Kwango",
        cityCode: "CD_KG",
      },
      {
        name: "Kwilu",
        countryCode: "CD",
        latitude: "-5.11888250",
        longitude: "18.42760470",
        nameNative: null,
        label: "Kwilu",
        cityCode: "CD_KL",
      },
      {
        name: "Lomami",
        countryCode: "CD",
        latitude: "-6.14539310",
        longitude: "24.52426400",
        nameNative: null,
        label: "Lomami",
        cityCode: "CD_LO",
      },
      {
        name: "Lualaba",
        countryCode: "CD",
        latitude: "-10.48086980",
        longitude: "25.62978160",
        nameNative: null,
        label: "Lualaba",
        cityCode: "CD_LU",
      },
      {
        name: "Mai-Ndombe",
        countryCode: "CD",
        latitude: "-2.63574340",
        longitude: "18.42760470",
        nameNative: null,
        label: "Mai-Ndombe",
        cityCode: "CD_MN",
      },
      {
        name: "Maniema",
        countryCode: "CD",
        latitude: "-3.07309290",
        longitude: "26.04138890",
        nameNative: null,
        label: "Maniema",
        cityCode: "CD_MA",
      },
      {
        name: "Mongala",
        countryCode: "CD",
        latitude: "1.99623240",
        longitude: "21.47528510",
        nameNative: null,
        label: "Mongala",
        cityCode: "CD_MO",
      },
      {
        name: "Nord-Kivu",
        countryCode: "CD",
        latitude: "-0.79177290",
        longitude: "29.04599270",
        nameNative: null,
        label: "Nord-Kivu",
        cityCode: "CD_NK",
      },
      {
        name: "Nord-Ubangi",
        countryCode: "CD",
        latitude: "3.78787260",
        longitude: "21.47528510",
        nameNative: null,
        label: "Nord-Ubangi",
        cityCode: "CD_NU",
      },
      {
        name: "Sankuru",
        countryCode: "CD",
        latitude: "-2.84374530",
        longitude: "23.38235450",
        nameNative: null,
        label: "Sankuru",
        cityCode: "CD_SA",
      },
      {
        name: "Sud-Kivu",
        countryCode: "CD",
        latitude: "-3.01165800",
        longitude: "28.29943500",
        nameNative: null,
        label: "Sud-Kivu",
        cityCode: "CD_SK",
      },
      {
        name: "Sud-Ubangi",
        countryCode: "CD",
        latitude: "3.22999420",
        longitude: "19.18800470",
        nameNative: null,
        label: "Sud-Ubangi",
        cityCode: "CD_SU",
      },
      {
        name: "Tanganyika",
        countryCode: "CD",
        latitude: "-6.27401180",
        longitude: "27.92490020",
        nameNative: null,
        label: "Tanganyika",
        cityCode: "CD_TA",
      },
      {
        name: "Tshopo",
        countryCode: "CD",
        latitude: "0.54554620",
        longitude: "24.90422080",
        nameNative: null,
        label: "Tshopo",
        cityCode: "CD_TO",
      },
      {
        name: "Tshuapa",
        countryCode: "CD",
        latitude: "-0.99030230",
        longitude: "23.02888440",
        nameNative: null,
        label: "Tshuapa",
        cityCode: "CD_TU",
      },
      {
        name: "Équateur",
        countryCode: "CD",
        latitude: "-1.83123900",
        longitude: "-78.18340600",
        nameNative: null,
        label: "Équateur",
        cityCode: "CD_EQ",
      },
    ],
    CR: [
      {
        name: "Alajuela Province",
        countryCode: "CR",
        latitude: "10.39158300",
        longitude: "-84.43827210",
        nameNative: null,
        label: "Alajuela Province",
        cityCode: "CR_A",
      },
      {
        name: "Guanacaste Province",
        countryCode: "CR",
        latitude: "10.62673990",
        longitude: "-85.44367060",
        nameNative: null,
        label: "Guanacaste Province",
        cityCode: "CR_G",
      },
      {
        name: "Heredia Province",
        countryCode: "CR",
        latitude: "10.47352300",
        longitude: "-84.01674230",
        nameNative: null,
        label: "Heredia Province",
        cityCode: "CR_H",
      },
      {
        name: "Limón Province",
        countryCode: "CR",
        latitude: "9.98963980",
        longitude: "-83.03324170",
        nameNative: null,
        label: "Limón Province",
        cityCode: "CR_L",
      },
      {
        name: "Provincia de Cartago",
        countryCode: "CR",
        latitude: "9.86223110",
        longitude: "-83.92141870",
        nameNative: null,
        label: "Provincia de Cartago",
        cityCode: "CR_C",
      },
      {
        name: "Puntarenas Province",
        countryCode: "CR",
        latitude: "9.21695310",
        longitude: "-83.33618800",
        nameNative: null,
        label: "Puntarenas Province",
        cityCode: "CR_P",
      },
      {
        name: "San José Province",
        countryCode: "CR",
        latitude: "9.91297270",
        longitude: "-84.07682940",
        nameNative: null,
        label: "San José Province",
        cityCode: "CR_SJ",
      },
    ],
    CI: [
      {
        name: "Abidjan",
        countryCode: "CI",
        latitude: "5.35995170",
        longitude: "-4.00825630",
        nameNative: null,
        label: "Abidjan",
        cityCode: "CI_AB",
      },
      {
        name: "Agnéby",
        countryCode: "CI",
        latitude: "5.32245030",
        longitude: "-4.34495290",
        nameNative: null,
        label: "Agnéby",
        cityCode: "CI_16",
      },
      {
        name: "Bafing Region",
        countryCode: "CI",
        latitude: "8.32520470",
        longitude: "-7.52472430",
        nameNative: null,
        label: "Bafing Region",
        cityCode: "CI_17",
      },
      {
        name: "Bas-Sassandra District",
        countryCode: "CI",
        latitude: "5.27983560",
        longitude: "-6.15269850",
        nameNative: null,
        label: "Bas-Sassandra District",
        cityCode: "CI_BS",
      },
      {
        name: "Bas-Sassandra Region",
        countryCode: "CI",
        latitude: "5.35679160",
        longitude: "-6.74939930",
        nameNative: null,
        label: "Bas-Sassandra Region",
        cityCode: "CI_09",
      },
      {
        name: "Comoé District",
        countryCode: "CI",
        latitude: "5.55279300",
        longitude: "-3.25836260",
        nameNative: null,
        label: "Comoé District",
        cityCode: "CI_CM",
      },
      {
        name: "Denguélé District",
        countryCode: "CI",
        latitude: "48.07077630",
        longitude: "-68.56093410",
        nameNative: null,
        label: "Denguélé District",
        cityCode: "CI_DN",
      },
      {
        name: "Denguélé Region",
        countryCode: "CI",
        latitude: "9.46623720",
        longitude: "-7.43813550",
        nameNative: null,
        label: "Denguélé Region",
        cityCode: "CI_10",
      },
      {
        name: "Dix-Huit Montagnes",
        countryCode: "CI",
        latitude: "7.37623730",
        longitude: "-7.43813550",
        nameNative: null,
        label: "Dix-Huit Montagnes",
        cityCode: "CI_06",
      },
      {
        name: "Fromager",
        countryCode: "CI",
        latitude: "45.54502130",
        longitude: "-73.60462230",
        nameNative: null,
        label: "Fromager",
        cityCode: "CI_18",
      },
      {
        name: "Gôh-Djiboua District",
        countryCode: "CI",
        latitude: "5.87113930",
        longitude: "-5.56172790",
        nameNative: null,
        label: "Gôh-Djiboua District",
        cityCode: "CI_GD",
      },
      {
        name: "Haut-Sassandra",
        countryCode: "CI",
        latitude: "6.87578480",
        longitude: "-6.57833870",
        nameNative: null,
        label: "Haut-Sassandra",
        cityCode: "CI_02",
      },
      {
        name: "Lacs District",
        countryCode: "CI",
        latitude: "48.19801690",
        longitude: "-80.45644120",
        nameNative: null,
        label: "Lacs District",
        cityCode: "CI_LC",
      },
      {
        name: "Lacs Region",
        countryCode: "CI",
        latitude: "47.73958660",
        longitude: "-70.41866520",
        nameNative: null,
        label: "Lacs Region",
        cityCode: "CI_07",
      },
      {
        name: "Lagunes District",
        countryCode: "CI",
        latitude: "5.88273340",
        longitude: "-4.23333550",
        nameNative: null,
        label: "Lagunes District",
        cityCode: "CI_LG",
      },
      {
        name: "Lagunes region",
        countryCode: "CI",
        latitude: "5.88273340",
        longitude: "-4.23333550",
        nameNative: null,
        label: "Lagunes region",
        cityCode: "CI_01",
      },
      {
        name: "Marahoué Region",
        countryCode: "CI",
        latitude: "6.88462070",
        longitude: "-5.89871390",
        nameNative: null,
        label: "Marahoué Region",
        cityCode: "CI_12",
      },
      {
        name: "Montagnes District",
        countryCode: "CI",
        latitude: "7.37623730",
        longitude: "-7.43813550",
        nameNative: null,
        label: "Montagnes District",
        cityCode: "CI_MG",
      },
      {
        name: "Moyen-Cavally",
        countryCode: "CI",
        latitude: "6.52087930",
        longitude: "-7.61142170",
        nameNative: null,
        label: "Moyen-Cavally",
        cityCode: "CI_19",
      },
      {
        name: "Moyen-Comoé",
        countryCode: "CI",
        latitude: "6.65149170",
        longitude: "-3.50034540",
        nameNative: null,
        label: "Moyen-Comoé",
        cityCode: "CI_05",
      },
      {
        name: "N'zi-Comoé",
        countryCode: "CI",
        latitude: "7.24567490",
        longitude: "-4.23333550",
        nameNative: null,
        label: "N'zi-Comoé",
        cityCode: "CI_11",
      },
      {
        name: "Sassandra-Marahoué District",
        countryCode: "CI",
        latitude: "6.88033480",
        longitude: "-6.23759470",
        nameNative: null,
        label: "Sassandra-Marahoué District",
        cityCode: "CI_SM",
      },
      {
        name: "Savanes Region",
        countryCode: "CI",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Savanes Region",
        cityCode: "CI_03",
      },
      {
        name: "Sud-Bandama",
        countryCode: "CI",
        latitude: "5.53570830",
        longitude: "-5.56172790",
        nameNative: null,
        label: "Sud-Bandama",
        cityCode: "CI_15",
      },
      {
        name: "Sud-Comoé",
        countryCode: "CI",
        latitude: "5.55279300",
        longitude: "-3.25836260",
        nameNative: null,
        label: "Sud-Comoé",
        cityCode: "CI_13",
      },
      {
        name: "Vallée du Bandama District",
        countryCode: "CI",
        latitude: "8.27897800",
        longitude: "-4.89356270",
        nameNative: null,
        label: "Vallée du Bandama District",
        cityCode: "CI_VB",
      },
      {
        name: "Vallée du Bandama Region",
        countryCode: "CI",
        latitude: "8.27897800",
        longitude: "-4.89356270",
        nameNative: null,
        label: "Vallée du Bandama Region",
        cityCode: "CI_04",
      },
      {
        name: "Woroba District",
        countryCode: "CI",
        latitude: "8.24913720",
        longitude: "-6.92091350",
        nameNative: null,
        label: "Woroba District",
        cityCode: "CI_WR",
      },
      {
        name: "Worodougou",
        countryCode: "CI",
        latitude: "8.25489620",
        longitude: "-6.57833870",
        nameNative: null,
        label: "Worodougou",
        cityCode: "CI_14",
      },
      {
        name: "Yamoussoukro",
        countryCode: "CI",
        latitude: "6.82762280",
        longitude: "-5.28934330",
        nameNative: null,
        label: "Yamoussoukro",
        cityCode: "CI_YM",
      },
      {
        name: "Zanzan Region",
        countryCode: "CI",
        latitude: "8.82079040",
        longitude: "-3.41955270",
        nameNative: null,
        label: "Zanzan Region",
        cityCode: "CI_ZZ",
      },
    ],
    HR: [
      {
        name: "Bjelovar-Bilogora County",
        countryCode: "HR",
        latitude: "45.89879720",
        longitude: "16.84230930",
        nameNative: null,
        label: "Bjelovar-Bilogora County",
        cityCode: "HR_07",
      },
      {
        name: "Brod-Posavina County",
        countryCode: "HR",
        latitude: "45.26379510",
        longitude: "17.32645620",
        nameNative: null,
        label: "Brod-Posavina County",
        cityCode: "HR_12",
      },
      {
        name: "Dubrovnik-Neretva County",
        countryCode: "HR",
        latitude: "43.07665880",
        longitude: "17.52684710",
        nameNative: null,
        label: "Dubrovnik-Neretva County",
        cityCode: "HR_19",
      },
      {
        name: "Istria County",
        countryCode: "HR",
        latitude: "45.12864550",
        longitude: "13.90154200",
        nameNative: null,
        label: "Istria County",
        cityCode: "HR_18",
      },
      {
        name: "Karlovac County",
        countryCode: "HR",
        latitude: "45.26133520",
        longitude: "15.52542016",
        nameNative: null,
        label: "Karlovac County",
        cityCode: "HR_05",
      },
      {
        name: "Koprivnica-Križevci County",
        countryCode: "HR",
        latitude: "46.15689190",
        longitude: "16.83908260",
        nameNative: null,
        label: "Koprivnica-Križevci County",
        cityCode: "HR_06",
      },
      {
        name: "Krapina-Zagorje County",
        countryCode: "HR",
        latitude: "46.10133930",
        longitude: "15.88096930",
        nameNative: null,
        label: "Krapina-Zagorje County",
        cityCode: "HR_02",
      },
      {
        name: "Lika-Senj County",
        countryCode: "HR",
        latitude: "44.61922180",
        longitude: "15.47016080",
        nameNative: null,
        label: "Lika-Senj County",
        cityCode: "HR_09",
      },
      {
        name: "Međimurje County",
        countryCode: "HR",
        latitude: "46.37666440",
        longitude: "16.42132980",
        nameNative: null,
        label: "Međimurje County",
        cityCode: "HR_20",
      },
      {
        name: "Osijek-Baranja County",
        countryCode: "HR",
        latitude: "45.55764280",
        longitude: "18.39421410",
        nameNative: null,
        label: "Osijek-Baranja County",
        cityCode: "HR_14",
      },
      {
        name: "Požega-Slavonia County",
        countryCode: "HR",
        latitude: "45.34178680",
        longitude: "17.81143590",
        nameNative: null,
        label: "Požega-Slavonia County",
        cityCode: "HR_11",
      },
      {
        name: "Primorje-Gorski Kotar County",
        countryCode: "HR",
        latitude: "45.31739960",
        longitude: "14.81674660",
        nameNative: null,
        label: "Primorje-Gorski Kotar County",
        cityCode: "HR_08",
      },
      {
        name: "Sisak-Moslavina County",
        countryCode: "HR",
        latitude: "45.38379260",
        longitude: "16.53809940",
        nameNative: null,
        label: "Sisak-Moslavina County",
        cityCode: "HR_03",
      },
      {
        name: "Split-Dalmatia County",
        countryCode: "HR",
        latitude: "43.52403280",
        longitude: "16.81783770",
        nameNative: null,
        label: "Split-Dalmatia County",
        cityCode: "HR_17",
      },
      {
        name: "Varaždin County",
        countryCode: "HR",
        latitude: "46.23174730",
        longitude: "16.33605590",
        nameNative: null,
        label: "Varaždin County",
        cityCode: "HR_05",
      },
      {
        name: "Virovitica-Podravina County",
        countryCode: "HR",
        latitude: "45.65579850",
        longitude: "17.79324720",
        nameNative: null,
        label: "Virovitica-Podravina County",
        cityCode: "HR_10",
      },
      {
        name: "Vukovar-Syrmia County",
        countryCode: "HR",
        latitude: "45.17735520",
        longitude: "18.80535270",
        nameNative: null,
        label: "Vukovar-Syrmia County",
        cityCode: "HR_16",
      },
      {
        name: "Zadar County",
        countryCode: "HR",
        latitude: "44.14693900",
        longitude: "15.61649430",
        nameNative: null,
        label: "Zadar County",
        cityCode: "HR_13",
      },
      {
        name: "Zagreb County",
        countryCode: "HR",
        latitude: "45.87066120",
        longitude: "16.39549100",
        nameNative: null,
        label: "Zagreb County",
        cityCode: "HR_01",
      },
      {
        name: "Zagreb",
        countryCode: "HR",
        latitude: "45.81501080",
        longitude: "15.98191890",
        nameNative: null,
        label: "Zagreb",
        cityCode: "HR_21",
      },
      {
        name: "Šibenik-Knin County",
        countryCode: "HR",
        latitude: "43.92814850",
        longitude: "16.10376940",
        nameNative: null,
        label: "Šibenik-Knin County",
        cityCode: "HR_15",
      },
    ],
    CU: [
      {
        name: "Artemisa Province",
        countryCode: "CU",
        latitude: "22.75229030",
        longitude: "-82.99316070",
        nameNative: null,
        label: "Artemisa Province",
        cityCode: "CU_15",
      },
      {
        name: "Camagüey Province",
        countryCode: "CU",
        latitude: "21.21672470",
        longitude: "-77.74520810",
        nameNative: null,
        label: "Camagüey Province",
        cityCode: "CU_09",
      },
      {
        name: "Ciego de Ávila Province",
        countryCode: "CU",
        latitude: "21.93295150",
        longitude: "-78.56608520",
        nameNative: null,
        label: "Ciego de Ávila Province",
        cityCode: "CU_08",
      },
      {
        name: "Cienfuegos Province",
        countryCode: "CU",
        latitude: "22.23797830",
        longitude: "-80.36586500",
        nameNative: null,
        label: "Cienfuegos Province",
        cityCode: "CU_06",
      },
      {
        name: "Granma Province",
        countryCode: "CU",
        latitude: "20.38449020",
        longitude: "-76.64127120",
        nameNative: null,
        label: "Granma Province",
        cityCode: "CU_12",
      },
      {
        name: "Guantánamo Province",
        countryCode: "CU",
        latitude: "20.14559170",
        longitude: "-74.87410450",
        nameNative: null,
        label: "Guantánamo Province",
        cityCode: "CU_14",
      },
      {
        name: "Havana Province",
        countryCode: "CU",
        latitude: "23.05406980",
        longitude: "-82.34518900",
        nameNative: null,
        label: "Havana Province",
        cityCode: "CU_03",
      },
      {
        name: "Holguín Province",
        countryCode: "CU",
        latitude: "20.78378930",
        longitude: "-75.80690820",
        nameNative: null,
        label: "Holguín Province",
        cityCode: "CU_11",
      },
      {
        name: "Isla de la Juventud",
        countryCode: "CU",
        latitude: "21.70847370",
        longitude: "-82.82202320",
        nameNative: null,
        label: "Isla de la Juventud",
        cityCode: "CU_99",
      },
      {
        name: "Las Tunas Province",
        countryCode: "CU",
        latitude: "21.06051620",
        longitude: "-76.91820970",
        nameNative: null,
        label: "Las Tunas Province",
        cityCode: "CU_10",
      },
      {
        name: "Matanzas Province",
        countryCode: "CU",
        latitude: "22.57671230",
        longitude: "-81.33994140",
        nameNative: null,
        label: "Matanzas Province",
        cityCode: "CU_04",
      },
      {
        name: "Mayabeque Province",
        countryCode: "CU",
        latitude: "22.89265290",
        longitude: "-81.95348150",
        nameNative: null,
        label: "Mayabeque Province",
        cityCode: "CU_16",
      },
      {
        name: "Pinar del Río Province",
        countryCode: "CU",
        latitude: "22.40762560",
        longitude: "-83.84730150",
        nameNative: null,
        label: "Pinar del Río Province",
        cityCode: "CU_01",
      },
      {
        name: "Sancti Spíritus Province",
        countryCode: "CU",
        latitude: "21.99382140",
        longitude: "-79.47038850",
        nameNative: null,
        label: "Sancti Spíritus Province",
        cityCode: "CU_07",
      },
      {
        name: "Santiago de Cuba Province",
        countryCode: "CU",
        latitude: "20.23976820",
        longitude: "-75.99276520",
        nameNative: null,
        label: "Santiago de Cuba Province",
        cityCode: "CU_13",
      },
      {
        name: "Villa Clara Province",
        countryCode: "CU",
        latitude: "22.49372040",
        longitude: "-79.91927020",
        nameNative: null,
        label: "Villa Clara Province",
        cityCode: "CU_05",
      },
    ],
    CY: [
      {
        name: "Famagusta District (Mağusa)",
        countryCode: "CY",
        latitude: "35.28570230",
        longitude: "33.84112880",
        nameNative: null,
        label: "Famagusta District (Mağusa)",
        cityCode: "CY_04",
      },
      {
        name: "Kyrenia District (Keryneia)",
        countryCode: "CY",
        latitude: "35.29919400",
        longitude: "33.23632460",
        nameNative: null,
        label: "Kyrenia District (Keryneia)",
        cityCode: "CY_06",
      },
      {
        name: "Larnaca District (Larnaka)",
        countryCode: "CY",
        latitude: "34.85072060",
        longitude: "33.48319060",
        nameNative: null,
        label: "Larnaca District (Larnaka)",
        cityCode: "CY_03",
      },
      {
        name: "Limassol District (Leymasun)",
        countryCode: "CY",
        latitude: "34.70713010",
        longitude: "33.02261740",
        nameNative: null,
        label: "Limassol District (Leymasun)",
        cityCode: "CY_02",
      },
      {
        name: "Nicosia District (Lefkoşa)",
        countryCode: "CY",
        latitude: "35.18556590",
        longitude: "33.38227640",
        nameNative: null,
        label: "Nicosia District (Lefkoşa)",
        cityCode: "CY_01",
      },
      {
        name: "Paphos District (Pafos)",
        countryCode: "CY",
        latitude: "34.91645940",
        longitude: "32.49200880",
        nameNative: null,
        label: "Paphos District (Pafos)",
        cityCode: "CY_05",
      },
    ],
    CZ: [
      {
        name: "Benešov",
        countryCode: "CZ",
        latitude: "49.69008280",
        longitude: "14.77643990",
        nameNative: null,
        label: "Benešov",
        cityCode: "CZ_201",
      },
      {
        name: "Beroun",
        countryCode: "CZ",
        latitude: "49.95734280",
        longitude: "13.98407150",
        nameNative: null,
        label: "Beroun",
        cityCode: "CZ_202",
      },
      {
        name: "Blansko",
        countryCode: "CZ",
        latitude: "49.36485020",
        longitude: "16.64775520",
        nameNative: null,
        label: "Blansko",
        cityCode: "CZ_641",
      },
      {
        name: "Brno-město",
        countryCode: "CZ",
        latitude: "49.19506020",
        longitude: "16.60683710",
        nameNative: null,
        label: "Brno-město",
        cityCode: "CZ_642",
      },
      {
        name: "Brno-venkov",
        countryCode: "CZ",
        latitude: "49.12501380",
        longitude: "16.45588240",
        nameNative: null,
        label: "Brno-venkov",
        cityCode: "CZ_643",
      },
      {
        name: "Bruntál",
        countryCode: "CZ",
        latitude: "49.98817670",
        longitude: "17.46369410",
        nameNative: null,
        label: "Bruntál",
        cityCode: "CZ_801",
      },
      {
        name: "Břeclav",
        countryCode: "CZ",
        latitude: "48.75314000",
        longitude: "16.88251690",
        nameNative: null,
        label: "Břeclav",
        cityCode: "CZ_644",
      },
      {
        name: "Cheb",
        countryCode: "CZ",
        latitude: "50.07953340",
        longitude: "12.36986360",
        nameNative: null,
        label: "Cheb",
        cityCode: "CZ_411",
      },
      {
        name: "Chomutov",
        countryCode: "CZ",
        latitude: "50.45838720",
        longitude: "13.30179100",
        nameNative: null,
        label: "Chomutov",
        cityCode: "CZ_422",
      },
      {
        name: "Chrudim",
        countryCode: "CZ",
        latitude: "49.88302160",
        longitude: "15.82908660",
        nameNative: null,
        label: "Chrudim",
        cityCode: "CZ_531",
      },
      {
        name: "Domažlice",
        countryCode: "CZ",
        latitude: "49.43970270",
        longitude: "12.93114350",
        nameNative: null,
        label: "Domažlice",
        cityCode: "CZ_321",
      },
      {
        name: "Děčín",
        countryCode: "CZ",
        latitude: "50.77255630",
        longitude: "14.21276120",
        nameNative: null,
        label: "Děčín",
        cityCode: "CZ_421",
      },
      {
        name: "Frýdek-Místek",
        countryCode: "CZ",
        latitude: "49.68193050",
        longitude: "18.36732160",
        nameNative: null,
        label: "Frýdek-Místek",
        cityCode: "CZ_802",
      },
      {
        name: "Havlíčkův Brod",
        countryCode: "CZ",
        latitude: "49.60433640",
        longitude: "15.57965520",
        nameNative: null,
        label: "Havlíčkův Brod",
        cityCode: "CZ_631",
      },
      {
        name: "Hodonín",
        countryCode: "CZ",
        latitude: "48.85293910",
        longitude: "17.12600250",
        nameNative: null,
        label: "Hodonín",
        cityCode: "CZ_645",
      },
      {
        name: "Hradec Králové",
        countryCode: "CZ",
        latitude: "50.24148050",
        longitude: "15.67430000",
        nameNative: null,
        label: "Hradec Králové",
        cityCode: "CZ_521",
      },
      {
        name: "Jablonec nad Nisou",
        countryCode: "CZ",
        latitude: "50.72205280",
        longitude: "15.17031350",
        nameNative: null,
        label: "Jablonec nad Nisou",
        cityCode: "CZ_512",
      },
      {
        name: "Jeseník",
        countryCode: "CZ",
        latitude: "50.22462490",
        longitude: "17.19804710",
        nameNative: null,
        label: "Jeseník",
        cityCode: "CZ_711",
      },
      {
        name: "Jihlava",
        countryCode: "CZ",
        latitude: "49.39837820",
        longitude: "15.58704150",
        nameNative: null,
        label: "Jihlava",
        cityCode: "CZ_632",
      },
      {
        name: "Jihomoravský kraj",
        countryCode: "CZ",
        latitude: "48.95445280",
        longitude: "16.76768990",
        nameNative: null,
        label: "Jihomoravský kraj",
        cityCode: "CZ_64",
      },
      {
        name: "Jihočeský kraj",
        countryCode: "CZ",
        latitude: "48.94577890",
        longitude: "14.44160550",
        nameNative: null,
        label: "Jihočeský kraj",
        cityCode: "CZ_31",
      },
      {
        name: "Jindřichův Hradec",
        countryCode: "CZ",
        latitude: "49.14448230",
        longitude: "15.00613890",
        nameNative: null,
        label: "Jindřichův Hradec",
        cityCode: "CZ_313",
      },
      {
        name: "Jičín",
        countryCode: "CZ",
        latitude: "50.43533250",
        longitude: "15.36104400",
        nameNative: null,
        label: "Jičín",
        cityCode: "CZ_522",
      },
      {
        name: "Karlovarský kraj",
        countryCode: "CZ",
        latitude: "50.14350000",
        longitude: "12.75018990",
        nameNative: null,
        label: "Karlovarský kraj",
        cityCode: "CZ_41",
      },
      {
        name: "Karlovy Vary",
        countryCode: "CZ",
        latitude: "50.14350000",
        longitude: "12.75018990",
        nameNative: null,
        label: "Karlovy Vary",
        cityCode: "CZ_412",
      },
      {
        name: "Karviná",
        countryCode: "CZ",
        latitude: "49.85665240",
        longitude: "18.54321860",
        nameNative: null,
        label: "Karviná",
        cityCode: "CZ_803",
      },
      {
        name: "Kladno",
        countryCode: "CZ",
        latitude: "50.19402580",
        longitude: "14.10436570",
        nameNative: null,
        label: "Kladno",
        cityCode: "CZ_203",
      },
      {
        name: "Klatovy",
        countryCode: "CZ",
        latitude: "49.39555490",
        longitude: "13.29509370",
        nameNative: null,
        label: "Klatovy",
        cityCode: "CZ_322",
      },
      {
        name: "Kolín",
        countryCode: "CZ",
        latitude: "49.98832930",
        longitude: "15.05519770",
        nameNative: null,
        label: "Kolín",
        cityCode: "CZ_204",
      },
      {
        name: "Kraj Vysočina",
        countryCode: "CZ",
        latitude: "49.44900520",
        longitude: "15.64059340",
        nameNative: null,
        label: "Kraj Vysočina",
        cityCode: "CZ_63",
      },
      {
        name: "Kroměříž",
        countryCode: "CZ",
        latitude: "49.29165820",
        longitude: "17.39938000",
        nameNative: null,
        label: "Kroměříž",
        cityCode: "CZ_721",
      },
      {
        name: "Královéhradecký kraj",
        countryCode: "CZ",
        latitude: "50.35124840",
        longitude: "15.79764590",
        nameNative: null,
        label: "Královéhradecký kraj",
        cityCode: "CZ_52",
      },
      {
        name: "Kutná Hora",
        countryCode: "CZ",
        latitude: "49.94920890",
        longitude: "15.24704400",
        nameNative: null,
        label: "Kutná Hora",
        cityCode: "CZ_205",
      },
      {
        name: "Liberec",
        countryCode: "CZ",
        latitude: "50.75641010",
        longitude: "14.99650410",
        nameNative: null,
        label: "Liberec",
        cityCode: "CZ_513",
      },
      {
        name: "Liberecký kraj",
        countryCode: "CZ",
        latitude: "50.65942400",
        longitude: "14.76324240",
        nameNative: null,
        label: "Liberecký kraj",
        cityCode: "CZ_51",
      },
      {
        name: "Litoměřice",
        countryCode: "CZ",
        latitude: "50.53841970",
        longitude: "14.13054580",
        nameNative: null,
        label: "Litoměřice",
        cityCode: "CZ_423",
      },
      {
        name: "Louny",
        countryCode: "CZ",
        latitude: "50.35398120",
        longitude: "13.80335510",
        nameNative: null,
        label: "Louny",
        cityCode: "CZ_424",
      },
      {
        name: "Mladá Boleslav",
        countryCode: "CZ",
        latitude: "50.42523170",
        longitude: "14.93624770",
        nameNative: null,
        label: "Mladá Boleslav",
        cityCode: "CZ_207",
      },
      {
        name: "Moravskoslezský kraj",
        countryCode: "CZ",
        latitude: "49.73053270",
        longitude: "18.23326370",
        nameNative: null,
        label: "Moravskoslezský kraj",
        cityCode: "CZ_80",
      },
      {
        name: "Most",
        countryCode: "CZ",
        latitude: "37.15540830",
        longitude: "-94.29488840",
        nameNative: null,
        label: "Most",
        cityCode: "CZ_425",
      },
      {
        name: "Mělník",
        countryCode: "CZ",
        latitude: "50.31044150",
        longitude: "14.51792230",
        nameNative: null,
        label: "Mělník",
        cityCode: "CZ_206",
      },
      {
        name: "Nový Jičín",
        countryCode: "CZ",
        latitude: "49.59432510",
        longitude: "18.01353560",
        nameNative: null,
        label: "Nový Jičín",
        cityCode: "CZ_804",
      },
      {
        name: "Nymburk",
        countryCode: "CZ",
        latitude: "50.18558160",
        longitude: "15.04366040",
        nameNative: null,
        label: "Nymburk",
        cityCode: "CZ_208",
      },
      {
        name: "Náchod",
        countryCode: "CZ",
        latitude: "50.41457220",
        longitude: "16.16563470",
        nameNative: null,
        label: "Náchod",
        cityCode: "CZ_523",
      },
      {
        name: "Olomouc",
        countryCode: "CZ",
        latitude: "49.59377800",
        longitude: "17.25087870",
        nameNative: null,
        label: "Olomouc",
        cityCode: "CZ_712",
      },
      {
        name: "Olomoucký kraj",
        countryCode: "CZ",
        latitude: "49.65865490",
        longitude: "17.08114060",
        nameNative: null,
        label: "Olomoucký kraj",
        cityCode: "CZ_71",
      },
      {
        name: "Opava",
        countryCode: "CZ",
        latitude: "49.90837570",
        longitude: "17.91633800",
        nameNative: null,
        label: "Opava",
        cityCode: "CZ_805",
      },
      {
        name: "Ostrava-město",
        countryCode: "CZ",
        latitude: "49.82092260",
        longitude: "18.26252430",
        nameNative: null,
        label: "Ostrava-město",
        cityCode: "CZ_806",
      },
      {
        name: "Pardubice",
        countryCode: "CZ",
        latitude: "49.94444790",
        longitude: "16.28569160",
        nameNative: null,
        label: "Pardubice",
        cityCode: "CZ_532",
      },
      {
        name: "Pardubický kraj",
        countryCode: "CZ",
        latitude: "49.94444790",
        longitude: "16.28569160",
        nameNative: null,
        label: "Pardubický kraj",
        cityCode: "CZ_53",
      },
      {
        name: "Pelhřimov",
        countryCode: "CZ",
        latitude: "49.43062070",
        longitude: "15.22298300",
        nameNative: null,
        label: "Pelhřimov",
        cityCode: "CZ_633",
      },
      {
        name: "Plzeň-jih",
        countryCode: "CZ",
        latitude: "49.59048850",
        longitude: "13.57158610",
        nameNative: null,
        label: "Plzeň-jih",
        cityCode: "CZ_324",
      },
      {
        name: "Plzeň-město",
        countryCode: "CZ",
        latitude: "49.73843140",
        longitude: "13.37363710",
        nameNative: null,
        label: "Plzeň-město",
        cityCode: "CZ_323",
      },
      {
        name: "Plzeň-sever",
        countryCode: "CZ",
        latitude: "49.87748930",
        longitude: "13.25374280",
        nameNative: null,
        label: "Plzeň-sever",
        cityCode: "CZ_325",
      },
      {
        name: "Plzeňský kraj",
        countryCode: "CZ",
        latitude: "49.41348120",
        longitude: "13.31572460",
        nameNative: null,
        label: "Plzeňský kraj",
        cityCode: "CZ_32",
      },
      {
        name: "Prachatice",
        countryCode: "CZ",
        latitude: "49.01091000",
        longitude: "14.00000050",
        nameNative: null,
        label: "Prachatice",
        cityCode: "CZ_315",
      },
      {
        name: "Praha, Hlavní město",
        countryCode: "CZ",
        latitude: "50.07553810",
        longitude: "14.43780050",
        nameNative: null,
        label: "Praha, Hlavní město",
        cityCode: "CZ_10",
      },
      {
        name: "Praha-východ",
        countryCode: "CZ",
        latitude: "49.93893070",
        longitude: "14.79244720",
        nameNative: null,
        label: "Praha-východ",
        cityCode: "CZ_209",
      },
      {
        name: "Praha-západ",
        countryCode: "CZ",
        latitude: "49.89352350",
        longitude: "14.32937790",
        nameNative: null,
        label: "Praha-západ",
        cityCode: "CZ_20A",
      },
      {
        name: "Prostějov",
        countryCode: "CZ",
        latitude: "49.44184010",
        longitude: "17.12779040",
        nameNative: null,
        label: "Prostějov",
        cityCode: "CZ_713",
      },
      {
        name: "Písek",
        countryCode: "CZ",
        latitude: "49.34199380",
        longitude: "14.24697600",
        nameNative: null,
        label: "Písek",
        cityCode: "CZ_314",
      },
      {
        name: "Přerov",
        countryCode: "CZ",
        latitude: "49.46713560",
        longitude: "17.50773320",
        nameNative: null,
        label: "Přerov",
        cityCode: "CZ_714",
      },
      {
        name: "Příbram",
        countryCode: "CZ",
        latitude: "49.69479590",
        longitude: "14.08238100",
        nameNative: null,
        label: "Příbram",
        cityCode: "CZ_20B",
      },
      {
        name: "Rakovník",
        countryCode: "CZ",
        latitude: "50.10612300",
        longitude: "13.73966230",
        nameNative: null,
        label: "Rakovník",
        cityCode: "CZ_20C",
      },
      {
        name: "Rokycany",
        countryCode: "CZ",
        latitude: "49.82628270",
        longitude: "13.68749430",
        nameNative: null,
        label: "Rokycany",
        cityCode: "CZ_326",
      },
      {
        name: "Rychnov nad Kněžnou",
        countryCode: "CZ",
        latitude: "50.16596510",
        longitude: "16.27768420",
        nameNative: null,
        label: "Rychnov nad Kněžnou",
        cityCode: "CZ_524",
      },
      {
        name: "Semily",
        countryCode: "CZ",
        latitude: "50.60515760",
        longitude: "15.32814090",
        nameNative: null,
        label: "Semily",
        cityCode: "CZ_514",
      },
      {
        name: "Sokolov",
        countryCode: "CZ",
        latitude: "50.20134340",
        longitude: "12.60546360",
        nameNative: null,
        label: "Sokolov",
        cityCode: "CZ_413",
      },
      {
        name: "Strakonice",
        countryCode: "CZ",
        latitude: "49.26040430",
        longitude: "13.91030850",
        nameNative: null,
        label: "Strakonice",
        cityCode: "CZ_316",
      },
      {
        name: "Středočeský kraj",
        countryCode: "CZ",
        latitude: "49.87822230",
        longitude: "14.93629550",
        nameNative: null,
        label: "Středočeský kraj",
        cityCode: "CZ_20",
      },
      {
        name: "Svitavy",
        countryCode: "CZ",
        latitude: "49.75516290",
        longitude: "16.46918610",
        nameNative: null,
        label: "Svitavy",
        cityCode: "CZ_533",
      },
      {
        name: "Tachov",
        countryCode: "CZ",
        latitude: "49.79878030",
        longitude: "12.63619210",
        nameNative: null,
        label: "Tachov",
        cityCode: "CZ_327",
      },
      {
        name: "Teplice",
        countryCode: "CZ",
        latitude: "50.65846050",
        longitude: "13.75132270",
        nameNative: null,
        label: "Teplice",
        cityCode: "CZ_426",
      },
      {
        name: "Trutnov",
        countryCode: "CZ",
        latitude: "50.56538380",
        longitude: "15.90909230",
        nameNative: null,
        label: "Trutnov",
        cityCode: "CZ_525",
      },
      {
        name: "Tábor",
        countryCode: "CZ",
        latitude: "49.36462930",
        longitude: "14.71912930",
        nameNative: null,
        label: "Tábor",
        cityCode: "CZ_317",
      },
      {
        name: "Třebíč",
        countryCode: "CZ",
        latitude: "49.21478690",
        longitude: "15.87955160",
        nameNative: null,
        label: "Třebíč",
        cityCode: "CZ_634",
      },
      {
        name: "Uherské Hradiště",
        countryCode: "CZ",
        latitude: "49.05979690",
        longitude: "17.49585010",
        nameNative: null,
        label: "Uherské Hradiště",
        cityCode: "CZ_722",
      },
      {
        name: "Vsetín",
        countryCode: "CZ",
        latitude: "49.37932500",
        longitude: "18.06181620",
        nameNative: null,
        label: "Vsetín",
        cityCode: "CZ_723",
      },
      {
        name: "Vyškov",
        countryCode: "CZ",
        latitude: "49.21274450",
        longitude: "16.98559270",
        nameNative: null,
        label: "Vyškov",
        cityCode: "CZ_646",
      },
      {
        name: "Zlín",
        countryCode: "CZ",
        latitude: "49.16960520",
        longitude: "17.80252200",
        nameNative: null,
        label: "Zlín",
        cityCode: "CZ_724",
      },
      {
        name: "Zlínský kraj",
        countryCode: "CZ",
        latitude: "49.21622960",
        longitude: "17.77203530",
        nameNative: null,
        label: "Zlínský kraj",
        cityCode: "CZ_72",
      },
      {
        name: "Znojmo",
        countryCode: "CZ",
        latitude: "48.92723270",
        longitude: "16.10378080",
        nameNative: null,
        label: "Znojmo",
        cityCode: "CZ_647",
      },
      {
        name: "Ústecký kraj",
        countryCode: "CZ",
        latitude: "50.61190370",
        longitude: "13.78700860",
        nameNative: null,
        label: "Ústecký kraj",
        cityCode: "CZ_42",
      },
      {
        name: "Ústí nad Labem",
        countryCode: "CZ",
        latitude: "50.61190370",
        longitude: "13.78700860",
        nameNative: null,
        label: "Ústí nad Labem",
        cityCode: "CZ_427",
      },
      {
        name: "Ústí nad Orlicí",
        countryCode: "CZ",
        latitude: "49.97218010",
        longitude: "16.39966170",
        nameNative: null,
        label: "Ústí nad Orlicí",
        cityCode: "CZ_534",
      },
      {
        name: "Česká Lípa",
        countryCode: "CZ",
        latitude: "50.67852010",
        longitude: "14.53969910",
        nameNative: null,
        label: "Česká Lípa",
        cityCode: "CZ_511",
      },
      {
        name: "České Budějovice",
        countryCode: "CZ",
        latitude: "48.97755530",
        longitude: "14.51507470",
        nameNative: null,
        label: "České Budějovice",
        cityCode: "CZ_311",
      },
      {
        name: "Český Krumlov",
        countryCode: "CZ",
        latitude: "48.81273540",
        longitude: "14.31746570",
        nameNative: null,
        label: "Český Krumlov",
        cityCode: "CZ_312",
      },
      {
        name: "Šumperk",
        countryCode: "CZ",
        latitude: "49.97784070",
        longitude: "16.97177540",
        nameNative: null,
        label: "Šumperk",
        cityCode: "CZ_715",
      },
      {
        name: "Žďár nad Sázavou",
        countryCode: "CZ",
        latitude: "49.56430120",
        longitude: "15.93910300",
        nameNative: null,
        label: "Žďár nad Sázavou",
        cityCode: "CZ_635",
      },
    ],
    DK: [
      {
        name: "Capital Region of Denmark",
        countryCode: "DK",
        latitude: "55.67518120",
        longitude: "12.54932610",
        nameNative: null,
        label: "Capital Region of Denmark",
        cityCode: "DK_84",
      },
      {
        name: "Central Denmark Region",
        countryCode: "DK",
        latitude: "56.30213900",
        longitude: "9.30277700",
        nameNative: null,
        label: "Central Denmark Region",
        cityCode: "DK_82",
      },
      {
        name: "North Denmark Region",
        countryCode: "DK",
        latitude: "56.83074160",
        longitude: "9.49305270",
        nameNative: null,
        label: "North Denmark Region",
        cityCode: "DK_81",
      },
      {
        name: "Region Zealand",
        countryCode: "DK",
        latitude: "55.46325180",
        longitude: "11.72149790",
        nameNative: null,
        label: "Region Zealand",
        cityCode: "DK_85",
      },
      {
        name: "Region of Southern Denmark",
        countryCode: "DK",
        latitude: "55.33077140",
        longitude: "9.09249030",
        nameNative: null,
        label: "Region of Southern Denmark",
        cityCode: "DK_83",
      },
    ],
    DJ: [
      {
        name: "Ali Sabieh Region",
        countryCode: "DJ",
        latitude: "11.19289730",
        longitude: "42.94169800",
        nameNative: null,
        label: "Ali Sabieh Region",
        cityCode: "DJ_AS",
      },
      {
        name: "Arta Region",
        countryCode: "DJ",
        latitude: "11.52555280",
        longitude: "42.84794740",
        nameNative: null,
        label: "Arta Region",
        cityCode: "DJ_AR",
      },
      {
        name: "Dikhil Region",
        countryCode: "DJ",
        latitude: "11.10543360",
        longitude: "42.37047440",
        nameNative: null,
        label: "Dikhil Region",
        cityCode: "DJ_DI",
      },
      {
        name: "Djibouti",
        countryCode: "DJ",
        latitude: "11.82513800",
        longitude: "42.59027500",
        nameNative: null,
        label: "Djibouti",
        cityCode: "DJ_DJ",
      },
      {
        name: "Obock Region",
        countryCode: "DJ",
        latitude: "12.38956910",
        longitude: "43.01948970",
        nameNative: null,
        label: "Obock Region",
        cityCode: "DJ_OB",
      },
      {
        name: "Tadjourah Region",
        countryCode: "DJ",
        latitude: "11.93388850",
        longitude: "42.39383750",
        nameNative: null,
        label: "Tadjourah Region",
        cityCode: "DJ_TA",
      },
    ],
    DM: [
      {
        name: "Saint Andrew Parish",
        countryCode: "DM",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Andrew Parish",
        cityCode: "DM_02",
      },
      {
        name: "Saint David Parish",
        countryCode: "DM",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint David Parish",
        cityCode: "DM_03",
      },
      {
        name: "Saint George Parish",
        countryCode: "DM",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint George Parish",
        cityCode: "DM_04",
      },
      {
        name: "Saint John Parish",
        countryCode: "DM",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint John Parish",
        cityCode: "DM_05",
      },
      {
        name: "Saint Joseph Parish",
        countryCode: "DM",
        latitude: "39.02227120",
        longitude: "-94.71765040",
        nameNative: null,
        label: "Saint Joseph Parish",
        cityCode: "DM_06",
      },
      {
        name: "Saint Luke Parish",
        countryCode: "DM",
        latitude: "42.10513630",
        longitude: "-80.05707220",
        nameNative: null,
        label: "Saint Luke Parish",
        cityCode: "DM_07",
      },
      {
        name: "Saint Mark Parish",
        countryCode: "DM",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Mark Parish",
        cityCode: "DM_08",
      },
      {
        name: "Saint Patrick Parish",
        countryCode: "DM",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Patrick Parish",
        cityCode: "DM_09",
      },
      {
        name: "Saint Paul Parish",
        countryCode: "DM",
        latitude: "38.86146000",
        longitude: "-90.74356190",
        nameNative: null,
        label: "Saint Paul Parish",
        cityCode: "DM_10",
      },
      {
        name: "Saint Peter Parish",
        countryCode: "DM",
        latitude: "40.45241940",
        longitude: "-80.00850560",
        nameNative: null,
        label: "Saint Peter Parish",
        cityCode: "DM_11",
      },
    ],
    DO: [
      {
        name: "Azua Province",
        countryCode: "DO",
        latitude: "18.45527090",
        longitude: "-70.73809280",
        nameNative: null,
        label: "Azua Province",
        cityCode: "DO_02",
      },
      {
        name: "Baoruco Province",
        countryCode: "DO",
        latitude: "18.48798980",
        longitude: "-71.41822490",
        nameNative: null,
        label: "Baoruco Province",
        cityCode: "DO_03",
      },
      {
        name: "Barahona Province",
        countryCode: "DO",
        latitude: "18.21390660",
        longitude: "-71.10437590",
        nameNative: null,
        label: "Barahona Province",
        cityCode: "DO_04",
      },
      {
        name: "Dajabón Province",
        countryCode: "DO",
        latitude: "19.54992410",
        longitude: "-71.70865140",
        nameNative: null,
        label: "Dajabón Province",
        cityCode: "DO_05",
      },
      {
        name: "Distrito Nacional",
        countryCode: "DO",
        latitude: "18.48605750",
        longitude: "-69.93121170",
        nameNative: null,
        label: "Distrito Nacional",
        cityCode: "DO_01",
      },
      {
        name: "Duarte Province",
        countryCode: "DO",
        latitude: "19.20908230",
        longitude: "-70.02700040",
        nameNative: null,
        label: "Duarte Province",
        cityCode: "DO_06",
      },
      {
        name: "El Seibo Province",
        countryCode: "DO",
        latitude: "18.76584960",
        longitude: "-69.04066800",
        nameNative: null,
        label: "El Seibo Province",
        cityCode: "DO_08",
      },
      {
        name: "Espaillat Province",
        countryCode: "DO",
        latitude: "19.62776580",
        longitude: "-70.27867750",
        nameNative: null,
        label: "Espaillat Province",
        cityCode: "DO_09",
      },
      {
        name: "Hato Mayor Province",
        countryCode: "DO",
        latitude: "18.76357990",
        longitude: "-69.25576370",
        nameNative: null,
        label: "Hato Mayor Province",
        cityCode: "DO_30",
      },
      {
        name: "Hermanas Mirabal Province",
        countryCode: "DO",
        latitude: "19.37475590",
        longitude: "-70.35132350",
        nameNative: null,
        label: "Hermanas Mirabal Province",
        cityCode: "DO_19",
      },
      {
        name: "Independencia",
        countryCode: "DO",
        latitude: "32.63357480",
        longitude: "-115.42892940",
        nameNative: null,
        label: "Independencia",
        cityCode: "DO_10",
      },
      {
        name: "La Altagracia Province",
        countryCode: "DO",
        latitude: "18.58502360",
        longitude: "-68.62010720",
        nameNative: null,
        label: "La Altagracia Province",
        cityCode: "DO_11",
      },
      {
        name: "La Romana Province",
        countryCode: "DO",
        latitude: "18.43102710",
        longitude: "-68.98373730",
        nameNative: null,
        label: "La Romana Province",
        cityCode: "DO_12",
      },
      {
        name: "La Vega Province",
        countryCode: "DO",
        latitude: "19.22115540",
        longitude: "-70.52887530",
        nameNative: null,
        label: "La Vega Province",
        cityCode: "DO_13",
      },
      {
        name: "María Trinidad Sánchez Province",
        countryCode: "DO",
        latitude: "19.37345970",
        longitude: "-69.85144390",
        nameNative: null,
        label: "María Trinidad Sánchez Province",
        cityCode: "DO_14",
      },
      {
        name: "Monseñor Nouel Province",
        countryCode: "DO",
        latitude: "18.92152340",
        longitude: "-70.38368150",
        nameNative: null,
        label: "Monseñor Nouel Province",
        cityCode: "DO_28",
      },
      {
        name: "Monte Cristi Province",
        countryCode: "DO",
        latitude: "19.73968990",
        longitude: "-71.44339840",
        nameNative: null,
        label: "Monte Cristi Province",
        cityCode: "DO_15",
      },
      {
        name: "Monte Plata Province",
        countryCode: "DO",
        latitude: "18.80808780",
        longitude: "-69.78691460",
        nameNative: null,
        label: "Monte Plata Province",
        cityCode: "DO_29",
      },
      {
        name: "Pedernales Province",
        countryCode: "DO",
        latitude: "17.85376260",
        longitude: "-71.33032090",
        nameNative: null,
        label: "Pedernales Province",
        cityCode: "DO_16",
      },
      {
        name: "Peravia Province",
        countryCode: "DO",
        latitude: "18.27865940",
        longitude: "-70.33358870",
        nameNative: null,
        label: "Peravia Province",
        cityCode: "DO_17",
      },
      {
        name: "Puerto Plata Province",
        countryCode: "DO",
        latitude: "19.75432250",
        longitude: "-70.83328470",
        nameNative: null,
        label: "Puerto Plata Province",
        cityCode: "DO_18",
      },
      {
        name: "Samaná Province",
        countryCode: "DO",
        latitude: "19.20583710",
        longitude: "-69.33629490",
        nameNative: null,
        label: "Samaná Province",
        cityCode: "DO_20",
      },
      {
        name: "San Cristóbal Province",
        countryCode: "DO",
        latitude: "18.41804140",
        longitude: "-70.10658490",
        nameNative: null,
        label: "San Cristóbal Province",
        cityCode: "DO_21",
      },
      {
        name: "San José de Ocoa Province",
        countryCode: "DO",
        latitude: "18.54385800",
        longitude: "-70.50418160",
        nameNative: null,
        label: "San José de Ocoa Province",
        cityCode: "DO_31",
      },
      {
        name: "San Juan Province",
        countryCode: "DO",
        latitude: "-31.52871270",
        longitude: "-68.53604030",
        nameNative: null,
        label: "San Juan Province",
        cityCode: "DO_22",
      },
      {
        name: "San Pedro de Macorís",
        countryCode: "DO",
        latitude: "18.46266000",
        longitude: "-69.30512340",
        nameNative: null,
        label: "San Pedro de Macorís",
        cityCode: "DO_23",
      },
      {
        name: "Santiago Province",
        countryCode: "DO",
        latitude: "-33.45000000",
        longitude: "-70.66670000",
        nameNative: null,
        label: "Santiago Province",
        cityCode: "DO_25",
      },
      {
        name: "Santiago Rodríguez Province",
        countryCode: "DO",
        latitude: "19.47131810",
        longitude: "-71.33958010",
        nameNative: null,
        label: "Santiago Rodríguez Province",
        cityCode: "DO_26",
      },
      {
        name: "Santo Domingo Province",
        countryCode: "DO",
        latitude: "18.51042530",
        longitude: "-69.84040540",
        nameNative: null,
        label: "Santo Domingo Province",
        cityCode: "DO_32",
      },
      {
        name: "Sánchez Ramírez Province",
        countryCode: "DO",
        latitude: "19.05270600",
        longitude: "-70.14922640",
        nameNative: null,
        label: "Sánchez Ramírez Province",
        cityCode: "DO_24",
      },
      {
        name: "Valverde Province",
        countryCode: "DO",
        latitude: "19.58812210",
        longitude: "-70.98033100",
        nameNative: null,
        label: "Valverde Province",
        cityCode: "DO_27",
      },
    ],
    TL: [
      {
        name: "Aileu municipality",
        countryCode: "TL",
        latitude: "-8.70439940",
        longitude: "125.60954740",
        nameNative: null,
        label: "Aileu municipality",
        cityCode: "TL_AL",
      },
      {
        name: "Ainaro Municipality",
        countryCode: "TL",
        latitude: "-9.01131710",
        longitude: "125.52200120",
        nameNative: null,
        label: "Ainaro Municipality",
        cityCode: "TL_AN",
      },
      {
        name: "Baucau Municipality",
        countryCode: "TL",
        latitude: "-8.47143080",
        longitude: "126.45759910",
        nameNative: null,
        label: "Baucau Municipality",
        cityCode: "TL_BA",
      },
      {
        name: "Bobonaro Municipality",
        countryCode: "TL",
        latitude: "-8.96554060",
        longitude: "125.25879640",
        nameNative: null,
        label: "Bobonaro Municipality",
        cityCode: "TL_BO",
      },
      {
        name: "Cova Lima Municipality",
        countryCode: "TL",
        latitude: "-9.26503750",
        longitude: "125.25879640",
        nameNative: null,
        label: "Cova Lima Municipality",
        cityCode: "TL_CO",
      },
      {
        name: "Dili municipality",
        countryCode: "TL",
        latitude: "-8.24496130",
        longitude: "125.58766970",
        nameNative: null,
        label: "Dili municipality",
        cityCode: "TL_DI",
      },
      {
        name: "Ermera District",
        countryCode: "TL",
        latitude: "-8.75248020",
        longitude: "125.39872940",
        nameNative: null,
        label: "Ermera District",
        cityCode: "TL_ER",
      },
      {
        name: "Lautém Municipality",
        countryCode: "TL",
        latitude: "-8.36423070",
        longitude: "126.90438450",
        nameNative: null,
        label: "Lautém Municipality",
        cityCode: "TL_LA",
      },
      {
        name: "Liquiçá Municipality",
        countryCode: "TL",
        latitude: "-8.66740950",
        longitude: "125.25879640",
        nameNative: null,
        label: "Liquiçá Municipality",
        cityCode: "TL_LI",
      },
      {
        name: "Manatuto District",
        countryCode: "TL",
        latitude: "-8.51556080",
        longitude: "126.01592550",
        nameNative: null,
        label: "Manatuto District",
        cityCode: "TL_MT",
      },
      {
        name: "Manufahi Municipality",
        countryCode: "TL",
        latitude: "-9.01454950",
        longitude: "125.82799590",
        nameNative: null,
        label: "Manufahi Municipality",
        cityCode: "TL_MF",
      },
      {
        name: "Viqueque Municipality",
        countryCode: "TL",
        latitude: "-8.85979180",
        longitude: "126.36335160",
        nameNative: null,
        label: "Viqueque Municipality",
        cityCode: "TL_VI",
      },
    ],
    EC: [
      {
        name: "Azuay",
        countryCode: "EC",
        latitude: "-2.89430680",
        longitude: "-78.99683440",
        nameNative: null,
        label: "Azuay",
        cityCode: "EC_A",
      },
      {
        name: "Bolívar",
        countryCode: "EC",
        latitude: "-1.70958280",
        longitude: "-79.04504290",
        nameNative: null,
        label: "Bolívar",
        cityCode: "EC_B",
      },
      {
        name: "Carchi",
        countryCode: "EC",
        latitude: "0.50269120",
        longitude: "-77.90425210",
        nameNative: null,
        label: "Carchi",
        cityCode: "EC_C",
      },
      {
        name: "Cañar",
        countryCode: "EC",
        latitude: "-2.55893150",
        longitude: "-78.93881910",
        nameNative: null,
        label: "Cañar",
        cityCode: "EC_F",
      },
      {
        name: "Chimborazo",
        countryCode: "EC",
        latitude: "-1.66479950",
        longitude: "-78.65432550",
        nameNative: null,
        label: "Chimborazo",
        cityCode: "EC_H",
      },
      {
        name: "Cotopaxi",
        countryCode: "EC",
        latitude: "-0.83842060",
        longitude: "-78.66626780",
        nameNative: null,
        label: "Cotopaxi",
        cityCode: "EC_X",
      },
      {
        name: "El Oro",
        countryCode: "EC",
        latitude: "-3.25924130",
        longitude: "-79.95835410",
        nameNative: null,
        label: "El Oro",
        cityCode: "EC_O",
      },
      {
        name: "Esmeraldas",
        countryCode: "EC",
        latitude: "0.96817890",
        longitude: "-79.65172020",
        nameNative: null,
        label: "Esmeraldas",
        cityCode: "EC_E",
      },
      {
        name: "Galápagos",
        countryCode: "EC",
        latitude: "-0.95376910",
        longitude: "-90.96560190",
        nameNative: null,
        label: "Galápagos",
        cityCode: "EC_W",
      },
      {
        name: "Guayas",
        countryCode: "EC",
        latitude: "-1.95748390",
        longitude: "-79.91927020",
        nameNative: null,
        label: "Guayas",
        cityCode: "EC_G",
      },
      {
        name: "Imbabura",
        countryCode: "EC",
        latitude: "0.34997680",
        longitude: "-78.12601290",
        nameNative: null,
        label: "Imbabura",
        cityCode: "EC_I",
      },
      {
        name: "Loja",
        countryCode: "EC",
        latitude: "-3.99313000",
        longitude: "-79.20422000",
        nameNative: null,
        label: "Loja",
        cityCode: "EC_L",
      },
      {
        name: "Los Ríos",
        countryCode: "EC",
        latitude: "-1.02306070",
        longitude: "-79.46088970",
        nameNative: null,
        label: "Los Ríos",
        cityCode: "EC_R",
      },
      {
        name: "Manabí",
        countryCode: "EC",
        latitude: "-1.05434340",
        longitude: "-80.45264400",
        nameNative: null,
        label: "Manabí",
        cityCode: "EC_M",
      },
      {
        name: "Morona-Santiago",
        countryCode: "EC",
        latitude: "-2.30510620",
        longitude: "-78.11468660",
        nameNative: null,
        label: "Morona-Santiago",
        cityCode: "EC_S",
      },
      {
        name: "Napo",
        countryCode: "EC",
        latitude: "-0.99559640",
        longitude: "-77.81296840",
        nameNative: null,
        label: "Napo",
        cityCode: "EC_N",
      },
      {
        name: "Orellana",
        countryCode: "EC",
        latitude: "-0.45451630",
        longitude: "-76.99502860",
        nameNative: null,
        label: "Orellana",
        cityCode: "EC_D",
      },
      {
        name: "Pastaza",
        countryCode: "EC",
        latitude: "-1.48822650",
        longitude: "-78.00310570",
        nameNative: null,
        label: "Pastaza",
        cityCode: "EC_Y",
      },
      {
        name: "Pichincha",
        countryCode: "EC",
        latitude: "-0.14648470",
        longitude: "-78.47519450",
        nameNative: null,
        label: "Pichincha",
        cityCode: "EC_P",
      },
      {
        name: "Santa Elena",
        countryCode: "EC",
        latitude: "-2.22671050",
        longitude: "-80.85949900",
        nameNative: null,
        label: "Santa Elena",
        cityCode: "EC_SE",
      },
      {
        name: "Santo Domingo de los Tsáchilas",
        countryCode: "EC",
        latitude: "-0.25218820",
        longitude: "-79.18793830",
        nameNative: null,
        label: "Santo Domingo de los Tsáchilas",
        cityCode: "EC_SD",
      },
      {
        name: "Sucumbíos",
        countryCode: "EC",
        latitude: "0.08892310",
        longitude: "-76.88975570",
        nameNative: null,
        label: "Sucumbíos",
        cityCode: "EC_U",
      },
      {
        name: "Tungurahua",
        countryCode: "EC",
        latitude: "-1.26352840",
        longitude: "-78.56608520",
        nameNative: null,
        label: "Tungurahua",
        cityCode: "EC_T",
      },
      {
        name: "Zamora Chinchipe",
        countryCode: "EC",
        latitude: "-4.06558920",
        longitude: "-78.95035250",
        nameNative: null,
        label: "Zamora Chinchipe",
        cityCode: "EC_Z",
      },
    ],
    EG: [
      {
        name: "Alexandria",
        countryCode: "EG",
        latitude: "30.87605680",
        longitude: "29.74260400",
        nameNative: null,
        label: "Alexandria",
        cityCode: "EG_ALX",
      },
      {
        name: "Aswan",
        countryCode: "EG",
        latitude: "23.69664980",
        longitude: "32.71813750",
        nameNative: null,
        label: "Aswan",
        cityCode: "EG_ASN",
      },
      {
        name: "Asyut",
        countryCode: "EG",
        latitude: "27.21338310",
        longitude: "31.44561790",
        nameNative: null,
        label: "Asyut",
        cityCode: "EG_AST",
      },
      {
        name: "Beheira",
        countryCode: "EG",
        latitude: "30.84809860",
        longitude: "30.34355060",
        nameNative: null,
        label: "Beheira",
        cityCode: "EG_BH",
      },
      {
        name: "Beni Suef",
        countryCode: "EG",
        latitude: "28.89388370",
        longitude: "31.44561790",
        nameNative: null,
        label: "Beni Suef",
        cityCode: "EG_BNS",
      },
      {
        name: "Cairo",
        countryCode: "EG",
        latitude: "29.95375640",
        longitude: "31.53700030",
        nameNative: null,
        label: "Cairo",
        cityCode: "EG_C",
      },
      {
        name: "Dakahlia",
        countryCode: "EG",
        latitude: "31.16560440",
        longitude: "31.49131820",
        nameNative: null,
        label: "Dakahlia",
        cityCode: "EG_DK",
      },
      {
        name: "Damietta",
        countryCode: "EG",
        latitude: "31.36257990",
        longitude: "31.67393710",
        nameNative: null,
        label: "Damietta",
        cityCode: "EG_DT",
      },
      {
        name: "Faiyum",
        countryCode: "EG",
        latitude: "29.30840210",
        longitude: "30.84284970",
        nameNative: null,
        label: "Faiyum",
        cityCode: "EG_FYM",
      },
      {
        name: "Gharbia",
        countryCode: "EG",
        latitude: "30.87535560",
        longitude: "31.03351000",
        nameNative: null,
        label: "Gharbia",
        cityCode: "EG_GH",
      },
      {
        name: "Giza",
        countryCode: "EG",
        latitude: "28.76662160",
        longitude: "29.23207840",
        nameNative: null,
        label: "Giza",
        cityCode: "EG_GZ",
      },
      {
        name: "Ismailia",
        countryCode: "EG",
        latitude: "30.58309340",
        longitude: "32.26538870",
        nameNative: null,
        label: "Ismailia",
        cityCode: "EG_IS",
      },
      {
        name: "Kafr el-Sheikh",
        countryCode: "EG",
        latitude: "31.30854440",
        longitude: "30.80394740",
        nameNative: null,
        label: "Kafr el-Sheikh",
        cityCode: "EG_KFS",
      },
      {
        name: "Luxor",
        countryCode: "EG",
        latitude: "25.39444440",
        longitude: "32.49200880",
        nameNative: null,
        label: "Luxor",
        cityCode: "EG_LX",
      },
      {
        name: "Matrouh",
        countryCode: "EG",
        latitude: "29.56963500",
        longitude: "26.41938900",
        nameNative: null,
        label: "Matrouh",
        cityCode: "EG_MT",
      },
      {
        name: "Minya",
        countryCode: "EG",
        latitude: "28.28472900",
        longitude: "30.52790960",
        nameNative: null,
        label: "Minya",
        cityCode: "EG_MN",
      },
      {
        name: "Monufia",
        countryCode: "EG",
        latitude: "30.59724550",
        longitude: "30.98763210",
        nameNative: null,
        label: "Monufia",
        cityCode: "EG_MNF",
      },
      {
        name: "New Valley",
        countryCode: "EG",
        latitude: "24.54556380",
        longitude: "27.17353160",
        nameNative: null,
        label: "New Valley",
        cityCode: "EG_WAD",
      },
      {
        name: "North Sinai",
        countryCode: "EG",
        latitude: "30.28236500",
        longitude: "33.61757700",
        nameNative: null,
        label: "North Sinai",
        cityCode: "EG_SIN",
      },
      {
        name: "Port Said",
        countryCode: "EG",
        latitude: "31.07586060",
        longitude: "32.26538870",
        nameNative: null,
        label: "Port Said",
        cityCode: "EG_PTS",
      },
      {
        name: "Qalyubia",
        countryCode: "EG",
        latitude: "30.32923680",
        longitude: "31.21684660",
        nameNative: null,
        label: "Qalyubia",
        cityCode: "EG_KB",
      },
      {
        name: "Qena",
        countryCode: "EG",
        latitude: "26.23460330",
        longitude: "32.98883190",
        nameNative: null,
        label: "Qena",
        cityCode: "EG_KN",
      },
      {
        name: "Red Sea",
        countryCode: "EG",
        latitude: "24.68263160",
        longitude: "34.15319470",
        nameNative: null,
        label: "Red Sea",
        cityCode: "EG_BA",
      },
      {
        name: "Sharqia",
        countryCode: "EG",
        latitude: "30.67305450",
        longitude: "31.15932470",
        nameNative: null,
        label: "Sharqia",
        cityCode: "EG_SHR",
      },
      {
        name: "Sohag",
        countryCode: "EG",
        latitude: "26.69383400",
        longitude: "32.17460500",
        nameNative: null,
        label: "Sohag",
        cityCode: "EG_SHG",
      },
      {
        name: "South Sinai",
        countryCode: "EG",
        latitude: "29.31018280",
        longitude: "34.15319470",
        nameNative: null,
        label: "South Sinai",
        cityCode: "EG_JS",
      },
      {
        name: "Suez",
        countryCode: "EG",
        latitude: "29.36822550",
        longitude: "32.17460500",
        nameNative: null,
        label: "Suez",
        cityCode: "EG_SUZ",
      },
    ],
    SV: [
      {
        name: "Ahuachapán Department",
        countryCode: "SV",
        latitude: "13.82161480",
        longitude: "-89.92532330",
        nameNative: null,
        label: "Ahuachapán Department",
        cityCode: "SV_AH",
      },
      {
        name: "Cabañas Department",
        countryCode: "SV",
        latitude: "13.86482880",
        longitude: "-88.74939980",
        nameNative: null,
        label: "Cabañas Department",
        cityCode: "SV_CA",
      },
      {
        name: "Chalatenango Department",
        countryCode: "SV",
        latitude: "14.19166480",
        longitude: "-89.17059980",
        nameNative: null,
        label: "Chalatenango Department",
        cityCode: "SV_CH",
      },
      {
        name: "Cuscatlán Department",
        countryCode: "SV",
        latitude: "13.86619570",
        longitude: "-89.05615320",
        nameNative: null,
        label: "Cuscatlán Department",
        cityCode: "SV_CU",
      },
      {
        name: "La Libertad Department",
        countryCode: "SV",
        latitude: "13.68176610",
        longitude: "-89.36062980",
        nameNative: null,
        label: "La Libertad Department",
        cityCode: "SV_LI",
      },
      {
        name: "La Paz Department",
        countryCode: "SV",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "La Paz Department",
        cityCode: "SV_PA",
      },
      {
        name: "La Unión Department",
        countryCode: "SV",
        latitude: "13.48864430",
        longitude: "-87.89424510",
        nameNative: null,
        label: "La Unión Department",
        cityCode: "SV_UN",
      },
      {
        name: "Morazán Department",
        countryCode: "SV",
        latitude: "13.76820000",
        longitude: "-88.12913870",
        nameNative: null,
        label: "Morazán Department",
        cityCode: "SV_MO",
      },
      {
        name: "San Miguel Department",
        countryCode: "SV",
        latitude: "13.44510410",
        longitude: "-88.24611830",
        nameNative: null,
        label: "San Miguel Department",
        cityCode: "SV_SM",
      },
      {
        name: "San Salvador Department",
        countryCode: "SV",
        latitude: "13.77399970",
        longitude: "-89.20867730",
        nameNative: null,
        label: "San Salvador Department",
        cityCode: "SV_SS",
      },
      {
        name: "San Vicente Department",
        countryCode: "SV",
        latitude: "13.58685610",
        longitude: "-88.74939980",
        nameNative: null,
        label: "San Vicente Department",
        cityCode: "SV_SV",
      },
      {
        name: "Santa Ana Department",
        countryCode: "SV",
        latitude: "14.14611210",
        longitude: "-89.51200840",
        nameNative: null,
        label: "Santa Ana Department",
        cityCode: "SV_SA",
      },
      {
        name: "Sonsonate Department",
        countryCode: "SV",
        latitude: "13.68235800",
        longitude: "-89.66281110",
        nameNative: null,
        label: "Sonsonate Department",
        cityCode: "SV_SO",
      },
      {
        name: "Usulután Department",
        countryCode: "SV",
        latitude: "13.44706340",
        longitude: "-88.55653100",
        nameNative: null,
        label: "Usulután Department",
        cityCode: "SV_US",
      },
    ],
    GQ: [
      {
        name: "Annobón Province",
        countryCode: "GQ",
        latitude: "-1.42687820",
        longitude: "5.63528010",
        nameNative: null,
        label: "Annobón Province",
        cityCode: "GQ_AN",
      },
      {
        name: "Bioko Norte Province",
        countryCode: "GQ",
        latitude: "3.65950720",
        longitude: "8.79218360",
        nameNative: null,
        label: "Bioko Norte Province",
        cityCode: "GQ_BN",
      },
      {
        name: "Bioko Sur Province",
        countryCode: "GQ",
        latitude: "3.42097850",
        longitude: "8.61606740",
        nameNative: null,
        label: "Bioko Sur Province",
        cityCode: "GQ_BS",
      },
      {
        name: "Centro Sur Province",
        countryCode: "GQ",
        latitude: "1.34360840",
        longitude: "10.43965600",
        nameNative: null,
        label: "Centro Sur Province",
        cityCode: "GQ_CS",
      },
      {
        name: "Insular Region",
        countryCode: "GQ",
        latitude: "37.09024000",
        longitude: "-95.71289100",
        nameNative: null,
        label: "Insular Region",
        cityCode: "GQ_I",
      },
      {
        name: "Kié-Ntem Province",
        countryCode: "GQ",
        latitude: "2.02809300",
        longitude: "11.07117580",
        nameNative: null,
        label: "Kié-Ntem Province",
        cityCode: "GQ_KN",
      },
      {
        name: "Litoral Province",
        countryCode: "GQ",
        latitude: "1.57502440",
        longitude: "9.81249350",
        nameNative: null,
        label: "Litoral Province",
        cityCode: "GQ_LI",
      },
      {
        name: "Río Muni",
        countryCode: "GQ",
        latitude: "1.46106060",
        longitude: "9.67868940",
        nameNative: null,
        label: "Río Muni",
        cityCode: "GQ_C",
      },
      {
        name: "Wele-Nzas Province",
        countryCode: "GQ",
        latitude: "1.41661620",
        longitude: "11.07117580",
        nameNative: null,
        label: "Wele-Nzas Province",
        cityCode: "GQ_WN",
      },
    ],
    ER: [
      {
        name: "Anseba Region",
        countryCode: "ER",
        latitude: "16.47455310",
        longitude: "37.80876930",
        nameNative: null,
        label: "Anseba Region",
        cityCode: "ER_AN",
      },
      {
        name: "Debub Region",
        countryCode: "ER",
        latitude: "14.94786920",
        longitude: "39.15436770",
        nameNative: null,
        label: "Debub Region",
        cityCode: "ER_DU",
      },
      {
        name: "Gash-Barka Region",
        countryCode: "ER",
        latitude: "15.40688250",
        longitude: "37.63866220",
        nameNative: null,
        label: "Gash-Barka Region",
        cityCode: "ER_GB",
      },
      {
        name: "Maekel Region",
        countryCode: "ER",
        latitude: "15.35514090",
        longitude: "38.86236830",
        nameNative: null,
        label: "Maekel Region",
        cityCode: "ER_MA",
      },
      {
        name: "Northern Red Sea Region",
        countryCode: "ER",
        latitude: "16.25839970",
        longitude: "38.82054540",
        nameNative: null,
        label: "Northern Red Sea Region",
        cityCode: "ER_SK",
      },
      {
        name: "Southern Red Sea Region",
        countryCode: "ER",
        latitude: "13.51371030",
        longitude: "41.76064720",
        nameNative: null,
        label: "Southern Red Sea Region",
        cityCode: "ER_DK",
      },
    ],
    EE: [
      {
        name: "Harju County",
        countryCode: "EE",
        latitude: "59.33342390",
        longitude: "25.24669740",
        nameNative: null,
        label: "Harju County",
        cityCode: "EE_37",
      },
      {
        name: "Hiiu County",
        countryCode: "EE",
        latitude: "58.92395530",
        longitude: "22.59194680",
        nameNative: null,
        label: "Hiiu County",
        cityCode: "EE_39",
      },
      {
        name: "Ida-Viru County",
        countryCode: "EE",
        latitude: "59.25926630",
        longitude: "27.41365350",
        nameNative: null,
        label: "Ida-Viru County",
        cityCode: "EE_44",
      },
      {
        name: "Järva County",
        countryCode: "EE",
        latitude: "58.88667130",
        longitude: "25.50006240",
        nameNative: null,
        label: "Järva County",
        cityCode: "EE_51",
      },
      {
        name: "Jõgeva County",
        countryCode: "EE",
        latitude: "58.75061430",
        longitude: "26.36048780",
        nameNative: null,
        label: "Jõgeva County",
        cityCode: "EE_49",
      },
      {
        name: "Lääne County",
        countryCode: "EE",
        latitude: "58.97227420",
        longitude: "23.87408340",
        nameNative: null,
        label: "Lääne County",
        cityCode: "EE_57",
      },
      {
        name: "Lääne-Viru County",
        countryCode: "EE",
        latitude: "59.30188160",
        longitude: "26.32803120",
        nameNative: null,
        label: "Lääne-Viru County",
        cityCode: "EE_59",
      },
      {
        name: "Pärnu County",
        countryCode: "EE",
        latitude: "58.52619520",
        longitude: "24.40201590",
        nameNative: null,
        label: "Pärnu County",
        cityCode: "EE_67",
      },
      {
        name: "Põlva County",
        countryCode: "EE",
        latitude: "58.11606220",
        longitude: "27.20663940",
        nameNative: null,
        label: "Põlva County",
        cityCode: "EE_65",
      },
      {
        name: "Rapla County",
        countryCode: "EE",
        latitude: "58.84926250",
        longitude: "24.73465690",
        nameNative: null,
        label: "Rapla County",
        cityCode: "EE_70",
      },
      {
        name: "Saare County",
        countryCode: "EE",
        latitude: "58.48497210",
        longitude: "22.61364080",
        nameNative: null,
        label: "Saare County",
        cityCode: "EE_74",
      },
      {
        name: "Tartu County",
        countryCode: "EE",
        latitude: "58.40571280",
        longitude: "26.80157600",
        nameNative: null,
        label: "Tartu County",
        cityCode: "EE_78",
      },
      {
        name: "Valga County",
        countryCode: "EE",
        latitude: "57.91034410",
        longitude: "26.16018190",
        nameNative: null,
        label: "Valga County",
        cityCode: "EE_82",
      },
      {
        name: "Viljandi County",
        countryCode: "EE",
        latitude: "58.28217460",
        longitude: "25.57522330",
        nameNative: null,
        label: "Viljandi County",
        cityCode: "EE_84",
      },
      {
        name: "Võru County",
        countryCode: "EE",
        latitude: "57.73773720",
        longitude: "27.13989380",
        nameNative: null,
        label: "Võru County",
        cityCode: "EE_86",
      },
    ],
    ET: [
      {
        name: "Addis Ababa",
        countryCode: "ET",
        latitude: "8.98060340",
        longitude: "38.75776050",
        nameNative: null,
        label: "Addis Ababa",
        cityCode: "ET_AA",
      },
      {
        name: "Afar Region",
        countryCode: "ET",
        latitude: "11.75593880",
        longitude: "40.95868800",
        nameNative: null,
        label: "Afar Region",
        cityCode: "ET_AF",
      },
      {
        name: "Amhara Region",
        countryCode: "ET",
        latitude: "11.34942470",
        longitude: "37.97845850",
        nameNative: null,
        label: "Amhara Region",
        cityCode: "ET_AM",
      },
      {
        name: "Benishangul-Gumuz Region",
        countryCode: "ET",
        latitude: "10.78028890",
        longitude: "35.56578620",
        nameNative: null,
        label: "Benishangul-Gumuz Region",
        cityCode: "ET_BE",
      },
      {
        name: "Dire Dawa",
        countryCode: "ET",
        latitude: "9.60087470",
        longitude: "41.85014200",
        nameNative: null,
        label: "Dire Dawa",
        cityCode: "ET_DD",
      },
      {
        name: "Gambela Region",
        countryCode: "ET",
        latitude: "7.92196870",
        longitude: "34.15319470",
        nameNative: null,
        label: "Gambela Region",
        cityCode: "ET_GA",
      },
      {
        name: "Harari Region",
        countryCode: "ET",
        latitude: "9.31486600",
        longitude: "42.19677160",
        nameNative: null,
        label: "Harari Region",
        cityCode: "ET_HA",
      },
      {
        name: "Oromia Region",
        countryCode: "ET",
        latitude: "7.54603770",
        longitude: "40.63468510",
        nameNative: null,
        label: "Oromia Region",
        cityCode: "ET_OR",
      },
      {
        name: "Somali Region",
        countryCode: "ET",
        latitude: "6.66122930",
        longitude: "43.79084530",
        nameNative: null,
        label: "Somali Region",
        cityCode: "ET_SO",
      },
      {
        name: "Southern Nations, Nationalities, and Peoples' Region",
        countryCode: "ET",
        latitude: "6.51569110",
        longitude: "36.95410700",
        nameNative: null,
        label: "Southern Nations, Nationalities, and Peoples' Region",
        cityCode: "ET_SN",
      },
      {
        name: "Tigray Region",
        countryCode: "ET",
        latitude: "14.03233360",
        longitude: "38.31657250",
        nameNative: null,
        label: "Tigray Region",
        cityCode: "ET_TI",
      },
    ],
    FJ: [
      {
        name: "Ba",
        countryCode: "FJ",
        latitude: "36.06138930",
        longitude: "-95.80058720",
        nameNative: null,
        label: "Ba",
        cityCode: "FJ_01",
      },
      {
        name: "Bua",
        countryCode: "FJ",
        latitude: "43.09645840",
        longitude: "-89.50088000",
        nameNative: null,
        label: "Bua",
        cityCode: "FJ_02",
      },
      {
        name: "Cakaudrove",
        countryCode: "FJ",
        latitude: "-16.58141050",
        longitude: "179.51200840",
        nameNative: null,
        label: "Cakaudrove",
        cityCode: "FJ_03",
      },
      {
        name: "Central Division",
        countryCode: "FJ",
        latitude: "34.04400660",
        longitude: "-118.24727380",
        nameNative: null,
        label: "Central Division",
        cityCode: "FJ_C",
      },
      {
        name: "Eastern Division",
        countryCode: "FJ",
        latitude: "32.80943050",
        longitude: "-117.12899370",
        nameNative: null,
        label: "Eastern Division",
        cityCode: "FJ_E",
      },
      {
        name: "Kadavu",
        countryCode: "FJ",
        latitude: "-19.01271220",
        longitude: "178.18766760",
        nameNative: null,
        label: "Kadavu",
        cityCode: "FJ_04",
      },
      {
        name: "Lau",
        countryCode: "FJ",
        latitude: "31.66870150",
        longitude: "-106.39557630",
        nameNative: null,
        label: "Lau",
        cityCode: "FJ_05",
      },
      {
        name: "Lomaiviti",
        countryCode: "FJ",
        latitude: "-17.70900000",
        longitude: "179.09100000",
        nameNative: null,
        label: "Lomaiviti",
        cityCode: "FJ_06",
      },
      {
        name: "Macuata",
        countryCode: "FJ",
        latitude: "-16.48649220",
        longitude: "179.28472510",
        nameNative: null,
        label: "Macuata",
        cityCode: "FJ_07",
      },
      {
        name: "Nadroga-Navosa",
        countryCode: "FJ",
        latitude: "-17.98652780",
        longitude: "177.65811300",
        nameNative: null,
        label: "Nadroga-Navosa",
        cityCode: "FJ_08",
      },
      {
        name: "Naitasiri",
        countryCode: "FJ",
        latitude: "-17.89757540",
        longitude: "178.20715980",
        nameNative: null,
        label: "Naitasiri",
        cityCode: "FJ_09",
      },
      {
        name: "Namosi",
        countryCode: "FJ",
        latitude: "-18.08641760",
        longitude: "178.12913870",
        nameNative: null,
        label: "Namosi",
        cityCode: "FJ_10",
      },
      {
        name: "Northern Division",
        countryCode: "FJ",
        latitude: "32.87687660",
        longitude: "-117.21563450",
        nameNative: null,
        label: "Northern Division",
        cityCode: "FJ_N",
      },
      {
        name: "Ra",
        countryCode: "FJ",
        latitude: "37.10031530",
        longitude: "-95.67442460",
        nameNative: null,
        label: "Ra",
        cityCode: "FJ_11",
      },
      {
        name: "Rewa",
        countryCode: "FJ",
        latitude: "34.79235170",
        longitude: "-82.36092640",
        nameNative: null,
        label: "Rewa",
        cityCode: "FJ_12",
      },
      {
        name: "Rotuma",
        countryCode: "FJ",
        latitude: "-12.50250690",
        longitude: "177.07241640",
        nameNative: null,
        label: "Rotuma",
        cityCode: "FJ_R",
      },
      {
        name: "Serua",
        countryCode: "FJ",
        latitude: "-18.18047490",
        longitude: "178.05097900",
        nameNative: null,
        label: "Serua",
        cityCode: "FJ_13",
      },
      {
        name: "Tailevu",
        countryCode: "FJ",
        latitude: "-17.82691110",
        longitude: "178.29324800",
        nameNative: null,
        label: "Tailevu",
        cityCode: "FJ_14",
      },
      {
        name: "Western Division",
        countryCode: "FJ",
        latitude: "42.96621980",
        longitude: "-78.70211340",
        nameNative: null,
        label: "Western Division",
        cityCode: "FJ_W",
      },
    ],
    FI: [
      {
        name: "Central Finland",
        countryCode: "FI",
        latitude: "62.56667430",
        longitude: "25.55494450",
        nameNative: null,
        label: "Central Finland",
        cityCode: "FI_08",
      },
      {
        name: "Central Ostrobothnia",
        countryCode: "FI",
        latitude: "63.56217350",
        longitude: "24.00136310",
        nameNative: null,
        label: "Central Ostrobothnia",
        cityCode: "FI_07",
      },
      {
        name: "Eastern Finland Province",
        countryCode: "FI",
        latitude: "62.56338910",
        longitude: "28.50240420",
        nameNative: null,
        label: "Eastern Finland Province",
        cityCode: "FI_IS",
      },
      {
        name: "Finland Proper",
        countryCode: "FI",
        latitude: "60.36279140",
        longitude: "22.44393690",
        nameNative: null,
        label: "Finland Proper",
        cityCode: "FI_19",
      },
      {
        name: "Kainuu",
        countryCode: "FI",
        latitude: "64.37365640",
        longitude: "28.74374750",
        nameNative: null,
        label: "Kainuu",
        cityCode: "FI_05",
      },
      {
        name: "Kymenlaakso",
        countryCode: "FI",
        latitude: "60.78051200",
        longitude: "26.88293360",
        nameNative: null,
        label: "Kymenlaakso",
        cityCode: "FI_09",
      },
      {
        name: "Lapland",
        countryCode: "FI",
        latitude: "67.92223040",
        longitude: "26.50464380",
        nameNative: null,
        label: "Lapland",
        cityCode: "FI_LL",
      },
      {
        name: "North Karelia",
        countryCode: "FI",
        latitude: "62.80620780",
        longitude: "30.15538870",
        nameNative: null,
        label: "North Karelia",
        cityCode: "FI_13",
      },
      {
        name: "Northern Ostrobothnia",
        countryCode: "FI",
        latitude: "65.27949300",
        longitude: "26.28904170",
        nameNative: null,
        label: "Northern Ostrobothnia",
        cityCode: "FI_14",
      },
      {
        name: "Northern Savonia",
        countryCode: "FI",
        latitude: "63.08448000",
        longitude: "27.02535040",
        nameNative: null,
        label: "Northern Savonia",
        cityCode: "FI_15",
      },
      {
        name: "Ostrobothnia",
        countryCode: "FI",
        latitude: "63.11817570",
        longitude: "21.90610620",
        nameNative: null,
        label: "Ostrobothnia",
        cityCode: "FI_12",
      },
      {
        name: "Oulu Province",
        countryCode: "FI",
        latitude: "65.01207480",
        longitude: "25.46504960",
        nameNative: null,
        label: "Oulu Province",
        cityCode: "FI_OL",
      },
      {
        name: "Pirkanmaa",
        countryCode: "FI",
        latitude: "61.69869180",
        longitude: "23.78955980",
        nameNative: null,
        label: "Pirkanmaa",
        cityCode: "FI_11",
      },
      {
        name: "Päijänne Tavastia",
        countryCode: "FI",
        latitude: "61.32300410",
        longitude: "25.73224960",
        nameNative: null,
        label: "Päijänne Tavastia",
        cityCode: "FI_16",
      },
      {
        name: "Satakunta",
        countryCode: "FI",
        latitude: "61.59327580",
        longitude: "22.14830810",
        nameNative: null,
        label: "Satakunta",
        cityCode: "FI_17",
      },
      {
        name: "South Karelia",
        countryCode: "FI",
        latitude: "61.11819490",
        longitude: "28.10243720",
        nameNative: null,
        label: "South Karelia",
        cityCode: "FI_02",
      },
      {
        name: "Southern Ostrobothnia",
        countryCode: "FI",
        latitude: "62.94330990",
        longitude: "23.52852670",
        nameNative: null,
        label: "Southern Ostrobothnia",
        cityCode: "FI_03",
      },
      {
        name: "Southern Savonia",
        countryCode: "FI",
        latitude: "61.69451480",
        longitude: "27.80050150",
        nameNative: null,
        label: "Southern Savonia",
        cityCode: "FI_04",
      },
      {
        name: "Tavastia Proper",
        countryCode: "FI",
        latitude: "60.90701500",
        longitude: "24.30054980",
        nameNative: null,
        label: "Tavastia Proper",
        cityCode: "FI_06",
      },
      {
        name: "Uusimaa",
        countryCode: "FI",
        latitude: "60.21872000",
        longitude: "25.27162100",
        nameNative: null,
        label: "Uusimaa",
        cityCode: "FI_18",
      },
      {
        name: "Åland Islands",
        countryCode: "FI",
        latitude: "60.17852470",
        longitude: "19.91561050",
        nameNative: null,
        label: "Åland Islands",
        cityCode: "FI_01",
      },
    ],
    FR: [
      {
        name: "Ain",
        countryCode: "FR",
        latitude: "46.06508600",
        longitude: "4.88861500",
        nameNative: "Ain",
        label: "Ain / Ain",
        cityCode: "FR_01",
      },
      {
        name: "Aisne",
        countryCode: "FR",
        latitude: "49.45289210",
        longitude: "3.04651110",
        nameNative: "Aisne",
        label: "Aisne / Aisne",
        cityCode: "FR_02",
      },
      {
        name: "Allier",
        countryCode: "FR",
        latitude: "46.36708630",
        longitude: "2.58082770",
        nameNative: "Allier",
        label: "Allier / Allier",
        cityCode: "FR_03",
      },
      {
        name: "Alpes-Maritimes",
        countryCode: "FR",
        latitude: "43.92041700",
        longitude: "6.61678220",
        nameNative: "Alpes-Maritimes",
        label: "Alpes-Maritimes / Alpes-Maritimes",
        cityCode: "FR_06",
      },
      {
        name: "Alpes-de-Haute-Provence",
        countryCode: "FR",
        latitude: "44.16377520",
        longitude: "5.67247800",
        nameNative: "Alpes-de-Haute-Provence",
        label: "Alpes-de-Haute-Provence / Alpes-de-Haute-Provence",
        cityCode: "FR_04",
      },
      {
        name: "Alsace",
        countryCode: "FR",
        latitude: "48.31817950",
        longitude: "7.44162410",
        nameNative: "Alsace",
        label: "Alsace / Alsace",
        cityCode: "FR_6AE",
      },
      {
        name: "Ardennes",
        countryCode: "FR",
        latitude: "49.69759510",
        longitude: "4.14895760",
        nameNative: "Ardennes",
        label: "Ardennes / Ardennes",
        cityCode: "FR_08",
      },
      {
        name: "Ardèche",
        countryCode: "FR",
        latitude: "44.81486950",
        longitude: "3.81334830",
        nameNative: "Ardèche",
        label: "Ardèche / Ardèche",
        cityCode: "FR_07",
      },
      {
        name: "Ariège",
        countryCode: "FR",
        latitude: "42.94347830",
        longitude: "0.94048640",
        nameNative: "Ariège",
        label: "Ariège / Ariège",
        cityCode: "FR_09",
      },
      {
        name: "Aube",
        countryCode: "FR",
        latitude: "48.31975470",
        longitude: "3.56371040",
        nameNative: "Aube",
        label: "Aube / Aube",
        cityCode: "FR_10",
      },
      {
        name: "Aude",
        countryCode: "FR",
        latitude: "43.05411400",
        longitude: "1.90384760",
        nameNative: "Aude",
        label: "Aude / Aude",
        cityCode: "FR_11",
      },
      {
        name: "Auvergne-Rhône-Alpes",
        countryCode: "FR",
        latitude: "45.44714310",
        longitude: "4.38525070",
        nameNative: "Auvergne-Rhône-Alpes",
        label: "Auvergne-Rhône-Alpes / Auvergne-Rhône-Alpes",
        cityCode: "FR_ARA",
      },
      {
        name: "Aveyron",
        countryCode: "FR",
        latitude: "44.31563620",
        longitude: "2.08523790",
        nameNative: "Aveyron",
        label: "Aveyron / Aveyron",
        cityCode: "FR_12",
      },
      {
        name: "Bas-Rhin",
        countryCode: "FR",
        latitude: "48.59864440",
        longitude: "7.02666760",
        nameNative: "Bas-Rhin",
        label: "Bas-Rhin / Bas-Rhin",
        cityCode: "FR_67",
      },
      {
        name: "Bouches-du-Rhône",
        countryCode: "FR",
        latitude: "43.54038650",
        longitude: "4.46138290",
        nameNative: "Bouches-du-Rhône",
        label: "Bouches-du-Rhône / Bouches-du-Rhône",
        cityCode: "FR_13",
      },
      {
        name: "Bourgogne-Franche-Comté",
        countryCode: "FR",
        latitude: "47.28051270",
        longitude: "4.99943720",
        nameNative: "Bourgogne-Franche-Comté",
        label: "Bourgogne-Franche-Comté / Bourgogne-Franche-Comté",
        cityCode: "FR_BFC",
      },
      {
        name: "Bretagne",
        countryCode: "FR",
        latitude: "48.20204710",
        longitude: "-2.93264350",
        nameNative: "Bretagne",
        label: "Bretagne / Bretagne",
        cityCode: "FR_BRE",
      },
      {
        name: "Calvados",
        countryCode: "FR",
        latitude: "49.09035140",
        longitude: "-0.91706480",
        nameNative: "Calvados",
        label: "Calvados / Calvados",
        cityCode: "FR_14",
      },
      {
        name: "Cantal",
        countryCode: "FR",
        latitude: "45.04921770",
        longitude: "2.15672720",
        nameNative: "Cantal",
        label: "Cantal / Cantal",
        cityCode: "FR_15",
      },
      {
        name: "Centre-Val de Loire",
        countryCode: "FR",
        latitude: "47.75156860",
        longitude: "1.67506310",
        nameNative: "Centre-Val de Loire",
        label: "Centre-Val de Loire / Centre-Val de Loire",
        cityCode: "FR_CVL",
      },
      {
        name: "Charente",
        countryCode: "FR",
        latitude: "45.66584790",
        longitude: "-0.31845770",
        nameNative: "Charente",
        label: "Charente / Charente",
        cityCode: "FR_16",
      },
      {
        name: "Charente-Maritime",
        countryCode: "FR",
        latitude: "45.72968280",
        longitude: "-1.33881160",
        nameNative: "Charente-Maritime",
        label: "Charente-Maritime / Charente-Maritime",
        cityCode: "FR_17",
      },
      {
        name: "Cher",
        countryCode: "FR",
        latitude: "47.02436280",
        longitude: "1.86627320",
        nameNative: "Cher",
        label: "Cher / Cher",
        cityCode: "FR_18",
      },
      {
        name: "Clipperton",
        countryCode: "FR",
        latitude: "10.28335410",
        longitude: "-109.22542150",
        nameNative: "Clipperton",
        label: "Clipperton / Clipperton",
        cityCode: "FR_CP",
      },
      {
        name: "Corrèze",
        countryCode: "FR",
        latitude: "45.34237070",
        longitude: "1.31717330",
        nameNative: "Corrèze",
        label: "Corrèze / Corrèze",
        cityCode: "FR_19",
      },
      {
        name: "Corse",
        countryCode: "FR",
        latitude: "42.03960420",
        longitude: "9.01289260",
        nameNative: "Corse",
        label: "Corse / Corse",
        cityCode: "FR_20R",
      },
      {
        name: "Corse-du-Sud",
        countryCode: "FR",
        latitude: "41.85720550",
        longitude: "8.41091830",
        nameNative: "Corse-du-Sud",
        label: "Corse-du-Sud / Corse-du-Sud",
        cityCode: "FR_2A",
      },
      {
        name: "Creuse",
        countryCode: "FR",
        latitude: "46.05903940",
        longitude: "1.43150500",
        nameNative: "Creuse",
        label: "Creuse / Creuse",
        cityCode: "FR_23",
      },
      {
        name: "Côte-d'Or",
        countryCode: "FR",
        latitude: "47.46513020",
        longitude: "4.23154950",
        nameNative: "Côte-d'Or",
        label: "Côte-d'Or / Côte-d'Or",
        cityCode: "FR_21",
      },
      {
        name: "Côtes-d'Armor",
        countryCode: "FR",
        latitude: "48.46633360",
        longitude: "-3.34789610",
        nameNative: "Côtes-d'Armor",
        label: "Côtes-d'Armor / Côtes-d'Armor",
        cityCode: "FR_22",
      },
      {
        name: "Deux-Sèvres",
        countryCode: "FR",
        latitude: "46.53868170",
        longitude: "-0.90199480",
        nameNative: "Deux-Sèvres",
        label: "Deux-Sèvres / Deux-Sèvres",
        cityCode: "FR_79",
      },
      {
        name: "Dordogne",
        countryCode: "FR",
        latitude: "45.14234160",
        longitude: "0.14274080",
        nameNative: "Dordogne",
        label: "Dordogne / Dordogne",
        cityCode: "FR_24",
      },
      {
        name: "Doubs",
        countryCode: "FR",
        latitude: "46.93217740",
        longitude: "6.34762140",
        nameNative: "Doubs",
        label: "Doubs / Doubs",
        cityCode: "FR_25",
      },
      {
        name: "Drôme",
        countryCode: "FR",
        latitude: "44.72933570",
        longitude: "4.67821580",
        nameNative: "Drôme",
        label: "Drôme / Drôme",
        cityCode: "FR_26",
      },
      {
        name: "Essonne",
        countryCode: "FR",
        latitude: "48.53046150",
        longitude: "1.96990560",
        nameNative: "Essonne",
        label: "Essonne / Essonne",
        cityCode: "FR_91",
      },
      {
        name: "Eure",
        countryCode: "FR",
        latitude: "49.07540350",
        longitude: "0.48937320",
        nameNative: "Eure",
        label: "Eure / Eure",
        cityCode: "FR_27",
      },
      {
        name: "Eure-et-Loir",
        countryCode: "FR",
        latitude: "48.44697840",
        longitude: "0.81470250",
        nameNative: "Eure-et-Loir",
        label: "Eure-et-Loir / Eure-et-Loir",
        cityCode: "FR_28",
      },
      {
        name: "Finistère",
        countryCode: "FR",
        latitude: "48.22696100",
        longitude: "-4.82437330",
        nameNative: "Finistère",
        label: "Finistère / Finistère",
        cityCode: "FR_29",
      },
      {
        name: "French Guiana",
        countryCode: "FR",
        latitude: "3.93388900",
        longitude: "-53.12578200",
        nameNative: "Guyane française",
        label: "Guyane française / French Guiana",
        cityCode: "FR_973",
      },
      {
        name: "French Polynesia",
        countryCode: "FR",
        latitude: "-17.67974200",
        longitude: "-149.40684300",
        nameNative: "Polynésie française",
        label: "Polynésie française / French Polynesia",
        cityCode: "FR_PF",
      },
      {
        name: "French Southern and Antarctic Lands",
        countryCode: "FR",
        latitude: "-47.54466040",
        longitude: "51.28375420",
        nameNative: "Terres australes et antarctiques françaises",
        label:
          "Terres australes et antarctiques françaises / French Southern and Antarctic Lands",
        cityCode: "FR_TF",
      },
      {
        name: "Gard",
        countryCode: "FR",
        latitude: "43.95952760",
        longitude: "3.49356810",
        nameNative: "Gard",
        label: "Gard / Gard",
        cityCode: "FR_30",
      },
      {
        name: "Gers",
        countryCode: "FR",
        latitude: "43.69505340",
        longitude: "-0.09997280",
        nameNative: "Gers",
        label: "Gers / Gers",
        cityCode: "FR_32",
      },
      {
        name: "Gironde",
        countryCode: "FR",
        latitude: "44.89584690",
        longitude: "-1.59405320",
        nameNative: "Gironde",
        label: "Gironde / Gironde",
        cityCode: "FR_33",
      },
      {
        name: "Grand-Est",
        countryCode: "FR",
        latitude: "48.69980300",
        longitude: "6.18780740",
        nameNative: "Grand Est",
        label: "Grand Est / Grand-Est",
        cityCode: "FR_GES",
      },
      {
        name: "Guadeloupe",
        countryCode: "FR",
        latitude: "16.26500000",
        longitude: "-61.55100000",
        nameNative: "Guadeloupe",
        label: "Guadeloupe / Guadeloupe",
        cityCode: "FR_971",
      },
      {
        name: "Haut-Rhin",
        countryCode: "FR",
        latitude: "47.86537740",
        longitude: "6.67113810",
        nameNative: "Haut-Rhin",
        label: "Haut-Rhin / Haut-Rhin",
        cityCode: "FR_68",
      },
      {
        name: "Haute-Corse",
        countryCode: "FR",
        latitude: "42.42958660",
        longitude: "8.50625610",
        nameNative: "Haute-Corse",
        label: "Haute-Corse / Haute-Corse",
        cityCode: "FR_2B",
      },
      {
        name: "Haute-Garonne",
        countryCode: "FR",
        latitude: "43.30505550",
        longitude: "0.68455150",
        nameNative: "Haute-Garonne",
        label: "Haute-Garonne / Haute-Garonne",
        cityCode: "FR_31",
      },
      {
        name: "Haute-Loire",
        countryCode: "FR",
        latitude: "45.08538060",
        longitude: "3.22607070",
        nameNative: "Haute-Loire",
        label: "Haute-Loire / Haute-Loire",
        cityCode: "FR_43",
      },
      {
        name: "Haute-Marne",
        countryCode: "FR",
        latitude: "48.13248210",
        longitude: "4.69834990",
        nameNative: "Haute-Marne",
        label: "Haute-Marne / Haute-Marne",
        cityCode: "FR_52",
      },
      {
        name: "Haute-Savoie",
        countryCode: "FR",
        latitude: "46.04452770",
        longitude: "5.86413800",
        nameNative: "Haute-Savoie",
        label: "Haute-Savoie / Haute-Savoie",
        cityCode: "FR_74",
      },
      {
        name: "Haute-Saône",
        countryCode: "FR",
        latitude: "47.63789960",
        longitude: "5.53550550",
        nameNative: "Haute-Saône",
        label: "Haute-Saône / Haute-Saône",
        cityCode: "FR_70",
      },
      {
        name: "Haute-Vienne",
        countryCode: "FR",
        latitude: "45.91868780",
        longitude: "0.70972060",
        nameNative: "Haute-Vienne",
        label: "Haute-Vienne / Haute-Vienne",
        cityCode: "FR_87",
      },
      {
        name: "Hautes-Alpes",
        countryCode: "FR",
        latitude: "44.65626820",
        longitude: "5.68732110",
        nameNative: "Hautes-Alpes",
        label: "Hautes-Alpes / Hautes-Alpes",
        cityCode: "FR_05",
      },
      {
        name: "Hautes-Pyrénées",
        countryCode: "FR",
        latitude: "43.14294620",
        longitude: "-0.40097360",
        nameNative: "Hautes-Pyrénées",
        label: "Hautes-Pyrénées / Hautes-Pyrénées",
        cityCode: "FR_65",
      },
      {
        name: "Hauts-de-France",
        countryCode: "FR",
        latitude: "50.48011530",
        longitude: "2.79372650",
        nameNative: "Hauts-de-France",
        label: "Hauts-de-France / Hauts-de-France",
        cityCode: "FR_HDF",
      },
      {
        name: "Hauts-de-Seine",
        countryCode: "FR",
        latitude: "48.84030080",
        longitude: "2.10125590",
        nameNative: "Hauts-de-Seine",
        label: "Hauts-de-Seine / Hauts-de-Seine",
        cityCode: "FR_92",
      },
      {
        name: "Hérault",
        countryCode: "FR",
        latitude: "43.59111200",
        longitude: "2.80661080",
        nameNative: "Hérault",
        label: "Hérault / Hérault",
        cityCode: "FR_34",
      },
      {
        name: "Ille-et-Vilaine",
        countryCode: "FR",
        latitude: "48.17624840",
        longitude: "-2.21304010",
        nameNative: "Ille-et-Vilaine",
        label: "Ille-et-Vilaine / Ille-et-Vilaine",
        cityCode: "FR_35",
      },
      {
        name: "Indre",
        countryCode: "FR",
        latitude: "46.81175500",
        longitude: "0.97555230",
        nameNative: "Indre",
        label: "Indre / Indre",
        cityCode: "FR_36",
      },
      {
        name: "Indre-et-Loire",
        countryCode: "FR",
        latitude: "47.22285820",
        longitude: "0.14896190",
        nameNative: "Indre-et-Loire",
        label: "Indre-et-Loire / Indre-et-Loire",
        cityCode: "FR_37",
      },
      {
        name: "Isère",
        countryCode: "FR",
        latitude: "45.28922710",
        longitude: "4.99023550",
        nameNative: "Isère",
        label: "Isère / Isère",
        cityCode: "FR_38",
      },
      {
        name: "Jura",
        countryCode: "FR",
        latitude: "46.78287410",
        longitude: "5.16918440",
        nameNative: "Jura",
        label: "Jura / Jura",
        cityCode: "FR_39",
      },
      {
        name: "La Réunion",
        countryCode: "FR",
        latitude: "-21.11514100",
        longitude: "55.53638400",
        nameNative: "La Réunion",
        label: "La Réunion / La Réunion",
        cityCode: "FR_974",
      },
      {
        name: "Landes",
        countryCode: "FR",
        latitude: "44.00950800",
        longitude: "-1.25385790",
        nameNative: "Landes",
        label: "Landes / Landes",
        cityCode: "FR_40",
      },
      {
        name: "Loir-et-Cher",
        countryCode: "FR",
        latitude: "47.65937600",
        longitude: "0.85376310",
        nameNative: "Loir-et-Cher",
        label: "Loir-et-Cher / Loir-et-Cher",
        cityCode: "FR_41",
      },
      {
        name: "Loire",
        countryCode: "FR",
        latitude: "46.35228120",
        longitude: "-1.17563390",
        nameNative: "Loire",
        label: "Loire / Loire",
        cityCode: "FR_42",
      },
      {
        name: "Loire-Atlantique",
        countryCode: "FR",
        latitude: "47.34757210",
        longitude: "-2.34663120",
        nameNative: "Loire-Atlantique",
        label: "Loire-Atlantique / Loire-Atlantique",
        cityCode: "FR_44",
      },
      {
        name: "Loiret",
        countryCode: "FR",
        latitude: "47.91354310",
        longitude: "1.76009900",
        nameNative: "Loiret",
        label: "Loiret / Loiret",
        cityCode: "FR_45",
      },
      {
        name: "Lot",
        countryCode: "FR",
        latitude: "44.62460700",
        longitude: "1.03576310",
        nameNative: "Lot",
        label: "Lot / Lot",
        cityCode: "FR_46",
      },
      {
        name: "Lot-et-Garonne",
        countryCode: "FR",
        latitude: "44.36873140",
        longitude: "-0.09161690",
        nameNative: "Lot-et-Garonne",
        label: "Lot-et-Garonne / Lot-et-Garonne",
        cityCode: "FR_47",
      },
      {
        name: "Lozère",
        countryCode: "FR",
        latitude: "44.54227790",
        longitude: "2.92934590",
        nameNative: "Lozère",
        label: "Lozère / Lozère",
        cityCode: "FR_48",
      },
      {
        name: "Maine-et-Loire",
        countryCode: "FR",
        latitude: "47.38900340",
        longitude: "-1.12025270",
        nameNative: "Maine-et-Loire",
        label: "Maine-et-Loire / Maine-et-Loire",
        cityCode: "FR_49",
      },
      {
        name: "Manche",
        countryCode: "FR",
        latitude: "49.08817340",
        longitude: "-2.46272090",
        nameNative: "Manche",
        label: "Manche / Manche",
        cityCode: "FR_50",
      },
      {
        name: "Marne",
        countryCode: "FR",
        latitude: "48.96107450",
        longitude: "3.65737670",
        nameNative: "Marne",
        label: "Marne / Marne",
        cityCode: "FR_51",
      },
      {
        name: "Martinique",
        countryCode: "FR",
        latitude: "14.64152800",
        longitude: "-61.02417400",
        nameNative: "Martinique",
        label: "Martinique / Martinique",
        cityCode: "FR_972",
      },
      {
        name: "Mayenne",
        countryCode: "FR",
        latitude: "48.30668420",
        longitude: "-0.64901820",
        nameNative: "Mayenne",
        label: "Mayenne / Mayenne",
        cityCode: "FR_53",
      },
      {
        name: "Mayotte",
        countryCode: "FR",
        latitude: "-12.82750000",
        longitude: "45.16624400",
        nameNative: "Mayotte",
        label: "Mayotte / Mayotte",
        cityCode: "FR_976",
      },
      {
        name: "Meurthe-et-Moselle",
        countryCode: "FR",
        latitude: "48.95566150",
        longitude: "5.71423500",
        nameNative: "Meurthe-et-Moselle",
        label: "Meurthe-et-Moselle / Meurthe-et-Moselle",
        cityCode: "FR_54",
      },
      {
        name: "Meuse",
        countryCode: "FR",
        latitude: "49.01246200",
        longitude: "4.81087340",
        nameNative: "Meuse",
        label: "Meuse / Meuse",
        cityCode: "FR_55",
      },
      {
        name: "Morbihan",
        countryCode: "FR",
        latitude: "47.74395180",
        longitude: "-3.44555240",
        nameNative: "Morbihan",
        label: "Morbihan / Morbihan",
        cityCode: "FR_56",
      },
      {
        name: "Moselle",
        countryCode: "FR",
        latitude: "49.02045660",
        longitude: "6.20553220",
        nameNative: "Moselle",
        label: "Moselle / Moselle",
        cityCode: "FR_57",
      },
      {
        name: "Métropole de Lyon",
        countryCode: "FR",
        latitude: "45.74826290",
        longitude: "4.59584040",
        nameNative: "Métropole de Lyon",
        label: "Métropole de Lyon / Métropole de Lyon",
        cityCode: "FR_69M",
      },
      {
        name: "Nièvre",
        countryCode: "FR",
        latitude: "47.11921640",
        longitude: "2.97797130",
        nameNative: "Nièvre",
        label: "Nièvre / Nièvre",
        cityCode: "FR_58",
      },
      {
        name: "Nord",
        countryCode: "FR",
        latitude: "50.52854770",
        longitude: "2.60007760",
        nameNative: "Nord",
        label: "Nord / Nord",
        cityCode: "FR_59",
      },
      {
        name: "Normandie",
        countryCode: "FR",
        latitude: "48.87987040",
        longitude: "0.17125290",
        nameNative: "Normandie",
        label: "Normandie / Normandie",
        cityCode: "FR_NOR",
      },
      {
        name: "Nouvelle-Aquitaine",
        countryCode: "FR",
        latitude: "45.70871820",
        longitude: "0.62689100",
        nameNative: "Nouvelle-Aquitaine",
        label: "Nouvelle-Aquitaine / Nouvelle-Aquitaine",
        cityCode: "FR_NAQ",
      },
      {
        name: "Occitanie",
        countryCode: "FR",
        latitude: "43.89272320",
        longitude: "3.28276250",
        nameNative: "Occitanie",
        label: "Occitanie / Occitanie",
        cityCode: "FR_OCC",
      },
      {
        name: "Oise",
        countryCode: "FR",
        latitude: "49.41173350",
        longitude: "1.86688250",
        nameNative: "Oise",
        label: "Oise / Oise",
        cityCode: "FR_60",
      },
      {
        name: "Orne",
        countryCode: "FR",
        latitude: "48.57576440",
        longitude: "-0.50242950",
        nameNative: "Orne",
        label: "Orne / Orne",
        cityCode: "FR_61",
      },
      {
        name: "Paris",
        countryCode: "FR",
        latitude: "48.85661400",
        longitude: "2.35222190",
        nameNative: "Paris",
        label: "Paris / Paris",
        cityCode: "FR_75C",
      },
      {
        name: "Pas-de-Calais",
        countryCode: "FR",
        latitude: "50.51446990",
        longitude: "1.81149800",
        nameNative: "Pas-de-Calais",
        label: "Pas-de-Calais / Pas-de-Calais",
        cityCode: "FR_62",
      },
      {
        name: "Pays-de-la-Loire",
        countryCode: "FR",
        latitude: "47.76328360",
        longitude: "-0.32996870",
        nameNative: "Pays-de-la-Loire",
        label: "Pays-de-la-Loire / Pays-de-la-Loire",
        cityCode: "FR_PDL",
      },
      {
        name: "Provence-Alpes-Côte-d’Azur",
        countryCode: "FR",
        latitude: "43.93516910",
        longitude: "6.06791940",
        nameNative: "Provence-Alpes-Côte d'Azur",
        label: "Provence-Alpes-Côte d'Azur / Provence-Alpes-Côte-d’Azur",
        cityCode: "FR_PAC",
      },
      {
        name: "Puy-de-Dôme",
        countryCode: "FR",
        latitude: "45.77141850",
        longitude: "2.62626760",
        nameNative: "Puy-de-Dôme",
        label: "Puy-de-Dôme / Puy-de-Dôme",
        cityCode: "FR_63",
      },
      {
        name: "Pyrénées-Atlantiques",
        countryCode: "FR",
        latitude: "43.18681700",
        longitude: "-1.44170710",
        nameNative: "Pyrénées-Atlantiques",
        label: "Pyrénées-Atlantiques / Pyrénées-Atlantiques",
        cityCode: "FR_64",
      },
      {
        name: "Pyrénées-Orientales",
        countryCode: "FR",
        latitude: "42.62541790",
        longitude: "1.88929580",
        nameNative: "Pyrénées-Orientales",
        label: "Pyrénées-Orientales / Pyrénées-Orientales",
        cityCode: "FR_66",
      },
      {
        name: "Rhône",
        countryCode: "FR",
        latitude: "44.93433000",
        longitude: "4.24093290",
        nameNative: "Rhône",
        label: "Rhône / Rhône",
        cityCode: "FR_69",
      },
      {
        name: "Saint Pierre and Miquelon",
        countryCode: "FR",
        latitude: "46.88520000",
        longitude: "-56.31590000",
        nameNative: "Saint-Pierre-et-Miquelon",
        label: "Saint-Pierre-et-Miquelon / Saint Pierre and Miquelon",
        cityCode: "FR_PM",
      },
      {
        name: "Saint-Barthélemy",
        countryCode: "FR",
        latitude: "17.90051340",
        longitude: "-62.82058710",
        nameNative: "Saint-Barthélemy",
        label: "Saint-Barthélemy / Saint-Barthélemy",
        cityCode: "FR_BL",
      },
      {
        name: "Saint-Martin",
        countryCode: "FR",
        latitude: "18.07082980",
        longitude: "-63.05008090",
        nameNative: "Saint-Martin",
        label: "Saint-Martin / Saint-Martin",
        cityCode: "FR_MF",
      },
      {
        name: "Sarthe",
        countryCode: "FR",
        latitude: "48.02627330",
        longitude: "-0.32613170",
        nameNative: "Sarthe",
        label: "Sarthe / Sarthe",
        cityCode: "FR_72",
      },
      {
        name: "Savoie",
        countryCode: "FR",
        latitude: "45.49469900",
        longitude: "5.84329840",
        nameNative: "Savoie",
        label: "Savoie / Savoie",
        cityCode: "FR_73",
      },
      {
        name: "Saône-et-Loire",
        countryCode: "FR",
        latitude: "46.65548830",
        longitude: "3.98350500",
        nameNative: "Saône-et-Loire",
        label: "Saône-et-Loire / Saône-et-Loire",
        cityCode: "FR_71",
      },
      {
        name: "Seine-Maritime",
        countryCode: "FR",
        latitude: "49.66096810",
        longitude: "0.36775610",
        nameNative: "Seine-Maritime",
        label: "Seine-Maritime / Seine-Maritime",
        cityCode: "FR_76",
      },
      {
        name: "Seine-Saint-Denis",
        countryCode: "FR",
        latitude: "48.90993180",
        longitude: "2.30573790",
        nameNative: "Seine-Saint-Denis",
        label: "Seine-Saint-Denis / Seine-Saint-Denis",
        cityCode: "FR_93",
      },
      {
        name: "Seine-et-Marne",
        countryCode: "FR",
        latitude: "48.61853940",
        longitude: "2.41525610",
        nameNative: "Seine-et-Marne",
        label: "Seine-et-Marne / Seine-et-Marne",
        cityCode: "FR_77",
      },
      {
        name: "Somme",
        countryCode: "FR",
        latitude: "49.96859220",
        longitude: "1.73106960",
        nameNative: "Somme",
        label: "Somme / Somme",
        cityCode: "FR_80",
      },
      {
        name: "Tarn",
        countryCode: "FR",
        latitude: "43.79149770",
        longitude: "1.67588930",
        nameNative: "Tarn",
        label: "Tarn / Tarn",
        cityCode: "FR_81",
      },
      {
        name: "Tarn-et-Garonne",
        countryCode: "FR",
        latitude: "44.08089500",
        longitude: "1.08916570",
        nameNative: "Tarn-et-Garonne",
        label: "Tarn-et-Garonne / Tarn-et-Garonne",
        cityCode: "FR_82",
      },
      {
        name: "Territoire de Belfort",
        countryCode: "FR",
        latitude: "47.62930720",
        longitude: "6.66962000",
        nameNative: "Territoire de Belfort",
        label: "Territoire de Belfort / Territoire de Belfort",
        cityCode: "FR_90",
      },
      {
        name: "Val-d'Oise",
        countryCode: "FR",
        latitude: "49.07518180",
        longitude: "1.82169140",
        nameNative: "Val-d'Oise",
        label: "Val-d'Oise / Val-d'Oise",
        cityCode: "FR_95",
      },
      {
        name: "Val-de-Marne",
        countryCode: "FR",
        latitude: "48.77470040",
        longitude: "2.32210390",
        nameNative: "Val-de-Marne",
        label: "Val-de-Marne / Val-de-Marne",
        cityCode: "FR_94",
      },
      {
        name: "Var",
        countryCode: "FR",
        latitude: "43.39507300",
        longitude: "5.73424170",
        nameNative: "Var",
        label: "Var / Var",
        cityCode: "FR_83",
      },
      {
        name: "Vaucluse",
        countryCode: "FR",
        latitude: "44.04475000",
        longitude: "4.64277180",
        nameNative: "Vaucluse",
        label: "Vaucluse / Vaucluse",
        cityCode: "FR_84",
      },
      {
        name: "Vendée",
        countryCode: "FR",
        latitude: "46.67541030",
        longitude: "-2.02983920",
        nameNative: "Vendée",
        label: "Vendée / Vendée",
        cityCode: "FR_85",
      },
      {
        name: "Vienne",
        countryCode: "FR",
        latitude: "45.52213140",
        longitude: "4.84531360",
        nameNative: "Vienne",
        label: "Vienne / Vienne",
        cityCode: "FR_86",
      },
      {
        name: "Vosges",
        countryCode: "FR",
        latitude: "48.16301730",
        longitude: "5.73556000",
        nameNative: "Vosges",
        label: "Vosges / Vosges",
        cityCode: "FR_88",
      },
      {
        name: "Wallis and Futuna",
        countryCode: "FR",
        latitude: "-14.29380000",
        longitude: "-178.11650000",
        nameNative: "Wallis-et-Futuna",
        label: "Wallis-et-Futuna / Wallis and Futuna",
        cityCode: "FR_WF",
      },
      {
        name: "Yonne",
        countryCode: "FR",
        latitude: "47.85476140",
        longitude: "3.03394040",
        nameNative: "Yonne",
        label: "Yonne / Yonne",
        cityCode: "FR_89",
      },
      {
        name: "Yvelines",
        countryCode: "FR",
        latitude: "48.76153010",
        longitude: "1.27729490",
        nameNative: "Yvelines",
        label: "Yvelines / Yvelines",
        cityCode: "FR_78",
      },
      {
        name: "Île-de-France",
        countryCode: "FR",
        latitude: "48.84991980",
        longitude: "2.63704110",
        nameNative: "Île-de-France",
        label: "Île-de-France / Île-de-France",
        cityCode: "FR_IDF",
      },
    ],
    GA: [
      {
        name: "Estuaire Province",
        countryCode: "GA",
        latitude: "0.44328640",
        longitude: "10.08072980",
        nameNative: null,
        label: "Estuaire Province",
        cityCode: "GA_1",
      },
      {
        name: "Haut-Ogooué Province",
        countryCode: "GA",
        latitude: "-1.47625440",
        longitude: "13.91439900",
        nameNative: null,
        label: "Haut-Ogooué Province",
        cityCode: "GA_2",
      },
      {
        name: "Moyen-Ogooué Province",
        countryCode: "GA",
        latitude: "-0.44278400",
        longitude: "10.43965600",
        nameNative: null,
        label: "Moyen-Ogooué Province",
        cityCode: "GA_3",
      },
      {
        name: "Ngounié Province",
        countryCode: "GA",
        latitude: "-1.49303030",
        longitude: "10.98070030",
        nameNative: null,
        label: "Ngounié Province",
        cityCode: "GA_4",
      },
      {
        name: "Nyanga Province",
        countryCode: "GA",
        latitude: "-2.88210330",
        longitude: "11.16173560",
        nameNative: null,
        label: "Nyanga Province",
        cityCode: "GA_5",
      },
      {
        name: "Ogooué-Ivindo Province",
        countryCode: "GA",
        latitude: "0.88183110",
        longitude: "13.17403480",
        nameNative: null,
        label: "Ogooué-Ivindo Province",
        cityCode: "GA_6",
      },
      {
        name: "Ogooué-Lolo Province",
        countryCode: "GA",
        latitude: "-0.88440930",
        longitude: "12.43805810",
        nameNative: null,
        label: "Ogooué-Lolo Province",
        cityCode: "GA_7",
      },
      {
        name: "Ogooué-Maritime Province",
        countryCode: "GA",
        latitude: "-1.34659750",
        longitude: "9.72326730",
        nameNative: null,
        label: "Ogooué-Maritime Province",
        cityCode: "GA_8",
      },
      {
        name: "Woleu-Ntem Province",
        countryCode: "GA",
        latitude: "2.29898270",
        longitude: "11.44669140",
        nameNative: null,
        label: "Woleu-Ntem Province",
        cityCode: "GA_9",
      },
    ],
    GM: [
      {
        name: "Banjul",
        countryCode: "GM",
        latitude: "13.45487610",
        longitude: "-16.57903230",
        nameNative: null,
        label: "Banjul",
        cityCode: "GM_B",
      },
      {
        name: "Central River Division",
        countryCode: "GM",
        latitude: "13.59944690",
        longitude: "-14.89216680",
        nameNative: null,
        label: "Central River Division",
        cityCode: "GM_M",
      },
      {
        name: "Lower River Division",
        countryCode: "GM",
        latitude: "13.35533060",
        longitude: "-15.92299000",
        nameNative: null,
        label: "Lower River Division",
        cityCode: "GM_L",
      },
      {
        name: "North Bank Division",
        countryCode: "GM",
        latitude: "13.52854360",
        longitude: "-16.01699710",
        nameNative: null,
        label: "North Bank Division",
        cityCode: "GM_N",
      },
      {
        name: "Upper River Division",
        countryCode: "GM",
        latitude: "13.42573660",
        longitude: "-14.00723480",
        nameNative: null,
        label: "Upper River Division",
        cityCode: "GM_U",
      },
      {
        name: "West Coast Division",
        countryCode: "GM",
        latitude: "5.97727980",
        longitude: "116.07542880",
        nameNative: null,
        label: "West Coast Division",
        cityCode: "GM_W",
      },
    ],
    GE: [
      {
        name: "Adjara",
        countryCode: "GE",
        latitude: "41.60056260",
        longitude: "42.06883830",
        nameNative: null,
        label: "Adjara",
        cityCode: "GE_AJ",
      },
      {
        name: "Autonomous Republic of Abkhazia",
        countryCode: "GE",
        latitude: "43.00155440",
        longitude: "41.02340700",
        nameNative: null,
        label: "Autonomous Republic of Abkhazia",
        cityCode: "GE_AB",
      },
      {
        name: "Guria",
        countryCode: "GE",
        latitude: "41.94427360",
        longitude: "42.04580910",
        nameNative: null,
        label: "Guria",
        cityCode: "GE_GU",
      },
      {
        name: "Imereti",
        countryCode: "GE",
        latitude: "42.23010800",
        longitude: "42.90086640",
        nameNative: null,
        label: "Imereti",
        cityCode: "GE_IM",
      },
      {
        name: "Kakheti",
        countryCode: "GE",
        latitude: "41.64816020",
        longitude: "45.69055540",
        nameNative: null,
        label: "Kakheti",
        cityCode: "GE_KA",
      },
      {
        name: "Khelvachauri Municipality",
        countryCode: "GE",
        latitude: "41.58019260",
        longitude: "41.66107420",
        nameNative: null,
        label: "Khelvachauri Municipality",
        cityCode: "GE_29",
      },
      {
        name: "Kvemo Kartli",
        countryCode: "GE",
        latitude: "41.47918330",
        longitude: "44.65604510",
        nameNative: null,
        label: "Kvemo Kartli",
        cityCode: "GE_KK",
      },
      {
        name: "Mtskheta-Mtianeti",
        countryCode: "GE",
        latitude: "42.16821850",
        longitude: "44.65060580",
        nameNative: null,
        label: "Mtskheta-Mtianeti",
        cityCode: "GE_MM",
      },
      {
        name: "Racha-Lechkhumi and Kvemo Svaneti",
        countryCode: "GE",
        latitude: "42.67188730",
        longitude: "43.05628360",
        nameNative: null,
        label: "Racha-Lechkhumi and Kvemo Svaneti",
        cityCode: "GE_RL",
      },
      {
        name: "Samegrelo-Zemo Svaneti",
        countryCode: "GE",
        latitude: "42.73522470",
        longitude: "42.16893620",
        nameNative: null,
        label: "Samegrelo-Zemo Svaneti",
        cityCode: "GE_SZ",
      },
      {
        name: "Samtskhe-Javakheti",
        countryCode: "GE",
        latitude: "41.54792960",
        longitude: "43.27764000",
        nameNative: null,
        label: "Samtskhe-Javakheti",
        cityCode: "GE_SJ",
      },
      {
        name: "Senaki Municipality",
        countryCode: "GE",
        latitude: "42.26963600",
        longitude: "42.06568960",
        nameNative: null,
        label: "Senaki Municipality",
        cityCode: "GE_50",
      },
      {
        name: "Shida Kartli",
        countryCode: "GE",
        latitude: "42.07569440",
        longitude: "43.95404620",
        nameNative: null,
        label: "Shida Kartli",
        cityCode: "GE_SK",
      },
      {
        name: "Tbilisi",
        countryCode: "GE",
        latitude: "41.71513770",
        longitude: "44.82709600",
        nameNative: null,
        label: "Tbilisi",
        cityCode: "GE_TB",
      },
    ],
    DE: [
      {
        name: "Baden-Württemberg",
        countryCode: "DE",
        latitude: "48.66160370",
        longitude: "9.35013360",
        nameNative: "Baden-Württemberg",
        label: "Baden-Württemberg / Baden-Württemberg",
        cityCode: "DE_BW",
      },
      {
        name: "Bavaria",
        countryCode: "DE",
        latitude: "48.79044720",
        longitude: "11.49788950",
        nameNative: "Bayern",
        label: "Bayern / Bavaria",
        cityCode: "DE_BY",
      },
      {
        name: "Berlin",
        countryCode: "DE",
        latitude: "52.52000660",
        longitude: "13.40495400",
        nameNative: "Berlin",
        label: "Berlin / Berlin",
        cityCode: "DE_BE",
      },
      {
        name: "Brandenburg",
        countryCode: "DE",
        latitude: "52.41252870",
        longitude: "12.53164440",
        nameNative: "Brandenburg",
        label: "Brandenburg / Brandenburg",
        cityCode: "DE_BB",
      },
      {
        name: "Bremen",
        countryCode: "DE",
        latitude: "53.07929620",
        longitude: "8.80169360",
        nameNative: "Bremen",
        label: "Bremen / Bremen",
        cityCode: "DE_HB",
      },
      {
        name: "Hamburg",
        countryCode: "DE",
        latitude: "53.55108460",
        longitude: "9.99368190",
        nameNative: "Hamburg",
        label: "Hamburg / Hamburg",
        cityCode: "DE_HH",
      },
      {
        name: "Hesse",
        countryCode: "DE",
        latitude: "50.65205150",
        longitude: "9.16243760",
        nameNative: "Hessen",
        label: "Hessen / Hesse",
        cityCode: "DE_HE",
      },
      {
        name: "Lower Saxony",
        countryCode: "DE",
        latitude: "52.63670360",
        longitude: "9.84507660",
        nameNative: "Niedersachsen",
        label: "Niedersachsen / Lower Saxony",
        cityCode: "DE_NI",
      },
      {
        name: "Mecklenburg-Vorpommern",
        countryCode: "DE",
        latitude: "53.61265050",
        longitude: "12.42959530",
        nameNative: "Mecklenburg-Vorpommern",
        label: "Mecklenburg-Vorpommern / Mecklenburg-Vorpommern",
        cityCode: "DE_MV",
      },
      {
        name: "North Rhine-Westphalia",
        countryCode: "DE",
        latitude: "51.43323670",
        longitude: "7.66159380",
        nameNative: "Nordrhein-Westfalen",
        label: "Nordrhein-Westfalen / North Rhine-Westphalia",
        cityCode: "DE_NW",
      },
      {
        name: "Rhineland-Palatinate",
        countryCode: "DE",
        latitude: "50.11834600",
        longitude: "7.30895270",
        nameNative: "Rheinland-Pfalz",
        label: "Rheinland-Pfalz / Rhineland-Palatinate",
        cityCode: "DE_RP",
      },
      {
        name: "Saarland",
        countryCode: "DE",
        latitude: "49.39642340",
        longitude: "7.02296070",
        nameNative: "Saarland",
        label: "Saarland / Saarland",
        cityCode: "DE_SL",
      },
      {
        name: "Saxony",
        countryCode: "DE",
        latitude: "51.10454070",
        longitude: "13.20173840",
        nameNative: "Sachsen",
        label: "Sachsen / Saxony",
        cityCode: "DE_SN",
      },
      {
        name: "Saxony-Anhalt",
        countryCode: "DE",
        latitude: "51.95026490",
        longitude: "11.69227340",
        nameNative: "Sachsen-Anhalt",
        label: "Sachsen-Anhalt / Saxony-Anhalt",
        cityCode: "DE_ST",
      },
      {
        name: "Schleswig-Holstein",
        countryCode: "DE",
        latitude: "54.21936720",
        longitude: "9.69611670",
        nameNative: "Schleswig-Holstein",
        label: "Schleswig-Holstein / Schleswig-Holstein",
        cityCode: "DE_SH",
      },
      {
        name: "Thuringia",
        countryCode: "DE",
        latitude: "51.01098920",
        longitude: "10.84534600",
        nameNative: "Thüringen",
        label: "Thüringen / Thuringia",
        cityCode: "DE_TH",
      },
    ],
    GH: [
      {
        name: "Ahafo",
        countryCode: "GH",
        latitude: "7.58213720",
        longitude: "-2.54974630",
        nameNative: null,
        label: "Ahafo",
        cityCode: "GH_AF",
      },
      {
        name: "Ashanti",
        countryCode: "GH",
        latitude: "6.74704360",
        longitude: "-1.52086240",
        nameNative: null,
        label: "Ashanti",
        cityCode: "GH_AH",
      },
      {
        name: "Bono East",
        countryCode: "GH",
        latitude: "7.75000000",
        longitude: "-1.05000000",
        nameNative: null,
        label: "Bono East",
        cityCode: "GH_BE",
      },
      {
        name: "Bono",
        countryCode: "GH",
        latitude: "7.65000000",
        longitude: "-2.50000000",
        nameNative: null,
        label: "Bono",
        cityCode: "GH_BO",
      },
      {
        name: "Central",
        countryCode: "GH",
        latitude: "5.50000000",
        longitude: "-1.00000000",
        nameNative: null,
        label: "Central",
        cityCode: "GH_CP",
      },
      {
        name: "Eastern",
        countryCode: "GH",
        latitude: "6.50000000",
        longitude: "-0.50000000",
        nameNative: null,
        label: "Eastern",
        cityCode: "GH_EP",
      },
      {
        name: "Greater Accra",
        countryCode: "GH",
        latitude: "5.81428360",
        longitude: "0.07467670",
        nameNative: null,
        label: "Greater Accra",
        cityCode: "GH_AA",
      },
      {
        name: "North East",
        countryCode: "GH",
        latitude: "10.51666700",
        longitude: "-0.36666700",
        nameNative: null,
        label: "North East",
        cityCode: "GH_NE",
      },
      {
        name: "Northern",
        countryCode: "GH",
        latitude: "9.50000000",
        longitude: "-1.00000000",
        nameNative: null,
        label: "Northern",
        cityCode: "GH_NP",
      },
      {
        name: "Oti",
        countryCode: "GH",
        latitude: "7.90000000",
        longitude: "0.30000000",
        nameNative: null,
        label: "Oti",
        cityCode: "GH_OT",
      },
      {
        name: "Savannah",
        countryCode: "GH",
        latitude: "9.08333300",
        longitude: "-1.81666700",
        nameNative: null,
        label: "Savannah",
        cityCode: "GH_SV",
      },
      {
        name: "Upper East",
        countryCode: "GH",
        latitude: "10.70824990",
        longitude: "-0.98206680",
        nameNative: null,
        label: "Upper East",
        cityCode: "GH_UE",
      },
      {
        name: "Upper West",
        countryCode: "GH",
        latitude: "10.25297570",
        longitude: "-2.14502450",
        nameNative: null,
        label: "Upper West",
        cityCode: "GH_UW",
      },
      {
        name: "Volta",
        countryCode: "GH",
        latitude: "6.57813730",
        longitude: "0.45023680",
        nameNative: null,
        label: "Volta",
        cityCode: "GH_TV",
      },
      {
        name: "Western North",
        countryCode: "GH",
        latitude: "6.30000000",
        longitude: "-2.80000000",
        nameNative: null,
        label: "Western North",
        cityCode: "GH_WN",
      },
      {
        name: "Western",
        countryCode: "GH",
        latitude: "5.50000000",
        longitude: "-2.50000000",
        nameNative: null,
        label: "Western",
        cityCode: "GH_WP",
      },
    ],
    GR: [
      {
        name: "Achaea Regional Unit",
        countryCode: "GR",
        latitude: "38.11587290",
        longitude: "21.95224910",
        nameNative: null,
        label: "Achaea Regional Unit",
        cityCode: "GR_13",
      },
      {
        name: "Aetolia-Acarnania Regional Unit",
        countryCode: "GR",
        latitude: "38.70843860",
        longitude: "21.37989280",
        nameNative: null,
        label: "Aetolia-Acarnania Regional Unit",
        cityCode: "GR_01",
      },
      {
        name: "Arcadia Prefecture",
        countryCode: "GR",
        latitude: "37.55578250",
        longitude: "22.33377690",
        nameNative: null,
        label: "Arcadia Prefecture",
        cityCode: "GR_12",
      },
      {
        name: "Argolis Regional Unit",
        countryCode: "GR",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Argolis Regional Unit",
        cityCode: "GR_11",
      },
      {
        name: "Attica Region",
        countryCode: "GR",
        latitude: "38.04575680",
        longitude: "23.85847370",
        nameNative: null,
        label: "Attica Region",
        cityCode: "GR_I",
      },
      {
        name: "Boeotia Regional Unit",
        countryCode: "GR",
        latitude: "38.36636640",
        longitude: "23.09650640",
        nameNative: null,
        label: "Boeotia Regional Unit",
        cityCode: "GR_03",
      },
      {
        name: "Central Greece Region",
        countryCode: "GR",
        latitude: "38.60439840",
        longitude: "22.71521310",
        nameNative: null,
        label: "Central Greece Region",
        cityCode: "GR_H",
      },
      {
        name: "Central Macedonia",
        countryCode: "GR",
        latitude: "40.62117300",
        longitude: "23.19180210",
        nameNative: null,
        label: "Central Macedonia",
        cityCode: "GR_B",
      },
      {
        name: "Chania Regional Unit",
        countryCode: "GR",
        latitude: "35.51382980",
        longitude: "24.01803670",
        nameNative: null,
        label: "Chania Regional Unit",
        cityCode: "GR_94",
      },
      {
        name: "Corfu Prefecture",
        countryCode: "GR",
        latitude: "39.62498380",
        longitude: "19.92234610",
        nameNative: null,
        label: "Corfu Prefecture",
        cityCode: "GR_22",
      },
      {
        name: "Corinthia Regional Unit",
        countryCode: "GR",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Corinthia Regional Unit",
        cityCode: "GR_15",
      },
      {
        name: "Crete Region",
        countryCode: "GR",
        latitude: "35.24011700",
        longitude: "24.80926910",
        nameNative: null,
        label: "Crete Region",
        cityCode: "GR_M",
      },
      {
        name: "Drama Regional Unit",
        countryCode: "GR",
        latitude: "41.23400230",
        longitude: "24.23904980",
        nameNative: null,
        label: "Drama Regional Unit",
        cityCode: "GR_52",
      },
      {
        name: "East Attica Regional Unit",
        countryCode: "GR",
        latitude: "38.20540930",
        longitude: "23.85847370",
        nameNative: null,
        label: "East Attica Regional Unit",
        cityCode: "GR_A2",
      },
      {
        name: "East Macedonia and Thrace",
        countryCode: "GR",
        latitude: "41.12951260",
        longitude: "24.88771910",
        nameNative: null,
        label: "East Macedonia and Thrace",
        cityCode: "GR_A",
      },
      {
        name: "Epirus Region",
        countryCode: "GR",
        latitude: "39.57064130",
        longitude: "20.76428430",
        nameNative: null,
        label: "Epirus Region",
        cityCode: "GR_D",
      },
      {
        name: "Euboea",
        countryCode: "GR",
        latitude: "38.52360360",
        longitude: "23.85847370",
        nameNative: null,
        label: "Euboea",
        cityCode: "GR_04",
      },
      {
        name: "Grevena Prefecture",
        countryCode: "GR",
        latitude: "40.08376260",
        longitude: "21.42732990",
        nameNative: null,
        label: "Grevena Prefecture",
        cityCode: "GR_51",
      },
      {
        name: "Imathia Regional Unit",
        countryCode: "GR",
        latitude: "40.60600670",
        longitude: "22.14302150",
        nameNative: null,
        label: "Imathia Regional Unit",
        cityCode: "GR_53",
      },
      {
        name: "Ioannina Regional Unit",
        countryCode: "GR",
        latitude: "39.66502880",
        longitude: "20.85374660",
        nameNative: null,
        label: "Ioannina Regional Unit",
        cityCode: "GR_33",
      },
      {
        name: "Ionian Islands Region",
        countryCode: "GR",
        latitude: "37.96948980",
        longitude: "21.38023720",
        nameNative: null,
        label: "Ionian Islands Region",
        cityCode: "GR_F",
      },
      {
        name: "Karditsa Regional Unit",
        countryCode: "GR",
        latitude: "39.36402580",
        longitude: "21.92140490",
        nameNative: null,
        label: "Karditsa Regional Unit",
        cityCode: "GR_41",
      },
      {
        name: "Kastoria Regional Unit",
        countryCode: "GR",
        latitude: "40.51926910",
        longitude: "21.26871710",
        nameNative: null,
        label: "Kastoria Regional Unit",
        cityCode: "GR_56",
      },
      {
        name: "Kefalonia Prefecture",
        countryCode: "GR",
        latitude: "38.17536750",
        longitude: "20.56921790",
        nameNative: null,
        label: "Kefalonia Prefecture",
        cityCode: "GR_23",
      },
      {
        name: "Kilkis Regional Unit",
        countryCode: "GR",
        latitude: "40.99370710",
        longitude: "22.87536740",
        nameNative: null,
        label: "Kilkis Regional Unit",
        cityCode: "GR_57",
      },
      {
        name: "Kozani Prefecture",
        countryCode: "GR",
        latitude: "40.30055860",
        longitude: "21.78877370",
        nameNative: null,
        label: "Kozani Prefecture",
        cityCode: "GR_58",
      },
      {
        name: "Laconia",
        countryCode: "GR",
        latitude: "43.52785460",
        longitude: "-71.47035090",
        nameNative: null,
        label: "Laconia",
        cityCode: "GR_16",
      },
      {
        name: "Larissa Prefecture",
        countryCode: "GR",
        latitude: "39.63902240",
        longitude: "22.41912540",
        nameNative: null,
        label: "Larissa Prefecture",
        cityCode: "GR_42",
      },
      {
        name: "Lefkada Regional Unit",
        countryCode: "GR",
        latitude: "38.83336630",
        longitude: "20.70691080",
        nameNative: null,
        label: "Lefkada Regional Unit",
        cityCode: "GR_24",
      },
      {
        name: "Pella Regional Unit",
        countryCode: "GR",
        latitude: "40.91480390",
        longitude: "22.14302150",
        nameNative: null,
        label: "Pella Regional Unit",
        cityCode: "GR_59",
      },
      {
        name: "Peloponnese Region",
        countryCode: "GR",
        latitude: "37.50794720",
        longitude: "22.37349000",
        nameNative: null,
        label: "Peloponnese Region",
        cityCode: "GR_J",
      },
      {
        name: "Phthiotis Prefecture",
        countryCode: "GR",
        latitude: "38.99978500",
        longitude: "22.33377690",
        nameNative: null,
        label: "Phthiotis Prefecture",
        cityCode: "GR_06",
      },
      {
        name: "Preveza Prefecture",
        countryCode: "GR",
        latitude: "38.95926490",
        longitude: "20.75171550",
        nameNative: null,
        label: "Preveza Prefecture",
        cityCode: "GR_34",
      },
      {
        name: "Serres Prefecture",
        countryCode: "GR",
        latitude: "41.08638540",
        longitude: "23.54838190",
        nameNative: null,
        label: "Serres Prefecture",
        cityCode: "GR_62",
      },
      {
        name: "South Aegean",
        countryCode: "GR",
        latitude: "37.08553020",
        longitude: "25.14892150",
        nameNative: null,
        label: "South Aegean",
        cityCode: "GR_L",
      },
      {
        name: "Thessaloniki Regional Unit",
        countryCode: "GR",
        latitude: "40.64006290",
        longitude: "22.94441910",
        nameNative: null,
        label: "Thessaloniki Regional Unit",
        cityCode: "GR_54",
      },
      {
        name: "West Greece Region",
        countryCode: "GR",
        latitude: "38.51154960",
        longitude: "21.57067860",
        nameNative: null,
        label: "West Greece Region",
        cityCode: "GR_G",
      },
      {
        name: "West Macedonia Region",
        countryCode: "GR",
        latitude: "40.30040580",
        longitude: "21.79035590",
        nameNative: null,
        label: "West Macedonia Region",
        cityCode: "GR_C",
      },
    ],
    GD: [
      {
        name: "Carriacou and Petite Martinique",
        countryCode: "GD",
        latitude: "12.47858880",
        longitude: "-61.44938420",
        nameNative: null,
        label: "Carriacou and Petite Martinique",
        cityCode: "GD_10",
      },
      {
        name: "Saint Andrew Parish",
        countryCode: "GD",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Andrew Parish",
        cityCode: "GD_01",
      },
      {
        name: "Saint David Parish",
        countryCode: "GD",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint David Parish",
        cityCode: "GD_02",
      },
      {
        name: "Saint George Parish",
        countryCode: "GD",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint George Parish",
        cityCode: "GD_03",
      },
      {
        name: "Saint John Parish",
        countryCode: "GD",
        latitude: "30.11183310",
        longitude: "-90.48799160",
        nameNative: null,
        label: "Saint John Parish",
        cityCode: "GD_04",
      },
      {
        name: "Saint Mark Parish",
        countryCode: "GD",
        latitude: "40.58818630",
        longitude: "-73.94957010",
        nameNative: null,
        label: "Saint Mark Parish",
        cityCode: "GD_05",
      },
      {
        name: "Saint Patrick Parish",
        countryCode: "GD",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Saint Patrick Parish",
        cityCode: "GD_06",
      },
    ],
    GT: [
      {
        name: "Alta Verapaz Department",
        countryCode: "GT",
        latitude: "15.59428830",
        longitude: "-90.14949880",
        nameNative: null,
        label: "Alta Verapaz Department",
        cityCode: "GT_AV",
      },
      {
        name: "Baja Verapaz Department",
        countryCode: "GT",
        latitude: "15.12558670",
        longitude: "-90.37483540",
        nameNative: null,
        label: "Baja Verapaz Department",
        cityCode: "GT_BV",
      },
      {
        name: "Chimaltenango Department",
        countryCode: "GT",
        latitude: "14.56347870",
        longitude: "-90.98206680",
        nameNative: null,
        label: "Chimaltenango Department",
        cityCode: "GT_CM",
      },
      {
        name: "Chiquimula Department",
        countryCode: "GT",
        latitude: "14.75149990",
        longitude: "-89.47421770",
        nameNative: null,
        label: "Chiquimula Department",
        cityCode: "GT_CQ",
      },
      {
        name: "El Progreso Department",
        countryCode: "GT",
        latitude: "14.93887320",
        longitude: "-90.07467670",
        nameNative: null,
        label: "El Progreso Department",
        cityCode: "GT_PR",
      },
      {
        name: "Escuintla Department",
        countryCode: "GT",
        latitude: "14.19109120",
        longitude: "-90.98206680",
        nameNative: null,
        label: "Escuintla Department",
        cityCode: "GT_ES",
      },
      {
        name: "Guatemala Department",
        countryCode: "GT",
        latitude: "14.56494010",
        longitude: "-90.52578230",
        nameNative: null,
        label: "Guatemala Department",
        cityCode: "GT_GU",
      },
      {
        name: "Huehuetenango Department",
        countryCode: "GT",
        latitude: "15.58799140",
        longitude: "-91.67606910",
        nameNative: null,
        label: "Huehuetenango Department",
        cityCode: "GT_HU",
      },
      {
        name: "Izabal Department",
        countryCode: "GT",
        latitude: "15.49765170",
        longitude: "-88.86469800",
        nameNative: null,
        label: "Izabal Department",
        cityCode: "GT_IZ",
      },
      {
        name: "Jalapa Department",
        countryCode: "GT",
        latitude: "14.61214460",
        longitude: "-89.96267990",
        nameNative: null,
        label: "Jalapa Department",
        cityCode: "GT_JA",
      },
      {
        name: "Jutiapa Department",
        countryCode: "GT",
        latitude: "14.19308020",
        longitude: "-89.92532330",
        nameNative: null,
        label: "Jutiapa Department",
        cityCode: "GT_JU",
      },
      {
        name: "Petén Department",
        countryCode: "GT",
        latitude: "16.91203300",
        longitude: "-90.29957850",
        nameNative: null,
        label: "Petén Department",
        cityCode: "GT_PE",
      },
      {
        name: "Quetzaltenango Department",
        countryCode: "GT",
        latitude: "14.79243300",
        longitude: "-91.71495800",
        nameNative: null,
        label: "Quetzaltenango Department",
        cityCode: "GT_QZ",
      },
      {
        name: "Quiché Department",
        countryCode: "GT",
        latitude: "15.49838080",
        longitude: "-90.98206680",
        nameNative: null,
        label: "Quiché Department",
        cityCode: "GT_QC",
      },
      {
        name: "Retalhuleu Department",
        countryCode: "GT",
        latitude: "14.52454850",
        longitude: "-91.68578800",
        nameNative: null,
        label: "Retalhuleu Department",
        cityCode: "GT_RE",
      },
      {
        name: "Sacatepéquez Department",
        countryCode: "GT",
        latitude: "14.51783790",
        longitude: "-90.71527490",
        nameNative: null,
        label: "Sacatepéquez Department",
        cityCode: "GT_SA",
      },
      {
        name: "San Marcos Department",
        countryCode: "GT",
        latitude: "14.93095690",
        longitude: "-91.90992380",
        nameNative: null,
        label: "San Marcos Department",
        cityCode: "GT_SM",
      },
      {
        name: "Santa Rosa Department",
        countryCode: "GT",
        latitude: "38.44057590",
        longitude: "-122.70375430",
        nameNative: null,
        label: "Santa Rosa Department",
        cityCode: "GT_SR",
      },
      {
        name: "Sololá Department",
        countryCode: "GT",
        latitude: "14.74852300",
        longitude: "-91.28910360",
        nameNative: null,
        label: "Sololá Department",
        cityCode: "GT_SO",
      },
      {
        name: "Suchitepéquez Department",
        countryCode: "GT",
        latitude: "14.42159820",
        longitude: "-91.40482490",
        nameNative: null,
        label: "Suchitepéquez Department",
        cityCode: "GT_SU",
      },
      {
        name: "Totonicapán Department",
        countryCode: "GT",
        latitude: "14.91734020",
        longitude: "-91.36139230",
        nameNative: null,
        label: "Totonicapán Department",
        cityCode: "GT_TO",
      },
    ],
    GN: [
      {
        name: "Beyla Prefecture",
        countryCode: "GN",
        latitude: "8.91981780",
        longitude: "-8.30884410",
        nameNative: null,
        label: "Beyla Prefecture",
        cityCode: "GN_BE",
      },
      {
        name: "Boffa Prefecture",
        countryCode: "GN",
        latitude: "10.18082540",
        longitude: "-14.03916150",
        nameNative: null,
        label: "Boffa Prefecture",
        cityCode: "GN_BF",
      },
      {
        name: "Boké Prefecture",
        countryCode: "GN",
        latitude: "11.08473790",
        longitude: "-14.37919120",
        nameNative: null,
        label: "Boké Prefecture",
        cityCode: "GN_BK",
      },
      {
        name: "Boké Region",
        countryCode: "GN",
        latitude: "11.18646720",
        longitude: "-14.10013260",
        nameNative: null,
        label: "Boké Region",
        cityCode: "GN_B",
      },
      {
        name: "Conakry",
        countryCode: "GN",
        latitude: "9.64118550",
        longitude: "-13.57840120",
        nameNative: null,
        label: "Conakry",
        cityCode: "GN_C",
      },
      {
        name: "Coyah Prefecture",
        countryCode: "GN",
        latitude: "9.77155350",
        longitude: "-13.31252990",
        nameNative: null,
        label: "Coyah Prefecture",
        cityCode: "GN_CO",
      },
      {
        name: "Dabola Prefecture",
        countryCode: "GN",
        latitude: "10.72978060",
        longitude: "-11.11078540",
        nameNative: null,
        label: "Dabola Prefecture",
        cityCode: "GN_DB",
      },
      {
        name: "Dalaba Prefecture",
        countryCode: "GN",
        latitude: "10.68681760",
        longitude: "-12.24906970",
        nameNative: null,
        label: "Dalaba Prefecture",
        cityCode: "GN_DL",
      },
      {
        name: "Dinguiraye Prefecture",
        countryCode: "GN",
        latitude: "11.68442220",
        longitude: "-10.80000510",
        nameNative: null,
        label: "Dinguiraye Prefecture",
        cityCode: "GN_DI",
      },
      {
        name: "Dubréka Prefecture",
        countryCode: "GN",
        latitude: "9.79073480",
        longitude: "-13.51477350",
        nameNative: null,
        label: "Dubréka Prefecture",
        cityCode: "GN_DU",
      },
      {
        name: "Faranah Prefecture",
        countryCode: "GN",
        latitude: "9.90573990",
        longitude: "-10.80000510",
        nameNative: null,
        label: "Faranah Prefecture",
        cityCode: "GN_FA",
      },
      {
        name: "Forécariah Prefecture",
        countryCode: "GN",
        latitude: "9.38861870",
        longitude: "-13.08179030",
        nameNative: null,
        label: "Forécariah Prefecture",
        cityCode: "GN_FO",
      },
      {
        name: "Fria Prefecture",
        countryCode: "GN",
        latitude: "10.36745430",
        longitude: "-13.58418710",
        nameNative: null,
        label: "Fria Prefecture",
        cityCode: "GN_FR",
      },
      {
        name: "Gaoual Prefecture",
        countryCode: "GN",
        latitude: "11.57628040",
        longitude: "-13.35872880",
        nameNative: null,
        label: "Gaoual Prefecture",
        cityCode: "GN_GA",
      },
      {
        name: "Guéckédou Prefecture",
        countryCode: "GN",
        latitude: "8.56496880",
        longitude: "-10.13111630",
        nameNative: null,
        label: "Guéckédou Prefecture",
        cityCode: "GN_GU",
      },
      {
        name: "Kankan Prefecture",
        countryCode: "GN",
        latitude: "10.30344650",
        longitude: "-9.36730840",
        nameNative: null,
        label: "Kankan Prefecture",
        cityCode: "GN_KA",
      },
      {
        name: "Kankan Region",
        countryCode: "GN",
        latitude: "10.12092300",
        longitude: "-9.54509740",
        nameNative: null,
        label: "Kankan Region",
        cityCode: "GN_K",
      },
      {
        name: "Kindia Prefecture",
        countryCode: "GN",
        latitude: "10.10132920",
        longitude: "-12.71351210",
        nameNative: null,
        label: "Kindia Prefecture",
        cityCode: "GN_KD",
      },
      {
        name: "Kindia Region",
        countryCode: "GN",
        latitude: "10.17816940",
        longitude: "-12.98961500",
        nameNative: null,
        label: "Kindia Region",
        cityCode: "GN_D",
      },
      {
        name: "Kissidougou Prefecture",
        countryCode: "GN",
        latitude: "9.22520220",
        longitude: "-10.08072980",
        nameNative: null,
        label: "Kissidougou Prefecture",
        cityCode: "GN_KS",
      },
      {
        name: "Koubia Prefecture",
        countryCode: "GN",
        latitude: "11.58235400",
        longitude: "-11.89202370",
        nameNative: null,
        label: "Koubia Prefecture",
        cityCode: "GN_KB",
      },
      {
        name: "Koundara Prefecture",
        countryCode: "GN",
        latitude: "12.48940210",
        longitude: "-13.30675620",
        nameNative: null,
        label: "Koundara Prefecture",
        cityCode: "GN_KN",
      },
      {
        name: "Kouroussa Prefecture",
        countryCode: "GN",
        latitude: "10.64892290",
        longitude: "-9.88505860",
        nameNative: null,
        label: "Kouroussa Prefecture",
        cityCode: "GN_KO",
      },
      {
        name: "Kérouané Prefecture",
        countryCode: "GN",
        latitude: "9.25366430",
        longitude: "-9.01289260",
        nameNative: null,
        label: "Kérouané Prefecture",
        cityCode: "GN_KE",
      },
      {
        name: "Labé Prefecture",
        countryCode: "GN",
        latitude: "11.35419390",
        longitude: "-12.34638750",
        nameNative: null,
        label: "Labé Prefecture",
        cityCode: "GN_LA",
      },
      {
        name: "Labé Region",
        countryCode: "GN",
        latitude: "11.32320420",
        longitude: "-12.28913140",
        nameNative: null,
        label: "Labé Region",
        cityCode: "GN_L",
      },
      {
        name: "Lola Prefecture",
        countryCode: "GN",
        latitude: "7.96138180",
        longitude: "-8.39649380",
        nameNative: null,
        label: "Lola Prefecture",
        cityCode: "GN_LO",
      },
      {
        name: "Lélouma Prefecture",
        countryCode: "GN",
        latitude: "11.18333300",
        longitude: "-12.93333300",
        nameNative: null,
        label: "Lélouma Prefecture",
        cityCode: "GN_LE",
      },
      {
        name: "Macenta Prefecture",
        countryCode: "GN",
        latitude: "8.46157950",
        longitude: "-9.27855830",
        nameNative: null,
        label: "Macenta Prefecture",
        cityCode: "GN_MC",
      },
      {
        name: "Mali Prefecture",
        countryCode: "GN",
        latitude: "11.98370900",
        longitude: "-12.25479190",
        nameNative: null,
        label: "Mali Prefecture",
        cityCode: "GN_ML",
      },
      {
        name: "Mamou Prefecture",
        countryCode: "GN",
        latitude: "10.57360240",
        longitude: "-11.88917210",
        nameNative: null,
        label: "Mamou Prefecture",
        cityCode: "GN_MM",
      },
      {
        name: "Mamou Region",
        countryCode: "GN",
        latitude: "10.57360240",
        longitude: "-11.88917210",
        nameNative: null,
        label: "Mamou Region",
        cityCode: "GN_M",
      },
      {
        name: "Mandiana Prefecture",
        countryCode: "GN",
        latitude: "10.61728270",
        longitude: "-8.69857160",
        nameNative: null,
        label: "Mandiana Prefecture",
        cityCode: "GN_MD",
      },
      {
        name: "Nzérékoré Prefecture",
        countryCode: "GN",
        latitude: "7.74783590",
        longitude: "-8.82525020",
        nameNative: null,
        label: "Nzérékoré Prefecture",
        cityCode: "GN_NZ",
      },
      {
        name: "Nzérékoré Region",
        countryCode: "GN",
        latitude: "8.03858700",
        longitude: "-8.83627550",
        nameNative: null,
        label: "Nzérékoré Region",
        cityCode: "GN_N",
      },
      {
        name: "Pita Prefecture",
        countryCode: "GN",
        latitude: "10.80620860",
        longitude: "-12.71351210",
        nameNative: null,
        label: "Pita Prefecture",
        cityCode: "GN_PI",
      },
      {
        name: "Siguiri Prefecture",
        countryCode: "GN",
        latitude: "11.41481130",
        longitude: "-9.17883040",
        nameNative: null,
        label: "Siguiri Prefecture",
        cityCode: "GN_SI",
      },
      {
        name: "Tougué Prefecture",
        countryCode: "GN",
        latitude: "11.38415830",
        longitude: "-11.61577730",
        nameNative: null,
        label: "Tougué Prefecture",
        cityCode: "GN_TO",
      },
      {
        name: "Télimélé Prefecture",
        countryCode: "GN",
        latitude: "10.90893640",
        longitude: "-13.02993310",
        nameNative: null,
        label: "Télimélé Prefecture",
        cityCode: "GN_TE",
      },
      {
        name: "Yomou Prefecture",
        countryCode: "GN",
        latitude: "7.56962790",
        longitude: "-9.25915710",
        nameNative: null,
        label: "Yomou Prefecture",
        cityCode: "GN_YO",
      },
    ],
    GW: [
      {
        name: "Bafatá",
        countryCode: "GW",
        latitude: "12.17352430",
        longitude: "-14.65295200",
        nameNative: null,
        label: "Bafatá",
        cityCode: "GW_BA",
      },
      {
        name: "Biombo Region",
        countryCode: "GW",
        latitude: "11.85290610",
        longitude: "-15.73511710",
        nameNative: null,
        label: "Biombo Region",
        cityCode: "GW_BM",
      },
      {
        name: "Bolama Region",
        countryCode: "GW",
        latitude: "11.14805910",
        longitude: "-16.13457050",
        nameNative: null,
        label: "Bolama Region",
        cityCode: "GW_BL",
      },
      {
        name: "Cacheu Region",
        countryCode: "GW",
        latitude: "12.05514160",
        longitude: "-16.06401790",
        nameNative: null,
        label: "Cacheu Region",
        cityCode: "GW_CA",
      },
      {
        name: "Gabú Region",
        countryCode: "GW",
        latitude: "11.89624880",
        longitude: "-14.10013260",
        nameNative: null,
        label: "Gabú Region",
        cityCode: "GW_GA",
      },
      {
        name: "Leste Province",
        countryCode: "GW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Leste Province",
        cityCode: "GW_L",
      },
      {
        name: "Norte Province",
        countryCode: "GW",
        latitude: "7.87218110",
        longitude: "123.88577470",
        nameNative: null,
        label: "Norte Province",
        cityCode: "GW_N",
      },
      {
        name: "Oio Region",
        countryCode: "GW",
        latitude: "12.27607090",
        longitude: "-15.31311850",
        nameNative: null,
        label: "Oio Region",
        cityCode: "GW_OI",
      },
      {
        name: "Quinara Region",
        countryCode: "GW",
        latitude: "11.79556200",
        longitude: "-15.17268160",
        nameNative: null,
        label: "Quinara Region",
        cityCode: "GW_QU",
      },
      {
        name: "Sul Province",
        countryCode: "GW",
        latitude: "-10.28665780",
        longitude: "20.71224650",
        nameNative: null,
        label: "Sul Province",
        cityCode: "GW_S",
      },
      {
        name: "Tombali Region",
        countryCode: "GW",
        latitude: "11.36326960",
        longitude: "-14.98561760",
        nameNative: null,
        label: "Tombali Region",
        cityCode: "GW_TO",
      },
    ],
    GY: [
      {
        name: "Barima-Waini",
        countryCode: "GY",
        latitude: "7.48824190",
        longitude: "-59.65644940",
        nameNative: null,
        label: "Barima-Waini",
        cityCode: "GY_BA",
      },
      {
        name: "Cuyuni-Mazaruni",
        countryCode: "GY",
        latitude: "6.46421410",
        longitude: "-60.21107520",
        nameNative: null,
        label: "Cuyuni-Mazaruni",
        cityCode: "GY_CU",
      },
      {
        name: "Demerara-Mahaica",
        countryCode: "GY",
        latitude: "6.54642600",
        longitude: "-58.09820460",
        nameNative: null,
        label: "Demerara-Mahaica",
        cityCode: "GY_DE",
      },
      {
        name: "East Berbice-Corentyne",
        countryCode: "GY",
        latitude: "2.74779220",
        longitude: "-57.46272590",
        nameNative: null,
        label: "East Berbice-Corentyne",
        cityCode: "GY_EB",
      },
      {
        name: "Essequibo Islands-West Demerara",
        countryCode: "GY",
        latitude: "6.57201320",
        longitude: "-58.46299970",
        nameNative: null,
        label: "Essequibo Islands-West Demerara",
        cityCode: "GY_ES",
      },
      {
        name: "Mahaica-Berbice",
        countryCode: "GY",
        latitude: "6.23849600",
        longitude: "-57.91625550",
        nameNative: null,
        label: "Mahaica-Berbice",
        cityCode: "GY_MA",
      },
      {
        name: "Pomeroon-Supenaam",
        countryCode: "GY",
        latitude: "7.12941660",
        longitude: "-58.92062950",
        nameNative: null,
        label: "Pomeroon-Supenaam",
        cityCode: "GY_PM",
      },
      {
        name: "Potaro-Siparuni",
        countryCode: "GY",
        latitude: "4.78558530",
        longitude: "-59.28799770",
        nameNative: null,
        label: "Potaro-Siparuni",
        cityCode: "GY_PT",
      },
      {
        name: "Upper Demerara-Berbice",
        countryCode: "GY",
        latitude: "5.30648790",
        longitude: "-58.18929210",
        nameNative: null,
        label: "Upper Demerara-Berbice",
        cityCode: "GY_UD",
      },
      {
        name: "Upper Takutu-Upper Essequibo",
        countryCode: "GY",
        latitude: "2.92395950",
        longitude: "-58.73736340",
        nameNative: null,
        label: "Upper Takutu-Upper Essequibo",
        cityCode: "GY_UT",
      },
    ],
    HT: [
      {
        name: "Artibonite",
        countryCode: "HT",
        latitude: "19.36290200",
        longitude: "-72.42581450",
        nameNative: null,
        label: "Artibonite",
        cityCode: "HT_AR",
      },
      {
        name: "Centre",
        countryCode: "HT",
        latitude: "32.83702510",
        longitude: "-96.77738820",
        nameNative: null,
        label: "Centre",
        cityCode: "HT_CE",
      },
      {
        name: "Grand'Anse",
        countryCode: "HT",
        latitude: "12.01666670",
        longitude: "-61.76666670",
        nameNative: null,
        label: "Grand'Anse",
        cityCode: "HT_GA",
      },
      {
        name: "Nippes",
        countryCode: "HT",
        latitude: "18.39907350",
        longitude: "-73.41802110",
        nameNative: null,
        label: "Nippes",
        cityCode: "HT_NI",
      },
      {
        name: "Nord",
        countryCode: "HT",
        latitude: "43.19052600",
        longitude: "-89.43792100",
        nameNative: null,
        label: "Nord",
        cityCode: "HT_ND",
      },
      {
        name: "Nord-Est",
        countryCode: "HT",
        latitude: "19.48897230",
        longitude: "-71.85713310",
        nameNative: null,
        label: "Nord-Est",
        cityCode: "HT_NE",
      },
      {
        name: "Nord-Ouest",
        countryCode: "HT",
        latitude: "19.83740090",
        longitude: "-73.04052770",
        nameNative: null,
        label: "Nord-Ouest",
        cityCode: "HT_NO",
      },
      {
        name: "Ouest",
        countryCode: "HT",
        latitude: "45.45472490",
        longitude: "-73.65023650",
        nameNative: null,
        label: "Ouest",
        cityCode: "HT_OU",
      },
      {
        name: "Sud",
        countryCode: "HT",
        latitude: "29.92132480",
        longitude: "-90.09737720",
        nameNative: null,
        label: "Sud",
        cityCode: "HT_SD",
      },
      {
        name: "Sud-Est",
        countryCode: "HT",
        latitude: "18.27835980",
        longitude: "-72.35479150",
        nameNative: null,
        label: "Sud-Est",
        cityCode: "HT_SE",
      },
    ],
    HN: [
      {
        name: "Atlántida Department",
        countryCode: "HN",
        latitude: "15.66962830",
        longitude: "-87.14228950",
        nameNative: null,
        label: "Atlántida Department",
        cityCode: "HN_AT",
      },
      {
        name: "Bay Islands Department",
        countryCode: "HN",
        latitude: "16.48266140",
        longitude: "-85.87932520",
        nameNative: null,
        label: "Bay Islands Department",
        cityCode: "HN_IB",
      },
      {
        name: "Choluteca Department",
        countryCode: "HN",
        latitude: "13.25043250",
        longitude: "-87.14228950",
        nameNative: null,
        label: "Choluteca Department",
        cityCode: "HN_CH",
      },
      {
        name: "Colón Department",
        countryCode: "HN",
        latitude: "15.64259650",
        longitude: "-85.52002400",
        nameNative: null,
        label: "Colón Department",
        cityCode: "HN_CL",
      },
      {
        name: "Comayagua Department",
        countryCode: "HN",
        latitude: "14.55348280",
        longitude: "-87.61863790",
        nameNative: null,
        label: "Comayagua Department",
        cityCode: "HN_CM",
      },
      {
        name: "Copán Department",
        countryCode: "HN",
        latitude: "14.93608380",
        longitude: "-88.86469800",
        nameNative: null,
        label: "Copán Department",
        cityCode: "HN_CP",
      },
      {
        name: "Cortés Department",
        countryCode: "HN",
        latitude: "15.49235080",
        longitude: "-88.09007620",
        nameNative: null,
        label: "Cortés Department",
        cityCode: "HN_CR",
      },
      {
        name: "El Paraíso Department",
        countryCode: "HN",
        latitude: "13.98212940",
        longitude: "-86.49965460",
        nameNative: null,
        label: "El Paraíso Department",
        cityCode: "HN_EP",
      },
      {
        name: "Francisco Morazán Department",
        countryCode: "HN",
        latitude: "14.45411000",
        longitude: "-87.06242610",
        nameNative: null,
        label: "Francisco Morazán Department",
        cityCode: "HN_FM",
      },
      {
        name: "Gracias a Dios Department",
        countryCode: "HN",
        latitude: "15.34180600",
        longitude: "-84.60604490",
        nameNative: null,
        label: "Gracias a Dios Department",
        cityCode: "HN_GD",
      },
      {
        name: "Intibucá Department",
        countryCode: "HN",
        latitude: "14.37273400",
        longitude: "-88.24611830",
        nameNative: null,
        label: "Intibucá Department",
        cityCode: "HN_IN",
      },
      {
        name: "La Paz Department",
        countryCode: "HN",
        latitude: "-15.08924160",
        longitude: "-68.52471490",
        nameNative: null,
        label: "La Paz Department",
        cityCode: "HN_LP",
      },
      {
        name: "Lempira Department",
        countryCode: "HN",
        latitude: "14.18876980",
        longitude: "-88.55653100",
        nameNative: null,
        label: "Lempira Department",
        cityCode: "HN_LE",
      },
      {
        name: "Ocotepeque Department",
        countryCode: "HN",
        latitude: "14.51703470",
        longitude: "-89.05615320",
        nameNative: null,
        label: "Ocotepeque Department",
        cityCode: "HN_OC",
      },
      {
        name: "Olancho Department",
        countryCode: "HN",
        latitude: "14.80674060",
        longitude: "-85.76666450",
        nameNative: null,
        label: "Olancho Department",
        cityCode: "HN_OL",
      },
      {
        name: "Santa Bárbara Department",
        countryCode: "HN",
        latitude: "15.12027950",
        longitude: "-88.40160410",
        nameNative: null,
        label: "Santa Bárbara Department",
        cityCode: "HN_SB",
      },
      {
        name: "Valle Department",
        countryCode: "HN",
        latitude: "13.57829360",
        longitude: "-87.57912870",
        nameNative: null,
        label: "Valle Department",
        cityCode: "HN_VA",
      },
      {
        name: "Yoro Department",
        countryCode: "HN",
        latitude: "15.29496790",
        longitude: "-87.14228950",
        nameNative: null,
        label: "Yoro Department",
        cityCode: "HN_YO",
      },
    ],
    HK: [
      {
        name: "Central and Western District",
        countryCode: "HK",
        latitude: "22.28666000",
        longitude: "114.15497000",
        nameNative: null,
        label: "Central and Western District",
        cityCode: "HK_HCW",
      },
      {
        name: "Eastern",
        countryCode: "HK",
        latitude: "22.28411000",
        longitude: "114.22414000",
        nameNative: null,
        label: "Eastern",
        cityCode: "HK_HEA",
      },
      {
        name: "Islands District",
        countryCode: "HK",
        latitude: "22.26114000",
        longitude: "113.94608000",
        nameNative: null,
        label: "Islands District",
        cityCode: "HK_NIS",
      },
      {
        name: "Kowloon City",
        countryCode: "HK",
        latitude: "22.32820000",
        longitude: "114.19155000",
        nameNative: null,
        label: "Kowloon City",
        cityCode: "HK_KKC",
      },
      {
        name: "Kwai Tsing",
        countryCode: "HK",
        latitude: "22.35488000",
        longitude: "114.08401000",
        nameNative: null,
        label: "Kwai Tsing",
        cityCode: "HK_NKT",
      },
      {
        name: "Kwun Tong",
        countryCode: "HK",
        latitude: "22.31326000",
        longitude: "114.22581000",
        nameNative: null,
        label: "Kwun Tong",
        cityCode: "HK_KKT",
      },
      {
        name: "North",
        countryCode: "HK",
        latitude: "22.49471000",
        longitude: "114.13812000",
        nameNative: null,
        label: "North",
        cityCode: "HK_NNO",
      },
      {
        name: "Sai Kung District",
        countryCode: "HK",
        latitude: "22.38143000",
        longitude: "114.27052000",
        nameNative: null,
        label: "Sai Kung District",
        cityCode: "HK_NSK",
      },
      {
        name: "Sha Tin",
        countryCode: "HK",
        latitude: "22.38715000",
        longitude: "114.19534000",
        nameNative: null,
        label: "Sha Tin",
        cityCode: "HK_NST",
      },
      {
        name: "Sham Shui Po",
        countryCode: "HK",
        latitude: "22.33074000",
        longitude: "114.16220000",
        nameNative: null,
        label: "Sham Shui Po",
        cityCode: "HK_KSS",
      },
      {
        name: "Southern",
        countryCode: "HK",
        latitude: "22.24725000",
        longitude: "114.15884000",
        nameNative: null,
        label: "Southern",
        cityCode: "HK_HSO",
      },
      {
        name: "Tsuen Wan District",
        countryCode: "HK",
        latitude: "22.36281000",
        longitude: "114.12907000",
        nameNative: null,
        label: "Tsuen Wan District",
        cityCode: "HK_NTW",
      },
      {
        name: "Tuen Mun",
        countryCode: "HK",
        latitude: "22.39163000",
        longitude: "113.97708850",
        nameNative: null,
        label: "Tuen Mun",
        cityCode: "HK_NTM",
      },
      {
        name: "Wan Chai",
        countryCode: "HK",
        latitude: "22.27968000",
        longitude: "114.17168000",
        nameNative: null,
        label: "Wan Chai",
        cityCode: "HK_HWC",
      },
      {
        name: "Wong Tai Sin",
        countryCode: "HK",
        latitude: "22.33353000",
        longitude: "114.19686000",
        nameNative: null,
        label: "Wong Tai Sin",
        cityCode: "HK_KWT",
      },
      {
        name: "Yau Tsim Mong",
        countryCode: "HK",
        latitude: "22.32138000",
        longitude: "114.17260000",
        nameNative: null,
        label: "Yau Tsim Mong",
        cityCode: "HK_KYT",
      },
      {
        name: "Yuen Long District",
        countryCode: "HK",
        latitude: "22.44559000",
        longitude: "114.02218000",
        nameNative: null,
        label: "Yuen Long District",
        cityCode: "HK_NYL",
      },
    ],
    HU: [
      {
        name: "Baranya County",
        countryCode: "HU",
        latitude: "46.04845850",
        longitude: "18.27191730",
        nameNative: null,
        label: "Baranya County",
        cityCode: "HU_BA",
      },
      {
        name: "Borsod-Abaúj-Zemplén County",
        countryCode: "HU",
        latitude: "48.29394010",
        longitude: "20.69341120",
        nameNative: null,
        label: "Borsod-Abaúj-Zemplén County",
        cityCode: "HU_BZ",
      },
      {
        name: "Budapest",
        countryCode: "HU",
        latitude: "47.49791200",
        longitude: "19.04023500",
        nameNative: null,
        label: "Budapest",
        cityCode: "HU_BU",
      },
      {
        name: "Bács-Kiskun County",
        countryCode: "HU",
        latitude: "46.56614370",
        longitude: "19.42724640",
        nameNative: null,
        label: "Bács-Kiskun County",
        cityCode: "HU_BK",
      },
      {
        name: "Békés County",
        countryCode: "HU",
        latitude: "46.67048990",
        longitude: "21.04349960",
        nameNative: null,
        label: "Békés County",
        cityCode: "HU_BE",
      },
      {
        name: "Békéscsaba",
        countryCode: "HU",
        latitude: "46.67359390",
        longitude: "21.08773090",
        nameNative: null,
        label: "Békéscsaba",
        cityCode: "HU_BC",
      },
      {
        name: "Csongrád County",
        countryCode: "HU",
        latitude: "46.41670500",
        longitude: "20.25661610",
        nameNative: null,
        label: "Csongrád County",
        cityCode: "HU_CS",
      },
      {
        name: "Debrecen",
        countryCode: "HU",
        latitude: "47.53160490",
        longitude: "21.62731240",
        nameNative: null,
        label: "Debrecen",
        cityCode: "HU_DE",
      },
      {
        name: "Dunaújváros",
        countryCode: "HU",
        latitude: "46.96190590",
        longitude: "18.93552270",
        nameNative: null,
        label: "Dunaújváros",
        cityCode: "HU_DU",
      },
      {
        name: "Eger",
        countryCode: "HU",
        latitude: "47.90253480",
        longitude: "20.37722840",
        nameNative: null,
        label: "Eger",
        cityCode: "HU_EG",
      },
      {
        name: "Fejér County",
        countryCode: "HU",
        latitude: "47.12179320",
        longitude: "18.52948150",
        nameNative: null,
        label: "Fejér County",
        cityCode: "HU_FE",
      },
      {
        name: "Győr",
        countryCode: "HU",
        latitude: "47.68745690",
        longitude: "17.65039740",
        nameNative: null,
        label: "Győr",
        cityCode: "HU_GY",
      },
      {
        name: "Győr-Moson-Sopron County",
        countryCode: "HU",
        latitude: "47.65092850",
        longitude: "17.25058830",
        nameNative: null,
        label: "Győr-Moson-Sopron County",
        cityCode: "HU_GS",
      },
      {
        name: "Hajdú-Bihar County",
        countryCode: "HU",
        latitude: "47.46883550",
        longitude: "21.54532270",
        nameNative: null,
        label: "Hajdú-Bihar County",
        cityCode: "HU_HB",
      },
      {
        name: "Heves County",
        countryCode: "HU",
        latitude: "47.80576170",
        longitude: "20.20385590",
        nameNative: null,
        label: "Heves County",
        cityCode: "HU_HE",
      },
      {
        name: "Hódmezővásárhely",
        countryCode: "HU",
        latitude: "46.41812620",
        longitude: "20.33003150",
        nameNative: null,
        label: "Hódmezővásárhely",
        cityCode: "HU_HV",
      },
      {
        name: "Jász-Nagykun-Szolnok County",
        countryCode: "HU",
        latitude: "47.25555790",
        longitude: "20.52324560",
        nameNative: null,
        label: "Jász-Nagykun-Szolnok County",
        cityCode: "HU_JN",
      },
      {
        name: "Kaposvár",
        countryCode: "HU",
        latitude: "46.35936060",
        longitude: "17.79676390",
        nameNative: null,
        label: "Kaposvár",
        cityCode: "HU_KV",
      },
      {
        name: "Kecskemét",
        countryCode: "HU",
        latitude: "46.89637110",
        longitude: "19.68968610",
        nameNative: null,
        label: "Kecskemét",
        cityCode: "HU_KM",
      },
      {
        name: "Miskolc",
        countryCode: "HU",
        latitude: "48.10347750",
        longitude: "20.77843840",
        nameNative: null,
        label: "Miskolc",
        cityCode: "HU_MI",
      },
      {
        name: "Nagykanizsa",
        countryCode: "HU",
        latitude: "46.45902180",
        longitude: "16.98967960",
        nameNative: null,
        label: "Nagykanizsa",
        cityCode: "HU_NK",
      },
      {
        name: "Nyíregyháza",
        countryCode: "HU",
        latitude: "47.94953240",
        longitude: "21.72440530",
        nameNative: null,
        label: "Nyíregyháza",
        cityCode: "HU_NY",
      },
      {
        name: "Nógrád County",
        countryCode: "HU",
        latitude: "47.90410310",
        longitude: "19.04985040",
        nameNative: null,
        label: "Nógrád County",
        cityCode: "HU_NO",
      },
      {
        name: "Pest County",
        countryCode: "HU",
        latitude: "47.44800010",
        longitude: "19.46181280",
        nameNative: null,
        label: "Pest County",
        cityCode: "HU_PE",
      },
      {
        name: "Pécs",
        countryCode: "HU",
        latitude: "46.07273450",
        longitude: "18.23226600",
        nameNative: null,
        label: "Pécs",
        cityCode: "HU_PS",
      },
      {
        name: "Salgótarján",
        countryCode: "HU",
        latitude: "48.09352370",
        longitude: "19.79998130",
        nameNative: null,
        label: "Salgótarján",
        cityCode: "HU_ST",
      },
      {
        name: "Somogy County",
        countryCode: "HU",
        latitude: "46.55485900",
        longitude: "17.58667320",
        nameNative: null,
        label: "Somogy County",
        cityCode: "HU_SO",
      },
      {
        name: "Sopron",
        countryCode: "HU",
        latitude: "47.68166190",
        longitude: "16.58447950",
        nameNative: null,
        label: "Sopron",
        cityCode: "HU_SN",
      },
      {
        name: "Szabolcs-Szatmár-Bereg County",
        countryCode: "HU",
        latitude: "48.03949540",
        longitude: "22.00333000",
        nameNative: null,
        label: "Szabolcs-Szatmár-Bereg County",
        cityCode: "HU_SZ",
      },
      {
        name: "Szeged",
        countryCode: "HU",
        latitude: "46.25301020",
        longitude: "20.14142530",
        nameNative: null,
        label: "Szeged",
        cityCode: "HU_SD",
      },
      {
        name: "Szekszárd",
        countryCode: "HU",
        latitude: "46.34743260",
        longitude: "18.70622930",
        nameNative: null,
        label: "Szekszárd",
        cityCode: "HU_SS",
      },
      {
        name: "Szolnok",
        countryCode: "HU",
        latitude: "47.16213550",
        longitude: "20.18247120",
        nameNative: null,
        label: "Szolnok",
        cityCode: "HU_SK",
      },
      {
        name: "Szombathely",
        countryCode: "HU",
        latitude: "47.23068510",
        longitude: "16.62184410",
        nameNative: null,
        label: "Szombathely",
        cityCode: "HU_SH",
      },
      {
        name: "Székesfehérvár",
        countryCode: "HU",
        latitude: "47.18602620",
        longitude: "18.42213580",
        nameNative: null,
        label: "Székesfehérvár",
        cityCode: "HU_SF",
      },
      {
        name: "Tatabánya",
        countryCode: "HU",
        latitude: "47.56924600",
        longitude: "18.40481800",
        nameNative: null,
        label: "Tatabánya",
        cityCode: "HU_TB",
      },
      {
        name: "Tolna County",
        countryCode: "HU",
        latitude: "46.47627540",
        longitude: "18.55706270",
        nameNative: null,
        label: "Tolna County",
        cityCode: "HU_TO",
      },
      {
        name: "Vas County",
        countryCode: "HU",
        latitude: "47.09291110",
        longitude: "16.68121830",
        nameNative: null,
        label: "Vas County",
        cityCode: "HU_VA",
      },
      {
        name: "Veszprém County",
        countryCode: "HU",
        latitude: "47.09309740",
        longitude: "17.91007630",
        nameNative: null,
        label: "Veszprém County",
        cityCode: "HU_VE",
      },
      {
        name: "Veszprém",
        countryCode: "HU",
        latitude: "47.10280870",
        longitude: "17.90930190",
        nameNative: null,
        label: "Veszprém",
        cityCode: "HU_VM",
      },
      {
        name: "Zala County",
        countryCode: "HU",
        latitude: "46.73844040",
        longitude: "16.91522520",
        nameNative: null,
        label: "Zala County",
        cityCode: "HU_ZA",
      },
      {
        name: "Zalaegerszeg",
        countryCode: "HU",
        latitude: "46.84169360",
        longitude: "16.84163220",
        nameNative: null,
        label: "Zalaegerszeg",
        cityCode: "HU_ZE",
      },
      {
        name: "Érd",
        countryCode: "HU",
        latitude: "47.39197180",
        longitude: "18.90454400",
        nameNative: null,
        label: "Érd",
        cityCode: "HU_ER",
      },
    ],
    IS: [
      {
        name: "Capital Region",
        countryCode: "IS",
        latitude: "38.56569570",
        longitude: "-92.18169490",
        nameNative: null,
        label: "Capital Region",
        cityCode: "IS_1",
      },
      {
        name: "Eastern Region",
        countryCode: "IS",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Eastern Region",
        cityCode: "IS_7",
      },
      {
        name: "Northeastern Region",
        countryCode: "IS",
        latitude: "43.29942850",
        longitude: "-74.21793260",
        nameNative: null,
        label: "Northeastern Region",
        cityCode: "IS_6",
      },
      {
        name: "Northwestern Region",
        countryCode: "IS",
        latitude: "41.91339320",
        longitude: "-73.04716880",
        nameNative: null,
        label: "Northwestern Region",
        cityCode: "IS_5",
      },
      {
        name: "Southern Peninsula Region",
        countryCode: "IS",
        latitude: "63.91548030",
        longitude: "-22.36496670",
        nameNative: null,
        label: "Southern Peninsula Region",
        cityCode: "IS_2",
      },
      {
        name: "Southern Region",
        countryCode: "IS",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Southern Region",
        cityCode: "IS_8",
      },
      {
        name: "Western Region",
        countryCode: "IS",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Western Region",
        cityCode: "IS_3",
      },
      {
        name: "Westfjords",
        countryCode: "IS",
        latitude: "65.91961500",
        longitude: "-21.88117640",
        nameNative: null,
        label: "Westfjords",
        cityCode: "IS_4",
      },
    ],
    IN: [
      {
        name: "Andaman and Nicobar Islands",
        countryCode: "IN",
        latitude: "11.74008670",
        longitude: "92.65864010",
        nameNative: "अंडमान और निकोबार द्वीप समूह",
        label: "अंडमान और निकोबार द्वीप समूह / Andaman and Nicobar Islands",
        cityCode: "IN_AN",
      },
      {
        name: "Andhra Pradesh",
        countryCode: "IN",
        latitude: "15.91289980",
        longitude: "79.73998750",
        nameNative: "आंध्र प्रदेश",
        label: "आंध्र प्रदेश / Andhra Pradesh",
        cityCode: "IN_AP",
      },
      {
        name: "Arunachal Pradesh",
        countryCode: "IN",
        latitude: "28.21799940",
        longitude: "94.72775280",
        nameNative: "अरुणाचल प्रदेश",
        label: "अरुणाचल प्रदेश / Arunachal Pradesh",
        cityCode: "IN_AR",
      },
      {
        name: "Assam",
        countryCode: "IN",
        latitude: "26.20060430",
        longitude: "92.93757390",
        nameNative: "असम",
        label: "असम / Assam",
        cityCode: "IN_AS",
      },
      {
        name: "Bihar",
        countryCode: "IN",
        latitude: "25.09607420",
        longitude: "85.31311940",
        nameNative: "बिहार",
        label: "बिहार / Bihar",
        cityCode: "IN_BR",
      },
      {
        name: "Chandigarh",
        countryCode: "IN",
        latitude: "30.73331480",
        longitude: "76.77941790",
        nameNative: "चंडीगढ़",
        label: "चंडीगढ़ / Chandigarh",
        cityCode: "IN_CH",
      },
      {
        name: "Chhattisgarh",
        countryCode: "IN",
        latitude: "21.27865670",
        longitude: "81.86614420",
        nameNative: "छत्तीसगढ़",
        label: "छत्तीसगढ़ / Chhattisgarh",
        cityCode: "IN_CT",
      },
      {
        name: "Dadra and Nagar Haveli and Daman and Diu",
        countryCode: "IN",
        latitude: "20.39737360",
        longitude: "72.83279910",
        nameNative: "दादरा और नगर हवेली और दमन और दीव",
        label:
          "दादरा और नगर हवेली और दमन और दीव / Dadra and Nagar Haveli and Daman and Diu",
        cityCode: "IN_DH",
      },
      {
        name: "Delhi",
        countryCode: "IN",
        latitude: "28.70405920",
        longitude: "77.10249020",
        nameNative: "दिल्ली",
        label: "दिल्ली / Delhi",
        cityCode: "IN_DL",
      },
      {
        name: "Goa",
        countryCode: "IN",
        latitude: "15.29932650",
        longitude: "74.12399600",
        nameNative: "गोवा",
        label: "गोवा / Goa",
        cityCode: "IN_GA",
      },
      {
        name: "Gujarat",
        countryCode: "IN",
        latitude: "22.25865200",
        longitude: "71.19238050",
        nameNative: "गुजरात",
        label: "गुजरात / Gujarat",
        cityCode: "IN_GJ",
      },
      {
        name: "Haryana",
        countryCode: "IN",
        latitude: "29.05877570",
        longitude: "76.08560100",
        nameNative: "हरियाणा",
        label: "हरियाणा / Haryana",
        cityCode: "IN_HR",
      },
      {
        name: "Himachal Pradesh",
        countryCode: "IN",
        latitude: "31.10482940",
        longitude: "77.17339010",
        nameNative: "हिमाचल प्रदेश",
        label: "हिमाचल प्रदेश / Himachal Pradesh",
        cityCode: "IN_HP",
      },
      {
        name: "Jammu and Kashmir",
        countryCode: "IN",
        latitude: "33.27783900",
        longitude: "75.34121790",
        nameNative: "जम्मू और कश्मीर",
        label: "जम्मू और कश्मीर / Jammu and Kashmir",
        cityCode: "IN_JK",
      },
      {
        name: "Jharkhand",
        countryCode: "IN",
        latitude: "23.61018080",
        longitude: "85.27993540",
        nameNative: "झारखंड",
        label: "झारखंड / Jharkhand",
        cityCode: "IN_JH",
      },
      {
        name: "Karnataka",
        countryCode: "IN",
        latitude: "15.31727750",
        longitude: "75.71388840",
        nameNative: "कर्नाटक",
        label: "कर्नाटक / Karnataka",
        cityCode: "IN_KA",
      },
      {
        name: "Kerala",
        countryCode: "IN",
        latitude: "10.85051590",
        longitude: "76.27108330",
        nameNative: "केरल",
        label: "केरल / Kerala",
        cityCode: "IN_KL",
      },
      {
        name: "Ladakh",
        countryCode: "IN",
        latitude: "34.22684750",
        longitude: "77.56194190",
        nameNative: "लद्दाख",
        label: "लद्दाख / Ladakh",
        cityCode: "IN_LA",
      },
      {
        name: "Lakshadweep",
        countryCode: "IN",
        latitude: "10.32802650",
        longitude: "72.78463360",
        nameNative: "लक्षद्वीप",
        label: "लक्षद्वीप / Lakshadweep",
        cityCode: "IN_LD",
      },
      {
        name: "Madhya Pradesh",
        countryCode: "IN",
        latitude: "22.97342290",
        longitude: "78.65689420",
        nameNative: "मध्य प्रदेश",
        label: "मध्य प्रदेश / Madhya Pradesh",
        cityCode: "IN_MP",
      },
      {
        name: "Maharashtra",
        countryCode: "IN",
        latitude: "19.75147980",
        longitude: "75.71388840",
        nameNative: "महाराष्ट्र",
        label: "महाराष्ट्र / Maharashtra",
        cityCode: "IN_MH",
      },
      {
        name: "Manipur",
        countryCode: "IN",
        latitude: "24.66371730",
        longitude: "93.90626880",
        nameNative: "मणिपुर",
        label: "मणिपुर / Manipur",
        cityCode: "IN_MN",
      },
      {
        name: "Meghalaya",
        countryCode: "IN",
        latitude: "25.46703080",
        longitude: "91.36621600",
        nameNative: "मेघालय",
        label: "मेघालय / Meghalaya",
        cityCode: "IN_ML",
      },
      {
        name: "Mizoram",
        countryCode: "IN",
        latitude: "23.16454300",
        longitude: "92.93757390",
        nameNative: "मिज़ोरम",
        label: "मिज़ोरम / Mizoram",
        cityCode: "IN_MZ",
      },
      {
        name: "Nagaland",
        countryCode: "IN",
        latitude: "26.15843540",
        longitude: "94.56244260",
        nameNative: "नागालैंड",
        label: "नागालैंड / Nagaland",
        cityCode: "IN_NL",
      },
      {
        name: "Odisha",
        countryCode: "IN",
        latitude: "20.95166580",
        longitude: "85.09852360",
        nameNative: "ओडिशा",
        label: "ओडिशा / Odisha",
        cityCode: "IN_OR",
      },
      {
        name: "Puducherry",
        countryCode: "IN",
        latitude: "11.94159150",
        longitude: "79.80831330",
        nameNative: "पुडुचेरी",
        label: "पुडुचेरी / Puducherry",
        cityCode: "IN_PY",
      },
      {
        name: "Punjab",
        countryCode: "IN",
        latitude: "31.14713050",
        longitude: "75.34121790",
        nameNative: "पंजाब",
        label: "पंजाब / Punjab",
        cityCode: "IN_PB",
      },
      {
        name: "Rajasthan",
        countryCode: "IN",
        latitude: "27.02380360",
        longitude: "74.21793260",
        nameNative: "राजस्थान",
        label: "राजस्थान / Rajasthan",
        cityCode: "IN_RJ",
      },
      {
        name: "Sikkim",
        countryCode: "IN",
        latitude: "27.53297180",
        longitude: "88.51221780",
        nameNative: "सिक्किम",
        label: "सिक्किम / Sikkim",
        cityCode: "IN_SK",
      },
      {
        name: "Tamil Nadu",
        countryCode: "IN",
        latitude: "11.12712250",
        longitude: "78.65689420",
        nameNative: "तमिलनाडु",
        label: "तमिलनाडु / Tamil Nadu",
        cityCode: "IN_TN",
      },
      {
        name: "Telangana",
        countryCode: "IN",
        latitude: "18.11243720",
        longitude: "79.01929970",
        nameNative: "तेलंगाना",
        label: "तेलंगाना / Telangana",
        cityCode: "IN_TG",
      },
      {
        name: "Tripura",
        countryCode: "IN",
        latitude: "23.94084820",
        longitude: "91.98815270",
        nameNative: "त्रिपुरा",
        label: "त्रिपुरा / Tripura",
        cityCode: "IN_TR",
      },
      {
        name: "Uttar Pradesh",
        countryCode: "IN",
        latitude: "26.84670880",
        longitude: "80.94615920",
        nameNative: "उत्तर प्रदेश",
        label: "उत्तर प्रदेश / Uttar Pradesh",
        cityCode: "IN_UP",
      },
      {
        name: "Uttarakhand",
        countryCode: "IN",
        latitude: "30.06675300",
        longitude: "79.01929970",
        nameNative: "उत्तराखंड",
        label: "उत्तराखंड / Uttarakhand",
        cityCode: "IN_UT",
      },
      {
        name: "West Bengal",
        countryCode: "IN",
        latitude: "22.98675690",
        longitude: "87.85497550",
        nameNative: "पश्चिम बंगाल",
        label: "पश्चिम बंगाल / West Bengal",
        cityCode: "IN_WB",
      },
    ],
    ID: [
      {
        name: "Aceh",
        countryCode: "ID",
        latitude: "4.69513500",
        longitude: "96.74939930",
        nameNative: "Aceh",
        label: "Aceh / Aceh",
        cityCode: "ID_AC",
      },
      {
        name: "Bali",
        countryCode: "ID",
        latitude: "-8.34053890",
        longitude: "115.09195090",
        nameNative: "Bali",
        label: "Bali / Bali",
        cityCode: "ID_BA",
      },
      {
        name: "Banten",
        countryCode: "ID",
        latitude: "-6.40581720",
        longitude: "106.06401790",
        nameNative: "Banten",
        label: "Banten / Banten",
        cityCode: "ID_BT",
      },
      {
        name: "Bengkulu",
        countryCode: "ID",
        latitude: "-3.79284510",
        longitude: "102.26076410",
        nameNative: "Bengkulu",
        label: "Bengkulu / Bengkulu",
        cityCode: "ID_BE",
      },
      {
        name: "DI Yogyakarta",
        countryCode: "ID",
        latitude: "-7.87538490",
        longitude: "110.42620880",
        nameNative: "Daerah Istimewa Yogyakarta",
        label: "Daerah Istimewa Yogyakarta / DI Yogyakarta",
        cityCode: "ID_YO",
      },
      {
        name: "DKI Jakarta",
        countryCode: "ID",
        latitude: "-6.20876340",
        longitude: "106.84559900",
        nameNative: "Daerah Khusus Ibukota Jakarta",
        label: "Daerah Khusus Ibukota Jakarta / DKI Jakarta",
        cityCode: "ID_JK",
      },
      {
        name: "Gorontalo",
        countryCode: "ID",
        latitude: "0.54354420",
        longitude: "123.05676930",
        nameNative: "Gorontalo",
        label: "Gorontalo / Gorontalo",
        cityCode: "ID_GO",
      },
      {
        name: "Jambi",
        countryCode: "ID",
        latitude: "-1.61012290",
        longitude: "103.61312030",
        nameNative: "Jambi",
        label: "Jambi / Jambi",
        cityCode: "ID_JA",
      },
      {
        name: "Jawa Barat",
        countryCode: "ID",
        latitude: "-7.09091100",
        longitude: "107.66888700",
        nameNative: "Jawa Barat",
        label: "Jawa Barat / Jawa Barat",
        cityCode: "ID_JB",
      },
      {
        name: "Jawa Tengah",
        countryCode: "ID",
        latitude: "-7.15097500",
        longitude: "110.14025940",
        nameNative: "Jawa Tengah",
        label: "Jawa Tengah / Jawa Tengah",
        cityCode: "ID_JT",
      },
      {
        name: "Jawa Timur",
        countryCode: "ID",
        latitude: "-7.53606390",
        longitude: "112.23840170",
        nameNative: "Jawa Timur",
        label: "Jawa Timur / Jawa Timur",
        cityCode: "ID_JI",
      },
      {
        name: "Kalimantan Barat",
        countryCode: "ID",
        latitude: "0.96188340",
        longitude: "114.55484950",
        nameNative: "Kalimantan Barat",
        label: "Kalimantan Barat / Kalimantan Barat",
        cityCode: "ID_KA",
      },
      {
        name: "Kalimantan Selatan",
        countryCode: "ID",
        latitude: "-3.09264150",
        longitude: "115.28375850",
        nameNative: "Kalimantan Selatan",
        label: "Kalimantan Selatan / Kalimantan Selatan",
        cityCode: "ID_KS",
      },
      {
        name: "Kalimantan Tengah",
        countryCode: "ID",
        latitude: "-1.68148780",
        longitude: "113.38235450",
        nameNative: "Kalimantan Tengah",
        label: "Kalimantan Tengah / Kalimantan Tengah",
        cityCode: "ID_KT",
      },
      {
        name: "Kalimantan Timur",
        countryCode: "ID",
        latitude: "0.53865860",
        longitude: "116.41938900",
        nameNative: "Kalimantan Timur",
        label: "Kalimantan Timur / Kalimantan Timur",
        cityCode: "ID_KI",
      },
      {
        name: "Kalimantan Utara",
        countryCode: "ID",
        latitude: "3.07309290",
        longitude: "116.04138890",
        nameNative: "Kalimantan Utara",
        label: "Kalimantan Utara / Kalimantan Utara",
        cityCode: "ID_KU",
      },
      {
        name: "Kepulauan Bangka Belitung",
        countryCode: "ID",
        latitude: "-2.74105130",
        longitude: "106.44058720",
        nameNative: "Kepulauan Bangka Belitung",
        label: "Kepulauan Bangka Belitung / Kepulauan Bangka Belitung",
        cityCode: "ID_BB",
      },
      {
        name: "Kepulauan Riau",
        countryCode: "ID",
        latitude: "3.94565140",
        longitude: "108.14286690",
        nameNative: "Kepulauan Riau",
        label: "Kepulauan Riau / Kepulauan Riau",
        cityCode: "ID_KR",
      },
      {
        name: "Lampung",
        countryCode: "ID",
        latitude: "-4.55858490",
        longitude: "105.40680790",
        nameNative: "Lampung",
        label: "Lampung / Lampung",
        cityCode: "ID_LA",
      },
      {
        name: "Maluku Utara",
        countryCode: "ID",
        latitude: "1.57099930",
        longitude: "127.80876930",
        nameNative: "Maluku Utara",
        label: "Maluku Utara / Maluku Utara",
        cityCode: "ID_MU",
      },
      {
        name: "Maluku",
        countryCode: "ID",
        latitude: "-3.23846160",
        longitude: "130.14527340",
        nameNative: "Maluku",
        label: "Maluku / Maluku",
        cityCode: "ID_MA",
      },
      {
        name: "Nusa Tenggara Barat",
        countryCode: "ID",
        latitude: "-8.65293340",
        longitude: "117.36164760",
        nameNative: "Nusa Tenggara Barat",
        label: "Nusa Tenggara Barat / Nusa Tenggara Barat",
        cityCode: "ID_NB",
      },
      {
        name: "Nusa Tenggara Timur",
        countryCode: "ID",
        latitude: "-8.65738190",
        longitude: "121.07937050",
        nameNative: "Nusa Tenggara Timur",
        label: "Nusa Tenggara Timur / Nusa Tenggara Timur",
        cityCode: "ID_NT",
      },
      {
        name: "Papua Barat",
        countryCode: "ID",
        latitude: "-1.33611540",
        longitude: "133.17471620",
        nameNative: "Papua Barat",
        label: "Papua Barat / Papua Barat",
        cityCode: "ID_PB",
      },
      {
        name: "Papua",
        countryCode: "ID",
        latitude: "-5.01222020",
        longitude: "141.34701590",
        nameNative: "Papua",
        label: "Papua / Papua",
        cityCode: "ID_PA",
      },
      {
        name: "Riau",
        countryCode: "ID",
        latitude: "0.29334690",
        longitude: "101.70682940",
        nameNative: "Riau",
        label: "Riau / Riau",
        cityCode: "ID_RI",
      },
      {
        name: "Sulawesi Barat",
        countryCode: "ID",
        latitude: "-2.84413710",
        longitude: "119.23207840",
        nameNative: "Sulawesi Barat",
        label: "Sulawesi Barat / Sulawesi Barat",
        cityCode: "ID_SR",
      },
      {
        name: "Sulawesi Selatan",
        countryCode: "ID",
        latitude: "-3.66879940",
        longitude: "119.97405340",
        nameNative: "Sulawesi Selatan",
        label: "Sulawesi Selatan / Sulawesi Selatan",
        cityCode: "ID_SN",
      },
      {
        name: "Sulawesi Tengah",
        countryCode: "ID",
        latitude: "-1.43002540",
        longitude: "121.44561790",
        nameNative: "Sulawesi Tengah",
        label: "Sulawesi Tengah / Sulawesi Tengah",
        cityCode: "ID_ST",
      },
      {
        name: "Sulawesi Tenggara",
        countryCode: "ID",
        latitude: "-4.14491000",
        longitude: "122.17460500",
        nameNative: "Sulawesi Tenggara",
        label: "Sulawesi Tenggara / Sulawesi Tenggara",
        cityCode: "ID_SG",
      },
      {
        name: "Sulawesi Utara",
        countryCode: "ID",
        latitude: "0.62469320",
        longitude: "123.97500180",
        nameNative: "Sulawesi Utara",
        label: "Sulawesi Utara / Sulawesi Utara",
        cityCode: "ID_SA",
      },
      {
        name: "Sumatera Barat",
        countryCode: "ID",
        latitude: "-0.73993970",
        longitude: "100.80000510",
        nameNative: "Sumatera Barat",
        label: "Sumatera Barat / Sumatera Barat",
        cityCode: "ID_SB",
      },
      {
        name: "Sumatera Selatan",
        countryCode: "ID",
        latitude: "-3.31943740",
        longitude: "103.91439900",
        nameNative: "Sumatera Selatan",
        label: "Sumatera Selatan / Sumatera Selatan",
        cityCode: "ID_SS",
      },
      {
        name: "Sumatera Utara",
        countryCode: "ID",
        latitude: "2.11535470",
        longitude: "99.54509740",
        nameNative: "Sumatera Utara",
        label: "Sumatera Utara / Sumatera Utara",
        cityCode: "ID_SU",
      },
    ],
    IR: [
      {
        name: "Alborz",
        countryCode: "IR",
        latitude: "35.99604670",
        longitude: "50.92892460",
        nameNative: "البرز ",
        label: "البرز  / Alborz",
        cityCode: "IR_30",
      },
      {
        name: "Ardabil",
        countryCode: "IR",
        latitude: "38.48532760",
        longitude: "47.89112090",
        nameNative: "اردبیل ",
        label: "اردبیل  / Ardabil",
        cityCode: "IR_24",
      },
      {
        name: "Bushehr",
        countryCode: "IR",
        latitude: "28.76207390",
        longitude: "51.51500770",
        nameNative: "بوشهر ",
        label: "بوشهر  / Bushehr",
        cityCode: "IR_18",
      },
      {
        name: "Chaharmahal and Bakhtiari",
        countryCode: "IR",
        latitude: "31.99704190",
        longitude: "50.66138490",
        nameNative: "چهارمحال و بختیاری ",
        label: "چهارمحال و بختیاری  / Chaharmahal and Bakhtiari",
        cityCode: "IR_14",
      },
      {
        name: "East Azerbaijan",
        countryCode: "IR",
        latitude: "37.90357330",
        longitude: "46.26821090",
        nameNative: "آذربایجان شرقی ",
        label: "آذربایجان شرقی  / East Azerbaijan",
        cityCode: "IR_03",
      },
      {
        name: "Fars",
        countryCode: "IR",
        latitude: "29.10438130",
        longitude: "53.04589300",
        nameNative: "فارس ",
        label: "فارس  / Fars",
        cityCode: "IR_07",
      },
      {
        name: "Gilan",
        countryCode: "IR",
        latitude: "37.28094550",
        longitude: "49.59241340",
        nameNative: "گیلان ",
        label: "گیلان  / Gilan",
        cityCode: "IR_01",
      },
      {
        name: "Golestan",
        countryCode: "IR",
        latitude: "37.28981230",
        longitude: "55.13758340",
        nameNative: "گلستان ",
        label: "گلستان  / Golestan",
        cityCode: "IR_27",
      },
      {
        name: "Hamadan",
        countryCode: "IR",
        latitude: "34.91936070",
        longitude: "47.48329250",
        nameNative: "همدان ",
        label: "همدان  / Hamadan",
        cityCode: "IR_13",
      },
      {
        name: "Hormozgan",
        countryCode: "IR",
        latitude: "27.13872300",
        longitude: "55.13758340",
        nameNative: "هرمزگان ",
        label: "هرمزگان  / Hormozgan",
        cityCode: "IR_22",
      },
      {
        name: "Ilam",
        countryCode: "IR",
        latitude: "33.29576180",
        longitude: "46.67053400",
        nameNative: "ایلام ",
        label: "ایلام  / Ilam",
        cityCode: "IR_16",
      },
      {
        name: "Isfahan",
        countryCode: "IR",
        latitude: "33.27710730",
        longitude: "52.36133780",
        nameNative: "اصفهان ",
        label: "اصفهان  / Isfahan",
        cityCode: "IR_10",
      },
      {
        name: "Kerman",
        countryCode: "IR",
        latitude: "29.48500890",
        longitude: "57.64390480",
        nameNative: "کرمان ",
        label: "کرمان  / Kerman",
        cityCode: "IR_08",
      },
      {
        name: "Kermanshah",
        countryCode: "IR",
        latitude: "34.45762330",
        longitude: "46.67053400",
        nameNative: "کرمانشاه ",
        label: "کرمانشاه  / Kermanshah",
        cityCode: "IR_05",
      },
      {
        name: "Khuzestan",
        countryCode: "IR",
        latitude: "31.43601490",
        longitude: "49.04131200",
        nameNative: "خوزستان ",
        label: "خوزستان  / Khuzestan",
        cityCode: "IR_06",
      },
      {
        name: "Kohgiluyeh and Boyer-Ahmad",
        countryCode: "IR",
        latitude: "30.72458600",
        longitude: "50.84563230",
        nameNative: "کهگیلویه و بویراحمد ",
        label: "کهگیلویه و بویراحمد  / Kohgiluyeh and Boyer-Ahmad",
        cityCode: "IR_17",
      },
      {
        name: "Kurdistan",
        countryCode: "IR",
        latitude: "35.95535790",
        longitude: "47.13621250",
        nameNative: "کردستان ",
        label: "کردستان  / Kurdistan",
        cityCode: "IR_12",
      },
      {
        name: "Lorestan",
        countryCode: "IR",
        latitude: "33.58183940",
        longitude: "48.39881860",
        nameNative: "لرستان ",
        label: "لرستان  / Lorestan",
        cityCode: "IR_15",
      },
      {
        name: "Markazi",
        countryCode: "IR",
        latitude: "34.61230500",
        longitude: "49.85472660",
        nameNative: "مرکزی ",
        label: "مرکزی  / Markazi",
        cityCode: "IR_00",
      },
      {
        name: "Mazandaran",
        countryCode: "IR",
        latitude: "36.22623930",
        longitude: "52.53186040",
        nameNative: "مازندران ",
        label: "مازندران  / Mazandaran",
        cityCode: "IR_02",
      },
      {
        name: "North Khorasan",
        countryCode: "IR",
        latitude: "37.47103530",
        longitude: "57.10131880",
        nameNative: "خراسان شمالی ",
        label: "خراسان شمالی  / North Khorasan",
        cityCode: "IR_28",
      },
      {
        name: "Qazvin",
        countryCode: "IR",
        latitude: "36.08813170",
        longitude: "49.85472660",
        nameNative: "قزوین ",
        label: "قزوین  / Qazvin",
        cityCode: "IR_26",
      },
      {
        name: "Qom",
        countryCode: "IR",
        latitude: "34.64157640",
        longitude: "50.87460350",
        nameNative: "قم ",
        label: "قم  / Qom",
        cityCode: "IR_25",
      },
      {
        name: "Razavi Khorasan",
        countryCode: "IR",
        latitude: "35.10202530",
        longitude: "59.10417580",
        nameNative: "خراسان رضوی ",
        label: "خراسان رضوی  / Razavi Khorasan",
        cityCode: "IR_09",
      },
      {
        name: "Semnan",
        countryCode: "IR",
        latitude: "35.22555850",
        longitude: "54.43421380",
        nameNative: "سمنان ",
        label: "سمنان  / Semnan",
        cityCode: "IR_20",
      },
      {
        name: "Sistan and Baluchestan",
        countryCode: "IR",
        latitude: "27.52999060",
        longitude: "60.58206760",
        nameNative: "سیستان و بلوچستان ",
        label: "سیستان و بلوچستان  / Sistan and Baluchestan",
        cityCode: "IR_11",
      },
      {
        name: "South Khorasan",
        countryCode: "IR",
        latitude: "32.51756430",
        longitude: "59.10417580",
        nameNative: "خراسان جنوبی ",
        label: "خراسان جنوبی  / South Khorasan",
        cityCode: "IR_29",
      },
      {
        name: "Tehran",
        countryCode: "IR",
        latitude: "35.72484160",
        longitude: "51.38165300",
        nameNative: "تهران ",
        label: "تهران  / Tehran",
        cityCode: "IR_23",
      },
      {
        name: "West Azarbaijan",
        countryCode: "IR",
        latitude: "37.45500620",
        longitude: "45.00000000",
        nameNative: "آذربایجان غربی ",
        label: "آذربایجان غربی  / West Azarbaijan",
        cityCode: "IR_04",
      },
      {
        name: "Yazd",
        countryCode: "IR",
        latitude: "32.10063870",
        longitude: "54.43421380",
        nameNative: "یزد ",
        label: "یزد  / Yazd",
        cityCode: "IR_21",
      },
      {
        name: "Zanjan",
        countryCode: "IR",
        latitude: "36.50181850",
        longitude: "48.39881860",
        nameNative: "زنجان ",
        label: "زنجان  / Zanjan",
        cityCode: "IR_19",
      },
    ],
    IQ: [
      {
        name: "Al Anbar Governorate",
        countryCode: "IQ",
        latitude: "32.55976140",
        longitude: "41.91964710",
        nameNative: null,
        label: "Al Anbar Governorate",
        cityCode: "IQ_AN",
      },
      {
        name: "Al Muthanna Governorate",
        countryCode: "IQ",
        latitude: "29.91331710",
        longitude: "45.29938620",
        nameNative: null,
        label: "Al Muthanna Governorate",
        cityCode: "IQ_MU",
      },
      {
        name: "Al-Qādisiyyah Governorate",
        countryCode: "IQ",
        latitude: "32.04369100",
        longitude: "45.14945050",
        nameNative: null,
        label: "Al-Qādisiyyah Governorate",
        cityCode: "IQ_QA",
      },
      {
        name: "Babylon Governorate",
        countryCode: "IQ",
        latitude: "32.46819100",
        longitude: "44.55019350",
        nameNative: null,
        label: "Babylon Governorate",
        cityCode: "IQ_BB",
      },
      {
        name: "Baghdad Governorate",
        countryCode: "IQ",
        latitude: "33.31526180",
        longitude: "44.36606530",
        nameNative: null,
        label: "Baghdad Governorate",
        cityCode: "IQ_BG",
      },
      {
        name: "Basra Governorate",
        countryCode: "IQ",
        latitude: "30.51142520",
        longitude: "47.82962530",
        nameNative: null,
        label: "Basra Governorate",
        cityCode: "IQ_BA",
      },
      {
        name: "Dhi Qar Governorate",
        countryCode: "IQ",
        latitude: "31.10422920",
        longitude: "46.36246860",
        nameNative: null,
        label: "Dhi Qar Governorate",
        cityCode: "IQ_DQ",
      },
      {
        name: "Diyala Governorate",
        countryCode: "IQ",
        latitude: "33.77334870",
        longitude: "45.14945050",
        nameNative: null,
        label: "Diyala Governorate",
        cityCode: "IQ_DI",
      },
      {
        name: "Dohuk Governorate",
        countryCode: "IQ",
        latitude: "36.90772520",
        longitude: "43.06316890",
        nameNative: null,
        label: "Dohuk Governorate",
        cityCode: "IQ_DA",
      },
      {
        name: "Erbil Governorate",
        countryCode: "IQ",
        latitude: "36.55706280",
        longitude: "44.38512630",
        nameNative: null,
        label: "Erbil Governorate",
        cityCode: "IQ_AR",
      },
      {
        name: "Karbala Governorate",
        countryCode: "IQ",
        latitude: "32.40454930",
        longitude: "43.86732220",
        nameNative: null,
        label: "Karbala Governorate",
        cityCode: "IQ_KA",
      },
      {
        name: "Kirkuk Governorate",
        countryCode: "IQ",
        latitude: "35.32920140",
        longitude: "43.94367880",
        nameNative: null,
        label: "Kirkuk Governorate",
        cityCode: "IQ_KI",
      },
      {
        name: "Maysan Governorate",
        countryCode: "IQ",
        latitude: "31.87340020",
        longitude: "47.13621250",
        nameNative: null,
        label: "Maysan Governorate",
        cityCode: "IQ_MA",
      },
      {
        name: "Najaf Governorate",
        countryCode: "IQ",
        latitude: "31.35174860",
        longitude: "44.09603110",
        nameNative: null,
        label: "Najaf Governorate",
        cityCode: "IQ_NA",
      },
      {
        name: "Nineveh Governorate",
        countryCode: "IQ",
        latitude: "36.22957400",
        longitude: "42.23624350",
        nameNative: null,
        label: "Nineveh Governorate",
        cityCode: "IQ_NI",
      },
      {
        name: "Saladin Governorate",
        countryCode: "IQ",
        latitude: "34.53375270",
        longitude: "43.48373800",
        nameNative: null,
        label: "Saladin Governorate",
        cityCode: "IQ_SD",
      },
      {
        name: "Sulaymaniyah Governorate",
        countryCode: "IQ",
        latitude: "35.54663480",
        longitude: "45.30036830",
        nameNative: null,
        label: "Sulaymaniyah Governorate",
        cityCode: "IQ_SU",
      },
      {
        name: "Wasit Governorate",
        countryCode: "IQ",
        latitude: "32.60240940",
        longitude: "45.75209850",
        nameNative: null,
        label: "Wasit Governorate",
        cityCode: "IQ_WA",
      },
    ],
    IE: [
      {
        name: "Carlow",
        countryCode: "IE",
        latitude: "52.72322170",
        longitude: "-6.81082950",
        nameNative: null,
        label: "Carlow",
        cityCode: "IE_CW",
      },
      {
        name: "Cavan",
        countryCode: "IE",
        latitude: "53.97654240",
        longitude: "-7.29966230",
        nameNative: null,
        label: "Cavan",
        cityCode: "IE_CN",
      },
      {
        name: "Clare",
        countryCode: "IE",
        latitude: "43.04664000",
        longitude: "-87.89958100",
        nameNative: null,
        label: "Clare",
        cityCode: "IE_CE",
      },
      {
        name: "Connacht",
        countryCode: "IE",
        latitude: "53.83762430",
        longitude: "-8.95844810",
        nameNative: null,
        label: "Connacht",
        cityCode: "IE_C",
      },
      {
        name: "Cork",
        countryCode: "IE",
        latitude: "51.89851430",
        longitude: "-8.47560350",
        nameNative: null,
        label: "Cork",
        cityCode: "IE_CO",
      },
      {
        name: "Donegal",
        countryCode: "IE",
        latitude: "54.65489930",
        longitude: "-8.10409670",
        nameNative: null,
        label: "Donegal",
        cityCode: "IE_DL",
      },
      {
        name: "Dublin",
        countryCode: "IE",
        latitude: "53.34980530",
        longitude: "-6.26030970",
        nameNative: null,
        label: "Dublin",
        cityCode: "IE_D",
      },
      {
        name: "Galway",
        countryCode: "IE",
        latitude: "53.35645090",
        longitude: "-8.85341130",
        nameNative: null,
        label: "Galway",
        cityCode: "IE_G",
      },
      {
        name: "Kerry",
        countryCode: "IE",
        latitude: "52.15446070",
        longitude: "-9.56686330",
        nameNative: null,
        label: "Kerry",
        cityCode: "IE_KY",
      },
      {
        name: "Kildare",
        countryCode: "IE",
        latitude: "53.21204340",
        longitude: "-6.81947080",
        nameNative: null,
        label: "Kildare",
        cityCode: "IE_KE",
      },
      {
        name: "Kilkenny",
        countryCode: "IE",
        latitude: "52.57769570",
        longitude: "-7.21800200",
        nameNative: null,
        label: "Kilkenny",
        cityCode: "IE_KK",
      },
      {
        name: "Laois",
        countryCode: "IE",
        latitude: "52.99429500",
        longitude: "-7.33230070",
        nameNative: null,
        label: "Laois",
        cityCode: "IE_LS",
      },
      {
        name: "Leinster",
        countryCode: "IE",
        latitude: "53.32715380",
        longitude: "-7.51408410",
        nameNative: null,
        label: "Leinster",
        cityCode: "IE_L",
      },
      {
        name: "Limerick",
        countryCode: "IE",
        latitude: "52.50905170",
        longitude: "-8.74749550",
        nameNative: null,
        label: "Limerick",
        cityCode: "IE_LK",
      },
      {
        name: "Longford",
        countryCode: "IE",
        latitude: "53.72749820",
        longitude: "-7.79315270",
        nameNative: null,
        label: "Longford",
        cityCode: "IE_LD",
      },
      {
        name: "Louth",
        countryCode: "IE",
        latitude: "53.92523240",
        longitude: "-6.48894230",
        nameNative: null,
        label: "Louth",
        cityCode: "IE_LH",
      },
      {
        name: "Mayo",
        countryCode: "IE",
        latitude: "54.01526040",
        longitude: "-9.42893690",
        nameNative: null,
        label: "Mayo",
        cityCode: "IE_MO",
      },
      {
        name: "Meath",
        countryCode: "IE",
        latitude: "53.60554800",
        longitude: "-6.65641690",
        nameNative: null,
        label: "Meath",
        cityCode: "IE_MH",
      },
      {
        name: "Monaghan",
        countryCode: "IE",
        latitude: "54.24920460",
        longitude: "-6.96831320",
        nameNative: null,
        label: "Monaghan",
        cityCode: "IE_MN",
      },
      {
        name: "Munster",
        countryCode: "IE",
        latitude: "51.94711970",
        longitude: "7.58453200",
        nameNative: null,
        label: "Munster",
        cityCode: "IE_M",
      },
      {
        name: "Offaly",
        countryCode: "IE",
        latitude: "53.23568710",
        longitude: "-7.71222290",
        nameNative: null,
        label: "Offaly",
        cityCode: "IE_OY",
      },
      {
        name: "Roscommon",
        countryCode: "IE",
        latitude: "53.75926040",
        longitude: "-8.26816210",
        nameNative: null,
        label: "Roscommon",
        cityCode: "IE_RN",
      },
      {
        name: "Sligo",
        countryCode: "IE",
        latitude: "54.15532770",
        longitude: "-8.60645320",
        nameNative: null,
        label: "Sligo",
        cityCode: "IE_SO",
      },
      {
        name: "Tipperary",
        countryCode: "IE",
        latitude: "52.47378940",
        longitude: "-8.16185140",
        nameNative: null,
        label: "Tipperary",
        cityCode: "IE_TA",
      },
      {
        name: "Ulster",
        countryCode: "IE",
        latitude: "54.76165550",
        longitude: "-6.96122730",
        nameNative: null,
        label: "Ulster",
        cityCode: "IE_U",
      },
      {
        name: "Waterford",
        countryCode: "IE",
        latitude: "52.19435490",
        longitude: "-7.62275120",
        nameNative: null,
        label: "Waterford",
        cityCode: "IE_WD",
      },
      {
        name: "Westmeath",
        countryCode: "IE",
        latitude: "53.53453080",
        longitude: "-7.46532170",
        nameNative: null,
        label: "Westmeath",
        cityCode: "IE_WH",
      },
      {
        name: "Wexford",
        countryCode: "IE",
        latitude: "52.47936030",
        longitude: "-6.58399130",
        nameNative: null,
        label: "Wexford",
        cityCode: "IE_WX",
      },
      {
        name: "Wicklow",
        countryCode: "IE",
        latitude: "52.98623130",
        longitude: "-6.36725430",
        nameNative: null,
        label: "Wicklow",
        cityCode: "IE_WW",
      },
    ],
    IL: [
      {
        name: "Central District",
        countryCode: "IL",
        latitude: "47.60875830",
        longitude: "-122.29642350",
        nameNative: null,
        label: "Central District",
        cityCode: "IL_M",
      },
      {
        name: "Haifa District",
        countryCode: "IL",
        latitude: "32.48141110",
        longitude: "34.99475100",
        nameNative: null,
        label: "Haifa District",
        cityCode: "IL_HA",
      },
      {
        name: "Jerusalem District",
        countryCode: "IL",
        latitude: "31.76482430",
        longitude: "34.99475100",
        nameNative: null,
        label: "Jerusalem District",
        cityCode: "IL_JM",
      },
      {
        name: "Northern District",
        countryCode: "IL",
        latitude: "36.15118640",
        longitude: "-95.99517630",
        nameNative: null,
        label: "Northern District",
        cityCode: "IL_Z",
      },
      {
        name: "Southern District",
        countryCode: "IL",
        latitude: "40.71375860",
        longitude: "-74.00090590",
        nameNative: null,
        label: "Southern District",
        cityCode: "IL_D",
      },
      {
        name: "Tel Aviv District",
        countryCode: "IL",
        latitude: "32.09290750",
        longitude: "34.80721650",
        nameNative: null,
        label: "Tel Aviv District",
        cityCode: "IL_TA",
      },
    ],
    IT: [
      {
        name: "Abruzzo",
        countryCode: "IT",
        latitude: "42.19201190",
        longitude: "13.72891670",
        nameNative: "Abruzzo",
        label: "Abruzzo / Abruzzo",
        cityCode: "IT_65",
      },
      {
        name: "Aosta Valley",
        countryCode: "IT",
        latitude: "45.73888780",
        longitude: "7.42618660",
        nameNative: "Valle d'Aosta",
        label: "Valle d'Aosta / Aosta Valley",
        cityCode: "IT_23",
      },
      {
        name: "Apulia",
        countryCode: "IT",
        latitude: "40.79283930",
        longitude: "17.10119310",
        nameNative: "Puglia",
        label: "Puglia / Apulia",
        cityCode: "IT_75",
      },
      {
        name: "Basilicata",
        countryCode: "IT",
        latitude: "40.64307660",
        longitude: "15.96998780",
        nameNative: "Basilicata",
        label: "Basilicata / Basilicata",
        cityCode: "IT_77",
      },
      {
        name: "Benevento Province",
        countryCode: "IT",
        latitude: "41.20350930",
        longitude: "14.75209390",
        nameNative: "Provincia di Benevento",
        label: "Provincia di Benevento / Benevento Province",
        cityCode: "IT_BN",
      },
      {
        name: "Calabria",
        countryCode: "IT",
        latitude: "39.30877140",
        longitude: "16.34637910",
        nameNative: "Calabria",
        label: "Calabria / Calabria",
        cityCode: "IT_78",
      },
      {
        name: "Campania",
        countryCode: "IT",
        latitude: "40.66708870",
        longitude: "15.10681130",
        nameNative: "Campania",
        label: "Campania / Campania",
        cityCode: "IT_72",
      },
      {
        name: "Emilia-Romagna",
        countryCode: "IT",
        latitude: "44.59676070",
        longitude: "11.21863960",
        nameNative: "Emilia-Romagna",
        label: "Emilia-Romagna / Emilia-Romagna",
        cityCode: "IT_45",
      },
      {
        name: "Friuli–Venezia Giulia",
        countryCode: "IT",
        latitude: "46.22591770",
        longitude: "13.10336460",
        nameNative: "Friuli-Venezia Giulia",
        label: "Friuli-Venezia Giulia / Friuli–Venezia Giulia",
        cityCode: "IT_36",
      },
      {
        name: "Lazio",
        countryCode: "IT",
        latitude: "45.69916670",
        longitude: "-73.65583330",
        nameNative: "Lazio",
        label: "Lazio / Lazio",
        cityCode: "IT_62",
      },
      {
        name: "Libero consorzio comunale di Agrigento",
        countryCode: "IT",
        latitude: "37.31052020",
        longitude: "13.58579780",
        nameNative: "Libero consorzio comunale di Agrigento",
        label:
          "Libero consorzio comunale di Agrigento / Libero consorzio comunale di Agrigento",
        cityCode: "IT_AG",
      },
      {
        name: "Libero consorzio comunale di Caltanissetta",
        countryCode: "IT",
        latitude: "37.48601300",
        longitude: "14.06149820",
        nameNative: "Libero consorzio comunale di Caltanissetta",
        label:
          "Libero consorzio comunale di Caltanissetta / Libero consorzio comunale di Caltanissetta",
        cityCode: "IT_CL",
      },
      {
        name: "Libero consorzio comunale di Enna",
        countryCode: "IT",
        latitude: "37.56762160",
        longitude: "14.27953490",
        nameNative: "Libero consorzio comunale di Enna",
        label:
          "Libero consorzio comunale di Enna / Libero consorzio comunale di Enna",
        cityCode: "IT_EN",
      },
      {
        name: "Libero consorzio comunale di Ragusa",
        countryCode: "IT",
        latitude: "36.92692730",
        longitude: "14.72551290",
        nameNative: "Libero consorzio comunale di Ragusa",
        label:
          "Libero consorzio comunale di Ragusa / Libero consorzio comunale di Ragusa",
        cityCode: "IT_RG",
      },
      {
        name: "Libero consorzio comunale di Siracusa",
        countryCode: "IT",
        latitude: "37.06569240",
        longitude: "15.28571090",
        nameNative: "Libero consorzio comunale di Siracusa",
        label:
          "Libero consorzio comunale di Siracusa / Libero consorzio comunale di Siracusa",
        cityCode: "IT_SR",
      },
      {
        name: "Libero consorzio comunale di Trapani",
        countryCode: "IT",
        latitude: "38.01831160",
        longitude: "12.51482650",
        nameNative: "Libero consorzio comunale di Trapani",
        label:
          "Libero consorzio comunale di Trapani / Libero consorzio comunale di Trapani",
        cityCode: "IT_TP",
      },
      {
        name: "Liguria",
        countryCode: "IT",
        latitude: "44.31679170",
        longitude: "8.39649380",
        nameNative: "Liguria",
        label: "Liguria / Liguria",
        cityCode: "IT_42",
      },
      {
        name: "Lombardy",
        countryCode: "IT",
        latitude: "45.47906710",
        longitude: "9.84524330",
        nameNative: "Lombardia",
        label: "Lombardia / Lombardy",
        cityCode: "IT_25",
      },
      {
        name: "Marche",
        countryCode: "IT",
        latitude: "30.55670700",
        longitude: "-81.44930300",
        nameNative: "Marche",
        label: "Marche / Marche",
        cityCode: "IT_57",
      },
      {
        name: "Metropolitan City of Bari",
        countryCode: "IT",
        latitude: "41.11714320",
        longitude: "16.87187150",
        nameNative: "Città Metropolitana di Bari",
        label: "Città Metropolitana di Bari / Metropolitan City of Bari",
        cityCode: "IT_BA",
      },
      {
        name: "Metropolitan City of Bologna",
        countryCode: "IT",
        latitude: "44.49488700",
        longitude: "11.34261620",
        nameNative: "Città Metropolitana di Bologna",
        label: "Città Metropolitana di Bologna / Metropolitan City of Bologna",
        cityCode: "IT_BO",
      },
      {
        name: "Metropolitan City of Cagliari",
        countryCode: "IT",
        latitude: "39.22384110",
        longitude: "9.12166130",
        nameNative: "Città Metropolitana di Cagliari",
        label:
          "Città Metropolitana di Cagliari / Metropolitan City of Cagliari",
        cityCode: "IT_CA",
      },
      {
        name: "Metropolitan City of Catania",
        countryCode: "IT",
        latitude: "37.45154380",
        longitude: "15.05574150",
        nameNative: "Città Metropolitana di Catania",
        label: "Città Metropolitana di Catania / Metropolitan City of Catania",
        cityCode: "IT_CT",
      },
      {
        name: "Metropolitan City of Florence",
        countryCode: "IT",
        latitude: "43.76791780",
        longitude: "11.25237920",
        nameNative: "Città Metropolitana di Firenze",
        label: "Città Metropolitana di Firenze / Metropolitan City of Florence",
        cityCode: "IT_FI",
      },
      {
        name: "Metropolitan City of Genoa",
        countryCode: "IT",
        latitude: "44.40564930",
        longitude: "8.94625640",
        nameNative: "Città Metropolitana di Genova",
        label: "Città Metropolitana di Genova / Metropolitan City of Genoa",
        cityCode: "IT_GE",
      },
      {
        name: "Metropolitan City of Messina",
        countryCode: "IT",
        latitude: "38.19373350",
        longitude: "15.55420570",
        nameNative: "Città Metropolitana di Messina",
        label: "Città Metropolitana di Messina / Metropolitan City of Messina",
        cityCode: "IT_ME",
      },
      {
        name: "Metropolitan City of Milan",
        countryCode: "IT",
        latitude: "45.45862600",
        longitude: "9.18187300",
        nameNative: "Città Metropolitana di Milano",
        label: "Città Metropolitana di Milano / Metropolitan City of Milan",
        cityCode: "IT_MI",
      },
      {
        name: "Metropolitan City of Naples",
        countryCode: "IT",
        latitude: "40.90197500",
        longitude: "14.33264400",
        nameNative: "Città Metropolitana di Napoli",
        label: "Città Metropolitana di Napoli / Metropolitan City of Naples",
        cityCode: "IT_NA",
      },
      {
        name: "Metropolitan City of Palermo",
        countryCode: "IT",
        latitude: "38.11569000",
        longitude: "13.36148680",
        nameNative: "Città Metropolitana di Palermo",
        label: "Città Metropolitana di Palermo / Metropolitan City of Palermo",
        cityCode: "IT_PA",
      },
      {
        name: "Metropolitan City of Reggio Calabria",
        countryCode: "IT",
        latitude: "38.10843960",
        longitude: "15.64370480",
        nameNative: "Città Metropolitana di Reggio Calabria",
        label:
          "Città Metropolitana di Reggio Calabria / Metropolitan City of Reggio Calabria",
        cityCode: "IT_RC",
      },
      {
        name: "Metropolitan City of Rome",
        countryCode: "IT",
        latitude: "41.90270080",
        longitude: "12.49623520",
        nameNative: "Città Metropolitana di Roma",
        label: "Città Metropolitana di Roma / Metropolitan City of Rome",
        cityCode: "IT_RM",
      },
      {
        name: "Metropolitan City of Turin",
        countryCode: "IT",
        latitude: "45.06329900",
        longitude: "7.66928900",
        nameNative: "Città Metropolitana di Torino",
        label: "Città Metropolitana di Torino / Metropolitan City of Turin",
        cityCode: "IT_TO",
      },
      {
        name: "Metropolitan City of Venice",
        countryCode: "IT",
        latitude: "45.44146850",
        longitude: "12.31526720",
        nameNative: "Città Metropolitana di Venezia",
        label: "Città Metropolitana di Venezia / Metropolitan City of Venice",
        cityCode: "IT_VE",
      },
      {
        name: "Molise",
        countryCode: "IT",
        latitude: "41.67388650",
        longitude: "14.75209390",
        nameNative: "Molise",
        label: "Molise / Molise",
        cityCode: "IT_67",
      },
      {
        name: "Pesaro and Urbino Province",
        countryCode: "IT",
        latitude: "43.61301180",
        longitude: "12.71351210",
        nameNative: "Provincia di Pesaro e Urbino",
        label: "Provincia di Pesaro e Urbino / Pesaro and Urbino Province",
        cityCode: "IT_PU",
      },
      {
        name: "Piedmont",
        countryCode: "IT",
        latitude: "45.05223660",
        longitude: "7.51538850",
        nameNative: "Piemonte",
        label: "Piemonte / Piedmont",
        cityCode: "IT_21",
      },
      {
        name: "Province of Alessandria",
        countryCode: "IT",
        latitude: "44.81755870",
        longitude: "8.70466270",
        nameNative: "Provincia di Alessandria",
        label: "Provincia di Alessandria / Province of Alessandria",
        cityCode: "IT_AL",
      },
      {
        name: "Province of Ancona",
        countryCode: "IT",
        latitude: "43.54932450",
        longitude: "13.26634790",
        nameNative: "Provincia di Ancona",
        label: "Provincia di Ancona / Province of Ancona",
        cityCode: "IT_AN",
      },
      {
        name: "Province of Ascoli Piceno",
        countryCode: "IT",
        latitude: "42.86389330",
        longitude: "13.58997330",
        nameNative: "Provincia di Ascoli Piceno",
        label: "Provincia di Ascoli Piceno / Province of Ascoli Piceno",
        cityCode: "IT_AP",
      },
      {
        name: "Province of Asti",
        countryCode: "IT",
        latitude: "44.90076520",
        longitude: "8.20643150",
        nameNative: "Provincia di Asti",
        label: "Provincia di Asti / Province of Asti",
        cityCode: "IT_AT",
      },
      {
        name: "Province of Avellino",
        countryCode: "IT",
        latitude: "40.99645100",
        longitude: "15.12589550",
        nameNative: "Provincia di Avellino",
        label: "Provincia di Avellino / Province of Avellino",
        cityCode: "IT_AV",
      },
      {
        name: "Province of Barletta-Andria-Trani",
        countryCode: "IT",
        latitude: "41.20045430",
        longitude: "16.20514840",
        nameNative: "Provincia di Barletta-Andria-Trani",
        label:
          "Provincia di Barletta-Andria-Trani / Province of Barletta-Andria-Trani",
        cityCode: "IT_BT",
      },
      {
        name: "Province of Belluno",
        countryCode: "IT",
        latitude: "46.24976590",
        longitude: "12.19695650",
        nameNative: "Provincia di Belluno",
        label: "Provincia di Belluno / Province of Belluno",
        cityCode: "IT_BL",
      },
      {
        name: "Province of Bergamo",
        countryCode: "IT",
        latitude: "45.69826420",
        longitude: "9.67726980",
        nameNative: "Provincia di Bergamo",
        label: "Provincia di Bergamo / Province of Bergamo",
        cityCode: "IT_BG",
      },
      {
        name: "Province of Biella",
        countryCode: "IT",
        latitude: "45.56281760",
        longitude: "8.05827170",
        nameNative: "Provincia di Biella",
        label: "Provincia di Biella / Province of Biella",
        cityCode: "IT_BI",
      },
      {
        name: "Province of Brescia",
        countryCode: "IT",
        latitude: "45.54155260",
        longitude: "10.21180190",
        nameNative: "Provincia di Brescia",
        label: "Provincia di Brescia / Province of Brescia",
        cityCode: "IT_BS",
      },
      {
        name: "Province of Brindisi",
        countryCode: "IT",
        latitude: "40.61126630",
        longitude: "17.76362100",
        nameNative: "Provincia di Brindisi",
        label: "Provincia di Brindisi / Province of Brindisi",
        cityCode: "IT_BR",
      },
      {
        name: "Province of Campobasso",
        countryCode: "IT",
        latitude: "41.67388650",
        longitude: "14.75209390",
        nameNative: "Provincia di Campobasso",
        label: "Provincia di Campobasso / Province of Campobasso",
        cityCode: "IT_CB",
      },
      {
        name: "Province of Carbonia-Iglesias",
        countryCode: "IT",
        latitude: "39.25346590",
        longitude: "8.57210160",
        nameNative: "Provincia di Carbonia-Iglesias",
        label: "Provincia di Carbonia-Iglesias / Province of Carbonia-Iglesias",
        cityCode: "IT_CI",
      },
      {
        name: "Province of Caserta",
        countryCode: "IT",
        latitude: "41.20783540",
        longitude: "14.10013260",
        nameNative: "Provincia di Caserta",
        label: "Provincia di Caserta / Province of Caserta",
        cityCode: "IT_CE",
      },
      {
        name: "Province of Catanzaro",
        countryCode: "IT",
        latitude: "38.88963480",
        longitude: "16.44058720",
        nameNative: "Provincia di Catanzaro",
        label: "Provincia di Catanzaro / Province of Catanzaro",
        cityCode: "IT_CZ",
      },
      {
        name: "Province of Chieti",
        countryCode: "IT",
        latitude: "42.03344280",
        longitude: "14.37919120",
        nameNative: "Provincia di Chieti",
        label: "Provincia di Chieti / Province of Chieti",
        cityCode: "IT_CH",
      },
      {
        name: "Province of Como",
        countryCode: "IT",
        latitude: "45.80804160",
        longitude: "9.08517930",
        nameNative: "Provincia di Como",
        label: "Provincia di Como / Province of Como",
        cityCode: "IT_CO",
      },
      {
        name: "Province of Cosenza",
        countryCode: "IT",
        latitude: "39.56441050",
        longitude: "16.25221430",
        nameNative: "Provincia di Cosenza",
        label: "Provincia di Cosenza / Province of Cosenza",
        cityCode: "IT_CS",
      },
      {
        name: "Province of Cremona",
        countryCode: "IT",
        latitude: "45.20143750",
        longitude: "9.98365820",
        nameNative: null,
        label: "Province of Cremona",
        cityCode: "IT_CR",
      },
      {
        name: "Province of Crotone",
        countryCode: "IT",
        latitude: "39.13098560",
        longitude: "17.00670310",
        nameNative: "Provincia di Crotone",
        label: "Provincia di Crotone / Province of Crotone",
        cityCode: "IT_KR",
      },
      {
        name: "Province of Cuneo",
        countryCode: "IT",
        latitude: "44.59703140",
        longitude: "7.61142170",
        nameNative: "Provincia di Cuneo",
        label: "Provincia di Cuneo / Province of Cuneo",
        cityCode: "IT_CN",
      },
      {
        name: "Province of Fermo",
        countryCode: "IT",
        latitude: "43.09313670",
        longitude: "13.58997330",
        nameNative: "Provincia di Fermo",
        label: "Provincia di Fermo / Province of Fermo",
        cityCode: "IT_FM",
      },
      {
        name: "Province of Ferrara",
        countryCode: "IT",
        latitude: "44.76636800",
        longitude: "11.76440680",
        nameNative: "Provincia di Ferrara",
        label: "Provincia di Ferrara / Province of Ferrara",
        cityCode: "IT_FE",
      },
      {
        name: "Province of Foggia",
        countryCode: "IT",
        latitude: "41.63844800",
        longitude: "15.59433880",
        nameNative: "Provincia di Foggia",
        label: "Provincia di Foggia / Province of Foggia",
        cityCode: "IT_FG",
      },
      {
        name: "Province of Forlì-Cesena",
        countryCode: "IT",
        latitude: "43.99476810",
        longitude: "11.98046130",
        nameNative: "Provincia di Forlì-Cesena",
        label: "Provincia di Forlì-Cesena / Province of Forlì-Cesena",
        cityCode: "IT_FC",
      },
      {
        name: "Province of Frosinone",
        countryCode: "IT",
        latitude: "41.65765280",
        longitude: "13.63627150",
        nameNative: "Provincia di Frosinone",
        label: "Provincia di Frosinone / Province of Frosinone",
        cityCode: "IT_FR",
      },
      {
        name: "Province of Gorizia",
        countryCode: "IT",
        latitude: "45.90538990",
        longitude: "13.51637250",
        nameNative: "Provincia di Gorizia",
        label: "Provincia di Gorizia / Province of Gorizia",
        cityCode: "IT_GO",
      },
      {
        name: "Province of Grosseto",
        countryCode: "IT",
        latitude: "42.85180070",
        longitude: "11.25237920",
        nameNative: "Provincia di Grosseto",
        label: "Provincia di Grosseto / Province of Grosseto",
        cityCode: "IT_GR",
      },
      {
        name: "Province of Imperia",
        countryCode: "IT",
        latitude: "43.94186600",
        longitude: "7.82863680",
        nameNative: "Provincia di Imperia",
        label: "Provincia di Imperia / Province of Imperia",
        cityCode: "IT_IM",
      },
      {
        name: "Province of Isernia",
        countryCode: "IT",
        latitude: "41.58915550",
        longitude: "14.19309180",
        nameNative: "Provincia di Isernia",
        label: "Provincia di Isernia / Province of Isernia",
        cityCode: "IT_IS",
      },
      {
        name: "Province of L'Aquila",
        countryCode: "IT",
        latitude: "42.12563170",
        longitude: "13.63627150",
        nameNative: "Provincia dell'Aquila",
        label: "Provincia dell'Aquila / Province of L'Aquila",
        cityCode: "IT_AQ",
      },
      {
        name: "Province of La Spezia",
        countryCode: "IT",
        latitude: "44.24479130",
        longitude: "9.76786870",
        nameNative: "Provincia della Spezia",
        label: "Provincia della Spezia / Province of La Spezia",
        cityCode: "IT_SP",
      },
      {
        name: "Province of Latina",
        countryCode: "IT",
        latitude: "41.40874760",
        longitude: "13.08179030",
        nameNative: "Provincia di Latina",
        label: "Provincia di Latina / Province of Latina",
        cityCode: "IT_LT",
      },
      {
        name: "Province of Lecce",
        countryCode: "IT",
        latitude: "40.23473930",
        longitude: "18.14286690",
        nameNative: "Provincia di Lecce",
        label: "Provincia di Lecce / Province of Lecce",
        cityCode: "IT_LE",
      },
      {
        name: "Province of Lecco",
        countryCode: "IT",
        latitude: "45.93829410",
        longitude: "9.38572900",
        nameNative: "Provincia di Lecco",
        label: "Provincia di Lecco / Province of Lecco",
        cityCode: "IT_LC",
      },
      {
        name: "Province of Livorno",
        countryCode: "IT",
        latitude: "43.02398480",
        longitude: "10.66471010",
        nameNative: "Provincia di Livorno",
        label: "Provincia di Livorno / Province of Livorno",
        cityCode: "IT_LI",
      },
      {
        name: "Province of Lodi",
        countryCode: "IT",
        latitude: "45.24050360",
        longitude: "9.52925120",
        nameNative: "Provincia di Lodi",
        label: "Provincia di Lodi / Province of Lodi",
        cityCode: "IT_LO",
      },
      {
        name: "Province of Lucca",
        countryCode: "IT",
        latitude: "43.83767360",
        longitude: "10.49505300",
        nameNative: "Provincia di Lucca",
        label: "Provincia di Lucca / Province of Lucca",
        cityCode: "IT_LU",
      },
      {
        name: "Province of Macerata",
        countryCode: "IT",
        latitude: "43.24593220",
        longitude: "13.26634790",
        nameNative: "Provincia di Macerata",
        label: "Provincia di Macerata / Province of Macerata",
        cityCode: "IT_MC",
      },
      {
        name: "Province of Mantua",
        countryCode: "IT",
        latitude: "45.16677280",
        longitude: "10.77536130",
        nameNative: "Provincia di Mantova",
        label: "Provincia di Mantova / Province of Mantua",
        cityCode: "IT_MN",
      },
      {
        name: "Province of Massa and Carrara",
        countryCode: "IT",
        latitude: "44.22139980",
        longitude: "10.03596610",
        nameNative: "Provincia di Massa-Carrara",
        label: "Provincia di Massa-Carrara / Province of Massa and Carrara",
        cityCode: "IT_MS",
      },
      {
        name: "Province of Matera",
        countryCode: "IT",
        latitude: "40.66634960",
        longitude: "16.60436360",
        nameNative: "Provincia di Matera",
        label: "Provincia di Matera / Province of Matera",
        cityCode: "IT_MT",
      },
      {
        name: "Province of Medio Campidano",
        countryCode: "IT",
        latitude: "39.53173890",
        longitude: "8.70407500",
        nameNative: "Provincia del Medio Campidano",
        label: "Provincia del Medio Campidano / Province of Medio Campidano",
        cityCode: "IT_VS",
      },
      {
        name: "Province of Modena",
        countryCode: "IT",
        latitude: "44.55137990",
        longitude: "10.91804800",
        nameNative: "Provincia di Modena",
        label: "Provincia di Modena / Province of Modena",
        cityCode: "IT_MO",
      },
      {
        name: "Province of Monza and Brianza",
        countryCode: "IT",
        latitude: "45.62359900",
        longitude: "9.25880150",
        nameNative: "Provincia di Monza e della Brianza",
        label:
          "Provincia di Monza e della Brianza / Province of Monza and Brianza",
        cityCode: "IT_MB",
      },
      {
        name: "Province of Novara",
        countryCode: "IT",
        latitude: "45.54851330",
        longitude: "8.51507930",
        nameNative: "Provincia di Novara",
        label: "Provincia di Novara / Province of Novara",
        cityCode: "IT_NO",
      },
      {
        name: "Province of Nuoro",
        countryCode: "IT",
        latitude: "40.32869040",
        longitude: "9.45615500",
        nameNative: "Provincia di Nuoro",
        label: "Provincia di Nuoro / Province of Nuoro",
        cityCode: "IT_NU",
      },
      {
        name: "Province of Ogliastra",
        countryCode: "IT",
        latitude: "39.84105360",
        longitude: "9.45615500",
        nameNative: "Provincia dell'Ogliastra",
        label: "Provincia dell'Ogliastra / Province of Ogliastra",
        cityCode: "IT_OG",
      },
      {
        name: "Province of Olbia-Tempio",
        countryCode: "IT",
        latitude: "40.82683830",
        longitude: "9.27855830",
        nameNative: "Provincia di Olbia-Tempio",
        label: "Provincia di Olbia-Tempio / Province of Olbia-Tempio",
        cityCode: "IT_OT",
      },
      {
        name: "Province of Oristano",
        countryCode: "IT",
        latitude: "40.05990680",
        longitude: "8.74811670",
        nameNative: "Provincia di Oristano",
        label: "Provincia di Oristano / Province of Oristano",
        cityCode: "IT_OR",
      },
      {
        name: "Province of Padua",
        countryCode: "IT",
        latitude: "45.36618640",
        longitude: "11.82091390",
        nameNative: "Provincia di Padova",
        label: "Provincia di Padova / Province of Padua",
        cityCode: "IT_PD",
      },
      {
        name: "Province of Parma",
        countryCode: "IT",
        latitude: "44.80153220",
        longitude: "10.32793540",
        nameNative: "Provincia di Parma",
        label: "Provincia di Parma / Province of Parma",
        cityCode: "IT_PR",
      },
      {
        name: "Province of Pavia",
        countryCode: "IT",
        latitude: "45.32181660",
        longitude: "8.84662360",
        nameNative: "Provincia di Pavia",
        label: "Provincia di Pavia / Province of Pavia",
        cityCode: "IT_PV",
      },
      {
        name: "Province of Perugia",
        countryCode: "IT",
        latitude: "42.93800400",
        longitude: "12.62162110",
        nameNative: "Provincia di Perugia",
        label: "Provincia di Perugia / Province of Perugia",
        cityCode: "IT_PG",
      },
      {
        name: "Province of Pescara",
        countryCode: "IT",
        latitude: "42.35706550",
        longitude: "13.96080910",
        nameNative: "Provincia di Pescara",
        label: "Provincia di Pescara / Province of Pescara",
        cityCode: "IT_PE",
      },
      {
        name: "Province of Piacenza",
        countryCode: "IT",
        latitude: "44.82631120",
        longitude: "9.52914470",
        nameNative: "Provincia di Piacenza",
        label: "Provincia di Piacenza / Province of Piacenza",
        cityCode: "IT_PC",
      },
      {
        name: "Province of Pisa",
        countryCode: "IT",
        latitude: "43.72283150",
        longitude: "10.40171940",
        nameNative: "Provincia di Pisa",
        label: "Provincia di Pisa / Province of Pisa",
        cityCode: "IT_PI",
      },
      {
        name: "Province of Pistoia",
        countryCode: "IT",
        latitude: "43.95437330",
        longitude: "10.89030990",
        nameNative: "Provincia di Pistoia",
        label: "Provincia di Pistoia / Province of Pistoia",
        cityCode: "IT_PT",
      },
      {
        name: "Province of Pordenone",
        countryCode: "IT",
        latitude: "46.03788620",
        longitude: "12.71083500",
        nameNative: "Provincia di Pordenone",
        label: "Provincia di Pordenone / Province of Pordenone",
        cityCode: "IT_PN",
      },
      {
        name: "Province of Potenza",
        countryCode: "IT",
        latitude: "40.41821940",
        longitude: "15.87600400",
        nameNative: "Provincia di Potenza",
        label: "Provincia di Potenza / Province of Potenza",
        cityCode: "IT_PZ",
      },
      {
        name: "Province of Prato",
        countryCode: "IT",
        latitude: "44.04539000",
        longitude: "11.11644520",
        nameNative: "Provincia di Prato",
        label: "Provincia di Prato / Province of Prato",
        cityCode: "IT_PO",
      },
      {
        name: "Province of Ravenna",
        countryCode: "IT",
        latitude: "44.41844430",
        longitude: "12.20359980",
        nameNative: "Provincia di Ravenna",
        label: "Provincia di Ravenna / Province of Ravenna",
        cityCode: "IT_RA",
      },
      {
        name: "Province of Reggio Emilia",
        countryCode: "IT",
        latitude: "44.58565800",
        longitude: "10.55647360",
        nameNative: "Provincia di Reggio Emilia",
        label: "Provincia di Reggio Emilia / Province of Reggio Emilia",
        cityCode: "IT_RE",
      },
      {
        name: "Province of Rieti",
        countryCode: "IT",
        latitude: "42.36744050",
        longitude: "12.89750980",
        nameNative: "Provincia di Rieti",
        label: "Provincia di Rieti / Province of Rieti",
        cityCode: "IT_RI",
      },
      {
        name: "Province of Rimini",
        countryCode: "IT",
        latitude: "44.06782880",
        longitude: "12.56951580",
        nameNative: "Provincia di Rimini",
        label: "Provincia di Rimini / Province of Rimini",
        cityCode: "IT_RN",
      },
      {
        name: "Province of Rovigo",
        countryCode: "IT",
        latitude: "45.02418180",
        longitude: "11.82381620",
        nameNative: "Provincia di Rovigo",
        label: "Provincia di Rovigo / Province of Rovigo",
        cityCode: "IT_RO",
      },
      {
        name: "Province of Salerno",
        countryCode: "IT",
        latitude: "40.42878320",
        longitude: "15.21948080",
        nameNative: "Provincia di Salerno",
        label: "Provincia di Salerno / Province of Salerno",
        cityCode: "IT_SA",
      },
      {
        name: "Province of Sassari",
        countryCode: "IT",
        latitude: "40.79679070",
        longitude: "8.57504070",
        nameNative: "Provincia di Sassari",
        label: "Provincia di Sassari / Province of Sassari",
        cityCode: "IT_SS",
      },
      {
        name: "Province of Savona",
        countryCode: "IT",
        latitude: "44.28879950",
        longitude: "8.26505800",
        nameNative: "Provincia di Savona",
        label: "Provincia di Savona / Province of Savona",
        cityCode: "IT_SV",
      },
      {
        name: "Province of Siena",
        countryCode: "IT",
        latitude: "43.29377320",
        longitude: "11.43391480",
        nameNative: "Provincia di Siena",
        label: "Provincia di Siena / Province of Siena",
        cityCode: "IT_SI",
      },
      {
        name: "Province of Sondrio",
        countryCode: "IT",
        latitude: "46.17276360",
        longitude: "9.79949170",
        nameNative: "Provincia di Sondrio",
        label: "Provincia di Sondrio / Province of Sondrio",
        cityCode: "IT_SO",
      },
      {
        name: "Province of Taranto",
        countryCode: "IT",
        latitude: "40.57409010",
        longitude: "17.24299760",
        nameNative: "Provincia di Taranto",
        label: "Provincia di Taranto / Province of Taranto",
        cityCode: "IT_TA",
      },
      {
        name: "Province of Teramo",
        countryCode: "IT",
        latitude: "42.58956080",
        longitude: "13.63627150",
        nameNative: "Provincia di Teramo",
        label: "Provincia di Teramo / Province of Teramo",
        cityCode: "IT_TE",
      },
      {
        name: "Province of Terni",
        countryCode: "IT",
        latitude: "42.56345340",
        longitude: "12.52980280",
        nameNative: "Provincia di Terni",
        label: "Provincia di Terni / Province of Terni",
        cityCode: "IT_TR",
      },
      {
        name: "Province of Treviso",
        countryCode: "IT",
        latitude: "45.66685170",
        longitude: "12.24306170",
        nameNative: "Provincia di Treviso",
        label: "Provincia di Treviso / Province of Treviso",
        cityCode: "IT_TV",
      },
      {
        name: "Province of Trieste",
        countryCode: "IT",
        latitude: "45.68948230",
        longitude: "13.78330720",
        nameNative: "Provincia di Trieste",
        label: "Provincia di Trieste / Province of Trieste",
        cityCode: "IT_TS",
      },
      {
        name: "Province of Udine",
        countryCode: "IT",
        latitude: "46.14079720",
        longitude: "13.16628960",
        nameNative: "Provincia di Udine",
        label: "Provincia di Udine / Province of Udine",
        cityCode: "IT_UD",
      },
      {
        name: "Province of Varese",
        countryCode: "IT",
        latitude: "45.79902600",
        longitude: "8.73009450",
        nameNative: "Provincia di Varese",
        label: "Provincia di Varese / Province of Varese",
        cityCode: "IT_VA",
      },
      {
        name: "Province of Verbano-Cusio-Ossola",
        countryCode: "IT",
        latitude: "46.13996880",
        longitude: "8.27246490",
        nameNative: "Provincia del Verbano Cusio Ossola",
        label:
          "Provincia del Verbano Cusio Ossola / Province of Verbano-Cusio-Ossola",
        cityCode: "IT_VB",
      },
      {
        name: "Province of Vercelli",
        countryCode: "IT",
        latitude: "45.32022040",
        longitude: "8.41850800",
        nameNative: "Provincia di Vercelli",
        label: "Provincia di Vercelli / Province of Vercelli",
        cityCode: "IT_VC",
      },
      {
        name: "Province of Verona",
        countryCode: "IT",
        latitude: "45.44184980",
        longitude: "11.07353160",
        nameNative: "Provincia di Verona",
        label: "Provincia di Verona / Province of Verona",
        cityCode: "IT_VR",
      },
      {
        name: "Province of Vibo Valentia",
        countryCode: "IT",
        latitude: "38.63785650",
        longitude: "16.20514840",
        nameNative: "Provincia di Vibo Valentia",
        label: "Provincia di Vibo Valentia / Province of Vibo Valentia",
        cityCode: "IT_VV",
      },
      {
        name: "Province of Vicenza",
        countryCode: "IT",
        latitude: "45.69839950",
        longitude: "11.56614650",
        nameNative: "Provincia di Vicenza",
        label: "Provincia di Vicenza / Province of Vicenza",
        cityCode: "IT_VI",
      },
      {
        name: "Province of Viterbo",
        countryCode: "IT",
        latitude: "42.40024200",
        longitude: "11.88917210",
        nameNative: "Provincia di Viterbo",
        label: "Provincia di Viterbo / Province of Viterbo",
        cityCode: "IT_VT",
      },
      {
        name: "Sardinia",
        countryCode: "IT",
        latitude: "40.12087520",
        longitude: "9.01289260",
        nameNative: "Sardegna",
        label: "Sardegna / Sardinia",
        cityCode: "IT_88",
      },
      {
        name: "Sicily",
        countryCode: "IT",
        latitude: "37.59999380",
        longitude: "14.01535570",
        nameNative: "Sicilia",
        label: "Sicilia / Sicily",
        cityCode: "IT_82",
      },
      {
        name: "South Tyrol",
        countryCode: "IT",
        latitude: "46.49494500",
        longitude: "11.34026570",
        nameNative: "Alto Adige",
        label: "Alto Adige / South Tyrol",
        cityCode: "IT_BZ",
      },
      {
        name: "Trentino",
        countryCode: "IT",
        latitude: "46.05120040",
        longitude: "11.11753920",
        nameNative: "Trentino",
        label: "Trentino / Trentino",
        cityCode: "IT_TN",
      },
      {
        name: "Trentino-South Tyrol",
        countryCode: "IT",
        latitude: "46.43366620",
        longitude: "11.16932960",
        nameNative: "Trentino-Alto Adige",
        label: "Trentino-Alto Adige / Trentino-South Tyrol",
        cityCode: "IT_32",
      },
      {
        name: "Tuscany",
        countryCode: "IT",
        latitude: "43.77105130",
        longitude: "11.24862080",
        nameNative: "Toscana",
        label: "Toscana / Tuscany",
        cityCode: "IT_52",
      },
      {
        name: "Umbria",
        countryCode: "IT",
        latitude: "42.93800400",
        longitude: "12.62162110",
        nameNative: "Umbria",
        label: "Umbria / Umbria",
        cityCode: "IT_55",
      },
      {
        name: "Veneto",
        countryCode: "IT",
        latitude: "45.44146620",
        longitude: "12.31525950",
        nameNative: "Veneto",
        label: "Veneto / Veneto",
        cityCode: "IT_34",
      },
    ],
    JM: [
      {
        name: "Clarendon Parish",
        countryCode: "JM",
        latitude: "17.95571830",
        longitude: "-77.24051530",
        nameNative: null,
        label: "Clarendon Parish",
        cityCode: "JM_13",
      },
      {
        name: "Hanover Parish",
        countryCode: "JM",
        latitude: "18.40977070",
        longitude: "-78.13363800",
        nameNative: null,
        label: "Hanover Parish",
        cityCode: "JM_09",
      },
      {
        name: "Kingston Parish",
        countryCode: "JM",
        latitude: "17.96832710",
        longitude: "-76.78270200",
        nameNative: null,
        label: "Kingston Parish",
        cityCode: "JM_01",
      },
      {
        name: "Manchester Parish",
        countryCode: "JM",
        latitude: "18.06696540",
        longitude: "-77.51607880",
        nameNative: null,
        label: "Manchester Parish",
        cityCode: "JM_12",
      },
      {
        name: "Portland Parish",
        countryCode: "JM",
        latitude: "18.08442740",
        longitude: "-76.41002670",
        nameNative: null,
        label: "Portland Parish",
        cityCode: "JM_04",
      },
      {
        name: "Saint Andrew",
        countryCode: "JM",
        latitude: "37.22451030",
        longitude: "-95.70211890",
        nameNative: null,
        label: "Saint Andrew",
        cityCode: "JM_02",
      },
      {
        name: "Saint Ann Parish",
        countryCode: "JM",
        latitude: "37.28714520",
        longitude: "-77.41035330",
        nameNative: null,
        label: "Saint Ann Parish",
        cityCode: "JM_06",
      },
      {
        name: "Saint Catherine Parish",
        countryCode: "JM",
        latitude: "18.03641340",
        longitude: "-77.05644640",
        nameNative: null,
        label: "Saint Catherine Parish",
        cityCode: "JM_14",
      },
      {
        name: "Saint Elizabeth Parish",
        countryCode: "JM",
        latitude: "38.99253080",
        longitude: "-94.58992000",
        nameNative: null,
        label: "Saint Elizabeth Parish",
        cityCode: "JM_11",
      },
      {
        name: "Saint James Parish",
        countryCode: "JM",
        latitude: "30.01792920",
        longitude: "-90.79132270",
        nameNative: null,
        label: "Saint James Parish",
        cityCode: "JM_08",
      },
      {
        name: "Saint Mary Parish",
        countryCode: "JM",
        latitude: "36.09252200",
        longitude: "-95.97384400",
        nameNative: null,
        label: "Saint Mary Parish",
        cityCode: "JM_05",
      },
      {
        name: "Saint Thomas Parish",
        countryCode: "JM",
        latitude: "41.44253890",
        longitude: "-81.74022180",
        nameNative: null,
        label: "Saint Thomas Parish",
        cityCode: "JM_03",
      },
      {
        name: "Trelawny Parish",
        countryCode: "JM",
        latitude: "18.35261430",
        longitude: "-77.60778650",
        nameNative: null,
        label: "Trelawny Parish",
        cityCode: "JM_07",
      },
      {
        name: "Westmoreland Parish",
        countryCode: "JM",
        latitude: "18.29443780",
        longitude: "-78.15644320",
        nameNative: null,
        label: "Westmoreland Parish",
        cityCode: "JM_10",
      },
    ],
    JP: [
      {
        name: "Aichi Prefecture",
        countryCode: "JP",
        latitude: "35.01825050",
        longitude: "137.29238930",
        nameNative: "愛知県",
        label: "愛知県 / Aichi Prefecture",
        cityCode: "JP_23",
      },
      {
        name: "Aomori Prefecture",
        countryCode: "JP",
        latitude: "40.76570770",
        longitude: "140.91758790",
        nameNative: "青森県",
        label: "青森県 / Aomori Prefecture",
        cityCode: "JP_02",
      },
      {
        name: "Akita Prefecture",
        countryCode: "JP",
        latitude: "40.13762930",
        longitude: "140.33434100",
        nameNative: "秋田県",
        label: "秋田県 / Akita Prefecture",
        cityCode: "JP_05",
      },
      {
        name: "Ishikawa Prefecture",
        countryCode: "JP",
        latitude: "36.32603170",
        longitude: "136.52896530",
        nameNative: "石川県",
        label: "石川県 / Ishikawa Prefecture",
        cityCode: "JP_17",
      },
      {
        name: "Ibaraki Prefecture",
        countryCode: "JP",
        latitude: "36.21935710",
        longitude: "140.18325160",
        nameNative: "茨城県",
        label: "茨城県 / Ibaraki Prefecture",
        cityCode: "JP_08",
      },
      {
        name: "Iwate Prefecture",
        countryCode: "JP",
        latitude: "39.58329890",
        longitude: "141.25345740",
        nameNative: "岩手県",
        label: "岩手県 / Iwate Prefecture",
        cityCode: "JP_03",
      },
      {
        name: "Ehime Prefecture",
        countryCode: "JP",
        latitude: "33.60253060",
        longitude: "132.78575830",
        nameNative: "愛媛県",
        label: "愛媛県 / Ehime Prefecture",
        cityCode: "JP_38",
      },
      {
        name: "Ōita Prefecture",
        countryCode: "JP",
        latitude: "33.15892990",
        longitude: "131.36111210",
        nameNative: "大分県",
        label: "大分県 / Ōita Prefecture",
        cityCode: "JP_44",
      },
      {
        name: "Ōsaka Prefecture",
        countryCode: "JP",
        latitude: "34.64133150",
        longitude: "135.56293940",
        nameNative: "大阪府",
        label: "大阪府 / Ōsaka Prefecture",
        cityCode: "JP_27",
      },
      {
        name: "Okayama Prefecture",
        countryCode: "JP",
        latitude: "34.89634070",
        longitude: "133.63753140",
        nameNative: "岡山県",
        label: "岡山県 / Okayama Prefecture",
        cityCode: "JP_33",
      },
      {
        name: "Okinawa Prefecture",
        countryCode: "JP",
        latitude: "26.12019110",
        longitude: "127.70250120",
        nameNative: "沖縄県",
        label: "沖縄県 / Okinawa Prefecture",
        cityCode: "JP_47",
      },
      {
        name: "Kagawa Prefecture",
        countryCode: "JP",
        latitude: "34.22259150",
        longitude: "134.01991520",
        nameNative: "香川県",
        label: "香川県 / Kagawa Prefecture",
        cityCode: "JP_37",
      },
      {
        name: "Kagoshima Prefecture",
        countryCode: "JP",
        latitude: "31.39119580",
        longitude: "130.87785860",
        nameNative: "鹿児島県",
        label: "鹿児島県 / Kagoshima Prefecture",
        cityCode: "JP_46",
      },
      {
        name: "Kanagawa Prefecture",
        countryCode: "JP",
        latitude: "35.49135350",
        longitude: "139.28414300",
        nameNative: "神奈川県",
        label: "神奈川県 / Kanagawa Prefecture",
        cityCode: "JP_14",
      },
      {
        name: "Gifu Prefecture",
        countryCode: "JP",
        latitude: "35.74374910",
        longitude: "136.98051030",
        nameNative: "岐阜県",
        label: "岐阜県 / Gifu Prefecture",
        cityCode: "JP_21",
      },
      {
        name: "Kyōto Prefecture",
        countryCode: "JP",
        latitude: "35.15666090",
        longitude: "135.52519820",
        nameNative: "京都府",
        label: "京都府 / Kyōto Prefecture",
        cityCode: "JP_26",
      },
      {
        name: "Kumamoto Prefecture",
        countryCode: "JP",
        latitude: "32.85944270",
        longitude: "130.79691490",
        nameNative: "熊本県",
        label: "熊本県 / Kumamoto Prefecture",
        cityCode: "JP_43",
      },
      {
        name: "Gunma Prefecture",
        countryCode: "JP",
        latitude: "36.56053880",
        longitude: "138.87999720",
        nameNative: "群馬県",
        label: "群馬県 / Gunma Prefecture",
        cityCode: "JP_10",
      },
      {
        name: "Kōchi Prefecture",
        countryCode: "JP",
        latitude: "33.28791610",
        longitude: "132.27592620",
        nameNative: "高知県",
        label: "高知県 / Kōchi Prefecture",
        cityCode: "JP_39",
      },
      {
        name: "Saitama Prefecture",
        countryCode: "JP",
        latitude: "35.99625130",
        longitude: "139.44660050",
        nameNative: "埼玉県",
        label: "埼玉県 / Saitama Prefecture",
        cityCode: "JP_11",
      },
      {
        name: "Saga Prefecture",
        countryCode: "JP",
        latitude: "33.30783710",
        longitude: "130.22712430",
        nameNative: "佐賀県",
        label: "佐賀県 / Saga Prefecture",
        cityCode: "JP_41",
      },
      {
        name: "Shiga Prefecture",
        countryCode: "JP",
        latitude: "35.32920140",
        longitude: "136.05632120",
        nameNative: "滋賀県",
        label: "滋賀県 / Shiga Prefecture",
        cityCode: "JP_25",
      },
      {
        name: "Shizuoka Prefecture",
        countryCode: "JP",
        latitude: "35.09293970",
        longitude: "138.31902760",
        nameNative: "静岡県",
        label: "静岡県 / Shizuoka Prefecture",
        cityCode: "JP_22",
      },
      {
        name: "Shimane Prefecture",
        countryCode: "JP",
        latitude: "35.12440940",
        longitude: "132.62934460",
        nameNative: "島根県",
        label: "島根県 / Shimane Prefecture",
        cityCode: "JP_32",
      },
      {
        name: "Chiba Prefecture",
        countryCode: "JP",
        latitude: "35.33541550",
        longitude: "140.18325160",
        nameNative: "千葉県",
        label: "千葉県 / Chiba Prefecture",
        cityCode: "JP_12",
      },
      {
        name: "Tokyo",
        countryCode: "JP",
        latitude: "35.67619190",
        longitude: "139.65031060",
        nameNative: "東京都",
        label: "東京都 / Tokyo",
        cityCode: "JP_13",
      },
      {
        name: "Tokushima Prefecture",
        countryCode: "JP",
        latitude: "33.94196550",
        longitude: "134.32365570",
        nameNative: "徳島県",
        label: "徳島県 / Tokushima Prefecture",
        cityCode: "JP_36",
      },
      {
        name: "Tochigi Prefecture",
        countryCode: "JP",
        latitude: "36.67147390",
        longitude: "139.85472660",
        nameNative: "栃木県",
        label: "栃木県 / Tochigi Prefecture",
        cityCode: "JP_09",
      },
      {
        name: "Tottori Prefecture",
        countryCode: "JP",
        latitude: "35.35731610",
        longitude: "133.40666180",
        nameNative: "鳥取県",
        label: "鳥取県 / Tottori Prefecture",
        cityCode: "JP_31",
      },
      {
        name: "Toyama Prefecture",
        countryCode: "JP",
        latitude: "36.69582660",
        longitude: "137.21370710",
        nameNative: "富山県",
        label: "富山県 / Toyama Prefecture",
        cityCode: "JP_16",
      },
      {
        name: "Nagasaki Prefecture",
        countryCode: "JP",
        latitude: "33.24885250",
        longitude: "129.69309120",
        nameNative: "長崎県",
        label: "長崎県 / Nagasaki Prefecture",
        cityCode: "JP_42",
      },
      {
        name: "Nagano Prefecture",
        countryCode: "JP",
        latitude: "36.15439410",
        longitude: "137.92182040",
        nameNative: "長野県",
        label: "長野県 / Nagano Prefecture",
        cityCode: "JP_20",
      },
      {
        name: "Nara Prefecture",
        countryCode: "JP",
        latitude: "34.29755280",
        longitude: "135.82797340",
        nameNative: "奈良県",
        label: "奈良県 / Nara Prefecture",
        cityCode: "JP_29",
      },
      {
        name: "Niigata Prefecture",
        countryCode: "JP",
        latitude: "37.51783860",
        longitude: "138.92697940",
        nameNative: "新潟県",
        label: "新潟県 / Niigata Prefecture",
        cityCode: "JP_15",
      },
      {
        name: "Hyōgo Prefecture",
        countryCode: "JP",
        latitude: "34.85795180",
        longitude: "134.54537870",
        nameNative: "兵庫県",
        label: "兵庫県 / Hyōgo Prefecture",
        cityCode: "JP_28",
      },
      {
        name: "Hiroshima Prefecture",
        countryCode: "JP",
        latitude: "34.88234080",
        longitude: "133.01948970",
        nameNative: "広島県",
        label: "広島県 / Hiroshima Prefecture",
        cityCode: "JP_34",
      },
      {
        name: "Fukui Prefecture",
        countryCode: "JP",
        latitude: "35.89622700",
        longitude: "136.21115790",
        nameNative: "福井県",
        label: "福井県 / Fukui Prefecture",
        cityCode: "JP_18",
      },
      {
        name: "Fukuoka Prefecture",
        countryCode: "JP",
        latitude: "33.56625590",
        longitude: "130.71585700",
        nameNative: "福岡県",
        label: "福岡県 / Fukuoka Prefecture",
        cityCode: "JP_40",
      },
      {
        name: "Fukushima Prefecture",
        countryCode: "JP",
        latitude: "37.38343730",
        longitude: "140.18325160",
        nameNative: "福島県",
        label: "福島県 / Fukushima Prefecture",
        cityCode: "JP_07",
      },
      {
        name: "Hokkaidō Prefecture",
        countryCode: "JP",
        latitude: "43.22032660",
        longitude: "142.86347370",
        nameNative: "北海道",
        label: "北海道 / Hokkaidō Prefecture",
        cityCode: "JP_01",
      },
      {
        name: "Mie Prefecture",
        countryCode: "JP",
        latitude: "33.81439010",
        longitude: "136.04870470",
        nameNative: "三重県",
        label: "三重県 / Mie Prefecture",
        cityCode: "JP_24",
      },
      {
        name: "Miyagi Prefecture",
        countryCode: "JP",
        latitude: "38.63061200",
        longitude: "141.11930480",
        nameNative: "宮城県",
        label: "宮城県 / Miyagi Prefecture",
        cityCode: "JP_04",
      },
      {
        name: "Miyazaki Prefecture",
        countryCode: "JP",
        latitude: "32.60360220",
        longitude: "131.44125100",
        nameNative: "宮崎県",
        label: "宮崎県 / Miyazaki Prefecture",
        cityCode: "JP_45",
      },
      {
        name: "Yamagata Prefecture",
        countryCode: "JP",
        latitude: "38.53705640",
        longitude: "140.14351980",
        nameNative: "山形県",
        label: "山形県 / Yamagata Prefecture",
        cityCode: "JP_06",
      },
      {
        name: "Yamaguchi Prefecture",
        countryCode: "JP",
        latitude: "34.27967690",
        longitude: "131.52127420",
        nameNative: "山口県",
        label: "山口県 / Yamaguchi Prefecture",
        cityCode: "JP_35",
      },
      {
        name: "Yamanashi Prefecture",
        countryCode: "JP",
        latitude: "35.66351130",
        longitude: "138.63888790",
        nameNative: "山梨県",
        label: "山梨県 / Yamanashi Prefecture",
        cityCode: "JP_19",
      },
      {
        name: "Wakayama Prefecture",
        countryCode: "JP",
        latitude: "33.94809140",
        longitude: "135.37453580",
        nameNative: "和歌山県",
        label: "和歌山県 / Wakayama Prefecture",
        cityCode: "JP_30",
      },
    ],
    JO: [
      {
        name: "Ajloun Governorate",
        countryCode: "JO",
        latitude: "32.33255840",
        longitude: "35.75168440",
        nameNative: null,
        label: "Ajloun Governorate",
        cityCode: "JO_AJ",
      },
      {
        name: "Amman Governorate",
        countryCode: "JO",
        latitude: "31.94536330",
        longitude: "35.92838950",
        nameNative: null,
        label: "Amman Governorate",
        cityCode: "JO_AM",
      },
      {
        name: "Aqaba Governorate",
        countryCode: "JO",
        latitude: "29.53208600",
        longitude: "35.00628210",
        nameNative: null,
        label: "Aqaba Governorate",
        cityCode: "JO_AQ",
      },
      {
        name: "Balqa Governorate",
        countryCode: "JO",
        latitude: "32.03668060",
        longitude: "35.72884800",
        nameNative: null,
        label: "Balqa Governorate",
        cityCode: "JO_BA",
      },
      {
        name: "Irbid Governorate",
        countryCode: "JO",
        latitude: "32.55696360",
        longitude: "35.84789650",
        nameNative: null,
        label: "Irbid Governorate",
        cityCode: "JO_IR",
      },
      {
        name: "Jerash Governorate",
        countryCode: "JO",
        latitude: "32.27472370",
        longitude: "35.89609540",
        nameNative: null,
        label: "Jerash Governorate",
        cityCode: "JO_JA",
      },
      {
        name: "Karak Governorate",
        countryCode: "JO",
        latitude: "31.18535270",
        longitude: "35.70476820",
        nameNative: null,
        label: "Karak Governorate",
        cityCode: "JO_KA",
      },
      {
        name: "Ma'an Governorate",
        countryCode: "JO",
        latitude: "30.19267890",
        longitude: "35.72493190",
        nameNative: null,
        label: "Ma'an Governorate",
        cityCode: "JO_MN",
      },
      {
        name: "Madaba Governorate",
        countryCode: "JO",
        latitude: "31.71960970",
        longitude: "35.79327540",
        nameNative: null,
        label: "Madaba Governorate",
        cityCode: "JO_MD",
      },
      {
        name: "Mafraq Governorate",
        countryCode: "JO",
        latitude: "32.34169230",
        longitude: "36.20201750",
        nameNative: null,
        label: "Mafraq Governorate",
        cityCode: "JO_MA",
      },
      {
        name: "Tafilah Governorate",
        countryCode: "JO",
        latitude: "30.83380630",
        longitude: "35.61605130",
        nameNative: null,
        label: "Tafilah Governorate",
        cityCode: "JO_AT",
      },
      {
        name: "Zarqa Governorate",
        countryCode: "JO",
        latitude: "32.06085050",
        longitude: "36.09421210",
        nameNative: null,
        label: "Zarqa Governorate",
        cityCode: "JO_AZ",
      },
    ],
    KZ: [
      {
        name: "Akmola Region",
        countryCode: "KZ",
        latitude: "51.91653200",
        longitude: "69.41104940",
        nameNative: null,
        label: "Akmola Region",
        cityCode: "KZ_AKM",
      },
      {
        name: "Aktobe Region",
        countryCode: "KZ",
        latitude: "48.77970780",
        longitude: "57.99743780",
        nameNative: null,
        label: "Aktobe Region",
        cityCode: "KZ_AKT",
      },
      {
        name: "Almaty Region",
        countryCode: "KZ",
        latitude: "45.01192270",
        longitude: "78.42293920",
        nameNative: null,
        label: "Almaty Region",
        cityCode: "KZ_ALM",
      },
      {
        name: "Almaty",
        countryCode: "KZ",
        latitude: "43.22201460",
        longitude: "76.85124850",
        nameNative: null,
        label: "Almaty",
        cityCode: "KZ_ALA",
      },
      {
        name: "Atyrau Region",
        countryCode: "KZ",
        latitude: "47.10761880",
        longitude: "51.91413300",
        nameNative: null,
        label: "Atyrau Region",
        cityCode: "KZ_ATY",
      },
      {
        name: "Baikonur",
        countryCode: "KZ",
        latitude: "45.96458510",
        longitude: "63.30524280",
        nameNative: null,
        label: "Baikonur",
        cityCode: "KZ_BAY",
      },
      {
        name: "East Kazakhstan Region",
        countryCode: "KZ",
        latitude: "48.70626870",
        longitude: "80.79225340",
        nameNative: null,
        label: "East Kazakhstan Region",
        cityCode: "KZ_VOS",
      },
      {
        name: "Jambyl Region",
        countryCode: "KZ",
        latitude: "44.22203080",
        longitude: "72.36579670",
        nameNative: null,
        label: "Jambyl Region",
        cityCode: "KZ_ZHA",
      },
      {
        name: "Karaganda Region",
        countryCode: "KZ",
        latitude: "47.90221820",
        longitude: "71.77068070",
        nameNative: null,
        label: "Karaganda Region",
        cityCode: "KZ_KAR",
      },
      {
        name: "Kostanay Region",
        countryCode: "KZ",
        latitude: "51.50770960",
        longitude: "64.04790730",
        nameNative: null,
        label: "Kostanay Region",
        cityCode: "KZ_KUS",
      },
      {
        name: "Kyzylorda Region",
        countryCode: "KZ",
        latitude: "44.69226130",
        longitude: "62.65718850",
        nameNative: null,
        label: "Kyzylorda Region",
        cityCode: "KZ_KZY",
      },
      {
        name: "Mangystau Region",
        countryCode: "KZ",
        latitude: "44.59080200",
        longitude: "53.84995080",
        nameNative: null,
        label: "Mangystau Region",
        cityCode: "KZ_MAN",
      },
      {
        name: "North Kazakhstan Region",
        countryCode: "KZ",
        latitude: "54.16220660",
        longitude: "69.93870710",
        nameNative: null,
        label: "North Kazakhstan Region",
        cityCode: "KZ_SEV",
      },
      {
        name: "Nur-Sultan",
        countryCode: "KZ",
        latitude: "51.16052270",
        longitude: "71.47035580",
        nameNative: null,
        label: "Nur-Sultan",
        cityCode: "KZ_AST",
      },
      {
        name: "Pavlodar Region",
        countryCode: "KZ",
        latitude: "52.28784440",
        longitude: "76.97334530",
        nameNative: null,
        label: "Pavlodar Region",
        cityCode: "KZ_PAV",
      },
      {
        name: "Turkestan Region",
        countryCode: "KZ",
        latitude: "43.36669580",
        longitude: "68.40944050",
        nameNative: null,
        label: "Turkestan Region",
        cityCode: "KZ_YUZ",
      },
      {
        name: "West Kazakhstan Province",
        countryCode: "KZ",
        latitude: "49.56797270",
        longitude: "50.80666160",
        nameNative: null,
        label: "West Kazakhstan Province",
        cityCode: "KZ_ZAP",
      },
    ],
    KE: [
      {
        name: "Baringo",
        countryCode: "KE",
        latitude: "0.85549880",
        longitude: "36.08934060",
        nameNative: null,
        label: "Baringo",
        cityCode: "KE_01",
      },
      {
        name: "Bomet",
        countryCode: "KE",
        latitude: "-0.80150090",
        longitude: "35.30272260",
        nameNative: null,
        label: "Bomet",
        cityCode: "KE_02",
      },
      {
        name: "Bungoma",
        countryCode: "KE",
        latitude: "0.56952520",
        longitude: "34.55837660",
        nameNative: null,
        label: "Bungoma",
        cityCode: "KE_03",
      },
      {
        name: "Busia",
        countryCode: "KE",
        latitude: "0.43465060",
        longitude: "34.24215970",
        nameNative: null,
        label: "Busia",
        cityCode: "KE_04",
      },
      {
        name: "Elgeyo-Marakwet",
        countryCode: "KE",
        latitude: "1.04982370",
        longitude: "35.47819260",
        nameNative: null,
        label: "Elgeyo-Marakwet",
        cityCode: "KE_05",
      },
      {
        name: "Embu",
        countryCode: "KE",
        latitude: "-0.65604770",
        longitude: "37.72376780",
        nameNative: null,
        label: "Embu",
        cityCode: "KE_06",
      },
      {
        name: "Garissa",
        countryCode: "KE",
        latitude: "-0.45322930",
        longitude: "39.64609880",
        nameNative: null,
        label: "Garissa",
        cityCode: "KE_07",
      },
      {
        name: "Homa Bay",
        countryCode: "KE",
        latitude: "-0.62206550",
        longitude: "34.33103640",
        nameNative: null,
        label: "Homa Bay",
        cityCode: "KE_08",
      },
      {
        name: "Isiolo",
        countryCode: "KE",
        latitude: "0.35243520",
        longitude: "38.48499230",
        nameNative: null,
        label: "Isiolo",
        cityCode: "KE_09",
      },
      {
        name: "Kajiado",
        countryCode: "KE",
        latitude: "-2.09807510",
        longitude: "36.78195050",
        nameNative: null,
        label: "Kajiado",
        cityCode: "KE_10",
      },
      {
        name: "Kakamega",
        countryCode: "KE",
        latitude: "0.30789400",
        longitude: "34.77407930",
        nameNative: null,
        label: "Kakamega",
        cityCode: "KE_11",
      },
      {
        name: "Kericho",
        countryCode: "KE",
        latitude: "-0.18279130",
        longitude: "35.47819260",
        nameNative: null,
        label: "Kericho",
        cityCode: "KE_12",
      },
      {
        name: "Kiambu",
        countryCode: "KE",
        latitude: "-1.03137010",
        longitude: "36.86807910",
        nameNative: null,
        label: "Kiambu",
        cityCode: "KE_13",
      },
      {
        name: "Kilifi",
        countryCode: "KE",
        latitude: "-3.51065080",
        longitude: "39.90932690",
        nameNative: null,
        label: "Kilifi",
        cityCode: "KE_14",
      },
      {
        name: "Kirinyaga",
        countryCode: "KE",
        latitude: "-0.65905650",
        longitude: "37.38272340",
        nameNative: null,
        label: "Kirinyaga",
        cityCode: "KE_15",
      },
      {
        name: "Kisii",
        countryCode: "KE",
        latitude: "-0.67733400",
        longitude: "34.77960300",
        nameNative: null,
        label: "Kisii",
        cityCode: "KE_16",
      },
      {
        name: "Kisumu",
        countryCode: "KE",
        latitude: "-0.09170160",
        longitude: "34.76795680",
        nameNative: null,
        label: "Kisumu",
        cityCode: "KE_17",
      },
      {
        name: "Kitui",
        countryCode: "KE",
        latitude: "-1.68328220",
        longitude: "38.31657250",
        nameNative: null,
        label: "Kitui",
        cityCode: "KE_18",
      },
      {
        name: "Kwale",
        countryCode: "KE",
        latitude: "-4.18161150",
        longitude: "39.46056120",
        nameNative: null,
        label: "Kwale",
        cityCode: "KE_19",
      },
      {
        name: "Laikipia",
        countryCode: "KE",
        latitude: "0.36060630",
        longitude: "36.78195050",
        nameNative: null,
        label: "Laikipia",
        cityCode: "KE_20",
      },
      {
        name: "Lamu",
        countryCode: "KE",
        latitude: "-2.23550580",
        longitude: "40.47200040",
        nameNative: null,
        label: "Lamu",
        cityCode: "KE_21",
      },
      {
        name: "Machakos",
        countryCode: "KE",
        latitude: "-1.51768370",
        longitude: "37.26341460",
        nameNative: null,
        label: "Machakos",
        cityCode: "KE_22",
      },
      {
        name: "Makueni",
        countryCode: "KE",
        latitude: "-2.25587340",
        longitude: "37.89366630",
        nameNative: null,
        label: "Makueni",
        cityCode: "KE_23",
      },
      {
        name: "Mandera",
        countryCode: "KE",
        latitude: "3.57379910",
        longitude: "40.95868800",
        nameNative: null,
        label: "Mandera",
        cityCode: "KE_24",
      },
      {
        name: "Marsabit",
        countryCode: "KE",
        latitude: "2.44264030",
        longitude: "37.97845850",
        nameNative: null,
        label: "Marsabit",
        cityCode: "KE_25",
      },
      {
        name: "Meru",
        countryCode: "KE",
        latitude: "0.35571740",
        longitude: "37.80876930",
        nameNative: null,
        label: "Meru",
        cityCode: "KE_26",
      },
      {
        name: "Migori",
        countryCode: "KE",
        latitude: "-0.93657020",
        longitude: "34.41982430",
        nameNative: null,
        label: "Migori",
        cityCode: "KE_27",
      },
      {
        name: "Mombasa",
        countryCode: "KE",
        latitude: "-3.97682910",
        longitude: "39.71371810",
        nameNative: null,
        label: "Mombasa",
        cityCode: "KE_28",
      },
      {
        name: "Murang'a",
        countryCode: "KE",
        latitude: "-0.78392810",
        longitude: "37.04003390",
        nameNative: null,
        label: "Murang'a",
        cityCode: "KE_29",
      },
      {
        name: "Nairobi City",
        countryCode: "KE",
        latitude: "-1.29206590",
        longitude: "36.82194620",
        nameNative: null,
        label: "Nairobi City",
        cityCode: "KE_30",
      },
      {
        name: "Nakuru",
        countryCode: "KE",
        latitude: "-0.30309880",
        longitude: "36.08002600",
        nameNative: null,
        label: "Nakuru",
        cityCode: "KE_31",
      },
      {
        name: "Nandi",
        countryCode: "KE",
        latitude: "0.18358670",
        longitude: "35.12687810",
        nameNative: null,
        label: "Nandi",
        cityCode: "KE_32",
      },
      {
        name: "Narok",
        countryCode: "KE",
        latitude: "-1.10411100",
        longitude: "36.08934060",
        nameNative: null,
        label: "Narok",
        cityCode: "KE_33",
      },
      {
        name: "Nyamira",
        countryCode: "KE",
        latitude: "-0.56694050",
        longitude: "34.93412340",
        nameNative: null,
        label: "Nyamira",
        cityCode: "KE_34",
      },
      {
        name: "Nyandarua",
        countryCode: "KE",
        latitude: "-0.18038550",
        longitude: "36.52296410",
        nameNative: null,
        label: "Nyandarua",
        cityCode: "KE_35",
      },
      {
        name: "Nyeri",
        countryCode: "KE",
        latitude: "-0.41969150",
        longitude: "37.04003390",
        nameNative: null,
        label: "Nyeri",
        cityCode: "KE_36",
      },
      {
        name: "Samburu",
        countryCode: "KE",
        latitude: "1.21545060",
        longitude: "36.95410700",
        nameNative: null,
        label: "Samburu",
        cityCode: "KE_37",
      },
      {
        name: "Siaya",
        countryCode: "KE",
        latitude: "-0.06173280",
        longitude: "34.24215970",
        nameNative: null,
        label: "Siaya",
        cityCode: "KE_38",
      },
      {
        name: "Taita–Taveta",
        countryCode: "KE",
        latitude: "-3.31606870",
        longitude: "38.48499230",
        nameNative: null,
        label: "Taita–Taveta",
        cityCode: "KE_39",
      },
      {
        name: "Tana River",
        countryCode: "KE",
        latitude: "-1.65184680",
        longitude: "39.65181650",
        nameNative: null,
        label: "Tana River",
        cityCode: "KE_40",
      },
      {
        name: "Tharaka-Nithi",
        countryCode: "KE",
        latitude: "-0.29648510",
        longitude: "37.72376780",
        nameNative: null,
        label: "Tharaka-Nithi",
        cityCode: "KE_41",
      },
      {
        name: "Trans Nzoia",
        countryCode: "KE",
        latitude: "1.05666670",
        longitude: "34.95066250",
        nameNative: null,
        label: "Trans Nzoia",
        cityCode: "KE_42",
      },
      {
        name: "Turkana",
        countryCode: "KE",
        latitude: "3.31224770",
        longitude: "35.56578620",
        nameNative: null,
        label: "Turkana",
        cityCode: "KE_43",
      },
      {
        name: "Uasin Gishu",
        countryCode: "KE",
        latitude: "0.55276380",
        longitude: "35.30272260",
        nameNative: null,
        label: "Uasin Gishu",
        cityCode: "KE_44",
      },
      {
        name: "Vihiga",
        countryCode: "KE",
        latitude: "0.07675530",
        longitude: "34.70776650",
        nameNative: null,
        label: "Vihiga",
        cityCode: "KE_45",
      },
      {
        name: "Wajir",
        countryCode: "KE",
        latitude: "1.63604750",
        longitude: "40.30886260",
        nameNative: null,
        label: "Wajir",
        cityCode: "KE_46",
      },
      {
        name: "West Pokot",
        countryCode: "KE",
        latitude: "1.62100760",
        longitude: "35.39050460",
        nameNative: null,
        label: "West Pokot",
        cityCode: "KE_47",
      },
    ],
    KI: [
      {
        name: "Gilbert Islands",
        countryCode: "KI",
        latitude: "0.35242620",
        longitude: "174.75526340",
        nameNative: null,
        label: "Gilbert Islands",
        cityCode: "KI_G",
      },
      {
        name: "Line Islands",
        countryCode: "KI",
        latitude: "1.74294390",
        longitude: "-157.21328260",
        nameNative: null,
        label: "Line Islands",
        cityCode: "KI_L",
      },
      {
        name: "Phoenix Islands",
        countryCode: "KI",
        latitude: "33.32843690",
        longitude: "-111.98247740",
        nameNative: null,
        label: "Phoenix Islands",
        cityCode: "KI_P",
      },
    ],
    KP: [
      {
        name: "Chagang Province",
        countryCode: "KP",
        latitude: "40.72028090",
        longitude: "126.56211370",
        nameNative: null,
        label: "Chagang Province",
        cityCode: "KP_04",
      },
      {
        name: "Kangwon Province",
        countryCode: "KP",
        latitude: "38.84323930",
        longitude: "127.55970670",
        nameNative: null,
        label: "Kangwon Province",
        cityCode: "KP_07",
      },
      {
        name: "North Hamgyong Province",
        countryCode: "KP",
        latitude: "41.81487580",
        longitude: "129.45819550",
        nameNative: null,
        label: "North Hamgyong Province",
        cityCode: "KP_09",
      },
      {
        name: "North Hwanghae Province",
        countryCode: "KP",
        latitude: "38.37860850",
        longitude: "126.43643630",
        nameNative: null,
        label: "North Hwanghae Province",
        cityCode: "KP_06",
      },
      {
        name: "North Pyongan Province",
        countryCode: "KP",
        latitude: "39.92556180",
        longitude: "125.39280250",
        nameNative: null,
        label: "North Pyongan Province",
        cityCode: "KP_03",
      },
      {
        name: "Pyongyang",
        countryCode: "KP",
        latitude: "39.03921930",
        longitude: "125.76252410",
        nameNative: null,
        label: "Pyongyang",
        cityCode: "KP_01",
      },
      {
        name: "Rason",
        countryCode: "KP",
        latitude: "42.25690630",
        longitude: "130.29771860",
        nameNative: null,
        label: "Rason",
        cityCode: "KP_13",
      },
      {
        name: "Ryanggang Province",
        countryCode: "KP",
        latitude: "41.23189210",
        longitude: "128.50763590",
        nameNative: null,
        label: "Ryanggang Province",
        cityCode: "KP_10",
      },
      {
        name: "South Hamgyong Province",
        countryCode: "KP",
        latitude: "40.37253390",
        longitude: "128.29888400",
        nameNative: null,
        label: "South Hamgyong Province",
        cityCode: "KP_08",
      },
      {
        name: "South Hwanghae Province",
        countryCode: "KP",
        latitude: "38.20072150",
        longitude: "125.47819260",
        nameNative: null,
        label: "South Hwanghae Province",
        cityCode: "KP_05",
      },
      {
        name: "South Pyongan Province",
        countryCode: "KP",
        latitude: "39.35391780",
        longitude: "126.16827100",
        nameNative: null,
        label: "South Pyongan Province",
        cityCode: "KP_02",
      },
    ],
    KR: [
      {
        name: "Busan",
        countryCode: "KR",
        latitude: "35.17955430",
        longitude: "129.07564160",
        nameNative: "부산",
        label: "부산 / Busan",
        cityCode: "KR_26",
      },
      {
        name: "Daegu",
        countryCode: "KR",
        latitude: "35.87143540",
        longitude: "128.60144500",
        nameNative: "대구",
        label: "대구 / Daegu",
        cityCode: "KR_27",
      },
      {
        name: "Daejeon",
        countryCode: "KR",
        latitude: "36.35041190",
        longitude: "127.38454750",
        nameNative: "대전",
        label: "대전 / Daejeon",
        cityCode: "KR_30",
      },
      {
        name: "Gangwon Province",
        countryCode: "KR",
        latitude: "37.82280000",
        longitude: "128.15550000",
        nameNative: "강원도",
        label: "강원도 / Gangwon Province",
        cityCode: "KR_42",
      },
      {
        name: "Gwangju",
        countryCode: "KR",
        latitude: "35.15954540",
        longitude: "126.85260120",
        nameNative: "광주",
        label: "광주 / Gwangju",
        cityCode: "KR_29",
      },
      {
        name: "Gyeonggi Province",
        countryCode: "KR",
        latitude: "37.41380000",
        longitude: "127.51830000",
        nameNative: "경기도",
        label: "경기도 / Gyeonggi Province",
        cityCode: "KR_41",
      },
      {
        name: "Incheon",
        countryCode: "KR",
        latitude: "37.45625570",
        longitude: "126.70520620",
        nameNative: "인천",
        label: "인천 / Incheon",
        cityCode: "KR_28",
      },
      {
        name: "Jeju",
        countryCode: "KR",
        latitude: "33.95682780",
        longitude: "-84.13135000",
        nameNative: "제주",
        label: "제주 / Jeju",
        cityCode: "KR_49",
      },
      {
        name: "North Chungcheong Province",
        countryCode: "KR",
        latitude: "36.80000000",
        longitude: "127.70000000",
        nameNative: "충청북도",
        label: "충청북도 / North Chungcheong Province",
        cityCode: "KR_43",
      },
      {
        name: "North Gyeongsang Province",
        countryCode: "KR",
        latitude: "36.49190000",
        longitude: "128.88890000",
        nameNative: "경상북도",
        label: "경상북도 / North Gyeongsang Province",
        cityCode: "KR_47",
      },
      {
        name: "North Jeolla Province",
        countryCode: "KR",
        latitude: "35.71750000",
        longitude: "127.15300000",
        nameNative: "전라북도",
        label: "전라북도 / North Jeolla Province",
        cityCode: "KR_45",
      },
      {
        name: "Sejong City",
        countryCode: "KR",
        latitude: "34.05233230",
        longitude: "-118.30848970",
        nameNative: "세종시",
        label: "세종시 / Sejong City",
        cityCode: "KR_50",
      },
      {
        name: "Seoul",
        countryCode: "KR",
        latitude: "37.56653500",
        longitude: "126.97796920",
        nameNative: "서울",
        label: "서울 / Seoul",
        cityCode: "KR_11",
      },
      {
        name: "South Chungcheong Province",
        countryCode: "KR",
        latitude: "36.51840000",
        longitude: "126.80000000",
        nameNative: "충청남도",
        label: "충청남도 / South Chungcheong Province",
        cityCode: "KR_44",
      },
      {
        name: "South Gyeongsang Province",
        countryCode: "KR",
        latitude: "35.46060000",
        longitude: "128.21320000",
        nameNative: "경상남도",
        label: "경상남도 / South Gyeongsang Province",
        cityCode: "KR_48",
      },
      {
        name: "South Jeolla Province",
        countryCode: "KR",
        latitude: "34.86790000",
        longitude: "126.99100000",
        nameNative: "전라남도",
        label: "전라남도 / South Jeolla Province",
        cityCode: "KR_46",
      },
      {
        name: "Ulsan",
        countryCode: "KR",
        latitude: "35.53837730",
        longitude: "129.31135960",
        nameNative: "울산",
        label: "울산 / Ulsan",
        cityCode: "KR_31",
      },
    ],
    KW: [
      {
        name: "Al Ahmadi Governorate",
        countryCode: "KW",
        latitude: "28.57451250",
        longitude: "48.10247430",
        nameNative: null,
        label: "Al Ahmadi Governorate",
        cityCode: "KW_AH",
      },
      {
        name: "Al Farwaniyah Governorate",
        countryCode: "KW",
        latitude: "29.27335700",
        longitude: "47.94001540",
        nameNative: null,
        label: "Al Farwaniyah Governorate",
        cityCode: "KW_FA",
      },
      {
        name: "Al Jahra Governorate",
        countryCode: "KW",
        latitude: "29.99318310",
        longitude: "47.76347310",
        nameNative: null,
        label: "Al Jahra Governorate",
        cityCode: "KW_JA",
      },
      {
        name: "Capital Governorate",
        countryCode: "KW",
        latitude: "26.22851610",
        longitude: "50.58604970",
        nameNative: null,
        label: "Capital Governorate",
        cityCode: "KW_KU",
      },
      {
        name: "Hawalli Governorate",
        countryCode: "KW",
        latitude: "29.30567160",
        longitude: "48.03076130",
        nameNative: null,
        label: "Hawalli Governorate",
        cityCode: "KW_HA",
      },
      {
        name: "Mubarak Al-Kabeer Governorate",
        countryCode: "KW",
        latitude: "29.21224000",
        longitude: "48.06051080",
        nameNative: null,
        label: "Mubarak Al-Kabeer Governorate",
        cityCode: "KW_MU",
      },
    ],
    KG: [
      {
        name: "Batken Region",
        countryCode: "KG",
        latitude: "39.97214250",
        longitude: "69.85974060",
        nameNative: null,
        label: "Batken Region",
        cityCode: "KG_B",
      },
      {
        name: "Bishkek",
        countryCode: "KG",
        latitude: "42.87462120",
        longitude: "74.56976170",
        nameNative: null,
        label: "Bishkek",
        cityCode: "KG_GB",
      },
      {
        name: "Chuy Region",
        countryCode: "KG",
        latitude: "42.56550000",
        longitude: "74.40566120",
        nameNative: null,
        label: "Chuy Region",
        cityCode: "KG_C",
      },
      {
        name: "Issyk-Kul Region",
        countryCode: "KG",
        latitude: "42.18594280",
        longitude: "77.56194190",
        nameNative: null,
        label: "Issyk-Kul Region",
        cityCode: "KG_Y",
      },
      {
        name: "Jalal-Abad Region",
        countryCode: "KG",
        latitude: "41.10680800",
        longitude: "72.89880690",
        nameNative: null,
        label: "Jalal-Abad Region",
        cityCode: "KG_J",
      },
      {
        name: "Naryn Region",
        countryCode: "KG",
        latitude: "41.29432270",
        longitude: "75.34121790",
        nameNative: null,
        label: "Naryn Region",
        cityCode: "KG_N",
      },
      {
        name: "Osh Region",
        countryCode: "KG",
        latitude: "39.84073660",
        longitude: "72.89880690",
        nameNative: null,
        label: "Osh Region",
        cityCode: "KG_O",
      },
      {
        name: "Osh",
        countryCode: "KG",
        latitude: "36.06313990",
        longitude: "-95.91828950",
        nameNative: null,
        label: "Osh",
        cityCode: "KG_GO",
      },
      {
        name: "Talas Region",
        countryCode: "KG",
        latitude: "42.28673390",
        longitude: "72.52048270",
        nameNative: null,
        label: "Talas Region",
        cityCode: "KG_T",
      },
    ],
    LA: [
      {
        name: "Attapeu Province",
        countryCode: "LA",
        latitude: "14.93634000",
        longitude: "107.10119310",
        nameNative: null,
        label: "Attapeu Province",
        cityCode: "LA_AT",
      },
      {
        name: "Bokeo Province",
        countryCode: "LA",
        latitude: "20.28726620",
        longitude: "100.70978670",
        nameNative: null,
        label: "Bokeo Province",
        cityCode: "LA_BK",
      },
      {
        name: "Bolikhamsai Province",
        countryCode: "LA",
        latitude: "18.43629240",
        longitude: "104.47233010",
        nameNative: null,
        label: "Bolikhamsai Province",
        cityCode: "LA_BL",
      },
      {
        name: "Champasak Province",
        countryCode: "LA",
        latitude: "14.65786640",
        longitude: "105.96998780",
        nameNative: null,
        label: "Champasak Province",
        cityCode: "LA_CH",
      },
      {
        name: "Houaphanh Province",
        countryCode: "LA",
        latitude: "20.32541750",
        longitude: "104.10013260",
        nameNative: null,
        label: "Houaphanh Province",
        cityCode: "LA_HO",
      },
      {
        name: "Khammouane Province",
        countryCode: "LA",
        latitude: "17.63840660",
        longitude: "105.21948080",
        nameNative: null,
        label: "Khammouane Province",
        cityCode: "LA_KH",
      },
      {
        name: "Luang Namtha Province",
        countryCode: "LA",
        latitude: "20.91701870",
        longitude: "101.16173560",
        nameNative: null,
        label: "Luang Namtha Province",
        cityCode: "LA_LM",
      },
      {
        name: "Luang Prabang Province",
        countryCode: "LA",
        latitude: "20.06562290",
        longitude: "102.62162110",
        nameNative: null,
        label: "Luang Prabang Province",
        cityCode: "LA_LP",
      },
      {
        name: "Oudomxay Province",
        countryCode: "LA",
        latitude: "20.49219290",
        longitude: "101.88917210",
        nameNative: null,
        label: "Oudomxay Province",
        cityCode: "LA_OU",
      },
      {
        name: "Phongsaly Province",
        countryCode: "LA",
        latitude: "21.59193770",
        longitude: "102.25479190",
        nameNative: null,
        label: "Phongsaly Province",
        cityCode: "LA_PH",
      },
      {
        name: "Sainyabuli Province",
        countryCode: "LA",
        latitude: "19.39078860",
        longitude: "101.52480550",
        nameNative: null,
        label: "Sainyabuli Province",
        cityCode: "LA_XA",
      },
      {
        name: "Salavan Province",
        countryCode: "LA",
        latitude: "15.81710730",
        longitude: "106.25221430",
        nameNative: null,
        label: "Salavan Province",
        cityCode: "LA_SL",
      },
      {
        name: "Savannakhet Province",
        countryCode: "LA",
        latitude: "16.50653810",
        longitude: "105.59433880",
        nameNative: null,
        label: "Savannakhet Province",
        cityCode: "LA_SV",
      },
      {
        name: "Sekong Province",
        countryCode: "LA",
        latitude: "15.57674460",
        longitude: "107.00670310",
        nameNative: null,
        label: "Sekong Province",
        cityCode: "LA_XE",
      },
      {
        name: "Vientiane Prefecture",
        countryCode: "LA",
        latitude: "18.11054100",
        longitude: "102.52980280",
        nameNative: null,
        label: "Vientiane Prefecture",
        cityCode: "LA_VT",
      },
      {
        name: "Vientiane Province",
        countryCode: "LA",
        latitude: "18.57050630",
        longitude: "102.62162110",
        nameNative: null,
        label: "Vientiane Province",
        cityCode: "LA_VI",
      },
      {
        name: "Xaisomboun Province",
        countryCode: "LA",
        latitude: "18.43629240",
        longitude: "104.47233010",
        nameNative: null,
        label: "Xaisomboun Province",
        cityCode: "LA_XS",
      },
      {
        name: "Xaisomboun",
        countryCode: "LA",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Xaisomboun",
        cityCode: "LA_XN",
      },
      {
        name: "Xiangkhouang Province",
        countryCode: "LA",
        latitude: "19.60930030",
        longitude: "103.72891670",
        nameNative: null,
        label: "Xiangkhouang Province",
        cityCode: "LA_XI",
      },
    ],
    LV: [
      {
        name: "Aglona Municipality",
        countryCode: "LV",
        latitude: "56.10890060",
        longitude: "27.12862270",
        nameNative: null,
        label: "Aglona Municipality",
        cityCode: "LV_001",
      },
      {
        name: "Aizkraukle Municipality",
        countryCode: "LV",
        latitude: "56.64610800",
        longitude: "25.23708540",
        nameNative: null,
        label: "Aizkraukle Municipality",
        cityCode: "LV_002",
      },
      {
        name: "Aizpute Municipality",
        countryCode: "LV",
        latitude: "56.71825960",
        longitude: "21.60727590",
        nameNative: null,
        label: "Aizpute Municipality",
        cityCode: "LV_003",
      },
      {
        name: "Aknīste Municipality",
        countryCode: "LV",
        latitude: "56.16130370",
        longitude: "25.74848270",
        nameNative: null,
        label: "Aknīste Municipality",
        cityCode: "LV_004",
      },
      {
        name: "Aloja Municipality",
        countryCode: "LV",
        latitude: "57.76713600",
        longitude: "24.87708390",
        nameNative: null,
        label: "Aloja Municipality",
        cityCode: "LV_005",
      },
      {
        name: "Alsunga Municipality",
        countryCode: "LV",
        latitude: "56.98285310",
        longitude: "21.55559190",
        nameNative: null,
        label: "Alsunga Municipality",
        cityCode: "LV_006",
      },
      {
        name: "Alūksne Municipality",
        countryCode: "LV",
        latitude: "57.42544850",
        longitude: "27.04249680",
        nameNative: null,
        label: "Alūksne Municipality",
        cityCode: "LV_007",
      },
      {
        name: "Amata Municipality",
        countryCode: "LV",
        latitude: "56.99387260",
        longitude: "25.26276750",
        nameNative: null,
        label: "Amata Municipality",
        cityCode: "LV_008",
      },
      {
        name: "Ape Municipality",
        countryCode: "LV",
        latitude: "57.53926970",
        longitude: "26.69416490",
        nameNative: null,
        label: "Ape Municipality",
        cityCode: "LV_009",
      },
      {
        name: "Auce Municipality",
        countryCode: "LV",
        latitude: "56.46016800",
        longitude: "22.90547810",
        nameNative: null,
        label: "Auce Municipality",
        cityCode: "LV_010",
      },
      {
        name: "Babīte Municipality",
        countryCode: "LV",
        latitude: "56.95415500",
        longitude: "23.94539900",
        nameNative: null,
        label: "Babīte Municipality",
        cityCode: "LV_012",
      },
      {
        name: "Baldone Municipality",
        countryCode: "LV",
        latitude: "56.74246000",
        longitude: "24.39115440",
        nameNative: null,
        label: "Baldone Municipality",
        cityCode: "LV_013",
      },
      {
        name: "Baltinava Municipality",
        countryCode: "LV",
        latitude: "56.94584680",
        longitude: "27.64410660",
        nameNative: null,
        label: "Baltinava Municipality",
        cityCode: "LV_014",
      },
      {
        name: "Balvi Municipality",
        countryCode: "LV",
        latitude: "57.13262400",
        longitude: "27.26466850",
        nameNative: null,
        label: "Balvi Municipality",
        cityCode: "LV_015",
      },
      {
        name: "Bauska Municipality",
        countryCode: "LV",
        latitude: "56.41018680",
        longitude: "24.20006890",
        nameNative: null,
        label: "Bauska Municipality",
        cityCode: "LV_016",
      },
      {
        name: "Beverīna Municipality",
        countryCode: "LV",
        latitude: "57.51971090",
        longitude: "25.60736540",
        nameNative: null,
        label: "Beverīna Municipality",
        cityCode: "LV_017",
      },
      {
        name: "Brocēni Municipality",
        countryCode: "LV",
        latitude: "56.73475410",
        longitude: "22.63573710",
        nameNative: null,
        label: "Brocēni Municipality",
        cityCode: "LV_018",
      },
      {
        name: "Burtnieki Municipality",
        countryCode: "LV",
        latitude: "57.69490040",
        longitude: "25.27647770",
        nameNative: null,
        label: "Burtnieki Municipality",
        cityCode: "LV_019",
      },
      {
        name: "Carnikava Municipality",
        countryCode: "LV",
        latitude: "57.10241210",
        longitude: "24.21086620",
        nameNative: null,
        label: "Carnikava Municipality",
        cityCode: "LV_020",
      },
      {
        name: "Cesvaine Municipality",
        countryCode: "LV",
        latitude: "56.96792640",
        longitude: "26.30831720",
        nameNative: null,
        label: "Cesvaine Municipality",
        cityCode: "LV_021",
      },
      {
        name: "Cibla Municipality",
        countryCode: "LV",
        latitude: "56.61023440",
        longitude: "27.86965980",
        nameNative: null,
        label: "Cibla Municipality",
        cityCode: "LV_023",
      },
      {
        name: "Cēsis Municipality",
        countryCode: "LV",
        latitude: "57.31028970",
        longitude: "25.26761250",
        nameNative: null,
        label: "Cēsis Municipality",
        cityCode: "LV_022",
      },
      {
        name: "Dagda Municipality",
        countryCode: "LV",
        latitude: "56.09560890",
        longitude: "27.53245900",
        nameNative: null,
        label: "Dagda Municipality",
        cityCode: "LV_024",
      },
      {
        name: "Daugavpils Municipality",
        countryCode: "LV",
        latitude: "55.89917830",
        longitude: "26.61020120",
        nameNative: null,
        label: "Daugavpils Municipality",
        cityCode: "LV_025",
      },
      {
        name: "Daugavpils",
        countryCode: "LV",
        latitude: "55.87473600",
        longitude: "26.53617900",
        nameNative: null,
        label: "Daugavpils",
        cityCode: "LV_DGV",
      },
      {
        name: "Dobele Municipality",
        countryCode: "LV",
        latitude: "56.62630500",
        longitude: "23.28090660",
        nameNative: null,
        label: "Dobele Municipality",
        cityCode: "LV_026",
      },
      {
        name: "Dundaga Municipality",
        countryCode: "LV",
        latitude: "57.50491670",
        longitude: "22.35051140",
        nameNative: null,
        label: "Dundaga Municipality",
        cityCode: "LV_027",
      },
      {
        name: "Durbe Municipality",
        countryCode: "LV",
        latitude: "56.62798570",
        longitude: "21.49162450",
        nameNative: null,
        label: "Durbe Municipality",
        cityCode: "LV_028",
      },
      {
        name: "Engure Municipality",
        countryCode: "LV",
        latitude: "57.16235000",
        longitude: "23.21966340",
        nameNative: null,
        label: "Engure Municipality",
        cityCode: "LV_029",
      },
      {
        name: "Garkalne Municipality",
        countryCode: "LV",
        latitude: "57.01903870",
        longitude: "24.38261810",
        nameNative: null,
        label: "Garkalne Municipality",
        cityCode: "LV_031",
      },
      {
        name: "Grobiņa Municipality",
        countryCode: "LV",
        latitude: "56.53963200",
        longitude: "21.16689200",
        nameNative: null,
        label: "Grobiņa Municipality",
        cityCode: "LV_032",
      },
      {
        name: "Gulbene Municipality",
        countryCode: "LV",
        latitude: "57.21556450",
        longitude: "26.64529550",
        nameNative: null,
        label: "Gulbene Municipality",
        cityCode: "LV_033",
      },
      {
        name: "Iecava Municipality",
        countryCode: "LV",
        latitude: "56.59867930",
        longitude: "24.19962720",
        nameNative: null,
        label: "Iecava Municipality",
        cityCode: "LV_034",
      },
      {
        name: "Ikšķile Municipality",
        countryCode: "LV",
        latitude: "56.83736670",
        longitude: "24.49747450",
        nameNative: null,
        label: "Ikšķile Municipality",
        cityCode: "LV_035",
      },
      {
        name: "Ilūkste Municipality",
        countryCode: "LV",
        latitude: "55.97825470",
        longitude: "26.29650880",
        nameNative: null,
        label: "Ilūkste Municipality",
        cityCode: "LV_036",
      },
      {
        name: "Inčukalns Municipality",
        countryCode: "LV",
        latitude: "57.09943420",
        longitude: "24.68555700",
        nameNative: null,
        label: "Inčukalns Municipality",
        cityCode: "LV_037",
      },
      {
        name: "Jaunjelgava Municipality",
        countryCode: "LV",
        latitude: "56.52836590",
        longitude: "25.39214430",
        nameNative: null,
        label: "Jaunjelgava Municipality",
        cityCode: "LV_038",
      },
      {
        name: "Jaunpiebalga Municipality",
        countryCode: "LV",
        latitude: "57.14334710",
        longitude: "25.99518880",
        nameNative: null,
        label: "Jaunpiebalga Municipality",
        cityCode: "LV_039",
      },
      {
        name: "Jaunpils Municipality",
        countryCode: "LV",
        latitude: "56.73141940",
        longitude: "23.01256160",
        nameNative: null,
        label: "Jaunpils Municipality",
        cityCode: "LV_040",
      },
      {
        name: "Jelgava Municipality",
        countryCode: "LV",
        latitude: "56.58956890",
        longitude: "23.66104810",
        nameNative: null,
        label: "Jelgava Municipality",
        cityCode: "LV_041",
      },
      {
        name: "Jelgava",
        countryCode: "LV",
        latitude: "56.65110910",
        longitude: "23.72135410",
        nameNative: null,
        label: "Jelgava",
        cityCode: "LV_JEL",
      },
      {
        name: "Jēkabpils Municipality",
        countryCode: "LV",
        latitude: "56.29193200",
        longitude: "25.98120170",
        nameNative: null,
        label: "Jēkabpils Municipality",
        cityCode: "LV_042",
      },
      {
        name: "Jēkabpils",
        countryCode: "LV",
        latitude: "56.50145500",
        longitude: "25.87829900",
        nameNative: null,
        label: "Jēkabpils",
        cityCode: "LV_JKB",
      },
      {
        name: "Jūrmala",
        countryCode: "LV",
        latitude: "56.94707900",
        longitude: "23.61684850",
        nameNative: null,
        label: "Jūrmala",
        cityCode: "LV_JUR",
      },
      {
        name: "Kandava Municipality",
        countryCode: "LV",
        latitude: "57.03406730",
        longitude: "22.78018130",
        nameNative: null,
        label: "Kandava Municipality",
        cityCode: "LV_043",
      },
      {
        name: "Kocēni Municipality",
        countryCode: "LV",
        latitude: "57.52262920",
        longitude: "25.33495070",
        nameNative: null,
        label: "Kocēni Municipality",
        cityCode: "LV_045",
      },
      {
        name: "Koknese Municipality",
        countryCode: "LV",
        latitude: "56.72055600",
        longitude: "25.48939090",
        nameNative: null,
        label: "Koknese Municipality",
        cityCode: "LV_046",
      },
      {
        name: "Krimulda Municipality",
        countryCode: "LV",
        latitude: "57.17912730",
        longitude: "24.71401270",
        nameNative: null,
        label: "Krimulda Municipality",
        cityCode: "LV_048",
      },
      {
        name: "Krustpils Municipality",
        countryCode: "LV",
        latitude: "56.54155780",
        longitude: "26.24463970",
        nameNative: null,
        label: "Krustpils Municipality",
        cityCode: "LV_049",
      },
      {
        name: "Krāslava Municipality",
        countryCode: "LV",
        latitude: "55.89514640",
        longitude: "27.18145770",
        nameNative: null,
        label: "Krāslava Municipality",
        cityCode: "LV_047",
      },
      {
        name: "Kuldīga Municipality",
        countryCode: "LV",
        latitude: "56.96872570",
        longitude: "21.96137390",
        nameNative: null,
        label: "Kuldīga Municipality",
        cityCode: "LV_050",
      },
      {
        name: "Kārsava Municipality",
        countryCode: "LV",
        latitude: "56.76458420",
        longitude: "27.73582950",
        nameNative: null,
        label: "Kārsava Municipality",
        cityCode: "LV_044",
      },
      {
        name: "Lielvārde Municipality",
        countryCode: "LV",
        latitude: "56.73929760",
        longitude: "24.97116180",
        nameNative: null,
        label: "Lielvārde Municipality",
        cityCode: "LV_053",
      },
      {
        name: "Liepāja",
        countryCode: "LV",
        latitude: "56.50466780",
        longitude: "21.01080600",
        nameNative: null,
        label: "Liepāja",
        cityCode: "LV_LPX",
      },
      {
        name: "Limbaži Municipality",
        countryCode: "LV",
        latitude: "57.54032270",
        longitude: "24.71344510",
        nameNative: null,
        label: "Limbaži Municipality",
        cityCode: "LV_054",
      },
      {
        name: "Lubāna Municipality",
        countryCode: "LV",
        latitude: "56.89992690",
        longitude: "26.71987890",
        nameNative: null,
        label: "Lubāna Municipality",
        cityCode: "LV_057",
      },
      {
        name: "Ludza Municipality",
        countryCode: "LV",
        latitude: "56.54595900",
        longitude: "27.71431990",
        nameNative: null,
        label: "Ludza Municipality",
        cityCode: "LV_058",
      },
      {
        name: "Līgatne Municipality",
        countryCode: "LV",
        latitude: "57.19442040",
        longitude: "25.09406810",
        nameNative: null,
        label: "Līgatne Municipality",
        cityCode: "LV_055",
      },
      {
        name: "Līvāni Municipality",
        countryCode: "LV",
        latitude: "56.35509420",
        longitude: "26.17251900",
        nameNative: null,
        label: "Līvāni Municipality",
        cityCode: "LV_056",
      },
      {
        name: "Madona Municipality",
        countryCode: "LV",
        latitude: "56.85989230",
        longitude: "26.22762010",
        nameNative: null,
        label: "Madona Municipality",
        cityCode: "LV_059",
      },
      {
        name: "Mazsalaca Municipality",
        countryCode: "LV",
        latitude: "57.92677490",
        longitude: "25.06698950",
        nameNative: null,
        label: "Mazsalaca Municipality",
        cityCode: "LV_060",
      },
      {
        name: "Mālpils Municipality",
        countryCode: "LV",
        latitude: "57.00841190",
        longitude: "24.95742780",
        nameNative: null,
        label: "Mālpils Municipality",
        cityCode: "LV_061",
      },
      {
        name: "Mārupe Municipality",
        countryCode: "LV",
        latitude: "56.89657170",
        longitude: "24.04600490",
        nameNative: null,
        label: "Mārupe Municipality",
        cityCode: "LV_062",
      },
      {
        name: "Mērsrags Municipality",
        countryCode: "LV",
        latitude: "57.33068810",
        longitude: "23.10237070",
        nameNative: null,
        label: "Mērsrags Municipality",
        cityCode: "LV_063",
      },
      {
        name: "Naukšēni Municipality",
        countryCode: "LV",
        latitude: "57.92953610",
        longitude: "25.51192660",
        nameNative: null,
        label: "Naukšēni Municipality",
        cityCode: "LV_064",
      },
      {
        name: "Nereta Municipality",
        countryCode: "LV",
        latitude: "56.19866550",
        longitude: "25.32529690",
        nameNative: null,
        label: "Nereta Municipality",
        cityCode: "LV_065",
      },
      {
        name: "Nīca Municipality",
        countryCode: "LV",
        latitude: "56.34649830",
        longitude: "21.06549300",
        nameNative: null,
        label: "Nīca Municipality",
        cityCode: "LV_066",
      },
      {
        name: "Ogre Municipality",
        countryCode: "LV",
        latitude: "56.81473550",
        longitude: "24.60445550",
        nameNative: null,
        label: "Ogre Municipality",
        cityCode: "LV_067",
      },
      {
        name: "Olaine Municipality",
        countryCode: "LV",
        latitude: "56.79523530",
        longitude: "24.01535890",
        nameNative: null,
        label: "Olaine Municipality",
        cityCode: "LV_068",
      },
      {
        name: "Ozolnieki Municipality",
        countryCode: "LV",
        latitude: "56.67563050",
        longitude: "23.89948160",
        nameNative: null,
        label: "Ozolnieki Municipality",
        cityCode: "LV_069",
      },
      {
        name: "Preiļi Municipality",
        countryCode: "LV",
        latitude: "56.15111570",
        longitude: "26.74397670",
        nameNative: null,
        label: "Preiļi Municipality",
        cityCode: "LV_073",
      },
      {
        name: "Priekule Municipality",
        countryCode: "LV",
        latitude: "56.41794130",
        longitude: "21.55033360",
        nameNative: null,
        label: "Priekule Municipality",
        cityCode: "LV_074",
      },
      {
        name: "Priekuļi Municipality",
        countryCode: "LV",
        latitude: "57.36171380",
        longitude: "25.44104230",
        nameNative: null,
        label: "Priekuļi Municipality",
        cityCode: "LV_075",
      },
      {
        name: "Pārgauja Municipality",
        countryCode: "LV",
        latitude: "57.36481220",
        longitude: "24.98220450",
        nameNative: null,
        label: "Pārgauja Municipality",
        cityCode: "LV_070",
      },
      {
        name: "Pāvilosta Municipality",
        countryCode: "LV",
        latitude: "56.88654240",
        longitude: "21.19468490",
        nameNative: null,
        label: "Pāvilosta Municipality",
        cityCode: "LV_071",
      },
      {
        name: "Pļaviņas Municipality",
        countryCode: "LV",
        latitude: "56.61773130",
        longitude: "25.71940430",
        nameNative: null,
        label: "Pļaviņas Municipality",
        cityCode: "LV_072",
      },
      {
        name: "Rauna Municipality",
        countryCode: "LV",
        latitude: "57.33169300",
        longitude: "25.61003390",
        nameNative: null,
        label: "Rauna Municipality",
        cityCode: "LV_076",
      },
      {
        name: "Riebiņi Municipality",
        countryCode: "LV",
        latitude: "56.34361900",
        longitude: "26.80181380",
        nameNative: null,
        label: "Riebiņi Municipality",
        cityCode: "LV_078",
      },
      {
        name: "Riga",
        countryCode: "LV",
        latitude: "56.94964870",
        longitude: "24.10518650",
        nameNative: null,
        label: "Riga",
        cityCode: "LV_RIX",
      },
      {
        name: "Roja Municipality",
        countryCode: "LV",
        latitude: "57.50467130",
        longitude: "22.80121640",
        nameNative: null,
        label: "Roja Municipality",
        cityCode: "LV_079",
      },
      {
        name: "Ropaži Municipality",
        countryCode: "LV",
        latitude: "56.96157860",
        longitude: "24.60104760",
        nameNative: null,
        label: "Ropaži Municipality",
        cityCode: "LV_080",
      },
      {
        name: "Rucava Municipality",
        countryCode: "LV",
        latitude: "56.15931240",
        longitude: "21.16181210",
        nameNative: null,
        label: "Rucava Municipality",
        cityCode: "LV_081",
      },
      {
        name: "Rugāji Municipality",
        countryCode: "LV",
        latitude: "57.00560230",
        longitude: "27.13172030",
        nameNative: null,
        label: "Rugāji Municipality",
        cityCode: "LV_082",
      },
      {
        name: "Rundāle Municipality",
        countryCode: "LV",
        latitude: "56.40972100",
        longitude: "24.01241390",
        nameNative: null,
        label: "Rundāle Municipality",
        cityCode: "LV_083",
      },
      {
        name: "Rēzekne Municipality",
        countryCode: "LV",
        latitude: "56.32736380",
        longitude: "27.32843310",
        nameNative: null,
        label: "Rēzekne Municipality",
        cityCode: "LV_077",
      },
      {
        name: "Rēzekne",
        countryCode: "LV",
        latitude: "56.50992230",
        longitude: "27.33313570",
        nameNative: null,
        label: "Rēzekne",
        cityCode: "LV_REZ",
      },
      {
        name: "Rūjiena Municipality",
        countryCode: "LV",
        latitude: "57.89372910",
        longitude: "25.33910080",
        nameNative: null,
        label: "Rūjiena Municipality",
        cityCode: "LV_084",
      },
      {
        name: "Sala Municipality",
        countryCode: "LV",
        latitude: "59.96796130",
        longitude: "16.49782170",
        nameNative: null,
        label: "Sala Municipality",
        cityCode: "LV_085",
      },
      {
        name: "Salacgrīva Municipality",
        countryCode: "LV",
        latitude: "57.75808830",
        longitude: "24.35431810",
        nameNative: null,
        label: "Salacgrīva Municipality",
        cityCode: "LV_086",
      },
      {
        name: "Salaspils Municipality",
        countryCode: "LV",
        latitude: "56.86081520",
        longitude: "24.34978810",
        nameNative: null,
        label: "Salaspils Municipality",
        cityCode: "LV_087",
      },
      {
        name: "Saldus Municipality",
        countryCode: "LV",
        latitude: "56.66650880",
        longitude: "22.49354930",
        nameNative: null,
        label: "Saldus Municipality",
        cityCode: "LV_088",
      },
      {
        name: "Saulkrasti Municipality",
        countryCode: "LV",
        latitude: "57.25794180",
        longitude: "24.41831460",
        nameNative: null,
        label: "Saulkrasti Municipality",
        cityCode: "LV_089",
      },
      {
        name: "Sigulda Municipality",
        countryCode: "LV",
        latitude: "57.10550920",
        longitude: "24.83142590",
        nameNative: null,
        label: "Sigulda Municipality",
        cityCode: "LV_091",
      },
      {
        name: "Skrunda Municipality",
        countryCode: "LV",
        latitude: "56.66434580",
        longitude: "22.00457290",
        nameNative: null,
        label: "Skrunda Municipality",
        cityCode: "LV_093",
      },
      {
        name: "Skrīveri Municipality",
        countryCode: "LV",
        latitude: "56.67613910",
        longitude: "25.09788490",
        nameNative: null,
        label: "Skrīveri Municipality",
        cityCode: "LV_092",
      },
      {
        name: "Smiltene Municipality",
        countryCode: "LV",
        latitude: "57.42303320",
        longitude: "25.90027800",
        nameNative: null,
        label: "Smiltene Municipality",
        cityCode: "LV_094",
      },
      {
        name: "Stopiņi Municipality",
        countryCode: "LV",
        latitude: "56.93644900",
        longitude: "24.28729490",
        nameNative: null,
        label: "Stopiņi Municipality",
        cityCode: "LV_095",
      },
      {
        name: "Strenči Municipality",
        countryCode: "LV",
        latitude: "57.62254710",
        longitude: "25.80480860",
        nameNative: null,
        label: "Strenči Municipality",
        cityCode: "LV_096",
      },
      {
        name: "Sēja Municipality",
        countryCode: "LV",
        latitude: "57.20069950",
        longitude: "24.59228210",
        nameNative: null,
        label: "Sēja Municipality",
        cityCode: "LV_090",
      },
      {
        name: "Talsi Municipality",
        countryCode: "LV",
        latitude: "57.34152080",
        longitude: "22.57131250",
        nameNative: null,
        label: "Talsi Municipality",
        cityCode: "LV_097",
      },
      {
        name: "Tukums Municipality",
        countryCode: "LV",
        latitude: "56.96728680",
        longitude: "23.15243790",
        nameNative: null,
        label: "Tukums Municipality",
        cityCode: "LV_099",
      },
      {
        name: "Tērvete Municipality",
        countryCode: "LV",
        latitude: "56.41192010",
        longitude: "23.31883320",
        nameNative: null,
        label: "Tērvete Municipality",
        cityCode: "LV_098",
      },
      {
        name: "Vaiņode Municipality",
        countryCode: "LV",
        latitude: "56.41542710",
        longitude: "21.85139840",
        nameNative: null,
        label: "Vaiņode Municipality",
        cityCode: "LV_100",
      },
      {
        name: "Valka Municipality",
        countryCode: "LV",
        latitude: "57.77439000",
        longitude: "26.01700500",
        nameNative: null,
        label: "Valka Municipality",
        cityCode: "LV_101",
      },
      {
        name: "Valmiera",
        countryCode: "LV",
        latitude: "57.53846590",
        longitude: "25.42636180",
        nameNative: null,
        label: "Valmiera",
        cityCode: "LV_VMR",
      },
      {
        name: "Varakļāni Municipality",
        countryCode: "LV",
        latitude: "56.66880060",
        longitude: "26.56364140",
        nameNative: null,
        label: "Varakļāni Municipality",
        cityCode: "LV_102",
      },
      {
        name: "Vecpiebalga Municipality",
        countryCode: "LV",
        latitude: "57.06033560",
        longitude: "25.81615920",
        nameNative: null,
        label: "Vecpiebalga Municipality",
        cityCode: "LV_104",
      },
      {
        name: "Vecumnieki Municipality",
        countryCode: "LV",
        latitude: "56.60623370",
        longitude: "24.52218910",
        nameNative: null,
        label: "Vecumnieki Municipality",
        cityCode: "LV_105",
      },
      {
        name: "Ventspils Municipality",
        countryCode: "LV",
        latitude: "57.28336820",
        longitude: "21.85875580",
        nameNative: null,
        label: "Ventspils Municipality",
        cityCode: "LV_106",
      },
      {
        name: "Ventspils",
        countryCode: "LV",
        latitude: "57.39372160",
        longitude: "21.56470660",
        nameNative: null,
        label: "Ventspils",
        cityCode: "LV_VEN",
      },
      {
        name: "Viesīte Municipality",
        countryCode: "LV",
        latitude: "56.31130850",
        longitude: "25.50704640",
        nameNative: null,
        label: "Viesīte Municipality",
        cityCode: "LV_107",
      },
      {
        name: "Viļaka Municipality",
        countryCode: "LV",
        latitude: "57.17222630",
        longitude: "27.66731880",
        nameNative: null,
        label: "Viļaka Municipality",
        cityCode: "LV_108",
      },
      {
        name: "Viļāni Municipality",
        countryCode: "LV",
        latitude: "56.54561710",
        longitude: "26.91679270",
        nameNative: null,
        label: "Viļāni Municipality",
        cityCode: "LV_109",
      },
      {
        name: "Vārkava Municipality",
        countryCode: "LV",
        latitude: "56.24657440",
        longitude: "26.56643710",
        nameNative: null,
        label: "Vārkava Municipality",
        cityCode: "LV_103",
      },
      {
        name: "Zilupe Municipality",
        countryCode: "LV",
        latitude: "56.30189850",
        longitude: "28.13395900",
        nameNative: null,
        label: "Zilupe Municipality",
        cityCode: "LV_110",
      },
      {
        name: "Ērgļi Municipality",
        countryCode: "LV",
        latitude: "56.92370650",
        longitude: "25.67538520",
        nameNative: null,
        label: "Ērgļi Municipality",
        cityCode: "LV_030",
      },
      {
        name: "Ķegums Municipality",
        countryCode: "LV",
        latitude: "56.74753570",
        longitude: "24.71736450",
        nameNative: null,
        label: "Ķegums Municipality",
        cityCode: "LV_051",
      },
      {
        name: "Ķekava Municipality",
        countryCode: "LV",
        latitude: "56.80643510",
        longitude: "24.19394930",
        nameNative: null,
        label: "Ķekava Municipality",
        cityCode: "LV_052",
      },
    ],
    LB: [
      {
        name: "Akkar Governorate",
        countryCode: "LB",
        latitude: "34.53287630",
        longitude: "36.13281320",
        nameNative: null,
        label: "Akkar Governorate",
        cityCode: "LB_AK",
      },
      {
        name: "Baalbek-Hermel Governorate",
        countryCode: "LB",
        latitude: "34.26585560",
        longitude: "36.34980970",
        nameNative: null,
        label: "Baalbek-Hermel Governorate",
        cityCode: "LB_BH",
      },
      {
        name: "Beirut Governorate",
        countryCode: "LB",
        latitude: "33.88861060",
        longitude: "35.49547720",
        nameNative: null,
        label: "Beirut Governorate",
        cityCode: "LB_BA",
      },
      {
        name: "Beqaa Governorate",
        countryCode: "LB",
        latitude: "33.84626620",
        longitude: "35.90194890",
        nameNative: null,
        label: "Beqaa Governorate",
        cityCode: "LB_BI",
      },
      {
        name: "Mount Lebanon Governorate",
        countryCode: "LB",
        latitude: "33.81008580",
        longitude: "35.59731390",
        nameNative: null,
        label: "Mount Lebanon Governorate",
        cityCode: "LB_JL",
      },
      {
        name: "Nabatieh Governorate",
        countryCode: "LB",
        latitude: "33.37716930",
        longitude: "35.48382930",
        nameNative: null,
        label: "Nabatieh Governorate",
        cityCode: "LB_NA",
      },
      {
        name: "North Governorate",
        countryCode: "LB",
        latitude: "34.43806250",
        longitude: "35.83082330",
        nameNative: null,
        label: "North Governorate",
        cityCode: "LB_AS",
      },
      {
        name: "South Governorate",
        countryCode: "LB",
        latitude: "33.27214790",
        longitude: "35.20327780",
        nameNative: null,
        label: "South Governorate",
        cityCode: "LB_JA",
      },
    ],
    LS: [
      {
        name: "Berea District",
        countryCode: "LS",
        latitude: "41.36616140",
        longitude: "-81.85430260",
        nameNative: null,
        label: "Berea District",
        cityCode: "LS_D",
      },
      {
        name: "Butha-Buthe District",
        countryCode: "LS",
        latitude: "-28.76537540",
        longitude: "28.24681480",
        nameNative: null,
        label: "Butha-Buthe District",
        cityCode: "LS_B",
      },
      {
        name: "Leribe District",
        countryCode: "LS",
        latitude: "-28.86380650",
        longitude: "28.04788260",
        nameNative: null,
        label: "Leribe District",
        cityCode: "LS_C",
      },
      {
        name: "Mafeteng District",
        countryCode: "LS",
        latitude: "-29.80410080",
        longitude: "27.50261740",
        nameNative: null,
        label: "Mafeteng District",
        cityCode: "LS_E",
      },
      {
        name: "Maseru District",
        countryCode: "LS",
        latitude: "-29.51656500",
        longitude: "27.83114280",
        nameNative: null,
        label: "Maseru District",
        cityCode: "LS_A",
      },
      {
        name: "Mohale's Hoek District",
        countryCode: "LS",
        latitude: "-30.14259170",
        longitude: "27.46738450",
        nameNative: null,
        label: "Mohale's Hoek District",
        cityCode: "LS_F",
      },
      {
        name: "Mokhotlong District",
        countryCode: "LS",
        latitude: "-29.25731930",
        longitude: "28.95286450",
        nameNative: null,
        label: "Mokhotlong District",
        cityCode: "LS_J",
      },
      {
        name: "Qacha's Nek District",
        countryCode: "LS",
        latitude: "-30.11145650",
        longitude: "28.67897900",
        nameNative: null,
        label: "Qacha's Nek District",
        cityCode: "LS_H",
      },
      {
        name: "Quthing District",
        countryCode: "LS",
        latitude: "-30.40156870",
        longitude: "27.70801330",
        nameNative: null,
        label: "Quthing District",
        cityCode: "LS_G",
      },
      {
        name: "Thaba-Tseka District",
        countryCode: "LS",
        latitude: "-29.52389750",
        longitude: "28.60897520",
        nameNative: null,
        label: "Thaba-Tseka District",
        cityCode: "LS_K",
      },
    ],
    LR: [
      {
        name: "Bomi County",
        countryCode: "LR",
        latitude: "6.75629260",
        longitude: "-10.84514670",
        nameNative: null,
        label: "Bomi County",
        cityCode: "LR_BM",
      },
      {
        name: "Bong County",
        countryCode: "LR",
        latitude: "6.82950190",
        longitude: "-9.36730840",
        nameNative: null,
        label: "Bong County",
        cityCode: "LR_BG",
      },
      {
        name: "Gbarpolu County",
        countryCode: "LR",
        latitude: "7.49526370",
        longitude: "-10.08072980",
        nameNative: null,
        label: "Gbarpolu County",
        cityCode: "LR_GP",
      },
      {
        name: "Grand Bassa County",
        countryCode: "LR",
        latitude: "6.23084520",
        longitude: "-9.81249350",
        nameNative: null,
        label: "Grand Bassa County",
        cityCode: "LR_GB",
      },
      {
        name: "Grand Cape Mount County",
        countryCode: "LR",
        latitude: "7.04677580",
        longitude: "-11.07117580",
        nameNative: null,
        label: "Grand Cape Mount County",
        cityCode: "LR_CM",
      },
      {
        name: "Grand Gedeh County",
        countryCode: "LR",
        latitude: "5.92220780",
        longitude: "-8.22129790",
        nameNative: null,
        label: "Grand Gedeh County",
        cityCode: "LR_GG",
      },
      {
        name: "Grand Kru County",
        countryCode: "LR",
        latitude: "4.76138620",
        longitude: "-8.22129790",
        nameNative: null,
        label: "Grand Kru County",
        cityCode: "LR_GK",
      },
      {
        name: "Lofa County",
        countryCode: "LR",
        latitude: "8.19111840",
        longitude: "-9.72326730",
        nameNative: null,
        label: "Lofa County",
        cityCode: "LR_LO",
      },
      {
        name: "Margibi County",
        countryCode: "LR",
        latitude: "6.51518750",
        longitude: "-10.30488970",
        nameNative: null,
        label: "Margibi County",
        cityCode: "LR_MG",
      },
      {
        name: "Maryland County",
        countryCode: "LR",
        latitude: "39.04575490",
        longitude: "-76.64127120",
        nameNative: null,
        label: "Maryland County",
        cityCode: "LR_MY",
      },
      {
        name: "Montserrado County",
        countryCode: "LR",
        latitude: "6.55258150",
        longitude: "-10.52961150",
        nameNative: null,
        label: "Montserrado County",
        cityCode: "LR_MO",
      },
      {
        name: "Nimba",
        countryCode: "LR",
        latitude: "7.61666670",
        longitude: "-8.41666670",
        nameNative: null,
        label: "Nimba",
        cityCode: "LR_NI",
      },
      {
        name: "River Cess County",
        countryCode: "LR",
        latitude: "5.90253280",
        longitude: "-9.45615500",
        nameNative: null,
        label: "River Cess County",
        cityCode: "LR_RI",
      },
      {
        name: "River Gee County",
        countryCode: "LR",
        latitude: "5.26048940",
        longitude: "-7.87216000",
        nameNative: null,
        label: "River Gee County",
        cityCode: "LR_RG",
      },
      {
        name: "Sinoe County",
        countryCode: "LR",
        latitude: "5.49871000",
        longitude: "-8.66005860",
        nameNative: null,
        label: "Sinoe County",
        cityCode: "LR_SI",
      },
    ],
    LY: [
      {
        name: "Al Wahat District",
        countryCode: "LY",
        latitude: "29.04668080",
        longitude: "21.85685860",
        nameNative: null,
        label: "Al Wahat District",
        cityCode: "LY_WA",
      },
      {
        name: "Benghazi",
        countryCode: "LY",
        latitude: "32.11942420",
        longitude: "20.08679090",
        nameNative: null,
        label: "Benghazi",
        cityCode: "LY_BA",
      },
      {
        name: "Derna District",
        countryCode: "LY",
        latitude: "32.75561300",
        longitude: "22.63774320",
        nameNative: null,
        label: "Derna District",
        cityCode: "LY_DR",
      },
      {
        name: "Ghat District",
        countryCode: "LY",
        latitude: "24.96403710",
        longitude: "10.17592850",
        nameNative: null,
        label: "Ghat District",
        cityCode: "LY_GT",
      },
      {
        name: "Jabal al Akhdar",
        countryCode: "LY",
        latitude: "23.18560810",
        longitude: "57.37138790",
        nameNative: null,
        label: "Jabal al Akhdar",
        cityCode: "LY_JA",
      },
      {
        name: "Jabal al Gharbi District",
        countryCode: "LY",
        latitude: "30.26380320",
        longitude: "12.80547530",
        nameNative: null,
        label: "Jabal al Gharbi District",
        cityCode: "LY_JG",
      },
      {
        name: "Jafara",
        countryCode: "LY",
        latitude: "32.45259040",
        longitude: "12.94355360",
        nameNative: null,
        label: "Jafara",
        cityCode: "LY_JI",
      },
      {
        name: "Jufra",
        countryCode: "LY",
        latitude: "27.98351350",
        longitude: "16.91225100",
        nameNative: null,
        label: "Jufra",
        cityCode: "LY_JU",
      },
      {
        name: "Kufra District",
        countryCode: "LY",
        latitude: "23.31123890",
        longitude: "21.85685860",
        nameNative: null,
        label: "Kufra District",
        cityCode: "LY_KF",
      },
      {
        name: "Marj District",
        countryCode: "LY",
        latitude: "32.05503630",
        longitude: "21.18911510",
        nameNative: null,
        label: "Marj District",
        cityCode: "LY_MJ",
      },
      {
        name: "Misrata District",
        countryCode: "LY",
        latitude: "32.32558840",
        longitude: "15.09925560",
        nameNative: null,
        label: "Misrata District",
        cityCode: "LY_MI",
      },
      {
        name: "Murqub",
        countryCode: "LY",
        latitude: "32.45996770",
        longitude: "14.10013260",
        nameNative: null,
        label: "Murqub",
        cityCode: "LY_MB",
      },
      {
        name: "Murzuq District",
        countryCode: "LY",
        latitude: "25.91822620",
        longitude: "13.92600010",
        nameNative: null,
        label: "Murzuq District",
        cityCode: "LY_MQ",
      },
      {
        name: "Nalut District",
        countryCode: "LY",
        latitude: "31.87423480",
        longitude: "10.97504840",
        nameNative: null,
        label: "Nalut District",
        cityCode: "LY_NL",
      },
      {
        name: "Nuqat al Khams",
        countryCode: "LY",
        latitude: "32.69149090",
        longitude: "11.88917210",
        nameNative: null,
        label: "Nuqat al Khams",
        cityCode: "LY_NQ",
      },
      {
        name: "Sabha District",
        countryCode: "LY",
        latitude: "27.03654060",
        longitude: "14.42902360",
        nameNative: null,
        label: "Sabha District",
        cityCode: "LY_SB",
      },
      {
        name: "Sirte District",
        countryCode: "LY",
        latitude: "31.18968900",
        longitude: "16.57019270",
        nameNative: null,
        label: "Sirte District",
        cityCode: "LY_SR",
      },
      {
        name: "Tripoli District",
        countryCode: "LY",
        latitude: "32.64080210",
        longitude: "13.26634790",
        nameNative: null,
        label: "Tripoli District",
        cityCode: "LY_TB",
      },
      {
        name: "Wadi al Hayaa District",
        countryCode: "LY",
        latitude: "26.42259260",
        longitude: "12.62162110",
        nameNative: null,
        label: "Wadi al Hayaa District",
        cityCode: "LY_WD",
      },
      {
        name: "Wadi al Shatii District",
        countryCode: "LY",
        latitude: "27.73514680",
        longitude: "12.43805810",
        nameNative: null,
        label: "Wadi al Shatii District",
        cityCode: "LY_WS",
      },
      {
        name: "Zawiya District",
        countryCode: "LY",
        latitude: "32.76302820",
        longitude: "12.73649620",
        nameNative: null,
        label: "Zawiya District",
        cityCode: "LY_ZA",
      },
    ],
    LI: [
      {
        name: "Balzers",
        countryCode: "LI",
        latitude: "42.05283570",
        longitude: "-88.03668480",
        nameNative: null,
        label: "Balzers",
        cityCode: "LI_01",
      },
      {
        name: "Eschen",
        countryCode: "LI",
        latitude: "40.76695740",
        longitude: "-73.95228210",
        nameNative: null,
        label: "Eschen",
        cityCode: "LI_02",
      },
      {
        name: "Gamprin",
        countryCode: "LI",
        latitude: "47.21324900",
        longitude: "9.50251950",
        nameNative: null,
        label: "Gamprin",
        cityCode: "LI_03",
      },
      {
        name: "Mauren",
        countryCode: "LI",
        latitude: "47.21892850",
        longitude: "9.54173500",
        nameNative: null,
        label: "Mauren",
        cityCode: "LI_04",
      },
      {
        name: "Planken",
        countryCode: "LI",
        latitude: "40.66505760",
        longitude: "-73.50479800",
        nameNative: null,
        label: "Planken",
        cityCode: "LI_05",
      },
      {
        name: "Ruggell",
        countryCode: "LI",
        latitude: "47.25292220",
        longitude: "9.54021270",
        nameNative: null,
        label: "Ruggell",
        cityCode: "LI_06",
      },
      {
        name: "Schaan",
        countryCode: "LI",
        latitude: "47.12043400",
        longitude: "9.59416020",
        nameNative: null,
        label: "Schaan",
        cityCode: "LI_07",
      },
      {
        name: "Schellenberg",
        countryCode: "LI",
        latitude: "47.23096600",
        longitude: "9.54678430",
        nameNative: null,
        label: "Schellenberg",
        cityCode: "LI_08",
      },
      {
        name: "Triesen",
        countryCode: "LI",
        latitude: "47.10979880",
        longitude: "9.52482960",
        nameNative: null,
        label: "Triesen",
        cityCode: "LI_09",
      },
      {
        name: "Triesenberg",
        countryCode: "LI",
        latitude: "47.12245110",
        longitude: "9.57019850",
        nameNative: null,
        label: "Triesenberg",
        cityCode: "LI_10",
      },
      {
        name: "Vaduz",
        countryCode: "LI",
        latitude: "47.14103030",
        longitude: "9.52092770",
        nameNative: null,
        label: "Vaduz",
        cityCode: "LI_11",
      },
    ],
    LT: [
      {
        name: "Akmenė District Municipality",
        countryCode: "LT",
        latitude: "56.24550290",
        longitude: "22.74711690",
        nameNative: null,
        label: "Akmenė District Municipality",
        cityCode: "LT_01",
      },
      {
        name: "Alytus City Municipality",
        countryCode: "LT",
        latitude: "54.39629380",
        longitude: "24.04587610",
        nameNative: null,
        label: "Alytus City Municipality",
        cityCode: "LT_02",
      },
      {
        name: "Alytus County",
        countryCode: "LT",
        latitude: "54.20002140",
        longitude: "24.15126340",
        nameNative: null,
        label: "Alytus County",
        cityCode: "LT_AL",
      },
      {
        name: "Alytus District Municipality",
        countryCode: "LT",
        latitude: "54.32974960",
        longitude: "24.19609310",
        nameNative: null,
        label: "Alytus District Municipality",
        cityCode: "LT_03",
      },
      {
        name: "Birštonas Municipality",
        countryCode: "LT",
        latitude: "54.56696640",
        longitude: "24.00930980",
        nameNative: null,
        label: "Birštonas Municipality",
        cityCode: "LT_05",
      },
      {
        name: "Biržai District Municipality",
        countryCode: "LT",
        latitude: "56.20177190",
        longitude: "24.75601180",
        nameNative: null,
        label: "Biržai District Municipality",
        cityCode: "LT_06",
      },
      {
        name: "Druskininkai municipality",
        countryCode: "LT",
        latitude: "53.99336850",
        longitude: "24.03424380",
        nameNative: null,
        label: "Druskininkai municipality",
        cityCode: "LT_07",
      },
      {
        name: "Elektrėnai municipality",
        countryCode: "LT",
        latitude: "54.76539340",
        longitude: "24.77405830",
        nameNative: null,
        label: "Elektrėnai municipality",
        cityCode: "LT_08",
      },
      {
        name: "Ignalina District Municipality",
        countryCode: "LT",
        latitude: "55.40903420",
        longitude: "26.32848930",
        nameNative: null,
        label: "Ignalina District Municipality",
        cityCode: "LT_09",
      },
      {
        name: "Jonava District Municipality",
        countryCode: "LT",
        latitude: "55.07272420",
        longitude: "24.27933370",
        nameNative: null,
        label: "Jonava District Municipality",
        cityCode: "LT_10",
      },
      {
        name: "Joniškis District Municipality",
        countryCode: "LT",
        latitude: "56.23607300",
        longitude: "23.61365790",
        nameNative: null,
        label: "Joniškis District Municipality",
        cityCode: "LT_11",
      },
      {
        name: "Jurbarkas District Municipality",
        countryCode: "LT",
        latitude: "55.07740700",
        longitude: "22.74195690",
        nameNative: null,
        label: "Jurbarkas District Municipality",
        cityCode: "LT_12",
      },
      {
        name: "Kaišiadorys District Municipality",
        countryCode: "LT",
        latitude: "54.85886690",
        longitude: "24.42779290",
        nameNative: null,
        label: "Kaišiadorys District Municipality",
        cityCode: "LT_13",
      },
      {
        name: "Kalvarija municipality",
        countryCode: "LT",
        latitude: "54.37616740",
        longitude: "23.19203210",
        nameNative: null,
        label: "Kalvarija municipality",
        cityCode: "LT_14",
      },
      {
        name: "Kaunas City Municipality",
        countryCode: "LT",
        latitude: "54.91453260",
        longitude: "23.90535180",
        nameNative: null,
        label: "Kaunas City Municipality",
        cityCode: "LT_15",
      },
      {
        name: "Kaunas County",
        countryCode: "LT",
        latitude: "54.98728630",
        longitude: "23.95257360",
        nameNative: null,
        label: "Kaunas County",
        cityCode: "LT_KU",
      },
      {
        name: "Kaunas District Municipality",
        countryCode: "LT",
        latitude: "54.99362360",
        longitude: "23.63249410",
        nameNative: null,
        label: "Kaunas District Municipality",
        cityCode: "LT_16",
      },
      {
        name: "Kazlų Rūda municipality",
        countryCode: "LT",
        latitude: "54.78075260",
        longitude: "23.48402430",
        nameNative: null,
        label: "Kazlų Rūda municipality",
        cityCode: "LT_17",
      },
      {
        name: "Kelmė District Municipality",
        countryCode: "LT",
        latitude: "55.62663520",
        longitude: "22.87817200",
        nameNative: null,
        label: "Kelmė District Municipality",
        cityCode: "LT_19",
      },
      {
        name: "Klaipeda City Municipality",
        countryCode: "LT",
        latitude: "55.70329480",
        longitude: "21.14427950",
        nameNative: null,
        label: "Klaipeda City Municipality",
        cityCode: "LT_20",
      },
      {
        name: "Klaipėda County",
        countryCode: "LT",
        latitude: "55.65197440",
        longitude: "21.37439560",
        nameNative: null,
        label: "Klaipėda County",
        cityCode: "LT_KL",
      },
      {
        name: "Klaipėda District Municipality",
        countryCode: "LT",
        latitude: "55.68416150",
        longitude: "21.44164640",
        nameNative: null,
        label: "Klaipėda District Municipality",
        cityCode: "LT_21",
      },
      {
        name: "Kretinga District Municipality",
        countryCode: "LT",
        latitude: "55.88384200",
        longitude: "21.23509190",
        nameNative: null,
        label: "Kretinga District Municipality",
        cityCode: "LT_22",
      },
      {
        name: "Kupiškis District Municipality",
        countryCode: "LT",
        latitude: "55.84287410",
        longitude: "25.02958160",
        nameNative: null,
        label: "Kupiškis District Municipality",
        cityCode: "LT_23",
      },
      {
        name: "Kėdainiai District Municipality",
        countryCode: "LT",
        latitude: "55.35609470",
        longitude: "23.98326830",
        nameNative: null,
        label: "Kėdainiai District Municipality",
        cityCode: "LT_18",
      },
      {
        name: "Lazdijai District Municipality",
        countryCode: "LT",
        latitude: "54.23432670",
        longitude: "23.51565050",
        nameNative: null,
        label: "Lazdijai District Municipality",
        cityCode: "LT_24",
      },
      {
        name: "Marijampolė County",
        countryCode: "LT",
        latitude: "54.78199710",
        longitude: "23.13413650",
        nameNative: null,
        label: "Marijampolė County",
        cityCode: "LT_MR",
      },
      {
        name: "Marijampolė Municipality",
        countryCode: "LT",
        latitude: "54.57110940",
        longitude: "23.48593710",
        nameNative: null,
        label: "Marijampolė Municipality",
        cityCode: "LT_25",
      },
      {
        name: "Mažeikiai District Municipality",
        countryCode: "LT",
        latitude: "56.30924390",
        longitude: "22.34146800",
        nameNative: null,
        label: "Mažeikiai District Municipality",
        cityCode: "LT_26",
      },
      {
        name: "Molėtai District Municipality",
        countryCode: "LT",
        latitude: "55.22653090",
        longitude: "25.41800110",
        nameNative: null,
        label: "Molėtai District Municipality",
        cityCode: "LT_27",
      },
      {
        name: "Neringa Municipality",
        countryCode: "LT",
        latitude: "55.45724030",
        longitude: "21.08390050",
        nameNative: null,
        label: "Neringa Municipality",
        cityCode: "LT_28",
      },
      {
        name: "Pagėgiai municipality",
        countryCode: "LT",
        latitude: "55.17213200",
        longitude: "21.96836140",
        nameNative: null,
        label: "Pagėgiai municipality",
        cityCode: "LT_29",
      },
      {
        name: "Pakruojis District Municipality",
        countryCode: "LT",
        latitude: "56.07326050",
        longitude: "23.93899060",
        nameNative: null,
        label: "Pakruojis District Municipality",
        cityCode: "LT_30",
      },
      {
        name: "Palanga City Municipality",
        countryCode: "LT",
        latitude: "55.92019800",
        longitude: "21.06776140",
        nameNative: null,
        label: "Palanga City Municipality",
        cityCode: "LT_31",
      },
      {
        name: "Panevėžys City Municipality",
        countryCode: "LT",
        latitude: "55.73479150",
        longitude: "24.35747740",
        nameNative: null,
        label: "Panevėžys City Municipality",
        cityCode: "LT_32",
      },
      {
        name: "Panevėžys County",
        countryCode: "LT",
        latitude: "55.97480490",
        longitude: "25.07947670",
        nameNative: null,
        label: "Panevėžys County",
        cityCode: "LT_PN",
      },
      {
        name: "Panevėžys District Municipality",
        countryCode: "LT",
        latitude: "55.61667280",
        longitude: "24.31422830",
        nameNative: null,
        label: "Panevėžys District Municipality",
        cityCode: "LT_33",
      },
      {
        name: "Pasvalys District Municipality",
        countryCode: "LT",
        latitude: "56.06046190",
        longitude: "24.39629100",
        nameNative: null,
        label: "Pasvalys District Municipality",
        cityCode: "LT_34",
      },
      {
        name: "Plungė District Municipality",
        countryCode: "LT",
        latitude: "55.91078400",
        longitude: "21.84540690",
        nameNative: null,
        label: "Plungė District Municipality",
        cityCode: "LT_35",
      },
      {
        name: "Prienai District Municipality",
        countryCode: "LT",
        latitude: "54.63835800",
        longitude: "23.94680090",
        nameNative: null,
        label: "Prienai District Municipality",
        cityCode: "LT_36",
      },
      {
        name: "Radviliškis District Municipality",
        countryCode: "LT",
        latitude: "55.81083990",
        longitude: "23.54648700",
        nameNative: null,
        label: "Radviliškis District Municipality",
        cityCode: "LT_37",
      },
      {
        name: "Raseiniai District Municipality",
        countryCode: "LT",
        latitude: "55.38194990",
        longitude: "23.11561290",
        nameNative: null,
        label: "Raseiniai District Municipality",
        cityCode: "LT_38",
      },
      {
        name: "Rietavas municipality",
        countryCode: "LT",
        latitude: "55.70217190",
        longitude: "21.99865640",
        nameNative: null,
        label: "Rietavas municipality",
        cityCode: "LT_39",
      },
      {
        name: "Rokiškis District Municipality",
        countryCode: "LT",
        latitude: "55.95550390",
        longitude: "25.58592490",
        nameNative: null,
        label: "Rokiškis District Municipality",
        cityCode: "LT_40",
      },
      {
        name: "Skuodas District Municipality",
        countryCode: "LT",
        latitude: "56.27021690",
        longitude: "21.52143310",
        nameNative: null,
        label: "Skuodas District Municipality",
        cityCode: "LT_48",
      },
      {
        name: "Tauragė County",
        countryCode: "LT",
        latitude: "55.30725860",
        longitude: "22.35729390",
        nameNative: null,
        label: "Tauragė County",
        cityCode: "LT_TA",
      },
      {
        name: "Tauragė District Municipality",
        countryCode: "LT",
        latitude: "55.25036600",
        longitude: "22.29095000",
        nameNative: null,
        label: "Tauragė District Municipality",
        cityCode: "LT_50",
      },
      {
        name: "Telšiai County",
        countryCode: "LT",
        latitude: "56.10266160",
        longitude: "22.11139150",
        nameNative: null,
        label: "Telšiai County",
        cityCode: "LT_TE",
      },
      {
        name: "Telšiai District Municipality",
        countryCode: "LT",
        latitude: "55.91752150",
        longitude: "22.34518400",
        nameNative: null,
        label: "Telšiai District Municipality",
        cityCode: "LT_51",
      },
      {
        name: "Trakai District Municipality",
        countryCode: "LT",
        latitude: "54.63791130",
        longitude: "24.93468940",
        nameNative: null,
        label: "Trakai District Municipality",
        cityCode: "LT_52",
      },
      {
        name: "Ukmergė District Municipality",
        countryCode: "LT",
        latitude: "55.24526500",
        longitude: "24.77607490",
        nameNative: null,
        label: "Ukmergė District Municipality",
        cityCode: "LT_53",
      },
      {
        name: "Utena County",
        countryCode: "LT",
        latitude: "55.53189690",
        longitude: "25.79046990",
        nameNative: null,
        label: "Utena County",
        cityCode: "LT_UT",
      },
      {
        name: "Utena District Municipality",
        countryCode: "LT",
        latitude: "55.50846140",
        longitude: "25.68326420",
        nameNative: null,
        label: "Utena District Municipality",
        cityCode: "LT_54",
      },
      {
        name: "Varėna District Municipality",
        countryCode: "LT",
        latitude: "54.22033300",
        longitude: "24.57899700",
        nameNative: null,
        label: "Varėna District Municipality",
        cityCode: "LT_55",
      },
      {
        name: "Vilkaviškis District Municipality",
        countryCode: "LT",
        latitude: "54.65194500",
        longitude: "23.03515500",
        nameNative: null,
        label: "Vilkaviškis District Municipality",
        cityCode: "LT_56",
      },
      {
        name: "Vilnius City Municipality",
        countryCode: "LT",
        latitude: "54.67107610",
        longitude: "25.28787210",
        nameNative: null,
        label: "Vilnius City Municipality",
        cityCode: "LT_57",
      },
      {
        name: "Vilnius County",
        countryCode: "LT",
        latitude: "54.80865020",
        longitude: "25.21821390",
        nameNative: null,
        label: "Vilnius County",
        cityCode: "LT_VL",
      },
      {
        name: "Vilnius District Municipality",
        countryCode: "LT",
        latitude: "54.77325780",
        longitude: "25.58671130",
        nameNative: null,
        label: "Vilnius District Municipality",
        cityCode: "LT_58",
      },
      {
        name: "Visaginas Municipality",
        countryCode: "LT",
        latitude: "55.59411800",
        longitude: "26.43079540",
        nameNative: null,
        label: "Visaginas Municipality",
        cityCode: "LT_59",
      },
      {
        name: "Zarasai District Municipality",
        countryCode: "LT",
        latitude: "55.73096090",
        longitude: "26.24529500",
        nameNative: null,
        label: "Zarasai District Municipality",
        cityCode: "LT_60",
      },
      {
        name: "Šakiai District Municipality",
        countryCode: "LT",
        latitude: "54.95267100",
        longitude: "23.04801990",
        nameNative: null,
        label: "Šakiai District Municipality",
        cityCode: "LT_41",
      },
      {
        name: "Šalčininkai District Municipality",
        countryCode: "LT",
        latitude: "54.30976700",
        longitude: "25.38756400",
        nameNative: null,
        label: "Šalčininkai District Municipality",
        cityCode: "LT_42",
      },
      {
        name: "Šiauliai City Municipality",
        countryCode: "LT",
        latitude: "55.93490850",
        longitude: "23.31368230",
        nameNative: null,
        label: "Šiauliai City Municipality",
        cityCode: "LT_43",
      },
      {
        name: "Šiauliai County",
        countryCode: "LT",
        latitude: "55.99857510",
        longitude: "23.13800510",
        nameNative: null,
        label: "Šiauliai County",
        cityCode: "LT_SA",
      },
      {
        name: "Šiauliai District Municipality",
        countryCode: "LT",
        latitude: "55.97214560",
        longitude: "23.03323710",
        nameNative: null,
        label: "Šiauliai District Municipality",
        cityCode: "LT_44",
      },
      {
        name: "Šilalė District Municipality",
        countryCode: "LT",
        latitude: "55.49268000",
        longitude: "22.18455590",
        nameNative: null,
        label: "Šilalė District Municipality",
        cityCode: "LT_45",
      },
      {
        name: "Šilutė District Municipality",
        countryCode: "LT",
        latitude: "55.35041400",
        longitude: "21.46598590",
        nameNative: null,
        label: "Šilutė District Municipality",
        cityCode: "LT_46",
      },
      {
        name: "Širvintos District Municipality",
        countryCode: "LT",
        latitude: "55.04310200",
        longitude: "24.95698100",
        nameNative: null,
        label: "Širvintos District Municipality",
        cityCode: "LT_47",
      },
      {
        name: "Švenčionys District Municipality",
        countryCode: "LT",
        latitude: "55.10280980",
        longitude: "26.00718550",
        nameNative: null,
        label: "Švenčionys District Municipality",
        cityCode: "LT_49",
      },
    ],
    LU: [
      {
        name: "Canton of Capellen",
        countryCode: "LU",
        latitude: "49.64039310",
        longitude: "5.95538460",
        nameNative: null,
        label: "Canton of Capellen",
        cityCode: "LU_CA",
      },
      {
        name: "Canton of Clervaux",
        countryCode: "LU",
        latitude: "50.05463130",
        longitude: "6.02858750",
        nameNative: null,
        label: "Canton of Clervaux",
        cityCode: "LU_CL",
      },
      {
        name: "Canton of Diekirch",
        countryCode: "LU",
        latitude: "49.86717840",
        longitude: "6.15956330",
        nameNative: null,
        label: "Canton of Diekirch",
        cityCode: "LU_DI",
      },
      {
        name: "Canton of Echternach",
        countryCode: "LU",
        latitude: "49.81141330",
        longitude: "6.41756350",
        nameNative: null,
        label: "Canton of Echternach",
        cityCode: "LU_EC",
      },
      {
        name: "Canton of Esch-sur-Alzette",
        countryCode: "LU",
        latitude: "49.50088050",
        longitude: "5.98609250",
        nameNative: null,
        label: "Canton of Esch-sur-Alzette",
        cityCode: "LU_ES",
      },
      {
        name: "Canton of Grevenmacher",
        countryCode: "LU",
        latitude: "49.68084100",
        longitude: "6.44075930",
        nameNative: null,
        label: "Canton of Grevenmacher",
        cityCode: "LU_GR",
      },
      {
        name: "Canton of Luxembourg",
        countryCode: "LU",
        latitude: "49.63010250",
        longitude: "6.15201850",
        nameNative: null,
        label: "Canton of Luxembourg",
        cityCode: "LU_LU",
      },
      {
        name: "Canton of Mersch",
        countryCode: "LU",
        latitude: "49.75429060",
        longitude: "6.12921850",
        nameNative: null,
        label: "Canton of Mersch",
        cityCode: "LU_ME",
      },
      {
        name: "Canton of Redange",
        countryCode: "LU",
        latitude: "49.76455000",
        longitude: "5.88948000",
        nameNative: null,
        label: "Canton of Redange",
        cityCode: "LU_RD",
      },
      {
        name: "Canton of Remich",
        countryCode: "LU",
        latitude: "49.54501700",
        longitude: "6.36742220",
        nameNative: null,
        label: "Canton of Remich",
        cityCode: "LU_RM",
      },
      {
        name: "Canton of Vianden",
        countryCode: "LU",
        latitude: "49.93419240",
        longitude: "6.20199170",
        nameNative: null,
        label: "Canton of Vianden",
        cityCode: "LU_VD",
      },
      {
        name: "Canton of Wiltz",
        countryCode: "LU",
        latitude: "49.96622000",
        longitude: "5.93243060",
        nameNative: null,
        label: "Canton of Wiltz",
        cityCode: "LU_WI",
      },
      {
        name: "Diekirch District",
        countryCode: "LU",
        latitude: "49.86717200",
        longitude: "6.15963620",
        nameNative: null,
        label: "Diekirch District",
        cityCode: "LU_D",
      },
      {
        name: "Grevenmacher District",
        countryCode: "LU",
        latitude: "49.68085100",
        longitude: "6.44075240",
        nameNative: null,
        label: "Grevenmacher District",
        cityCode: "LU_G",
      },
      {
        name: "Luxembourg District",
        countryCode: "LU",
        latitude: "49.59537060",
        longitude: "6.13331780",
        nameNative: null,
        label: "Luxembourg District",
        cityCode: "LU_L",
      },
    ],
    MK: [
      {
        name: "Aerodrom Municipality",
        countryCode: "MK",
        latitude: "41.94643630",
        longitude: "21.49317130",
        nameNative: null,
        label: "Aerodrom Municipality",
        cityCode: "MK_01",
      },
      {
        name: "Aračinovo Municipality",
        countryCode: "MK",
        latitude: "42.02473810",
        longitude: "21.57664070",
        nameNative: null,
        label: "Aračinovo Municipality",
        cityCode: "MK_02",
      },
      {
        name: "Berovo Municipality",
        countryCode: "MK",
        latitude: "41.66619290",
        longitude: "22.76288300",
        nameNative: null,
        label: "Berovo Municipality",
        cityCode: "MK_03",
      },
      {
        name: "Bitola Municipality",
        countryCode: "MK",
        latitude: "41.03633020",
        longitude: "21.33219740",
        nameNative: null,
        label: "Bitola Municipality",
        cityCode: "MK_04",
      },
      {
        name: "Bogdanci Municipality",
        countryCode: "MK",
        latitude: "41.18696160",
        longitude: "22.59602680",
        nameNative: null,
        label: "Bogdanci Municipality",
        cityCode: "MK_05",
      },
      {
        name: "Bogovinje Municipality",
        countryCode: "MK",
        latitude: "41.92363710",
        longitude: "20.91638870",
        nameNative: null,
        label: "Bogovinje Municipality",
        cityCode: "MK_06",
      },
      {
        name: "Bosilovo Municipality",
        countryCode: "MK",
        latitude: "41.49048640",
        longitude: "22.78671740",
        nameNative: null,
        label: "Bosilovo Municipality",
        cityCode: "MK_07",
      },
      {
        name: "Brvenica Municipality",
        countryCode: "MK",
        latitude: "41.96814820",
        longitude: "20.98195860",
        nameNative: null,
        label: "Brvenica Municipality",
        cityCode: "MK_08",
      },
      {
        name: "Butel Municipality",
        countryCode: "MK",
        latitude: "42.08950680",
        longitude: "21.46336100",
        nameNative: null,
        label: "Butel Municipality",
        cityCode: "MK_09",
      },
      {
        name: "Centar Municipality",
        countryCode: "MK",
        latitude: "41.96989340",
        longitude: "21.42162670",
        nameNative: null,
        label: "Centar Municipality",
        cityCode: "MK_77",
      },
      {
        name: "Centar Župa Municipality",
        countryCode: "MK",
        latitude: "41.46522590",
        longitude: "20.59305480",
        nameNative: null,
        label: "Centar Župa Municipality",
        cityCode: "MK_78",
      },
      {
        name: "Debarca Municipality",
        countryCode: "MK",
        latitude: "41.35840770",
        longitude: "20.85529190",
        nameNative: null,
        label: "Debarca Municipality",
        cityCode: "MK_22",
      },
      {
        name: "Delčevo Municipality",
        countryCode: "MK",
        latitude: "41.96843870",
        longitude: "22.76288300",
        nameNative: null,
        label: "Delčevo Municipality",
        cityCode: "MK_23",
      },
      {
        name: "Demir Hisar Municipality",
        countryCode: "MK",
        latitude: "41.22708300",
        longitude: "21.14142260",
        nameNative: null,
        label: "Demir Hisar Municipality",
        cityCode: "MK_25",
      },
      {
        name: "Demir Kapija Municipality",
        countryCode: "MK",
        latitude: "41.37955380",
        longitude: "22.21455710",
        nameNative: null,
        label: "Demir Kapija Municipality",
        cityCode: "MK_24",
      },
      {
        name: "Dojran Municipality",
        countryCode: "MK",
        latitude: "41.24366720",
        longitude: "22.69137640",
        nameNative: null,
        label: "Dojran Municipality",
        cityCode: "MK_26",
      },
      {
        name: "Dolneni Municipality",
        countryCode: "MK",
        latitude: "41.46409350",
        longitude: "21.40374070",
        nameNative: null,
        label: "Dolneni Municipality",
        cityCode: "MK_27",
      },
      {
        name: "Drugovo Municipality",
        countryCode: "MK",
        latitude: "41.44081530",
        longitude: "20.92682010",
        nameNative: null,
        label: "Drugovo Municipality",
        cityCode: "MK_28",
      },
      {
        name: "Gazi Baba Municipality",
        countryCode: "MK",
        latitude: "42.01629610",
        longitude: "21.49913340",
        nameNative: null,
        label: "Gazi Baba Municipality",
        cityCode: "MK_17",
      },
      {
        name: "Gevgelija Municipality",
        countryCode: "MK",
        latitude: "41.21186060",
        longitude: "22.38146240",
        nameNative: null,
        label: "Gevgelija Municipality",
        cityCode: "MK_18",
      },
      {
        name: "Gjorče Petrov Municipality",
        countryCode: "MK",
        latitude: "42.06063740",
        longitude: "21.32027360",
        nameNative: null,
        label: "Gjorče Petrov Municipality",
        cityCode: "MK_29",
      },
      {
        name: "Gostivar Municipality",
        countryCode: "MK",
        latitude: "41.80255410",
        longitude: "20.90893780",
        nameNative: null,
        label: "Gostivar Municipality",
        cityCode: "MK_19",
      },
      {
        name: "Gradsko Municipality",
        countryCode: "MK",
        latitude: "41.59916080",
        longitude: "21.88070640",
        nameNative: null,
        label: "Gradsko Municipality",
        cityCode: "MK_20",
      },
      {
        name: "Greater Skopje",
        countryCode: "MK",
        latitude: "41.99812940",
        longitude: "21.42543550",
        nameNative: null,
        label: "Greater Skopje",
        cityCode: "MK_85",
      },
      {
        name: "Ilinden Municipality",
        countryCode: "MK",
        latitude: "41.99574430",
        longitude: "21.56769750",
        nameNative: null,
        label: "Ilinden Municipality",
        cityCode: "MK_34",
      },
      {
        name: "Jegunovce Municipality",
        countryCode: "MK",
        latitude: "42.07407200",
        longitude: "21.12204780",
        nameNative: null,
        label: "Jegunovce Municipality",
        cityCode: "MK_35",
      },
      {
        name: "Karbinci",
        countryCode: "MK",
        latitude: "41.81801590",
        longitude: "22.23247580",
        nameNative: null,
        label: "Karbinci",
        cityCode: "MK_37",
      },
      {
        name: "Karpoš Municipality",
        countryCode: "MK",
        latitude: "41.97096610",
        longitude: "21.39181680",
        nameNative: null,
        label: "Karpoš Municipality",
        cityCode: "MK_38",
      },
      {
        name: "Kavadarci Municipality",
        countryCode: "MK",
        latitude: "41.28900680",
        longitude: "21.99994350",
        nameNative: null,
        label: "Kavadarci Municipality",
        cityCode: "MK_36",
      },
      {
        name: "Kisela Voda Municipality",
        countryCode: "MK",
        latitude: "41.92748000",
        longitude: "21.49317130",
        nameNative: null,
        label: "Kisela Voda Municipality",
        cityCode: "MK_39",
      },
      {
        name: "Kičevo Municipality",
        countryCode: "MK",
        latitude: "41.51291120",
        longitude: "20.95250650",
        nameNative: null,
        label: "Kičevo Municipality",
        cityCode: "MK_40",
      },
      {
        name: "Konče Municipality",
        countryCode: "MK",
        latitude: "41.51710110",
        longitude: "22.38146240",
        nameNative: null,
        label: "Konče Municipality",
        cityCode: "MK_41",
      },
      {
        name: "Kočani Municipality",
        countryCode: "MK",
        latitude: "41.98583740",
        longitude: "22.40530460",
        nameNative: null,
        label: "Kočani Municipality",
        cityCode: "MK_42",
      },
      {
        name: "Kratovo Municipality",
        countryCode: "MK",
        latitude: "42.05371410",
        longitude: "22.07148350",
        nameNative: null,
        label: "Kratovo Municipality",
        cityCode: "MK_43",
      },
      {
        name: "Kriva Palanka Municipality",
        countryCode: "MK",
        latitude: "42.20584540",
        longitude: "22.33079650",
        nameNative: null,
        label: "Kriva Palanka Municipality",
        cityCode: "MK_44",
      },
      {
        name: "Krivogaštani Municipality",
        countryCode: "MK",
        latitude: "41.30823060",
        longitude: "21.36796890",
        nameNative: null,
        label: "Krivogaštani Municipality",
        cityCode: "MK_45",
      },
      {
        name: "Kruševo Municipality",
        countryCode: "MK",
        latitude: "41.37693310",
        longitude: "21.26065540",
        nameNative: null,
        label: "Kruševo Municipality",
        cityCode: "MK_46",
      },
      {
        name: "Kumanovo Municipality",
        countryCode: "MK",
        latitude: "42.07326130",
        longitude: "21.78531430",
        nameNative: null,
        label: "Kumanovo Municipality",
        cityCode: "MK_47",
      },
      {
        name: "Lipkovo Municipality",
        countryCode: "MK",
        latitude: "42.20066260",
        longitude: "21.61837550",
        nameNative: null,
        label: "Lipkovo Municipality",
        cityCode: "MK_48",
      },
      {
        name: "Lozovo Municipality",
        countryCode: "MK",
        latitude: "41.78181390",
        longitude: "21.90008270",
        nameNative: null,
        label: "Lozovo Municipality",
        cityCode: "MK_49",
      },
      {
        name: "Makedonska Kamenica Municipality",
        countryCode: "MK",
        latitude: "42.06946040",
        longitude: "22.54834900",
        nameNative: null,
        label: "Makedonska Kamenica Municipality",
        cityCode: "MK_51",
      },
      {
        name: "Makedonski Brod Municipality",
        countryCode: "MK",
        latitude: "41.51330880",
        longitude: "21.21743290",
        nameNative: null,
        label: "Makedonski Brod Municipality",
        cityCode: "MK_52",
      },
      {
        name: "Mavrovo and Rostuša Municipality",
        countryCode: "MK",
        latitude: "41.60924270",
        longitude: "20.60124880",
        nameNative: null,
        label: "Mavrovo and Rostuša Municipality",
        cityCode: "MK_50",
      },
      {
        name: "Mogila Municipality",
        countryCode: "MK",
        latitude: "41.14796450",
        longitude: "21.45143690",
        nameNative: null,
        label: "Mogila Municipality",
        cityCode: "MK_53",
      },
      {
        name: "Negotino Municipality",
        countryCode: "MK",
        latitude: "41.49899850",
        longitude: "22.09532970",
        nameNative: null,
        label: "Negotino Municipality",
        cityCode: "MK_54",
      },
      {
        name: "Novaci Municipality",
        countryCode: "MK",
        latitude: "41.04426610",
        longitude: "21.45888940",
        nameNative: null,
        label: "Novaci Municipality",
        cityCode: "MK_55",
      },
      {
        name: "Novo Selo Municipality",
        countryCode: "MK",
        latitude: "41.43255800",
        longitude: "22.88204890",
        nameNative: null,
        label: "Novo Selo Municipality",
        cityCode: "MK_56",
      },
      {
        name: "Ohrid Municipality",
        countryCode: "MK",
        latitude: "41.06820880",
        longitude: "20.75992660",
        nameNative: null,
        label: "Ohrid Municipality",
        cityCode: "MK_58",
      },
      {
        name: "Oslomej Municipality",
        countryCode: "MK",
        latitude: "41.57583910",
        longitude: "21.02219600",
        nameNative: null,
        label: "Oslomej Municipality",
        cityCode: "MK_57",
      },
      {
        name: "Pehčevo Municipality",
        countryCode: "MK",
        latitude: "41.77371320",
        longitude: "22.88204890",
        nameNative: null,
        label: "Pehčevo Municipality",
        cityCode: "MK_60",
      },
      {
        name: "Petrovec Municipality",
        countryCode: "MK",
        latitude: "41.90298970",
        longitude: "21.68992100",
        nameNative: null,
        label: "Petrovec Municipality",
        cityCode: "MK_59",
      },
      {
        name: "Plasnica Municipality",
        countryCode: "MK",
        latitude: "41.45463490",
        longitude: "21.10565390",
        nameNative: null,
        label: "Plasnica Municipality",
        cityCode: "MK_61",
      },
      {
        name: "Prilep Municipality",
        countryCode: "MK",
        latitude: "41.26931420",
        longitude: "21.71376940",
        nameNative: null,
        label: "Prilep Municipality",
        cityCode: "MK_62",
      },
      {
        name: "Probištip Municipality",
        countryCode: "MK",
        latitude: "41.95891460",
        longitude: "22.16686700",
        nameNative: null,
        label: "Probištip Municipality",
        cityCode: "MK_63",
      },
      {
        name: "Radoviš Municipality",
        countryCode: "MK",
        latitude: "41.64955310",
        longitude: "22.47682870",
        nameNative: null,
        label: "Radoviš Municipality",
        cityCode: "MK_64",
      },
      {
        name: "Rankovce Municipality",
        countryCode: "MK",
        latitude: "42.18081410",
        longitude: "22.09532970",
        nameNative: null,
        label: "Rankovce Municipality",
        cityCode: "MK_65",
      },
      {
        name: "Resen Municipality",
        countryCode: "MK",
        latitude: "40.93680930",
        longitude: "21.04604070",
        nameNative: null,
        label: "Resen Municipality",
        cityCode: "MK_66",
      },
      {
        name: "Rosoman Municipality",
        countryCode: "MK",
        latitude: "41.48480060",
        longitude: "21.88070640",
        nameNative: null,
        label: "Rosoman Municipality",
        cityCode: "MK_67",
      },
      {
        name: "Saraj Municipality",
        countryCode: "MK",
        latitude: "41.98694960",
        longitude: "21.26065540",
        nameNative: null,
        label: "Saraj Municipality",
        cityCode: "MK_68",
      },
      {
        name: "Sopište Municipality",
        countryCode: "MK",
        latitude: "41.86384920",
        longitude: "21.30834990",
        nameNative: null,
        label: "Sopište Municipality",
        cityCode: "MK_70",
      },
      {
        name: "Staro Nagoričane Municipality",
        countryCode: "MK",
        latitude: "42.21916920",
        longitude: "21.90455410",
        nameNative: null,
        label: "Staro Nagoričane Municipality",
        cityCode: "MK_71",
      },
      {
        name: "Struga Municipality",
        countryCode: "MK",
        latitude: "41.31737440",
        longitude: "20.66456830",
        nameNative: null,
        label: "Struga Municipality",
        cityCode: "MK_72",
      },
      {
        name: "Strumica Municipality",
        countryCode: "MK",
        latitude: "41.43780040",
        longitude: "22.64274280",
        nameNative: null,
        label: "Strumica Municipality",
        cityCode: "MK_73",
      },
      {
        name: "Studeničani Municipality",
        countryCode: "MK",
        latitude: "41.92256390",
        longitude: "21.53639650",
        nameNative: null,
        label: "Studeničani Municipality",
        cityCode: "MK_74",
      },
      {
        name: "Sveti Nikole Municipality",
        countryCode: "MK",
        latitude: "41.89803120",
        longitude: "21.99994350",
        nameNative: null,
        label: "Sveti Nikole Municipality",
        cityCode: "MK_69",
      },
      {
        name: "Tearce Municipality",
        countryCode: "MK",
        latitude: "42.07775110",
        longitude: "21.05349230",
        nameNative: null,
        label: "Tearce Municipality",
        cityCode: "MK_75",
      },
      {
        name: "Tetovo Municipality",
        countryCode: "MK",
        latitude: "42.02748600",
        longitude: "20.95066360",
        nameNative: null,
        label: "Tetovo Municipality",
        cityCode: "MK_76",
      },
      {
        name: "Valandovo Municipality",
        countryCode: "MK",
        latitude: "41.32119090",
        longitude: "22.50066930",
        nameNative: null,
        label: "Valandovo Municipality",
        cityCode: "MK_10",
      },
      {
        name: "Vasilevo Municipality",
        countryCode: "MK",
        latitude: "41.47416990",
        longitude: "22.64221280",
        nameNative: null,
        label: "Vasilevo Municipality",
        cityCode: "MK_11",
      },
      {
        name: "Veles Municipality",
        countryCode: "MK",
        latitude: "41.72744260",
        longitude: "21.71376940",
        nameNative: null,
        label: "Veles Municipality",
        cityCode: "MK_13",
      },
      {
        name: "Vevčani Municipality",
        countryCode: "MK",
        latitude: "41.24075430",
        longitude: "20.59156490",
        nameNative: null,
        label: "Vevčani Municipality",
        cityCode: "MK_12",
      },
      {
        name: "Vinica Municipality",
        countryCode: "MK",
        latitude: "41.85710200",
        longitude: "22.57218810",
        nameNative: null,
        label: "Vinica Municipality",
        cityCode: "MK_14",
      },
      {
        name: "Vraneštica Municipality",
        countryCode: "MK",
        latitude: "41.48290870",
        longitude: "21.05796320",
        nameNative: null,
        label: "Vraneštica Municipality",
        cityCode: "MK_15",
      },
      {
        name: "Vrapčište Municipality",
        countryCode: "MK",
        latitude: "41.87911600",
        longitude: "20.83145000",
        nameNative: null,
        label: "Vrapčište Municipality",
        cityCode: "MK_16",
      },
      {
        name: "Zajas Municipality",
        countryCode: "MK",
        latitude: "41.60303280",
        longitude: "20.87913430",
        nameNative: null,
        label: "Zajas Municipality",
        cityCode: "MK_31",
      },
      {
        name: "Zelenikovo Municipality",
        countryCode: "MK",
        latitude: "41.87338120",
        longitude: "21.60272500",
        nameNative: null,
        label: "Zelenikovo Municipality",
        cityCode: "MK_32",
      },
      {
        name: "Zrnovci Municipality",
        countryCode: "MK",
        latitude: "41.82282210",
        longitude: "22.41722560",
        nameNative: null,
        label: "Zrnovci Municipality",
        cityCode: "MK_33",
      },
      {
        name: "Čair Municipality",
        countryCode: "MK",
        latitude: "41.99303550",
        longitude: "21.43653180",
        nameNative: null,
        label: "Čair Municipality",
        cityCode: "MK_79",
      },
      {
        name: "Čaška Municipality",
        countryCode: "MK",
        latitude: "41.64743800",
        longitude: "21.69141150",
        nameNative: null,
        label: "Čaška Municipality",
        cityCode: "MK_80",
      },
      {
        name: "Češinovo-Obleševo Municipality",
        countryCode: "MK",
        latitude: "41.86393160",
        longitude: "22.26224600",
        nameNative: null,
        label: "Češinovo-Obleševo Municipality",
        cityCode: "MK_81",
      },
      {
        name: "Čučer-Sandevo Municipality",
        countryCode: "MK",
        latitude: "42.14839460",
        longitude: "21.40374070",
        nameNative: null,
        label: "Čučer-Sandevo Municipality",
        cityCode: "MK_82",
      },
      {
        name: "Štip Municipality",
        countryCode: "MK",
        latitude: "41.70792970",
        longitude: "22.19071220",
        nameNative: null,
        label: "Štip Municipality",
        cityCode: "MK_83",
      },
      {
        name: "Šuto Orizari Municipality",
        countryCode: "MK",
        latitude: "42.02904160",
        longitude: "21.40970270",
        nameNative: null,
        label: "Šuto Orizari Municipality",
        cityCode: "MK_84",
      },
      {
        name: "Želino Municipality",
        countryCode: "MK",
        latitude: "41.90065310",
        longitude: "21.11757670",
        nameNative: null,
        label: "Želino Municipality",
        cityCode: "MK_30",
      },
    ],
    MG: [
      {
        name: "Antananarivo Province",
        countryCode: "MG",
        latitude: "-18.70514740",
        longitude: "46.82528380",
        nameNative: null,
        label: "Antananarivo Province",
        cityCode: "MG_T",
      },
      {
        name: "Antsiranana Province",
        countryCode: "MG",
        latitude: "-13.77153900",
        longitude: "49.52799960",
        nameNative: null,
        label: "Antsiranana Province",
        cityCode: "MG_D",
      },
      {
        name: "Fianarantsoa Province",
        countryCode: "MG",
        latitude: "-22.35362400",
        longitude: "46.82528380",
        nameNative: null,
        label: "Fianarantsoa Province",
        cityCode: "MG_F",
      },
      {
        name: "Mahajanga Province",
        countryCode: "MG",
        latitude: "-16.52388300",
        longitude: "46.51626200",
        nameNative: null,
        label: "Mahajanga Province",
        cityCode: "MG_M",
      },
      {
        name: "Toamasina Province",
        countryCode: "MG",
        latitude: "-18.14428110",
        longitude: "49.39578360",
        nameNative: null,
        label: "Toamasina Province",
        cityCode: "MG_A",
      },
      {
        name: "Toliara Province",
        countryCode: "MG",
        latitude: "-23.35161910",
        longitude: "43.68549360",
        nameNative: null,
        label: "Toliara Province",
        cityCode: "MG_U",
      },
    ],
    MW: [
      {
        name: "Balaka District",
        countryCode: "MW",
        latitude: "-15.05065950",
        longitude: "35.08285880",
        nameNative: null,
        label: "Balaka District",
        cityCode: "MW_BA",
      },
      {
        name: "Blantyre District",
        countryCode: "MW",
        latitude: "-15.67785410",
        longitude: "34.95066250",
        nameNative: null,
        label: "Blantyre District",
        cityCode: "MW_BL",
      },
      {
        name: "Central Region",
        countryCode: "MW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Central Region",
        cityCode: "MW_C",
      },
      {
        name: "Chikwawa District",
        countryCode: "MW",
        latitude: "-16.19584460",
        longitude: "34.77407930",
        nameNative: null,
        label: "Chikwawa District",
        cityCode: "MW_CK",
      },
      {
        name: "Chiradzulu District",
        countryCode: "MW",
        latitude: "-15.74231510",
        longitude: "35.25879640",
        nameNative: null,
        label: "Chiradzulu District",
        cityCode: "MW_CR",
      },
      {
        name: "Chitipa district",
        countryCode: "MW",
        latitude: "-9.70376550",
        longitude: "33.27002530",
        nameNative: null,
        label: "Chitipa district",
        cityCode: "MW_CT",
      },
      {
        name: "Dedza District",
        countryCode: "MW",
        latitude: "-14.18945850",
        longitude: "34.24215970",
        nameNative: null,
        label: "Dedza District",
        cityCode: "MW_DE",
      },
      {
        name: "Dowa District",
        countryCode: "MW",
        latitude: "-13.60412560",
        longitude: "33.88577470",
        nameNative: null,
        label: "Dowa District",
        cityCode: "MW_DO",
      },
      {
        name: "Karonga District",
        countryCode: "MW",
        latitude: "-9.90363650",
        longitude: "33.97500180",
        nameNative: null,
        label: "Karonga District",
        cityCode: "MW_KR",
      },
      {
        name: "Kasungu District",
        countryCode: "MW",
        latitude: "-13.13670650",
        longitude: "33.25879300",
        nameNative: null,
        label: "Kasungu District",
        cityCode: "MW_KS",
      },
      {
        name: "Likoma District",
        countryCode: "MW",
        latitude: "-12.05840050",
        longitude: "34.73540310",
        nameNative: null,
        label: "Likoma District",
        cityCode: "MW_LK",
      },
      {
        name: "Lilongwe District",
        countryCode: "MW",
        latitude: "-14.04752280",
        longitude: "33.61757700",
        nameNative: null,
        label: "Lilongwe District",
        cityCode: "MW_LI",
      },
      {
        name: "Machinga District",
        countryCode: "MW",
        latitude: "-14.94072630",
        longitude: "35.47819260",
        nameNative: null,
        label: "Machinga District",
        cityCode: "MW_MH",
      },
      {
        name: "Mangochi District",
        countryCode: "MW",
        latitude: "-14.13882480",
        longitude: "35.03881640",
        nameNative: null,
        label: "Mangochi District",
        cityCode: "MW_MG",
      },
      {
        name: "Mchinji District",
        countryCode: "MW",
        latitude: "-13.74015250",
        longitude: "32.98883190",
        nameNative: null,
        label: "Mchinji District",
        cityCode: "MW_MC",
      },
      {
        name: "Mulanje District",
        countryCode: "MW",
        latitude: "-15.93464340",
        longitude: "35.52200120",
        nameNative: null,
        label: "Mulanje District",
        cityCode: "MW_MU",
      },
      {
        name: "Mwanza District",
        countryCode: "MW",
        latitude: "-2.46711970",
        longitude: "32.89868120",
        nameNative: null,
        label: "Mwanza District",
        cityCode: "MW_MW",
      },
      {
        name: "Mzimba District",
        countryCode: "MW",
        latitude: "-11.74754520",
        longitude: "33.52800720",
        nameNative: null,
        label: "Mzimba District",
        cityCode: "MW_MZ",
      },
      {
        name: "Nkhata Bay District",
        countryCode: "MW",
        latitude: "-11.71850420",
        longitude: "34.33103640",
        nameNative: null,
        label: "Nkhata Bay District",
        cityCode: "MW_NB",
      },
      {
        name: "Nkhotakota District",
        countryCode: "MW",
        latitude: "-12.75419610",
        longitude: "34.24215970",
        nameNative: null,
        label: "Nkhotakota District",
        cityCode: "MW_NK",
      },
      {
        name: "Northern Region",
        countryCode: "MW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Northern Region",
        cityCode: "MW_N",
      },
      {
        name: "Nsanje District",
        countryCode: "MW",
        latitude: "-16.72882020",
        longitude: "35.17087410",
        nameNative: null,
        label: "Nsanje District",
        cityCode: "MW_NS",
      },
      {
        name: "Ntcheu District",
        countryCode: "MW",
        latitude: "-14.90375380",
        longitude: "34.77407930",
        nameNative: null,
        label: "Ntcheu District",
        cityCode: "MW_NU",
      },
      {
        name: "Ntchisi District",
        countryCode: "MW",
        latitude: "-13.28419920",
        longitude: "33.88577470",
        nameNative: null,
        label: "Ntchisi District",
        cityCode: "MW_NI",
      },
      {
        name: "Phalombe District",
        countryCode: "MW",
        latitude: "-15.70920380",
        longitude: "35.65328480",
        nameNative: null,
        label: "Phalombe District",
        cityCode: "MW_PH",
      },
      {
        name: "Rumphi District",
        countryCode: "MW",
        latitude: "-10.78515370",
        longitude: "34.33103640",
        nameNative: null,
        label: "Rumphi District",
        cityCode: "MW_RU",
      },
      {
        name: "Salima District",
        countryCode: "MW",
        latitude: "-13.68095860",
        longitude: "34.41982430",
        nameNative: null,
        label: "Salima District",
        cityCode: "MW_SA",
      },
      {
        name: "Southern Region",
        countryCode: "MW",
        latitude: "32.75049570",
        longitude: "-97.33154760",
        nameNative: null,
        label: "Southern Region",
        cityCode: "MW_S",
      },
      {
        name: "Thyolo District",
        countryCode: "MW",
        latitude: "-16.12991770",
        longitude: "35.12687810",
        nameNative: null,
        label: "Thyolo District",
        cityCode: "MW_TH",
      },
      {
        name: "Zomba District",
        countryCode: "MW",
        latitude: "-15.37658570",
        longitude: "35.33565180",
        nameNative: null,
        label: "Zomba District",
        cityCode: "MW_ZO",
      },
    ],
    MY: [
      {
        name: "Johor",
        countryCode: "MY",
        latitude: "1.48536820",
        longitude: "103.76181540",
        nameNative: null,
        label: "Johor",
        cityCode: "MY_01",
      },
      {
        name: "Kedah",
        countryCode: "MY",
        latitude: "6.11839640",
        longitude: "100.36845950",
        nameNative: null,
        label: "Kedah",
        cityCode: "MY_02",
      },
      {
        name: "Kelantan",
        countryCode: "MY",
        latitude: "6.12539690",
        longitude: "102.23807100",
        nameNative: null,
        label: "Kelantan",
        cityCode: "MY_03",
      },
      {
        name: "Kuala Lumpur",
        countryCode: "MY",
        latitude: "3.13900300",
        longitude: "101.68685500",
        nameNative: null,
        label: "Kuala Lumpur",
        cityCode: "MY_14",
      },
      {
        name: "Labuan",
        countryCode: "MY",
        latitude: "5.28314560",
        longitude: "115.23082500",
        nameNative: null,
        label: "Labuan",
        cityCode: "MY_15",
      },
      {
        name: "Malacca",
        countryCode: "MY",
        latitude: "2.18959400",
        longitude: "102.25008680",
        nameNative: null,
        label: "Malacca",
        cityCode: "MY_04",
      },
      {
        name: "Negeri Sembilan",
        countryCode: "MY",
        latitude: "2.72580580",
        longitude: "101.94237820",
        nameNative: null,
        label: "Negeri Sembilan",
        cityCode: "MY_05",
      },
      {
        name: "Pahang",
        countryCode: "MY",
        latitude: "3.81263180",
        longitude: "103.32562040",
        nameNative: null,
        label: "Pahang",
        cityCode: "MY_06",
      },
      {
        name: "Penang",
        countryCode: "MY",
        latitude: "5.41639350",
        longitude: "100.33267860",
        nameNative: null,
        label: "Penang",
        cityCode: "MY_07",
      },
      {
        name: "Perak",
        countryCode: "MY",
        latitude: "4.59211260",
        longitude: "101.09010900",
        nameNative: null,
        label: "Perak",
        cityCode: "MY_08",
      },
      {
        name: "Perlis",
        countryCode: "MY",
        latitude: "29.92270940",
        longitude: "-90.12285590",
        nameNative: null,
        label: "Perlis",
        cityCode: "MY_09",
      },
      {
        name: "Putrajaya",
        countryCode: "MY",
        latitude: "2.92636100",
        longitude: "101.69644500",
        nameNative: null,
        label: "Putrajaya",
        cityCode: "MY_16",
      },
      {
        name: "Sabah",
        countryCode: "MY",
        latitude: "5.97883980",
        longitude: "116.07531990",
        nameNative: null,
        label: "Sabah",
        cityCode: "MY_12",
      },
      {
        name: "Sarawak",
        countryCode: "MY",
        latitude: "1.55327830",
        longitude: "110.35921270",
        nameNative: null,
        label: "Sarawak",
        cityCode: "MY_13",
      },
      {
        name: "Selangor",
        countryCode: "MY",
        latitude: "3.07383790",
        longitude: "101.51834690",
        nameNative: null,
        label: "Selangor",
        cityCode: "MY_10",
      },
      {
        name: "Terengganu",
        countryCode: "MY",
        latitude: "5.31169160",
        longitude: "103.13241540",
        nameNative: null,
        label: "Terengganu",
        cityCode: "MY_11",
      },
    ],
    MV: [
      {
        name: "Addu Atoll",
        countryCode: "MV",
        latitude: "-0.63009950",
        longitude: "73.15856260",
        nameNative: null,
        label: "Addu Atoll",
        cityCode: "MV_01",
      },
      {
        name: "Alif Alif Atoll",
        countryCode: "MV",
        latitude: "4.08500000",
        longitude: "72.85154790",
        nameNative: null,
        label: "Alif Alif Atoll",
        cityCode: "MV_02",
      },
      {
        name: "Alif Dhaal Atoll",
        countryCode: "MV",
        latitude: "3.65433020",
        longitude: "72.80427970",
        nameNative: null,
        label: "Alif Dhaal Atoll",
        cityCode: "MV_00",
      },
      {
        name: "Central Province",
        countryCode: "MV",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Central Province",
        cityCode: "MV_CE",
      },
      {
        name: "Dhaalu Atoll",
        countryCode: "MV",
        latitude: "2.84685020",
        longitude: "72.94605660",
        nameNative: null,
        label: "Dhaalu Atoll",
        cityCode: "MV_17",
      },
      {
        name: "Faafu Atoll",
        countryCode: "MV",
        latitude: "3.23094090",
        longitude: "72.94605660",
        nameNative: null,
        label: "Faafu Atoll",
        cityCode: "MV_14",
      },
      {
        name: "Gaafu Alif Atoll",
        countryCode: "MV",
        latitude: "0.61248130",
        longitude: "73.32370800",
        nameNative: null,
        label: "Gaafu Alif Atoll",
        cityCode: "MV_27",
      },
      {
        name: "Gaafu Dhaalu Atoll",
        countryCode: "MV",
        latitude: "0.35880400",
        longitude: "73.18216230",
        nameNative: null,
        label: "Gaafu Dhaalu Atoll",
        cityCode: "MV_28",
      },
      {
        name: "Gnaviyani Atoll",
        countryCode: "MV",
        latitude: "-0.30064250",
        longitude: "73.42391430",
        nameNative: null,
        label: "Gnaviyani Atoll",
        cityCode: "MV_29",
      },
      {
        name: "Haa Alif Atoll",
        countryCode: "MV",
        latitude: "6.99034880",
        longitude: "72.94605660",
        nameNative: null,
        label: "Haa Alif Atoll",
        cityCode: "MV_07",
      },
      {
        name: "Haa Dhaalu Atoll",
        countryCode: "MV",
        latitude: "6.57827170",
        longitude: "72.94605660",
        nameNative: null,
        label: "Haa Dhaalu Atoll",
        cityCode: "MV_23",
      },
      {
        name: "Kaafu Atoll",
        countryCode: "MV",
        latitude: "4.45589790",
        longitude: "73.55941280",
        nameNative: null,
        label: "Kaafu Atoll",
        cityCode: "MV_26",
      },
      {
        name: "Laamu Atoll",
        countryCode: "MV",
        latitude: "1.94307370",
        longitude: "73.41802110",
        nameNative: null,
        label: "Laamu Atoll",
        cityCode: "MV_05",
      },
      {
        name: "Lhaviyani Atoll",
        countryCode: "MV",
        latitude: "5.37470210",
        longitude: "73.51229280",
        nameNative: null,
        label: "Lhaviyani Atoll",
        cityCode: "MV_03",
      },
      {
        name: "Malé",
        countryCode: "MV",
        latitude: "46.34888670",
        longitude: "10.90724890",
        nameNative: null,
        label: "Malé",
        cityCode: "MV_MLE",
      },
      {
        name: "Meemu Atoll",
        countryCode: "MV",
        latitude: "3.00903450",
        longitude: "73.51229280",
        nameNative: null,
        label: "Meemu Atoll",
        cityCode: "MV_12",
      },
      {
        name: "Noonu Atoll",
        countryCode: "MV",
        latitude: "5.85512760",
        longitude: "73.32370800",
        nameNative: null,
        label: "Noonu Atoll",
        cityCode: "MV_25",
      },
      {
        name: "North Central Province",
        countryCode: "MV",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "North Central Province",
        cityCode: "MV_NC",
      },
      {
        name: "North Province",
        countryCode: "MV",
        latitude: "8.88550270",
        longitude: "80.27673270",
        nameNative: null,
        label: "North Province",
        cityCode: "MV_NO",
      },
      {
        name: "Raa Atoll",
        countryCode: "MV",
        latitude: "5.60064570",
        longitude: "72.94605660",
        nameNative: null,
        label: "Raa Atoll",
        cityCode: "MV_13",
      },
      {
        name: "Shaviyani Atoll",
        countryCode: "MV",
        latitude: "6.17511000",
        longitude: "73.13496050",
        nameNative: null,
        label: "Shaviyani Atoll",
        cityCode: "MV_24",
      },
      {
        name: "South Central Province",
        countryCode: "MV",
        latitude: "7.25649960",
        longitude: "80.72144170",
        nameNative: null,
        label: "South Central Province",
        cityCode: "MV_SC",
      },
      {
        name: "South Province",
        countryCode: "MV",
        latitude: "-21.74820060",
        longitude: "166.17837390",
        nameNative: null,
        label: "South Province",
        cityCode: "MV_SU",
      },
      {
        name: "Thaa Atoll",
        countryCode: "MV",
        latitude: "2.43111610",
        longitude: "73.18216230",
        nameNative: null,
        label: "Thaa Atoll",
        cityCode: "MV_08",
      },
      {
        name: "Upper South Province",
        countryCode: "MV",
        latitude: "0.23070000",
        longitude: "73.27948460",
        nameNative: null,
        label: "Upper South Province",
        cityCode: "MV_US",
      },
      {
        name: "Vaavu Atoll",
        countryCode: "MV",
        latitude: "3.39554380",
        longitude: "73.51229280",
        nameNative: null,
        label: "Vaavu Atoll",
        cityCode: "MV_04",
      },
    ],
    ML: [
      {
        name: "Bamako",
        countryCode: "ML",
        latitude: "12.63923160",
        longitude: "-8.00288920",
        nameNative: null,
        label: "Bamako",
        cityCode: "ML_BKO",
      },
      {
        name: "Gao Region",
        countryCode: "ML",
        latitude: "16.90663320",
        longitude: "1.52086240",
        nameNative: null,
        label: "Gao Region",
        cityCode: "ML_7",
      },
      {
        name: "Kayes Region",
        countryCode: "ML",
        latitude: "14.08183080",
        longitude: "-9.90181310",
        nameNative: null,
        label: "Kayes Region",
        cityCode: "ML_1",
      },
      {
        name: "Kidal Region",
        countryCode: "ML",
        latitude: "18.79868320",
        longitude: "1.83183340",
        nameNative: null,
        label: "Kidal Region",
        cityCode: "ML_8",
      },
      {
        name: "Koulikoro Region",
        countryCode: "ML",
        latitude: "13.80180740",
        longitude: "-7.43813550",
        nameNative: null,
        label: "Koulikoro Region",
        cityCode: "ML_2",
      },
      {
        name: "Mopti Region",
        countryCode: "ML",
        latitude: "14.63380390",
        longitude: "-3.41955270",
        nameNative: null,
        label: "Mopti Region",
        cityCode: "ML_5",
      },
      {
        name: "Ménaka Region",
        countryCode: "ML",
        latitude: "15.91564210",
        longitude: "2.39617400",
        nameNative: null,
        label: "Ménaka Region",
        cityCode: "ML_9",
      },
      {
        name: "Sikasso Region",
        countryCode: "ML",
        latitude: "10.89051860",
        longitude: "-7.43813550",
        nameNative: null,
        label: "Sikasso Region",
        cityCode: "ML_3",
      },
      {
        name: "Ségou Region",
        countryCode: "ML",
        latitude: "13.83944560",
        longitude: "-6.06791940",
        nameNative: null,
        label: "Ségou Region",
        cityCode: "ML_4",
      },
      {
        name: "Taoudénit Region",
        countryCode: "ML",
        latitude: "22.67641320",
        longitude: "-3.97891430",
        nameNative: null,
        label: "Taoudénit Region",
        cityCode: "ML_10",
      },
      {
        name: "Tombouctou Region",
        countryCode: "ML",
        latitude: "21.05267060",
        longitude: "-3.74350900",
        nameNative: null,
        label: "Tombouctou Region",
        cityCode: "ML_6",
      },
    ],
    MT: [
      {
        name: "Attard",
        countryCode: "MT",
        latitude: "35.89049670",
        longitude: "14.41993220",
        nameNative: null,
        label: "Attard",
        cityCode: "MT_01",
      },
      {
        name: "Balzan",
        countryCode: "MT",
        latitude: "35.89574140",
        longitude: "14.45340650",
        nameNative: null,
        label: "Balzan",
        cityCode: "MT_02",
      },
      {
        name: "Birgu",
        countryCode: "MT",
        latitude: "35.88792140",
        longitude: "14.52256200",
        nameNative: null,
        label: "Birgu",
        cityCode: "MT_03",
      },
      {
        name: "Birkirkara",
        countryCode: "MT",
        latitude: "35.89547060",
        longitude: "14.46650720",
        nameNative: null,
        label: "Birkirkara",
        cityCode: "MT_04",
      },
      {
        name: "Birżebbuġa",
        countryCode: "MT",
        latitude: "35.81359890",
        longitude: "14.52474630",
        nameNative: null,
        label: "Birżebbuġa",
        cityCode: "MT_05",
      },
      {
        name: "Cospicua",
        countryCode: "MT",
        latitude: "35.88067530",
        longitude: "14.52183380",
        nameNative: null,
        label: "Cospicua",
        cityCode: "MT_06",
      },
      {
        name: "Dingli",
        countryCode: "MT",
        latitude: "35.86273090",
        longitude: "14.38501070",
        nameNative: null,
        label: "Dingli",
        cityCode: "MT_07",
      },
      {
        name: "Fgura",
        countryCode: "MT",
        latitude: "35.87382690",
        longitude: "14.52329010",
        nameNative: null,
        label: "Fgura",
        cityCode: "MT_08",
      },
      {
        name: "Floriana",
        countryCode: "MT",
        latitude: "45.49521850",
        longitude: "-73.71395760",
        nameNative: null,
        label: "Floriana",
        cityCode: "MT_09",
      },
      {
        name: "Fontana",
        countryCode: "MT",
        latitude: "34.09223350",
        longitude: "-117.43504800",
        nameNative: null,
        label: "Fontana",
        cityCode: "MT_10",
      },
      {
        name: "Gudja",
        countryCode: "MT",
        latitude: "35.84698030",
        longitude: "14.50290400",
        nameNative: null,
        label: "Gudja",
        cityCode: "MT_11",
      },
      {
        name: "Għajnsielem",
        countryCode: "MT",
        latitude: "36.02479660",
        longitude: "14.28029610",
        nameNative: null,
        label: "Għajnsielem",
        cityCode: "MT_13",
      },
      {
        name: "Għarb",
        countryCode: "MT",
        latitude: "36.06890900",
        longitude: "14.20180980",
        nameNative: null,
        label: "Għarb",
        cityCode: "MT_14",
      },
      {
        name: "Għargħur",
        countryCode: "MT",
        latitude: "35.92205690",
        longitude: "14.45631760",
        nameNative: null,
        label: "Għargħur",
        cityCode: "MT_15",
      },
      {
        name: "Għasri",
        countryCode: "MT",
        latitude: "36.06680750",
        longitude: "14.21924750",
        nameNative: null,
        label: "Għasri",
        cityCode: "MT_16",
      },
      {
        name: "Għaxaq",
        countryCode: "MT",
        latitude: "35.84403590",
        longitude: "14.51600900",
        nameNative: null,
        label: "Għaxaq",
        cityCode: "MT_17",
      },
      {
        name: "Gżira",
        countryCode: "MT",
        latitude: "35.90589700",
        longitude: "14.49533380",
        nameNative: null,
        label: "Gżira",
        cityCode: "MT_12",
      },
      {
        name: "Iklin",
        countryCode: "MT",
        latitude: "35.90987740",
        longitude: "14.45777320",
        nameNative: null,
        label: "Iklin",
        cityCode: "MT_19",
      },
      {
        name: "Kalkara",
        countryCode: "MT",
        latitude: "35.89142420",
        longitude: "14.53202780",
        nameNative: null,
        label: "Kalkara",
        cityCode: "MT_21",
      },
      {
        name: "Kerċem",
        countryCode: "MT",
        latitude: "36.04479390",
        longitude: "14.22506050",
        nameNative: null,
        label: "Kerċem",
        cityCode: "MT_22",
      },
      {
        name: "Kirkop",
        countryCode: "MT",
        latitude: "35.84378620",
        longitude: "14.48543240",
        nameNative: null,
        label: "Kirkop",
        cityCode: "MT_23",
      },
      {
        name: "Lija",
        countryCode: "MT",
        latitude: "49.18007600",
        longitude: "-123.10331700",
        nameNative: null,
        label: "Lija",
        cityCode: "MT_24",
      },
      {
        name: "Luqa",
        countryCode: "MT",
        latitude: "35.85828650",
        longitude: "14.48688830",
        nameNative: null,
        label: "Luqa",
        cityCode: "MT_25",
      },
      {
        name: "Marsa",
        countryCode: "MT",
        latitude: "34.03195870",
        longitude: "-118.44555350",
        nameNative: null,
        label: "Marsa",
        cityCode: "MT_26",
      },
      {
        name: "Marsaskala",
        countryCode: "MT",
        latitude: "35.86036400",
        longitude: "14.55678760",
        nameNative: null,
        label: "Marsaskala",
        cityCode: "MT_27",
      },
      {
        name: "Marsaxlokk",
        countryCode: "MT",
        latitude: "35.84116990",
        longitude: "14.53930970",
        nameNative: null,
        label: "Marsaxlokk",
        cityCode: "MT_28",
      },
      {
        name: "Mdina",
        countryCode: "MT",
        latitude: "35.88809300",
        longitude: "14.40683570",
        nameNative: null,
        label: "Mdina",
        cityCode: "MT_29",
      },
      {
        name: "Mellieħa",
        countryCode: "MT",
        latitude: "35.95235290",
        longitude: "14.35009750",
        nameNative: null,
        label: "Mellieħa",
        cityCode: "MT_30",
      },
      {
        name: "Mosta",
        countryCode: "MT",
        latitude: "35.91415040",
        longitude: "14.42284270",
        nameNative: null,
        label: "Mosta",
        cityCode: "MT_32",
      },
      {
        name: "Mqabba",
        countryCode: "MT",
        latitude: "35.84441430",
        longitude: "14.46941860",
        nameNative: null,
        label: "Mqabba",
        cityCode: "MT_33",
      },
      {
        name: "Msida",
        countryCode: "MT",
        latitude: "35.89563880",
        longitude: "14.48688830",
        nameNative: null,
        label: "Msida",
        cityCode: "MT_34",
      },
      {
        name: "Mtarfa",
        countryCode: "MT",
        latitude: "35.88951250",
        longitude: "14.39519530",
        nameNative: null,
        label: "Mtarfa",
        cityCode: "MT_35",
      },
      {
        name: "Munxar",
        countryCode: "MT",
        latitude: "36.02880580",
        longitude: "14.22506050",
        nameNative: null,
        label: "Munxar",
        cityCode: "MT_36",
      },
      {
        name: "Mġarr",
        countryCode: "MT",
        latitude: "35.91893270",
        longitude: "14.36173430",
        nameNative: null,
        label: "Mġarr",
        cityCode: "MT_31",
      },
      {
        name: "Nadur",
        countryCode: "MT",
        latitude: "36.04470190",
        longitude: "14.29192730",
        nameNative: null,
        label: "Nadur",
        cityCode: "MT_37",
      },
      {
        name: "Naxxar",
        countryCode: "MT",
        latitude: "35.93175180",
        longitude: "14.43157460",
        nameNative: null,
        label: "Naxxar",
        cityCode: "MT_38",
      },
      {
        name: "Paola",
        countryCode: "MT",
        latitude: "38.57223530",
        longitude: "-94.87912940",
        nameNative: null,
        label: "Paola",
        cityCode: "MT_39",
      },
      {
        name: "Pembroke",
        countryCode: "MT",
        latitude: "34.68016260",
        longitude: "-79.19503730",
        nameNative: null,
        label: "Pembroke",
        cityCode: "MT_40",
      },
      {
        name: "Pietà",
        countryCode: "MT",
        latitude: "42.21862000",
        longitude: "-83.73464700",
        nameNative: null,
        label: "Pietà",
        cityCode: "MT_41",
      },
      {
        name: "Qala",
        countryCode: "MT",
        latitude: "36.03886280",
        longitude: "14.31810100",
        nameNative: null,
        label: "Qala",
        cityCode: "MT_42",
      },
      {
        name: "Qormi",
        countryCode: "MT",
        latitude: "35.87643880",
        longitude: "14.46941860",
        nameNative: null,
        label: "Qormi",
        cityCode: "MT_43",
      },
      {
        name: "Qrendi",
        countryCode: "MT",
        latitude: "35.83284880",
        longitude: "14.45486210",
        nameNative: null,
        label: "Qrendi",
        cityCode: "MT_44",
      },
      {
        name: "Rabat",
        countryCode: "MT",
        latitude: "33.97159040",
        longitude: "-6.84981290",
        nameNative: null,
        label: "Rabat",
        cityCode: "MT_46",
      },
      {
        name: "Saint Lawrence",
        countryCode: "MT",
        latitude: "38.95780560",
        longitude: "-95.25656890",
        nameNative: null,
        label: "Saint Lawrence",
        cityCode: "MT_50",
      },
      {
        name: "San Ġwann",
        countryCode: "MT",
        latitude: "35.90773650",
        longitude: "14.47524160",
        nameNative: null,
        label: "San Ġwann",
        cityCode: "MT_49",
      },
      {
        name: "Sannat",
        countryCode: "MT",
        latitude: "36.01926430",
        longitude: "14.25994370",
        nameNative: null,
        label: "Sannat",
        cityCode: "MT_52",
      },
      {
        name: "Santa Luċija",
        countryCode: "MT",
        latitude: "35.85614200",
        longitude: "14.50436000",
        nameNative: null,
        label: "Santa Luċija",
        cityCode: "MT_53",
      },
      {
        name: "Santa Venera",
        countryCode: "MT",
        latitude: "35.89022010",
        longitude: "14.47669740",
        nameNative: null,
        label: "Santa Venera",
        cityCode: "MT_54",
      },
      {
        name: "Senglea",
        countryCode: "MT",
        latitude: "35.88730410",
        longitude: "14.51673710",
        nameNative: null,
        label: "Senglea",
        cityCode: "MT_20",
      },
      {
        name: "Siġġiewi",
        countryCode: "MT",
        latitude: "35.84637420",
        longitude: "14.43157460",
        nameNative: null,
        label: "Siġġiewi",
        cityCode: "MT_55",
      },
      {
        name: "Sliema",
        countryCode: "MT",
        latitude: "35.91100810",
        longitude: "14.50290400",
        nameNative: null,
        label: "Sliema",
        cityCode: "MT_56",
      },
      {
        name: "St. Julian's",
        countryCode: "MT",
        latitude: "42.21225130",
        longitude: "-85.89171270",
        nameNative: null,
        label: "St. Julian's",
        cityCode: "MT_48",
      },
      {
        name: "St. Paul's Bay",
        countryCode: "MT",
        latitude: "35.93601700",
        longitude: "14.39665030",
        nameNative: null,
        label: "St. Paul's Bay",
        cityCode: "MT_51",
      },
      {
        name: "Swieqi",
        countryCode: "MT",
        latitude: "35.91911820",
        longitude: "14.46941860",
        nameNative: null,
        label: "Swieqi",
        cityCode: "MT_57",
      },
      {
        name: "Ta' Xbiex",
        countryCode: "MT",
        latitude: "35.89914480",
        longitude: "14.49635190",
        nameNative: null,
        label: "Ta' Xbiex",
        cityCode: "MT_58",
      },
      {
        name: "Tarxien",
        countryCode: "MT",
        latitude: "35.86725520",
        longitude: "14.51164050",
        nameNative: null,
        label: "Tarxien",
        cityCode: "MT_59",
      },
      {
        name: "Valletta",
        countryCode: "MT",
        latitude: "35.89890850",
        longitude: "14.51455280",
        nameNative: null,
        label: "Valletta",
        cityCode: "MT_60",
      },
      {
        name: "Victoria",
        countryCode: "MT",
        latitude: "28.80526740",
        longitude: "-97.00359820",
        nameNative: null,
        label: "Victoria",
        cityCode: "MT_45",
      },
      {
        name: "Xagħra",
        countryCode: "MT",
        latitude: "36.05084500",
        longitude: "14.26748200",
        nameNative: null,
        label: "Xagħra",
        cityCode: "MT_61",
      },
      {
        name: "Xewkija",
        countryCode: "MT",
        latitude: "36.02992360",
        longitude: "14.25994370",
        nameNative: null,
        label: "Xewkija",
        cityCode: "MT_62",
      },
      {
        name: "Xgħajra",
        countryCode: "MT",
        latitude: "35.88682820",
        longitude: "14.54723910",
        nameNative: null,
        label: "Xgħajra",
        cityCode: "MT_63",
      },
      {
        name: "Ħamrun",
        countryCode: "MT",
        latitude: "35.88612370",
        longitude: "14.48834420",
        nameNative: null,
        label: "Ħamrun",
        cityCode: "MT_18",
      },
      {
        name: "Żabbar",
        countryCode: "MT",
        latitude: "35.87247150",
        longitude: "14.54513540",
        nameNative: null,
        label: "Żabbar",
        cityCode: "MT_64",
      },
      {
        name: "Żebbuġ Gozo",
        countryCode: "MT",
        latitude: "36.07164030",
        longitude: "14.24540800",
        nameNative: null,
        label: "Żebbuġ Gozo",
        cityCode: "MT_65",
      },
      {
        name: "Żebbuġ Malta",
        countryCode: "MT",
        latitude: "35.87646480",
        longitude: "14.43908400",
        nameNative: null,
        label: "Żebbuġ Malta",
        cityCode: "MT_66",
      },
      {
        name: "Żejtun",
        countryCode: "MT",
        latitude: "35.85487140",
        longitude: "14.53639690",
        nameNative: null,
        label: "Żejtun",
        cityCode: "MT_67",
      },
      {
        name: "Żurrieq",
        countryCode: "MT",
        latitude: "35.82163060",
        longitude: "14.48106480",
        nameNative: null,
        label: "Żurrieq",
        cityCode: "MT_68",
      },
    ],
    MH: [
      {
        name: "Ralik Chain",
        countryCode: "MH",
        latitude: "8.13614600",
        longitude: "164.88679560",
        nameNative: null,
        label: "Ralik Chain",
        cityCode: "MH_L",
      },
      {
        name: "Ratak Chain",
        countryCode: "MH",
        latitude: "10.27632760",
        longitude: "170.55009370",
        nameNative: null,
        label: "Ratak Chain",
        cityCode: "MH_T",
      },
    ],
    MR: [
      {
        name: "Adrar Region",
        countryCode: "MR",
        latitude: "19.86521760",
        longitude: "-12.80547530",
        nameNative: null,
        label: "Adrar Region",
        cityCode: "MR_07",
      },
      {
        name: "Assaba Region",
        countryCode: "MR",
        latitude: "16.77595580",
        longitude: "-11.52480550",
        nameNative: null,
        label: "Assaba Region",
        cityCode: "MR_03",
      },
      {
        name: "Brakna Region",
        countryCode: "MR",
        latitude: "17.23175610",
        longitude: "-13.17403480",
        nameNative: null,
        label: "Brakna Region",
        cityCode: "MR_05",
      },
      {
        name: "Dakhlet Nouadhibou",
        countryCode: "MR",
        latitude: "20.59855880",
        longitude: "-16.25221430",
        nameNative: null,
        label: "Dakhlet Nouadhibou",
        cityCode: "MR_08",
      },
      {
        name: "Gorgol Region",
        countryCode: "MR",
        latitude: "15.97173570",
        longitude: "-12.62162110",
        nameNative: null,
        label: "Gorgol Region",
        cityCode: "MR_04",
      },
      {
        name: "Guidimaka Region",
        countryCode: "MR",
        latitude: "15.25573310",
        longitude: "-12.25479190",
        nameNative: null,
        label: "Guidimaka Region",
        cityCode: "MR_10",
      },
      {
        name: "Hodh Ech Chargui Region",
        countryCode: "MR",
        latitude: "18.67370260",
        longitude: "-7.09287700",
        nameNative: null,
        label: "Hodh Ech Chargui Region",
        cityCode: "MR_01",
      },
      {
        name: "Hodh El Gharbi Region",
        countryCode: "MR",
        latitude: "16.69121490",
        longitude: "-9.54509740",
        nameNative: null,
        label: "Hodh El Gharbi Region",
        cityCode: "MR_02",
      },
      {
        name: "Inchiri Region",
        countryCode: "MR",
        latitude: "20.02805610",
        longitude: "-15.40680790",
        nameNative: null,
        label: "Inchiri Region",
        cityCode: "MR_12",
      },
      {
        name: "Nouakchott-Nord Region",
        countryCode: "MR",
        latitude: "18.11302050",
        longitude: "-15.89949560",
        nameNative: null,
        label: "Nouakchott-Nord Region",
        cityCode: "MR_14",
      },
      {
        name: "Nouakchott-Ouest Region",
        countryCode: "MR",
        latitude: "18.15113570",
        longitude: "-15.99349100",
        nameNative: null,
        label: "Nouakchott-Ouest Region",
        cityCode: "MR_13",
      },
      {
        name: "Nouakchott-Sud Region",
        countryCode: "MR",
        latitude: "17.97092880",
        longitude: "-15.94648740",
        nameNative: null,
        label: "Nouakchott-Sud Region",
        cityCode: "MR_15",
      },
      {
        name: "Tagant Region",
        countryCode: "MR",
        latitude: "18.54675270",
        longitude: "-9.90181310",
        nameNative: null,
        label: "Tagant Region",
        cityCode: "MR_09",
      },
      {
        name: "Tiris Zemmour Region",
        countryCode: "MR",
        latitude: "24.57737640",
        longitude: "-9.90181310",
        nameNative: null,
        label: "Tiris Zemmour Region",
        cityCode: "MR_11",
      },
      {
        name: "Trarza Region",
        countryCode: "MR",
        latitude: "17.86649640",
        longitude: "-14.65878210",
        nameNative: null,
        label: "Trarza Region",
        cityCode: "MR_06",
      },
    ],
    MU: [
      {
        name: "Agaléga",
        countryCode: "MU",
        latitude: "-10.40000000",
        longitude: "56.61666670",
        nameNative: null,
        label: "Agaléga",
        cityCode: "MU_AG",
      },
      {
        name: "Beau Bassin-Rose Hill",
        countryCode: "MU",
        latitude: "-20.22303050",
        longitude: "57.46838300",
        nameNative: null,
        label: "Beau Bassin-Rose Hill",
        cityCode: "MU_BR",
      },
      {
        name: "Cargados Carajos",
        countryCode: "MU",
        latitude: "-16.58333300",
        longitude: "59.61666700",
        nameNative: null,
        label: "Cargados Carajos",
        cityCode: "MU_CC",
      },
      {
        name: "Curepipe",
        countryCode: "MU",
        latitude: "-20.31708720",
        longitude: "57.52652890",
        nameNative: null,
        label: "Curepipe",
        cityCode: "MU_CU",
      },
      {
        name: "Flacq District",
        countryCode: "MU",
        latitude: "-20.22578360",
        longitude: "57.71192740",
        nameNative: null,
        label: "Flacq District",
        cityCode: "MU_FL",
      },
      {
        name: "Grand Port District",
        countryCode: "MU",
        latitude: "-20.38515460",
        longitude: "57.66657420",
        nameNative: null,
        label: "Grand Port District",
        cityCode: "MU_GP",
      },
      {
        name: "Moka District",
        countryCode: "MU",
        latitude: "-20.23997820",
        longitude: "57.57592600",
        nameNative: null,
        label: "Moka District",
        cityCode: "MU_MO",
      },
      {
        name: "Pamplemousses District",
        countryCode: "MU",
        latitude: "-20.11360080",
        longitude: "57.57592600",
        nameNative: null,
        label: "Pamplemousses District",
        cityCode: "MU_PA",
      },
      {
        name: "Plaines Wilhems District",
        countryCode: "MU",
        latitude: "-20.30548720",
        longitude: "57.48535610",
        nameNative: null,
        label: "Plaines Wilhems District",
        cityCode: "MU_PW",
      },
      {
        name: "Port Louis District",
        countryCode: "MU",
        latitude: "-20.16089120",
        longitude: "57.50122220",
        nameNative: null,
        label: "Port Louis District",
        cityCode: "MU_PL",
      },
      {
        name: "Port Louis",
        countryCode: "MU",
        latitude: "-20.16089120",
        longitude: "57.50122220",
        nameNative: null,
        label: "Port Louis",
        cityCode: "MU_PU",
      },
      {
        name: "Quatre Bornes",
        countryCode: "MU",
        latitude: "-20.26747180",
        longitude: "57.47969810",
        nameNative: null,
        label: "Quatre Bornes",
        cityCode: "MU_QB",
      },
      {
        name: "Rivière Noire District",
        countryCode: "MU",
        latitude: "-20.37084920",
        longitude: "57.39486490",
        nameNative: null,
        label: "Rivière Noire District",
        cityCode: "MU_BL",
      },
      {
        name: "Rivière du Rempart District",
        countryCode: "MU",
        latitude: "-20.05609830",
        longitude: "57.65523890",
        nameNative: null,
        label: "Rivière du Rempart District",
        cityCode: "MU_RR",
      },
      {
        name: "Rodrigues",
        countryCode: "MU",
        latitude: "-19.72453850",
        longitude: "63.42721850",
        nameNative: null,
        label: "Rodrigues",
        cityCode: "MU_RO",
      },
      {
        name: "Savanne District",
        countryCode: "MU",
        latitude: "-20.47395300",
        longitude: "57.48535610",
        nameNative: null,
        label: "Savanne District",
        cityCode: "MU_SA",
      },
      {
        name: "Vacoas-Phoenix",
        countryCode: "MU",
        latitude: "-20.29840260",
        longitude: "57.49383550",
        nameNative: null,
        label: "Vacoas-Phoenix",
        cityCode: "MU_VP",
      },
    ],
    MX: [
      {
        name: "Aguascalientes",
        countryCode: "MX",
        latitude: "21.88525620",
        longitude: "-102.29156770",
        nameNative: "Aguascalientes",
        label: "Aguascalientes / Aguascalientes",
        cityCode: "MX_AGU",
      },
      {
        name: "Baja California Sur",
        countryCode: "MX",
        latitude: "26.04444460",
        longitude: "-111.66607250",
        nameNative: "Baja California Sur",
        label: "Baja California Sur / Baja California Sur",
        cityCode: "MX_BCS",
      },
      {
        name: "Baja California",
        countryCode: "MX",
        latitude: "30.84063380",
        longitude: "-115.28375850",
        nameNative: "Baja California",
        label: "Baja California / Baja California",
        cityCode: "MX_BCN",
      },
      {
        name: "Campeche",
        countryCode: "MX",
        latitude: "19.83012510",
        longitude: "-90.53490870",
        nameNative: "Campeche",
        label: "Campeche / Campeche",
        cityCode: "MX_CAM",
      },
      {
        name: "Chiapas",
        countryCode: "MX",
        latitude: "16.75693180",
        longitude: "-93.12923530",
        nameNative: "Chiapas",
        label: "Chiapas / Chiapas",
        cityCode: "MX_CHP",
      },
      {
        name: "Chihuahua",
        countryCode: "MX",
        latitude: "28.63299570",
        longitude: "-106.06910040",
        nameNative: "Chihuahua",
        label: "Chihuahua / Chihuahua",
        cityCode: "MX_CHH",
      },
      {
        name: "Ciudad de México",
        countryCode: "MX",
        latitude: "19.43260770",
        longitude: "-99.13320800",
        nameNative: "Ciudad de México",
        label: "Ciudad de México / Ciudad de México",
        cityCode: "MX_CDMX",
      },
      {
        name: "Coahuila de Zaragoza",
        countryCode: "MX",
        latitude: "27.05867600",
        longitude: "-101.70682940",
        nameNative: "Coahuila de Zaragoza",
        label: "Coahuila de Zaragoza / Coahuila de Zaragoza",
        cityCode: "MX_COA",
      },
      {
        name: "Colima",
        countryCode: "MX",
        latitude: "19.24523420",
        longitude: "-103.72408680",
        nameNative: "Colima",
        label: "Colima / Colima",
        cityCode: "MX_COL",
      },
      {
        name: "Durango",
        countryCode: "MX",
        latitude: "37.27528000",
        longitude: "-107.88006670",
        nameNative: "Durango",
        label: "Durango / Durango",
        cityCode: "MX_DUR",
      },
      {
        name: "Estado de México",
        countryCode: "MX",
        latitude: "23.63450100",
        longitude: "-102.55278400",
        nameNative: "Estado de México",
        label: "Estado de México / Estado de México",
        cityCode: "MX_MEX",
      },
      {
        name: "Guanajuato",
        countryCode: "MX",
        latitude: "21.01901450",
        longitude: "-101.25735860",
        nameNative: "Guanajuato",
        label: "Guanajuato / Guanajuato",
        cityCode: "MX_GUA",
      },
      {
        name: "Guerrero",
        countryCode: "MX",
        latitude: "17.43919260",
        longitude: "-99.54509740",
        nameNative: "Guerrero",
        label: "Guerrero / Guerrero",
        cityCode: "MX_GRO",
      },
      {
        name: "Hidalgo",
        countryCode: "MX",
        latitude: "26.10035470",
        longitude: "-98.26306840",
        nameNative: "Hidalgo",
        label: "Hidalgo / Hidalgo",
        cityCode: "MX_HID",
      },
      {
        name: "Jalisco",
        countryCode: "MX",
        latitude: "20.65953820",
        longitude: "-103.34943760",
        nameNative: "Jalisco",
        label: "Jalisco / Jalisco",
        cityCode: "MX_JAL",
      },
      {
        name: "Michoacán de Ocampo",
        countryCode: "MX",
        latitude: "19.56651920",
        longitude: "-101.70682940",
        nameNative: "Michoacán de Ocampo",
        label: "Michoacán de Ocampo / Michoacán de Ocampo",
        cityCode: "MX_MIC",
      },
      {
        name: "Morelos",
        countryCode: "MX",
        latitude: "18.68130490",
        longitude: "-99.10134980",
        nameNative: "Morelos",
        label: "Morelos / Morelos",
        cityCode: "MX_MOR",
      },
      {
        name: "Nayarit",
        countryCode: "MX",
        latitude: "21.75138440",
        longitude: "-104.84546190",
        nameNative: "Nayarit",
        label: "Nayarit / Nayarit",
        cityCode: "MX_NAY",
      },
      {
        name: "Nuevo León",
        countryCode: "MX",
        latitude: "25.59217200",
        longitude: "-99.99619470",
        nameNative: "Nuevo León",
        label: "Nuevo León / Nuevo León",
        cityCode: "MX_NLE",
      },
      {
        name: "Oaxaca",
        countryCode: "MX",
        latitude: "17.07318420",
        longitude: "-96.72658890",
        nameNative: "Oaxaca",
        label: "Oaxaca / Oaxaca",
        cityCode: "MX_OAX",
      },
      {
        name: "Puebla",
        countryCode: "MX",
        latitude: "19.04143980",
        longitude: "-98.20627270",
        nameNative: "Puebla",
        label: "Puebla / Puebla",
        cityCode: "MX_PUE",
      },
      {
        name: "Querétaro",
        countryCode: "MX",
        latitude: "20.58879320",
        longitude: "-100.38988810",
        nameNative: "Querétaro",
        label: "Querétaro / Querétaro",
        cityCode: "MX_QUE",
      },
      {
        name: "Quintana Roo",
        countryCode: "MX",
        latitude: "19.18173930",
        longitude: "-88.47913760",
        nameNative: "Quintana Roo",
        label: "Quintana Roo / Quintana Roo",
        cityCode: "MX_ROO",
      },
      {
        name: "San Luis Potosí",
        countryCode: "MX",
        latitude: "22.15646990",
        longitude: "-100.98554090",
        nameNative: "San Luis Potosí",
        label: "San Luis Potosí / San Luis Potosí",
        cityCode: "MX_SLP",
      },
      {
        name: "Sinaloa",
        countryCode: "MX",
        latitude: "25.17210910",
        longitude: "-107.47951730",
        nameNative: "Sinaloa",
        label: "Sinaloa / Sinaloa",
        cityCode: "MX_SIN",
      },
      {
        name: "Sonora",
        countryCode: "MX",
        latitude: "37.98294960",
        longitude: "-120.38217240",
        nameNative: "Sonora",
        label: "Sonora / Sonora",
        cityCode: "MX_SON",
      },
      {
        name: "Tabasco",
        countryCode: "MX",
        latitude: "17.84091730",
        longitude: "-92.61892730",
        nameNative: "Tabasco",
        label: "Tabasco / Tabasco",
        cityCode: "MX_TAB",
      },
      {
        name: "Tamaulipas",
        countryCode: "MX",
        latitude: "24.26694000",
        longitude: "-98.83627550",
        nameNative: "Tamaulipas",
        label: "Tamaulipas / Tamaulipas",
        cityCode: "MX_TAM",
      },
      {
        name: "Tlaxcala",
        countryCode: "MX",
        latitude: "19.31815400",
        longitude: "-98.23749540",
        nameNative: "Tlaxcala",
        label: "Tlaxcala / Tlaxcala",
        cityCode: "MX_TLA",
      },
      {
        name: "Veracruz de Ignacio de la Llave",
        countryCode: "MX",
        latitude: "19.17377300",
        longitude: "-96.13422410",
        nameNative: "Veracruz de Ignacio de la Llave",
        label:
          "Veracruz de Ignacio de la Llave / Veracruz de Ignacio de la Llave",
        cityCode: "MX_VER",
      },
      {
        name: "Yucatán",
        countryCode: "MX",
        latitude: "20.70987860",
        longitude: "-89.09433770",
        nameNative: "Yucatán",
        label: "Yucatán / Yucatán",
        cityCode: "MX_YUC",
      },
      {
        name: "Zacatecas",
        countryCode: "MX",
        latitude: "22.77085550",
        longitude: "-102.58324260",
        nameNative: "Zacatecas",
        label: "Zacatecas / Zacatecas",
        cityCode: "MX_ZAC",
      },
    ],
    FM: [
      {
        name: "Chuuk State",
        countryCode: "FM",
        latitude: "7.13867590",
        longitude: "151.55930650",
        nameNative: null,
        label: "Chuuk State",
        cityCode: "FM_TRK",
      },
      {
        name: "Kosrae State",
        countryCode: "FM",
        latitude: "5.30956180",
        longitude: "162.98148770",
        nameNative: null,
        label: "Kosrae State",
        cityCode: "FM_KSA",
      },
      {
        name: "Pohnpei State",
        countryCode: "FM",
        latitude: "6.85412540",
        longitude: "158.26238220",
        nameNative: null,
        label: "Pohnpei State",
        cityCode: "FM_PNI",
      },
      {
        name: "Yap State",
        countryCode: "FM",
        latitude: "8.67164900",
        longitude: "142.84393350",
        nameNative: null,
        label: "Yap State",
        cityCode: "FM_YAP",
      },
    ],
    MD: [
      {
        name: "Anenii Noi District",
        countryCode: "MD",
        latitude: "46.87956630",
        longitude: "29.23121750",
        nameNative: null,
        label: "Anenii Noi District",
        cityCode: "MD_AN",
      },
      {
        name: "Basarabeasca District",
        countryCode: "MD",
        latitude: "46.42370600",
        longitude: "28.89354920",
        nameNative: null,
        label: "Basarabeasca District",
        cityCode: "MD_BS",
      },
      {
        name: "Bender Municipality",
        countryCode: "MD",
        latitude: "46.82275510",
        longitude: "29.46201010",
        nameNative: null,
        label: "Bender Municipality",
        cityCode: "MD_BD",
      },
      {
        name: "Briceni District",
        countryCode: "MD",
        latitude: "48.36320220",
        longitude: "27.07503980",
        nameNative: null,
        label: "Briceni District",
        cityCode: "MD_BR",
      },
      {
        name: "Bălți Municipality",
        countryCode: "MD",
        latitude: "47.75399470",
        longitude: "27.91841480",
        nameNative: null,
        label: "Bălți Municipality",
        cityCode: "MD_BA",
      },
      {
        name: "Cahul District",
        countryCode: "MD",
        latitude: "45.89394040",
        longitude: "28.18902750",
        nameNative: null,
        label: "Cahul District",
        cityCode: "MD_CA",
      },
      {
        name: "Cantemir District",
        countryCode: "MD",
        latitude: "46.27717420",
        longitude: "28.20096530",
        nameNative: null,
        label: "Cantemir District",
        cityCode: "MD_CT",
      },
      {
        name: "Chișinău Municipality",
        countryCode: "MD",
        latitude: "47.01045290",
        longitude: "28.86381020",
        nameNative: null,
        label: "Chișinău Municipality",
        cityCode: "MD_CU",
      },
      {
        name: "Cimișlia District",
        countryCode: "MD",
        latitude: "46.52508510",
        longitude: "28.77218350",
        nameNative: null,
        label: "Cimișlia District",
        cityCode: "MD_CM",
      },
      {
        name: "Criuleni District",
        countryCode: "MD",
        latitude: "47.21361140",
        longitude: "29.15575190",
        nameNative: null,
        label: "Criuleni District",
        cityCode: "MD_CR",
      },
      {
        name: "Călărași District",
        countryCode: "MD",
        latitude: "47.28694600",
        longitude: "28.27453100",
        nameNative: null,
        label: "Călărași District",
        cityCode: "MD_CL",
      },
      {
        name: "Căușeni District",
        countryCode: "MD",
        latitude: "46.65547150",
        longitude: "29.40912220",
        nameNative: null,
        label: "Căușeni District",
        cityCode: "MD_CS",
      },
      {
        name: "Dondușeni District",
        countryCode: "MD",
        latitude: "48.23383050",
        longitude: "27.59980870",
        nameNative: null,
        label: "Dondușeni District",
        cityCode: "MD_DO",
      },
      {
        name: "Drochia District",
        countryCode: "MD",
        latitude: "48.07977880",
        longitude: "27.86041140",
        nameNative: null,
        label: "Drochia District",
        cityCode: "MD_DR",
      },
      {
        name: "Dubăsari District",
        countryCode: "MD",
        latitude: "47.26439420",
        longitude: "29.15503480",
        nameNative: null,
        label: "Dubăsari District",
        cityCode: "MD_DU",
      },
      {
        name: "Edineț District",
        countryCode: "MD",
        latitude: "48.16789910",
        longitude: "27.29361430",
        nameNative: null,
        label: "Edineț District",
        cityCode: "MD_ED",
      },
      {
        name: "Florești District",
        countryCode: "MD",
        latitude: "47.86678490",
        longitude: "28.33918640",
        nameNative: null,
        label: "Florești District",
        cityCode: "MD_FL",
      },
      {
        name: "Fălești District",
        countryCode: "MD",
        latitude: "47.56477250",
        longitude: "27.72655930",
        nameNative: null,
        label: "Fălești District",
        cityCode: "MD_FA",
      },
      {
        name: "Gagauzia",
        countryCode: "MD",
        latitude: "46.09794350",
        longitude: "28.63846450",
        nameNative: null,
        label: "Gagauzia",
        cityCode: "MD_GA",
      },
      {
        name: "Glodeni District",
        countryCode: "MD",
        latitude: "47.77901560",
        longitude: "27.51680100",
        nameNative: null,
        label: "Glodeni District",
        cityCode: "MD_GL",
      },
      {
        name: "Hîncești District",
        countryCode: "MD",
        latitude: "46.82811470",
        longitude: "28.58508890",
        nameNative: null,
        label: "Hîncești District",
        cityCode: "MD_HI",
      },
      {
        name: "Ialoveni District",
        countryCode: "MD",
        latitude: "46.86308600",
        longitude: "28.82342180",
        nameNative: null,
        label: "Ialoveni District",
        cityCode: "MD_IA",
      },
      {
        name: "Nisporeni District",
        countryCode: "MD",
        latitude: "47.07513490",
        longitude: "28.17681550",
        nameNative: null,
        label: "Nisporeni District",
        cityCode: "MD_NI",
      },
      {
        name: "Ocnița District",
        countryCode: "MD",
        latitude: "48.41104350",
        longitude: "27.47680920",
        nameNative: null,
        label: "Ocnița District",
        cityCode: "MD_OC",
      },
      {
        name: "Orhei District",
        countryCode: "MD",
        latitude: "47.38604000",
        longitude: "28.83030820",
        nameNative: null,
        label: "Orhei District",
        cityCode: "MD_OR",
      },
      {
        name: "Rezina District",
        countryCode: "MD",
        latitude: "47.71804470",
        longitude: "28.88710240",
        nameNative: null,
        label: "Rezina District",
        cityCode: "MD_RE",
      },
      {
        name: "Rîșcani District",
        countryCode: "MD",
        latitude: "47.90701530",
        longitude: "27.53749960",
        nameNative: null,
        label: "Rîșcani District",
        cityCode: "MD_RI",
      },
      {
        name: "Soroca District",
        countryCode: "MD",
        latitude: "48.15497430",
        longitude: "28.28707830",
        nameNative: null,
        label: "Soroca District",
        cityCode: "MD_SO",
      },
      {
        name: "Strășeni District",
        countryCode: "MD",
        latitude: "47.14502670",
        longitude: "28.61367360",
        nameNative: null,
        label: "Strășeni District",
        cityCode: "MD_ST",
      },
      {
        name: "Sîngerei District",
        countryCode: "MD",
        latitude: "47.63891340",
        longitude: "28.13718160",
        nameNative: null,
        label: "Sîngerei District",
        cityCode: "MD_SI",
      },
      {
        name: "Taraclia District",
        countryCode: "MD",
        latitude: "45.89865100",
        longitude: "28.66716440",
        nameNative: null,
        label: "Taraclia District",
        cityCode: "MD_TA",
      },
      {
        name: "Telenești District",
        countryCode: "MD",
        latitude: "47.49839620",
        longitude: "28.36760190",
        nameNative: null,
        label: "Telenești District",
        cityCode: "MD_TE",
      },
      {
        name: "Transnistria autonomous territorial unit",
        countryCode: "MD",
        latitude: "47.21529720",
        longitude: "29.46380540",
        nameNative: null,
        label: "Transnistria autonomous territorial unit",
        cityCode: "MD_SN",
      },
      {
        name: "Ungheni District",
        countryCode: "MD",
        latitude: "47.23057670",
        longitude: "27.78926610",
        nameNative: null,
        label: "Ungheni District",
        cityCode: "MD_UN",
      },
      {
        name: "Șoldănești District",
        countryCode: "MD",
        latitude: "47.81473890",
        longitude: "28.78895860",
        nameNative: null,
        label: "Șoldănești District",
        cityCode: "MD_SD",
      },
      {
        name: "Ștefan Vodă District",
        countryCode: "MD",
        latitude: "46.55404880",
        longitude: "29.70224200",
        nameNative: null,
        label: "Ștefan Vodă District",
        cityCode: "MD_SV",
      },
    ],
    MC: [
      {
        name: "La Colle",
        countryCode: "MC",
        latitude: "43.73274650",
        longitude: "7.41372760",
        nameNative: null,
        label: "La Colle",
        cityCode: "MC_CL",
      },
      {
        name: "La Condamine",
        countryCode: "MC",
        latitude: "43.73506650",
        longitude: "7.41990600",
        nameNative: null,
        label: "La Condamine",
        cityCode: "MC_CO",
      },
      {
        name: "Moneghetti",
        countryCode: "MC",
        latitude: "43.73649270",
        longitude: "7.41533830",
        nameNative: null,
        label: "Moneghetti",
        cityCode: "MC_MG",
      },
    ],
    MN: [
      {
        name: "Arkhangai Province",
        countryCode: "MN",
        latitude: "47.89711010",
        longitude: "100.72401650",
        nameNative: null,
        label: "Arkhangai Province",
        cityCode: "MN_073",
      },
      {
        name: "Bayan-Ölgii Province",
        countryCode: "MN",
        latitude: "48.39832540",
        longitude: "89.66259150",
        nameNative: null,
        label: "Bayan-Ölgii Province",
        cityCode: "MN_071",
      },
      {
        name: "Bayankhongor Province",
        countryCode: "MN",
        latitude: "45.15267070",
        longitude: "100.10736670",
        nameNative: null,
        label: "Bayankhongor Province",
        cityCode: "MN_069",
      },
      {
        name: "Bulgan Province",
        countryCode: "MN",
        latitude: "48.96909130",
        longitude: "102.88317230",
        nameNative: null,
        label: "Bulgan Province",
        cityCode: "MN_067",
      },
      {
        name: "Darkhan-Uul Province",
        countryCode: "MN",
        latitude: "49.46484340",
        longitude: "105.97459190",
        nameNative: null,
        label: "Darkhan-Uul Province",
        cityCode: "MN_037",
      },
      {
        name: "Dornod Province",
        countryCode: "MN",
        latitude: "47.46581540",
        longitude: "115.39271200",
        nameNative: null,
        label: "Dornod Province",
        cityCode: "MN_061",
      },
      {
        name: "Dornogovi Province",
        countryCode: "MN",
        latitude: "43.96538890",
        longitude: "109.17734590",
        nameNative: null,
        label: "Dornogovi Province",
        cityCode: "MN_063",
      },
      {
        name: "Dundgovi Province",
        countryCode: "MN",
        latitude: "45.58227860",
        longitude: "106.76442090",
        nameNative: null,
        label: "Dundgovi Province",
        cityCode: "MN_059",
      },
      {
        name: "Govi-Altai Province",
        countryCode: "MN",
        latitude: "45.45112270",
        longitude: "95.85057660",
        nameNative: null,
        label: "Govi-Altai Province",
        cityCode: "MN_065",
      },
      {
        name: "Govisümber Province",
        countryCode: "MN",
        latitude: "46.47627540",
        longitude: "108.55706270",
        nameNative: null,
        label: "Govisümber Province",
        cityCode: "MN_064",
      },
      {
        name: "Khentii Province",
        countryCode: "MN",
        latitude: "47.60812090",
        longitude: "109.93728560",
        nameNative: null,
        label: "Khentii Province",
        cityCode: "MN_039",
      },
      {
        name: "Khovd Province",
        countryCode: "MN",
        latitude: "47.11296540",
        longitude: "92.31107520",
        nameNative: null,
        label: "Khovd Province",
        cityCode: "MN_043",
      },
      {
        name: "Khövsgöl Province",
        countryCode: "MN",
        latitude: "50.22044840",
        longitude: "100.32137680",
        nameNative: null,
        label: "Khövsgöl Province",
        cityCode: "MN_041",
      },
      {
        name: "Orkhon Province",
        countryCode: "MN",
        latitude: "49.00470500",
        longitude: "104.30165270",
        nameNative: null,
        label: "Orkhon Province",
        cityCode: "MN_035",
      },
      {
        name: "Selenge Province",
        countryCode: "MN",
        latitude: "50.00592730",
        longitude: "106.44341080",
        nameNative: null,
        label: "Selenge Province",
        cityCode: "MN_049",
      },
      {
        name: "Sükhbaatar Province",
        countryCode: "MN",
        latitude: "46.56531630",
        longitude: "113.53808360",
        nameNative: null,
        label: "Sükhbaatar Province",
        cityCode: "MN_051",
      },
      {
        name: "Töv Province",
        countryCode: "MN",
        latitude: "47.21240560",
        longitude: "106.41541000",
        nameNative: null,
        label: "Töv Province",
        cityCode: "MN_047",
      },
      {
        name: "Uvs Province",
        countryCode: "MN",
        latitude: "49.64497070",
        longitude: "93.27365760",
        nameNative: null,
        label: "Uvs Province",
        cityCode: "MN_046",
      },
      {
        name: "Zavkhan Province",
        countryCode: "MN",
        latitude: "48.23881470",
        longitude: "96.07030190",
        nameNative: null,
        label: "Zavkhan Province",
        cityCode: "MN_057",
      },
      {
        name: "Ömnögovi Province",
        countryCode: "MN",
        latitude: "43.50002400",
        longitude: "104.28611160",
        nameNative: null,
        label: "Ömnögovi Province",
        cityCode: "MN_053",
      },
      {
        name: "Övörkhangai Province",
        countryCode: "MN",
        latitude: "45.76243920",
        longitude: "103.09170320",
        nameNative: null,
        label: "Övörkhangai Province",
        cityCode: "MN_055",
      },
    ],
    ME: [
      {
        name: "Andrijevica Municipality",
        countryCode: "ME",
        latitude: "42.73624770",
        longitude: "19.78595560",
        nameNative: null,
        label: "Andrijevica Municipality",
        cityCode: "ME_01",
      },
      {
        name: "Bar Municipality",
        countryCode: "ME",
        latitude: "42.12781190",
        longitude: "19.14043800",
        nameNative: null,
        label: "Bar Municipality",
        cityCode: "ME_02",
      },
      {
        name: "Berane Municipality",
        countryCode: "ME",
        latitude: "42.82572890",
        longitude: "19.90205090",
        nameNative: null,
        label: "Berane Municipality",
        cityCode: "ME_03",
      },
      {
        name: "Bijelo Polje Municipality",
        countryCode: "ME",
        latitude: "43.08465260",
        longitude: "19.71154720",
        nameNative: null,
        label: "Bijelo Polje Municipality",
        cityCode: "ME_04",
      },
      {
        name: "Budva Municipality",
        countryCode: "ME",
        latitude: "42.31407200",
        longitude: "18.83138320",
        nameNative: null,
        label: "Budva Municipality",
        cityCode: "ME_05",
      },
      {
        name: "Danilovgrad Municipality",
        countryCode: "ME",
        latitude: "42.58357000",
        longitude: "19.14043800",
        nameNative: null,
        label: "Danilovgrad Municipality",
        cityCode: "ME_07",
      },
      {
        name: "Gusinje Municipality",
        countryCode: "ME",
        latitude: "42.55634550",
        longitude: "19.83060510",
        nameNative: null,
        label: "Gusinje Municipality",
        cityCode: "ME_22",
      },
      {
        name: "Kolašin Municipality",
        countryCode: "ME",
        latitude: "42.76019160",
        longitude: "19.42591140",
        nameNative: null,
        label: "Kolašin Municipality",
        cityCode: "ME_09",
      },
      {
        name: "Kotor Municipality",
        countryCode: "ME",
        latitude: "42.57402610",
        longitude: "18.64131450",
        nameNative: null,
        label: "Kotor Municipality",
        cityCode: "ME_10",
      },
      {
        name: "Mojkovac Municipality",
        countryCode: "ME",
        latitude: "42.96880180",
        longitude: "19.52110630",
        nameNative: null,
        label: "Mojkovac Municipality",
        cityCode: "ME_11",
      },
      {
        name: "Nikšić Municipality",
        countryCode: "ME",
        latitude: "42.79971840",
        longitude: "18.76009630",
        nameNative: null,
        label: "Nikšić Municipality",
        cityCode: "ME_12",
      },
      {
        name: "Old Royal Capital Cetinje",
        countryCode: "ME",
        latitude: "42.39309590",
        longitude: "18.91159640",
        nameNative: null,
        label: "Old Royal Capital Cetinje",
        cityCode: "ME_06",
      },
      {
        name: "Petnjica Municipality",
        countryCode: "ME",
        latitude: "42.93534800",
        longitude: "20.02114490",
        nameNative: null,
        label: "Petnjica Municipality",
        cityCode: "ME_23",
      },
      {
        name: "Plav Municipality",
        countryCode: "ME",
        latitude: "42.60013370",
        longitude: "19.94075410",
        nameNative: null,
        label: "Plav Municipality",
        cityCode: "ME_13",
      },
      {
        name: "Pljevlja Municipality",
        countryCode: "ME",
        latitude: "43.27233830",
        longitude: "19.28315310",
        nameNative: null,
        label: "Pljevlja Municipality",
        cityCode: "ME_14",
      },
      {
        name: "Plužine Municipality",
        countryCode: "ME",
        latitude: "43.15933840",
        longitude: "18.85514840",
        nameNative: null,
        label: "Plužine Municipality",
        cityCode: "ME_15",
      },
      {
        name: "Podgorica Municipality",
        countryCode: "ME",
        latitude: "42.36938340",
        longitude: "19.28315310",
        nameNative: null,
        label: "Podgorica Municipality",
        cityCode: "ME_16",
      },
      {
        name: "Rožaje Municipality",
        countryCode: "ME",
        latitude: "42.84083890",
        longitude: "20.16706280",
        nameNative: null,
        label: "Rožaje Municipality",
        cityCode: "ME_17",
      },
      {
        name: "Tivat Municipality",
        countryCode: "ME",
        latitude: "42.42348000",
        longitude: "18.71851840",
        nameNative: null,
        label: "Tivat Municipality",
        cityCode: "ME_19",
      },
      {
        name: "Ulcinj Municipality",
        countryCode: "ME",
        latitude: "41.96527950",
        longitude: "19.30694320",
        nameNative: null,
        label: "Ulcinj Municipality",
        cityCode: "ME_20",
      },
      {
        name: "Šavnik Municipality",
        countryCode: "ME",
        latitude: "42.96037560",
        longitude: "19.14043800",
        nameNative: null,
        label: "Šavnik Municipality",
        cityCode: "ME_18",
      },
      {
        name: "Žabljak Municipality",
        countryCode: "ME",
        latitude: "43.15551520",
        longitude: "19.12260180",
        nameNative: null,
        label: "Žabljak Municipality",
        cityCode: "ME_21",
      },
    ],
    MA: [
      {
        name: "Agadir-Ida-Ou-Tanane",
        countryCode: "MA",
        latitude: "30.64620910",
        longitude: "-9.83390610",
        nameNative: null,
        label: "Agadir-Ida-Ou-Tanane",
        cityCode: "MA_AGD",
      },
      {
        name: "Al Haouz",
        countryCode: "MA",
        latitude: "31.29567290",
        longitude: "-7.87216000",
        nameNative: null,
        label: "Al Haouz",
        cityCode: "MA_HAO",
      },
      {
        name: "Al Hoceïma",
        countryCode: "MA",
        latitude: "35.24455890",
        longitude: "-3.93174680",
        nameNative: null,
        label: "Al Hoceïma",
        cityCode: "MA_HOC",
      },
      {
        name: "Aousserd (EH)",
        countryCode: "MA",
        latitude: "22.55215380",
        longitude: "-14.32973530",
        nameNative: null,
        label: "Aousserd (EH)",
        cityCode: "MA_AOU",
      },
      {
        name: "Assa-Zag (EH-partial)",
        countryCode: "MA",
        latitude: "28.14023950",
        longitude: "-9.72326730",
        nameNative: null,
        label: "Assa-Zag (EH-partial)",
        cityCode: "MA_ASZ",
      },
      {
        name: "Azilal",
        countryCode: "MA",
        latitude: "32.00426200",
        longitude: "-6.57833870",
        nameNative: null,
        label: "Azilal",
        cityCode: "MA_AZI",
      },
      {
        name: "Benslimane",
        countryCode: "MA",
        latitude: "33.61896980",
        longitude: "-7.13055360",
        nameNative: null,
        label: "Benslimane",
        cityCode: "MA_BES",
      },
      {
        name: "Berkane",
        countryCode: "MA",
        latitude: "34.88408760",
        longitude: "-2.34188700",
        nameNative: null,
        label: "Berkane",
        cityCode: "MA_BER",
      },
      {
        name: "Berrechid",
        countryCode: "MA",
        latitude: "33.26025230",
        longitude: "-7.59848370",
        nameNative: null,
        label: "Berrechid",
        cityCode: "MA_BRR",
      },
      {
        name: "Boujdour (EH)",
        countryCode: "MA",
        latitude: "26.12524930",
        longitude: "-14.48473470",
        nameNative: null,
        label: "Boujdour (EH)",
        cityCode: "MA_BOD",
      },
      {
        name: "Boulemane",
        countryCode: "MA",
        latitude: "33.36251590",
        longitude: "-4.73033970",
        nameNative: null,
        label: "Boulemane",
        cityCode: "MA_BOM",
      },
      {
        name: "Béni Mellal",
        countryCode: "MA",
        latitude: "32.34244300",
        longitude: "-6.37579900",
        nameNative: null,
        label: "Béni Mellal",
        cityCode: "MA_BEM",
      },
      {
        name: "Béni Mellal-Khénifra",
        countryCode: "MA",
        latitude: "32.57191840",
        longitude: "-6.06791940",
        nameNative: null,
        label: "Béni Mellal-Khénifra",
        cityCode: "MA_05",
      },
      {
        name: "Casablanca",
        countryCode: "MA",
        latitude: "33.57226780",
        longitude: "-7.65703260",
        nameNative: null,
        label: "Casablanca",
        cityCode: "MA_CAS",
      },
      {
        name: "Casablanca-Settat",
        countryCode: "MA",
        latitude: "33.21608720",
        longitude: "-7.43813550",
        nameNative: null,
        label: "Casablanca-Settat",
        cityCode: "MA_06",
      },
      {
        name: "Chefchaouen",
        countryCode: "MA",
        latitude: "35.01817200",
        longitude: "-5.14320680",
        nameNative: null,
        label: "Chefchaouen",
        cityCode: "MA_CHE",
      },
      {
        name: "Chichaoua",
        countryCode: "MA",
        latitude: "31.53835810",
        longitude: "-8.76463880",
        nameNative: null,
        label: "Chichaoua",
        cityCode: "MA_CHI",
      },
      {
        name: "Chtouka-Ait Baha",
        countryCode: "MA",
        latitude: "30.10724220",
        longitude: "-9.27855830",
        nameNative: null,
        label: "Chtouka-Ait Baha",
        cityCode: "MA_CHT",
      },
      {
        name: "Dakhla-Oued Ed-Dahab (EH)",
        countryCode: "MA",
        latitude: "22.73378920",
        longitude: "-14.28611160",
        nameNative: null,
        label: "Dakhla-Oued Ed-Dahab (EH)",
        cityCode: "MA_12",
      },
      {
        name: "Driouch",
        countryCode: "MA",
        latitude: "34.97603200",
        longitude: "-3.39644930",
        nameNative: null,
        label: "Driouch",
        cityCode: "MA_DRI",
      },
      {
        name: "Drâa-Tafilalet",
        countryCode: "MA",
        latitude: "31.14995380",
        longitude: "-5.39395510",
        nameNative: null,
        label: "Drâa-Tafilalet",
        cityCode: "MA_08",
      },
      {
        name: "El Hajeb",
        countryCode: "MA",
        latitude: "33.68573500",
        longitude: "-5.36778440",
        nameNative: null,
        label: "El Hajeb",
        cityCode: "MA_HAJ",
      },
      {
        name: "El Jadida",
        countryCode: "MA",
        latitude: "33.23163260",
        longitude: "-8.50071160",
        nameNative: null,
        label: "El Jadida",
        cityCode: "MA_JDI",
      },
      {
        name: "El Kelâa des Sraghna",
        countryCode: "MA",
        latitude: "32.05227670",
        longitude: "-7.35165580",
        nameNative: null,
        label: "El Kelâa des Sraghna",
        cityCode: "MA_KES",
      },
      {
        name: "Errachidia",
        countryCode: "MA",
        latitude: "31.90512750",
        longitude: "-4.72775280",
        nameNative: null,
        label: "Errachidia",
        cityCode: "MA_ERR",
      },
      {
        name: "Es-Semara (EH-partial)",
        countryCode: "MA",
        latitude: "26.74185600",
        longitude: "-11.67836710",
        nameNative: null,
        label: "Es-Semara (EH-partial)",
        cityCode: "MA_ESM",
      },
      {
        name: "Essaouira",
        countryCode: "MA",
        latitude: "31.50849260",
        longitude: "-9.75950410",
        nameNative: null,
        label: "Essaouira",
        cityCode: "MA_ESI",
      },
      {
        name: "Fahs-Anjra",
        countryCode: "MA",
        latitude: "35.76019920",
        longitude: "-5.66683060",
        nameNative: null,
        label: "Fahs-Anjra",
        cityCode: "MA_FAH",
      },
      {
        name: "Figuig",
        countryCode: "MA",
        latitude: "32.10926130",
        longitude: "-1.22980600",
        nameNative: null,
        label: "Figuig",
        cityCode: "MA_FIG",
      },
      {
        name: "Fquih Ben Salah",
        countryCode: "MA",
        latitude: "32.50016800",
        longitude: "-6.71007170",
        nameNative: null,
        label: "Fquih Ben Salah",
        cityCode: "MA_FQH",
      },
      {
        name: "Fès",
        countryCode: "MA",
        latitude: "34.02395790",
        longitude: "-5.03675990",
        nameNative: null,
        label: "Fès",
        cityCode: "MA_FES",
      },
      {
        name: "Fès-Meknès",
        countryCode: "MA",
        latitude: "34.06252900",
        longitude: "-4.72775280",
        nameNative: null,
        label: "Fès-Meknès",
        cityCode: "MA_03",
      },
      {
        name: "Guelmim",
        countryCode: "MA",
        latitude: "28.98836590",
        longitude: "-10.05274980",
        nameNative: null,
        label: "Guelmim",
        cityCode: "MA_GUE",
      },
      {
        name: "Guelmim-Oued Noun (EH-partial)",
        countryCode: "MA",
        latitude: "28.48442810",
        longitude: "-10.08072980",
        nameNative: null,
        label: "Guelmim-Oued Noun (EH-partial)",
        cityCode: "MA_10",
      },
      {
        name: "Guercif",
        countryCode: "MA",
        latitude: "34.23450360",
        longitude: "-3.38130050",
        nameNative: null,
        label: "Guercif",
        cityCode: "MA_GUF",
      },
      {
        name: "Ifrane",
        countryCode: "MA",
        latitude: "33.52280620",
        longitude: "-5.11095520",
        nameNative: null,
        label: "Ifrane",
        cityCode: "MA_IFR",
      },
      {
        name: "Inezgane-Ait Melloul",
        countryCode: "MA",
        latitude: "30.35090980",
        longitude: "-9.38951100",
        nameNative: null,
        label: "Inezgane-Ait Melloul",
        cityCode: "MA_INE",
      },
      {
        name: "Jerada",
        countryCode: "MA",
        latitude: "34.30617910",
        longitude: "-2.17941360",
        nameNative: null,
        label: "Jerada",
        cityCode: "MA_JRA",
      },
      {
        name: "Khouribga",
        countryCode: "MA",
        latitude: "32.88602300",
        longitude: "-6.92086550",
        nameNative: null,
        label: "Khouribga",
        cityCode: "MA_KHO",
      },
      {
        name: "Khémisset",
        countryCode: "MA",
        latitude: "33.81537040",
        longitude: "-6.05733020",
        nameNative: null,
        label: "Khémisset",
        cityCode: "MA_KHE",
      },
      {
        name: "Khénifra",
        countryCode: "MA",
        latitude: "32.93404710",
        longitude: "-5.66157100",
        nameNative: null,
        label: "Khénifra",
        cityCode: "MA_KHN",
      },
      {
        name: "Kénitra",
        countryCode: "MA",
        latitude: "34.25405030",
        longitude: "-6.58901660",
        nameNative: null,
        label: "Kénitra",
        cityCode: "MA_KEN",
      },
      {
        name: "L'Oriental",
        countryCode: "MA",
        latitude: "37.06968300",
        longitude: "-94.51227700",
        nameNative: null,
        label: "L'Oriental",
        cityCode: "MA_02",
      },
      {
        name: "Larache",
        countryCode: "MA",
        latitude: "35.17442710",
        longitude: "-6.14739640",
        nameNative: null,
        label: "Larache",
        cityCode: "MA_LAR",
      },
      {
        name: "Laâyoune (EH)",
        countryCode: "MA",
        latitude: "27.15003840",
        longitude: "-13.19907580",
        nameNative: null,
        label: "Laâyoune (EH)",
        cityCode: "MA_LAA",
      },
      {
        name: "Laâyoune-Sakia El Hamra (EH-partial)",
        countryCode: "MA",
        latitude: "27.86831940",
        longitude: "-11.98046130",
        nameNative: null,
        label: "Laâyoune-Sakia El Hamra (EH-partial)",
        cityCode: "MA_11",
      },
      {
        name: "Marrakech",
        countryCode: "MA",
        latitude: "31.63460230",
        longitude: "-8.07789320",
        nameNative: null,
        label: "Marrakech",
        cityCode: "MA_MAR",
      },
      {
        name: "Marrakesh-Safi",
        countryCode: "MA",
        latitude: "31.73308330",
        longitude: "-8.13385580",
        nameNative: null,
        label: "Marrakesh-Safi",
        cityCode: "MA_07",
      },
      {
        name: "Meknès",
        countryCode: "MA",
        latitude: "33.88100000",
        longitude: "-5.57303970",
        nameNative: null,
        label: "Meknès",
        cityCode: "MA_MEK",
      },
      {
        name: "Midelt",
        countryCode: "MA",
        latitude: "32.68550790",
        longitude: "-4.75017090",
        nameNative: null,
        label: "Midelt",
        cityCode: "MA_MID",
      },
      {
        name: "Mohammadia",
        countryCode: "MA",
        latitude: "33.68737490",
        longitude: "-7.42391420",
        nameNative: null,
        label: "Mohammadia",
        cityCode: "MA_MOH",
      },
      {
        name: "Moulay Yacoub",
        countryCode: "MA",
        latitude: "34.08744790",
        longitude: "-5.17840190",
        nameNative: null,
        label: "Moulay Yacoub",
        cityCode: "MA_MOU",
      },
      {
        name: "Médiouna",
        countryCode: "MA",
        latitude: "33.45409390",
        longitude: "-7.51660200",
        nameNative: null,
        label: "Médiouna",
        cityCode: "MA_MED",
      },
      {
        name: "M’diq-Fnideq",
        countryCode: "MA",
        latitude: "35.77330190",
        longitude: "-5.51433000",
        nameNative: null,
        label: "M’diq-Fnideq",
        cityCode: "MA_MDF",
      },
      {
        name: "Nador",
        countryCode: "MA",
        latitude: "34.91719260",
        longitude: "-2.85771050",
        nameNative: null,
        label: "Nador",
        cityCode: "MA_NAD",
      },
      {
        name: "Nouaceur",
        countryCode: "MA",
        latitude: "33.36703930",
        longitude: "-7.57325370",
        nameNative: null,
        label: "Nouaceur",
        cityCode: "MA_NOU",
      },
      {
        name: "Ouarzazate",
        countryCode: "MA",
        latitude: "30.93354360",
        longitude: "-6.93701600",
        nameNative: null,
        label: "Ouarzazate",
        cityCode: "MA_OUA",
      },
      {
        name: "Oued Ed-Dahab (EH)",
        countryCode: "MA",
        latitude: "22.73378920",
        longitude: "-14.28611160",
        nameNative: null,
        label: "Oued Ed-Dahab (EH)",
        cityCode: "MA_OUD",
      },
      {
        name: "Ouezzane",
        countryCode: "MA",
        latitude: "34.80634500",
        longitude: "-5.59145050",
        nameNative: null,
        label: "Ouezzane",
        cityCode: "MA_OUZ",
      },
      {
        name: "Oujda-Angad",
        countryCode: "MA",
        latitude: "34.68375040",
        longitude: "-2.29932390",
        nameNative: null,
        label: "Oujda-Angad",
        cityCode: "MA_OUJ",
      },
      {
        name: "Rabat",
        countryCode: "MA",
        latitude: "33.96919900",
        longitude: "-6.92730290",
        nameNative: null,
        label: "Rabat",
        cityCode: "MA_RAB",
      },
      {
        name: "Rabat-Salé-Kénitra",
        countryCode: "MA",
        latitude: "34.07686400",
        longitude: "-7.34544760",
        nameNative: null,
        label: "Rabat-Salé-Kénitra",
        cityCode: "MA_04",
      },
      {
        name: "Rehamna",
        countryCode: "MA",
        latitude: "32.20329050",
        longitude: "-8.56896710",
        nameNative: null,
        label: "Rehamna",
        cityCode: "MA_REH",
      },
      {
        name: "Safi",
        countryCode: "MA",
        latitude: "32.29898720",
        longitude: "-9.10134980",
        nameNative: null,
        label: "Safi",
        cityCode: "MA_SAF",
      },
      {
        name: "Salé",
        countryCode: "MA",
        latitude: "34.03775700",
        longitude: "-6.84270730",
        nameNative: null,
        label: "Salé",
        cityCode: "MA_SAL",
      },
      {
        name: "Sefrou",
        countryCode: "MA",
        latitude: "33.83052440",
        longitude: "-4.83531540",
        nameNative: null,
        label: "Sefrou",
        cityCode: "MA_SEF",
      },
      {
        name: "Settat",
        countryCode: "MA",
        latitude: "32.99242420",
        longitude: "-7.62226650",
        nameNative: null,
        label: "Settat",
        cityCode: "MA_SET",
      },
      {
        name: "Sidi Bennour",
        countryCode: "MA",
        latitude: "32.64926020",
        longitude: "-8.44714530",
        nameNative: null,
        label: "Sidi Bennour",
        cityCode: "MA_SIB",
      },
      {
        name: "Sidi Ifni",
        countryCode: "MA",
        latitude: "29.36657970",
        longitude: "-10.21084850",
        nameNative: null,
        label: "Sidi Ifni",
        cityCode: "MA_SIF",
      },
      {
        name: "Sidi Kacem",
        countryCode: "MA",
        latitude: "34.22601720",
        longitude: "-5.71291640",
        nameNative: null,
        label: "Sidi Kacem",
        cityCode: "MA_SIK",
      },
      {
        name: "Sidi Slimane",
        countryCode: "MA",
        latitude: "34.27378280",
        longitude: "-5.98059720",
        nameNative: null,
        label: "Sidi Slimane",
        cityCode: "MA_SIL",
      },
      {
        name: "Skhirate-Témara",
        countryCode: "MA",
        latitude: "33.76224250",
        longitude: "-7.04190520",
        nameNative: null,
        label: "Skhirate-Témara",
        cityCode: "MA_SKH",
      },
      {
        name: "Souss-Massa",
        countryCode: "MA",
        latitude: "30.27506110",
        longitude: "-8.13385580",
        nameNative: null,
        label: "Souss-Massa",
        cityCode: "MA_09",
      },
      {
        name: "Tan-Tan (EH-partial)",
        countryCode: "MA",
        latitude: "28.03012000",
        longitude: "-11.16173560",
        nameNative: null,
        label: "Tan-Tan (EH-partial)",
        cityCode: "MA_TNT",
      },
      {
        name: "Tanger-Assilah",
        countryCode: "MA",
        latitude: "35.76325390",
        longitude: "-5.90450980",
        nameNative: null,
        label: "Tanger-Assilah",
        cityCode: "MA_TNG",
      },
      {
        name: "Tanger-Tétouan-Al Hoceïma",
        countryCode: "MA",
        latitude: "35.26295580",
        longitude: "-5.56172790",
        nameNative: null,
        label: "Tanger-Tétouan-Al Hoceïma",
        cityCode: "MA_01",
      },
      {
        name: "Taounate",
        countryCode: "MA",
        latitude: "34.53691700",
        longitude: "-4.63986930",
        nameNative: null,
        label: "Taounate",
        cityCode: "MA_TAO",
      },
      {
        name: "Taourirt",
        countryCode: "MA",
        latitude: "34.21259800",
        longitude: "-2.69838680",
        nameNative: null,
        label: "Taourirt",
        cityCode: "MA_TAI",
      },
      {
        name: "Tarfaya (EH-partial)",
        countryCode: "MA",
        latitude: "27.93777010",
        longitude: "-12.92940630",
        nameNative: null,
        label: "Tarfaya (EH-partial)",
        cityCode: "MA_TAF",
      },
      {
        name: "Taroudannt",
        countryCode: "MA",
        latitude: "30.47271260",
        longitude: "-8.87487650",
        nameNative: null,
        label: "Taroudannt",
        cityCode: "MA_TAR",
      },
      {
        name: "Tata",
        countryCode: "MA",
        latitude: "29.75087700",
        longitude: "-7.97563430",
        nameNative: null,
        label: "Tata",
        cityCode: "MA_TAT",
      },
      {
        name: "Taza",
        countryCode: "MA",
        latitude: "34.27889530",
        longitude: "-3.58126920",
        nameNative: null,
        label: "Taza",
        cityCode: "MA_TAZ",
      },
      {
        name: "Tinghir",
        countryCode: "MA",
        latitude: "31.48507940",
        longitude: "-6.20192980",
        nameNative: null,
        label: "Tinghir",
        cityCode: "MA_TIN",
      },
      {
        name: "Tiznit",
        countryCode: "MA",
        latitude: "29.69339200",
        longitude: "-9.73215700",
        nameNative: null,
        label: "Tiznit",
        cityCode: "MA_TIZ",
      },
      {
        name: "Tétouan",
        countryCode: "MA",
        latitude: "35.58889950",
        longitude: "-5.36255160",
        nameNative: null,
        label: "Tétouan",
        cityCode: "MA_TET",
      },
      {
        name: "Youssoufia",
        countryCode: "MA",
        latitude: "32.02006790",
        longitude: "-8.86926480",
        nameNative: null,
        label: "Youssoufia",
        cityCode: "MA_YUS",
      },
      {
        name: "Zagora",
        countryCode: "MA",
        latitude: "30.57860930",
        longitude: "-5.89871390",
        nameNative: null,
        label: "Zagora",
        cityCode: "MA_ZAG",
      },
    ],
    MZ: [
      {
        name: "Cabo Delgado Province",
        countryCode: "MZ",
        latitude: "-12.33354740",
        longitude: "39.32062410",
        nameNative: null,
        label: "Cabo Delgado Province",
        cityCode: "MZ_P",
      },
      {
        name: "Gaza Province",
        countryCode: "MZ",
        latitude: "-23.02219280",
        longitude: "32.71813750",
        nameNative: null,
        label: "Gaza Province",
        cityCode: "MZ_G",
      },
      {
        name: "Inhambane Province",
        countryCode: "MZ",
        latitude: "-22.85279970",
        longitude: "34.55087580",
        nameNative: null,
        label: "Inhambane Province",
        cityCode: "MZ_I",
      },
      {
        name: "Manica Province",
        countryCode: "MZ",
        latitude: "-19.50597870",
        longitude: "33.43835300",
        nameNative: null,
        label: "Manica Province",
        cityCode: "MZ_B",
      },
      {
        name: "Maputo Province",
        countryCode: "MZ",
        latitude: "-25.25698760",
        longitude: "32.53727410",
        nameNative: null,
        label: "Maputo Province",
        cityCode: "MZ_L",
      },
      {
        name: "Maputo",
        countryCode: "MZ",
        latitude: "-25.96924800",
        longitude: "32.57317460",
        nameNative: null,
        label: "Maputo",
        cityCode: "MZ_MPM",
      },
      {
        name: "Nampula Province",
        countryCode: "MZ",
        latitude: "-14.76049310",
        longitude: "39.32062410",
        nameNative: null,
        label: "Nampula Province",
        cityCode: "MZ_N",
      },
      {
        name: "Niassa Province",
        countryCode: "MZ",
        latitude: "-12.78262020",
        longitude: "36.60939260",
        nameNative: null,
        label: "Niassa Province",
        cityCode: "MZ_A",
      },
      {
        name: "Sofala Province",
        countryCode: "MZ",
        latitude: "-19.20390730",
        longitude: "34.86241660",
        nameNative: null,
        label: "Sofala Province",
        cityCode: "MZ_S",
      },
      {
        name: "Tete Province",
        countryCode: "MZ",
        latitude: "-15.65960560",
        longitude: "32.71813750",
        nameNative: null,
        label: "Tete Province",
        cityCode: "MZ_T",
      },
      {
        name: "Zambezia Province",
        countryCode: "MZ",
        latitude: "-16.56389870",
        longitude: "36.60939260",
        nameNative: null,
        label: "Zambezia Province",
        cityCode: "MZ_Q",
      },
    ],
    MM: [
      {
        name: "Ayeyarwady Region",
        countryCode: "MM",
        latitude: "17.03421250",
        longitude: "95.22666750",
        nameNative: null,
        label: "Ayeyarwady Region",
        cityCode: "MM_07",
      },
      {
        name: "Bago",
        countryCode: "MM",
        latitude: "17.32207110",
        longitude: "96.46632860",
        nameNative: null,
        label: "Bago",
        cityCode: "MM_02",
      },
      {
        name: "Chin State",
        countryCode: "MM",
        latitude: "22.00869780",
        longitude: "93.58126920",
        nameNative: null,
        label: "Chin State",
        cityCode: "MM_14",
      },
      {
        name: "Kachin State",
        countryCode: "MM",
        latitude: "25.85090400",
        longitude: "97.43813550",
        nameNative: null,
        label: "Kachin State",
        cityCode: "MM_11",
      },
      {
        name: "Kayah State",
        countryCode: "MM",
        latitude: "19.23420610",
        longitude: "97.26528580",
        nameNative: null,
        label: "Kayah State",
        cityCode: "MM_12",
      },
      {
        name: "Kayin State",
        countryCode: "MM",
        latitude: "16.94593460",
        longitude: "97.95928630",
        nameNative: null,
        label: "Kayin State",
        cityCode: "MM_13",
      },
      {
        name: "Magway Region",
        countryCode: "MM",
        latitude: "19.88713860",
        longitude: "94.72775280",
        nameNative: null,
        label: "Magway Region",
        cityCode: "MM_03",
      },
      {
        name: "Mandalay Region",
        countryCode: "MM",
        latitude: "21.56190580",
        longitude: "95.89871390",
        nameNative: null,
        label: "Mandalay Region",
        cityCode: "MM_04",
      },
      {
        name: "Mon State",
        countryCode: "MM",
        latitude: "16.30031330",
        longitude: "97.69822720",
        nameNative: null,
        label: "Mon State",
        cityCode: "MM_15",
      },
      {
        name: "Naypyidaw Union Territory",
        countryCode: "MM",
        latitude: "19.93862450",
        longitude: "96.15269850",
        nameNative: null,
        label: "Naypyidaw Union Territory",
        cityCode: "MM_18",
      },
      {
        name: "Rakhine State",
        countryCode: "MM",
        latitude: "20.10408180",
        longitude: "93.58126920",
        nameNative: null,
        label: "Rakhine State",
        cityCode: "MM_16",
      },
      {
        name: "Sagaing Region",
        countryCode: "MM",
        latitude: "24.42838100",
        longitude: "95.39395510",
        nameNative: null,
        label: "Sagaing Region",
        cityCode: "MM_01",
      },
      {
        name: "Shan State",
        countryCode: "MM",
        latitude: "22.03619850",
        longitude: "98.13385580",
        nameNative: null,
        label: "Shan State",
        cityCode: "MM_17",
      },
      {
        name: "Tanintharyi Region",
        countryCode: "MM",
        latitude: "12.47068760",
        longitude: "99.01289260",
        nameNative: null,
        label: "Tanintharyi Region",
        cityCode: "MM_05",
      },
      {
        name: "Yangon Region",
        countryCode: "MM",
        latitude: "16.91434880",
        longitude: "96.15269850",
        nameNative: null,
        label: "Yangon Region",
        cityCode: "MM_06",
      },
    ],
    NA: [
      {
        name: "Erongo Region",
        countryCode: "NA",
        latitude: "-22.25656820",
        longitude: "15.40680790",
        nameNative: null,
        label: "Erongo Region",
        cityCode: "NA_ER",
      },
      {
        name: "Hardap Region",
        countryCode: "NA",
        latitude: "-24.23101340",
        longitude: "17.66888700",
        nameNative: null,
        label: "Hardap Region",
        cityCode: "NA_HA",
      },
      {
        name: "Karas Region",
        countryCode: "NA",
        latitude: "-26.84296450",
        longitude: "17.29028390",
        nameNative: null,
        label: "Karas Region",
        cityCode: "NA_KA",
      },
      {
        name: "Kavango East Region",
        countryCode: "NA",
        latitude: "-18.27104800",
        longitude: "18.42760470",
        nameNative: null,
        label: "Kavango East Region",
        cityCode: "NA_KE",
      },
      {
        name: "Kavango West Region",
        countryCode: "NA",
        latitude: "-18.27104800",
        longitude: "18.42760470",
        nameNative: null,
        label: "Kavango West Region",
        cityCode: "NA_KW",
      },
      {
        name: "Khomas Region",
        countryCode: "NA",
        latitude: "-22.63778540",
        longitude: "17.10119310",
        nameNative: null,
        label: "Khomas Region",
        cityCode: "NA_KH",
      },
      {
        name: "Kunene Region",
        countryCode: "NA",
        latitude: "-19.40863170",
        longitude: "13.91439900",
        nameNative: null,
        label: "Kunene Region",
        cityCode: "NA_KU",
      },
      {
        name: "Ohangwena Region",
        countryCode: "NA",
        latitude: "-17.59792910",
        longitude: "16.81783770",
        nameNative: null,
        label: "Ohangwena Region",
        cityCode: "NA_OW",
      },
      {
        name: "Omaheke Region",
        countryCode: "NA",
        latitude: "-21.84666510",
        longitude: "19.18800470",
        nameNative: null,
        label: "Omaheke Region",
        cityCode: "NA_OH",
      },
      {
        name: "Omusati Region",
        countryCode: "NA",
        latitude: "-18.40702940",
        longitude: "14.84546190",
        nameNative: null,
        label: "Omusati Region",
        cityCode: "NA_OS",
      },
      {
        name: "Oshana Region",
        countryCode: "NA",
        latitude: "-18.43050640",
        longitude: "15.68817880",
        nameNative: null,
        label: "Oshana Region",
        cityCode: "NA_ON",
      },
      {
        name: "Oshikoto Region",
        countryCode: "NA",
        latitude: "-18.41525750",
        longitude: "16.91225100",
        nameNative: null,
        label: "Oshikoto Region",
        cityCode: "NA_OT",
      },
      {
        name: "Otjozondjupa Region",
        countryCode: "NA",
        latitude: "-20.54869160",
        longitude: "17.66888700",
        nameNative: null,
        label: "Otjozondjupa Region",
        cityCode: "NA_OD",
      },
      {
        name: "Zambezi Region",
        countryCode: "NA",
        latitude: "-17.81934190",
        longitude: "23.95364660",
        nameNative: null,
        label: "Zambezi Region",
        cityCode: "NA_CA",
      },
    ],
    NR: [
      {
        name: "Aiwo District",
        countryCode: "NR",
        latitude: "-0.53400120",
        longitude: "166.91388730",
        nameNative: null,
        label: "Aiwo District",
        cityCode: "NR_01",
      },
      {
        name: "Anabar District",
        countryCode: "NR",
        latitude: "-0.51335170",
        longitude: "166.94846240",
        nameNative: null,
        label: "Anabar District",
        cityCode: "NR_02",
      },
      {
        name: "Anetan District",
        countryCode: "NR",
        latitude: "-0.50643430",
        longitude: "166.94270060",
        nameNative: null,
        label: "Anetan District",
        cityCode: "NR_03",
      },
      {
        name: "Anibare District",
        countryCode: "NR",
        latitude: "-0.52947580",
        longitude: "166.95134320",
        nameNative: null,
        label: "Anibare District",
        cityCode: "NR_04",
      },
      {
        name: "Baiti District",
        countryCode: "NR",
        latitude: "-0.51043100",
        longitude: "166.92757440",
        nameNative: null,
        label: "Baiti District",
        cityCode: "NR_05",
      },
      {
        name: "Boe District",
        countryCode: "NR",
        latitude: "39.07327760",
        longitude: "-94.57104980",
        nameNative: null,
        label: "Boe District",
        cityCode: "NR_06",
      },
      {
        name: "Buada District",
        countryCode: "NR",
        latitude: "-0.53287770",
        longitude: "166.92685410",
        nameNative: null,
        label: "Buada District",
        cityCode: "NR_07",
      },
      {
        name: "Denigomodu District",
        countryCode: "NR",
        latitude: "-0.52479640",
        longitude: "166.91676890",
        nameNative: null,
        label: "Denigomodu District",
        cityCode: "NR_08",
      },
      {
        name: "Ewa District",
        countryCode: "NR",
        latitude: "-0.50872410",
        longitude: "166.93693840",
        nameNative: null,
        label: "Ewa District",
        cityCode: "NR_09",
      },
      {
        name: "Ijuw District",
        countryCode: "NR",
        latitude: "-0.52027670",
        longitude: "166.95710460",
        nameNative: null,
        label: "Ijuw District",
        cityCode: "NR_10",
      },
      {
        name: "Meneng District",
        countryCode: "NR",
        latitude: "-0.54672400",
        longitude: "166.93837900",
        nameNative: null,
        label: "Meneng District",
        cityCode: "NR_11",
      },
      {
        name: "Nibok District",
        countryCode: "NR",
        latitude: "-0.51962080",
        longitude: "166.91893010",
        nameNative: null,
        label: "Nibok District",
        cityCode: "NR_12",
      },
      {
        name: "Uaboe District",
        countryCode: "NR",
        latitude: "-0.52022220",
        longitude: "166.93117610",
        nameNative: null,
        label: "Uaboe District",
        cityCode: "NR_13",
      },
      {
        name: "Yaren District",
        countryCode: "NR",
        latitude: "-0.54668570",
        longitude: "166.92109130",
        nameNative: null,
        label: "Yaren District",
        cityCode: "NR_14",
      },
    ],
    NP: [
      {
        name: "Bagmati Zone",
        countryCode: "NP",
        latitude: "28.03675770",
        longitude: "85.43755740",
        nameNative: null,
        label: "Bagmati Zone",
        cityCode: "NP_BA",
      },
      {
        name: "Bheri Zone",
        countryCode: "NP",
        latitude: "28.51745600",
        longitude: "81.77870210",
        nameNative: null,
        label: "Bheri Zone",
        cityCode: "NP_BH",
      },
      {
        name: "Central Region",
        countryCode: "NP",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Central Region",
        cityCode: "NP_1",
      },
      {
        name: "Dhaulagiri Zone",
        countryCode: "NP",
        latitude: "28.61117600",
        longitude: "83.50702030",
        nameNative: null,
        label: "Dhaulagiri Zone",
        cityCode: "NP_DH",
      },
      {
        name: "Eastern Development Region",
        countryCode: "NP",
        latitude: "27.33090720",
        longitude: "87.06242610",
        nameNative: null,
        label: "Eastern Development Region",
        cityCode: "NP_4",
      },
      {
        name: "Far-Western Development Region",
        countryCode: "NP",
        latitude: "29.29878710",
        longitude: "80.98710740",
        nameNative: null,
        label: "Far-Western Development Region",
        cityCode: "NP_5",
      },
      {
        name: "Gandaki Zone",
        countryCode: "NP",
        latitude: "28.37320370",
        longitude: "84.43827210",
        nameNative: null,
        label: "Gandaki Zone",
        cityCode: "NP_GA",
      },
      {
        name: "Janakpur Zone",
        countryCode: "NP",
        latitude: "27.21108990",
        longitude: "86.01215730",
        nameNative: null,
        label: "Janakpur Zone",
        cityCode: "NP_JA",
      },
      {
        name: "Karnali Zone",
        countryCode: "NP",
        latitude: "29.38625550",
        longitude: "82.38857830",
        nameNative: null,
        label: "Karnali Zone",
        cityCode: "NP_KA",
      },
      {
        name: "Kosi Zone",
        countryCode: "NP",
        latitude: "27.05365240",
        longitude: "87.30161320",
        nameNative: null,
        label: "Kosi Zone",
        cityCode: "NP_KO",
      },
      {
        name: "Lumbini Zone",
        countryCode: "NP",
        latitude: "27.45000000",
        longitude: "83.25000000",
        nameNative: null,
        label: "Lumbini Zone",
        cityCode: "NP_LU",
      },
      {
        name: "Mahakali Zone",
        countryCode: "NP",
        latitude: "29.36010790",
        longitude: "80.54384500",
        nameNative: null,
        label: "Mahakali Zone",
        cityCode: "NP_MA",
      },
      {
        name: "Mechi Zone",
        countryCode: "NP",
        latitude: "26.87600070",
        longitude: "87.93348030",
        nameNative: null,
        label: "Mechi Zone",
        cityCode: "NP_ME",
      },
      {
        name: "Mid-Western Region",
        countryCode: "NP",
        latitude: "38.41118410",
        longitude: "-90.38320980",
        nameNative: null,
        label: "Mid-Western Region",
        cityCode: "NP_2",
      },
      {
        name: "Narayani Zone",
        countryCode: "NP",
        latitude: "27.36117660",
        longitude: "84.85679320",
        nameNative: null,
        label: "Narayani Zone",
        cityCode: "NP_NA",
      },
      {
        name: "Rapti Zone",
        countryCode: "NP",
        latitude: "28.27434700",
        longitude: "82.38857830",
        nameNative: null,
        label: "Rapti Zone",
        cityCode: "NP_RA",
      },
      {
        name: "Sagarmatha Zone",
        countryCode: "NP",
        latitude: "27.32382630",
        longitude: "86.74163740",
        nameNative: null,
        label: "Sagarmatha Zone",
        cityCode: "NP_SA",
      },
      {
        name: "Seti Zone",
        countryCode: "NP",
        latitude: "29.69054270",
        longitude: "81.33994140",
        nameNative: null,
        label: "Seti Zone",
        cityCode: "NP_SE",
      },
      {
        name: "Western Region",
        countryCode: "NP",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Western Region",
        cityCode: "NP_3",
      },
    ],
    NL: [
      {
        name: "Bonaire",
        countryCode: "NL",
        latitude: "12.20189020",
        longitude: "-68.26238220",
        nameNative: "Bonaire",
        label: "Bonaire / Bonaire",
        cityCode: "NL_BQ1",
      },
      {
        name: "Drenthe",
        countryCode: "NL",
        latitude: "52.94760120",
        longitude: "6.62305860",
        nameNative: "Drenthe",
        label: "Drenthe / Drenthe",
        cityCode: "NL_DR",
      },
      {
        name: "Flevoland",
        countryCode: "NL",
        latitude: "52.52797810",
        longitude: "5.59535080",
        nameNative: "Flevoland",
        label: "Flevoland / Flevoland",
        cityCode: "NL_FL",
      },
      {
        name: "Friesland",
        countryCode: "NL",
        latitude: "53.16416420",
        longitude: "5.78175420",
        nameNative: "Fryslân",
        label: "Fryslân / Friesland",
        cityCode: "NL_FR",
      },
      {
        name: "Gelderland",
        countryCode: "NL",
        latitude: "52.04515500",
        longitude: "5.87182350",
        nameNative: "Gelderland",
        label: "Gelderland / Gelderland",
        cityCode: "NL_GE",
      },
      {
        name: "Groningen",
        countryCode: "NL",
        latitude: "53.21938350",
        longitude: "6.56650170",
        nameNative: "Groningen",
        label: "Groningen / Groningen",
        cityCode: "NL_GR",
      },
      {
        name: "Limburg",
        countryCode: "NL",
        latitude: "51.44272380",
        longitude: "6.06087260",
        nameNative: "Limburg",
        label: "Limburg / Limburg",
        cityCode: "NL_LI",
      },
      {
        name: "North Brabant",
        countryCode: "NL",
        latitude: "51.48265370",
        longitude: "5.23216870",
        nameNative: "Noord-Brabant",
        label: "Noord-Brabant / North Brabant",
        cityCode: "NL_NB",
      },
      {
        name: "North Holland",
        countryCode: "NL",
        latitude: "52.52058690",
        longitude: "4.78847400",
        nameNative: "Noord-Holland",
        label: "Noord-Holland / North Holland",
        cityCode: "NL_NH",
      },
      {
        name: "Overijssel",
        countryCode: "NL",
        latitude: "52.43878140",
        longitude: "6.50164110",
        nameNative: "Overijssel",
        label: "Overijssel / Overijssel",
        cityCode: "NL_OV",
      },
      {
        name: "Saba",
        countryCode: "NL",
        latitude: "17.63546420",
        longitude: "-63.23267630",
        nameNative: "Saba",
        label: "Saba / Saba",
        cityCode: "NL_BQ2",
      },
      {
        name: "Sint Eustatius",
        countryCode: "NL",
        latitude: "17.48903060",
        longitude: "-62.97355500",
        nameNative: "Sint Eustatius",
        label: "Sint Eustatius / Sint Eustatius",
        cityCode: "NL_BQ3",
      },
      {
        name: "South Holland",
        countryCode: "NL",
        latitude: "41.60086810",
        longitude: "-87.60698940",
        nameNative: "Zuid-Holland",
        label: "Zuid-Holland / South Holland",
        cityCode: "NL_ZH",
      },
      {
        name: "Utrecht",
        countryCode: "NL",
        latitude: "52.09073740",
        longitude: "5.12142010",
        nameNative: "Utrecht",
        label: "Utrecht / Utrecht",
        cityCode: "NL_UT",
      },
      {
        name: "Zeeland",
        countryCode: "NL",
        latitude: "51.49403090",
        longitude: "3.84968150",
        nameNative: "Zeeland",
        label: "Zeeland / Zeeland",
        cityCode: "NL_ZE",
      },
    ],
    NZ: [
      {
        name: "Auckland Region",
        countryCode: "NZ",
        latitude: "-36.66753280",
        longitude: "174.77333250",
        nameNative: null,
        label: "Auckland Region",
        cityCode: "NZ_AUK",
      },
      {
        name: "Bay of Plenty Region",
        countryCode: "NZ",
        latitude: "-37.42339170",
        longitude: "176.74163740",
        nameNative: null,
        label: "Bay of Plenty Region",
        cityCode: "NZ_BOP",
      },
      {
        name: "Canterbury Region",
        countryCode: "NZ",
        latitude: "-43.75422750",
        longitude: "171.16372450",
        nameNative: null,
        label: "Canterbury Region",
        cityCode: "NZ_CAN",
      },
      {
        name: "Chatham Islands",
        countryCode: "NZ",
        latitude: "-44.00575230",
        longitude: "-176.54006740",
        nameNative: null,
        label: "Chatham Islands",
        cityCode: "NZ_CIT",
      },
      {
        name: "Gisborne District",
        countryCode: "NZ",
        latitude: "-38.13581740",
        longitude: "178.32393090",
        nameNative: null,
        label: "Gisborne District",
        cityCode: "NZ_GIS",
      },
      {
        name: "Hawke's Bay Region",
        countryCode: "NZ",
        latitude: "-39.60165970",
        longitude: "176.58044730",
        nameNative: null,
        label: "Hawke's Bay Region",
        cityCode: "NZ_HKB",
      },
      {
        name: "Manawatu-Wanganui Region",
        countryCode: "NZ",
        latitude: "-39.72733560",
        longitude: "175.43755740",
        nameNative: null,
        label: "Manawatu-Wanganui Region",
        cityCode: "NZ_MWT",
      },
      {
        name: "Marlborough Region",
        countryCode: "NZ",
        latitude: "-41.59168830",
        longitude: "173.76240530",
        nameNative: null,
        label: "Marlborough Region",
        cityCode: "NZ_MBH",
      },
      {
        name: "Nelson Region",
        countryCode: "NZ",
        latitude: "-41.29853970",
        longitude: "173.24414910",
        nameNative: null,
        label: "Nelson Region",
        cityCode: "NZ_NSN",
      },
      {
        name: "Northland Region",
        countryCode: "NZ",
        latitude: "-35.41361720",
        longitude: "173.93208060",
        nameNative: null,
        label: "Northland Region",
        cityCode: "NZ_NTL",
      },
      {
        name: "Otago Region",
        countryCode: "NZ",
        latitude: "-45.47906710",
        longitude: "170.15475670",
        nameNative: null,
        label: "Otago Region",
        cityCode: "NZ_OTA",
      },
      {
        name: "Southland Region",
        countryCode: "NZ",
        latitude: "-45.84891590",
        longitude: "167.67553870",
        nameNative: null,
        label: "Southland Region",
        cityCode: "NZ_STL",
      },
      {
        name: "Taranaki Region",
        countryCode: "NZ",
        latitude: "-39.35381490",
        longitude: "174.43827210",
        nameNative: null,
        label: "Taranaki Region",
        cityCode: "NZ_TKI",
      },
      {
        name: "Tasman District",
        countryCode: "NZ",
        latitude: "-41.45711840",
        longitude: "172.82097400",
        nameNative: null,
        label: "Tasman District",
        cityCode: "NZ_TAS",
      },
      {
        name: "Waikato Region",
        countryCode: "NZ",
        latitude: "-37.61908620",
        longitude: "175.02334600",
        nameNative: null,
        label: "Waikato Region",
        cityCode: "NZ_WKO",
      },
      {
        name: "Wellington Region",
        countryCode: "NZ",
        latitude: "-41.02993230",
        longitude: "175.43755740",
        nameNative: null,
        label: "Wellington Region",
        cityCode: "NZ_WGN",
      },
      {
        name: "West Coast Region",
        countryCode: "NZ",
        latitude: "62.41136340",
        longitude: "-149.07297140",
        nameNative: null,
        label: "West Coast Region",
        cityCode: "NZ_WTC",
      },
    ],
    NI: [
      {
        name: "Boaco",
        countryCode: "NI",
        latitude: "12.46928400",
        longitude: "-85.66146820",
        nameNative: null,
        label: "Boaco",
        cityCode: "NI_BO",
      },
      {
        name: "Carazo",
        countryCode: "NI",
        latitude: "11.72747290",
        longitude: "-86.21584970",
        nameNative: null,
        label: "Carazo",
        cityCode: "NI_CA",
      },
      {
        name: "Chinandega",
        countryCode: "NI",
        latitude: "12.88200620",
        longitude: "-87.14228950",
        nameNative: null,
        label: "Chinandega",
        cityCode: "NI_CI",
      },
      {
        name: "Chontales",
        countryCode: "NI",
        latitude: "11.93947170",
        longitude: "-85.18940450",
        nameNative: null,
        label: "Chontales",
        cityCode: "NI_CO",
      },
      {
        name: "Estelí",
        countryCode: "NI",
        latitude: "13.08511390",
        longitude: "-86.36301970",
        nameNative: null,
        label: "Estelí",
        cityCode: "NI_ES",
      },
      {
        name: "Granada",
        countryCode: "NI",
        latitude: "11.93440730",
        longitude: "-85.95600050",
        nameNative: null,
        label: "Granada",
        cityCode: "NI_GR",
      },
      {
        name: "Jinotega",
        countryCode: "NI",
        latitude: "13.08839070",
        longitude: "-85.99939970",
        nameNative: null,
        label: "Jinotega",
        cityCode: "NI_JI",
      },
      {
        name: "León",
        countryCode: "NI",
        latitude: "12.50920370",
        longitude: "-86.66110830",
        nameNative: null,
        label: "León",
        cityCode: "NI_LE",
      },
      {
        name: "Madriz",
        countryCode: "NI",
        latitude: "13.47260050",
        longitude: "-86.45920910",
        nameNative: null,
        label: "Madriz",
        cityCode: "NI_MD",
      },
      {
        name: "Managua",
        countryCode: "NI",
        latitude: "12.13916990",
        longitude: "-86.33767610",
        nameNative: null,
        label: "Managua",
        cityCode: "NI_MN",
      },
      {
        name: "Masaya",
        countryCode: "NI",
        latitude: "11.97593280",
        longitude: "-86.07334980",
        nameNative: null,
        label: "Masaya",
        cityCode: "NI_MS",
      },
      {
        name: "Matagalpa",
        countryCode: "NI",
        latitude: "12.94984360",
        longitude: "-85.43755740",
        nameNative: null,
        label: "Matagalpa",
        cityCode: "NI_MT",
      },
      {
        name: "North Caribbean Coast",
        countryCode: "NI",
        latitude: "13.83944560",
        longitude: "-83.93208060",
        nameNative: null,
        label: "North Caribbean Coast",
        cityCode: "NI_AN",
      },
      {
        name: "Nueva Segovia",
        countryCode: "NI",
        latitude: "13.76570610",
        longitude: "-86.53700390",
        nameNative: null,
        label: "Nueva Segovia",
        cityCode: "NI_NS",
      },
      {
        name: "Rivas",
        countryCode: "NI",
        latitude: "11.40234900",
        longitude: "-85.68457800",
        nameNative: null,
        label: "Rivas",
        cityCode: "NI_RI",
      },
      {
        name: "Río San Juan",
        countryCode: "NI",
        latitude: "11.47816100",
        longitude: "-84.77333250",
        nameNative: null,
        label: "Río San Juan",
        cityCode: "NI_SJ",
      },
      {
        name: "South Caribbean Coast",
        countryCode: "NI",
        latitude: "12.19185020",
        longitude: "-84.10128610",
        nameNative: null,
        label: "South Caribbean Coast",
        cityCode: "NI_AS",
      },
    ],
    NE: [
      {
        name: "Agadez Region",
        countryCode: "NE",
        latitude: "20.66707520",
        longitude: "12.07182810",
        nameNative: null,
        label: "Agadez Region",
        cityCode: "NE_1",
      },
      {
        name: "Diffa Region",
        countryCode: "NE",
        latitude: "13.67686470",
        longitude: "12.71351210",
        nameNative: null,
        label: "Diffa Region",
        cityCode: "NE_2",
      },
      {
        name: "Dosso Region",
        countryCode: "NE",
        latitude: "13.15139470",
        longitude: "3.41955270",
        nameNative: null,
        label: "Dosso Region",
        cityCode: "NE_3",
      },
      {
        name: "Maradi Region",
        countryCode: "NE",
        latitude: "13.80180740",
        longitude: "7.43813550",
        nameNative: null,
        label: "Maradi Region",
        cityCode: "NE_4",
      },
      {
        name: "Tahoua Region",
        countryCode: "NE",
        latitude: "16.09025430",
        longitude: "5.39395510",
        nameNative: null,
        label: "Tahoua Region",
        cityCode: "NE_5",
      },
      {
        name: "Tillabéri Region",
        countryCode: "NE",
        latitude: "14.64895250",
        longitude: "2.14502450",
        nameNative: null,
        label: "Tillabéri Region",
        cityCode: "NE_6",
      },
      {
        name: "Zinder Region",
        countryCode: "NE",
        latitude: "15.17188810",
        longitude: "10.26001250",
        nameNative: null,
        label: "Zinder Region",
        cityCode: "NE_7",
      },
    ],
    NG: [
      {
        name: "Abia",
        countryCode: "NG",
        latitude: "5.45273540",
        longitude: "7.52484140",
        nameNative: null,
        label: "Abia",
        cityCode: "NG_AB",
      },
      {
        name: "Abuja Federal Capital Territory",
        countryCode: "NG",
        latitude: "8.89406910",
        longitude: "7.18604020",
        nameNative: null,
        label: "Abuja Federal Capital Territory",
        cityCode: "NG_FC",
      },
      {
        name: "Adamawa",
        countryCode: "NG",
        latitude: "9.32647510",
        longitude: "12.39838530",
        nameNative: null,
        label: "Adamawa",
        cityCode: "NG_AD",
      },
      {
        name: "Akwa Ibom",
        countryCode: "NG",
        latitude: "4.90573710",
        longitude: "7.85366750",
        nameNative: null,
        label: "Akwa Ibom",
        cityCode: "NG_AK",
      },
      {
        name: "Anambra",
        countryCode: "NG",
        latitude: "6.22089970",
        longitude: "6.93695590",
        nameNative: null,
        label: "Anambra",
        cityCode: "NG_AN",
      },
      {
        name: "Bauchi",
        countryCode: "NG",
        latitude: "10.77606240",
        longitude: "9.99919430",
        nameNative: null,
        label: "Bauchi",
        cityCode: "NG_BA",
      },
      {
        name: "Bayelsa",
        countryCode: "NG",
        latitude: "4.77190710",
        longitude: "6.06985260",
        nameNative: null,
        label: "Bayelsa",
        cityCode: "NG_BY",
      },
      {
        name: "Benue",
        countryCode: "NG",
        latitude: "7.33690240",
        longitude: "8.74036870",
        nameNative: null,
        label: "Benue",
        cityCode: "NG_BE",
      },
      {
        name: "Borno",
        countryCode: "NG",
        latitude: "11.88463560",
        longitude: "13.15196650",
        nameNative: null,
        label: "Borno",
        cityCode: "NG_BO",
      },
      {
        name: "Cross River",
        countryCode: "NG",
        latitude: "5.87017240",
        longitude: "8.59880140",
        nameNative: null,
        label: "Cross River",
        cityCode: "NG_CR",
      },
      {
        name: "Delta",
        countryCode: "NG",
        latitude: "33.74537840",
        longitude: "-90.73545080",
        nameNative: null,
        label: "Delta",
        cityCode: "NG_DE",
      },
      {
        name: "Ebonyi",
        countryCode: "NG",
        latitude: "6.26492320",
        longitude: "8.01373020",
        nameNative: null,
        label: "Ebonyi",
        cityCode: "NG_EB",
      },
      {
        name: "Edo",
        countryCode: "NG",
        latitude: "6.63418310",
        longitude: "5.93040560",
        nameNative: null,
        label: "Edo",
        cityCode: "NG_ED",
      },
      {
        name: "Ekiti",
        countryCode: "NG",
        latitude: "7.71898620",
        longitude: "5.31095050",
        nameNative: null,
        label: "Ekiti",
        cityCode: "NG_EK",
      },
      {
        name: "Enugu",
        countryCode: "NG",
        latitude: "6.53635300",
        longitude: "7.43561940",
        nameNative: null,
        label: "Enugu",
        cityCode: "NG_EN",
      },
      {
        name: "Gombe",
        countryCode: "NG",
        latitude: "10.36377950",
        longitude: "11.19275870",
        nameNative: null,
        label: "Gombe",
        cityCode: "NG_GO",
      },
      {
        name: "Imo",
        countryCode: "NG",
        latitude: "5.57201220",
        longitude: "7.05882190",
        nameNative: null,
        label: "Imo",
        cityCode: "NG_IM",
      },
      {
        name: "Jigawa",
        countryCode: "NG",
        latitude: "12.22801200",
        longitude: "9.56158670",
        nameNative: null,
        label: "Jigawa",
        cityCode: "NG_JI",
      },
      {
        name: "Kaduna",
        countryCode: "NG",
        latitude: "10.37640060",
        longitude: "7.70945370",
        nameNative: null,
        label: "Kaduna",
        cityCode: "NG_KD",
      },
      {
        name: "Kano",
        countryCode: "NG",
        latitude: "11.74706980",
        longitude: "8.52471070",
        nameNative: null,
        label: "Kano",
        cityCode: "NG_KN",
      },
      {
        name: "Katsina",
        countryCode: "NG",
        latitude: "12.37967070",
        longitude: "7.63057480",
        nameNative: null,
        label: "Katsina",
        cityCode: "NG_KT",
      },
      {
        name: "Kebbi",
        countryCode: "NG",
        latitude: "11.49420030",
        longitude: "4.23333550",
        nameNative: null,
        label: "Kebbi",
        cityCode: "NG_KE",
      },
      {
        name: "Kogi",
        countryCode: "NG",
        latitude: "7.73373250",
        longitude: "6.69058360",
        nameNative: null,
        label: "Kogi",
        cityCode: "NG_KO",
      },
      {
        name: "Kwara",
        countryCode: "NG",
        latitude: "8.96689610",
        longitude: "4.38740510",
        nameNative: null,
        label: "Kwara",
        cityCode: "NG_KW",
      },
      {
        name: "Lagos",
        countryCode: "NG",
        latitude: "6.52437930",
        longitude: "3.37920570",
        nameNative: null,
        label: "Lagos",
        cityCode: "NG_LA",
      },
      {
        name: "Nasarawa",
        countryCode: "NG",
        latitude: "8.49979080",
        longitude: "8.19969370",
        nameNative: null,
        label: "Nasarawa",
        cityCode: "NG_NA",
      },
      {
        name: "Niger",
        countryCode: "NG",
        latitude: "9.93092240",
        longitude: "5.59832100",
        nameNative: null,
        label: "Niger",
        cityCode: "NG_NI",
      },
      {
        name: "Ogun",
        countryCode: "NG",
        latitude: "6.99797470",
        longitude: "3.47373780",
        nameNative: null,
        label: "Ogun",
        cityCode: "NG_OG",
      },
      {
        name: "Ondo",
        countryCode: "NG",
        latitude: "6.91486820",
        longitude: "5.14781440",
        nameNative: null,
        label: "Ondo",
        cityCode: "NG_ON",
      },
      {
        name: "Osun",
        countryCode: "NG",
        latitude: "7.56289640",
        longitude: "4.51995930",
        nameNative: null,
        label: "Osun",
        cityCode: "NG_OS",
      },
      {
        name: "Oyo",
        countryCode: "NG",
        latitude: "8.15738090",
        longitude: "3.61465340",
        nameNative: null,
        label: "Oyo",
        cityCode: "NG_OY",
      },
      {
        name: "Plateau",
        countryCode: "NG",
        latitude: "9.21820930",
        longitude: "9.51794880",
        nameNative: null,
        label: "Plateau",
        cityCode: "NG_PL",
      },
      {
        name: "Rivers",
        countryCode: "NG",
        latitude: "5.02134200",
        longitude: "6.43760220",
        nameNative: null,
        label: "Rivers",
        cityCode: "NG_RI",
      },
      {
        name: "Sokoto",
        countryCode: "NG",
        latitude: "13.05331430",
        longitude: "5.32227220",
        nameNative: null,
        label: "Sokoto",
        cityCode: "NG_SO",
      },
      {
        name: "Taraba",
        countryCode: "NG",
        latitude: "7.99936160",
        longitude: "10.77398630",
        nameNative: null,
        label: "Taraba",
        cityCode: "NG_TA",
      },
      {
        name: "Yobe",
        countryCode: "NG",
        latitude: "12.29387600",
        longitude: "11.43904110",
        nameNative: null,
        label: "Yobe",
        cityCode: "NG_YO",
      },
      {
        name: "Zamfara",
        countryCode: "NG",
        latitude: "12.12218050",
        longitude: "6.22358190",
        nameNative: null,
        label: "Zamfara",
        cityCode: "NG_ZA",
      },
    ],
    NO: [
      {
        name: "Akershus",
        countryCode: "NO",
        latitude: "28.37042030",
        longitude: "-81.54680580",
        nameNative: null,
        label: "Akershus",
        cityCode: "NO_02",
      },
      {
        name: "Buskerud",
        countryCode: "NO",
        latitude: "60.48460250",
        longitude: "8.69837640",
        nameNative: null,
        label: "Buskerud",
        cityCode: "NO_06",
      },
      {
        name: "Finnmark",
        countryCode: "NO",
        latitude: "70.48303880",
        longitude: "26.01351070",
        nameNative: null,
        label: "Finnmark",
        cityCode: "NO_20",
      },
      {
        name: "Hedmark",
        countryCode: "NO",
        latitude: "61.39673110",
        longitude: "11.56273690",
        nameNative: null,
        label: "Hedmark",
        cityCode: "NO_04",
      },
      {
        name: "Hordaland",
        countryCode: "NO",
        latitude: "60.27336740",
        longitude: "5.72201940",
        nameNative: null,
        label: "Hordaland",
        cityCode: "NO_12",
      },
      {
        name: "Jan Mayen",
        countryCode: "NO",
        latitude: "71.03181800",
        longitude: "-8.29203460",
        nameNative: null,
        label: "Jan Mayen",
        cityCode: "NO_22",
      },
      {
        name: "Møre og Romsdal",
        countryCode: "NO",
        latitude: "62.84068330",
        longitude: "7.00714300",
        nameNative: null,
        label: "Møre og Romsdal",
        cityCode: "NO_15",
      },
      {
        name: "Nord-Trøndelag",
        countryCode: "NO",
        latitude: "64.43707920",
        longitude: "11.74629500",
        nameNative: null,
        label: "Nord-Trøndelag",
        cityCode: "NO_17",
      },
      {
        name: "Nordland",
        countryCode: "NO",
        latitude: "67.69305800",
        longitude: "12.70739360",
        nameNative: null,
        label: "Nordland",
        cityCode: "NO_18",
      },
      {
        name: "Oppland",
        countryCode: "NO",
        latitude: "61.54227520",
        longitude: "9.71663150",
        nameNative: null,
        label: "Oppland",
        cityCode: "NO_05",
      },
      {
        name: "Oslo",
        countryCode: "NO",
        latitude: "59.91386880",
        longitude: "10.75224540",
        nameNative: null,
        label: "Oslo",
        cityCode: "NO_03",
      },
      {
        name: "Rogaland",
        countryCode: "NO",
        latitude: "59.14895440",
        longitude: "6.01434320",
        nameNative: null,
        label: "Rogaland",
        cityCode: "NO_11",
      },
      {
        name: "Sogn og Fjordane",
        countryCode: "NO",
        latitude: "61.55394350",
        longitude: "6.33258790",
        nameNative: null,
        label: "Sogn og Fjordane",
        cityCode: "NO_14",
      },
      {
        name: "Svalbard",
        countryCode: "NO",
        latitude: "77.87497250",
        longitude: "20.97518210",
        nameNative: null,
        label: "Svalbard",
        cityCode: "NO_21",
      },
      {
        name: "Sør-Trøndelag",
        countryCode: "NO",
        latitude: "63.01368230",
        longitude: "10.34871360",
        nameNative: null,
        label: "Sør-Trøndelag",
        cityCode: "NO_16",
      },
      {
        name: "Telemark",
        countryCode: "NO",
        latitude: "59.39139850",
        longitude: "8.32112090",
        nameNative: null,
        label: "Telemark",
        cityCode: "NO_08",
      },
      {
        name: "Troms",
        countryCode: "NO",
        latitude: "69.81782420",
        longitude: "18.78193650",
        nameNative: null,
        label: "Troms",
        cityCode: "NO_19",
      },
      {
        name: "Trøndelag",
        countryCode: "NO",
        latitude: "63.54201250",
        longitude: "10.93692670",
        nameNative: null,
        label: "Trøndelag",
        cityCode: "NO_50",
      },
      {
        name: "Vest-Agder",
        countryCode: "NO",
        latitude: "58.09990810",
        longitude: "6.58698090",
        nameNative: null,
        label: "Vest-Agder",
        cityCode: "NO_10",
      },
      {
        name: "Vestfold",
        countryCode: "NO",
        latitude: "59.17078620",
        longitude: "10.11443550",
        nameNative: null,
        label: "Vestfold",
        cityCode: "NO_07",
      },
      {
        name: "Østfold",
        countryCode: "NO",
        latitude: "59.25582860",
        longitude: "11.32790060",
        nameNative: null,
        label: "Østfold",
        cityCode: "NO_01",
      },
    ],
    OM: [
      {
        name: "Ad Dakhiliyah Governorate",
        countryCode: "OM",
        latitude: "22.85887580",
        longitude: "57.53943560",
        nameNative: null,
        label: "Ad Dakhiliyah Governorate",
        cityCode: "OM_DA",
      },
      {
        name: "Ad Dhahirah Governorate",
        countryCode: "OM",
        latitude: "23.21616740",
        longitude: "56.49074440",
        nameNative: null,
        label: "Ad Dhahirah Governorate",
        cityCode: "OM_ZA",
      },
      {
        name: "Al Batinah North Governorate",
        countryCode: "OM",
        latitude: "24.34198460",
        longitude: "56.72989040",
        nameNative: null,
        label: "Al Batinah North Governorate",
        cityCode: "OM_BS",
      },
      {
        name: "Al Batinah Region",
        countryCode: "OM",
        latitude: "24.34198460",
        longitude: "56.72989040",
        nameNative: null,
        label: "Al Batinah Region",
        cityCode: "OM_BA",
      },
      {
        name: "Al Batinah South Governorate",
        countryCode: "OM",
        latitude: "23.43149030",
        longitude: "57.42397960",
        nameNative: null,
        label: "Al Batinah South Governorate",
        cityCode: "OM_BJ",
      },
      {
        name: "Al Buraimi Governorate",
        countryCode: "OM",
        latitude: "24.16714130",
        longitude: "56.11422530",
        nameNative: null,
        label: "Al Buraimi Governorate",
        cityCode: "OM_BU",
      },
      {
        name: "Al Wusta Governorate",
        countryCode: "OM",
        latitude: "19.95710780",
        longitude: "56.27568460",
        nameNative: null,
        label: "Al Wusta Governorate",
        cityCode: "OM_WU",
      },
      {
        name: "Ash Sharqiyah North Governorate",
        countryCode: "OM",
        latitude: "22.71411960",
        longitude: "58.53080640",
        nameNative: null,
        label: "Ash Sharqiyah North Governorate",
        cityCode: "OM_SS",
      },
      {
        name: "Ash Sharqiyah Region",
        countryCode: "OM",
        latitude: "22.71411960",
        longitude: "58.53080640",
        nameNative: null,
        label: "Ash Sharqiyah Region",
        cityCode: "OM_SH",
      },
      {
        name: "Ash Sharqiyah South Governorate",
        countryCode: "OM",
        latitude: "22.01582490",
        longitude: "59.32519220",
        nameNative: null,
        label: "Ash Sharqiyah South Governorate",
        cityCode: "OM_SJ",
      },
      {
        name: "Dhofar Governorate",
        countryCode: "OM",
        latitude: "17.03221210",
        longitude: "54.14252140",
        nameNative: null,
        label: "Dhofar Governorate",
        cityCode: "OM_ZU",
      },
      {
        name: "Musandam Governorate",
        countryCode: "OM",
        latitude: "26.19861440",
        longitude: "56.24609490",
        nameNative: null,
        label: "Musandam Governorate",
        cityCode: "OM_MU",
      },
      {
        name: "Muscat Governorate",
        countryCode: "OM",
        latitude: "23.58803070",
        longitude: "58.38287170",
        nameNative: null,
        label: "Muscat Governorate",
        cityCode: "OM_MA",
      },
    ],
    PK: [
      {
        name: "Azad Kashmir",
        countryCode: "PK",
        latitude: "33.92590550",
        longitude: "73.78103340",
        nameNative: null,
        label: "Azad Kashmir",
        cityCode: "PK_JK",
      },
      {
        name: "Balochistan",
        countryCode: "PK",
        latitude: "28.49073320",
        longitude: "65.09577920",
        nameNative: null,
        label: "Balochistan",
        cityCode: "PK_BA",
      },
      {
        name: "Federally Administered Tribal Areas",
        countryCode: "PK",
        latitude: "32.66747600",
        longitude: "69.85974060",
        nameNative: null,
        label: "Federally Administered Tribal Areas",
        cityCode: "PK_TA",
      },
      {
        name: "Gilgit-Baltistan",
        countryCode: "PK",
        latitude: "35.80256670",
        longitude: "74.98318080",
        nameNative: null,
        label: "Gilgit-Baltistan",
        cityCode: "PK_GB",
      },
      {
        name: "Islamabad Capital Territory",
        countryCode: "PK",
        latitude: "33.72049970",
        longitude: "73.04052770",
        nameNative: null,
        label: "Islamabad Capital Territory",
        cityCode: "PK_IS",
      },
      {
        name: "Khyber Pakhtunkhwa",
        countryCode: "PK",
        latitude: "34.95262050",
        longitude: "72.33111300",
        nameNative: null,
        label: "Khyber Pakhtunkhwa",
        cityCode: "PK_KP",
      },
      {
        name: "Punjab",
        countryCode: "PK",
        latitude: "31.14713050",
        longitude: "75.34121790",
        nameNative: null,
        label: "Punjab",
        cityCode: "PK_PB",
      },
      {
        name: "Sindh",
        countryCode: "PK",
        latitude: "25.89430180",
        longitude: "68.52471490",
        nameNative: null,
        label: "Sindh",
        cityCode: "PK_SD",
      },
    ],
    PW: [
      {
        name: "Aimeliik",
        countryCode: "PW",
        latitude: "7.44558590",
        longitude: "134.50308780",
        nameNative: null,
        label: "Aimeliik",
        cityCode: "PW_002",
      },
      {
        name: "Airai",
        countryCode: "PW",
        latitude: "7.39661180",
        longitude: "134.56902250",
        nameNative: null,
        label: "Airai",
        cityCode: "PW_004",
      },
      {
        name: "Angaur",
        countryCode: "PW",
        latitude: "6.90922300",
        longitude: "134.13879340",
        nameNative: null,
        label: "Angaur",
        cityCode: "PW_010",
      },
      {
        name: "Hatohobei",
        countryCode: "PW",
        latitude: "3.00706580",
        longitude: "131.12377810",
        nameNative: null,
        label: "Hatohobei",
        cityCode: "PW_050",
      },
      {
        name: "Kayangel",
        countryCode: "PW",
        latitude: "8.07000000",
        longitude: "134.70277800",
        nameNative: null,
        label: "Kayangel",
        cityCode: "PW_100",
      },
      {
        name: "Koror",
        countryCode: "PW",
        latitude: "7.33756460",
        longitude: "134.48894690",
        nameNative: null,
        label: "Koror",
        cityCode: "PW_150",
      },
      {
        name: "Melekeok",
        countryCode: "PW",
        latitude: "7.51502860",
        longitude: "134.59725180",
        nameNative: null,
        label: "Melekeok",
        cityCode: "PW_212",
      },
      {
        name: "Ngaraard",
        countryCode: "PW",
        latitude: "7.60794000",
        longitude: "134.63486450",
        nameNative: null,
        label: "Ngaraard",
        cityCode: "PW_214",
      },
      {
        name: "Ngarchelong",
        countryCode: "PW",
        latitude: "7.71054690",
        longitude: "134.63016460",
        nameNative: null,
        label: "Ngarchelong",
        cityCode: "PW_218",
      },
      {
        name: "Ngardmau",
        countryCode: "PW",
        latitude: "7.58504860",
        longitude: "134.55960890",
        nameNative: null,
        label: "Ngardmau",
        cityCode: "PW_222",
      },
      {
        name: "Ngatpang",
        countryCode: "PW",
        latitude: "7.47109940",
        longitude: "134.52664660",
        nameNative: null,
        label: "Ngatpang",
        cityCode: "PW_224",
      },
      {
        name: "Ngchesar",
        countryCode: "PW",
        latitude: "7.45232800",
        longitude: "134.57843420",
        nameNative: null,
        label: "Ngchesar",
        cityCode: "PW_226",
      },
      {
        name: "Ngeremlengui",
        countryCode: "PW",
        latitude: "7.51983970",
        longitude: "134.55960890",
        nameNative: null,
        label: "Ngeremlengui",
        cityCode: "PW_227",
      },
      {
        name: "Ngiwal",
        countryCode: "PW",
        latitude: "7.56147640",
        longitude: "134.61606190",
        nameNative: null,
        label: "Ngiwal",
        cityCode: "PW_228",
      },
      {
        name: "Peleliu",
        countryCode: "PW",
        latitude: "7.00229060",
        longitude: "134.24316280",
        nameNative: null,
        label: "Peleliu",
        cityCode: "PW_350",
      },
      {
        name: "Sonsorol",
        countryCode: "PW",
        latitude: "5.32681190",
        longitude: "132.22391170",
        nameNative: null,
        label: "Sonsorol",
        cityCode: "PW_370",
      },
    ],
    PA: [
      {
        name: "Bocas del Toro Province",
        countryCode: "PA",
        latitude: "9.41655210",
        longitude: "-82.52077870",
        nameNative: null,
        label: "Bocas del Toro Province",
        cityCode: "PA_1",
      },
      {
        name: "Chiriquí Province",
        countryCode: "PA",
        latitude: "8.58489800",
        longitude: "-82.38857830",
        nameNative: null,
        label: "Chiriquí Province",
        cityCode: "PA_4",
      },
      {
        name: "Coclé Province",
        countryCode: "PA",
        latitude: "8.62660680",
        longitude: "-80.36586500",
        nameNative: null,
        label: "Coclé Province",
        cityCode: "PA_2",
      },
      {
        name: "Colón Province",
        countryCode: "PA",
        latitude: "9.18519890",
        longitude: "-80.05349230",
        nameNative: null,
        label: "Colón Province",
        cityCode: "PA_3",
      },
      {
        name: "Darién Province",
        countryCode: "PA",
        latitude: "7.86817130",
        longitude: "-77.83672820",
        nameNative: null,
        label: "Darién Province",
        cityCode: "PA_5",
      },
      {
        name: "Emberá-Wounaan Comarca",
        countryCode: "PA",
        latitude: "8.37669830",
        longitude: "-77.65361250",
        nameNative: null,
        label: "Emberá-Wounaan Comarca",
        cityCode: "PA_EM",
      },
      {
        name: "Guna Yala",
        countryCode: "PA",
        latitude: "9.23443950",
        longitude: "-78.19262500",
        nameNative: null,
        label: "Guna Yala",
        cityCode: "PA_KY",
      },
      {
        name: "Herrera Province",
        countryCode: "PA",
        latitude: "7.77042820",
        longitude: "-80.72144170",
        nameNative: null,
        label: "Herrera Province",
        cityCode: "PA_6",
      },
      {
        name: "Los Santos Province",
        countryCode: "PA",
        latitude: "7.59093020",
        longitude: "-80.36586500",
        nameNative: null,
        label: "Los Santos Province",
        cityCode: "PA_7",
      },
      {
        name: "Ngöbe-Buglé Comarca",
        countryCode: "PA",
        latitude: "8.65958330",
        longitude: "-81.77870210",
        nameNative: null,
        label: "Ngöbe-Buglé Comarca",
        cityCode: "PA_NB",
      },
      {
        name: "Panamá Oeste Province",
        countryCode: "PA",
        latitude: "9.11967510",
        longitude: "-79.29021330",
        nameNative: null,
        label: "Panamá Oeste Province",
        cityCode: "PA_10",
      },
      {
        name: "Panamá Province",
        countryCode: "PA",
        latitude: "9.11967510",
        longitude: "-79.29021330",
        nameNative: null,
        label: "Panamá Province",
        cityCode: "PA_8",
      },
      {
        name: "Veraguas Province",
        countryCode: "PA",
        latitude: "8.12310330",
        longitude: "-81.07546570",
        nameNative: null,
        label: "Veraguas Province",
        cityCode: "PA_9",
      },
    ],
    PG: [
      {
        name: "Bougainville",
        countryCode: "PG",
        latitude: "-6.37539190",
        longitude: "155.38071010",
        nameNative: null,
        label: "Bougainville",
        cityCode: "PG_NSB",
      },
      {
        name: "Central Province",
        countryCode: "PG",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Central Province",
        cityCode: "PG_CPM",
      },
      {
        name: "Chimbu Province",
        countryCode: "PG",
        latitude: "-6.30876820",
        longitude: "144.87312190",
        nameNative: null,
        label: "Chimbu Province",
        cityCode: "PG_CPK",
      },
      {
        name: "East New Britain",
        countryCode: "PG",
        latitude: "-4.61289430",
        longitude: "151.88773210",
        nameNative: null,
        label: "East New Britain",
        cityCode: "PG_EBR",
      },
      {
        name: "Eastern Highlands Province",
        countryCode: "PG",
        latitude: "-6.58616740",
        longitude: "145.66896360",
        nameNative: null,
        label: "Eastern Highlands Province",
        cityCode: "PG_EHG",
      },
      {
        name: "Enga Province",
        countryCode: "PG",
        latitude: "-5.30058490",
        longitude: "143.56356370",
        nameNative: null,
        label: "Enga Province",
        cityCode: "PG_EPW",
      },
      {
        name: "Gulf",
        countryCode: "PG",
        latitude: "37.05483150",
        longitude: "-94.43704190",
        nameNative: null,
        label: "Gulf",
        cityCode: "PG_GPK",
      },
      {
        name: "Hela",
        countryCode: "PG",
        latitude: "42.33295160",
        longitude: "-83.04826180",
        nameNative: null,
        label: "Hela",
        cityCode: "PG_HLA",
      },
      {
        name: "Jiwaka Province",
        countryCode: "PG",
        latitude: "-5.86911540",
        longitude: "144.69727740",
        nameNative: null,
        label: "Jiwaka Province",
        cityCode: "PG_JWK",
      },
      {
        name: "Madang Province",
        countryCode: "PG",
        latitude: "-4.98497330",
        longitude: "145.13758340",
        nameNative: null,
        label: "Madang Province",
        cityCode: "PG_MPM",
      },
      {
        name: "Manus Province",
        countryCode: "PG",
        latitude: "-2.09411690",
        longitude: "146.87609510",
        nameNative: null,
        label: "Manus Province",
        cityCode: "PG_MRL",
      },
      {
        name: "Milne Bay Province",
        countryCode: "PG",
        latitude: "-9.52214510",
        longitude: "150.67496530",
        nameNative: null,
        label: "Milne Bay Province",
        cityCode: "PG_MBA",
      },
      {
        name: "Morobe Province",
        countryCode: "PG",
        latitude: "-6.80137370",
        longitude: "146.56164700",
        nameNative: null,
        label: "Morobe Province",
        cityCode: "PG_MPL",
      },
      {
        name: "New Ireland Province",
        countryCode: "PG",
        latitude: "-4.28532560",
        longitude: "152.92059180",
        nameNative: null,
        label: "New Ireland Province",
        cityCode: "PG_NIK",
      },
      {
        name: "Oro Province",
        countryCode: "PG",
        latitude: "-8.89880630",
        longitude: "148.18929210",
        nameNative: null,
        label: "Oro Province",
        cityCode: "PG_NPP",
      },
      {
        name: "Port Moresby",
        countryCode: "PG",
        latitude: "-9.44380040",
        longitude: "147.18026710",
        nameNative: null,
        label: "Port Moresby",
        cityCode: "PG_NCD",
      },
      {
        name: "Sandaun Province",
        countryCode: "PG",
        latitude: "-3.71261790",
        longitude: "141.68342750",
        nameNative: null,
        label: "Sandaun Province",
        cityCode: "PG_SAN",
      },
      {
        name: "Southern Highlands Province",
        countryCode: "PG",
        latitude: "-6.41790830",
        longitude: "143.56356370",
        nameNative: null,
        label: "Southern Highlands Province",
        cityCode: "PG_SHM",
      },
      {
        name: "West New Britain Province",
        countryCode: "PG",
        latitude: "-5.70474320",
        longitude: "150.02594660",
        nameNative: null,
        label: "West New Britain Province",
        cityCode: "PG_WBK",
      },
      {
        name: "Western Highlands Province",
        countryCode: "PG",
        latitude: "-5.62681280",
        longitude: "144.25931180",
        nameNative: null,
        label: "Western Highlands Province",
        cityCode: "PG_WHM",
      },
      {
        name: "Western Province",
        countryCode: "PG",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Western Province",
        cityCode: "PG_WPD",
      },
    ],
    PY: [
      {
        name: "Alto Paraguay Department",
        countryCode: "PY",
        latitude: "-20.08525080",
        longitude: "-59.47209040",
        nameNative: null,
        label: "Alto Paraguay Department",
        cityCode: "PY_16",
      },
      {
        name: "Alto Paraná Department",
        countryCode: "PY",
        latitude: "-25.60755460",
        longitude: "-54.96118360",
        nameNative: null,
        label: "Alto Paraná Department",
        cityCode: "PY_10",
      },
      {
        name: "Amambay Department",
        countryCode: "PY",
        latitude: "-22.55902720",
        longitude: "-56.02499820",
        nameNative: null,
        label: "Amambay Department",
        cityCode: "PY_13",
      },
      {
        name: "Boquerón Department",
        countryCode: "PY",
        latitude: "-21.74492540",
        longitude: "-60.95400730",
        nameNative: null,
        label: "Boquerón Department",
        cityCode: "PY_19",
      },
      {
        name: "Caaguazú",
        countryCode: "PY",
        latitude: "-25.46458180",
        longitude: "-56.01385100",
        nameNative: null,
        label: "Caaguazú",
        cityCode: "PY_5",
      },
      {
        name: "Caazapá",
        countryCode: "PY",
        latitude: "-26.18277130",
        longitude: "-56.37123270",
        nameNative: null,
        label: "Caazapá",
        cityCode: "PY_6",
      },
      {
        name: "Canindeyú",
        countryCode: "PY",
        latitude: "-24.13787350",
        longitude: "-55.66896360",
        nameNative: null,
        label: "Canindeyú",
        cityCode: "PY_14",
      },
      {
        name: "Central Department",
        countryCode: "PY",
        latitude: "36.15592290",
        longitude: "-95.96620750",
        nameNative: null,
        label: "Central Department",
        cityCode: "PY_11",
      },
      {
        name: "Concepción Department",
        countryCode: "PY",
        latitude: "-23.42142640",
        longitude: "-57.43444510",
        nameNative: null,
        label: "Concepción Department",
        cityCode: "PY_1",
      },
      {
        name: "Cordillera Department",
        countryCode: "PY",
        latitude: "-25.22894910",
        longitude: "-57.01116810",
        nameNative: null,
        label: "Cordillera Department",
        cityCode: "PY_3",
      },
      {
        name: "Guairá Department",
        countryCode: "PY",
        latitude: "-25.88109320",
        longitude: "-56.29293810",
        nameNative: null,
        label: "Guairá Department",
        cityCode: "PY_4",
      },
      {
        name: "Itapúa",
        countryCode: "PY",
        latitude: "-26.79236230",
        longitude: "-55.66896360",
        nameNative: null,
        label: "Itapúa",
        cityCode: "PY_7",
      },
      {
        name: "Misiones Department",
        countryCode: "PY",
        latitude: "-26.84335120",
        longitude: "-57.10131880",
        nameNative: null,
        label: "Misiones Department",
        cityCode: "PY_8",
      },
      {
        name: "Paraguarí Department",
        countryCode: "PY",
        latitude: "-25.62621740",
        longitude: "-57.15206420",
        nameNative: null,
        label: "Paraguarí Department",
        cityCode: "PY_9",
      },
      {
        name: "Presidente Hayes Department",
        countryCode: "PY",
        latitude: "-23.35126050",
        longitude: "-58.73736340",
        nameNative: null,
        label: "Presidente Hayes Department",
        cityCode: "PY_15",
      },
      {
        name: "San Pedro Department",
        countryCode: "PY",
        latitude: "-24.19486680",
        longitude: "-56.56164700",
        nameNative: null,
        label: "San Pedro Department",
        cityCode: "PY_2",
      },
      {
        name: "Ñeembucú Department",
        countryCode: "PY",
        latitude: "-27.02991140",
        longitude: "-57.82539500",
        nameNative: null,
        label: "Ñeembucú Department",
        cityCode: "PY_12",
      },
    ],
    PE: [
      {
        name: "Amazonas",
        countryCode: "PE",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Amazonas",
        cityCode: "PE_AMA",
      },
      {
        name: "Apurímac",
        countryCode: "PE",
        latitude: "-14.05045330",
        longitude: "-73.08774900",
        nameNative: null,
        label: "Apurímac",
        cityCode: "PE_APU",
      },
      {
        name: "Arequipa",
        countryCode: "PE",
        latitude: "-16.40904740",
        longitude: "-71.53745100",
        nameNative: null,
        label: "Arequipa",
        cityCode: "PE_ARE",
      },
      {
        name: "Ayacucho",
        countryCode: "PE",
        latitude: "-13.16387370",
        longitude: "-74.22356410",
        nameNative: null,
        label: "Ayacucho",
        cityCode: "PE_AYA",
      },
      {
        name: "Cajamarca",
        countryCode: "PE",
        latitude: "-7.16174650",
        longitude: "-78.51278550",
        nameNative: null,
        label: "Cajamarca",
        cityCode: "PE_CAJ",
      },
      {
        name: "Callao",
        countryCode: "PE",
        latitude: "-12.05084910",
        longitude: "-77.12598430",
        nameNative: null,
        label: "Callao",
        cityCode: "PE_CAL",
      },
      {
        name: "Cusco",
        countryCode: "PE",
        latitude: "-13.53195000",
        longitude: "-71.96746260",
        nameNative: null,
        label: "Cusco",
        cityCode: "PE_CUS",
      },
      {
        name: "Huancavelica",
        countryCode: "PE",
        latitude: "-12.78619780",
        longitude: "-74.97640240",
        nameNative: null,
        label: "Huancavelica",
        cityCode: "PE_HUV",
      },
      {
        name: "Huanuco",
        countryCode: "PE",
        latitude: "-9.92076480",
        longitude: "-76.24108430",
        nameNative: null,
        label: "Huanuco",
        cityCode: "PE_HUC",
      },
      {
        name: "Ica",
        countryCode: "PE",
        latitude: "42.35288320",
        longitude: "-71.04300970",
        nameNative: null,
        label: "Ica",
        cityCode: "PE_ICA",
      },
      {
        name: "Junín",
        countryCode: "PE",
        latitude: "-11.15819250",
        longitude: "-75.99263060",
        nameNative: null,
        label: "Junín",
        cityCode: "PE_JUN",
      },
      {
        name: "La Libertad",
        countryCode: "PE",
        latitude: "13.49069700",
        longitude: "-89.30846070",
        nameNative: null,
        label: "La Libertad",
        cityCode: "PE_LAL",
      },
      {
        name: "Lambayeque",
        countryCode: "PE",
        latitude: "-6.71976660",
        longitude: "-79.90807570",
        nameNative: null,
        label: "Lambayeque",
        cityCode: "PE_LAM",
      },
      {
        name: "Lima",
        countryCode: "PE",
        latitude: "-12.04637310",
        longitude: "-77.04275400",
        nameNative: null,
        label: "Lima",
        cityCode: "PE_LIM",
      },
      {
        name: "Loreto",
        countryCode: "PE",
        latitude: "-4.37416430",
        longitude: "-76.13042640",
        nameNative: null,
        label: "Loreto",
        cityCode: "PE_LOR",
      },
      {
        name: "Madre de Dios",
        countryCode: "PE",
        latitude: "-11.76687050",
        longitude: "-70.81199530",
        nameNative: null,
        label: "Madre de Dios",
        cityCode: "PE_MDD",
      },
      {
        name: "Moquegua",
        countryCode: "PE",
        latitude: "-17.19273610",
        longitude: "-70.93281380",
        nameNative: null,
        label: "Moquegua",
        cityCode: "PE_MOQ",
      },
      {
        name: "Pasco",
        countryCode: "PE",
        latitude: "46.23050490",
        longitude: "-119.09223160",
        nameNative: null,
        label: "Pasco",
        cityCode: "PE_PAS",
      },
      {
        name: "Piura",
        countryCode: "PE",
        latitude: "-5.17828840",
        longitude: "-80.65488820",
        nameNative: null,
        label: "Piura",
        cityCode: "PE_PIU",
      },
      {
        name: "Puno",
        countryCode: "PE",
        latitude: "-15.84022180",
        longitude: "-70.02188050",
        nameNative: null,
        label: "Puno",
        cityCode: "PE_PUN",
      },
      {
        name: "San Martín",
        countryCode: "PE",
        latitude: "37.08494640",
        longitude: "-121.61022160",
        nameNative: null,
        label: "San Martín",
        cityCode: "PE_SAM",
      },
      {
        name: "Tacna",
        countryCode: "PE",
        latitude: "-18.00656790",
        longitude: "-70.24627410",
        nameNative: null,
        label: "Tacna",
        cityCode: "PE_TAC",
      },
      {
        name: "Tumbes",
        countryCode: "PE",
        latitude: "-3.55649210",
        longitude: "-80.42708850",
        nameNative: null,
        label: "Tumbes",
        cityCode: "PE_TUM",
      },
      {
        name: "Ucayali",
        countryCode: "PE",
        latitude: "-9.82511830",
        longitude: "-73.08774900",
        nameNative: null,
        label: "Ucayali",
        cityCode: "PE_UCA",
      },
      {
        name: "Áncash",
        countryCode: "PE",
        latitude: "-9.32504970",
        longitude: "-77.56194190",
        nameNative: null,
        label: "Áncash",
        cityCode: "PE_ANC",
      },
    ],
    PH: [
      {
        name: "Abra",
        countryCode: "PH",
        latitude: "42.49708300",
        longitude: "-96.38441000",
        nameNative: null,
        label: "Abra",
        cityCode: "PH_ABR",
      },
      {
        name: "Agusan del Norte",
        countryCode: "PH",
        latitude: "8.94562590",
        longitude: "125.53192340",
        nameNative: null,
        label: "Agusan del Norte",
        cityCode: "PH_AGN",
      },
      {
        name: "Agusan del Sur",
        countryCode: "PH",
        latitude: "8.04638880",
        longitude: "126.06153840",
        nameNative: null,
        label: "Agusan del Sur",
        cityCode: "PH_AGS",
      },
      {
        name: "Aklan",
        countryCode: "PH",
        latitude: "11.81661090",
        longitude: "122.09415410",
        nameNative: null,
        label: "Aklan",
        cityCode: "PH_AKL",
      },
      {
        name: "Albay",
        countryCode: "PH",
        latitude: "13.17748270",
        longitude: "123.52800720",
        nameNative: null,
        label: "Albay",
        cityCode: "PH_ALB",
      },
      {
        name: "Antique",
        countryCode: "PH",
        latitude: "37.03586950",
        longitude: "-95.63616940",
        nameNative: null,
        label: "Antique",
        cityCode: "PH_ANT",
      },
      {
        name: "Apayao",
        countryCode: "PH",
        latitude: "18.01203040",
        longitude: "121.17103890",
        nameNative: null,
        label: "Apayao",
        cityCode: "PH_APA",
      },
      {
        name: "Aurora",
        countryCode: "PH",
        latitude: "36.97089100",
        longitude: "-93.71797900",
        nameNative: null,
        label: "Aurora",
        cityCode: "PH_AUR",
      },
      {
        name: "Autonomous Region in Muslim Mindanao",
        countryCode: "PH",
        latitude: "6.95683130",
        longitude: "124.24215970",
        nameNative: null,
        label: "Autonomous Region in Muslim Mindanao",
        cityCode: "PH_14",
      },
      {
        name: "Basilan",
        countryCode: "PH",
        latitude: "6.42963490",
        longitude: "121.98701650",
        nameNative: null,
        label: "Basilan",
        cityCode: "PH_BAS",
      },
      {
        name: "Bataan",
        countryCode: "PH",
        latitude: "14.64168420",
        longitude: "120.48184460",
        nameNative: null,
        label: "Bataan",
        cityCode: "PH_BAN",
      },
      {
        name: "Batanes",
        countryCode: "PH",
        latitude: "20.44850740",
        longitude: "121.97081290",
        nameNative: null,
        label: "Batanes",
        cityCode: "PH_BTN",
      },
      {
        name: "Batangas",
        countryCode: "PH",
        latitude: "13.75646510",
        longitude: "121.05830760",
        nameNative: null,
        label: "Batangas",
        cityCode: "PH_BTG",
      },
      {
        name: "Benguet",
        countryCode: "PH",
        latitude: "16.55772570",
        longitude: "120.80394740",
        nameNative: null,
        label: "Benguet",
        cityCode: "PH_BEN",
      },
      {
        name: "Bicol Region",
        countryCode: "PH",
        latitude: "13.42098850",
        longitude: "123.41367360",
        nameNative: null,
        label: "Bicol Region",
        cityCode: "PH_05",
      },
      {
        name: "Biliran",
        countryCode: "PH",
        latitude: "11.58331520",
        longitude: "124.46418480",
        nameNative: null,
        label: "Biliran",
        cityCode: "PH_BIL",
      },
      {
        name: "Bohol",
        countryCode: "PH",
        latitude: "9.84999110",
        longitude: "124.14354270",
        nameNative: null,
        label: "Bohol",
        cityCode: "PH_BOH",
      },
      {
        name: "Bukidnon",
        countryCode: "PH",
        latitude: "8.05150540",
        longitude: "124.92299460",
        nameNative: null,
        label: "Bukidnon",
        cityCode: "PH_BUK",
      },
      {
        name: "Bulacan",
        countryCode: "PH",
        latitude: "14.79427350",
        longitude: "120.87990080",
        nameNative: null,
        label: "Bulacan",
        cityCode: "PH_BUL",
      },
      {
        name: "Cagayan Valley",
        countryCode: "PH",
        latitude: "16.97537580",
        longitude: "121.81070790",
        nameNative: null,
        label: "Cagayan Valley",
        cityCode: "PH_02",
      },
      {
        name: "Cagayan",
        countryCode: "PH",
        latitude: "18.24896290",
        longitude: "121.87878330",
        nameNative: null,
        label: "Cagayan",
        cityCode: "PH_CAG",
      },
      {
        name: "Calabarzon",
        countryCode: "PH",
        latitude: "14.10078030",
        longitude: "121.07937050",
        nameNative: null,
        label: "Calabarzon",
        cityCode: "PH_40",
      },
      {
        name: "Camarines Norte",
        countryCode: "PH",
        latitude: "14.13902650",
        longitude: "122.76330360",
        nameNative: null,
        label: "Camarines Norte",
        cityCode: "PH_CAN",
      },
      {
        name: "Camarines Sur",
        countryCode: "PH",
        latitude: "13.52501970",
        longitude: "123.34861470",
        nameNative: null,
        label: "Camarines Sur",
        cityCode: "PH_CAS",
      },
      {
        name: "Camiguin",
        countryCode: "PH",
        latitude: "9.17321640",
        longitude: "124.72987650",
        nameNative: null,
        label: "Camiguin",
        cityCode: "PH_CAM",
      },
      {
        name: "Capiz",
        countryCode: "PH",
        latitude: "11.55288160",
        longitude: "122.74072300",
        nameNative: null,
        label: "Capiz",
        cityCode: "PH_CAP",
      },
      {
        name: "Caraga",
        countryCode: "PH",
        latitude: "8.80145620",
        longitude: "125.74068820",
        nameNative: null,
        label: "Caraga",
        cityCode: "PH_13",
      },
      {
        name: "Catanduanes",
        countryCode: "PH",
        latitude: "13.70886840",
        longitude: "124.24215970",
        nameNative: null,
        label: "Catanduanes",
        cityCode: "PH_CAT",
      },
      {
        name: "Cavite",
        countryCode: "PH",
        latitude: "14.47912970",
        longitude: "120.89696340",
        nameNative: null,
        label: "Cavite",
        cityCode: "PH_CAV",
      },
      {
        name: "Cebu",
        countryCode: "PH",
        latitude: "10.31569920",
        longitude: "123.88543660",
        nameNative: null,
        label: "Cebu",
        cityCode: "PH_CEB",
      },
      {
        name: "Central Luzon",
        countryCode: "PH",
        latitude: "15.48277220",
        longitude: "120.71200230",
        nameNative: null,
        label: "Central Luzon",
        cityCode: "PH_03",
      },
      {
        name: "Central Visayas",
        countryCode: "PH",
        latitude: "9.81687500",
        longitude: "124.06414190",
        nameNative: null,
        label: "Central Visayas",
        cityCode: "PH_07",
      },
      {
        name: "Compostela Valley",
        countryCode: "PH",
        latitude: "7.51251500",
        longitude: "126.17626150",
        nameNative: null,
        label: "Compostela Valley",
        cityCode: "PH_COM",
      },
      {
        name: "Cordillera Administrative Region",
        countryCode: "PH",
        latitude: "17.35125420",
        longitude: "121.17188510",
        nameNative: null,
        label: "Cordillera Administrative Region",
        cityCode: "PH_15",
      },
      {
        name: "Cotabato",
        countryCode: "PH",
        latitude: "7.20466680",
        longitude: "124.23104390",
        nameNative: null,
        label: "Cotabato",
        cityCode: "PH_NCO",
      },
      {
        name: "Davao Occidental",
        countryCode: "PH",
        latitude: "6.09413960",
        longitude: "125.60954740",
        nameNative: null,
        label: "Davao Occidental",
        cityCode: "PH_DVO",
      },
      {
        name: "Davao Oriental",
        countryCode: "PH",
        latitude: "7.31715850",
        longitude: "126.54198870",
        nameNative: null,
        label: "Davao Oriental",
        cityCode: "PH_DAO",
      },
      {
        name: "Davao Region",
        countryCode: "PH",
        latitude: "7.30416220",
        longitude: "126.08934060",
        nameNative: null,
        label: "Davao Region",
        cityCode: "PH_11",
      },
      {
        name: "Davao del Norte",
        countryCode: "PH",
        latitude: "7.56176990",
        longitude: "125.65328480",
        nameNative: null,
        label: "Davao del Norte",
        cityCode: "PH_DAV",
      },
      {
        name: "Davao del Sur",
        countryCode: "PH",
        latitude: "6.76626870",
        longitude: "125.32842690",
        nameNative: null,
        label: "Davao del Sur",
        cityCode: "PH_DAS",
      },
      {
        name: "Dinagat Islands",
        countryCode: "PH",
        latitude: "10.12818160",
        longitude: "125.60954740",
        nameNative: null,
        label: "Dinagat Islands",
        cityCode: "PH_DIN",
      },
      {
        name: "Eastern Samar",
        countryCode: "PH",
        latitude: "11.50007310",
        longitude: "125.49999080",
        nameNative: null,
        label: "Eastern Samar",
        cityCode: "PH_EAS",
      },
      {
        name: "Eastern Visayas",
        countryCode: "PH",
        latitude: "12.24455330",
        longitude: "125.03881640",
        nameNative: null,
        label: "Eastern Visayas",
        cityCode: "PH_08",
      },
      {
        name: "Guimaras",
        countryCode: "PH",
        latitude: "10.59286610",
        longitude: "122.63250810",
        nameNative: null,
        label: "Guimaras",
        cityCode: "PH_GUI",
      },
      {
        name: "Ifugao",
        countryCode: "PH",
        latitude: "16.83307920",
        longitude: "121.17103890",
        nameNative: null,
        label: "Ifugao",
        cityCode: "PH_IFU",
      },
      {
        name: "Ilocos Norte",
        countryCode: "PH",
        latitude: "18.16472810",
        longitude: "120.71155920",
        nameNative: null,
        label: "Ilocos Norte",
        cityCode: "PH_ILN",
      },
      {
        name: "Ilocos Region",
        countryCode: "PH",
        latitude: "16.08321440",
        longitude: "120.61998950",
        nameNative: null,
        label: "Ilocos Region",
        cityCode: "PH_01",
      },
      {
        name: "Ilocos Sur",
        countryCode: "PH",
        latitude: "17.22786640",
        longitude: "120.57395790",
        nameNative: null,
        label: "Ilocos Sur",
        cityCode: "PH_ILS",
      },
      {
        name: "Iloilo",
        countryCode: "PH",
        latitude: "10.72015010",
        longitude: "122.56210630",
        nameNative: null,
        label: "Iloilo",
        cityCode: "PH_ILI",
      },
      {
        name: "Isabela",
        countryCode: "PH",
        latitude: "18.50077590",
        longitude: "-67.02434620",
        nameNative: null,
        label: "Isabela",
        cityCode: "PH_ISA",
      },
      {
        name: "Kalinga",
        countryCode: "PH",
        latitude: "17.47404220",
        longitude: "121.35416310",
        nameNative: null,
        label: "Kalinga",
        cityCode: "PH_KAL",
      },
      {
        name: "La Union",
        countryCode: "PH",
        latitude: "38.87668780",
        longitude: "-77.12809120",
        nameNative: null,
        label: "La Union",
        cityCode: "PH_LUN",
      },
      {
        name: "Laguna",
        countryCode: "PH",
        latitude: "33.54271890",
        longitude: "-117.78535680",
        nameNative: null,
        label: "Laguna",
        cityCode: "PH_LAG",
      },
      {
        name: "Lanao del Norte",
        countryCode: "PH",
        latitude: "7.87218110",
        longitude: "123.88577470",
        nameNative: null,
        label: "Lanao del Norte",
        cityCode: "PH_LAN",
      },
      {
        name: "Lanao del Sur",
        countryCode: "PH",
        latitude: "7.82317600",
        longitude: "124.41982430",
        nameNative: null,
        label: "Lanao del Sur",
        cityCode: "PH_LAS",
      },
      {
        name: "Leyte",
        countryCode: "PH",
        latitude: "10.86245360",
        longitude: "124.88111950",
        nameNative: null,
        label: "Leyte",
        cityCode: "PH_LEY",
      },
      {
        name: "Maguindanao",
        countryCode: "PH",
        latitude: "6.94225810",
        longitude: "124.41982430",
        nameNative: null,
        label: "Maguindanao",
        cityCode: "PH_MAG",
      },
      {
        name: "Marinduque",
        countryCode: "PH",
        latitude: "13.47671710",
        longitude: "121.90321920",
        nameNative: null,
        label: "Marinduque",
        cityCode: "PH_MAD",
      },
      {
        name: "Masbate",
        countryCode: "PH",
        latitude: "12.35743460",
        longitude: "123.55040760",
        nameNative: null,
        label: "Masbate",
        cityCode: "PH_MAS",
      },
      {
        name: "Metro Manila",
        countryCode: "PH",
        latitude: "14.60905370",
        longitude: "121.02225650",
        nameNative: null,
        label: "Metro Manila",
        cityCode: "PH_NCR",
      },
      {
        name: "Mimaropa",
        countryCode: "PH",
        latitude: "9.84320650",
        longitude: "118.73647830",
        nameNative: null,
        label: "Mimaropa",
        cityCode: "PH_41",
      },
      {
        name: "Misamis Occidental",
        countryCode: "PH",
        latitude: "8.33749030",
        longitude: "123.70706190",
        nameNative: null,
        label: "Misamis Occidental",
        cityCode: "PH_MSC",
      },
      {
        name: "Misamis Oriental",
        countryCode: "PH",
        latitude: "8.50455580",
        longitude: "124.62195920",
        nameNative: null,
        label: "Misamis Oriental",
        cityCode: "PH_MSR",
      },
      {
        name: "Mountain Province",
        countryCode: "PH",
        latitude: "40.70754370",
        longitude: "-73.95010330",
        nameNative: null,
        label: "Mountain Province",
        cityCode: "PH_MOU",
      },
      {
        name: "Negros Occidental",
        countryCode: "PH",
        latitude: "10.29256090",
        longitude: "123.02465180",
        nameNative: null,
        label: "Negros Occidental",
        cityCode: "PH_NEC",
      },
      {
        name: "Negros Oriental",
        countryCode: "PH",
        latitude: "9.62820830",
        longitude: "122.98883190",
        nameNative: null,
        label: "Negros Oriental",
        cityCode: "PH_NER",
      },
      {
        name: "Northern Mindanao",
        countryCode: "PH",
        latitude: "8.02016350",
        longitude: "124.68565090",
        nameNative: null,
        label: "Northern Mindanao",
        cityCode: "PH_10",
      },
      {
        name: "Northern Samar",
        countryCode: "PH",
        latitude: "12.36131990",
        longitude: "124.77407930",
        nameNative: null,
        label: "Northern Samar",
        cityCode: "PH_NSA",
      },
      {
        name: "Nueva Ecija",
        countryCode: "PH",
        latitude: "15.57837500",
        longitude: "121.11126150",
        nameNative: null,
        label: "Nueva Ecija",
        cityCode: "PH_NUE",
      },
      {
        name: "Nueva Vizcaya",
        countryCode: "PH",
        latitude: "16.33011070",
        longitude: "121.17103890",
        nameNative: null,
        label: "Nueva Vizcaya",
        cityCode: "PH_NUV",
      },
      {
        name: "Occidental Mindoro",
        countryCode: "PH",
        latitude: "13.10241110",
        longitude: "120.76512840",
        nameNative: null,
        label: "Occidental Mindoro",
        cityCode: "PH_MDC",
      },
      {
        name: "Oriental Mindoro",
        countryCode: "PH",
        latitude: "13.05645980",
        longitude: "121.40694170",
        nameNative: null,
        label: "Oriental Mindoro",
        cityCode: "PH_MDR",
      },
      {
        name: "Palawan",
        countryCode: "PH",
        latitude: "9.83494930",
        longitude: "118.73836150",
        nameNative: null,
        label: "Palawan",
        cityCode: "PH_PLW",
      },
      {
        name: "Pampanga",
        countryCode: "PH",
        latitude: "15.07940900",
        longitude: "120.61998950",
        nameNative: null,
        label: "Pampanga",
        cityCode: "PH_PAM",
      },
      {
        name: "Pangasinan",
        countryCode: "PH",
        latitude: "15.89490550",
        longitude: "120.28631830",
        nameNative: null,
        label: "Pangasinan",
        cityCode: "PH_PAN",
      },
      {
        name: "Quezon",
        countryCode: "PH",
        latitude: "14.03139060",
        longitude: "122.11309090",
        nameNative: null,
        label: "Quezon",
        cityCode: "PH_QUE",
      },
      {
        name: "Quirino",
        countryCode: "PH",
        latitude: "16.27004240",
        longitude: "121.53700030",
        nameNative: null,
        label: "Quirino",
        cityCode: "PH_QUI",
      },
      {
        name: "Rizal",
        countryCode: "PH",
        latitude: "14.60374460",
        longitude: "121.30840880",
        nameNative: null,
        label: "Rizal",
        cityCode: "PH_RIZ",
      },
      {
        name: "Romblon",
        countryCode: "PH",
        latitude: "12.57780160",
        longitude: "122.26914600",
        nameNative: null,
        label: "Romblon",
        cityCode: "PH_ROM",
      },
      {
        name: "Sarangani",
        countryCode: "PH",
        latitude: "5.92671750",
        longitude: "124.99475100",
        nameNative: null,
        label: "Sarangani",
        cityCode: "PH_SAR",
      },
      {
        name: "Siquijor",
        countryCode: "PH",
        latitude: "9.19987790",
        longitude: "123.59519250",
        nameNative: null,
        label: "Siquijor",
        cityCode: "PH_SIG",
      },
      {
        name: "Soccsksargen",
        countryCode: "PH",
        latitude: "6.27069180",
        longitude: "124.68565090",
        nameNative: null,
        label: "Soccsksargen",
        cityCode: "PH_12",
      },
      {
        name: "Sorsogon",
        countryCode: "PH",
        latitude: "12.99270950",
        longitude: "124.01474640",
        nameNative: null,
        label: "Sorsogon",
        cityCode: "PH_SOR",
      },
      {
        name: "South Cotabato",
        countryCode: "PH",
        latitude: "6.33575650",
        longitude: "124.77407930",
        nameNative: null,
        label: "South Cotabato",
        cityCode: "PH_SCO",
      },
      {
        name: "Southern Leyte",
        countryCode: "PH",
        latitude: "10.33462060",
        longitude: "125.17087410",
        nameNative: null,
        label: "Southern Leyte",
        cityCode: "PH_SLE",
      },
      {
        name: "Sultan Kudarat",
        countryCode: "PH",
        latitude: "6.50694010",
        longitude: "124.41982430",
        nameNative: null,
        label: "Sultan Kudarat",
        cityCode: "PH_SUK",
      },
      {
        name: "Sulu",
        countryCode: "PH",
        latitude: "5.97490110",
        longitude: "121.03351000",
        nameNative: null,
        label: "Sulu",
        cityCode: "PH_SLU",
      },
      {
        name: "Surigao del Norte",
        countryCode: "PH",
        latitude: "9.51482800",
        longitude: "125.69699840",
        nameNative: null,
        label: "Surigao del Norte",
        cityCode: "PH_SUN",
      },
      {
        name: "Surigao del Sur",
        countryCode: "PH",
        latitude: "8.54049060",
        longitude: "126.11447580",
        nameNative: null,
        label: "Surigao del Sur",
        cityCode: "PH_SUR",
      },
      {
        name: "Tarlac",
        countryCode: "PH",
        latitude: "15.47547860",
        longitude: "120.59634920",
        nameNative: null,
        label: "Tarlac",
        cityCode: "PH_TAR",
      },
      {
        name: "Tawi-Tawi",
        countryCode: "PH",
        latitude: "5.13381100",
        longitude: "119.95092600",
        nameNative: null,
        label: "Tawi-Tawi",
        cityCode: "PH_TAW",
      },
      {
        name: "Western Visayas",
        countryCode: "PH",
        latitude: "11.00498360",
        longitude: "122.53727410",
        nameNative: null,
        label: "Western Visayas",
        cityCode: "PH_06",
      },
      {
        name: "Zambales",
        countryCode: "PH",
        latitude: "15.50817660",
        longitude: "119.96978080",
        nameNative: null,
        label: "Zambales",
        cityCode: "PH_ZMB",
      },
      {
        name: "Zamboanga Peninsula",
        countryCode: "PH",
        latitude: "8.15407700",
        longitude: "123.25879300",
        nameNative: null,
        label: "Zamboanga Peninsula",
        cityCode: "PH_09",
      },
      {
        name: "Zamboanga Sibugay",
        countryCode: "PH",
        latitude: "7.52252470",
        longitude: "122.31075170",
        nameNative: null,
        label: "Zamboanga Sibugay",
        cityCode: "PH_ZSI",
      },
      {
        name: "Zamboanga del Norte",
        countryCode: "PH",
        latitude: "8.38862820",
        longitude: "123.16888830",
        nameNative: null,
        label: "Zamboanga del Norte",
        cityCode: "PH_ZAN",
      },
      {
        name: "Zamboanga del Sur",
        countryCode: "PH",
        latitude: "7.83830540",
        longitude: "123.29666570",
        nameNative: null,
        label: "Zamboanga del Sur",
        cityCode: "PH_ZAS",
      },
    ],
    PL: [
      {
        name: "Greater Poland Voivodeship",
        countryCode: "PL",
        latitude: "52.27998600",
        longitude: "17.35229390",
        nameNative: "Województwo wielkopolskie",
        label: "Województwo wielkopolskie / Greater Poland Voivodeship",
        cityCode: "PL_WP",
      },
      {
        name: "Kuyavian-Pomeranian Voivodeship",
        countryCode: "PL",
        latitude: "53.16483630",
        longitude: "18.48342240",
        nameNative: "Województwo kujawsko-pomorskie",
        label:
          "Województwo kujawsko-pomorskie / Kuyavian-Pomeranian Voivodeship",
        cityCode: "PL_KP",
      },
      {
        name: "Lesser Poland Voivodeship",
        countryCode: "PL",
        latitude: "49.72253060",
        longitude: "20.25033580",
        nameNative: "Województwo małopolskie",
        label: "Województwo małopolskie / Lesser Poland Voivodeship",
        cityCode: "PL_MA",
      },
      {
        name: "Lower Silesian Voivodeship",
        countryCode: "PL",
        latitude: "51.13398610",
        longitude: "16.88419610",
        nameNative: "Województwo dolnośląskie",
        label: "Województwo dolnośląskie / Lower Silesian Voivodeship",
        cityCode: "PL_DS",
      },
      {
        name: "Lublin Voivodeship",
        countryCode: "PL",
        latitude: "51.24935190",
        longitude: "23.10113920",
        nameNative: "Województwo lubelskie",
        label: "Województwo lubelskie / Lublin Voivodeship",
        cityCode: "PL_LU",
      },
      {
        name: "Lubusz Voivodeship",
        countryCode: "PL",
        latitude: "52.22746120",
        longitude: "15.25591030",
        nameNative: "Województwo lubuskie",
        label: "Województwo lubuskie / Lubusz Voivodeship",
        cityCode: "PL_LB",
      },
      {
        name: "Masovian Voivodeship",
        countryCode: "PL",
        latitude: "51.89271820",
        longitude: "21.00216790",
        nameNative: "Województwo mazowieckie",
        label: "Województwo mazowieckie / Masovian Voivodeship",
        cityCode: "PL_MZ",
      },
      {
        name: "Opole Voivodeship",
        countryCode: "PL",
        latitude: "50.80037610",
        longitude: "17.93798900",
        nameNative: "Województwo opolskie",
        label: "Województwo opolskie / Opole Voivodeship",
        cityCode: "PL_OP",
      },
      {
        name: "Podkarpackie Voivodeship",
        countryCode: "PL",
        latitude: "50.05747490",
        longitude: "22.08956910",
        nameNative: "Województwo podkarpackie",
        label: "Województwo podkarpackie / Podkarpackie Voivodeship",
        cityCode: "PL_PK",
      },
      {
        name: "Podlaskie Voivodeship",
        countryCode: "PL",
        latitude: "53.06971590",
        longitude: "22.96746390",
        nameNative: "Województwo podlaskie",
        label: "Województwo podlaskie / Podlaskie Voivodeship",
        cityCode: "PL_PD",
      },
      {
        name: "Pomeranian Voivodeship",
        countryCode: "PL",
        latitude: "54.29442520",
        longitude: "18.15311640",
        nameNative: "Województwo pomorskie",
        label: "Województwo pomorskie / Pomeranian Voivodeship",
        cityCode: "PL_PM",
      },
      {
        name: "Silesian Voivodeship",
        countryCode: "PL",
        latitude: "50.57165950",
        longitude: "19.32197680",
        nameNative: "Województwo śląskie",
        label: "Województwo śląskie / Silesian Voivodeship",
        cityCode: "PL_SL",
      },
      {
        name: "Warmian-Masurian Voivodeship",
        countryCode: "PL",
        latitude: "53.86711170",
        longitude: "20.70278610",
        nameNative: "Województwo warmińsko-mazurskie",
        label: "Województwo warmińsko-mazurskie / Warmian-Masurian Voivodeship",
        cityCode: "PL_WN",
      },
      {
        name: "West Pomeranian Voivodeship",
        countryCode: "PL",
        latitude: "53.46578910",
        longitude: "15.18225810",
        nameNative: "Województwo zachodniopomorskie",
        label: "Województwo zachodniopomorskie / West Pomeranian Voivodeship",
        cityCode: "PL_ZP",
      },
      {
        name: "Łódź Voivodeship",
        countryCode: "PL",
        latitude: "51.46347710",
        longitude: "19.17269740",
        nameNative: "Województwo łódzkie",
        label: "Województwo łódzkie / Łódź Voivodeship",
        cityCode: "PL_LD",
      },
      {
        name: "Świętokrzyskie Voivodeship",
        countryCode: "PL",
        latitude: "50.62610410",
        longitude: "20.94062790",
        nameNative: "Województwo świętokrzyskie",
        label: "Województwo świętokrzyskie / Świętokrzyskie Voivodeship",
        cityCode: "PL_SK",
      },
    ],
    PT: [
      {
        name: "Aveiro",
        countryCode: "PT",
        latitude: "40.72090230",
        longitude: "-8.57210160",
        nameNative: null,
        label: "Aveiro",
        cityCode: "PT_01",
      },
      {
        name: "Açores",
        countryCode: "PT",
        latitude: "37.74124880",
        longitude: "-25.67559440",
        nameNative: null,
        label: "Açores",
        cityCode: "PT_20",
      },
      {
        name: "Beja",
        countryCode: "PT",
        latitude: "37.96877860",
        longitude: "-7.87216000",
        nameNative: null,
        label: "Beja",
        cityCode: "PT_02",
      },
      {
        name: "Braga",
        countryCode: "PT",
        latitude: "41.55038800",
        longitude: "-8.42613010",
        nameNative: null,
        label: "Braga",
        cityCode: "PT_03",
      },
      {
        name: "Bragança",
        countryCode: "PT",
        latitude: "41.80616520",
        longitude: "-6.75674270",
        nameNative: null,
        label: "Bragança",
        cityCode: "PT_04",
      },
      {
        name: "Castelo Branco",
        countryCode: "PT",
        latitude: "39.86313230",
        longitude: "-7.48141630",
        nameNative: null,
        label: "Castelo Branco",
        cityCode: "PT_05",
      },
      {
        name: "Coimbra",
        countryCode: "PT",
        latitude: "40.20579940",
        longitude: "-8.41369000",
        nameNative: null,
        label: "Coimbra",
        cityCode: "PT_06",
      },
      {
        name: "Faro",
        countryCode: "PT",
        latitude: "37.01935480",
        longitude: "-7.93043970",
        nameNative: null,
        label: "Faro",
        cityCode: "PT_08",
      },
      {
        name: "Guarda",
        countryCode: "PT",
        latitude: "40.53859720",
        longitude: "-7.26757720",
        nameNative: null,
        label: "Guarda",
        cityCode: "PT_09",
      },
      {
        name: "Leiria",
        countryCode: "PT",
        latitude: "39.77095320",
        longitude: "-8.79218360",
        nameNative: null,
        label: "Leiria",
        cityCode: "PT_10",
      },
      {
        name: "Lisbon",
        countryCode: "PT",
        latitude: "38.72232630",
        longitude: "-9.13927140",
        nameNative: null,
        label: "Lisbon",
        cityCode: "PT_11",
      },
      {
        name: "Madeira",
        countryCode: "PT",
        latitude: "32.76070740",
        longitude: "-16.95947230",
        nameNative: null,
        label: "Madeira",
        cityCode: "PT_30",
      },
      {
        name: "Portalegre",
        countryCode: "PT",
        latitude: "39.29670860",
        longitude: "-7.42847550",
        nameNative: null,
        label: "Portalegre",
        cityCode: "PT_12",
      },
      {
        name: "Porto",
        countryCode: "PT",
        latitude: "41.14766290",
        longitude: "-8.60789730",
        nameNative: null,
        label: "Porto",
        cityCode: "PT_13",
      },
      {
        name: "Santarém",
        countryCode: "PT",
        latitude: "39.23666870",
        longitude: "-8.68599440",
        nameNative: null,
        label: "Santarém",
        cityCode: "PT_14",
      },
      {
        name: "Setúbal",
        countryCode: "PT",
        latitude: "38.52409330",
        longitude: "-8.89258760",
        nameNative: null,
        label: "Setúbal",
        cityCode: "PT_15",
      },
      {
        name: "Viana do Castelo",
        countryCode: "PT",
        latitude: "41.69180460",
        longitude: "-8.83445100",
        nameNative: null,
        label: "Viana do Castelo",
        cityCode: "PT_16",
      },
      {
        name: "Vila Real",
        countryCode: "PT",
        latitude: "41.30035270",
        longitude: "-7.74572740",
        nameNative: null,
        label: "Vila Real",
        cityCode: "PT_17",
      },
      {
        name: "Viseu",
        countryCode: "PT",
        latitude: "40.65884240",
        longitude: "-7.91475600",
        nameNative: null,
        label: "Viseu",
        cityCode: "PT_18",
      },
      {
        name: "Évora",
        countryCode: "PT",
        latitude: "38.57444680",
        longitude: "-7.90765530",
        nameNative: null,
        label: "Évora",
        cityCode: "PT_07",
      },
    ],
    QA: [
      {
        name: "Al Daayen",
        countryCode: "QA",
        latitude: "25.57845590",
        longitude: "51.48213870",
        nameNative: null,
        label: "Al Daayen",
        cityCode: "QA_ZA",
      },
      {
        name: "Al Khor",
        countryCode: "QA",
        latitude: "25.68040780",
        longitude: "51.49685020",
        nameNative: null,
        label: "Al Khor",
        cityCode: "QA_KH",
      },
      {
        name: "Al Rayyan Municipality",
        countryCode: "QA",
        latitude: "25.25225510",
        longitude: "51.43887130",
        nameNative: null,
        label: "Al Rayyan Municipality",
        cityCode: "QA_RA",
      },
      {
        name: "Al Wakrah",
        countryCode: "QA",
        latitude: "25.16593140",
        longitude: "51.59755240",
        nameNative: null,
        label: "Al Wakrah",
        cityCode: "QA_WA",
      },
      {
        name: "Al-Shahaniya",
        countryCode: "QA",
        latitude: "25.41063860",
        longitude: "51.18460250",
        nameNative: null,
        label: "Al-Shahaniya",
        cityCode: "QA_SH",
      },
      {
        name: "Doha",
        countryCode: "QA",
        latitude: "25.28544730",
        longitude: "51.53103980",
        nameNative: null,
        label: "Doha",
        cityCode: "QA_DA",
      },
      {
        name: "Madinat ash Shamal",
        countryCode: "QA",
        latitude: "26.11827430",
        longitude: "51.21572650",
        nameNative: null,
        label: "Madinat ash Shamal",
        cityCode: "QA_MS",
      },
      {
        name: "Umm Salal Municipality",
        countryCode: "QA",
        latitude: "25.48752420",
        longitude: "51.39656800",
        nameNative: null,
        label: "Umm Salal Municipality",
        cityCode: "QA_US",
      },
    ],
    RO: [
      {
        name: "Alba",
        countryCode: "RO",
        latitude: "44.70091530",
        longitude: "8.03569110",
        nameNative: null,
        label: "Alba",
        cityCode: "RO_AB",
      },
      {
        name: "Arad County",
        countryCode: "RO",
        latitude: "46.22836510",
        longitude: "21.65978190",
        nameNative: null,
        label: "Arad County",
        cityCode: "RO_AR",
      },
      {
        name: "Arges",
        countryCode: "RO",
        latitude: "45.07225270",
        longitude: "24.81427260",
        nameNative: null,
        label: "Arges",
        cityCode: "RO_AG",
      },
      {
        name: "Bacău County",
        countryCode: "RO",
        latitude: "46.32581840",
        longitude: "26.66237800",
        nameNative: null,
        label: "Bacău County",
        cityCode: "RO_BC",
      },
      {
        name: "Bihor County",
        countryCode: "RO",
        latitude: "47.01575160",
        longitude: "22.17226600",
        nameNative: null,
        label: "Bihor County",
        cityCode: "RO_BH",
      },
      {
        name: "Bistrița-Năsăud County",
        countryCode: "RO",
        latitude: "47.24861070",
        longitude: "24.53228140",
        nameNative: null,
        label: "Bistrița-Năsăud County",
        cityCode: "RO_BN",
      },
      {
        name: "Botoșani County",
        countryCode: "RO",
        latitude: "47.89240420",
        longitude: "26.75917810",
        nameNative: null,
        label: "Botoșani County",
        cityCode: "RO_BT",
      },
      {
        name: "Braila",
        countryCode: "RO",
        latitude: "45.26524630",
        longitude: "27.95947140",
        nameNative: null,
        label: "Braila",
        cityCode: "RO_BR",
      },
      {
        name: "Brașov County",
        countryCode: "RO",
        latitude: "45.77818440",
        longitude: "25.22258000",
        nameNative: null,
        label: "Brașov County",
        cityCode: "RO_BV",
      },
      {
        name: "Bucharest",
        countryCode: "RO",
        latitude: "44.42676740",
        longitude: "26.10253840",
        nameNative: null,
        label: "Bucharest",
        cityCode: "RO_B",
      },
      {
        name: "Buzău County",
        countryCode: "RO",
        latitude: "45.33509120",
        longitude: "26.71075780",
        nameNative: null,
        label: "Buzău County",
        cityCode: "RO_BZ",
      },
      {
        name: "Caraș-Severin County",
        countryCode: "RO",
        latitude: "45.11396460",
        longitude: "22.07409930",
        nameNative: null,
        label: "Caraș-Severin County",
        cityCode: "RO_CS",
      },
      {
        name: "Cluj County",
        countryCode: "RO",
        latitude: "46.79417970",
        longitude: "23.61214920",
        nameNative: null,
        label: "Cluj County",
        cityCode: "RO_CJ",
      },
      {
        name: "Constanța County",
        countryCode: "RO",
        latitude: "44.21298700",
        longitude: "28.25500550",
        nameNative: null,
        label: "Constanța County",
        cityCode: "RO_CT",
      },
      {
        name: "Covasna County",
        countryCode: "RO",
        latitude: "45.94263470",
        longitude: "25.89189840",
        nameNative: null,
        label: "Covasna County",
        cityCode: "RO_CV",
      },
      {
        name: "Călărași County",
        countryCode: "RO",
        latitude: "44.36587150",
        longitude: "26.75486070",
        nameNative: null,
        label: "Călărași County",
        cityCode: "RO_CL",
      },
      {
        name: "Dolj County",
        countryCode: "RO",
        latitude: "44.16230220",
        longitude: "23.63250540",
        nameNative: null,
        label: "Dolj County",
        cityCode: "RO_DJ",
      },
      {
        name: "Dâmbovița County",
        countryCode: "RO",
        latitude: "44.92898930",
        longitude: "25.42538500",
        nameNative: null,
        label: "Dâmbovița County",
        cityCode: "RO_DB",
      },
      {
        name: "Galați County",
        countryCode: "RO",
        latitude: "45.78005690",
        longitude: "27.82515760",
        nameNative: null,
        label: "Galați County",
        cityCode: "RO_GL",
      },
      {
        name: "Giurgiu County",
        countryCode: "RO",
        latitude: "43.90370760",
        longitude: "25.96992650",
        nameNative: null,
        label: "Giurgiu County",
        cityCode: "RO_GR",
      },
      {
        name: "Gorj County",
        countryCode: "RO",
        latitude: "44.94855950",
        longitude: "23.24270790",
        nameNative: null,
        label: "Gorj County",
        cityCode: "RO_GJ",
      },
      {
        name: "Harghita County",
        countryCode: "RO",
        latitude: "46.49285070",
        longitude: "25.64566960",
        nameNative: null,
        label: "Harghita County",
        cityCode: "RO_HR",
      },
      {
        name: "Hunedoara County",
        countryCode: "RO",
        latitude: "45.79363800",
        longitude: "22.99759930",
        nameNative: null,
        label: "Hunedoara County",
        cityCode: "RO_HD",
      },
      {
        name: "Ialomița County",
        countryCode: "RO",
        latitude: "44.60313300",
        longitude: "27.37899140",
        nameNative: null,
        label: "Ialomița County",
        cityCode: "RO_IL",
      },
      {
        name: "Iași County",
        countryCode: "RO",
        latitude: "47.26796530",
        longitude: "27.21856620",
        nameNative: null,
        label: "Iași County",
        cityCode: "RO_IS",
      },
      {
        name: "Ilfov County",
        countryCode: "RO",
        latitude: "44.53554800",
        longitude: "26.23248860",
        nameNative: null,
        label: "Ilfov County",
        cityCode: "RO_IF",
      },
      {
        name: "Maramureș County",
        countryCode: "RO",
        latitude: "46.55699040",
        longitude: "24.67232150",
        nameNative: null,
        label: "Maramureș County",
        cityCode: "RO_MM",
      },
      {
        name: "Mehedinți County",
        countryCode: "RO",
        latitude: "44.55150530",
        longitude: "22.90441570",
        nameNative: null,
        label: "Mehedinți County",
        cityCode: "RO_MH",
      },
      {
        name: "Mureș County",
        countryCode: "RO",
        latitude: "46.55699040",
        longitude: "24.67232150",
        nameNative: null,
        label: "Mureș County",
        cityCode: "RO_MS",
      },
      {
        name: "Neamț County",
        countryCode: "RO",
        latitude: "46.97586850",
        longitude: "26.38187640",
        nameNative: null,
        label: "Neamț County",
        cityCode: "RO_NT",
      },
      {
        name: "Olt County",
        countryCode: "RO",
        latitude: "44.20079700",
        longitude: "24.50229810",
        nameNative: null,
        label: "Olt County",
        cityCode: "RO_OT",
      },
      {
        name: "Prahova County",
        countryCode: "RO",
        latitude: "45.08919060",
        longitude: "26.08293130",
        nameNative: null,
        label: "Prahova County",
        cityCode: "RO_PH",
      },
      {
        name: "Satu Mare County",
        countryCode: "RO",
        latitude: "47.76689050",
        longitude: "22.92413770",
        nameNative: null,
        label: "Satu Mare County",
        cityCode: "RO_SM",
      },
      {
        name: "Sibiu County",
        countryCode: "RO",
        latitude: "45.92691060",
        longitude: "24.22548070",
        nameNative: null,
        label: "Sibiu County",
        cityCode: "RO_SB",
      },
      {
        name: "Suceava County",
        countryCode: "RO",
        latitude: "47.55055480",
        longitude: "25.74106200",
        nameNative: null,
        label: "Suceava County",
        cityCode: "RO_SV",
      },
      {
        name: "Sălaj County",
        countryCode: "RO",
        latitude: "47.20908130",
        longitude: "23.21219010",
        nameNative: null,
        label: "Sălaj County",
        cityCode: "RO_SJ",
      },
      {
        name: "Teleorman County",
        countryCode: "RO",
        latitude: "44.01604910",
        longitude: "25.29866280",
        nameNative: null,
        label: "Teleorman County",
        cityCode: "RO_TR",
      },
      {
        name: "Timiș County",
        countryCode: "RO",
        latitude: "45.81389020",
        longitude: "21.33310550",
        nameNative: null,
        label: "Timiș County",
        cityCode: "RO_TM",
      },
      {
        name: "Tulcea County",
        countryCode: "RO",
        latitude: "45.04505650",
        longitude: "29.03249120",
        nameNative: null,
        label: "Tulcea County",
        cityCode: "RO_TL",
      },
      {
        name: "Vaslui County",
        countryCode: "RO",
        latitude: "46.46310590",
        longitude: "27.73980310",
        nameNative: null,
        label: "Vaslui County",
        cityCode: "RO_VS",
      },
      {
        name: "Vrancea County",
        countryCode: "RO",
        latitude: "45.81348760",
        longitude: "27.06575310",
        nameNative: null,
        label: "Vrancea County",
        cityCode: "RO_VN",
      },
      {
        name: "Vâlcea County",
        countryCode: "RO",
        latitude: "45.07980510",
        longitude: "24.08352830",
        nameNative: null,
        label: "Vâlcea County",
        cityCode: "RO_VL",
      },
    ],
    RU: [
      {
        name: "Altai Krai",
        countryCode: "RU",
        latitude: "51.79362980",
        longitude: "82.67585960",
        nameNative: "Алтайский край",
        label: "Алтайский край / Altai Krai",
        cityCode: "RU_ALT",
      },
      {
        name: "Altai Republic",
        countryCode: "RU",
        latitude: "50.61819240",
        longitude: "86.21993080",
        nameNative: "Республика Алтай",
        label: "Республика Алтай / Altai Republic",
        cityCode: "RU_AL",
      },
      {
        name: "Amur Oblast",
        countryCode: "RU",
        latitude: "54.60350650",
        longitude: "127.48017210",
        nameNative: "Амурская область",
        label: "Амурская область / Amur Oblast",
        cityCode: "RU_AMU",
      },
      {
        name: "Arkhangelsk",
        countryCode: "RU",
        latitude: "64.54585490",
        longitude: "40.55057690",
        nameNative: "Арха́нгельск",
        label: "Арха́нгельск / Arkhangelsk",
        cityCode: "RU_ARK",
      },
      {
        name: "Astrakhan Oblast",
        countryCode: "RU",
        latitude: "46.13211660",
        longitude: "48.06101150",
        nameNative: "Астраха́нская о́бласть",
        label: "Астраха́нская о́бласть / Astrakhan Oblast",
        cityCode: "RU_AST",
      },
      {
        name: "Belgorod Oblast",
        countryCode: "RU",
        latitude: "50.71069260",
        longitude: "37.75333770",
        nameNative: "Белгоро́дская о́бласть",
        label: "Белгоро́дская о́бласть / Belgorod Oblast",
        cityCode: "RU_BEL",
      },
      {
        name: "Bryansk Oblast",
        countryCode: "RU",
        latitude: "53.04085990",
        longitude: "33.26909000",
        nameNative: "Бря́нская о́бласть",
        label: "Бря́нская о́бласть / Bryansk Oblast",
        cityCode: "RU_BRY",
      },
      {
        name: "Chechen Republic",
        countryCode: "RU",
        latitude: "43.40233010",
        longitude: "45.71874680",
        nameNative: "Чече́нская Респу́блика",
        label: "Чече́нская Респу́блика / Chechen Republic",
        cityCode: "RU_CE",
      },
      {
        name: "Chelyabinsk Oblast",
        countryCode: "RU",
        latitude: "54.43194220",
        longitude: "60.87889630",
        nameNative: "Челя́бинская о́бласть",
        label: "Челя́бинская о́бласть / Chelyabinsk Oblast",
        cityCode: "RU_CHE",
      },
      {
        name: "Chukotka Autonomous Okrug",
        countryCode: "RU",
        latitude: "65.62983550",
        longitude: "171.69521590",
        nameNative: "Чуко́тский автоно́мный о́круг",
        label: "Чуко́тский автоно́мный о́круг / Chukotka Autonomous Okrug",
        cityCode: "RU_CHU",
      },
      {
        name: "Chuvash Republic",
        countryCode: "RU",
        latitude: "55.55959920",
        longitude: "46.92835350",
        nameNative: "Чува́шская Респу́блика",
        label: "Чува́шская Респу́блика / Chuvash Republic",
        cityCode: "RU_CU",
      },
      {
        name: "Irkutsk",
        countryCode: "RU",
        latitude: "52.28548340",
        longitude: "104.28902220",
        nameNative: "Ирку́тск",
        label: "Ирку́тск / Irkutsk",
        cityCode: "RU_IRK",
      },
      {
        name: "Ivanovo Oblast",
        countryCode: "RU",
        latitude: "57.10568540",
        longitude: "41.48300840",
        nameNative: "Ива́новская о́бласть",
        label: "Ива́новская о́бласть / Ivanovo Oblast",
        cityCode: "RU_IVA",
      },
      {
        name: "Jewish Autonomous Oblast",
        countryCode: "RU",
        latitude: "48.48081470",
        longitude: "131.76573670",
        nameNative: "Евре́йская автоно́мная о́бласть",
        label: "Евре́йская автоно́мная о́бласть / Jewish Autonomous Oblast",
        cityCode: "RU_YEV",
      },
      {
        name: "Kabardino-Balkar Republic",
        countryCode: "RU",
        latitude: "43.39324690",
        longitude: "43.56284980",
        nameNative: "Кабарди́но-Балка́рская Респу́блика",
        label: "Кабарди́но-Балка́рская Респу́блика / Kabardino-Balkar Republic",
        cityCode: "RU_KB",
      },
      {
        name: "Kaliningrad",
        countryCode: "RU",
        latitude: "54.71042640",
        longitude: "20.45221440",
        nameNative: "Калинингра́д",
        label: "Калинингра́д / Kaliningrad",
        cityCode: "RU_KGD",
      },
      {
        name: "Kaluga Oblast",
        countryCode: "RU",
        latitude: "54.38726660",
        longitude: "35.18890940",
        nameNative: "Калу́жская о́бласть",
        label: "Калу́жская о́бласть / Kaluga Oblast",
        cityCode: "RU_KLU",
      },
      {
        name: "Kamchatka Krai",
        countryCode: "RU",
        latitude: "61.43439810",
        longitude: "166.78841310",
        nameNative: "Камча́тский край",
        label: "Камча́тский край / Kamchatka Krai",
        cityCode: "RU_KAM",
      },
      {
        name: "Karachay-Cherkess Republic",
        countryCode: "RU",
        latitude: "43.88451430",
        longitude: "41.73039390",
        nameNative: "Карача́ево-Черке́сская Респу́блика",
        label: "Карача́ево-Черке́сская Респу́блика / Karachay-Cherkess Republic",
        cityCode: "RU_KC",
      },
      {
        name: "Kemerovo Oblast",
        countryCode: "RU",
        latitude: "54.75746480",
        longitude: "87.40552880",
        nameNative: "Ке́меровская о́бласть",
        label: "Ке́меровская о́бласть / Kemerovo Oblast",
        cityCode: "RU_KEM",
      },
      {
        name: "Khabarovsk Krai",
        countryCode: "RU",
        latitude: "50.58884310",
        longitude: "135.00000000",
        nameNative: "Хабаро́вский край",
        label: "Хабаро́вский край / Khabarovsk Krai",
        cityCode: "RU_KHA",
      },
      {
        name: "Khanty-Mansi Autonomous Okrug",
        countryCode: "RU",
        latitude: "62.22870620",
        longitude: "70.64100570",
        nameNative: "Ха́нты-Манси́йский автоно́мный о́круг",
        label:
          "Ха́нты-Манси́йский автоно́мный о́круг / Khanty-Mansi Autonomous Okrug",
        cityCode: "RU_KHM",
      },
      {
        name: "Kirov Oblast",
        countryCode: "RU",
        latitude: "58.41985290",
        longitude: "50.20972480",
        nameNative: "Ки́ровская о́бласть",
        label: "Ки́ровская о́бласть / Kirov Oblast",
        cityCode: "RU_KIR",
      },
      {
        name: "Komi Republic",
        countryCode: "RU",
        latitude: "63.86305390",
        longitude: "54.83126900",
        nameNative: "Ко́ми Респу́блика",
        label: "Ко́ми Респу́блика / Komi Republic",
        cityCode: "RU_KO",
      },
      {
        name: "Kostroma Oblast",
        countryCode: "RU",
        latitude: "58.55010690",
        longitude: "43.95411020",
        nameNative: "Костромская о́бласть",
        label: "Костромская о́бласть / Kostroma Oblast",
        cityCode: "RU_KOS",
      },
      {
        name: "Krasnodar Krai",
        countryCode: "RU",
        latitude: "45.64152890",
        longitude: "39.70559770",
        nameNative: "Краснода́рский край",
        label: "Краснода́рский край / Krasnodar Krai",
        cityCode: "RU_KDA",
      },
      {
        name: "Krasnoyarsk Krai",
        countryCode: "RU",
        latitude: "64.24797580",
        longitude: "95.11041760",
        nameNative: "Красноя́рский край",
        label: "Красноя́рский край / Krasnoyarsk Krai",
        cityCode: "RU_KYA",
      },
      {
        name: "Kurgan Oblast",
        countryCode: "RU",
        latitude: "55.44815480",
        longitude: "65.11809750",
        nameNative: "Курга́нская о́бласть",
        label: "Курга́нская о́бласть / Kurgan Oblast",
        cityCode: "RU_KGN",
      },
      {
        name: "Kursk Oblast",
        countryCode: "RU",
        latitude: "51.76340260",
        longitude: "35.38118120",
        nameNative: "Курска́я о́бласть",
        label: "Курска́я о́бласть / Kursk Oblast",
        cityCode: "RU_KRS",
      },
      {
        name: "Leningrad Oblast",
        countryCode: "RU",
        latitude: "60.07932080",
        longitude: "31.89266450",
        nameNative: "Ленингра́дская о́бласть",
        label: "Ленингра́дская о́бласть / Leningrad Oblast",
        cityCode: "RU_LEN",
      },
      {
        name: "Lipetsk Oblast",
        countryCode: "RU",
        latitude: "52.52647020",
        longitude: "39.20322690",
        nameNative: "Липе́цкая о́бласть",
        label: "Липе́цкая о́бласть / Lipetsk Oblast",
        cityCode: "RU_LIP",
      },
      {
        name: "Magadan Oblast",
        countryCode: "RU",
        latitude: "62.66434170",
        longitude: "153.91499100",
        nameNative: "Магада́нская о́бласть",
        label: "Магада́нская о́бласть / Magadan Oblast",
        cityCode: "RU_MAG",
      },
      {
        name: "Mari El Republic",
        countryCode: "RU",
        latitude: "56.43845700",
        longitude: "47.96077580",
        nameNative: "Мари́й Эл Респу́блика",
        label: "Мари́й Эл Респу́блика / Mari El Republic",
        cityCode: "RU_ME",
      },
      {
        name: "Moscow Oblast",
        countryCode: "RU",
        latitude: "55.34039600",
        longitude: "38.29176510",
        nameNative: "Моско́вская о́бласть",
        label: "Моско́вская о́бласть / Moscow Oblast",
        cityCode: "RU_MOS",
      },
      {
        name: "Moscow",
        countryCode: "RU",
        latitude: "55.75582600",
        longitude: "37.61729990",
        nameNative: "Москва",
        label: "Москва / Moscow",
        cityCode: "RU_MOW",
      },
      {
        name: "Murmansk Oblast",
        countryCode: "RU",
        latitude: "67.84426740",
        longitude: "35.08841020",
        nameNative: "Мурма́нская о́бласть",
        label: "Мурма́нская о́бласть / Murmansk Oblast",
        cityCode: "RU_MUR",
      },
      {
        name: "Nenets Autonomous Okrug",
        countryCode: "RU",
        latitude: "67.60783370",
        longitude: "57.63383310",
        nameNative: "Не́нецкий автоно́мный о́круг",
        label: "Не́нецкий автоно́мный о́круг / Nenets Autonomous Okrug",
        cityCode: "RU_NEN",
      },
      {
        name: "Nizhny Novgorod Oblast",
        countryCode: "RU",
        latitude: "55.79951590",
        longitude: "44.02967690",
        nameNative: "Нижегор",
        label: "Нижегор / Nizhny Novgorod Oblast",
        cityCode: "RU_NIZ",
      },
      {
        name: "Novgorod Oblast",
        countryCode: "RU",
        latitude: "58.24275520",
        longitude: "32.56651900",
        nameNative: "Новгоро́дская о́бласть",
        label: "Новгоро́дская о́бласть / Novgorod Oblast",
        cityCode: "RU_NGR",
      },
      {
        name: "Novosibirsk",
        countryCode: "RU",
        latitude: "54.98326930",
        longitude: "82.89638310",
        nameNative: "Новосиби́рск",
        label: "Новосиби́рск / Novosibirsk",
        cityCode: "RU_NVS",
      },
      {
        name: "Omsk Oblast",
        countryCode: "RU",
        latitude: "55.05546690",
        longitude: "73.31673420",
        nameNative: "О́мская о́бласть",
        label: "О́мская о́бласть / Omsk Oblast",
        cityCode: "RU_OMS",
      },
      {
        name: "Orenburg Oblast",
        countryCode: "RU",
        latitude: "51.76340260",
        longitude: "54.61881880",
        nameNative: "Оренбу́ргская о́бласть",
        label: "Оренбу́ргская о́бласть / Orenburg Oblast",
        cityCode: "RU_ORE",
      },
      {
        name: "Oryol Oblast",
        countryCode: "RU",
        latitude: "52.78564140",
        longitude: "36.92423440",
        nameNative: "Орло́вская о́бласть",
        label: "Орло́вская о́бласть / Oryol Oblast",
        cityCode: "RU_ORL",
      },
      {
        name: "Penza Oblast",
        countryCode: "RU",
        latitude: "53.14121050",
        longitude: "44.09400480",
        nameNative: "Пензе́нская о́бласть",
        label: "Пензе́нская о́бласть / Penza Oblast",
        cityCode: "RU_PNZ",
      },
      {
        name: "Perm Krai",
        countryCode: "RU",
        latitude: "58.82319290",
        longitude: "56.58724810",
        nameNative: "Пермский край",
        label: "Пермский край / Perm Krai",
        cityCode: "RU_PER",
      },
      {
        name: "Primorsky Krai",
        countryCode: "RU",
        latitude: "45.05256410",
        longitude: "135.00000000",
        nameNative: "Приморский край",
        label: "Приморский край / Primorsky Krai",
        cityCode: "RU_PRI",
      },
      {
        name: "Pskov Oblast",
        countryCode: "RU",
        latitude: "56.77085990",
        longitude: "29.09400900",
        nameNative: "Пско́вская о́бласть",
        label: "Пско́вская о́бласть / Pskov Oblast",
        cityCode: "RU_PSK",
      },
      {
        name: "Republic of Adygea",
        countryCode: "RU",
        latitude: "44.82291550",
        longitude: "40.17544630",
        nameNative: "Адыге́йская Респу́блика",
        label: "Адыге́йская Респу́блика / Republic of Adygea",
        cityCode: "RU_AD",
      },
      {
        name: "Republic of Bashkortostan",
        countryCode: "RU",
        latitude: "54.23121720",
        longitude: "56.16452570",
        nameNative: "Башкортоста́н Респу́блика",
        label: "Башкортоста́н Респу́блика / Republic of Bashkortostan",
        cityCode: "RU_BA",
      },
      {
        name: "Republic of Buryatia",
        countryCode: "RU",
        latitude: "54.83311460",
        longitude: "112.40605300",
        nameNative: "Буря́тия Респу́блика",
        label: "Буря́тия Респу́блика / Republic of Buryatia",
        cityCode: "RU_BU",
      },
      {
        name: "Republic of Dagestan",
        countryCode: "RU",
        latitude: "42.14318860",
        longitude: "47.09497990",
        nameNative: "Дагеста́н Респу́блика",
        label: "Дагеста́н Респу́блика / Republic of Dagestan",
        cityCode: "RU_DA",
      },
      {
        name: "Republic of Ingushetia",
        countryCode: "RU",
        latitude: "43.40516980",
        longitude: "44.82029990",
        nameNative: "Ингуше́тия Респу́блика",
        label: "Ингуше́тия Респу́блика / Republic of Ingushetia",
        cityCode: "RU_IN",
      },
      {
        name: "Republic of Kalmykia",
        countryCode: "RU",
        latitude: "46.18671760",
        longitude: "45.00000000",
        nameNative: "Калмы́кия Респу́блика",
        label: "Калмы́кия Респу́блика / Republic of Kalmykia",
        cityCode: "RU_KL",
      },
      {
        name: "Republic of Karelia",
        countryCode: "RU",
        latitude: "63.15587020",
        longitude: "32.99055520",
        nameNative: "Каре́лия Респу́блика",
        label: "Каре́лия Респу́блика / Republic of Karelia",
        cityCode: "RU_KR",
      },
      {
        name: "Republic of Khakassia",
        countryCode: "RU",
        latitude: "53.04522810",
        longitude: "90.39821450",
        nameNative: "Хака́сия Респу́блика",
        label: "Хака́сия Респу́блика / Republic of Khakassia",
        cityCode: "RU_KK",
      },
      {
        name: "Republic of Mordovia",
        countryCode: "RU",
        latitude: "54.23694410",
        longitude: "44.06839700",
        nameNative: "Мордо́вия Респу́блика",
        label: "Мордо́вия Респу́блика / Republic of Mordovia",
        cityCode: "RU_MO",
      },
      {
        name: "Republic of North Ossetia-Alania",
        countryCode: "RU",
        latitude: "43.04513020",
        longitude: "44.28709720",
        nameNative: "Се́верная Осе́тия-Ала́ния Респу́блика",
        label:
          "Се́верная Осе́тия-Ала́ния Респу́блика / Republic of North Ossetia-Alania",
        cityCode: "RU_SE",
      },
      {
        name: "Republic of Tatarstan",
        countryCode: "RU",
        latitude: "55.18023640",
        longitude: "50.72639450",
        nameNative: "Татарста́н Респу́блика",
        label: "Татарста́н Респу́блика / Republic of Tatarstan",
        cityCode: "RU_TA",
      },
      {
        name: "Rostov Oblast",
        countryCode: "RU",
        latitude: "47.68532470",
        longitude: "41.82589520",
        nameNative: "Росто́вская о́бласть",
        label: "Росто́вская о́бласть / Rostov Oblast",
        cityCode: "RU_ROS",
      },
      {
        name: "Ryazan Oblast",
        countryCode: "RU",
        latitude: "54.38759640",
        longitude: "41.25956610",
        nameNative: "Ряза́нская о́бласть",
        label: "Ряза́нская о́бласть / Ryazan Oblast",
        cityCode: "RU_RYA",
      },
      {
        name: "Saint Petersburg",
        countryCode: "RU",
        latitude: "59.93105840",
        longitude: "30.36090960",
        nameNative: "Санкт-Петербу́рг",
        label: "Санкт-Петербу́рг / Saint Petersburg",
        cityCode: "RU_SPE",
      },
      {
        name: "Sakha Republic",
        countryCode: "RU",
        latitude: "66.76134510",
        longitude: "124.12375300",
        nameNative: "Саха (Якутия) Респу́блика",
        label: "Саха (Якутия) Респу́блика / Sakha Republic",
        cityCode: "RU_SA",
      },
      {
        name: "Sakhalin",
        countryCode: "RU",
        latitude: "50.69098480",
        longitude: "142.95056890",
        nameNative: "Сахали́н",
        label: "Сахали́н / Sakhalin",
        cityCode: "RU_SAK",
      },
      {
        name: "Samara Oblast",
        countryCode: "RU",
        latitude: "53.41838390",
        longitude: "50.47255280",
        nameNative: "Сама́рская о́бласть",
        label: "Сама́рская о́бласть / Samara Oblast",
        cityCode: "RU_SAM",
      },
      {
        name: "Saratov Oblast",
        countryCode: "RU",
        latitude: "51.83692630",
        longitude: "46.75393970",
        nameNative: "Сара́товская о́бласть",
        label: "Сара́товская о́бласть / Saratov Oblast",
        cityCode: "RU_SAR",
      },
      {
        name: "Sevastopol",
        countryCode: "RU",
        latitude: "44.61665000",
        longitude: "33.52536710",
        nameNative: "Севасто́поль",
        label: "Севасто́поль / Sevastopol",
        cityCode: "RU_UA-40",
      },
      {
        name: "Smolensk Oblast",
        countryCode: "RU",
        latitude: "54.98829940",
        longitude: "32.66773780",
        nameNative: "Смоле́нская о́бласть",
        label: "Смоле́нская о́бласть / Smolensk Oblast",
        cityCode: "RU_SMO",
      },
      {
        name: "Stavropol Krai",
        countryCode: "RU",
        latitude: "44.66809930",
        longitude: "43.52021400",
        nameNative: "Ставропо́льский край",
        label: "Ставропо́льский край / Stavropol Krai",
        cityCode: "RU_STA",
      },
      {
        name: "Sverdlovsk",
        countryCode: "RU",
        latitude: "56.84309930",
        longitude: "60.64540860",
        nameNative: "Свердло́вск",
        label: "Свердло́вск / Sverdlovsk",
        cityCode: "RU_SVE",
      },
      {
        name: "Tambov Oblast",
        countryCode: "RU",
        latitude: "52.64165890",
        longitude: "41.42164510",
        nameNative: "Тамбо́вская о́бласть",
        label: "Тамбо́вская о́бласть / Tambov Oblast",
        cityCode: "RU_TAM",
      },
      {
        name: "Tomsk Oblast",
        countryCode: "RU",
        latitude: "58.89698820",
        longitude: "82.67655000",
        nameNative: "То́мская о́бласть",
        label: "То́мская о́бласть / Tomsk Oblast",
        cityCode: "RU_TOM",
      },
      {
        name: "Tula Oblast",
        countryCode: "RU",
        latitude: "54.16376800",
        longitude: "37.56495070",
        nameNative: "Ту́льская о́бласть",
        label: "Ту́льская о́бласть / Tula Oblast",
        cityCode: "RU_TUL",
      },
      {
        name: "Tuva Republic",
        countryCode: "RU",
        latitude: "51.88726690",
        longitude: "95.62601720",
        nameNative: "Тува́ Респу́блика",
        label: "Тува́ Респу́блика / Tuva Republic",
        cityCode: "RU_TY",
      },
      {
        name: "Tver Oblast",
        countryCode: "RU",
        latitude: "57.00216540",
        longitude: "33.98531420",
        nameNative: "Тверская о́бласть",
        label: "Тверская о́бласть / Tver Oblast",
        cityCode: "RU_TVE",
      },
      {
        name: "Tyumen Oblast",
        countryCode: "RU",
        latitude: "56.96343870",
        longitude: "66.94827800",
        nameNative: "Тюме́нская о́бласть",
        label: "Тюме́нская о́бласть / Tyumen Oblast",
        cityCode: "RU_TYU",
      },
      {
        name: "Udmurt Republic",
        countryCode: "RU",
        latitude: "57.06702180",
        longitude: "53.02779480",
        nameNative: "Удму́ртская Респу́блика",
        label: "Удму́ртская Респу́блика / Udmurt Republic",
        cityCode: "RU_UD",
      },
      {
        name: "Ulyanovsk Oblast",
        countryCode: "RU",
        latitude: "53.97933570",
        longitude: "47.77624250",
        nameNative: "Улья́новская о́бласть",
        label: "Улья́новская о́бласть / Ulyanovsk Oblast",
        cityCode: "RU_ULY",
      },
      {
        name: "Vladimir Oblast",
        countryCode: "RU",
        latitude: "56.15534650",
        longitude: "40.59266850",
        nameNative: "Влади́мирская о́бласть",
        label: "Влади́мирская о́бласть / Vladimir Oblast",
        cityCode: "RU_VLA",
      },
      {
        name: "Volgograd Oblast",
        countryCode: "RU",
        latitude: "49.25873930",
        longitude: "39.81544630",
        nameNative: "Волгогра́дская о́бласть",
        label: "Волгогра́дская о́бласть / Volgograd Oblast",
        cityCode: "RU_VGG",
      },
      {
        name: "Vologda Oblast",
        countryCode: "RU",
        latitude: "59.87067110",
        longitude: "40.65554110",
        nameNative: "Волого́дская о́бласть",
        label: "Волого́дская о́бласть / Vologda Oblast",
        cityCode: "RU_VLG",
      },
      {
        name: "Voronezh Oblast",
        countryCode: "RU",
        latitude: "50.85897130",
        longitude: "39.86443740",
        nameNative: "Воро́нежская о́бласть",
        label: "Воро́нежская о́бласть / Voronezh Oblast",
        cityCode: "RU_VOR",
      },
      {
        name: "Yamalo-Nenets Autonomous Okrug",
        countryCode: "RU",
        latitude: "66.06530570",
        longitude: "76.93451930",
        nameNative: "Яма́ло-Не́нецкий автоно́мный о́круг",
        label:
          "Яма́ло-Не́нецкий автоно́мный о́круг / Yamalo-Nenets Autonomous Okrug",
        cityCode: "RU_YAN",
      },
      {
        name: "Yaroslavl Oblast",
        countryCode: "RU",
        latitude: "57.89915230",
        longitude: "38.83886330",
        nameNative: "Яросла́вская о́бласть",
        label: "Яросла́вская о́бласть / Yaroslavl Oblast",
        cityCode: "RU_YAR",
      },
      {
        name: "Zabaykalsky Krai",
        countryCode: "RU",
        latitude: "53.09287710",
        longitude: "116.96765610",
        nameNative: "Забайка́льский край",
        label: "Забайка́льский край / Zabaykalsky Krai",
        cityCode: "RU_ZAB",
      },
    ],
    RW: [
      {
        name: "Eastern Province",
        countryCode: "RW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Eastern Province",
        cityCode: "RW_02",
      },
      {
        name: "Kigali district",
        countryCode: "RW",
        latitude: "-1.94407270",
        longitude: "30.06188510",
        nameNative: null,
        label: "Kigali district",
        cityCode: "RW_01",
      },
      {
        name: "Northern Province",
        countryCode: "RW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Northern Province",
        cityCode: "RW_03",
      },
      {
        name: "Southern Province",
        countryCode: "RW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Southern Province",
        cityCode: "RW_05",
      },
      {
        name: "Western Province",
        countryCode: "RW",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Western Province",
        cityCode: "RW_04",
      },
    ],
    KN: [
      {
        name: "Christ Church Nichola Town Parish",
        countryCode: "KN",
        latitude: "17.36048120",
        longitude: "-62.76178370",
        nameNative: null,
        label: "Christ Church Nichola Town Parish",
        cityCode: "KN_01",
      },
      {
        name: "Nevis",
        countryCode: "KN",
        latitude: "17.15535580",
        longitude: "-62.57960260",
        nameNative: null,
        label: "Nevis",
        cityCode: "KN_N",
      },
      {
        name: "Saint Anne Sandy Point Parish",
        countryCode: "KN",
        latitude: "17.37253330",
        longitude: "-62.84411330",
        nameNative: null,
        label: "Saint Anne Sandy Point Parish",
        cityCode: "KN_02",
      },
      {
        name: "Saint George Gingerland Parish",
        countryCode: "KN",
        latitude: "17.12577590",
        longitude: "-62.56198110",
        nameNative: null,
        label: "Saint George Gingerland Parish",
        cityCode: "KN_04",
      },
      {
        name: "Saint James Windward Parish",
        countryCode: "KN",
        latitude: "17.17696330",
        longitude: "-62.57960260",
        nameNative: null,
        label: "Saint James Windward Parish",
        cityCode: "KN_05",
      },
      {
        name: "Saint John Capisterre Parish",
        countryCode: "KN",
        latitude: "17.38103410",
        longitude: "-62.79118330",
        nameNative: null,
        label: "Saint John Capisterre Parish",
        cityCode: "KN_06",
      },
      {
        name: "Saint John Figtree Parish",
        countryCode: "KN",
        latitude: "17.11557480",
        longitude: "-62.60310040",
        nameNative: null,
        label: "Saint John Figtree Parish",
        cityCode: "KN_07",
      },
      {
        name: "Saint Kitts",
        countryCode: "KN",
        latitude: "17.34337960",
        longitude: "-62.75590430",
        nameNative: null,
        label: "Saint Kitts",
        cityCode: "KN_K",
      },
      {
        name: "Saint Mary Cayon Parish",
        countryCode: "KN",
        latitude: "17.34620710",
        longitude: "-62.73826710",
        nameNative: null,
        label: "Saint Mary Cayon Parish",
        cityCode: "KN_08",
      },
      {
        name: "Saint Paul Capisterre Parish",
        countryCode: "KN",
        latitude: "17.40166830",
        longitude: "-62.82573320",
        nameNative: null,
        label: "Saint Paul Capisterre Parish",
        cityCode: "KN_09",
      },
      {
        name: "Saint Paul Charlestown Parish",
        countryCode: "KN",
        latitude: "17.13462970",
        longitude: "-62.61338160",
        nameNative: null,
        label: "Saint Paul Charlestown Parish",
        cityCode: "KN_10",
      },
      {
        name: "Saint Peter Basseterre Parish",
        countryCode: "KN",
        latitude: "17.31029110",
        longitude: "-62.71475330",
        nameNative: null,
        label: "Saint Peter Basseterre Parish",
        cityCode: "KN_11",
      },
      {
        name: "Saint Thomas Lowland Parish",
        countryCode: "KN",
        latitude: "17.16505130",
        longitude: "-62.60897530",
        nameNative: null,
        label: "Saint Thomas Lowland Parish",
        cityCode: "KN_12",
      },
      {
        name: "Saint Thomas Middle Island Parish",
        countryCode: "KN",
        latitude: "17.33488130",
        longitude: "-62.80882510",
        nameNative: null,
        label: "Saint Thomas Middle Island Parish",
        cityCode: "KN_13",
      },
      {
        name: "Trinity Palmetto Point Parish",
        countryCode: "KN",
        latitude: "17.30635190",
        longitude: "-62.76178370",
        nameNative: null,
        label: "Trinity Palmetto Point Parish",
        cityCode: "KN_15",
      },
    ],
    LC: [
      {
        name: "Anse la Raye Quarter",
        countryCode: "LC",
        latitude: "13.94594240",
        longitude: "-61.03694680",
        nameNative: null,
        label: "Anse la Raye Quarter",
        cityCode: "LC_01",
      },
      {
        name: "Canaries",
        countryCode: "LC",
        latitude: "28.29156370",
        longitude: "-16.62913040",
        nameNative: null,
        label: "Canaries",
        cityCode: "LC_12",
      },
      {
        name: "Castries Quarter",
        countryCode: "LC",
        latitude: "14.01010940",
        longitude: "-60.98746870",
        nameNative: null,
        label: "Castries Quarter",
        cityCode: "LC_02",
      },
      {
        name: "Choiseul Quarter",
        countryCode: "LC",
        latitude: "13.77501540",
        longitude: "-61.04859100",
        nameNative: null,
        label: "Choiseul Quarter",
        cityCode: "LC_03",
      },
      {
        name: "Dauphin Quarter",
        countryCode: "LC",
        latitude: "14.01033960",
        longitude: "-60.91909880",
        nameNative: null,
        label: "Dauphin Quarter",
        cityCode: "LC_04",
      },
      {
        name: "Dennery Quarter",
        countryCode: "LC",
        latitude: "13.92673930",
        longitude: "-60.91909880",
        nameNative: null,
        label: "Dennery Quarter",
        cityCode: "LC_05",
      },
      {
        name: "Gros Islet Quarter",
        countryCode: "LC",
        latitude: "14.08435780",
        longitude: "-60.94527940",
        nameNative: null,
        label: "Gros Islet Quarter",
        cityCode: "LC_06",
      },
      {
        name: "Laborie Quarter",
        countryCode: "LC",
        latitude: "13.75227830",
        longitude: "-60.99328890",
        nameNative: null,
        label: "Laborie Quarter",
        cityCode: "LC_07",
      },
      {
        name: "Micoud Quarter",
        countryCode: "LC",
        latitude: "13.82118710",
        longitude: "-60.90019340",
        nameNative: null,
        label: "Micoud Quarter",
        cityCode: "LC_08",
      },
      {
        name: "Praslin Quarter",
        countryCode: "LC",
        latitude: "13.87523920",
        longitude: "-60.89946630",
        nameNative: null,
        label: "Praslin Quarter",
        cityCode: "LC_09",
      },
      {
        name: "Soufrière Quarter",
        countryCode: "LC",
        latitude: "13.85709860",
        longitude: "-61.05732480",
        nameNative: null,
        label: "Soufrière Quarter",
        cityCode: "LC_10",
      },
      {
        name: "Vieux Fort Quarter",
        countryCode: "LC",
        latitude: "13.72060800",
        longitude: "-60.94964330",
        nameNative: null,
        label: "Vieux Fort Quarter",
        cityCode: "LC_11",
      },
    ],
    VC: [
      {
        name: "Charlotte Parish",
        countryCode: "VC",
        latitude: "13.21754510",
        longitude: "-61.16362440",
        nameNative: null,
        label: "Charlotte Parish",
        cityCode: "VC_01",
      },
      {
        name: "Grenadines Parish",
        countryCode: "VC",
        latitude: "13.01229650",
        longitude: "-61.22773010",
        nameNative: null,
        label: "Grenadines Parish",
        cityCode: "VC_06",
      },
      {
        name: "Saint Andrew Parish",
        countryCode: "VC",
        latitude: "43.02429990",
        longitude: "-81.20250000",
        nameNative: null,
        label: "Saint Andrew Parish",
        cityCode: "VC_02",
      },
      {
        name: "Saint David Parish",
        countryCode: "VC",
        latitude: "43.85230950",
        longitude: "-79.52366540",
        nameNative: null,
        label: "Saint David Parish",
        cityCode: "VC_03",
      },
      {
        name: "Saint George Parish",
        countryCode: "VC",
        latitude: "42.95760900",
        longitude: "-81.32670500",
        nameNative: null,
        label: "Saint George Parish",
        cityCode: "VC_04",
      },
      {
        name: "Saint Patrick Parish",
        countryCode: "VC",
        latitude: "39.75091860",
        longitude: "-94.84505560",
        nameNative: null,
        label: "Saint Patrick Parish",
        cityCode: "VC_05",
      },
    ],
    WS: [
      {
        name: "A'ana",
        countryCode: "WS",
        latitude: "-13.89841800",
        longitude: "-171.97529950",
        nameNative: null,
        label: "A'ana",
        cityCode: "WS_AA",
      },
      {
        name: "Aiga-i-le-Tai",
        countryCode: "WS",
        latitude: "-13.85137910",
        longitude: "-172.03254010",
        nameNative: null,
        label: "Aiga-i-le-Tai",
        cityCode: "WS_AL",
      },
      {
        name: "Atua",
        countryCode: "WS",
        latitude: "-13.97870530",
        longitude: "-171.62542830",
        nameNative: null,
        label: "Atua",
        cityCode: "WS_AT",
      },
      {
        name: "Fa'asaleleaga",
        countryCode: "WS",
        latitude: "-13.63076380",
        longitude: "-172.23659810",
        nameNative: null,
        label: "Fa'asaleleaga",
        cityCode: "WS_FA",
      },
      {
        name: "Gaga'emauga",
        countryCode: "WS",
        latitude: "-13.54286660",
        longitude: "-172.36688700",
        nameNative: null,
        label: "Gaga'emauga",
        cityCode: "WS_GE",
      },
      {
        name: "Gaga'ifomauga",
        countryCode: "WS",
        latitude: "-13.54680070",
        longitude: "-172.49693310",
        nameNative: null,
        label: "Gaga'ifomauga",
        cityCode: "WS_GI",
      },
      {
        name: "Palauli",
        countryCode: "WS",
        latitude: "-13.72945790",
        longitude: "-172.45361150",
        nameNative: null,
        label: "Palauli",
        cityCode: "WS_PA",
      },
      {
        name: "Satupa'itea",
        countryCode: "WS",
        latitude: "-13.65382140",
        longitude: "-172.61592710",
        nameNative: null,
        label: "Satupa'itea",
        cityCode: "WS_SA",
      },
      {
        name: "Tuamasaga",
        countryCode: "WS",
        latitude: "-13.91635920",
        longitude: "-171.82243620",
        nameNative: null,
        label: "Tuamasaga",
        cityCode: "WS_TU",
      },
      {
        name: "Va'a-o-Fonoti",
        countryCode: "WS",
        latitude: "-13.94709030",
        longitude: "-171.54318720",
        nameNative: null,
        label: "Va'a-o-Fonoti",
        cityCode: "WS_VF",
      },
      {
        name: "Vaisigano",
        countryCode: "WS",
        latitude: "-13.54138270",
        longitude: "-172.70233830",
        nameNative: null,
        label: "Vaisigano",
        cityCode: "WS_VS",
      },
    ],
    SM: [
      {
        name: "Acquaviva",
        countryCode: "SM",
        latitude: "41.86715970",
        longitude: "14.74694790",
        nameNative: null,
        label: "Acquaviva",
        cityCode: "SM_01",
      },
      {
        name: "Borgo Maggiore",
        countryCode: "SM",
        latitude: "43.95748820",
        longitude: "12.45525460",
        nameNative: null,
        label: "Borgo Maggiore",
        cityCode: "SM_06",
      },
      {
        name: "Chiesanuova",
        countryCode: "SM",
        latitude: "45.42261720",
        longitude: "7.65038540",
        nameNative: null,
        label: "Chiesanuova",
        cityCode: "SM_02",
      },
      {
        name: "Domagnano",
        countryCode: "SM",
        latitude: "43.95019290",
        longitude: "12.46815370",
        nameNative: null,
        label: "Domagnano",
        cityCode: "SM_03",
      },
      {
        name: "Faetano",
        countryCode: "SM",
        latitude: "43.93489670",
        longitude: "12.48965540",
        nameNative: null,
        label: "Faetano",
        cityCode: "SM_04",
      },
      {
        name: "Fiorentino",
        countryCode: "SM",
        latitude: "43.90783370",
        longitude: "12.45812090",
        nameNative: null,
        label: "Fiorentino",
        cityCode: "SM_05",
      },
      {
        name: "Montegiardino",
        countryCode: "SM",
        latitude: "43.90529990",
        longitude: "12.48105420",
        nameNative: null,
        label: "Montegiardino",
        cityCode: "SM_08",
      },
      {
        name: "San Marino",
        countryCode: "SM",
        latitude: "43.94236000",
        longitude: "12.45777700",
        nameNative: null,
        label: "San Marino",
        cityCode: "SM_07",
      },
      {
        name: "Serravalle",
        countryCode: "SM",
        latitude: "44.72320840",
        longitude: "8.85740050",
        nameNative: null,
        label: "Serravalle",
        cityCode: "SM_09",
      },
    ],
    ST: [
      {
        name: "Príncipe Province",
        countryCode: "ST",
        latitude: "1.61393810",
        longitude: "7.40569280",
        nameNative: null,
        label: "Príncipe Province",
        cityCode: "ST_P",
      },
      {
        name: "São Tomé Province",
        countryCode: "ST",
        latitude: "0.33019240",
        longitude: "6.73334300",
        nameNative: null,
        label: "São Tomé Province",
        cityCode: "ST_S",
      },
    ],
    SA: [
      {
        name: "'Asir",
        countryCode: "SA",
        latitude: "19.09690620",
        longitude: "42.86378750",
        nameNative: "عسير",
        label: "عسير / 'Asir",
        cityCode: "SA_14",
      },
      {
        name: "Al Bahah",
        countryCode: "SA",
        latitude: "20.27227390",
        longitude: "41.44125100",
        nameNative: "الباحة",
        label: "الباحة / Al Bahah",
        cityCode: "SA_11",
      },
      {
        name: "Al Jawf",
        countryCode: "SA",
        latitude: "29.88735600",
        longitude: "39.32062410",
        nameNative: "الجوف",
        label: "الجوف / Al Jawf",
        cityCode: "SA_12",
      },
      {
        name: "Al Madinah",
        countryCode: "SA",
        latitude: "24.84039770",
        longitude: "39.32062410",
        nameNative: "المدينة المنورة",
        label: "المدينة المنورة / Al Madinah",
        cityCode: "SA_03",
      },
      {
        name: "Al-Qassim",
        countryCode: "SA",
        latitude: "26.20782600",
        longitude: "43.48373800",
        nameNative: "القصيم",
        label: "القصيم / Al-Qassim",
        cityCode: "SA_05",
      },
      {
        name: "Eastern Province",
        countryCode: "SA",
        latitude: "24.04399320",
        longitude: "45.65892250",
        nameNative: "المنطقة الشرقية",
        label: "المنطقة الشرقية / Eastern Province",
        cityCode: "SA_04",
      },
      {
        name: "Ha'il",
        countryCode: "SA",
        latitude: "27.70761430",
        longitude: "41.91964710",
        nameNative: "حائل",
        label: "حائل / Ha'il",
        cityCode: "SA_06",
      },
      {
        name: "Jizan",
        countryCode: "SA",
        latitude: "17.17381760",
        longitude: "42.70761070",
        nameNative: "جازان",
        label: "جازان / Jizan",
        cityCode: "SA_09",
      },
      {
        name: "Makkah",
        countryCode: "SA",
        latitude: "21.52355840",
        longitude: "41.91964710",
        nameNative: "مكة المكرمة",
        label: "مكة المكرمة / Makkah",
        cityCode: "SA_02",
      },
      {
        name: "Najran",
        countryCode: "SA",
        latitude: "18.35146640",
        longitude: "45.60071080",
        nameNative: "نجران",
        label: "نجران / Najran",
        cityCode: "SA_10",
      },
      {
        name: "Northern Borders",
        countryCode: "SA",
        latitude: "30.07991620",
        longitude: "42.86378750",
        nameNative: "الحدود الشمالية",
        label: "الحدود الشمالية / Northern Borders",
        cityCode: "SA_08",
      },
      {
        name: "Riyadh",
        countryCode: "SA",
        latitude: "22.75543850",
        longitude: "46.20915470",
        nameNative: "الرياض",
        label: "الرياض / Riyadh",
        cityCode: "SA_01",
      },
      {
        name: "Tabuk",
        countryCode: "SA",
        latitude: "28.24533350",
        longitude: "37.63866220",
        nameNative: "تبوك",
        label: "تبوك / Tabuk",
        cityCode: "SA_07",
      },
    ],
    SN: [
      {
        name: "Dakar",
        countryCode: "SN",
        latitude: "14.71667700",
        longitude: "-17.46768610",
        nameNative: null,
        label: "Dakar",
        cityCode: "SN_DK",
      },
      {
        name: "Diourbel Region",
        countryCode: "SN",
        latitude: "14.72830850",
        longitude: "-16.25221430",
        nameNative: null,
        label: "Diourbel Region",
        cityCode: "SN_DB",
      },
      {
        name: "Fatick",
        countryCode: "SN",
        latitude: "14.33901670",
        longitude: "-16.41114250",
        nameNative: null,
        label: "Fatick",
        cityCode: "SN_FK",
      },
      {
        name: "Kaffrine",
        countryCode: "SN",
        latitude: "14.10520200",
        longitude: "-15.54157550",
        nameNative: null,
        label: "Kaffrine",
        cityCode: "SN_KA",
      },
      {
        name: "Kaolack",
        countryCode: "SN",
        latitude: "14.16520830",
        longitude: "-16.07577490",
        nameNative: null,
        label: "Kaolack",
        cityCode: "SN_KL",
      },
      {
        name: "Kolda",
        countryCode: "SN",
        latitude: "12.91074950",
        longitude: "-14.95056710",
        nameNative: null,
        label: "Kolda",
        cityCode: "SN_KD",
      },
      {
        name: "Kédougou",
        countryCode: "SN",
        latitude: "12.56046070",
        longitude: "-12.17470770",
        nameNative: null,
        label: "Kédougou",
        cityCode: "SN_KE",
      },
      {
        name: "Louga",
        countryCode: "SN",
        latitude: "15.61417680",
        longitude: "-16.22868000",
        nameNative: null,
        label: "Louga",
        cityCode: "SN_LG",
      },
      {
        name: "Matam",
        countryCode: "SN",
        latitude: "15.66002250",
        longitude: "-13.25769060",
        nameNative: null,
        label: "Matam",
        cityCode: "SN_MT",
      },
      {
        name: "Saint-Louis",
        countryCode: "SN",
        latitude: "38.62700250",
        longitude: "-90.19940420",
        nameNative: null,
        label: "Saint-Louis",
        cityCode: "SN_SL",
      },
      {
        name: "Sédhiou",
        countryCode: "SN",
        latitude: "12.70460400",
        longitude: "-15.55623040",
        nameNative: null,
        label: "Sédhiou",
        cityCode: "SN_SE",
      },
      {
        name: "Tambacounda Region",
        countryCode: "SN",
        latitude: "13.56190110",
        longitude: "-13.17403480",
        nameNative: null,
        label: "Tambacounda Region",
        cityCode: "SN_TC",
      },
      {
        name: "Thiès Region",
        countryCode: "SN",
        latitude: "14.79100520",
        longitude: "-16.93586040",
        nameNative: null,
        label: "Thiès Region",
        cityCode: "SN_TH",
      },
      {
        name: "Ziguinchor",
        countryCode: "SN",
        latitude: "12.56414790",
        longitude: "-16.26398250",
        nameNative: null,
        label: "Ziguinchor",
        cityCode: "SN_ZG",
      },
    ],
    RS: [
      {
        name: "Belgrade",
        countryCode: "RS",
        latitude: "44.78656800",
        longitude: "20.44892160",
        nameNative: null,
        label: "Belgrade",
        cityCode: "RS_00",
      },
      {
        name: "Bor District",
        countryCode: "RS",
        latitude: "44.06989180",
        longitude: "22.09850860",
        nameNative: null,
        label: "Bor District",
        cityCode: "RS_14",
      },
      {
        name: "Braničevo District",
        countryCode: "RS",
        latitude: "44.69822460",
        longitude: "21.54467750",
        nameNative: null,
        label: "Braničevo District",
        cityCode: "RS_11",
      },
      {
        name: "Central Banat District",
        countryCode: "RS",
        latitude: "45.47884850",
        longitude: "20.60825220",
        nameNative: null,
        label: "Central Banat District",
        cityCode: "RS_02",
      },
      {
        name: "Jablanica District",
        countryCode: "RS",
        latitude: "42.94815600",
        longitude: "21.81293210",
        nameNative: null,
        label: "Jablanica District",
        cityCode: "RS_23",
      },
      {
        name: "Kolubara District",
        countryCode: "RS",
        latitude: "44.35098110",
        longitude: "20.00043050",
        nameNative: null,
        label: "Kolubara District",
        cityCode: "RS_09",
      },
      {
        name: "Mačva District",
        countryCode: "RS",
        latitude: "44.59253140",
        longitude: "19.50822460",
        nameNative: null,
        label: "Mačva District",
        cityCode: "RS_08",
      },
      {
        name: "Moravica District",
        countryCode: "RS",
        latitude: "43.84147000",
        longitude: "20.29049870",
        nameNative: null,
        label: "Moravica District",
        cityCode: "RS_17",
      },
      {
        name: "Nišava District",
        countryCode: "RS",
        latitude: "43.37389020",
        longitude: "21.93223310",
        nameNative: null,
        label: "Nišava District",
        cityCode: "RS_20",
      },
      {
        name: "North Banat District",
        countryCode: "RS",
        latitude: "45.90683900",
        longitude: "19.99934170",
        nameNative: null,
        label: "North Banat District",
        cityCode: "RS_03",
      },
      {
        name: "North Bačka District",
        countryCode: "RS",
        latitude: "45.98033940",
        longitude: "19.59070010",
        nameNative: null,
        label: "North Bačka District",
        cityCode: "RS_01",
      },
      {
        name: "Pirot District",
        countryCode: "RS",
        latitude: "43.08740360",
        longitude: "22.59830440",
        nameNative: null,
        label: "Pirot District",
        cityCode: "RS_22",
      },
      {
        name: "Podunavlje District",
        countryCode: "RS",
        latitude: "44.47291560",
        longitude: "20.99014260",
        nameNative: null,
        label: "Podunavlje District",
        cityCode: "RS_10",
      },
      {
        name: "Pomoravlje District",
        countryCode: "RS",
        latitude: "43.95913790",
        longitude: "21.27135300",
        nameNative: null,
        label: "Pomoravlje District",
        cityCode: "RS_13",
      },
      {
        name: "Pčinja District",
        countryCode: "RS",
        latitude: "42.58363620",
        longitude: "22.14302150",
        nameNative: null,
        label: "Pčinja District",
        cityCode: "RS_24",
      },
      {
        name: "Rasina District",
        countryCode: "RS",
        latitude: "43.52635250",
        longitude: "21.15881780",
        nameNative: null,
        label: "Rasina District",
        cityCode: "RS_19",
      },
      {
        name: "Raška District",
        countryCode: "RS",
        latitude: "43.33734610",
        longitude: "20.57340050",
        nameNative: null,
        label: "Raška District",
        cityCode: "RS_18",
      },
      {
        name: "South Banat District",
        countryCode: "RS",
        latitude: "45.00274570",
        longitude: "21.05425090",
        nameNative: null,
        label: "South Banat District",
        cityCode: "RS_04",
      },
      {
        name: "South Bačka District",
        countryCode: "RS",
        latitude: "45.48903440",
        longitude: "19.69761870",
        nameNative: null,
        label: "South Bačka District",
        cityCode: "RS_06",
      },
      {
        name: "Srem District",
        countryCode: "RS",
        latitude: "45.00291710",
        longitude: "19.80137730",
        nameNative: null,
        label: "Srem District",
        cityCode: "RS_07",
      },
      {
        name: "Toplica District",
        countryCode: "RS",
        latitude: "43.19065920",
        longitude: "21.34077620",
        nameNative: null,
        label: "Toplica District",
        cityCode: "RS_21",
      },
      {
        name: "Vojvodina",
        countryCode: "RS",
        latitude: "45.26086510",
        longitude: "19.83193380",
        nameNative: null,
        label: "Vojvodina",
        cityCode: "RS_VO",
      },
      {
        name: "West Bačka District",
        countryCode: "RS",
        latitude: "45.73553850",
        longitude: "19.18973640",
        nameNative: null,
        label: "West Bačka District",
        cityCode: "RS_05",
      },
      {
        name: "Zaječar District",
        countryCode: "RS",
        latitude: "43.90150480",
        longitude: "22.27380110",
        nameNative: null,
        label: "Zaječar District",
        cityCode: "RS_15",
      },
      {
        name: "Zlatibor District",
        countryCode: "RS",
        latitude: "43.64541700",
        longitude: "19.71014550",
        nameNative: null,
        label: "Zlatibor District",
        cityCode: "RS_16",
      },
      {
        name: "Šumadija District",
        countryCode: "RS",
        latitude: "44.20506780",
        longitude: "20.78565650",
        nameNative: null,
        label: "Šumadija District",
        cityCode: "RS_12",
      },
    ],
    SC: [
      {
        name: "Anse Boileau",
        countryCode: "SC",
        latitude: "-4.70472680",
        longitude: "55.48593630",
        nameNative: null,
        label: "Anse Boileau",
        cityCode: "SC_02",
      },
      {
        name: "Anse Royale",
        countryCode: "SC",
        latitude: "-4.74079880",
        longitude: "55.50810120",
        nameNative: null,
        label: "Anse Royale",
        cityCode: "SC_05",
      },
      {
        name: "Anse-aux-Pins",
        countryCode: "SC",
        latitude: "-4.69004430",
        longitude: "55.51502890",
        nameNative: null,
        label: "Anse-aux-Pins",
        cityCode: "SC_01",
      },
      {
        name: "Au Cap",
        countryCode: "SC",
        latitude: "-4.70597230",
        longitude: "55.50810120",
        nameNative: null,
        label: "Au Cap",
        cityCode: "SC_04",
      },
      {
        name: "Baie Lazare",
        countryCode: "SC",
        latitude: "-4.74825250",
        longitude: "55.48593630",
        nameNative: null,
        label: "Baie Lazare",
        cityCode: "SC_06",
      },
      {
        name: "Baie Sainte Anne",
        countryCode: "SC",
        latitude: "47.05259000",
        longitude: "-64.95245790",
        nameNative: null,
        label: "Baie Sainte Anne",
        cityCode: "SC_07",
      },
      {
        name: "Beau Vallon",
        countryCode: "SC",
        latitude: "-4.62109670",
        longitude: "55.42778020",
        nameNative: null,
        label: "Beau Vallon",
        cityCode: "SC_08",
      },
      {
        name: "Bel Air",
        countryCode: "SC",
        latitude: "34.10024550",
        longitude: "-118.45946300",
        nameNative: null,
        label: "Bel Air",
        cityCode: "SC_09",
      },
      {
        name: "Bel Ombre",
        countryCode: "SC",
        latitude: "-20.50100950",
        longitude: "57.42596240",
        nameNative: null,
        label: "Bel Ombre",
        cityCode: "SC_10",
      },
      {
        name: "Cascade",
        countryCode: "SC",
        latitude: "44.51628210",
        longitude: "-116.04179830",
        nameNative: null,
        label: "Cascade",
        cityCode: "SC_11",
      },
      {
        name: "Glacis",
        countryCode: "SC",
        latitude: "47.11573030",
        longitude: "-70.30281830",
        nameNative: null,
        label: "Glacis",
        cityCode: "SC_12",
      },
      {
        name: "Grand'Anse Mahé",
        countryCode: "SC",
        latitude: "-4.67739200",
        longitude: "55.46377700",
        nameNative: null,
        label: "Grand'Anse Mahé",
        cityCode: "SC_13",
      },
      {
        name: "Grand'Anse Praslin",
        countryCode: "SC",
        latitude: "-4.31762190",
        longitude: "55.70783630",
        nameNative: null,
        label: "Grand'Anse Praslin",
        cityCode: "SC_14",
      },
      {
        name: "La Digue",
        countryCode: "SC",
        latitude: "49.76669220",
        longitude: "-97.15466290",
        nameNative: null,
        label: "La Digue",
        cityCode: "SC_15",
      },
      {
        name: "La Rivière Anglaise",
        countryCode: "SC",
        latitude: "-4.61061500",
        longitude: "55.45408410",
        nameNative: null,
        label: "La Rivière Anglaise",
        cityCode: "SC_16",
      },
      {
        name: "Les Mamelles",
        countryCode: "SC",
        latitude: "38.82505050",
        longitude: "-90.48345170",
        nameNative: null,
        label: "Les Mamelles",
        cityCode: "SC_24",
      },
      {
        name: "Mont Buxton",
        countryCode: "SC",
        latitude: "-4.61666670",
        longitude: "55.44577680",
        nameNative: null,
        label: "Mont Buxton",
        cityCode: "SC_17",
      },
      {
        name: "Mont Fleuri",
        countryCode: "SC",
        latitude: "-4.63565430",
        longitude: "55.45546880",
        nameNative: null,
        label: "Mont Fleuri",
        cityCode: "SC_18",
      },
      {
        name: "Plaisance",
        countryCode: "SC",
        latitude: "45.60709500",
        longitude: "-75.11427450",
        nameNative: null,
        label: "Plaisance",
        cityCode: "SC_19",
      },
      {
        name: "Pointe La Rue",
        countryCode: "SC",
        latitude: "-4.68048900",
        longitude: "55.51918570",
        nameNative: null,
        label: "Pointe La Rue",
        cityCode: "SC_20",
      },
      {
        name: "Port Glaud",
        countryCode: "SC",
        latitude: "-4.64885230",
        longitude: "55.41947530",
        nameNative: null,
        label: "Port Glaud",
        cityCode: "SC_21",
      },
      {
        name: "Roche Caiman",
        countryCode: "SC",
        latitude: "-4.63960280",
        longitude: "55.46793150",
        nameNative: null,
        label: "Roche Caiman",
        cityCode: "SC_25",
      },
      {
        name: "Saint Louis",
        countryCode: "SC",
        latitude: "38.62700250",
        longitude: "-90.19940420",
        nameNative: null,
        label: "Saint Louis",
        cityCode: "SC_22",
      },
      {
        name: "Takamaka",
        countryCode: "SC",
        latitude: "37.96459170",
        longitude: "-1.22177270",
        nameNative: null,
        label: "Takamaka",
        cityCode: "SC_23",
      },
    ],
    SL: [
      {
        name: "Eastern Province",
        countryCode: "SL",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Eastern Province",
        cityCode: "SL_E",
      },
      {
        name: "Northern Province",
        countryCode: "SL",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Northern Province",
        cityCode: "SL_N",
      },
      {
        name: "Southern Province",
        countryCode: "SL",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Southern Province",
        cityCode: "SL_S",
      },
      {
        name: "Western Area",
        countryCode: "SL",
        latitude: "40.25459690",
        longitude: "-80.24554440",
        nameNative: null,
        label: "Western Area",
        cityCode: "SL_W",
      },
    ],
    SG: [
      {
        name: "Central Singapore Community Development Council",
        countryCode: "SG",
        latitude: "1.28951400",
        longitude: "103.81438790",
        nameNative: null,
        label: "Central Singapore Community Development Council",
        cityCode: "SG_01",
      },
      {
        name: "North East Community Development Council",
        countryCode: "SG",
        latitude: "45.01181130",
        longitude: "-93.24681070",
        nameNative: null,
        label: "North East Community Development Council",
        cityCode: "SG_02",
      },
      {
        name: "North West Community Development Council",
        countryCode: "SG",
        latitude: "39.10709300",
        longitude: "-94.45733600",
        nameNative: null,
        label: "North West Community Development Council",
        cityCode: "SG_03",
      },
      {
        name: "South East Community Development Council",
        countryCode: "SG",
        latitude: "39.28630700",
        longitude: "-76.56912370",
        nameNative: null,
        label: "South East Community Development Council",
        cityCode: "SG_04",
      },
      {
        name: "South West Community Development Council",
        countryCode: "SG",
        latitude: "39.92569100",
        longitude: "-75.23105800",
        nameNative: null,
        label: "South West Community Development Council",
        cityCode: "SG_05",
      },
    ],
    SK: [
      {
        name: "Banská Bystrica Region",
        countryCode: "SK",
        latitude: "48.53124990",
        longitude: "19.38287400",
        nameNative: null,
        label: "Banská Bystrica Region",
        cityCode: "SK_BC",
      },
      {
        name: "Bratislava Region",
        countryCode: "SK",
        latitude: "48.31183040",
        longitude: "17.19732990",
        nameNative: null,
        label: "Bratislava Region",
        cityCode: "SK_BL",
      },
      {
        name: "Košice Region",
        countryCode: "SK",
        latitude: "48.63757370",
        longitude: "21.08342250",
        nameNative: null,
        label: "Košice Region",
        cityCode: "SK_KI",
      },
      {
        name: "Nitra Region",
        countryCode: "SK",
        latitude: "48.01437650",
        longitude: "18.54165040",
        nameNative: null,
        label: "Nitra Region",
        cityCode: "SK_NI",
      },
      {
        name: "Prešov Region",
        countryCode: "SK",
        latitude: "49.17167730",
        longitude: "21.37420010",
        nameNative: null,
        label: "Prešov Region",
        cityCode: "SK_PV",
      },
      {
        name: "Trenčín Region",
        countryCode: "SK",
        latitude: "48.80867580",
        longitude: "18.23240260",
        nameNative: null,
        label: "Trenčín Region",
        cityCode: "SK_TC",
      },
      {
        name: "Trnava Region",
        countryCode: "SK",
        latitude: "48.39438980",
        longitude: "17.72162050",
        nameNative: null,
        label: "Trnava Region",
        cityCode: "SK_TA",
      },
      {
        name: "Žilina Region",
        countryCode: "SK",
        latitude: "49.20314350",
        longitude: "19.36457330",
        nameNative: null,
        label: "Žilina Region",
        cityCode: "SK_ZI",
      },
    ],
    SI: [
      {
        name: "Ajdovščina Municipality",
        countryCode: "SI",
        latitude: "45.88707760",
        longitude: "13.90428180",
        nameNative: null,
        label: "Ajdovščina Municipality",
        cityCode: "SI_001",
      },
      {
        name: "Ankaran Municipality",
        countryCode: "SI",
        latitude: "45.57845100",
        longitude: "13.73691740",
        nameNative: null,
        label: "Ankaran Municipality",
        cityCode: "SI_213",
      },
      {
        name: "Beltinci Municipality",
        countryCode: "SI",
        latitude: "46.60791530",
        longitude: "16.23651270",
        nameNative: null,
        label: "Beltinci Municipality",
        cityCode: "SI_002",
      },
      {
        name: "Benedikt Municipality",
        countryCode: "SI",
        latitude: "46.61558410",
        longitude: "15.89572810",
        nameNative: null,
        label: "Benedikt Municipality",
        cityCode: "SI_148",
      },
      {
        name: "Bistrica ob Sotli Municipality",
        countryCode: "SI",
        latitude: "46.05655790",
        longitude: "15.66259470",
        nameNative: null,
        label: "Bistrica ob Sotli Municipality",
        cityCode: "SI_149",
      },
      {
        name: "Bled Municipality",
        countryCode: "SI",
        latitude: "46.36832660",
        longitude: "14.11457980",
        nameNative: null,
        label: "Bled Municipality",
        cityCode: "SI_003",
      },
      {
        name: "Bloke Municipality",
        countryCode: "SI",
        latitude: "45.77281410",
        longitude: "14.50634590",
        nameNative: null,
        label: "Bloke Municipality",
        cityCode: "SI_150",
      },
      {
        name: "Bohinj Municipality",
        countryCode: "SI",
        latitude: "46.30056520",
        longitude: "13.94271950",
        nameNative: null,
        label: "Bohinj Municipality",
        cityCode: "SI_004",
      },
      {
        name: "Borovnica Municipality",
        countryCode: "SI",
        latitude: "45.90445250",
        longitude: "14.38241890",
        nameNative: null,
        label: "Borovnica Municipality",
        cityCode: "SI_005",
      },
      {
        name: "Bovec Municipality",
        countryCode: "SI",
        latitude: "46.33804950",
        longitude: "13.55241740",
        nameNative: null,
        label: "Bovec Municipality",
        cityCode: "SI_006",
      },
      {
        name: "Braslovče Municipality",
        countryCode: "SI",
        latitude: "46.28361920",
        longitude: "15.04183200",
        nameNative: null,
        label: "Braslovče Municipality",
        cityCode: "SI_151",
      },
      {
        name: "Brda Municipality",
        countryCode: "SI",
        latitude: "45.99756520",
        longitude: "13.52704740",
        nameNative: null,
        label: "Brda Municipality",
        cityCode: "SI_007",
      },
      {
        name: "Brezovica Municipality",
        countryCode: "SI",
        latitude: "45.95593510",
        longitude: "14.43499520",
        nameNative: null,
        label: "Brezovica Municipality",
        cityCode: "SI_008",
      },
      {
        name: "Brežice Municipality",
        countryCode: "SI",
        latitude: "45.90410960",
        longitude: "15.59436390",
        nameNative: null,
        label: "Brežice Municipality",
        cityCode: "SI_009",
      },
      {
        name: "Cankova Municipality",
        countryCode: "SI",
        latitude: "46.71823700",
        longitude: "16.01972220",
        nameNative: null,
        label: "Cankova Municipality",
        cityCode: "SI_152",
      },
      {
        name: "Cerklje na Gorenjskem Municipality",
        countryCode: "SI",
        latitude: "46.25170540",
        longitude: "14.48579790",
        nameNative: null,
        label: "Cerklje na Gorenjskem Municipality",
        cityCode: "SI_012",
      },
      {
        name: "Cerknica Municipality",
        countryCode: "SI",
        latitude: "45.79662550",
        longitude: "14.39217700",
        nameNative: null,
        label: "Cerknica Municipality",
        cityCode: "SI_013",
      },
      {
        name: "Cerkno Municipality",
        countryCode: "SI",
        latitude: "46.12884140",
        longitude: "13.98940270",
        nameNative: null,
        label: "Cerkno Municipality",
        cityCode: "SI_014",
      },
      {
        name: "Cerkvenjak Municipality",
        countryCode: "SI",
        latitude: "46.56707110",
        longitude: "15.94297530",
        nameNative: null,
        label: "Cerkvenjak Municipality",
        cityCode: "SI_153",
      },
      {
        name: "City Municipality of Celje",
        countryCode: "SI",
        latitude: "46.23974950",
        longitude: "15.26770630",
        nameNative: null,
        label: "City Municipality of Celje",
        cityCode: "SI_011",
      },
      {
        name: "City Municipality of Novo Mesto",
        countryCode: "SI",
        latitude: "45.80108240",
        longitude: "15.17100890",
        nameNative: null,
        label: "City Municipality of Novo Mesto",
        cityCode: "SI_085",
      },
      {
        name: "Destrnik Municipality",
        countryCode: "SI",
        latitude: "46.49223680",
        longitude: "15.87779560",
        nameNative: null,
        label: "Destrnik Municipality",
        cityCode: "SI_018",
      },
      {
        name: "Divača Municipality",
        countryCode: "SI",
        latitude: "45.68060690",
        longitude: "13.97203120",
        nameNative: null,
        label: "Divača Municipality",
        cityCode: "SI_019",
      },
      {
        name: "Dobje Municipality",
        countryCode: "SI",
        latitude: "46.13700370",
        longitude: "15.39412900",
        nameNative: null,
        label: "Dobje Municipality",
        cityCode: "SI_154",
      },
      {
        name: "Dobrepolje Municipality",
        countryCode: "SI",
        latitude: "45.85249510",
        longitude: "14.70831090",
        nameNative: null,
        label: "Dobrepolje Municipality",
        cityCode: "SI_020",
      },
      {
        name: "Dobrna Municipality",
        countryCode: "SI",
        latitude: "46.33561410",
        longitude: "15.22597320",
        nameNative: null,
        label: "Dobrna Municipality",
        cityCode: "SI_155",
      },
      {
        name: "Dobrova–Polhov Gradec Municipality",
        countryCode: "SI",
        latitude: "46.06488960",
        longitude: "14.31681950",
        nameNative: null,
        label: "Dobrova–Polhov Gradec Municipality",
        cityCode: "SI_021",
      },
      {
        name: "Dobrovnik Municipality",
        countryCode: "SI",
        latitude: "46.65386620",
        longitude: "16.35065940",
        nameNative: null,
        label: "Dobrovnik Municipality",
        cityCode: "SI_156",
      },
      {
        name: "Dol pri Ljubljani Municipality",
        countryCode: "SI",
        latitude: "46.08843860",
        longitude: "14.64247920",
        nameNative: null,
        label: "Dol pri Ljubljani Municipality",
        cityCode: "SI_022",
      },
      {
        name: "Dolenjske Toplice Municipality",
        countryCode: "SI",
        latitude: "45.73457110",
        longitude: "15.01294930",
        nameNative: null,
        label: "Dolenjske Toplice Municipality",
        cityCode: "SI_157",
      },
      {
        name: "Domžale Municipality",
        countryCode: "SI",
        latitude: "46.14382690",
        longitude: "14.63752790",
        nameNative: null,
        label: "Domžale Municipality",
        cityCode: "SI_023",
      },
      {
        name: "Dornava Municipality",
        countryCode: "SI",
        latitude: "46.44435130",
        longitude: "15.98891590",
        nameNative: null,
        label: "Dornava Municipality",
        cityCode: "SI_024",
      },
      {
        name: "Dravograd Municipality",
        countryCode: "SI",
        latitude: "46.58921900",
        longitude: "15.02460210",
        nameNative: null,
        label: "Dravograd Municipality",
        cityCode: "SI_025",
      },
      {
        name: "Duplek Municipality",
        countryCode: "SI",
        latitude: "46.50100160",
        longitude: "15.75463070",
        nameNative: null,
        label: "Duplek Municipality",
        cityCode: "SI_026",
      },
      {
        name: "Gorenja Vas–Poljane Municipality",
        countryCode: "SI",
        latitude: "46.11165820",
        longitude: "14.11493480",
        nameNative: null,
        label: "Gorenja Vas–Poljane Municipality",
        cityCode: "SI_027",
      },
      {
        name: "Gorišnica Municipality",
        countryCode: "SI",
        latitude: "46.41202710",
        longitude: "16.01330890",
        nameNative: null,
        label: "Gorišnica Municipality",
        cityCode: "SI_028",
      },
      {
        name: "Gorje Municipality",
        countryCode: "SI",
        latitude: "46.38024580",
        longitude: "14.06853390",
        nameNative: null,
        label: "Gorje Municipality",
        cityCode: "SI_207",
      },
      {
        name: "Gornja Radgona Municipality",
        countryCode: "SI",
        latitude: "46.67670990",
        longitude: "15.99108470",
        nameNative: null,
        label: "Gornja Radgona Municipality",
        cityCode: "SI_029",
      },
      {
        name: "Gornji Grad Municipality",
        countryCode: "SI",
        latitude: "46.29617120",
        longitude: "14.80623470",
        nameNative: null,
        label: "Gornji Grad Municipality",
        cityCode: "SI_030",
      },
      {
        name: "Gornji Petrovci Municipality",
        countryCode: "SI",
        latitude: "46.80371280",
        longitude: "16.21913790",
        nameNative: null,
        label: "Gornji Petrovci Municipality",
        cityCode: "SI_031",
      },
      {
        name: "Grad Municipality",
        countryCode: "SI",
        latitude: "46.80873200",
        longitude: "16.10920600",
        nameNative: null,
        label: "Grad Municipality",
        cityCode: "SI_158",
      },
      {
        name: "Grosuplje Municipality",
        countryCode: "SI",
        latitude: "45.95576450",
        longitude: "14.65889900",
        nameNative: null,
        label: "Grosuplje Municipality",
        cityCode: "SI_032",
      },
      {
        name: "Hajdina Municipality",
        countryCode: "SI",
        latitude: "46.41850140",
        longitude: "15.82447220",
        nameNative: null,
        label: "Hajdina Municipality",
        cityCode: "SI_159",
      },
      {
        name: "Hodoš Municipality",
        countryCode: "SI",
        latitude: "46.83141340",
        longitude: "16.32106800",
        nameNative: null,
        label: "Hodoš Municipality",
        cityCode: "SI_161",
      },
      {
        name: "Horjul Municipality",
        countryCode: "SI",
        latitude: "46.02253780",
        longitude: "14.29862690",
        nameNative: null,
        label: "Horjul Municipality",
        cityCode: "SI_162",
      },
      {
        name: "Hoče–Slivnica Municipality",
        countryCode: "SI",
        latitude: "46.47785800",
        longitude: "15.64760050",
        nameNative: null,
        label: "Hoče–Slivnica Municipality",
        cityCode: "SI_160",
      },
      {
        name: "Hrastnik Municipality",
        countryCode: "SI",
        latitude: "46.14172880",
        longitude: "15.08448940",
        nameNative: null,
        label: "Hrastnik Municipality",
        cityCode: "SI_034",
      },
      {
        name: "Hrpelje–Kozina Municipality",
        countryCode: "SI",
        latitude: "45.60911920",
        longitude: "13.93791480",
        nameNative: null,
        label: "Hrpelje–Kozina Municipality",
        cityCode: "SI_035",
      },
      {
        name: "Idrija Municipality",
        countryCode: "SI",
        latitude: "46.00409390",
        longitude: "13.97754930",
        nameNative: null,
        label: "Idrija Municipality",
        cityCode: "SI_036",
      },
      {
        name: "Ig Municipality",
        countryCode: "SI",
        latitude: "45.95888680",
        longitude: "14.52705280",
        nameNative: null,
        label: "Ig Municipality",
        cityCode: "SI_037",
      },
      {
        name: "Ivančna Gorica Municipality",
        countryCode: "SI",
        latitude: "45.93958410",
        longitude: "14.80476260",
        nameNative: null,
        label: "Ivančna Gorica Municipality",
        cityCode: "SI_039",
      },
      {
        name: "Izola Municipality",
        countryCode: "SI",
        latitude: "45.53135570",
        longitude: "13.66646490",
        nameNative: null,
        label: "Izola Municipality",
        cityCode: "SI_040",
      },
      {
        name: "Jesenice Municipality",
        countryCode: "SI",
        latitude: "46.43670470",
        longitude: "14.05260570",
        nameNative: null,
        label: "Jesenice Municipality",
        cityCode: "SI_041",
      },
      {
        name: "Jezersko Municipality",
        countryCode: "SI",
        latitude: "46.39427940",
        longitude: "14.49855590",
        nameNative: null,
        label: "Jezersko Municipality",
        cityCode: "SI_163",
      },
      {
        name: "Juršinci Municipality",
        countryCode: "SI",
        latitude: "46.48986510",
        longitude: "15.98092300",
        nameNative: null,
        label: "Juršinci Municipality",
        cityCode: "SI_042",
      },
      {
        name: "Kamnik Municipality",
        countryCode: "SI",
        latitude: "46.22216660",
        longitude: "14.60707270",
        nameNative: null,
        label: "Kamnik Municipality",
        cityCode: "SI_043",
      },
      {
        name: "Kanal ob Soči Municipality",
        countryCode: "SI",
        latitude: "46.06735300",
        longitude: "13.62033500",
        nameNative: null,
        label: "Kanal ob Soči Municipality",
        cityCode: "SI_044",
      },
      {
        name: "Kidričevo Municipality",
        countryCode: "SI",
        latitude: "46.39575720",
        longitude: "15.79259060",
        nameNative: null,
        label: "Kidričevo Municipality",
        cityCode: "SI_045",
      },
      {
        name: "Kobarid Municipality",
        countryCode: "SI",
        latitude: "46.24569710",
        longitude: "13.57869490",
        nameNative: null,
        label: "Kobarid Municipality",
        cityCode: "SI_046",
      },
      {
        name: "Kobilje Municipality",
        countryCode: "SI",
        latitude: "46.68518000",
        longitude: "16.39367190",
        nameNative: null,
        label: "Kobilje Municipality",
        cityCode: "SI_047",
      },
      {
        name: "Komen Municipality",
        countryCode: "SI",
        latitude: "45.81752350",
        longitude: "13.74827110",
        nameNative: null,
        label: "Komen Municipality",
        cityCode: "SI_049",
      },
      {
        name: "Komenda Municipality",
        countryCode: "SI",
        latitude: "46.20648800",
        longitude: "14.53824990",
        nameNative: null,
        label: "Komenda Municipality",
        cityCode: "SI_164",
      },
      {
        name: "Koper City Municipality",
        countryCode: "SI",
        latitude: "45.54805900",
        longitude: "13.73018770",
        nameNative: null,
        label: "Koper City Municipality",
        cityCode: "SI_050",
      },
      {
        name: "Kostanjevica na Krki Municipality",
        countryCode: "SI",
        latitude: "45.83166380",
        longitude: "15.44119060",
        nameNative: null,
        label: "Kostanjevica na Krki Municipality",
        cityCode: "SI_197",
      },
      {
        name: "Kostel Municipality",
        countryCode: "SI",
        latitude: "45.49282550",
        longitude: "14.87082350",
        nameNative: null,
        label: "Kostel Municipality",
        cityCode: "SI_165",
      },
      {
        name: "Kozje Municipality",
        countryCode: "SI",
        latitude: "46.07332110",
        longitude: "15.55967190",
        nameNative: null,
        label: "Kozje Municipality",
        cityCode: "SI_051",
      },
      {
        name: "Kočevje Municipality",
        countryCode: "SI",
        latitude: "45.64280000",
        longitude: "14.86158380",
        nameNative: null,
        label: "Kočevje Municipality",
        cityCode: "SI_048",
      },
      {
        name: "Kranj City Municipality",
        countryCode: "SI",
        latitude: "46.25850210",
        longitude: "14.35435690",
        nameNative: null,
        label: "Kranj City Municipality",
        cityCode: "SI_052",
      },
      {
        name: "Kranjska Gora Municipality",
        countryCode: "SI",
        latitude: "46.48452930",
        longitude: "13.78571450",
        nameNative: null,
        label: "Kranjska Gora Municipality",
        cityCode: "SI_053",
      },
      {
        name: "Križevci Municipality",
        countryCode: "SI",
        latitude: "46.57018210",
        longitude: "16.10926530",
        nameNative: null,
        label: "Križevci Municipality",
        cityCode: "SI_166",
      },
      {
        name: "Kungota",
        countryCode: "SI",
        latitude: "46.64187930",
        longitude: "15.60362880",
        nameNative: null,
        label: "Kungota",
        cityCode: "SI_055",
      },
      {
        name: "Kuzma Municipality",
        countryCode: "SI",
        latitude: "46.83510380",
        longitude: "16.08071000",
        nameNative: null,
        label: "Kuzma Municipality",
        cityCode: "SI_056",
      },
      {
        name: "Laško Municipality",
        countryCode: "SI",
        latitude: "46.15422360",
        longitude: "15.23614910",
        nameNative: null,
        label: "Laško Municipality",
        cityCode: "SI_057",
      },
      {
        name: "Lenart Municipality",
        countryCode: "SI",
        latitude: "46.58344240",
        longitude: "15.82621250",
        nameNative: null,
        label: "Lenart Municipality",
        cityCode: "SI_058",
      },
      {
        name: "Lendava Municipality",
        countryCode: "SI",
        latitude: "46.55134830",
        longitude: "16.44198390",
        nameNative: null,
        label: "Lendava Municipality",
        cityCode: "SI_059",
      },
      {
        name: "Litija Municipality",
        countryCode: "SI",
        latitude: "46.05732260",
        longitude: "14.83096360",
        nameNative: null,
        label: "Litija Municipality",
        cityCode: "SI_060",
      },
      {
        name: "Ljubljana City Municipality",
        countryCode: "SI",
        latitude: "46.05694650",
        longitude: "14.50575150",
        nameNative: null,
        label: "Ljubljana City Municipality",
        cityCode: "SI_061",
      },
      {
        name: "Ljubno Municipality",
        countryCode: "SI",
        latitude: "46.34431250",
        longitude: "14.83354920",
        nameNative: null,
        label: "Ljubno Municipality",
        cityCode: "SI_062",
      },
      {
        name: "Ljutomer Municipality",
        countryCode: "SI",
        latitude: "46.51908480",
        longitude: "16.18932160",
        nameNative: null,
        label: "Ljutomer Municipality",
        cityCode: "SI_063",
      },
      {
        name: "Logatec Municipality",
        countryCode: "SI",
        latitude: "45.91761100",
        longitude: "14.23514510",
        nameNative: null,
        label: "Logatec Municipality",
        cityCode: "SI_064",
      },
      {
        name: "Log–Dragomer Municipality",
        countryCode: "SI",
        latitude: "46.01787470",
        longitude: "14.36877670",
        nameNative: null,
        label: "Log–Dragomer Municipality",
        cityCode: "SI_208",
      },
      {
        name: "Lovrenc na Pohorju Municipality",
        countryCode: "SI",
        latitude: "46.54196380",
        longitude: "15.40004430",
        nameNative: null,
        label: "Lovrenc na Pohorju Municipality",
        cityCode: "SI_167",
      },
      {
        name: "Loška Dolina Municipality",
        countryCode: "SI",
        latitude: "45.64779080",
        longitude: "14.49731470",
        nameNative: null,
        label: "Loška Dolina Municipality",
        cityCode: "SI_065",
      },
      {
        name: "Loški Potok Municipality",
        countryCode: "SI",
        latitude: "45.69096370",
        longitude: "14.59859700",
        nameNative: null,
        label: "Loški Potok Municipality",
        cityCode: "SI_066",
      },
      {
        name: "Lukovica Municipality",
        countryCode: "SI",
        latitude: "46.16962930",
        longitude: "14.69072590",
        nameNative: null,
        label: "Lukovica Municipality",
        cityCode: "SI_068",
      },
      {
        name: "Luče Municipality",
        countryCode: "SI",
        latitude: "46.35449250",
        longitude: "14.74715040",
        nameNative: null,
        label: "Luče Municipality",
        cityCode: "SI_067",
      },
      {
        name: "Majšperk Municipality",
        countryCode: "SI",
        latitude: "46.35030190",
        longitude: "15.73405950",
        nameNative: null,
        label: "Majšperk Municipality",
        cityCode: "SI_069",
      },
      {
        name: "Makole Municipality",
        countryCode: "SI",
        latitude: "46.31686970",
        longitude: "15.66641260",
        nameNative: null,
        label: "Makole Municipality",
        cityCode: "SI_198",
      },
      {
        name: "Maribor City Municipality",
        countryCode: "SI",
        latitude: "46.55064960",
        longitude: "15.62054390",
        nameNative: null,
        label: "Maribor City Municipality",
        cityCode: "SI_070",
      },
      {
        name: "Markovci Municipality",
        countryCode: "SI",
        latitude: "46.38793090",
        longitude: "15.95860140",
        nameNative: null,
        label: "Markovci Municipality",
        cityCode: "SI_168",
      },
      {
        name: "Medvode Municipality",
        countryCode: "SI",
        latitude: "46.14190800",
        longitude: "14.40325960",
        nameNative: null,
        label: "Medvode Municipality",
        cityCode: "SI_071",
      },
      {
        name: "Mengeš Municipality",
        countryCode: "SI",
        latitude: "46.16591220",
        longitude: "14.57196940",
        nameNative: null,
        label: "Mengeš Municipality",
        cityCode: "SI_072",
      },
      {
        name: "Metlika Municipality",
        countryCode: "SI",
        latitude: "45.64807150",
        longitude: "15.31778380",
        nameNative: null,
        label: "Metlika Municipality",
        cityCode: "SI_073",
      },
      {
        name: "Mežica Municipality",
        countryCode: "SI",
        latitude: "46.52150270",
        longitude: "14.85213400",
        nameNative: null,
        label: "Mežica Municipality",
        cityCode: "SI_074",
      },
      {
        name: "Miklavž na Dravskem Polju Municipality",
        countryCode: "SI",
        latitude: "46.50826280",
        longitude: "15.69520650",
        nameNative: null,
        label: "Miklavž na Dravskem Polju Municipality",
        cityCode: "SI_169",
      },
      {
        name: "Miren–Kostanjevica Municipality",
        countryCode: "SI",
        latitude: "45.84360290",
        longitude: "13.62766470",
        nameNative: null,
        label: "Miren–Kostanjevica Municipality",
        cityCode: "SI_075",
      },
      {
        name: "Mirna Municipality",
        countryCode: "SI",
        latitude: "45.95156470",
        longitude: "15.06209770",
        nameNative: null,
        label: "Mirna Municipality",
        cityCode: "SI_212",
      },
      {
        name: "Mirna Peč Municipality",
        countryCode: "SI",
        latitude: "45.84815740",
        longitude: "15.08794500",
        nameNative: null,
        label: "Mirna Peč Municipality",
        cityCode: "SI_170",
      },
      {
        name: "Mislinja Municipality",
        countryCode: "SI",
        latitude: "46.44294030",
        longitude: "15.19876780",
        nameNative: null,
        label: "Mislinja Municipality",
        cityCode: "SI_076",
      },
      {
        name: "Mokronog–Trebelno Municipality",
        countryCode: "SI",
        latitude: "45.90885290",
        longitude: "15.15967360",
        nameNative: null,
        label: "Mokronog–Trebelno Municipality",
        cityCode: "SI_199",
      },
      {
        name: "Moravske Toplice Municipality",
        countryCode: "SI",
        latitude: "46.68569320",
        longitude: "16.22245820",
        nameNative: null,
        label: "Moravske Toplice Municipality",
        cityCode: "SI_078",
      },
      {
        name: "Moravče Municipality",
        countryCode: "SI",
        latitude: "46.13627810",
        longitude: "14.74600100",
        nameNative: null,
        label: "Moravče Municipality",
        cityCode: "SI_077",
      },
      {
        name: "Mozirje Municipality",
        countryCode: "SI",
        latitude: "46.33943500",
        longitude: "14.96024130",
        nameNative: null,
        label: "Mozirje Municipality",
        cityCode: "SI_079",
      },
      {
        name: "Municipality of Apače",
        countryCode: "SI",
        latitude: "46.69746790",
        longitude: "15.91025340",
        nameNative: null,
        label: "Municipality of Apače",
        cityCode: "SI_195",
      },
      {
        name: "Municipality of Cirkulane",
        countryCode: "SI",
        latitude: "46.32983220",
        longitude: "15.99806660",
        nameNative: null,
        label: "Municipality of Cirkulane",
        cityCode: "SI_196",
      },
      {
        name: "Municipality of Ilirska Bistrica",
        countryCode: "SI",
        latitude: "45.57913230",
        longitude: "14.28097290",
        nameNative: null,
        label: "Municipality of Ilirska Bistrica",
        cityCode: "SI_038",
      },
      {
        name: "Municipality of Krško",
        countryCode: "SI",
        latitude: "45.95896090",
        longitude: "15.49235550",
        nameNative: null,
        label: "Municipality of Krško",
        cityCode: "SI_054",
      },
      {
        name: "Municipality of Škofljica",
        countryCode: "SI",
        latitude: "45.98409620",
        longitude: "14.57466260",
        nameNative: null,
        label: "Municipality of Škofljica",
        cityCode: "SI_123",
      },
      {
        name: "Murska Sobota City Municipality",
        countryCode: "SI",
        latitude: "46.64321470",
        longitude: "16.15157540",
        nameNative: null,
        label: "Murska Sobota City Municipality",
        cityCode: "SI_080",
      },
      {
        name: "Muta Municipality",
        countryCode: "SI",
        latitude: "46.60973660",
        longitude: "15.16299950",
        nameNative: null,
        label: "Muta Municipality",
        cityCode: "SI_081",
      },
      {
        name: "Naklo Municipality",
        countryCode: "SI",
        latitude: "46.27186630",
        longitude: "14.31569320",
        nameNative: null,
        label: "Naklo Municipality",
        cityCode: "SI_082",
      },
      {
        name: "Nazarje Municipality",
        countryCode: "SI",
        latitude: "46.28217410",
        longitude: "14.92256290",
        nameNative: null,
        label: "Nazarje Municipality",
        cityCode: "SI_083",
      },
      {
        name: "Nova Gorica City Municipality",
        countryCode: "SI",
        latitude: "45.97627600",
        longitude: "13.73088810",
        nameNative: null,
        label: "Nova Gorica City Municipality",
        cityCode: "SI_084",
      },
      {
        name: "Odranci Municipality",
        countryCode: "SI",
        latitude: "46.59010170",
        longitude: "16.27881650",
        nameNative: null,
        label: "Odranci Municipality",
        cityCode: "SI_086",
      },
      {
        name: "Oplotnica",
        countryCode: "SI",
        latitude: "46.38716300",
        longitude: "15.44581310",
        nameNative: null,
        label: "Oplotnica",
        cityCode: "SI_171",
      },
      {
        name: "Ormož Municipality",
        countryCode: "SI",
        latitude: "46.43533330",
        longitude: "16.15437400",
        nameNative: null,
        label: "Ormož Municipality",
        cityCode: "SI_087",
      },
      {
        name: "Osilnica Municipality",
        countryCode: "SI",
        latitude: "45.54184670",
        longitude: "14.71563030",
        nameNative: null,
        label: "Osilnica Municipality",
        cityCode: "SI_088",
      },
      {
        name: "Pesnica Municipality",
        countryCode: "SI",
        latitude: "46.60887550",
        longitude: "15.67570510",
        nameNative: null,
        label: "Pesnica Municipality",
        cityCode: "SI_089",
      },
      {
        name: "Piran Municipality",
        countryCode: "SI",
        latitude: "45.52888560",
        longitude: "13.56807350",
        nameNative: null,
        label: "Piran Municipality",
        cityCode: "SI_090",
      },
      {
        name: "Pivka Municipality",
        countryCode: "SI",
        latitude: "45.67892960",
        longitude: "14.25426890",
        nameNative: null,
        label: "Pivka Municipality",
        cityCode: "SI_091",
      },
      {
        name: "Podlehnik Municipality",
        countryCode: "SI",
        latitude: "46.33107820",
        longitude: "15.87858360",
        nameNative: null,
        label: "Podlehnik Municipality",
        cityCode: "SI_172",
      },
      {
        name: "Podvelka Municipality",
        countryCode: "SI",
        latitude: "46.62219520",
        longitude: "15.38899220",
        nameNative: null,
        label: "Podvelka Municipality",
        cityCode: "SI_093",
      },
      {
        name: "Podčetrtek Municipality",
        countryCode: "SI",
        latitude: "46.17395420",
        longitude: "15.60138160",
        nameNative: null,
        label: "Podčetrtek Municipality",
        cityCode: "SI_092",
      },
      {
        name: "Poljčane Municipality",
        countryCode: "SI",
        latitude: "46.31398530",
        longitude: "15.57847910",
        nameNative: null,
        label: "Poljčane Municipality",
        cityCode: "SI_200",
      },
      {
        name: "Polzela Municipality",
        countryCode: "SI",
        latitude: "46.28089700",
        longitude: "15.07373210",
        nameNative: null,
        label: "Polzela Municipality",
        cityCode: "SI_173",
      },
      {
        name: "Postojna Municipality",
        countryCode: "SI",
        latitude: "45.77493900",
        longitude: "14.21342630",
        nameNative: null,
        label: "Postojna Municipality",
        cityCode: "SI_094",
      },
      {
        name: "Prebold Municipality",
        countryCode: "SI",
        latitude: "46.23591360",
        longitude: "15.09369120",
        nameNative: null,
        label: "Prebold Municipality",
        cityCode: "SI_174",
      },
      {
        name: "Preddvor Municipality",
        countryCode: "SI",
        latitude: "46.30171390",
        longitude: "14.42181650",
        nameNative: null,
        label: "Preddvor Municipality",
        cityCode: "SI_095",
      },
      {
        name: "Prevalje Municipality",
        countryCode: "SI",
        latitude: "46.56211460",
        longitude: "14.88478610",
        nameNative: null,
        label: "Prevalje Municipality",
        cityCode: "SI_175",
      },
      {
        name: "Ptuj City Municipality",
        countryCode: "SI",
        latitude: "46.41995350",
        longitude: "15.86968840",
        nameNative: null,
        label: "Ptuj City Municipality",
        cityCode: "SI_096",
      },
      {
        name: "Puconci Municipality",
        countryCode: "SI",
        latitude: "46.72004180",
        longitude: "16.09977920",
        nameNative: null,
        label: "Puconci Municipality",
        cityCode: "SI_097",
      },
      {
        name: "Radenci Municipality",
        countryCode: "SI",
        latitude: "46.62311210",
        longitude: "16.05069030",
        nameNative: null,
        label: "Radenci Municipality",
        cityCode: "SI_100",
      },
      {
        name: "Radeče Municipality",
        countryCode: "SI",
        latitude: "46.06669540",
        longitude: "15.18204380",
        nameNative: null,
        label: "Radeče Municipality",
        cityCode: "SI_099",
      },
      {
        name: "Radlje ob Dravi Municipality",
        countryCode: "SI",
        latitude: "46.61357320",
        longitude: "15.23544380",
        nameNative: null,
        label: "Radlje ob Dravi Municipality",
        cityCode: "SI_101",
      },
      {
        name: "Radovljica Municipality",
        countryCode: "SI",
        latitude: "46.33558270",
        longitude: "14.20945340",
        nameNative: null,
        label: "Radovljica Municipality",
        cityCode: "SI_102",
      },
      {
        name: "Ravne na Koroškem Municipality",
        countryCode: "SI",
        latitude: "46.55211940",
        longitude: "14.95990840",
        nameNative: null,
        label: "Ravne na Koroškem Municipality",
        cityCode: "SI_103",
      },
      {
        name: "Razkrižje Municipality",
        countryCode: "SI",
        latitude: "46.52263390",
        longitude: "16.26686380",
        nameNative: null,
        label: "Razkrižje Municipality",
        cityCode: "SI_176",
      },
      {
        name: "Rače–Fram Municipality",
        countryCode: "SI",
        latitude: "46.45420830",
        longitude: "15.63294670",
        nameNative: null,
        label: "Rače–Fram Municipality",
        cityCode: "SI_098",
      },
      {
        name: "Renče–Vogrsko Municipality",
        countryCode: "SI",
        latitude: "45.89546170",
        longitude: "13.67856730",
        nameNative: null,
        label: "Renče–Vogrsko Municipality",
        cityCode: "SI_201",
      },
      {
        name: "Rečica ob Savinji Municipality",
        countryCode: "SI",
        latitude: "46.32337900",
        longitude: "14.92236700",
        nameNative: null,
        label: "Rečica ob Savinji Municipality",
        cityCode: "SI_209",
      },
      {
        name: "Ribnica Municipality",
        countryCode: "SI",
        latitude: "45.74003030",
        longitude: "14.72657820",
        nameNative: null,
        label: "Ribnica Municipality",
        cityCode: "SI_104",
      },
      {
        name: "Ribnica na Pohorju Municipality",
        countryCode: "SI",
        latitude: "46.53561450",
        longitude: "15.26745380",
        nameNative: null,
        label: "Ribnica na Pohorju Municipality",
        cityCode: "SI_177",
      },
      {
        name: "Rogatec Municipality",
        countryCode: "SI",
        latitude: "46.22866260",
        longitude: "15.69913380",
        nameNative: null,
        label: "Rogatec Municipality",
        cityCode: "SI_107",
      },
      {
        name: "Rogaška Slatina Municipality",
        countryCode: "SI",
        latitude: "46.24539730",
        longitude: "15.62650140",
        nameNative: null,
        label: "Rogaška Slatina Municipality",
        cityCode: "SI_106",
      },
      {
        name: "Rogašovci Municipality",
        countryCode: "SI",
        latitude: "46.80557850",
        longitude: "16.03452370",
        nameNative: null,
        label: "Rogašovci Municipality",
        cityCode: "SI_105",
      },
      {
        name: "Ruše Municipality",
        countryCode: "SI",
        latitude: "46.52062650",
        longitude: "15.48178690",
        nameNative: null,
        label: "Ruše Municipality",
        cityCode: "SI_108",
      },
      {
        name: "Selnica ob Dravi Municipality",
        countryCode: "SI",
        latitude: "46.55139180",
        longitude: "15.49294100",
        nameNative: null,
        label: "Selnica ob Dravi Municipality",
        cityCode: "SI_178",
      },
      {
        name: "Semič Municipality",
        countryCode: "SI",
        latitude: "45.65205340",
        longitude: "15.18207010",
        nameNative: null,
        label: "Semič Municipality",
        cityCode: "SI_109",
      },
      {
        name: "Sevnica Municipality",
        countryCode: "SI",
        latitude: "46.00703170",
        longitude: "15.30456790",
        nameNative: null,
        label: "Sevnica Municipality",
        cityCode: "SI_110",
      },
      {
        name: "Sežana Municipality",
        countryCode: "SI",
        latitude: "45.72751090",
        longitude: "13.86619310",
        nameNative: null,
        label: "Sežana Municipality",
        cityCode: "SI_111",
      },
      {
        name: "Slovenj Gradec City Municipality",
        countryCode: "SI",
        latitude: "46.48777180",
        longitude: "15.07294780",
        nameNative: null,
        label: "Slovenj Gradec City Municipality",
        cityCode: "SI_112",
      },
      {
        name: "Slovenska Bistrica Municipality",
        countryCode: "SI",
        latitude: "46.39198130",
        longitude: "15.57278690",
        nameNative: null,
        label: "Slovenska Bistrica Municipality",
        cityCode: "SI_113",
      },
      {
        name: "Slovenske Konjice Municipality",
        countryCode: "SI",
        latitude: "46.33691910",
        longitude: "15.42147080",
        nameNative: null,
        label: "Slovenske Konjice Municipality",
        cityCode: "SI_114",
      },
      {
        name: "Sodražica Municipality",
        countryCode: "SI",
        latitude: "45.76165650",
        longitude: "14.63528530",
        nameNative: null,
        label: "Sodražica Municipality",
        cityCode: "SI_179",
      },
      {
        name: "Solčava Municipality",
        countryCode: "SI",
        latitude: "46.40235260",
        longitude: "14.68023040",
        nameNative: null,
        label: "Solčava Municipality",
        cityCode: "SI_180",
      },
      {
        name: "Središče ob Dravi",
        countryCode: "SI",
        latitude: "46.39592820",
        longitude: "16.27049150",
        nameNative: null,
        label: "Središče ob Dravi",
        cityCode: "SI_202",
      },
      {
        name: "Starše Municipality",
        countryCode: "SI",
        latitude: "46.46743310",
        longitude: "15.76405460",
        nameNative: null,
        label: "Starše Municipality",
        cityCode: "SI_115",
      },
      {
        name: "Straža Municipality",
        countryCode: "SI",
        latitude: "45.77684280",
        longitude: "15.09486940",
        nameNative: null,
        label: "Straža Municipality",
        cityCode: "SI_203",
      },
      {
        name: "Sveta Ana Municipality",
        countryCode: "SI",
        latitude: "46.65000000",
        longitude: "15.84527800",
        nameNative: null,
        label: "Sveta Ana Municipality",
        cityCode: "SI_181",
      },
      {
        name: "Sveta Trojica v Slovenskih Goricah Municipality",
        countryCode: "SI",
        latitude: "46.56808090",
        longitude: "15.88230640",
        nameNative: null,
        label: "Sveta Trojica v Slovenskih Goricah Municipality",
        cityCode: "SI_204",
      },
      {
        name: "Sveti Andraž v Slovenskih Goricah Municipality",
        countryCode: "SI",
        latitude: "46.51897470",
        longitude: "15.94982620",
        nameNative: null,
        label: "Sveti Andraž v Slovenskih Goricah Municipality",
        cityCode: "SI_182",
      },
      {
        name: "Sveti Jurij ob Ščavnici Municipality",
        countryCode: "SI",
        latitude: "46.56874520",
        longitude: "16.02225280",
        nameNative: null,
        label: "Sveti Jurij ob Ščavnici Municipality",
        cityCode: "SI_116",
      },
      {
        name: "Sveti Jurij v Slovenskih Goricah Municipality",
        countryCode: "SI",
        latitude: "46.61707910",
        longitude: "15.78046770",
        nameNative: null,
        label: "Sveti Jurij v Slovenskih Goricah Municipality",
        cityCode: "SI_210",
      },
      {
        name: "Sveti Tomaž Municipality",
        countryCode: "SI",
        latitude: "46.48352830",
        longitude: "16.07944200",
        nameNative: null,
        label: "Sveti Tomaž Municipality",
        cityCode: "SI_205",
      },
      {
        name: "Tabor Municipality",
        countryCode: "SI",
        latitude: "46.21079210",
        longitude: "15.01742490",
        nameNative: null,
        label: "Tabor Municipality",
        cityCode: "SI_184",
      },
      {
        name: "Tišina Municipality",
        countryCode: "SI",
        latitude: "46.65418840",
        longitude: "16.07547810",
        nameNative: null,
        label: "Tišina Municipality",
        cityCode: "SI_010",
      },
      {
        name: "Tolmin Municipality",
        countryCode: "SI",
        latitude: "46.18571880",
        longitude: "13.73198380",
        nameNative: null,
        label: "Tolmin Municipality",
        cityCode: "SI_128",
      },
      {
        name: "Trbovlje Municipality",
        countryCode: "SI",
        latitude: "46.15035630",
        longitude: "15.04531370",
        nameNative: null,
        label: "Trbovlje Municipality",
        cityCode: "SI_129",
      },
      {
        name: "Trebnje Municipality",
        countryCode: "SI",
        latitude: "45.90801630",
        longitude: "15.01319050",
        nameNative: null,
        label: "Trebnje Municipality",
        cityCode: "SI_130",
      },
      {
        name: "Trnovska Vas Municipality",
        countryCode: "SI",
        latitude: "46.52940350",
        longitude: "15.88531180",
        nameNative: null,
        label: "Trnovska Vas Municipality",
        cityCode: "SI_185",
      },
      {
        name: "Trzin Municipality",
        countryCode: "SI",
        latitude: "46.12982410",
        longitude: "14.55776370",
        nameNative: null,
        label: "Trzin Municipality",
        cityCode: "SI_186",
      },
      {
        name: "Tržič Municipality",
        countryCode: "SI",
        latitude: "46.35935140",
        longitude: "14.30066230",
        nameNative: null,
        label: "Tržič Municipality",
        cityCode: "SI_131",
      },
      {
        name: "Turnišče Municipality",
        countryCode: "SI",
        latitude: "46.61375040",
        longitude: "16.32021000",
        nameNative: null,
        label: "Turnišče Municipality",
        cityCode: "SI_132",
      },
      {
        name: "Velika Polana Municipality",
        countryCode: "SI",
        latitude: "46.57317150",
        longitude: "16.34441260",
        nameNative: null,
        label: "Velika Polana Municipality",
        cityCode: "SI_187",
      },
      {
        name: "Velike Lašče Municipality",
        countryCode: "SI",
        latitude: "45.83365910",
        longitude: "14.63623630",
        nameNative: null,
        label: "Velike Lašče Municipality",
        cityCode: "SI_134",
      },
      {
        name: "Veržej Municipality",
        countryCode: "SI",
        latitude: "46.58411350",
        longitude: "16.16208000",
        nameNative: null,
        label: "Veržej Municipality",
        cityCode: "SI_188",
      },
      {
        name: "Videm Municipality",
        countryCode: "SI",
        latitude: "46.36383300",
        longitude: "15.87812120",
        nameNative: null,
        label: "Videm Municipality",
        cityCode: "SI_135",
      },
      {
        name: "Vipava Municipality",
        countryCode: "SI",
        latitude: "45.84126740",
        longitude: "13.96096130",
        nameNative: null,
        label: "Vipava Municipality",
        cityCode: "SI_136",
      },
      {
        name: "Vitanje Municipality",
        countryCode: "SI",
        latitude: "46.38153230",
        longitude: "15.29506870",
        nameNative: null,
        label: "Vitanje Municipality",
        cityCode: "SI_137",
      },
      {
        name: "Vodice Municipality",
        countryCode: "SI",
        latitude: "46.18966430",
        longitude: "14.49385390",
        nameNative: null,
        label: "Vodice Municipality",
        cityCode: "SI_138",
      },
      {
        name: "Vojnik Municipality",
        countryCode: "SI",
        latitude: "46.29205810",
        longitude: "15.30205800",
        nameNative: null,
        label: "Vojnik Municipality",
        cityCode: "SI_139",
      },
      {
        name: "Vransko Municipality",
        countryCode: "SI",
        latitude: "46.23900600",
        longitude: "14.95272490",
        nameNative: null,
        label: "Vransko Municipality",
        cityCode: "SI_189",
      },
      {
        name: "Vrhnika Municipality",
        countryCode: "SI",
        latitude: "45.95027190",
        longitude: "14.32764220",
        nameNative: null,
        label: "Vrhnika Municipality",
        cityCode: "SI_140",
      },
      {
        name: "Vuzenica Municipality",
        countryCode: "SI",
        latitude: "46.59808360",
        longitude: "15.16572370",
        nameNative: null,
        label: "Vuzenica Municipality",
        cityCode: "SI_141",
      },
      {
        name: "Zagorje ob Savi Municipality",
        countryCode: "SI",
        latitude: "46.13452020",
        longitude: "14.99643840",
        nameNative: null,
        label: "Zagorje ob Savi Municipality",
        cityCode: "SI_142",
      },
      {
        name: "Zavrč Municipality",
        countryCode: "SI",
        latitude: "46.35713000",
        longitude: "16.04777470",
        nameNative: null,
        label: "Zavrč Municipality",
        cityCode: "SI_143",
      },
      {
        name: "Zreče Municipality",
        countryCode: "SI",
        latitude: "46.41777860",
        longitude: "15.37094310",
        nameNative: null,
        label: "Zreče Municipality",
        cityCode: "SI_144",
      },
      {
        name: "Črenšovci Municipality",
        countryCode: "SI",
        latitude: "46.57200290",
        longitude: "16.28773460",
        nameNative: null,
        label: "Črenšovci Municipality",
        cityCode: "SI_015",
      },
      {
        name: "Črna na Koroškem Municipality",
        countryCode: "SI",
        latitude: "46.47045290",
        longitude: "14.84999980",
        nameNative: null,
        label: "Črna na Koroškem Municipality",
        cityCode: "SI_016",
      },
      {
        name: "Črnomelj Municipality",
        countryCode: "SI",
        latitude: "45.53612250",
        longitude: "15.19441430",
        nameNative: null,
        label: "Črnomelj Municipality",
        cityCode: "SI_017",
      },
      {
        name: "Šalovci Municipality",
        countryCode: "SI",
        latitude: "46.85335680",
        longitude: "16.25917910",
        nameNative: null,
        label: "Šalovci Municipality",
        cityCode: "SI_033",
      },
      {
        name: "Šempeter–Vrtojba Municipality",
        countryCode: "SI",
        latitude: "45.92900950",
        longitude: "13.64155940",
        nameNative: null,
        label: "Šempeter–Vrtojba Municipality",
        cityCode: "SI_183",
      },
      {
        name: "Šentilj Municipality",
        countryCode: "SI",
        latitude: "46.68628390",
        longitude: "15.71035670",
        nameNative: null,
        label: "Šentilj Municipality",
        cityCode: "SI_118",
      },
      {
        name: "Šentjernej Municipality",
        countryCode: "SI",
        latitude: "45.84341300",
        longitude: "15.33783120",
        nameNative: null,
        label: "Šentjernej Municipality",
        cityCode: "SI_119",
      },
      {
        name: "Šentjur Municipality",
        countryCode: "SI",
        latitude: "46.26543390",
        longitude: "15.40800000",
        nameNative: null,
        label: "Šentjur Municipality",
        cityCode: "SI_120",
      },
      {
        name: "Šentrupert Municipality",
        countryCode: "SI",
        latitude: "45.98731420",
        longitude: "15.08297830",
        nameNative: null,
        label: "Šentrupert Municipality",
        cityCode: "SI_211",
      },
      {
        name: "Šenčur Municipality",
        countryCode: "SI",
        latitude: "46.24336990",
        longitude: "14.41922230",
        nameNative: null,
        label: "Šenčur Municipality",
        cityCode: "SI_117",
      },
      {
        name: "Škocjan Municipality",
        countryCode: "SI",
        latitude: "45.91754540",
        longitude: "15.31017360",
        nameNative: null,
        label: "Škocjan Municipality",
        cityCode: "SI_121",
      },
      {
        name: "Škofja Loka Municipality",
        countryCode: "SI",
        latitude: "46.14098440",
        longitude: "14.28118730",
        nameNative: null,
        label: "Škofja Loka Municipality",
        cityCode: "SI_122",
      },
      {
        name: "Šmarje pri Jelšah Municipality",
        countryCode: "SI",
        latitude: "46.22870250",
        longitude: "15.51903530",
        nameNative: null,
        label: "Šmarje pri Jelšah Municipality",
        cityCode: "SI_124",
      },
      {
        name: "Šmarješke Toplice Municipality",
        countryCode: "SI",
        latitude: "45.86803770",
        longitude: "15.23474220",
        nameNative: null,
        label: "Šmarješke Toplice Municipality",
        cityCode: "SI_206",
      },
      {
        name: "Šmartno ob Paki Municipality",
        countryCode: "SI",
        latitude: "46.32903720",
        longitude: "15.03339370",
        nameNative: null,
        label: "Šmartno ob Paki Municipality",
        cityCode: "SI_125",
      },
      {
        name: "Šmartno pri Litiji Municipality",
        countryCode: "SI",
        latitude: "46.04549710",
        longitude: "14.84101330",
        nameNative: null,
        label: "Šmartno pri Litiji Municipality",
        cityCode: "SI_194",
      },
      {
        name: "Šoštanj Municipality",
        countryCode: "SI",
        latitude: "46.37828360",
        longitude: "15.04613780",
        nameNative: null,
        label: "Šoštanj Municipality",
        cityCode: "SI_126",
      },
      {
        name: "Štore Municipality",
        countryCode: "SI",
        latitude: "46.22225140",
        longitude: "15.31261160",
        nameNative: null,
        label: "Štore Municipality",
        cityCode: "SI_127",
      },
      {
        name: "Žalec Municipality",
        countryCode: "SI",
        latitude: "46.25197120",
        longitude: "15.16500720",
        nameNative: null,
        label: "Žalec Municipality",
        cityCode: "SI_190",
      },
      {
        name: "Železniki Municipality",
        countryCode: "SI",
        latitude: "46.22563770",
        longitude: "14.16936170",
        nameNative: null,
        label: "Železniki Municipality",
        cityCode: "SI_146",
      },
      {
        name: "Žetale Municipality",
        countryCode: "SI",
        latitude: "46.27428330",
        longitude: "15.79133590",
        nameNative: null,
        label: "Žetale Municipality",
        cityCode: "SI_191",
      },
      {
        name: "Žiri Municipality",
        countryCode: "SI",
        latitude: "46.04724990",
        longitude: "14.10984510",
        nameNative: null,
        label: "Žiri Municipality",
        cityCode: "SI_147",
      },
      {
        name: "Žirovnica Municipality",
        countryCode: "SI",
        latitude: "46.39544030",
        longitude: "14.15396320",
        nameNative: null,
        label: "Žirovnica Municipality",
        cityCode: "SI_192",
      },
      {
        name: "Žužemberk Municipality",
        countryCode: "SI",
        latitude: "45.82003500",
        longitude: "14.95359190",
        nameNative: null,
        label: "Žužemberk Municipality",
        cityCode: "SI_193",
      },
    ],
    SB: [
      {
        name: "Central Province",
        countryCode: "SB",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Central Province",
        cityCode: "SB_CE",
      },
      {
        name: "Choiseul Province",
        countryCode: "SB",
        latitude: "-7.05014940",
        longitude: "156.95114590",
        nameNative: null,
        label: "Choiseul Province",
        cityCode: "SB_CH",
      },
      {
        name: "Guadalcanal Province",
        countryCode: "SB",
        latitude: "-9.57732840",
        longitude: "160.14558050",
        nameNative: null,
        label: "Guadalcanal Province",
        cityCode: "SB_GU",
      },
      {
        name: "Honiara",
        countryCode: "SB",
        latitude: "-9.44563810",
        longitude: "159.97289990",
        nameNative: null,
        label: "Honiara",
        cityCode: "SB_CT",
      },
      {
        name: "Isabel Province",
        countryCode: "SB",
        latitude: "-8.05923530",
        longitude: "159.14470810",
        nameNative: null,
        label: "Isabel Province",
        cityCode: "SB_IS",
      },
      {
        name: "Makira-Ulawa Province",
        countryCode: "SB",
        latitude: "-10.57374470",
        longitude: "161.80969410",
        nameNative: null,
        label: "Makira-Ulawa Province",
        cityCode: "SB_MK",
      },
      {
        name: "Malaita Province",
        countryCode: "SB",
        latitude: "-8.94461680",
        longitude: "160.90712360",
        nameNative: null,
        label: "Malaita Province",
        cityCode: "SB_ML",
      },
      {
        name: "Rennell and Bellona Province",
        countryCode: "SB",
        latitude: "-11.61314350",
        longitude: "160.16939490",
        nameNative: null,
        label: "Rennell and Bellona Province",
        cityCode: "SB_RB",
      },
      {
        name: "Temotu Province",
        countryCode: "SB",
        latitude: "-10.68692900",
        longitude: "166.06239790",
        nameNative: null,
        label: "Temotu Province",
        cityCode: "SB_TE",
      },
      {
        name: "Western Province",
        countryCode: "SB",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Western Province",
        cityCode: "SB_WE",
      },
    ],
    SO: [
      {
        name: "Awdal Region",
        countryCode: "SO",
        latitude: "10.63342850",
        longitude: "43.32946600",
        nameNative: null,
        label: "Awdal Region",
        cityCode: "SO_AW",
      },
      {
        name: "Bakool",
        countryCode: "SO",
        latitude: "4.36572210",
        longitude: "44.09603110",
        nameNative: null,
        label: "Bakool",
        cityCode: "SO_BK",
      },
      {
        name: "Banaadir",
        countryCode: "SO",
        latitude: "2.11873750",
        longitude: "45.33694590",
        nameNative: null,
        label: "Banaadir",
        cityCode: "SO_BN",
      },
      {
        name: "Bari",
        countryCode: "SO",
        latitude: "41.11714320",
        longitude: "16.87187150",
        nameNative: null,
        label: "Bari",
        cityCode: "SO_BR",
      },
      {
        name: "Bay",
        countryCode: "SO",
        latitude: "37.03655340",
        longitude: "-95.61747670",
        nameNative: null,
        label: "Bay",
        cityCode: "SO_BY",
      },
      {
        name: "Galguduud",
        countryCode: "SO",
        latitude: "5.18501280",
        longitude: "46.82528380",
        nameNative: null,
        label: "Galguduud",
        cityCode: "SO_GA",
      },
      {
        name: "Gedo",
        countryCode: "SO",
        latitude: "3.50392270",
        longitude: "42.23624350",
        nameNative: null,
        label: "Gedo",
        cityCode: "SO_GE",
      },
      {
        name: "Hiran",
        countryCode: "SO",
        latitude: "4.32101500",
        longitude: "45.29938620",
        nameNative: null,
        label: "Hiran",
        cityCode: "SO_HI",
      },
      {
        name: "Lower Juba",
        countryCode: "SO",
        latitude: "0.22402100",
        longitude: "41.60118140",
        nameNative: null,
        label: "Lower Juba",
        cityCode: "SO_JH",
      },
      {
        name: "Lower Shebelle",
        countryCode: "SO",
        latitude: "1.87664580",
        longitude: "44.24790150",
        nameNative: null,
        label: "Lower Shebelle",
        cityCode: "SO_SH",
      },
      {
        name: "Middle Juba",
        countryCode: "SO",
        latitude: "2.07804880",
        longitude: "41.60118140",
        nameNative: null,
        label: "Middle Juba",
        cityCode: "SO_JD",
      },
      {
        name: "Middle Shebelle",
        countryCode: "SO",
        latitude: "2.92502470",
        longitude: "45.90396890",
        nameNative: null,
        label: "Middle Shebelle",
        cityCode: "SO_SD",
      },
      {
        name: "Mudug",
        countryCode: "SO",
        latitude: "6.56567260",
        longitude: "47.76375650",
        nameNative: null,
        label: "Mudug",
        cityCode: "SO_MU",
      },
      {
        name: "Nugal",
        countryCode: "SO",
        latitude: "43.27938610",
        longitude: "17.03392050",
        nameNative: null,
        label: "Nugal",
        cityCode: "SO_NU",
      },
      {
        name: "Sanaag Region",
        countryCode: "SO",
        latitude: "10.39382180",
        longitude: "47.76375650",
        nameNative: null,
        label: "Sanaag Region",
        cityCode: "SO_SA",
      },
      {
        name: "Togdheer Region",
        countryCode: "SO",
        latitude: "9.44605870",
        longitude: "45.29938620",
        nameNative: null,
        label: "Togdheer Region",
        cityCode: "SO_TO",
      },
    ],
    ZA: [
      {
        name: "Eastern Cape",
        countryCode: "ZA",
        latitude: "-32.29684020",
        longitude: "26.41938900",
        nameNative: null,
        label: "Eastern Cape",
        cityCode: "ZA_EC",
      },
      {
        name: "Free State",
        countryCode: "ZA",
        latitude: "37.68585250",
        longitude: "-97.28112560",
        nameNative: null,
        label: "Free State",
        cityCode: "ZA_FS",
      },
      {
        name: "Gauteng",
        countryCode: "ZA",
        latitude: "-26.27075930",
        longitude: "28.11226790",
        nameNative: null,
        label: "Gauteng",
        cityCode: "ZA_GP",
      },
      {
        name: "KwaZulu-Natal",
        countryCode: "ZA",
        latitude: "-28.53055390",
        longitude: "30.89582420",
        nameNative: null,
        label: "KwaZulu-Natal",
        cityCode: "ZA_KZN",
      },
      {
        name: "Limpopo",
        countryCode: "ZA",
        latitude: "-23.40129460",
        longitude: "29.41793240",
        nameNative: null,
        label: "Limpopo",
        cityCode: "ZA_LP",
      },
      {
        name: "Mpumalanga",
        countryCode: "ZA",
        latitude: "-25.56533600",
        longitude: "30.52790960",
        nameNative: null,
        label: "Mpumalanga",
        cityCode: "ZA_MP",
      },
      {
        name: "North West",
        countryCode: "ZA",
        latitude: "32.75885200",
        longitude: "-97.32880600",
        nameNative: null,
        label: "North West",
        cityCode: "ZA_NW",
      },
      {
        name: "Northern Cape",
        countryCode: "ZA",
        latitude: "-29.04668080",
        longitude: "21.85685860",
        nameNative: null,
        label: "Northern Cape",
        cityCode: "ZA_NC",
      },
      {
        name: "Western Cape",
        countryCode: "ZA",
        latitude: "-33.22779180",
        longitude: "21.85685860",
        nameNative: null,
        label: "Western Cape",
        cityCode: "ZA_WC",
      },
    ],
    SS: [
      {
        name: "Central Equatoria",
        countryCode: "SS",
        latitude: "4.61440630",
        longitude: "31.26263660",
        nameNative: null,
        label: "Central Equatoria",
        cityCode: "SS_EC",
      },
      {
        name: "Eastern Equatoria",
        countryCode: "SS",
        latitude: "5.06929950",
        longitude: "33.43835300",
        nameNative: null,
        label: "Eastern Equatoria",
        cityCode: "SS_EE",
      },
      {
        name: "Jonglei State",
        countryCode: "SS",
        latitude: "7.18196190",
        longitude: "32.35609520",
        nameNative: null,
        label: "Jonglei State",
        cityCode: "SS_JG",
      },
      {
        name: "Lakes",
        countryCode: "SS",
        latitude: "37.16282550",
        longitude: "-95.69116230",
        nameNative: null,
        label: "Lakes",
        cityCode: "SS_LK",
      },
      {
        name: "Northern Bahr el Ghazal",
        countryCode: "SS",
        latitude: "8.53604490",
        longitude: "26.79678490",
        nameNative: null,
        label: "Northern Bahr el Ghazal",
        cityCode: "SS_BN",
      },
      {
        name: "Unity",
        countryCode: "SS",
        latitude: "37.78712760",
        longitude: "-122.40340790",
        nameNative: null,
        label: "Unity",
        cityCode: "SS_UY",
      },
      {
        name: "Upper Nile",
        countryCode: "SS",
        latitude: "9.88942020",
        longitude: "32.71813750",
        nameNative: null,
        label: "Upper Nile",
        cityCode: "SS_NU",
      },
      {
        name: "Warrap",
        countryCode: "SS",
        latitude: "8.08862380",
        longitude: "28.64106410",
        nameNative: null,
        label: "Warrap",
        cityCode: "SS_WR",
      },
      {
        name: "Western Bahr el Ghazal",
        countryCode: "SS",
        latitude: "8.64523990",
        longitude: "25.28375850",
        nameNative: null,
        label: "Western Bahr el Ghazal",
        cityCode: "SS_BW",
      },
      {
        name: "Western Equatoria",
        countryCode: "SS",
        latitude: "5.34717990",
        longitude: "28.29943500",
        nameNative: null,
        label: "Western Equatoria",
        cityCode: "SS_EW",
      },
    ],
    ES: [
      {
        name: "Andalusia",
        countryCode: "ES",
        latitude: "37.54427060",
        longitude: "-4.72775280",
        nameNative: "Andalucía",
        label: "Andalucía / Andalusia",
        cityCode: "ES_AN",
      },
      {
        name: "Aragon",
        countryCode: "ES",
        latitude: "41.59762750",
        longitude: "-0.90566230",
        nameNative: "Aragón",
        label: "Aragón / Aragon",
        cityCode: "ES_AR",
      },
      {
        name: "Asturias",
        countryCode: "ES",
        latitude: "43.36139530",
        longitude: "-5.85932670",
        nameNative: "Asturias",
        label: "Asturias / Asturias",
        cityCode: "ES_AS",
      },
      {
        name: "Balearic Islands",
        countryCode: "ES",
        latitude: "39.35877590",
        longitude: "2.73563280",
        nameNative: "Islas Baleares",
        label: "Islas Baleares / Balearic Islands",
        cityCode: "ES_PM",
      },
      {
        name: "Basque Country",
        countryCode: "ES",
        latitude: "42.98962480",
        longitude: "-2.61892730",
        nameNative: "País Vasco",
        label: "País Vasco / Basque Country",
        cityCode: "ES_PV",
      },
      {
        name: "Burgos Province",
        countryCode: "ES",
        latitude: "42.33807580",
        longitude: "-3.58126920",
        nameNative: "Provincia de Burgos",
        label: "Provincia de Burgos / Burgos Province",
        cityCode: "ES_BU",
      },
      {
        name: "Canary Islands",
        countryCode: "ES",
        latitude: "28.29156370",
        longitude: "-16.62913040",
        nameNative: "Islas Canarias",
        label: "Islas Canarias / Canary Islands",
        cityCode: "ES_CN",
      },
      {
        name: "Cantabria",
        countryCode: "ES",
        latitude: "43.18283960",
        longitude: "-3.98784270",
        nameNative: "Cantabria",
        label: "Cantabria / Cantabria",
        cityCode: "ES_CB",
      },
      {
        name: "Castile and León",
        countryCode: "ES",
        latitude: "41.83568210",
        longitude: "-4.39763570",
        nameNative: "Castilla y León",
        label: "Castilla y León / Castile and León",
        cityCode: "ES_CL",
      },
      {
        name: "Castilla La Mancha",
        countryCode: "ES",
        latitude: "39.27956070",
        longitude: "-3.09770200",
        nameNative: "Castilla-La Mancha",
        label: "Castilla-La Mancha / Castilla La Mancha",
        cityCode: "ES_CM",
      },
      {
        name: "Catalonia",
        countryCode: "ES",
        latitude: "41.59115890",
        longitude: "1.52086240",
        nameNative: "Cataluña",
        label: "Cataluña / Catalonia",
        cityCode: "ES_CT",
      },
      {
        name: "Ceuta",
        countryCode: "ES",
        latitude: "35.88938740",
        longitude: "-5.32134550",
        nameNative: "Ceuta",
        label: "Ceuta / Ceuta",
        cityCode: "ES_CE",
      },
      {
        name: "Extremadura",
        countryCode: "ES",
        latitude: "39.49373920",
        longitude: "-6.06791940",
        nameNative: "Extremadura",
        label: "Extremadura / Extremadura",
        cityCode: "ES_EX",
      },
      {
        name: "Galicia",
        countryCode: "ES",
        latitude: "42.57505540",
        longitude: "-8.13385580",
        nameNative: "Galicia",
        label: "Galicia / Galicia",
        cityCode: "ES_GA",
      },
      {
        name: "La Rioja",
        countryCode: "ES",
        latitude: "42.28707330",
        longitude: "-2.53960300",
        nameNative: "La Rioja",
        label: "La Rioja / La Rioja",
        cityCode: "ES_RI",
      },
      {
        name: "Léon",
        countryCode: "ES",
        latitude: "42.59870410",
        longitude: "-5.56708390",
        nameNative: "León",
        label: "León / Léon",
        cityCode: "ES_LE",
      },
      {
        name: "Madrid",
        countryCode: "ES",
        latitude: "40.41675150",
        longitude: "-3.70383220",
        nameNative: "Madrid",
        label: "Madrid / Madrid",
        cityCode: "ES_MD",
      },
      {
        name: "Melilla",
        countryCode: "ES",
        latitude: "35.29227750",
        longitude: "-2.93809730",
        nameNative: "Melilla",
        label: "Melilla / Melilla",
        cityCode: "ES_ML",
      },
      {
        name: "Murcia",
        countryCode: "ES",
        latitude: "38.13981410",
        longitude: "-1.36621600",
        nameNative: "Región de Murcia",
        label: "Región de Murcia / Murcia",
        cityCode: "ES_MC",
      },
      {
        name: "Navarra",
        countryCode: "ES",
        latitude: "42.69539090",
        longitude: "-1.67606910",
        nameNative: "Navarra",
        label: "Navarra / Navarra",
        cityCode: "ES_NC",
      },
      {
        name: "Palencia Province",
        countryCode: "ES",
        latitude: "42.00968320",
        longitude: "-4.52879490",
        nameNative: "Provincia de Palencia",
        label: "Provincia de Palencia / Palencia Province",
        cityCode: "ES_P",
      },
      {
        name: "Salamanca Province",
        countryCode: "ES",
        latitude: "40.95152630",
        longitude: "-6.23759470",
        nameNative: "Provincia de Salamanca",
        label: "Provincia de Salamanca / Salamanca Province",
        cityCode: "ES_SA",
      },
      {
        name: "Segovia Province",
        countryCode: "ES",
        latitude: "40.94292960",
        longitude: "-4.10889420",
        nameNative: "Provincia de Segovia",
        label: "Provincia de Segovia / Segovia Province",
        cityCode: "ES_SG",
      },
      {
        name: "Soria Province",
        countryCode: "ES",
        latitude: "41.76654640",
        longitude: "-2.47903060",
        nameNative: "Provincia de Soria",
        label: "Provincia de Soria / Soria Province",
        cityCode: "ES_SO",
      },
      {
        name: "Valencia",
        countryCode: "ES",
        latitude: "39.48401080",
        longitude: "-0.75328090",
        nameNative: "Comunidad Valenciana",
        label: "Comunidad Valenciana / Valencia",
        cityCode: "ES_VC",
      },
      {
        name: "Valladolid Province",
        countryCode: "ES",
        latitude: "41.65173750",
        longitude: "-4.72449500",
        nameNative: "Provincia de Valladolid",
        label: "Provincia de Valladolid / Valladolid Province",
        cityCode: "ES_VA",
      },
      {
        name: "Zamora Province",
        countryCode: "ES",
        latitude: "41.60957440",
        longitude: "-5.89871390",
        nameNative: "Provincia de Zamora",
        label: "Provincia de Zamora / Zamora Province",
        cityCode: "ES_ZA",
      },
      {
        name: "Ávila",
        countryCode: "ES",
        latitude: "40.69345110",
        longitude: "-4.89356270",
        nameNative: "Ávila",
        label: "Ávila / Ávila",
        cityCode: "ES_AV",
      },
    ],
    LK: [
      {
        name: "Ampara District",
        countryCode: "LK",
        latitude: "7.29116850",
        longitude: "81.67237610",
        nameNative: null,
        label: "Ampara District",
        cityCode: "LK_52",
      },
      {
        name: "Anuradhapura District",
        countryCode: "LK",
        latitude: "8.33183050",
        longitude: "80.40290170",
        nameNative: null,
        label: "Anuradhapura District",
        cityCode: "LK_71",
      },
      {
        name: "Badulla District",
        countryCode: "LK",
        latitude: "6.99340090",
        longitude: "81.05498150",
        nameNative: null,
        label: "Badulla District",
        cityCode: "LK_81",
      },
      {
        name: "Batticaloa District",
        countryCode: "LK",
        latitude: "7.82927810",
        longitude: "81.47183870",
        nameNative: null,
        label: "Batticaloa District",
        cityCode: "LK_51",
      },
      {
        name: "Central Province",
        countryCode: "LK",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Central Province",
        cityCode: "LK_2",
      },
      {
        name: "Colombo District",
        countryCode: "LK",
        latitude: "6.92695570",
        longitude: "79.86173060",
        nameNative: null,
        label: "Colombo District",
        cityCode: "LK_11",
      },
      {
        name: "Eastern Province",
        countryCode: "LK",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Eastern Province",
        cityCode: "LK_5",
      },
      {
        name: "Galle District",
        countryCode: "LK",
        latitude: "6.05774900",
        longitude: "80.21755720",
        nameNative: null,
        label: "Galle District",
        cityCode: "LK_31",
      },
      {
        name: "Gampaha District",
        countryCode: "LK",
        latitude: "7.07126190",
        longitude: "80.00877460",
        nameNative: null,
        label: "Gampaha District",
        cityCode: "LK_12",
      },
      {
        name: "Hambantota District",
        countryCode: "LK",
        latitude: "6.15358160",
        longitude: "81.12714900",
        nameNative: null,
        label: "Hambantota District",
        cityCode: "LK_33",
      },
      {
        name: "Jaffna District",
        countryCode: "LK",
        latitude: "9.69304680",
        longitude: "80.16518540",
        nameNative: null,
        label: "Jaffna District",
        cityCode: "LK_41",
      },
      {
        name: "Kalutara District",
        countryCode: "LK",
        latitude: "6.60846860",
        longitude: "80.14285840",
        nameNative: null,
        label: "Kalutara District",
        cityCode: "LK_13",
      },
      {
        name: "Kandy District",
        countryCode: "LK",
        latitude: "7.29315880",
        longitude: "80.63501070",
        nameNative: null,
        label: "Kandy District",
        cityCode: "LK_21",
      },
      {
        name: "Kegalle District",
        countryCode: "LK",
        latitude: "7.12040530",
        longitude: "80.32131060",
        nameNative: null,
        label: "Kegalle District",
        cityCode: "LK_92",
      },
      {
        name: "Kilinochchi District",
        countryCode: "LK",
        latitude: "9.36779710",
        longitude: "80.32131060",
        nameNative: null,
        label: "Kilinochchi District",
        cityCode: "LK_42",
      },
      {
        name: "Mannar District",
        countryCode: "LK",
        latitude: "8.98095310",
        longitude: "79.90439750",
        nameNative: null,
        label: "Mannar District",
        cityCode: "LK_43",
      },
      {
        name: "Matale District",
        countryCode: "LK",
        latitude: "7.46596460",
        longitude: "80.62342590",
        nameNative: null,
        label: "Matale District",
        cityCode: "LK_22",
      },
      {
        name: "Matara District",
        countryCode: "LK",
        latitude: "5.94493480",
        longitude: "80.54879970",
        nameNative: null,
        label: "Matara District",
        cityCode: "LK_32",
      },
      {
        name: "Monaragala District",
        countryCode: "LK",
        latitude: "6.87277810",
        longitude: "81.35068320",
        nameNative: null,
        label: "Monaragala District",
        cityCode: "LK_82",
      },
      {
        name: "Mullaitivu District",
        countryCode: "LK",
        latitude: "9.26753880",
        longitude: "80.81282540",
        nameNative: null,
        label: "Mullaitivu District",
        cityCode: "LK_45",
      },
      {
        name: "North Central Province",
        countryCode: "LK",
        latitude: "8.19956380",
        longitude: "80.63269160",
        nameNative: null,
        label: "North Central Province",
        cityCode: "LK_7",
      },
      {
        name: "North Western Province",
        countryCode: "LK",
        latitude: "7.75840910",
        longitude: "80.18750650",
        nameNative: null,
        label: "North Western Province",
        cityCode: "LK_6",
      },
      {
        name: "Northern Province",
        countryCode: "LK",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Northern Province",
        cityCode: "LK_4",
      },
      {
        name: "Nuwara Eliya District",
        countryCode: "LK",
        latitude: "6.96065320",
        longitude: "80.76927580",
        nameNative: null,
        label: "Nuwara Eliya District",
        cityCode: "LK_23",
      },
      {
        name: "Polonnaruwa District",
        countryCode: "LK",
        latitude: "7.93955670",
        longitude: "81.00034030",
        nameNative: null,
        label: "Polonnaruwa District",
        cityCode: "LK_72",
      },
      {
        name: "Puttalam District",
        countryCode: "LK",
        latitude: "8.02599150",
        longitude: "79.84712720",
        nameNative: null,
        label: "Puttalam District",
        cityCode: "LK_62",
      },
      {
        name: "Ratnapura district",
        countryCode: "LK",
        latitude: "6.70551680",
        longitude: "80.38483890",
        nameNative: null,
        label: "Ratnapura district",
        cityCode: "LK_91",
      },
      {
        name: "Sabaragamuwa Province",
        countryCode: "LK",
        latitude: "6.73959410",
        longitude: "80.36586500",
        nameNative: null,
        label: "Sabaragamuwa Province",
        cityCode: "LK_9",
      },
      {
        name: "Southern Province",
        countryCode: "LK",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Southern Province",
        cityCode: "LK_3",
      },
      {
        name: "Trincomalee District",
        countryCode: "LK",
        latitude: "8.60130690",
        longitude: "81.11960750",
        nameNative: null,
        label: "Trincomalee District",
        cityCode: "LK_53",
      },
      {
        name: "Uva Province",
        countryCode: "LK",
        latitude: "6.84276120",
        longitude: "81.33994140",
        nameNative: null,
        label: "Uva Province",
        cityCode: "LK_8",
      },
      {
        name: "Vavuniya District",
        countryCode: "LK",
        latitude: "8.75947390",
        longitude: "80.50003340",
        nameNative: null,
        label: "Vavuniya District",
        cityCode: "LK_44",
      },
      {
        name: "Western Province",
        countryCode: "LK",
        latitude: null,
        longitude: null,
        nameNative: null,
        label: "Western Province",
        cityCode: "LK_1",
      },
    ],
    SD: [
      {
        name: "Al Jazirah",
        countryCode: "SD",
        latitude: "14.88596110",
        longitude: "33.43835300",
        nameNative: null,
        label: "Al Jazirah",
        cityCode: "SD_GZ",
      },
      {
        name: "Al Qadarif",
        countryCode: "SD",
        latitude: "14.02430700",
        longitude: "35.36856790",
        nameNative: null,
        label: "Al Qadarif",
        cityCode: "SD_GD",
      },
      {
        name: "Blue Nile",
        countryCode: "SD",
        latitude: "47.59867300",
        longitude: "-122.33441900",
        nameNative: null,
        label: "Blue Nile",
        cityCode: "SD_NB",
      },
      {
        name: "Central Darfur",
        countryCode: "SD",
        latitude: "14.37827470",
        longitude: "24.90422080",
        nameNative: null,
        label: "Central Darfur",
        cityCode: "SD_DC",
      },
      {
        name: "East Darfur",
        countryCode: "SD",
        latitude: "14.37827470",
        longitude: "24.90422080",
        nameNative: null,
        label: "East Darfur",
        cityCode: "SD_DE",
      },
      {
        name: "Kassala",
        countryCode: "SD",
        latitude: "15.45813320",
        longitude: "36.40396290",
        nameNative: null,
        label: "Kassala",
        cityCode: "SD_KA",
      },
      {
        name: "Khartoum",
        countryCode: "SD",
        latitude: "15.50065440",
        longitude: "32.55989940",
        nameNative: null,
        label: "Khartoum",
        cityCode: "SD_KH",
      },
      {
        name: "North Darfur",
        countryCode: "SD",
        latitude: "15.76619690",
        longitude: "24.90422080",
        nameNative: null,
        label: "North Darfur",
        cityCode: "SD_DN",
      },
      {
        name: "North Kordofan",
        countryCode: "SD",
        latitude: "13.83064410",
        longitude: "29.41793240",
        nameNative: null,
        label: "North Kordofan",
        cityCode: "SD_KN",
      },
      {
        name: "Northern",
        countryCode: "SD",
        latitude: "38.06381700",
        longitude: "-84.46286480",
        nameNative: null,
        label: "Northern",
        cityCode: "SD_NO",
      },
      {
        name: "Red Sea",
        countryCode: "SD",
        latitude: "20.28023200",
        longitude: "38.51257300",
        nameNative: null,
        label: "Red Sea",
        cityCode: "SD_RS",
      },
      {
        name: "River Nile",
        countryCode: "SD",
        latitude: "23.97275950",
        longitude: "32.87492060",
        nameNative: null,
        label: "River Nile",
        cityCode: "SD_NR",
      },
      {
        name: "Sennar",
        countryCode: "SD",
        latitude: "13.56746900",
        longitude: "33.56720450",
        nameNative: null,
        label: "Sennar",
        cityCode: "SD_SI",
      },
      {
        name: "South Darfur",
        countryCode: "SD",
        latitude: "11.64886390",
        longitude: "24.90422080",
        nameNative: null,
        label: "South Darfur",
        cityCode: "SD_DS",
      },
      {
        name: "South Kordofan",
        countryCode: "SD",
        latitude: "11.19901920",
        longitude: "29.41793240",
        nameNative: null,
        label: "South Kordofan",
        cityCode: "SD_KS",
      },
      {
        name: "West Darfur",
        countryCode: "SD",
        latitude: "12.84635610",
        longitude: "23.00119890",
        nameNative: null,
        label: "West Darfur",
        cityCode: "SD_DW",
      },
      {
        name: "West Kordofan",
        countryCode: "SD",
        latitude: "11.19901920",
        longitude: "29.41793240",
        nameNative: null,
        label: "West Kordofan",
        cityCode: "SD_GK",
      },
      {
        name: "White Nile",
        countryCode: "SD",
        latitude: "9.33215160",
        longitude: "31.46153000",
        nameNative: null,
        label: "White Nile",
        cityCode: "SD_NW",
      },
    ],
    SR: [
      {
        name: "Brokopondo District",
        countryCode: "SR",
        latitude: "4.77102470",
        longitude: "-55.04933750",
        nameNative: null,
        label: "Brokopondo District",
        cityCode: "SR_BR",
      },
      {
        name: "Commewijne District",
        countryCode: "SR",
        latitude: "5.74021100",
        longitude: "-54.87312190",
        nameNative: null,
        label: "Commewijne District",
        cityCode: "SR_CM",
      },
      {
        name: "Coronie District",
        countryCode: "SR",
        latitude: "5.69432710",
        longitude: "-56.29293810",
        nameNative: null,
        label: "Coronie District",
        cityCode: "SR_CR",
      },
      {
        name: "Marowijne District",
        countryCode: "SR",
        latitude: "5.62681280",
        longitude: "-54.25931180",
        nameNative: null,
        label: "Marowijne District",
        cityCode: "SR_MA",
      },
      {
        name: "Nickerie District",
        countryCode: "SR",
        latitude: "5.58554690",
        longitude: "-56.83111170",
        nameNative: null,
        label: "Nickerie District",
        cityCode: "SR_NI",
      },
      {
        name: "Para District",
        countryCode: "SR",
        latitude: "5.48173180",
        longitude: "-55.22592070",
        nameNative: null,
        label: "Para District",
        cityCode: "SR_PR",
      },
      {
        name: "Paramaribo District",
        countryCode: "SR",
        latitude: "5.85203550",
        longitude: "-55.20382780",
        nameNative: null,
        label: "Paramaribo District",
        cityCode: "SR_PM",
      },
      {
        name: "Saramacca District",
        countryCode: "SR",
        latitude: "5.72408130",
        longitude: "-55.66896360",
        nameNative: null,
        label: "Saramacca District",
        cityCode: "SR_SA",
      },
      {
        name: "Sipaliwini District",
        countryCode: "SR",
        latitude: "3.65673820",
        longitude: "-56.20353870",
        nameNative: null,
        label: "Sipaliwini District",
        cityCode: "SR_SI",
      },
      {
        name: "Wanica District",
        countryCode: "SR",
        latitude: "5.73237620",
        longitude: "-55.27012350",
        nameNative: null,
        label: "Wanica District",
        cityCode: "SR_WA",
      },
    ],
    SZ: [
      {
        name: "Hhohho District",
        countryCode: "SZ",
        latitude: "-26.13656620",
        longitude: "31.35416310",
        nameNative: null,
        label: "Hhohho District",
        cityCode: "SZ_HH",
      },
      {
        name: "Lubombo District",
        countryCode: "SZ",
        latitude: "-26.78517730",
        longitude: "31.81070790",
        nameNative: null,
        label: "Lubombo District",
        cityCode: "SZ_LU",
      },
      {
        name: "Manzini District",
        countryCode: "SZ",
        latitude: "-26.50819990",
        longitude: "31.37131640",
        nameNative: null,
        label: "Manzini District",
        cityCode: "SZ_MA",
      },
      {
        name: "Shiselweni District",
        countryCode: "SZ",
        latitude: "-26.98275770",
        longitude: "31.35416310",
        nameNative: null,
        label: "Shiselweni District",
        cityCode: "SZ_SH",
      },
    ],
    SE: [
      {
        name: "Blekinge",
        countryCode: "SE",
        latitude: "56.27838370",
        longitude: "15.01800580",
        nameNative: "Blekinge län",
        label: "Blekinge län / Blekinge",
        cityCode: "SE_K",
      },
      {
        name: "Dalarna County",
        countryCode: "SE",
        latitude: "61.09170120",
        longitude: "14.66636530",
        nameNative: "Dalarnas län",
        label: "Dalarnas län / Dalarna County",
        cityCode: "SE_W",
      },
      {
        name: "Gotland County",
        countryCode: "SE",
        latitude: "57.46841210",
        longitude: "18.48674470",
        nameNative: "Gotlands län",
        label: "Gotlands län / Gotland County",
        cityCode: "SE_I",
      },
      {
        name: "Gävleborg County",
        countryCode: "SE",
        latitude: "61.30119930",
        longitude: "16.15342140",
        nameNative: "Gävleborgs län",
        label: "Gävleborgs län / Gävleborg County",
        cityCode: "SE_X",
      },
      {
        name: "Halland County",
        countryCode: "SE",
        latitude: "56.89668050",
        longitude: "12.80339930",
        nameNative: "Hallands län",
        label: "Hallands län / Halland County",
        cityCode: "SE_N",
      },
      {
        name: "Jönköping County",
        countryCode: "SE",
        latitude: "57.37084340",
        longitude: "14.34391740",
        nameNative: "Jönköpings län",
        label: "Jönköpings län / Jönköping County",
        cityCode: "SE_F",
      },
      {
        name: "Kalmar County",
        countryCode: "SE",
        latitude: "57.23501560",
        longitude: "16.18493490",
        nameNative: "Kalmar län",
        label: "Kalmar län / Kalmar County",
        cityCode: "SE_H",
      },
      {
        name: "Kronoberg County",
        countryCode: "SE",
        latitude: "56.71834030",
        longitude: "14.41146730",
        nameNative: "Kronobergs län",
        label: "Kronobergs län / Kronoberg County",
        cityCode: "SE_G",
      },
      {
        name: "Norrbotten County",
        countryCode: "SE",
        latitude: "66.83092160",
        longitude: "20.39919660",
        nameNative: "Norrbottens län",
        label: "Norrbottens län / Norrbotten County",
        cityCode: "SE_BD",
      },
      {
        name: "Skåne County",
        countryCode: "SE",
        latitude: "55.99025720",
        longitude: "13.59576920",
        nameNative: "Skåne län",
        label: "Skåne län / Skåne County",
        cityCode: "SE_M",
      },
      {
        name: "Stockholm County",
        countryCode: "SE",
        latitude: "59.60249580",
        longitude: "18.13843830",
        nameNative: "Stockholms län",
        label: "Stockholms län / Stockholm County",
        cityCode: "SE_AB",
      },
      {
        name: "Södermanland County",
        countryCode: "SE",
        latitude: "59.03363490",
        longitude: "16.75188990",
        nameNative: "Södermanlands län",
        label: "Södermanlands län / Södermanland County",
        cityCode: "SE_D",
      },
      {
        name: "Uppsala County",
        countryCode: "SE",
        latitude: "60.00922620",
        longitude: "17.27145880",
        nameNative: "Uppsala län",
        label: "Uppsala län / Uppsala County",
        cityCode: "SE_C",
      },
      {
        name: "Värmland County",
        countryCode: "SE",
        latitude: "59.72940650",
        longitude: "13.23540240",
        nameNative: "Värmlands län",
        label: "Värmlands län / Värmland County",
        cityCode: "SE_S",
      },
      {
        name: "Västerbotten County",
        countryCode: "SE",
        latitude: "65.33373110",
        longitude: "16.51616940",
        nameNative: "Västerbottens län",
        label: "Västerbottens län / Västerbotten County",
        cityCode: "SE_AC",
      },
      {
        name: "Västernorrland County",
        countryCode: "SE",
        latitude: "63.42764730",
        longitude: "17.72924440",
        nameNative: "Västernorrlands län",
        label: "Västernorrlands län / Västernorrland County",
        cityCode: "SE_Y",
      },
      {
        name: "Västmanland County",
        countryCode: "SE",
        latitude: "59.67138790",
        longitude: "16.21589530",
        nameNative: "Västmanlands län",
        label: "Västmanlands län / Västmanland County",
        cityCode: "SE_U",
      },
      {
        name: "Västra Götaland County",
        countryCode: "SE",
        latitude: "58.25279260",
        longitude: "13.05964250",
        nameNative: "Västra Götalands län",
        label: "Västra Götalands län / Västra Götaland County",
        cityCode: "SE_O",
      },
      {
        name: "Örebro County",
        countryCode: "SE",
        latitude: "59.53503600",
        longitude: "15.00657310",
        nameNative: "Örebro län",
        label: "Örebro län / Örebro County",
        cityCode: "SE_T",
      },
      {
        name: "Östergötland County",
        countryCode: "SE",
        latitude: "58.34536350",
        longitude: "15.51978440",
        nameNative: "Östergötlands län",
        label: "Östergötlands län / Östergötland County",
        cityCode: "SE_E",
      },
    ],
    CH: [
      {
        name: "Aargau",
        countryCode: "CH",
        latitude: "47.38766640",
        longitude: "8.25542950",
        nameNative: null,
        label: "Aargau",
        cityCode: "CH_AG",
      },
      {
        name: "Appenzell Ausserrhoden",
        countryCode: "CH",
        latitude: "47.36648100",
        longitude: "9.30009160",
        nameNative: null,
        label: "Appenzell Ausserrhoden",
        cityCode: "CH_AR",
      },
      {
        name: "Appenzell Innerrhoden",
        countryCode: "CH",
        latitude: "47.31619250",
        longitude: "9.43165730",
        nameNative: null,
        label: "Appenzell Innerrhoden",
        cityCode: "CH_AI",
      },
      {
        name: "Basel-Land",
        countryCode: "CH",
        latitude: "47.44181220",
        longitude: "7.76440020",
        nameNative: null,
        label: "Basel-Land",
        cityCode: "CH_BL",
      },
      {
        name: "Basel-Stadt",
        countryCode: "CH",
        latitude: "47.56666700",
        longitude: "7.60000000",
        nameNative: null,
        label: "Basel-Stadt",
        cityCode: "CH_BS",
      },
      {
        name: "Bern",
        countryCode: "CH",
        latitude: "46.79886210",
        longitude: "7.70807010",
        nameNative: null,
        label: "Bern",
        cityCode: "CH_BE",
      },
      {
        name: "Fribourg",
        countryCode: "CH",
        latitude: "46.68167480",
        longitude: "7.11726350",
        nameNative: null,
        label: "Fribourg",
        cityCode: "CH_FR",
      },
      {
        name: "Geneva",
        countryCode: "CH",
        latitude: "46.21800730",
        longitude: "6.12169250",
        nameNative: null,
        label: "Geneva",
        cityCode: "CH_GE",
      },
      {
        name: "Glarus",
        countryCode: "CH",
        latitude: "47.04112320",
        longitude: "9.06790000",
        nameNative: null,
        label: "Glarus",
        cityCode: "CH_GL",
      },
      {
        name: "Graubünden",
        countryCode: "CH",
        latitude: "46.65698710",
        longitude: "9.57802570",
        nameNative: null,
        label: "Graubünden",
        cityCode: "CH_GR",
      },
      {
        name: "Jura",
        countryCode: "CH",
        latitude: "47.34444740",
        longitude: "7.14306080",
        nameNative: null,
        label: "Jura",
        cityCode: "CH_JU",
      },
      {
        name: "Lucerne",
        countryCode: "CH",
        latitude: "47.07956710",
        longitude: "8.16624450",
        nameNative: null,
        label: "Lucerne",
        cityCode: "CH_LU",
      },
      {
        name: "Neuchâtel",
        countryCode: "CH",
        latitude: "46.98998740",
        longitude: "6.92927320",
        nameNative: null,
        label: "Neuchâtel",
        cityCode: "CH_NE",
      },
      {
        name: "Nidwalden",
        countryCode: "CH",
        latitude: "46.92670160",
        longitude: "8.38499820",
        nameNative: null,
        label: "Nidwalden",
        cityCode: "CH_NW",
      },
      {
        name: "Obwalden",
        countryCode: "CH",
        latitude: "46.87785800",
        longitude: "8.25124900",
        nameNative: null,
        label: "Obwalden",
        cityCode: "CH_OW",
      },
      {
        name: "Schaffhausen",
        countryCode: "CH",
        latitude: "47.70093640",
        longitude: "8.56800400",
        nameNative: null,
        label: "Schaffhausen",
        cityCode: "CH_SH",
      },
      {
        name: "Schwyz",
        countryCode: "CH",
        latitude: "47.02071380",
        longitude: "8.65298840",
        nameNative: null,
        label: "Schwyz",
        cityCode: "CH_SZ",
      },
      {
        name: "Solothurn",
        countryCode: "CH",
        latitude: "47.33207170",
        longitude: "7.63883850",
        nameNative: null,
        label: "Solothurn",
        cityCode: "CH_SO",
      },
      {
        name: "St. Gallen",
        countryCode: "CH",
        latitude: "47.14562540",
        longitude: "9.35043320",
        nameNative: null,
        label: "St. Gallen",
        cityCode: "CH_SG",
      },
      {
        name: "Thurgau",
        countryCode: "CH",
        latitude: "47.60378560",
        longitude: "9.05573710",
        nameNative: null,
        label: "Thurgau",
        cityCode: "CH_TG",
      },
      {
        name: "Ticino",
        countryCode: "CH",
        latitude: "46.33173400",
        longitude: "8.80045290",
        nameNative: null,
        label: "Ticino",
        cityCode: "CH_TI",
      },
      {
        name: "Uri",
        countryCode: "CH",
        latitude: "41.48606470",
        longitude: "-71.53085370",
        nameNative: null,
        label: "Uri",
        cityCode: "CH_UR",
      },
      {
        name: "Valais",
        countryCode: "CH",
        latitude: "46.19046140",
        longitude: "7.54492260",
        nameNative: null,
        label: "Valais",
        cityCode: "CH_VS",
      },
      {
        name: "Vaud",
        countryCode: "CH",
        latitude: "46.56131350",
        longitude: "6.53676500",
        nameNative: null,
        label: "Vaud",
        cityCode: "CH_VD",
      },
      {
        name: "Zug",
        countryCode: "CH",
        latitude: "47.16615050",
        longitude: "8.51547490",
        nameNative: null,
        label: "Zug",
        cityCode: "CH_ZG",
      },
      {
        name: "Zürich",
        countryCode: "CH",
        latitude: "47.35953600",
        longitude: "8.63564520",
        nameNative: null,
        label: "Zürich",
        cityCode: "CH_ZH",
      },
    ],
    SY: [
      {
        name: "Al-Hasakah Governorate",
        countryCode: "SY",
        latitude: "36.40551500",
        longitude: "40.79691490",
        nameNative: null,
        label: "Al-Hasakah Governorate",
        cityCode: "SY_HA",
      },
      {
        name: "Al-Raqqah Governorate",
        countryCode: "SY",
        latitude: "35.95941060",
        longitude: "38.99810520",
        nameNative: null,
        label: "Al-Raqqah Governorate",
        cityCode: "SY_RA",
      },
      {
        name: "Aleppo Governorate",
        countryCode: "SY",
        latitude: "36.22623930",
        longitude: "37.46813960",
        nameNative: null,
        label: "Aleppo Governorate",
        cityCode: "SY_HL",
      },
      {
        name: "As-Suwayda Governorate",
        countryCode: "SY",
        latitude: "32.79891560",
        longitude: "36.78195050",
        nameNative: null,
        label: "As-Suwayda Governorate",
        cityCode: "SY_SU",
      },
      {
        name: "Damascus Governorate",
        countryCode: "SY",
        latitude: "33.51514440",
        longitude: "36.39313540",
        nameNative: null,
        label: "Damascus Governorate",
        cityCode: "SY_DI",
      },
      {
        name: "Daraa Governorate",
        countryCode: "SY",
        latitude: "32.92488130",
        longitude: "36.17626150",
        nameNative: null,
        label: "Daraa Governorate",
        cityCode: "SY_DR",
      },
      {
        name: "Deir ez-Zor Governorate",
        countryCode: "SY",
        latitude: "35.28797980",
        longitude: "40.30886260",
        nameNative: null,
        label: "Deir ez-Zor Governorate",
        cityCode: "SY_DY",
      },
      {
        name: "Hama Governorate",
        countryCode: "SY",
        latitude: "35.18878650",
        longitude: "37.21158290",
        nameNative: null,
        label: "Hama Governorate",
        cityCode: "SY_HM",
      },
      {
        name: "Homs Governorate",
        countryCode: "SY",
        latitude: "34.25671230",
        longitude: "38.31657250",
        nameNative: null,
        label: "Homs Governorate",
        cityCode: "SY_HI",
      },
      {
        name: "Idlib Governorate",
        countryCode: "SY",
        latitude: "35.82687980",
        longitude: "36.69572160",
        nameNative: null,
        label: "Idlib Governorate",
        cityCode: "SY_ID",
      },
      {
        name: "Latakia Governorate",
        countryCode: "SY",
        latitude: "35.61297910",
        longitude: "36.00232250",
        nameNative: null,
        label: "Latakia Governorate",
        cityCode: "SY_LA",
      },
      {
        name: "Quneitra Governorate",
        countryCode: "SY",
        latitude: "33.07763180",
        longitude: "35.89341360",
        nameNative: null,
        label: "Quneitra Governorate",
        cityCode: "SY_QU",
      },
      {
        name: "Rif Dimashq Governorate",
        countryCode: "SY",
        latitude: "33.51672890",
        longitude: "36.95410700",
        nameNative: null,
        label: "Rif Dimashq Governorate",
        cityCode: "SY_RD",
      },
      {
        name: "Tartus Governorate",
        countryCode: "SY",
        latitude: "35.00066520",
        longitude: "36.00232250",
        nameNative: null,
        label: "Tartus Governorate",
        cityCode: "SY_TA",
      },
    ],
    TW: [
      {
        name: "Changhua",
        countryCode: "TW",
        latitude: "24.05179630",
        longitude: "120.51613520",
        nameNative: "彰化",
        label: "彰化 / Changhua",
        cityCode: "TW_CHA",
      },
      {
        name: "Chiayi",
        countryCode: "TW",
        latitude: "23.45184280",
        longitude: "120.25546150",
        nameNative: "嘉義",
        label: "嘉義 / Chiayi",
        cityCode: "TW_CYI",
      },
      {
        name: "Chiayi",
        countryCode: "TW",
        latitude: "23.48007510",
        longitude: "120.44911130",
        nameNative: "嘉義",
        label: "嘉義 / Chiayi",
        cityCode: "TW_CYQ",
      },
      {
        name: "Hsinchu",
        countryCode: "TW",
        latitude: "24.81382870",
        longitude: "120.96747980",
        nameNative: "新竹",
        label: "新竹 / Hsinchu",
        cityCode: "TW_HSZ",
      },
      {
        name: "Hsinchu",
        countryCode: "TW",
        latitude: "24.83872260",
        longitude: "121.01772460",
        nameNative: "新竹",
        label: "新竹 / Hsinchu",
        cityCode: "TW_HSQ",
      },
      {
        name: "Hualien",
        countryCode: "TW",
        latitude: "23.98715890",
        longitude: "121.60157140",
        nameNative: "花蓮",
        label: "花蓮 / Hualien",
        cityCode: "TW_HUA",
      },
      {
        name: "Kaohsiung",
        countryCode: "TW",
        latitude: "22.62727840",
        longitude: "120.30143530",
        nameNative: "高雄",
        label: "高雄 / Kaohsiung",
        cityCode: "TW_KHH",
      },
      {
        name: "Keelung",
        countryCode: "TW",
        latitude: "25.12418620",
        longitude: "121.64758340",
        nameNative: "基隆",
        label: "基隆 / Keelung",
        cityCode: "TW_KEE",
      },
      {
        name: "Kinmen",
        countryCode: "TW",
        latitude: "24.34877920",
        longitude: "118.32856440",
        nameNative: "金門",
        label: "金門 / Kinmen",
        cityCode: "TW_KIN",
      },
      {
        name: "Lienchiang",
        countryCode: "TW",
        latitude: "26.15055560",
        longitude: "119.92888890",
        nameNative: "連江",
        label: "連江 / Lienchiang",
        cityCode: "TW_LIE",
      },
      {
        name: "Miaoli",
        countryCode: "TW",
        latitude: "24.56015900",
        longitude: "120.82142650",
        nameNative: "苗栗",
        label: "苗栗 / Miaoli",
        cityCode: "TW_MIA",
      },
      {
        name: "Nantou",
        countryCode: "TW",
        latitude: "23.96099810",
        longitude: "120.97186380",
        nameNative: "南投",
        label: "南投 / Nantou",
        cityCode: "TW_NAN",
      },
      {
        name: "New Taipei",
        countryCode: "TW",
        latitude: "24.98752780",
        longitude: "121.36459470",
        nameNative: "新北市",
        label: "新北市 / New Taipei",
        cityCode: "TW_NWT",
      },
      {
        name: "Penghu",
        countryCode: "TW",
        latitude: "23.57118990",
        longitude: "119.57931570",
        nameNative: "澎湖",
        label: "澎湖 / Penghu",
        cityCode: "TW_PEN",
      },
      {
        name: "Pingtung",
        countryCode: "TW",
        latitude: "22.55197590",
        longitude: "120.54875970",
        nameNative: "屏東",
        label: "屏東 / Pingtung",
        cityCode: "TW_PIF",
      },
      {
        name: "Taichung",
        countryCode: "TW",
        latitude: "24.14773580",
        longitude: "120.67364820",
        nameNative: "台中",
        label: "台中 / Taichung",
        cityCode: "TW_TXG",
      },
      {
        name: "Tainan",
        countryCode: "TW",
        latitude: "22.99972810",
        longitude: "120.22702770",
        nameNative: "台南",
        label: "台南 / Tainan",
        cityCode: "TW_TNN",
      },
      {
        name: "Taipei",
        countryCode: "TW",
        latitude: "25.03296940",
        longitude: "121.56541770",
        nameNative: "台北",
        label: "台北 / Taipei",
        cityCode: "TW_TPE",
      },
      {
        name: "Taitung",
        countryCode: "TW",
        latitude: "22.79724470",
        longitude: "121.07137020",
        nameNative: "台東",
        label: "台東 / Taitung",
        cityCode: "TW_TTT",
      },
      {
        name: "Taoyuan",
        countryCode: "TW",
        latitude: "24.99362810",
        longitude: "121.30097980",
        nameNative: "桃園",
        label: "桃園 / Taoyuan",
        cityCode: "TW_TAO",
      },
      {
        name: "Yilan",
        countryCode: "TW",
        latitude: "24.70210730",
        longitude: "121.73775020",
        nameNative: "宜蘭",
        label: "宜蘭 / Yilan",
        cityCode: "TW_ILA",
      },
      {
        name: "Yunlin",
        countryCode: "TW",
        latitude: "23.70920330",
        longitude: "120.43133730",
        nameNative: "雲林",
        label: "雲林 / Yunlin",
        cityCode: "TW_YUN",
      },
    ],
    TJ: [
      {
        name: "Gorno-Badakhshan Autonomous Province",
        countryCode: "TJ",
        latitude: "38.41273200",
        longitude: "73.08774900",
        nameNative: null,
        label: "Gorno-Badakhshan Autonomous Province",
        cityCode: "TJ_GB",
      },
      {
        name: "Khatlon Province",
        countryCode: "TJ",
        latitude: "37.91135620",
        longitude: "69.09702300",
        nameNative: null,
        label: "Khatlon Province",
        cityCode: "TJ_KT",
      },
      {
        name: "Sughd Province",
        countryCode: "TJ",
        latitude: "39.51553260",
        longitude: "69.09702300",
        nameNative: null,
        label: "Sughd Province",
        cityCode: "TJ_SU",
      },
      {
        name: "districts of Republican Subordination",
        countryCode: "TJ",
        latitude: "39.08579020",
        longitude: "70.24083250",
        nameNative: null,
        label: "districts of Republican Subordination",
        cityCode: "TJ_RA",
      },
    ],
    TZ: [
      {
        name: "Arusha",
        countryCode: "TZ",
        latitude: "-3.38692540",
        longitude: "36.68299270",
        nameNative: null,
        label: "Arusha",
        cityCode: "TZ_01",
      },
      {
        name: "Dar es Salaam",
        countryCode: "TZ",
        latitude: "-6.79235400",
        longitude: "39.20832840",
        nameNative: null,
        label: "Dar es Salaam",
        cityCode: "TZ_02",
      },
      {
        name: "Dodoma",
        countryCode: "TZ",
        latitude: "-6.57382280",
        longitude: "36.26308460",
        nameNative: null,
        label: "Dodoma",
        cityCode: "TZ_03",
      },
      {
        name: "Geita",
        countryCode: "TZ",
        latitude: "-2.82422570",
        longitude: "32.26538870",
        nameNative: null,
        label: "Geita",
        cityCode: "TZ_27",
      },
      {
        name: "Iringa",
        countryCode: "TZ",
        latitude: "-7.78874420",
        longitude: "35.56578620",
        nameNative: null,
        label: "Iringa",
        cityCode: "TZ_04",
      },
      {
        name: "Kagera",
        countryCode: "TZ",
        latitude: "-1.30011150",
        longitude: "31.26263660",
        nameNative: null,
        label: "Kagera",
        cityCode: "TZ_05",
      },
      {
        name: "Katavi",
        countryCode: "TZ",
        latitude: "-6.36771250",
        longitude: "31.26263660",
        nameNative: null,
        label: "Katavi",
        cityCode: "TZ_28",
      },
      {
        name: "Kigoma",
        countryCode: "TZ",
        latitude: "-4.88240920",
        longitude: "29.66150550",
        nameNative: null,
        label: "Kigoma",
        cityCode: "TZ_08",
      },
      {
        name: "Kilimanjaro",
        countryCode: "TZ",
        latitude: "-4.13369270",
        longitude: "37.80876930",
        nameNative: null,
        label: "Kilimanjaro",
        cityCode: "TZ_09",
      },
      {
        name: "Lindi",
        countryCode: "TZ",
        latitude: "-9.23433940",
        longitude: "38.31657250",
        nameNative: null,
        label: "Lindi",
        cityCode: "TZ_12",
      },
      {
        name: "Manyara",
        countryCode: "TZ",
        latitude: "-4.31500580",
        longitude: "36.95410700",
        nameNative: null,
        label: "Manyara",
        cityCode: "TZ_26",
      },
      {
        name: "Mara",
        countryCode: "TZ",
        latitude: "-1.77535380",
        longitude: "34.15319470",
        nameNative: null,
        label: "Mara",
        cityCode: "TZ_13",
      },
      {
        name: "Mbeya",
        countryCode: "TZ",
        latitude: "-8.28661120",
        longitude: "32.81325370",
        nameNative: null,
        label: "Mbeya",
        cityCode: "TZ_14",
      },
      {
        name: "Morogoro",
        countryCode: "TZ",
        latitude: "-8.81371730",
        longitude: "36.95410700",
        nameNative: null,
        label: "Morogoro",
        cityCode: "TZ_16",
      },
      {
        name: "Mtwara",
        countryCode: "TZ",
        latitude: "-10.33984550",
        longitude: "40.16574660",
        nameNative: null,
        label: "Mtwara",
        cityCode: "TZ_17",
      },
      {
        name: "Mwanza",
        countryCode: "TZ",
        latitude: "-2.46711970",
        longitude: "32.89868120",
        nameNative: null,
        label: "Mwanza",
        cityCode: "TZ_18",
      },
      {
        name: "Njombe",
        countryCode: "TZ",
        latitude: "-9.24226320",
        longitude: "35.12687810",
        nameNative: null,
        label: "Njombe",
        cityCode: "TZ_29",
      },
      {
        name: "Pemba North",
        countryCode: "TZ",
        latitude: "-5.03193520",
        longitude: "39.77555710",
        nameNative: null,
        label: "Pemba North",
        cityCode: "TZ_06",
      },
      {
        name: "Pemba South",
        countryCode: "TZ",
        latitude: "-5.31469610",
        longitude: "39.75495110",
        nameNative: null,
        label: "Pemba South",
        cityCode: "TZ_10",
      },
      {
        name: "Pwani",
        countryCode: "TZ",
        latitude: "-7.32377140",
        longitude: "38.82054540",
        nameNative: null,
        label: "Pwani",
        cityCode: "TZ_19",
      },
      {
        name: "Rukwa",
        countryCode: "TZ",
        latitude: "-8.01094440",
        longitude: "31.44561790",
        nameNative: null,
        label: "Rukwa",
        cityCode: "TZ_20",
      },
      {
        name: "Ruvuma",
        countryCode: "TZ",
        latitude: "-10.68787170",
        longitude: "36.26308460",
        nameNative: null,
        label: "Ruvuma",
        cityCode: "TZ_21",
      },
      {
        name: "Shinyanga",
        countryCode: "TZ",
        latitude: "-3.68099610",
        longitude: "33.42714030",
        nameNative: null,
        label: "Shinyanga",
        cityCode: "TZ_22",
      },
      {
        name: "Simiyu",
        countryCode: "TZ",
        latitude: "-2.83087380",
        longitude: "34.15319470",
        nameNative: null,
        label: "Simiyu",
        cityCode: "TZ_30",
      },
      {
        name: "Singida",
        countryCode: "TZ",
        latitude: "-6.74533520",
        longitude: "34.15319470",
        nameNative: null,
        label: "Singida",
        cityCode: "TZ_23",
      },
      {
        name: "Songwe",
        countryCode: "TZ",
        latitude: "-8.27261200",
        longitude: "31.71131740",
        nameNative: null,
        label: "Songwe",
        cityCode: "TZ_31",
      },
      {
        name: "Tabora",
        countryCode: "TZ",
        latitude: "-5.03421380",
        longitude: "32.80844960",
        nameNative: null,
        label: "Tabora",
        cityCode: "TZ_24",
      },
      {
        name: "Tanga",
        countryCode: "TZ",
        latitude: "-5.30497890",
        longitude: "38.31657250",
        nameNative: null,
        label: "Tanga",
        cityCode: "TZ_25",
      },
      {
        name: "Zanzibar North",
        countryCode: "TZ",
        latitude: "-5.93950930",
        longitude: "39.27910110",
        nameNative: null,
        label: "Zanzibar North",
        cityCode: "TZ_07",
      },
      {
        name: "Zanzibar South",
        countryCode: "TZ",
        latitude: "-6.26428510",
        longitude: "39.44502810",
        nameNative: null,
        label: "Zanzibar South",
        cityCode: "TZ_11",
      },
      {
        name: "Zanzibar West",
        countryCode: "TZ",
        latitude: "-6.22981360",
        longitude: "39.25832930",
        nameNative: null,
        label: "Zanzibar West",
        cityCode: "TZ_15",
      },
    ],
    TH: [
      {
        name: "Amnat Charoen",
        countryCode: "TH",
        latitude: "15.86567830",
        longitude: "104.62577740",
        nameNative: "อำนาจเจริญ",
        label: "อำนาจเจริญ / Amnat Charoen",
        cityCode: "TH_37",
      },
      {
        name: "Ang Thong",
        countryCode: "TH",
        latitude: "14.58960540",
        longitude: "100.45505200",
        nameNative: "อ่างทอง",
        label: "อ่างทอง / Ang Thong",
        cityCode: "TH_15",
      },
      {
        name: "Bangkok",
        countryCode: "TH",
        latitude: "13.75633090",
        longitude: "100.50176510",
        nameNative: "กรุงเทพมหานคร",
        label: "กรุงเทพมหานคร / Bangkok",
        cityCode: "TH_10",
      },
      {
        name: "Bueng Kan",
        countryCode: "TH",
        latitude: "18.36091040",
        longitude: "103.64644630",
        nameNative: "บึงกาฬ",
        label: "บึงกาฬ / Bueng Kan",
        cityCode: "TH_38",
      },
      {
        name: "Buri Ram",
        countryCode: "TH",
        latitude: "14.99510030",
        longitude: "103.11159150",
        nameNative: "บุรีรัมย์",
        label: "บุรีรัมย์ / Buri Ram",
        cityCode: "TH_31",
      },
      {
        name: "Chachoengsao",
        countryCode: "TH",
        latitude: "13.69041940",
        longitude: "101.07795960",
        nameNative: "ฉะเชิงเทรา",
        label: "ฉะเชิงเทรา / Chachoengsao",
        cityCode: "TH_24",
      },
      {
        name: "Chai Nat",
        countryCode: "TH",
        latitude: "15.18519710",
        longitude: "100.12512500",
        nameNative: "ชัยนาท",
        label: "ชัยนาท / Chai Nat",
        cityCode: "TH_18",
      },
      {
        name: "Chaiyaphum",
        countryCode: "TH",
        latitude: "16.00749740",
        longitude: "101.61291720",
        nameNative: "ชัยภูมิ",
        label: "ชัยภูมิ / Chaiyaphum",
        cityCode: "TH_36",
      },
      {
        name: "Chanthaburi",
        countryCode: "TH",
        latitude: "12.61124850",
        longitude: "102.10378060",
        nameNative: "จันทบุรี",
        label: "จันทบุรี / Chanthaburi",
        cityCode: "TH_22",
      },
      {
        name: "Chiang Mai",
        countryCode: "TH",
        latitude: "18.78834390",
        longitude: "98.98530080",
        nameNative: "เชียงใหม่",
        label: "เชียงใหม่ / Chiang Mai",
        cityCode: "TH_50",
      },
      {
        name: "Chiang Rai",
        countryCode: "TH",
        latitude: "19.91047980",
        longitude: "99.84057600",
        nameNative: "เชียงราย",
        label: "เชียงราย / Chiang Rai",
        cityCode: "TH_57",
      },
      {
        name: "Chon Buri",
        countryCode: "TH",
        latitude: "13.36114310",
        longitude: "100.98467170",
        nameNative: "ชลบุรี",
        label: "ชลบุรี / Chon Buri",
        cityCode: "TH_20",
      },
      {
        name: "Chumphon",
        countryCode: "TH",
        latitude: "10.49304960",
        longitude: "99.18001990",
        nameNative: "ชุมพร",
        label: "ชุมพร / Chumphon",
        cityCode: "TH_86",
      },
      {
        name: "Kalasin",
        countryCode: "TH",
        latitude: "16.43850800",
        longitude: "103.50609940",
        nameNative: "กาฬสินธุ์",
        label: "กาฬสินธุ์ / Kalasin",
        cityCode: "TH_46",
      },
      {
        name: "Kamphaeng Phet",
        countryCode: "TH",
        latitude: "16.48277980",
        longitude: "99.52266180",
        nameNative: "กำแพงเพชร",
        label: "กำแพงเพชร / Kamphaeng Phet",
        cityCode: "TH_62",
      },
      {
        name: "Kanchanaburi",
        countryCode: "TH",
        latitude: "14.10113930",
        longitude: "99.41794310",
        nameNative: "กาญจนบุรี",
        label: "กาญจนบุรี / Kanchanaburi",
        cityCode: "TH_71",
      },
      {
        name: "Khon Kaen",
        countryCode: "TH",
        latitude: "16.43219380",
        longitude: "102.82362140",
        nameNative: "ขอนแก่น",
        label: "ขอนแก่น / Khon Kaen",
        cityCode: "TH_40",
      },
      {
        name: "Krabi",
        countryCode: "TH",
        latitude: "8.08629970",
        longitude: "98.90628350",
        nameNative: "กระบี่",
        label: "กระบี่ / Krabi",
        cityCode: "TH_81",
      },
      {
        name: "Lampang",
        countryCode: "TH",
        latitude: "18.28553950",
        longitude: "99.51278950",
        nameNative: "ลำปาง",
        label: "ลำปาง / Lampang",
        cityCode: "TH_52",
      },
      {
        name: "Lamphun",
        countryCode: "TH",
        latitude: "18.57446060",
        longitude: "99.00872210",
        nameNative: "ลำพูน",
        label: "ลำพูน / Lamphun",
        cityCode: "TH_51",
      },
      {
        name: "Loei",
        countryCode: "TH",
        latitude: "17.48602320",
        longitude: "101.72230020",
        nameNative: "เลย",
        label: "เลย / Loei",
        cityCode: "TH_42",
      },
      {
        name: "Lop Buri",
        countryCode: "TH",
        latitude: "14.79950810",
        longitude: "100.65337060",
        nameNative: "ลพบุรี",
        label: "ลพบุรี / Lop Buri",
        cityCode: "TH_16",
      },
      {
        name: "Mae Hong Son",
        countryCode: "TH",
        latitude: "19.30202960",
        longitude: "97.96543680",
        nameNative: "แม่ฮ่องสอน",
        label: "แม่ฮ่องสอน / Mae Hong Son",
        cityCode: "TH_58",
      },
      {
        name: "Maha Sarakham",
        countryCode: "TH",
        latitude: "16.01320150",
        longitude: "103.16151690",
        nameNative: "มหาสารคาม",
        label: "มหาสารคาม / Maha Sarakham",
        cityCode: "TH_44",
      },
      {
        name: "Mukdahan",
        countryCode: "TH",
        latitude: "16.54359140",
        longitude: "104.70241210",
        nameNative: "มุกดาหาร",
        label: "มุกดาหาร / Mukdahan",
        cityCode: "TH_49",
      },
      {
        name: "Nakhon Nayok",
        countryCode: "TH",
        latitude: "14.20694660",
        longitude: "101.21305110",
        nameNative: "นครนายก",
        label: "นครนายก / Nakhon Nayok",
        cityCode: "TH_26",
      },
      {
        name: "Nakhon Pathom",
        countryCode: "TH",
        latitude: "13.81402930",
        longitude: "100.03729290",
        nameNative: "นครปฐม",
        label: "นครปฐม / Nakhon Pathom",
        cityCode: "TH_73",
      },
      {
        name: "Nakhon Phanom",
        countryCode: "TH",
        latitude: "17.39203900",
        longitude: "104.76955080",
        nameNative: "นครพนม",
        label: "นครพนม / Nakhon Phanom",
        cityCode: "TH_48",
      },
      {
        name: "Nakhon Ratchasima",
        countryCode: "TH",
        latitude: "14.97384930",
        longitude: "102.08365200",
        nameNative: "นครราชสีมา",
        label: "นครราชสีมา / Nakhon Ratchasima",
        cityCode: "TH_30",
      },
      {
        name: "Nakhon Sawan",
        countryCode: "TH",
        latitude: "15.69873820",
        longitude: "100.11996000",
        nameNative: "นครสวรรค์",
        label: "นครสวรรค์ / Nakhon Sawan",
        cityCode: "TH_60",
      },
      {
        name: "Nakhon Si Thammarat",
        countryCode: "TH",
        latitude: "8.43248310",
        longitude: "99.95990330",
        nameNative: "นครศรีธรรมราช",
        label: "นครศรีธรรมราช / Nakhon Si Thammarat",
        cityCode: "TH_80",
      },
      {
        name: "Nan",
        countryCode: "TH",
        latitude: "45.52220800",
        longitude: "-122.98632810",
        nameNative: "น่าน",
        label: "น่าน / Nan",
        cityCode: "TH_55",
      },
      {
        name: "Narathiwat",
        countryCode: "TH",
        latitude: "6.42546070",
        longitude: "101.82531430",
        nameNative: "นราธิวาส",
        label: "นราธิวาส / Narathiwat",
        cityCode: "TH_96",
      },
      {
        name: "Nong Bua Lam Phu",
        countryCode: "TH",
        latitude: "17.22182470",
        longitude: "102.42603680",
        nameNative: "หนองบัวลำภู",
        label: "หนองบัวลำภู / Nong Bua Lam Phu",
        cityCode: "TH_39",
      },
      {
        name: "Nong Khai",
        countryCode: "TH",
        latitude: "17.87828030",
        longitude: "102.74126380",
        nameNative: "หนองคาย",
        label: "หนองคาย / Nong Khai",
        cityCode: "TH_43",
      },
      {
        name: "Nonthaburi",
        countryCode: "TH",
        latitude: "13.85910840",
        longitude: "100.52165080",
        nameNative: "นนทบุรี",
        label: "นนทบุรี / Nonthaburi",
        cityCode: "TH_12",
      },
      {
        name: "Pathum Thani",
        countryCode: "TH",
        latitude: "14.02083910",
        longitude: "100.52502760",
        nameNative: "ปทุมธานี",
        label: "ปทุมธานี / Pathum Thani",
        cityCode: "TH_13",
      },
      {
        name: "Pattani",
        countryCode: "TH",
        latitude: "6.76183080",
        longitude: "101.32325490",
        nameNative: "ปัตตานี",
        label: "ปัตตานี / Pattani",
        cityCode: "TH_94",
      },
      {
        name: "Pattaya",
        countryCode: "TH",
        latitude: "12.92355570",
        longitude: "100.88245510",
        nameNative: "พัทยา",
        label: "พัทยา / Pattaya",
        cityCode: "TH_S",
      },
      {
        name: "Phangnga",
        countryCode: "TH",
        latitude: "8.45014140",
        longitude: "98.52553170",
        nameNative: "พังงา",
        label: "พังงา / Phangnga",
        cityCode: "TH_82",
      },
      {
        name: "Phatthalung",
        countryCode: "TH",
        latitude: "7.61668230",
        longitude: "100.07402310",
        nameNative: "พัทลุง",
        label: "พัทลุง / Phatthalung",
        cityCode: "TH_93",
      },
      {
        name: "Phayao",
        countryCode: "TH",
        latitude: "19.21543670",
        longitude: "100.20236920",
        nameNative: "พะเยา",
        label: "พะเยา / Phayao",
        cityCode: "TH_56",
      },
      {
        name: "Phetchabun",
        countryCode: "TH",
        latitude: "16.30166900",
        longitude: "101.11928040",
        nameNative: "เพชรบูรณ์",
        label: "เพชรบูรณ์ / Phetchabun",
        cityCode: "TH_67",
      },
      {
        name: "Phetchaburi",
        countryCode: "TH",
        latitude: "12.96492150",
        longitude: "99.64258830",
        nameNative: "เพชรบุรี",
        label: "เพชรบุรี / Phetchaburi",
        cityCode: "TH_76",
      },
      {
        name: "Phichit",
        countryCode: "TH",
        latitude: "16.27408760",
        longitude: "100.33469910",
        nameNative: "พิจิตร",
        label: "พิจิตร / Phichit",
        cityCode: "TH_66",
      },
      {
        name: "Phitsanulok",
        countryCode: "TH",
        latitude: "16.82112380",
        longitude: "100.26585160",
        nameNative: "พิษณุโลก",
        label: "พิษณุโลก / Phitsanulok",
        cityCode: "TH_65",
      },
      {
        name: "Phra Nakhon Si Ayutthaya",
        countryCode: "TH",
        latitude: "14.36923250",
        longitude: "100.58766340",
        nameNative: "พระนครศรีอยุธยา",
        label: "พระนครศรีอยุธยา / Phra Nakhon Si Ayutthaya",
        cityCode: "TH_14",
      },
      {
        name: "Phrae",
        countryCode: "TH",
        latitude: "18.14457740",
        longitude: "100.14028310",
        nameNative: "แพร่",
        label: "แพร่ / Phrae",
        cityCode: "TH_54",
      },
      {
        name: "Phuket",
        countryCode: "TH",
        latitude: "7.88044790",
        longitude: "98.39225040",
        nameNative: "ภูเก็ต",
        label: "ภูเก็ต / Phuket",
        cityCode: "TH_83",
      },
      {
        name: "Prachin Buri",
        countryCode: "TH",
        latitude: "14.04206990",
        longitude: "101.66008740",
        nameNative: "ปราจีนบุรี",
        label: "ปราจีนบุรี / Prachin Buri",
        cityCode: "TH_25",
      },
      {
        name: "Prachuap Khiri Khan",
        countryCode: "TH",
        latitude: "11.79383890",
        longitude: "99.79575640",
        nameNative: "ประจวบคีรีขันธ์",
        label: "ประจวบคีรีขันธ์ / Prachuap Khiri Khan",
        cityCode: "TH_77",
      },
      {
        name: "Ranong",
        countryCode: "TH",
        latitude: "9.95287020",
        longitude: "98.60846410",
        nameNative: "ระนอง",
        label: "ระนอง / Ranong",
        cityCode: "TH_85",
      },
      {
        name: "Ratchaburi",
        countryCode: "TH",
        latitude: "13.52828930",
        longitude: "99.81342110",
        nameNative: "ราชบุรี",
        label: "ราชบุรี / Ratchaburi",
        cityCode: "TH_70",
      },
      {
        name: "Rayong",
        countryCode: "TH",
        latitude: "12.68139570",
        longitude: "101.28162610",
        nameNative: "ระยอง",
        label: "ระยอง / Rayong",
        cityCode: "TH_21",
      },
      {
        name: "Roi Et",
        countryCode: "TH",
        latitude: "16.05381960",
        longitude: "103.65200360",
        nameNative: "ร้อยเอ็ด",
        label: "ร้อยเอ็ด / Roi Et",
        cityCode: "TH_45",
      },
      {
        name: "Sa Kaeo",
        countryCode: "TH",
        latitude: "13.82403800",
        longitude: "102.06458390",
        nameNative: "สระแก้ว",
        label: "สระแก้ว / Sa Kaeo",
        cityCode: "TH_27",
      },
      {
        name: "Sakon Nakhon",
        countryCode: "TH",
        latitude: "17.16642110",
        longitude: "104.14860550",
        nameNative: "สกลนคร",
        label: "สกลนคร / Sakon Nakhon",
        cityCode: "TH_47",
      },
      {
        name: "Samut Prakan",
        countryCode: "TH",
        latitude: "13.59909610",
        longitude: "100.59983190",
        nameNative: "สมุทรปราการ",
        label: "สมุทรปราการ / Samut Prakan",
        cityCode: "TH_11",
      },
      {
        name: "Samut Sakhon",
        countryCode: "TH",
        latitude: "13.54752160",
        longitude: "100.27439560",
        nameNative: "สมุทรสาคร",
        label: "สมุทรสาคร / Samut Sakhon",
        cityCode: "TH_74",
      },
      {
        name: "Samut Songkhram",
        countryCode: "TH",
        latitude: "13.40982170",
        longitude: "100.00226450",
        nameNative: "สมุทรสงคราม",
        label: "สมุทรสงคราม / Samut Songkhram",
        cityCode: "TH_75",
      },
      {
        name: "Saraburi",
        countryCode: "TH",
        latitude: "14.52891540",
        longitude: "100.91014210",
        nameNative: "สระบุรี",
        label: "สระบุรี / Saraburi",
        cityCode: "TH_19",
      },
      {
        name: "Satun",
        countryCode: "TH",
        latitude: "6.62381580",
        longitude: "100.06737440",
        nameNative: "สตูล",
        label: "สตูล / Satun",
        cityCode: "TH_91",
      },
      {
        name: "Si Sa Ket",
        countryCode: "TH",
        latitude: "15.11860090",
        longitude: "104.32200950",
        nameNative: "ศรีสะเกษ",
        label: "ศรีสะเกษ / Si Sa Ket",
        cityCode: "TH_33",
      },
      {
        name: "Sing Buri",
        countryCode: "TH",
        latitude: "14.89362530",
        longitude: "100.39673140",
        nameNative: "สิงห์บุรี",
        label: "สิงห์บุรี / Sing Buri",
        cityCode: "TH_17",
      },
      {
        name: "Songkhla",
        countryCode: "TH",
        latitude: "7.18976590",
        longitude: "100.59538130",
        nameNative: "สงขลา",
        label: "สงขลา / Songkhla",
        cityCode: "TH_90",
      },
      {
        name: "Sukhothai",
        countryCode: "TH",
        latitude: "43.64855560",
        longitude: "-79.37466390",
        nameNative: "สุโขทัย",
        label: "สุโขทัย / Sukhothai",
        cityCode: "TH_64",
      },
      {
        name: "Suphan Buri",
        countryCode: "TH",
        latitude: "14.47448920",
        longitude: "100.11771280",
        nameNative: "สุพรรณบุรี",
        label: "สุพรรณบุรี / Suphan Buri",
        cityCode: "TH_72",
      },
      {
        name: "Surat Thani",
        countryCode: "TH",
        latitude: "9.13419490",
        longitude: "99.33341980",
        nameNative: "สุราษฎร์ธานี",
        label: "สุราษฎร์ธานี / Surat Thani",
        cityCode: "TH_84",
      },
      {
        name: "Surin",
        countryCode: "TH",
        latitude: "37.03582710",
        longitude: "-95.62763670",
        nameNative: "สุรินทร์",
        label: "สุรินทร์ / Surin",
        cityCode: "TH_32",
      },
      {
        name: "Tak",
        countryCode: "TH",
        latitude: "45.02996460",
        longitude: "-93.10498150",
        nameNative: "ตาก",
        label: "ตาก / Tak",
        cityCode: "TH_63",
      },
      {
        name: "Trang",
        countryCode: "TH",
        latitude: "7.56448330",
        longitude: "99.62393340",
        nameNative: "ตรัง",
        label: "ตรัง / Trang",
        cityCode: "TH_92",
      },
      {
        name: "Trat",
        countryCode: "TH",
        latitude: "12.24275630",
        longitude: "102.51747340",
        nameNative: "ตราด",
        label: "ตราด / Trat",
        cityCode: "TH_23",
      },
      {
        name: "Ubon Ratchathani",
        countryCode: "TH",
        latitude: "15.24484530",
        longitude: "104.84729950",
        nameNative: "อุบลราชธานี",
        label: "อุบลราชธานี / Ubon Ratchathani",
        cityCode: "TH_34",
      },
      {
        name: "Udon Thani",
        countryCode: "TH",
        latitude: "17.36469690",
        longitude: "102.81589240",
        nameNative: "อุดรธานี",
        label: "อุดรธานี / Udon Thani",
        cityCode: "TH_41",
      },
      {
        name: "Uthai Thani",
        countryCode: "TH",
        latitude: "15.38350010",
        longitude: "100.02455270",
        nameNative: "อุทัยธานี",
        label: "อุทัยธานี / Uthai Thani",
        cityCode: "TH_61",
      },
      {
        name: "Uttaradit",
        countryCode: "TH",
        latitude: "17.62008860",
        longitude: "100.09929420",
        nameNative: "อุตรดิตถ์",
        label: "อุตรดิตถ์ / Uttaradit",
        cityCode: "TH_53",
      },
      {
        name: "Yala",
        countryCode: "TH",
        latitude: "44.05791170",
        longitude: "-123.16538480",
        nameNative: "ยะลา",
        label: "ยะลา / Yala",
        cityCode: "TH_95",
      },
      {
        name: "Yasothon",
        countryCode: "TH",
        latitude: "15.79264100",
        longitude: "104.14528270",
        nameNative: "ยโสธร",
        label: "ยโสธร / Yasothon",
        cityCode: "TH_35",
      },
    ],
    TG: [
      {
        name: "Centrale Region",
        countryCode: "TG",
        latitude: "8.65860290",
        longitude: "1.05861350",
        nameNative: null,
        label: "Centrale Region",
        cityCode: "TG_C",
      },
      {
        name: "Kara Region",
        countryCode: "TG",
        latitude: "9.72163930",
        longitude: "1.05861350",
        nameNative: null,
        label: "Kara Region",
        cityCode: "TG_K",
      },
      {
        name: "Maritime",
        countryCode: "TG",
        latitude: "41.65514930",
        longitude: "-83.52784670",
        nameNative: null,
        label: "Maritime",
        cityCode: "TG_M",
      },
      {
        name: "Plateaux Region",
        countryCode: "TG",
        latitude: "7.61013780",
        longitude: "1.05861350",
        nameNative: null,
        label: "Plateaux Region",
        cityCode: "TG_P",
      },
      {
        name: "Savanes Region",
        countryCode: "TG",
        latitude: "10.52917810",
        longitude: "0.52578230",
        nameNative: null,
        label: "Savanes Region",
        cityCode: "TG_S",
      },
    ],
    TO: [
      {
        name: "Haʻapai",
        countryCode: "TO",
        latitude: "-19.75000000",
        longitude: "-174.36666700",
        nameNative: null,
        label: "Haʻapai",
        cityCode: "TO_02",
      },
      {
        name: "Niuas",
        countryCode: "TO",
        latitude: "-15.95940000",
        longitude: "-173.78300000",
        nameNative: null,
        label: "Niuas",
        cityCode: "TO_03",
      },
      {
        name: "Tongatapu",
        countryCode: "TO",
        latitude: "-21.14659680",
        longitude: "-175.25154820",
        nameNative: null,
        label: "Tongatapu",
        cityCode: "TO_04",
      },
      {
        name: "Vavaʻu",
        countryCode: "TO",
        latitude: "-18.62275600",
        longitude: "-173.99029820",
        nameNative: null,
        label: "Vavaʻu",
        cityCode: "TO_05",
      },
      {
        name: "ʻEua",
        countryCode: "TO",
        latitude: "37.09024000",
        longitude: "-95.71289100",
        nameNative: null,
        label: "ʻEua",
        cityCode: "TO_01",
      },
    ],
    TT: [
      {
        name: "Arima",
        countryCode: "TT",
        latitude: "46.79316040",
        longitude: "-71.25843110",
        nameNative: null,
        label: "Arima",
        cityCode: "TT_ARI",
      },
      {
        name: "Chaguanas",
        countryCode: "TT",
        latitude: "10.51683870",
        longitude: "-61.41144820",
        nameNative: null,
        label: "Chaguanas",
        cityCode: "TT_CHA",
      },
      {
        name: "Couva-Tabaquite-Talparo Regional Corporation",
        countryCode: "TT",
        latitude: "10.42971450",
        longitude: "-61.37352100",
        nameNative: null,
        label: "Couva-Tabaquite-Talparo Regional Corporation",
        cityCode: "TT_CTT",
      },
      {
        name: "Diego Martin Regional Corporation",
        countryCode: "TT",
        latitude: "10.73622860",
        longitude: "-61.55448360",
        nameNative: null,
        label: "Diego Martin Regional Corporation",
        cityCode: "TT_DMN",
      },
      {
        name: "Eastern Tobago",
        countryCode: "TT",
        latitude: "11.29793480",
        longitude: "-60.55885240",
        nameNative: null,
        label: "Eastern Tobago",
        cityCode: "TT_ETO",
      },
      {
        name: "Penal-Debe Regional Corporation",
        countryCode: "TT",
        latitude: "10.13374020",
        longitude: "-61.44354740",
        nameNative: null,
        label: "Penal-Debe Regional Corporation",
        cityCode: "TT_PED",
      },
      {
        name: "Point Fortin",
        countryCode: "TT",
        latitude: "10.17027370",
        longitude: "-61.67133860",
        nameNative: null,
        label: "Point Fortin",
        cityCode: "TT_PTF",
      },
      {
        name: "Port of Spain",
        countryCode: "TT",
        latitude: "10.66031960",
        longitude: "-61.50856250",
        nameNative: null,
        label: "Port of Spain",
        cityCode: "TT_POS",
      },
      {
        name: "Princes Town Regional Corporation",
        countryCode: "TT",
        latitude: "10.17867460",
        longitude: "-61.28019960",
        nameNative: null,
        label: "Princes Town Regional Corporation",
        cityCode: "TT_PRT",
      },
      {
        name: "Rio Claro-Mayaro Regional Corporation",
        countryCode: "TT",
        latitude: "10.24128320",
        longitude: "-61.09372060",
        nameNative: null,
        label: "Rio Claro-Mayaro Regional Corporation",
        cityCode: "TT_MRC",
      },
      {
        name: "San Fernando",
        countryCode: "TT",
        latitude: "34.28194610",
        longitude: "-118.43897190",
        nameNative: null,
        label: "San Fernando",
        cityCode: "TT_SFO",
      },
      {
        name: "San Juan-Laventille Regional Corporation",
        countryCode: "TT",
        latitude: "10.69085780",
        longitude: "-61.45522130",
        nameNative: null,
        label: "San Juan-Laventille Regional Corporation",
        cityCode: "TT_SJL",
      },
      {
        name: "Sangre Grande Regional Corporation",
        countryCode: "TT",
        latitude: "10.58529390",
        longitude: "-61.13158130",
        nameNative: null,
        label: "Sangre Grande Regional Corporation",
        cityCode: "TT_SGE",
      },
      {
        name: "Siparia Regional Corporation",
        countryCode: "TT",
        latitude: "10.12456260",
        longitude: "-61.56032440",
        nameNative: null,
        label: "Siparia Regional Corporation",
        cityCode: "TT_SIP",
      },
      {
        name: "Tunapuna-Piarco Regional Corporation",
        countryCode: "TT",
        latitude: "10.68590960",
        longitude: "-61.30352480",
        nameNative: null,
        label: "Tunapuna-Piarco Regional Corporation",
        cityCode: "TT_TUP",
      },
      {
        name: "Western Tobago",
        countryCode: "TT",
        latitude: "11.18970720",
        longitude: "-60.77954520",
        nameNative: null,
        label: "Western Tobago",
        cityCode: "TT_WTO",
      },
    ],
    TN: [
      {
        name: "Ariana Governorate",
        countryCode: "TN",
        latitude: "36.99227510",
        longitude: "10.12551640",
        nameNative: null,
        label: "Ariana Governorate",
        cityCode: "TN_12",
      },
      {
        name: "Ben Arous Governorate",
        countryCode: "TN",
        latitude: "36.64356060",
        longitude: "10.21515780",
        nameNative: null,
        label: "Ben Arous Governorate",
        cityCode: "TN_13",
      },
      {
        name: "Bizerte Governorate",
        countryCode: "TN",
        latitude: "37.16093970",
        longitude: "9.63413500",
        nameNative: null,
        label: "Bizerte Governorate",
        cityCode: "TN_23",
      },
      {
        name: "Gabès Governorate",
        countryCode: "TN",
        latitude: "33.94596480",
        longitude: "9.72326730",
        nameNative: null,
        label: "Gabès Governorate",
        cityCode: "TN_81",
      },
      {
        name: "Gafsa Governorate",
        countryCode: "TN",
        latitude: "34.37885050",
        longitude: "8.66005860",
        nameNative: null,
        label: "Gafsa Governorate",
        cityCode: "TN_71",
      },
      {
        name: "Jendouba Governorate",
        countryCode: "TN",
        latitude: "36.71818620",
        longitude: "8.74811670",
        nameNative: null,
        label: "Jendouba Governorate",
        cityCode: "TN_32",
      },
      {
        name: "Kairouan Governorate",
        countryCode: "TN",
        latitude: "35.67116630",
        longitude: "10.10054690",
        nameNative: null,
        label: "Kairouan Governorate",
        cityCode: "TN_41",
      },
      {
        name: "Kasserine Governorate",
        countryCode: "TN",
        latitude: "35.08091480",
        longitude: "8.66005860",
        nameNative: null,
        label: "Kasserine Governorate",
        cityCode: "TN_42",
      },
      {
        name: "Kassrine",
        countryCode: "TN",
        latitude: "35.17227160",
        longitude: "8.83076260",
        nameNative: null,
        label: "Kassrine",
        cityCode: "TN_31",
      },
      {
        name: "Kebili Governorate",
        countryCode: "TN",
        latitude: "33.70715510",
        longitude: "8.97146230",
        nameNative: null,
        label: "Kebili Governorate",
        cityCode: "TN_73",
      },
      {
        name: "Kef Governorate",
        countryCode: "TN",
        latitude: "36.12305120",
        longitude: "8.66005860",
        nameNative: null,
        label: "Kef Governorate",
        cityCode: "TN_33",
      },
      {
        name: "Mahdia Governorate",
        countryCode: "TN",
        latitude: "35.33525580",
        longitude: "10.89030990",
        nameNative: null,
        label: "Mahdia Governorate",
        cityCode: "TN_53",
      },
      {
        name: "Manouba Governorate",
        countryCode: "TN",
        latitude: "36.84465040",
        longitude: "9.85714160",
        nameNative: null,
        label: "Manouba Governorate",
        cityCode: "TN_14",
      },
      {
        name: "Medenine Governorate",
        countryCode: "TN",
        latitude: "33.22805650",
        longitude: "10.89030990",
        nameNative: null,
        label: "Medenine Governorate",
        cityCode: "TN_82",
      },
      {
        name: "Monastir Governorate",
        countryCode: "TN",
        latitude: "35.76425150",
        longitude: "10.81128850",
        nameNative: null,
        label: "Monastir Governorate",
        cityCode: "TN_52",
      },
      {
        name: "Sfax Governorate",
        countryCode: "TN",
        latitude: "34.86065810",
        longitude: "10.34978950",
        nameNative: null,
        label: "Sfax Governorate",
        cityCode: "TN_61",
      },
      {
        name: "Sidi Bouzid Governorate",
        countryCode: "TN",
        latitude: "35.03543860",
        longitude: "9.48393920",
        nameNative: null,
        label: "Sidi Bouzid Governorate",
        cityCode: "TN_43",
      },
      {
        name: "Siliana Governorate",
        countryCode: "TN",
        latitude: "36.08872080",
        longitude: "9.36453350",
        nameNative: null,
        label: "Siliana Governorate",
        cityCode: "TN_34",
      },
      {
        name: "Sousse Governorate",
        countryCode: "TN",
        latitude: "35.90222670",
        longitude: "10.34978950",
        nameNative: null,
        label: "Sousse Governorate",
        cityCode: "TN_51",
      },
      {
        name: "Tataouine Governorate",
        countryCode: "TN",
        latitude: "32.13441220",
        longitude: "10.08072980",
        nameNative: null,
        label: "Tataouine Governorate",
        cityCode: "TN_83",
      },
      {
        name: "Tozeur Governorate",
        countryCode: "TN",
        latitude: "33.97894910",
        longitude: "8.04651850",
        nameNative: null,
        label: "Tozeur Governorate",
        cityCode: "TN_72",
      },
      {
        name: "Tunis Governorate",
        countryCode: "TN",
        latitude: "36.83749460",
        longitude: "10.19273890",
        nameNative: null,
        label: "Tunis Governorate",
        cityCode: "TN_11",
      },
      {
        name: "Zaghouan Governorate",
        countryCode: "TN",
        latitude: "36.40911880",
        longitude: "10.14231720",
        nameNative: null,
        label: "Zaghouan Governorate",
        cityCode: "TN_22",
      },
    ],
    TR: [
      {
        name: "Adana",
        countryCode: "TR",
        latitude: "37.26123150",
        longitude: "35.39050460",
        nameNative: null,
        label: "Adana",
        cityCode: "TR_01",
      },
      {
        name: "Adıyaman",
        countryCode: "TR",
        latitude: "37.90782910",
        longitude: "38.48499230",
        nameNative: null,
        label: "Adıyaman",
        cityCode: "TR_02",
      },
      {
        name: "Afyonkarahisar",
        countryCode: "TR",
        latitude: "38.73910990",
        longitude: "30.71200230",
        nameNative: null,
        label: "Afyonkarahisar",
        cityCode: "TR_03",
      },
      {
        name: "Aksaray",
        countryCode: "TR",
        latitude: "38.33520430",
        longitude: "33.97500180",
        nameNative: null,
        label: "Aksaray",
        cityCode: "TR_68",
      },
      {
        name: "Amasya",
        countryCode: "TR",
        latitude: "40.65166080",
        longitude: "35.90379660",
        nameNative: null,
        label: "Amasya",
        cityCode: "TR_05",
      },
      {
        name: "Ankara",
        countryCode: "TR",
        latitude: "39.78052450",
        longitude: "32.71813750",
        nameNative: null,
        label: "Ankara",
        cityCode: "TR_06",
      },
      {
        name: "Antalya",
        countryCode: "TR",
        latitude: "37.09516720",
        longitude: "31.07937050",
        nameNative: null,
        label: "Antalya",
        cityCode: "TR_07",
      },
      {
        name: "Ardahan",
        countryCode: "TR",
        latitude: "41.11129640",
        longitude: "42.78316740",
        nameNative: null,
        label: "Ardahan",
        cityCode: "TR_75",
      },
      {
        name: "Artvin",
        countryCode: "TR",
        latitude: "41.07866400",
        longitude: "41.76282230",
        nameNative: null,
        label: "Artvin",
        cityCode: "TR_08",
      },
      {
        name: "Aydın",
        countryCode: "TR",
        latitude: "37.81170330",
        longitude: "28.48639630",
        nameNative: null,
        label: "Aydın",
        cityCode: "TR_09",
      },
      {
        name: "Ağrı",
        countryCode: "TR",
        latitude: "39.62692180",
        longitude: "43.02159650",
        nameNative: null,
        label: "Ağrı",
        cityCode: "TR_04",
      },
      {
        name: "Balıkesir",
        countryCode: "TR",
        latitude: "39.76167820",
        longitude: "28.11226790",
        nameNative: null,
        label: "Balıkesir",
        cityCode: "TR_10",
      },
      {
        name: "Bartın",
        countryCode: "TR",
        latitude: "41.58105090",
        longitude: "32.46097940",
        nameNative: null,
        label: "Bartın",
        cityCode: "TR_74",
      },
      {
        name: "Batman",
        countryCode: "TR",
        latitude: "37.83624960",
        longitude: "41.36057390",
        nameNative: null,
        label: "Batman",
        cityCode: "TR_72",
      },
      {
        name: "Bayburt",
        countryCode: "TR",
        latitude: "40.26032000",
        longitude: "40.22804800",
        nameNative: null,
        label: "Bayburt",
        cityCode: "TR_69",
      },
      {
        name: "Bilecik",
        countryCode: "TR",
        latitude: "40.05665550",
        longitude: "30.06652360",
        nameNative: null,
        label: "Bilecik",
        cityCode: "TR_11",
      },
      {
        name: "Bingöl",
        countryCode: "TR",
        latitude: "39.06263540",
        longitude: "40.76960950",
        nameNative: null,
        label: "Bingöl",
        cityCode: "TR_12",
      },
      {
        name: "Bitlis",
        countryCode: "TR",
        latitude: "38.65231330",
        longitude: "42.42020280",
        nameNative: null,
        label: "Bitlis",
        cityCode: "TR_13",
      },
      {
        name: "Bolu",
        countryCode: "TR",
        latitude: "40.57597660",
        longitude: "31.57880860",
        nameNative: null,
        label: "Bolu",
        cityCode: "TR_14",
      },
      {
        name: "Burdur",
        countryCode: "TR",
        latitude: "37.46126690",
        longitude: "30.06652360",
        nameNative: null,
        label: "Burdur",
        cityCode: "TR_15",
      },
      {
        name: "Bursa",
        countryCode: "TR",
        latitude: "40.06554590",
        longitude: "29.23207840",
        nameNative: null,
        label: "Bursa",
        cityCode: "TR_16",
      },
      {
        name: "Denizli",
        countryCode: "TR",
        latitude: "37.61283950",
        longitude: "29.23207840",
        nameNative: null,
        label: "Denizli",
        cityCode: "TR_20",
      },
      {
        name: "Diyarbakır",
        countryCode: "TR",
        latitude: "38.10663720",
        longitude: "40.54268960",
        nameNative: null,
        label: "Diyarbakır",
        cityCode: "TR_21",
      },
      {
        name: "Düzce",
        countryCode: "TR",
        latitude: "40.87705310",
        longitude: "31.31927130",
        nameNative: null,
        label: "Düzce",
        cityCode: "TR_81",
      },
      {
        name: "Edirne",
        countryCode: "TR",
        latitude: "41.15172220",
        longitude: "26.51379640",
        nameNative: null,
        label: "Edirne",
        cityCode: "TR_22",
      },
      {
        name: "Elazığ",
        countryCode: "TR",
        latitude: "38.49648040",
        longitude: "39.21990290",
        nameNative: null,
        label: "Elazığ",
        cityCode: "TR_23",
      },
      {
        name: "Erzincan",
        countryCode: "TR",
        latitude: "39.76819140",
        longitude: "39.05013060",
        nameNative: null,
        label: "Erzincan",
        cityCode: "TR_24",
      },
      {
        name: "Erzurum",
        countryCode: "TR",
        latitude: "40.07467990",
        longitude: "41.66945620",
        nameNative: null,
        label: "Erzurum",
        cityCode: "TR_25",
      },
      {
        name: "Eskişehir",
        countryCode: "TR",
        latitude: "39.63296570",
        longitude: "31.26263660",
        nameNative: null,
        label: "Eskişehir",
        cityCode: "TR_26",
      },
      {
        name: "Gaziantep",
        countryCode: "TR",
        latitude: "37.07638820",
        longitude: "37.38272340",
        nameNative: null,
        label: "Gaziantep",
        cityCode: "TR_27",
      },
      {
        name: "Giresun",
        countryCode: "TR",
        latitude: "40.64616720",
        longitude: "38.59355110",
        nameNative: null,
        label: "Giresun",
        cityCode: "TR_28",
      },
      {
        name: "Gümüşhane",
        countryCode: "TR",
        latitude: "40.28036730",
        longitude: "39.31432530",
        nameNative: null,
        label: "Gümüşhane",
        cityCode: "TR_29",
      },
      {
        name: "Hakkâri",
        countryCode: "TR",
        latitude: "37.44593190",
        longitude: "43.74498410",
        nameNative: null,
        label: "Hakkâri",
        cityCode: "TR_30",
      },
      {
        name: "Hatay",
        countryCode: "TR",
        latitude: "36.40184880",
        longitude: "36.34980970",
        nameNative: null,
        label: "Hatay",
        cityCode: "TR_31",
      },
      {
        name: "Isparta",
        countryCode: "TR",
        latitude: "38.02114640",
        longitude: "31.07937050",
        nameNative: null,
        label: "Isparta",
        cityCode: "TR_32",
      },
      {
        name: "Istanbul",
        countryCode: "TR",
        latitude: "41.16343020",
        longitude: "28.76644080",
        nameNative: null,
        label: "Istanbul",
        cityCode: "TR_34",
      },
      {
        name: "Iğdır",
        countryCode: "TR",
        latitude: "39.88798410",
        longitude: "44.00483650",
        nameNative: null,
        label: "Iğdır",
        cityCode: "TR_76",
      },
      {
        name: "Kahramanmaraş",
        countryCode: "TR",
        latitude: "37.75030360",
        longitude: "36.95410700",
        nameNative: null,
        label: "Kahramanmaraş",
        cityCode: "TR_46",
      },
      {
        name: "Karabük",
        countryCode: "TR",
        latitude: "41.18748900",
        longitude: "32.74174190",
        nameNative: null,
        label: "Karabük",
        cityCode: "TR_78",
      },
      {
        name: "Karaman",
        countryCode: "TR",
        latitude: "37.24363360",
        longitude: "33.61757700",
        nameNative: null,
        label: "Karaman",
        cityCode: "TR_70",
      },
      {
        name: "Kars",
        countryCode: "TR",
        latitude: "40.28076360",
        longitude: "42.99195270",
        nameNative: null,
        label: "Kars",
        cityCode: "TR_36",
      },
      {
        name: "Kastamonu",
        countryCode: "TR",
        latitude: "41.41038630",
        longitude: "33.69983340",
        nameNative: null,
        label: "Kastamonu",
        cityCode: "TR_37",
      },
      {
        name: "Kayseri",
        countryCode: "TR",
        latitude: "38.62568540",
        longitude: "35.74068820",
        nameNative: null,
        label: "Kayseri",
        cityCode: "TR_38",
      },
      {
        name: "Kilis",
        countryCode: "TR",
        latitude: "36.82047750",
        longitude: "37.16873390",
        nameNative: null,
        label: "Kilis",
        cityCode: "TR_79",
      },
      {
        name: "Kocaeli",
        countryCode: "TR",
        latitude: "40.85327040",
        longitude: "29.88152030",
        nameNative: null,
        label: "Kocaeli",
        cityCode: "TR_41",
      },
      {
        name: "Konya",
        countryCode: "TR",
        latitude: "37.98381340",
        longitude: "32.71813750",
        nameNative: null,
        label: "Konya",
        cityCode: "TR_42",
      },
      {
        name: "Kütahya",
        countryCode: "TR",
        latitude: "39.35813700",
        longitude: "29.60354950",
        nameNative: null,
        label: "Kütahya",
        cityCode: "TR_43",
      },
      {
        name: "Kırklareli",
        countryCode: "TR",
        latitude: "41.72597950",
        longitude: "27.48383900",
        nameNative: null,
        label: "Kırklareli",
        cityCode: "TR_39",
      },
      {
        name: "Kırıkkale",
        countryCode: "TR",
        latitude: "39.88768780",
        longitude: "33.75552480",
        nameNative: null,
        label: "Kırıkkale",
        cityCode: "TR_71",
      },
      {
        name: "Kırşehir",
        countryCode: "TR",
        latitude: "39.22689050",
        longitude: "33.97500180",
        nameNative: null,
        label: "Kırşehir",
        cityCode: "TR_40",
      },
      {
        name: "Malatya",
        countryCode: "TR",
        latitude: "38.40150570",
        longitude: "37.95362980",
        nameNative: null,
        label: "Malatya",
        cityCode: "TR_44",
      },
      {
        name: "Manisa",
        countryCode: "TR",
        latitude: "38.84193730",
        longitude: "28.11226790",
        nameNative: null,
        label: "Manisa",
        cityCode: "TR_45",
      },
      {
        name: "Mardin",
        countryCode: "TR",
        latitude: "37.34429290",
        longitude: "40.61964870",
        nameNative: null,
        label: "Mardin",
        cityCode: "TR_47",
      },
      {
        name: "Mersin",
        countryCode: "TR",
        latitude: "36.81208580",
        longitude: "34.64147500",
        nameNative: null,
        label: "Mersin",
        cityCode: "TR_33",
      },
      {
        name: "Muğla",
        countryCode: "TR",
        latitude: "37.18358190",
        longitude: "28.48639630",
        nameNative: null,
        label: "Muğla",
        cityCode: "TR_48",
      },
      {
        name: "Muş",
        countryCode: "TR",
        latitude: "38.94618880",
        longitude: "41.75389310",
        nameNative: null,
        label: "Muş",
        cityCode: "TR_49",
      },
      {
        name: "Nevşehir",
        countryCode: "TR",
        latitude: "38.69393990",
        longitude: "34.68565090",
        nameNative: null,
        label: "Nevşehir",
        cityCode: "TR_50",
      },
      {
        name: "Niğde",
        countryCode: "TR",
        latitude: "38.09930860",
        longitude: "34.68565090",
        nameNative: null,
        label: "Niğde",
        cityCode: "TR_51",
      },
      {
        name: "Ordu",
        countryCode: "TR",
        latitude: "40.79905800",
        longitude: "37.38990050",
        nameNative: null,
        label: "Ordu",
        cityCode: "TR_52",
      },
      {
        name: "Osmaniye",
        countryCode: "TR",
        latitude: "37.21302580",
        longitude: "36.17626150",
        nameNative: null,
        label: "Osmaniye",
        cityCode: "TR_80",
      },
      {
        name: "Rize",
        countryCode: "TR",
        latitude: "40.95814970",
        longitude: "40.92269850",
        nameNative: null,
        label: "Rize",
        cityCode: "TR_53",
      },
      {
        name: "Sakarya",
        countryCode: "TR",
        latitude: "40.78885500",
        longitude: "30.40595400",
        nameNative: null,
        label: "Sakarya",
        cityCode: "TR_54",
      },
      {
        name: "Samsun",
        countryCode: "TR",
        latitude: "41.18648590",
        longitude: "36.13226780",
        nameNative: null,
        label: "Samsun",
        cityCode: "TR_55",
      },
      {
        name: "Siirt",
        countryCode: "TR",
        latitude: "37.86588620",
        longitude: "42.14945230",
        nameNative: null,
        label: "Siirt",
        cityCode: "TR_56",
      },
      {
        name: "Sinop",
        countryCode: "TR",
        latitude: "41.55947490",
        longitude: "34.85805320",
        nameNative: null,
        label: "Sinop",
        cityCode: "TR_57",
      },
      {
        name: "Sivas",
        countryCode: "TR",
        latitude: "39.44880390",
        longitude: "37.12944970",
        nameNative: null,
        label: "Sivas",
        cityCode: "TR_58",
      },
      {
        name: "Tekirdağ",
        countryCode: "TR",
        latitude: "41.11212270",
        longitude: "27.26761160",
        nameNative: null,
        label: "Tekirdağ",
        cityCode: "TR_59",
      },
      {
        name: "Tokat",
        countryCode: "TR",
        latitude: "40.39027130",
        longitude: "36.62518630",
        nameNative: null,
        label: "Tokat",
        cityCode: "TR_60",
      },
      {
        name: "Trabzon",
        countryCode: "TR",
        latitude: "40.79924100",
        longitude: "39.58479440",
        nameNative: null,
        label: "Trabzon",
        cityCode: "TR_61",
      },
      {
        name: "Tunceli",
        countryCode: "TR",
        latitude: "39.30735540",
        longitude: "39.43877780",
        nameNative: null,
        label: "Tunceli",
        cityCode: "TR_62",
      },
      {
        name: "Uşak",
        countryCode: "TR",
        latitude: "38.54313190",
        longitude: "29.23207840",
        nameNative: null,
        label: "Uşak",
        cityCode: "TR_64",
      },
      {
        name: "Van",
        countryCode: "TR",
        latitude: "38.36794170",
        longitude: "43.71827870",
        nameNative: null,
        label: "Van",
        cityCode: "TR_65",
      },
      {
        name: "Yalova",
        countryCode: "TR",
        latitude: "40.57759860",
        longitude: "29.20883030",
        nameNative: null,
        label: "Yalova",
        cityCode: "TR_77",
      },
      {
        name: "Yozgat",
        countryCode: "TR",
        latitude: "39.72719790",
        longitude: "35.10778580",
        nameNative: null,
        label: "Yozgat",
        cityCode: "TR_66",
      },
      {
        name: "Zonguldak",
        countryCode: "TR",
        latitude: "41.31249170",
        longitude: "31.85982510",
        nameNative: null,
        label: "Zonguldak",
        cityCode: "TR_67",
      },
      {
        name: "Çanakkale",
        countryCode: "TR",
        latitude: "40.05101040",
        longitude: "26.98524220",
        nameNative: null,
        label: "Çanakkale",
        cityCode: "TR_17",
      },
      {
        name: "Çankırı",
        countryCode: "TR",
        latitude: "40.53690730",
        longitude: "33.58838930",
        nameNative: null,
        label: "Çankırı",
        cityCode: "TR_18",
      },
      {
        name: "Çorum",
        countryCode: "TR",
        latitude: "40.49982110",
        longitude: "34.59862630",
        nameNative: null,
        label: "Çorum",
        cityCode: "TR_19",
      },
      {
        name: "İzmir",
        countryCode: "TR",
        latitude: "38.35916930",
        longitude: "27.26761160",
        nameNative: null,
        label: "İzmir",
        cityCode: "TR_35",
      },
      {
        name: "Şanlıurfa",
        countryCode: "TR",
        latitude: "37.35691020",
        longitude: "39.15436770",
        nameNative: null,
        label: "Şanlıurfa",
        cityCode: "TR_63",
      },
      {
        name: "Şırnak",
        countryCode: "TR",
        latitude: "37.41874810",
        longitude: "42.49183380",
        nameNative: null,
        label: "Şırnak",
        cityCode: "TR_73",
      },
    ],
    TM: [
      {
        name: "Ahal Region",
        countryCode: "TM",
        latitude: "38.63993980",
        longitude: "59.47209040",
        nameNative: null,
        label: "Ahal Region",
        cityCode: "TM_A",
      },
      {
        name: "Ashgabat",
        countryCode: "TM",
        latitude: "37.96007660",
        longitude: "58.32606290",
        nameNative: null,
        label: "Ashgabat",
        cityCode: "TM_S",
      },
      {
        name: "Balkan Region",
        countryCode: "TM",
        latitude: "41.81014720",
        longitude: "21.09373110",
        nameNative: null,
        label: "Balkan Region",
        cityCode: "TM_B",
      },
      {
        name: "Daşoguz Region",
        countryCode: "TM",
        latitude: "41.83687370",
        longitude: "59.96519040",
        nameNative: null,
        label: "Daşoguz Region",
        cityCode: "TM_D",
      },
      {
        name: "Lebap Region",
        countryCode: "TM",
        latitude: "38.12724620",
        longitude: "64.71624150",
        nameNative: null,
        label: "Lebap Region",
        cityCode: "TM_L",
      },
      {
        name: "Mary Region",
        countryCode: "TM",
        latitude: "36.94816230",
        longitude: "62.45041540",
        nameNative: null,
        label: "Mary Region",
        cityCode: "TM_M",
      },
    ],
    TV: [
      {
        name: "Funafuti",
        countryCode: "TV",
        latitude: "-8.52114710",
        longitude: "179.19619260",
        nameNative: null,
        label: "Funafuti",
        cityCode: "TV_FUN",
      },
      {
        name: "Nanumanga",
        countryCode: "TV",
        latitude: "-6.28580190",
        longitude: "176.31992800",
        nameNative: null,
        label: "Nanumanga",
        cityCode: "TV_NMG",
      },
      {
        name: "Nanumea",
        countryCode: "TV",
        latitude: "-5.68816170",
        longitude: "176.13701480",
        nameNative: null,
        label: "Nanumea",
        cityCode: "TV_NMA",
      },
      {
        name: "Niutao Island Council",
        countryCode: "TV",
        latitude: "-6.10642580",
        longitude: "177.34384290",
        nameNative: null,
        label: "Niutao Island Council",
        cityCode: "TV_NIT",
      },
      {
        name: "Nui",
        countryCode: "TV",
        latitude: "-7.23887680",
        longitude: "177.14852320",
        nameNative: null,
        label: "Nui",
        cityCode: "TV_NUI",
      },
      {
        name: "Nukufetau",
        countryCode: "TV",
        latitude: "-8.00000000",
        longitude: "178.50000000",
        nameNative: null,
        label: "Nukufetau",
        cityCode: "TV_NKF",
      },
      {
        name: "Nukulaelae",
        countryCode: "TV",
        latitude: "-9.38111100",
        longitude: "179.85222200",
        nameNative: null,
        label: "Nukulaelae",
        cityCode: "TV_NKL",
      },
      {
        name: "Vaitupu",
        countryCode: "TV",
        latitude: "-7.47673270",
        longitude: "178.67476750",
        nameNative: null,
        label: "Vaitupu",
        cityCode: "TV_VAI",
      },
    ],
    UG: [
      {
        name: "Abim District",
        countryCode: "UG",
        latitude: "2.70669800",
        longitude: "33.65953370",
        nameNative: null,
        label: "Abim District",
        cityCode: "UG_314",
      },
      {
        name: "Adjumani District",
        countryCode: "UG",
        latitude: "3.25485270",
        longitude: "31.71954590",
        nameNative: null,
        label: "Adjumani District",
        cityCode: "UG_301",
      },
      {
        name: "Agago District",
        countryCode: "UG",
        latitude: "2.92508200",
        longitude: "33.34861470",
        nameNative: null,
        label: "Agago District",
        cityCode: "UG_322",
      },
      {
        name: "Alebtong District",
        countryCode: "UG",
        latitude: "2.25457730",
        longitude: "33.34861470",
        nameNative: null,
        label: "Alebtong District",
        cityCode: "UG_323",
      },
      {
        name: "Amolatar District",
        countryCode: "UG",
        latitude: "1.60544020",
        longitude: "32.80844960",
        nameNative: null,
        label: "Amolatar District",
        cityCode: "UG_315",
      },
      {
        name: "Amudat District",
        countryCode: "UG",
        latitude: "1.79162240",
        longitude: "34.90655100",
        nameNative: null,
        label: "Amudat District",
        cityCode: "UG_324",
      },
      {
        name: "Amuria District",
        countryCode: "UG",
        latitude: "2.03017000",
        longitude: "33.64275330",
        nameNative: null,
        label: "Amuria District",
        cityCode: "UG_216",
      },
      {
        name: "Amuru District",
        countryCode: "UG",
        latitude: "2.96678780",
        longitude: "32.08374450",
        nameNative: null,
        label: "Amuru District",
        cityCode: "UG_316",
      },
      {
        name: "Apac District",
        countryCode: "UG",
        latitude: "1.87302630",
        longitude: "32.62774550",
        nameNative: null,
        label: "Apac District",
        cityCode: "UG_302",
      },
      {
        name: "Arua District",
        countryCode: "UG",
        latitude: "2.99598460",
        longitude: "31.17103890",
        nameNative: null,
        label: "Arua District",
        cityCode: "UG_303",
      },
      {
        name: "Budaka District",
        countryCode: "UG",
        latitude: "1.10162770",
        longitude: "33.93039910",
        nameNative: null,
        label: "Budaka District",
        cityCode: "UG_217",
      },
      {
        name: "Bududa District",
        countryCode: "UG",
        latitude: "1.00296930",
        longitude: "34.33381230",
        nameNative: null,
        label: "Bududa District",
        cityCode: "UG_218",
      },
      {
        name: "Bugiri District",
        countryCode: "UG",
        latitude: "0.53161270",
        longitude: "33.75177230",
        nameNative: null,
        label: "Bugiri District",
        cityCode: "UG_201",
      },
      {
        name: "Buhweju District",
        countryCode: "UG",
        latitude: "-0.29113590",
        longitude: "30.29741990",
        nameNative: null,
        label: "Buhweju District",
        cityCode: "UG_420",
      },
      {
        name: "Buikwe District",
        countryCode: "UG",
        latitude: "0.31440460",
        longitude: "32.98883190",
        nameNative: null,
        label: "Buikwe District",
        cityCode: "UG_117",
      },
      {
        name: "Bukedea District",
        countryCode: "UG",
        latitude: "1.35568980",
        longitude: "34.10867930",
        nameNative: null,
        label: "Bukedea District",
        cityCode: "UG_219",
      },
      {
        name: "Bukomansimbi District",
        countryCode: "UG",
        latitude: "-0.14327520",
        longitude: "31.60548930",
        nameNative: null,
        label: "Bukomansimbi District",
        cityCode: "UG_118",
      },
      {
        name: "Bukwo District",
        countryCode: "UG",
        latitude: "1.28186510",
        longitude: "34.72987650",
        nameNative: null,
        label: "Bukwo District",
        cityCode: "UG_220",
      },
      {
        name: "Bulambuli District",
        countryCode: "UG",
        latitude: "1.47988460",
        longitude: "34.37544140",
        nameNative: null,
        label: "Bulambuli District",
        cityCode: "UG_225",
      },
      {
        name: "Buliisa District",
        countryCode: "UG",
        latitude: "2.02996070",
        longitude: "31.53700030",
        nameNative: null,
        label: "Buliisa District",
        cityCode: "UG_416",
      },
      {
        name: "Bundibugyo District",
        countryCode: "UG",
        latitude: "0.68517630",
        longitude: "30.02029640",
        nameNative: null,
        label: "Bundibugyo District",
        cityCode: "UG_401",
      },
      {
        name: "Bunyangabu District",
        countryCode: "UG",
        latitude: "0.48709180",
        longitude: "30.20510960",
        nameNative: null,
        label: "Bunyangabu District",
        cityCode: "UG_430",
      },
      {
        name: "Bushenyi District",
        countryCode: "UG",
        latitude: "-0.48709180",
        longitude: "30.20510960",
        nameNative: null,
        label: "Bushenyi District",
        cityCode: "UG_402",
      },
      {
        name: "Busia District",
        countryCode: "UG",
        latitude: "0.40447310",
        longitude: "34.01958270",
        nameNative: null,
        label: "Busia District",
        cityCode: "UG_202",
      },
      {
        name: "Butaleja District",
        countryCode: "UG",
        latitude: "0.84749220",
        longitude: "33.84112880",
        nameNative: null,
        label: "Butaleja District",
        cityCode: "UG_221",
      },
      {
        name: "Butambala District",
        countryCode: "UG",
        latitude: "0.17425000",
        longitude: "32.10646680",
        nameNative: null,
        label: "Butambala District",
        cityCode: "UG_119",
      },
      {
        name: "Butebo District",
        countryCode: "UG",
        latitude: "1.21411240",
        longitude: "33.90808960",
        nameNative: null,
        label: "Butebo District",
        cityCode: "UG_233",
      },
      {
        name: "Buvuma District",
        countryCode: "UG",
        latitude: "-0.37649120",
        longitude: "33.25879300",
        nameNative: null,
        label: "Buvuma District",
        cityCode: "UG_120",
      },
      {
        name: "Buyende District",
        countryCode: "UG",
        latitude: "1.24136820",
        longitude: "33.12390490",
        nameNative: null,
        label: "Buyende District",
        cityCode: "UG_226",
      },
      {
        name: "Central Region",
        countryCode: "UG",
        latitude: "44.29687500",
        longitude: "-94.74017330",
        nameNative: null,
        label: "Central Region",
        cityCode: "UG_C",
      },
      {
        name: "Dokolo District",
        countryCode: "UG",
        latitude: "1.96364210",
        longitude: "33.03387670",
        nameNative: null,
        label: "Dokolo District",
        cityCode: "UG_317",
      },
      {
        name: "Eastern Region",
        countryCode: "UG",
        latitude: "6.23740360",
        longitude: "-0.45023680",
        nameNative: null,
        label: "Eastern Region",
        cityCode: "UG_E",
      },
      {
        name: "Gomba District",
        countryCode: "UG",
        latitude: "0.22297910",
        longitude: "31.67393710",
        nameNative: null,
        label: "Gomba District",
        cityCode: "UG_121",
      },
      {
        name: "Gulu District",
        countryCode: "UG",
        latitude: "2.81857760",
        longitude: "32.44672380",
        nameNative: null,
        label: "Gulu District",
        cityCode: "UG_304",
      },
      {
        name: "Ibanda District",
        countryCode: "UG",
        latitude: "-0.09648900",
        longitude: "30.57395790",
        nameNative: null,
        label: "Ibanda District",
        cityCode: "UG_417",
      },
      {
        name: "Iganga District",
        countryCode: "UG",
        latitude: "0.66001370",
        longitude: "33.48319060",
        nameNative: null,
        label: "Iganga District",
        cityCode: "UG_203",
      },
      {
        name: "Isingiro District",
        countryCode: "UG",
        latitude: "-0.84354300",
        longitude: "30.80394740",
        nameNative: null,
        label: "Isingiro District",
        cityCode: "UG_418",
      },
      {
        name: "Jinja District",
        countryCode: "UG",
        latitude: "0.53437430",
        longitude: "33.30371430",
        nameNative: null,
        label: "Jinja District",
        cityCode: "UG_204",
      },
      {
        name: "Kaabong District",
        countryCode: "UG",
        latitude: "3.51262150",
        longitude: "33.97500180",
        nameNative: null,
        label: "Kaabong District",
        cityCode: "UG_318",
      },
      {
        name: "Kabale District",
        countryCode: "UG",
        latitude: "-1.24930840",
        longitude: "30.06652360",
        nameNative: null,
        label: "Kabale District",
        cityCode: "UG_404",
      },
      {
        name: "Kabarole District",
        countryCode: "UG",
        latitude: "0.58507910",
        longitude: "30.25127280",
        nameNative: null,
        label: "Kabarole District",
        cityCode: "UG_405",
      },
      {
        name: "Kaberamaido District",
        countryCode: "UG",
        latitude: "1.69633220",
        longitude: "33.21385100",
        nameNative: null,
        label: "Kaberamaido District",
        cityCode: "UG_213",
      },
      {
        name: "Kagadi District",
        countryCode: "UG",
        latitude: "0.94007610",
        longitude: "30.81256380",
        nameNative: null,
        label: "Kagadi District",
        cityCode: "UG_427",
      },
      {
        name: "Kakumiro District",
        countryCode: "UG",
        latitude: "0.78080350",
        longitude: "31.32413890",
        nameNative: null,
        label: "Kakumiro District",
        cityCode: "UG_428",
      },
      {
        name: "Kalangala District",
        countryCode: "UG",
        latitude: "-0.63505780",
        longitude: "32.53727410",
        nameNative: null,
        label: "Kalangala District",
        cityCode: "UG_101",
      },
      {
        name: "Kaliro District",
        countryCode: "UG",
        latitude: "1.04311070",
        longitude: "33.48319060",
        nameNative: null,
        label: "Kaliro District",
        cityCode: "UG_222",
      },
      {
        name: "Kalungu District",
        countryCode: "UG",
        latitude: "-0.09528310",
        longitude: "31.76513620",
        nameNative: null,
        label: "Kalungu District",
        cityCode: "UG_122",
      },
      {
        name: "Kampala District",
        countryCode: "UG",
        latitude: "0.34759640",
        longitude: "32.58251970",
        nameNative: null,
        label: "Kampala District",
        cityCode: "UG_102",
      },
      {
        name: "Kamuli District",
        countryCode: "UG",
        latitude: "0.91871070",
        longitude: "33.12390490",
        nameNative: null,
        label: "Kamuli District",
        cityCode: "UG_205",
      },
      {
        name: "Kamwenge District",
        countryCode: "UG",
        latitude: "0.22579300",
        longitude: "30.48184460",
        nameNative: null,
        label: "Kamwenge District",
        cityCode: "UG_413",
      },
      {
        name: "Kanungu District",
        countryCode: "UG",
        latitude: "-0.81952530",
        longitude: "29.74260400",
        nameNative: null,
        label: "Kanungu District",
        cityCode: "UG_414",
      },
      {
        name: "Kapchorwa District",
        countryCode: "UG",
        latitude: "1.33502050",
        longitude: "34.39763560",
        nameNative: null,
        label: "Kapchorwa District",
        cityCode: "UG_206",
      },
      {
        name: "Kasese District",
        countryCode: "UG",
        latitude: "0.06462850",
        longitude: "30.06652360",
        nameNative: null,
        label: "Kasese District",
        cityCode: "UG_406",
      },
      {
        name: "Katakwi District",
        countryCode: "UG",
        latitude: "1.97310300",
        longitude: "34.06414190",
        nameNative: null,
        label: "Katakwi District",
        cityCode: "UG_207",
      },
      {
        name: "Kayunga District",
        countryCode: "UG",
        latitude: "0.98601820",
        longitude: "32.85357550",
        nameNative: null,
        label: "Kayunga District",
        cityCode: "UG_112",
      },
      {
        name: "Kibaale District",
        countryCode: "UG",
        latitude: "0.90668020",
        longitude: "31.07937050",
        nameNative: null,
        label: "Kibaale District",
        cityCode: "UG_407",
      },
      {
        name: "Kiboga District",
        countryCode: "UG",
        latitude: "0.96575900",
        longitude: "31.71954590",
        nameNative: null,
        label: "Kiboga District",
        cityCode: "UG_103",
      },
      {
        name: "Kibuku District",
        countryCode: "UG",
        latitude: "1.04528740",
        longitude: "33.79925360",
        nameNative: null,
        label: "Kibuku District",
        cityCode: "UG_227",
      },
      {
        name: "Kiruhura District",
        countryCode: "UG",
        latitude: "-0.19279980",
        longitude: "30.80394740",
        nameNative: null,
        label: "Kiruhura District",
        cityCode: "UG_419",
      },
      {
        name: "Kiryandongo District",
        countryCode: "UG",
        latitude: "2.01799070",
        longitude: "32.08374450",
        nameNative: null,
        label: "Kiryandongo District",
        cityCode: "UG_421",
      },
      {
        name: "Kisoro District",
        countryCode: "UG",
        latitude: "-1.22094300",
        longitude: "29.64991620",
        nameNative: null,
        label: "Kisoro District",
        cityCode: "UG_408",
      },
      {
        name: "Kitgum District",
        countryCode: "UG",
        latitude: "3.33968290",
        longitude: "33.16888830",
        nameNative: null,
        label: "Kitgum District",
        cityCode: "UG_305",
      },
      {
        name: "Koboko District",
        countryCode: "UG",
        latitude: "3.52370580",
        longitude: "31.03351000",
        nameNative: null,
        label: "Koboko District",
        cityCode: "UG_319",
      },
      {
        name: "Kole District",
        countryCode: "UG",
        latitude: "2.37010970",
        longitude: "32.76330360",
        nameNative: null,
        label: "Kole District",
        cityCode: "UG_325",
      },
      {
        name: "Kotido District",
        countryCode: "UG",
        latitude: "3.04156790",
        longitude: "33.88577470",
        nameNative: null,
        label: "Kotido District",
        cityCode: "UG_306",
      },
      {
        name: "Kumi District",
        countryCode: "UG",
        latitude: "1.48769990",
        longitude: "33.93039910",
        nameNative: null,
        label: "Kumi District",
        cityCode: "UG_208",
      },
      {
        name: "Kween District",
        countryCode: "UG",
        latitude: "1.44387900",
        longitude: "34.59713200",
        nameNative: null,
        label: "Kween District",
        cityCode: "UG_228",
      },
      {
        name: "Kyankwanzi District",
        countryCode: "UG",
        latitude: "1.09660370",
        longitude: "31.71954590",
        nameNative: null,
        label: "Kyankwanzi District",
        cityCode: "UG_123",
      },
      {
        name: "Kyegegwa District",
        countryCode: "UG",
        latitude: "0.48181930",
        longitude: "31.05500930",
        nameNative: null,
        label: "Kyegegwa District",
        cityCode: "UG_422",
      },
      {
        name: "Kyenjojo District",
        countryCode: "UG",
        latitude: "0.60929230",
        longitude: "30.64012310",
        nameNative: null,
        label: "Kyenjojo District",
        cityCode: "UG_415",
      },
      {
        name: "Kyotera District",
        countryCode: "UG",
        latitude: "-0.63589880",
        longitude: "31.54556370",
        nameNative: null,
        label: "Kyotera District",
        cityCode: "UG_125",
      },
      {
        name: "Lamwo District",
        countryCode: "UG",
        latitude: "3.57075680",
        longitude: "32.53727410",
        nameNative: null,
        label: "Lamwo District",
        cityCode: "UG_326",
      },
      {
        name: "Lira District",
        countryCode: "UG",
        latitude: "2.23161690",
        longitude: "32.94376670",
        nameNative: null,
        label: "Lira District",
        cityCode: "UG_307",
      },
      {
        name: "Luuka District",
        countryCode: "UG",
        latitude: "0.72505990",
        longitude: "33.30371430",
        nameNative: null,
        label: "Luuka District",
        cityCode: "UG_229",
      },
      {
        name: "Luwero District",
        countryCode: "UG",
        latitude: "0.82711180",
        longitude: "32.62774550",
        nameNative: null,
        label: "Luwero District",
        cityCode: "UG_104",
      },
      {
        name: "Lwengo District",
        countryCode: "UG",
        latitude: "-0.41652880",
        longitude: "31.39989950",
        nameNative: null,
        label: "Lwengo District",
        cityCode: "UG_124",
      },
      {
        name: "Lyantonde District",
        countryCode: "UG",
        latitude: "-0.22406960",
        longitude: "31.21684660",
        nameNative: null,
        label: "Lyantonde District",
        cityCode: "UG_114",
      },
      {
        name: "Manafwa District",
        countryCode: "UG",
        latitude: "0.90635990",
        longitude: "34.28660910",
        nameNative: null,
        label: "Manafwa District",
        cityCode: "UG_223",
      },
      {
        name: "Maracha District",
        countryCode: "UG",
        latitude: "3.28731270",
        longitude: "30.94030230",
        nameNative: null,
        label: "Maracha District",
        cityCode: "UG_320",
      },
      {
        name: "Masaka District",
        countryCode: "UG",
        latitude: "-0.44636910",
        longitude: "31.90179540",
        nameNative: null,
        label: "Masaka District",
        cityCode: "UG_105",
      },
      {
        name: "Masindi District",
        countryCode: "UG",
        latitude: "1.49203630",
        longitude: "31.71954590",
        nameNative: null,
        label: "Masindi District",
        cityCode: "UG_409",
      },
      {
        name: "Mayuge District",
        countryCode: "UG",
        latitude: "-0.21829820",
        longitude: "33.57280270",
        nameNative: null,
        label: "Mayuge District",
        cityCode: "UG_214",
      },
      {
        name: "Mbale District",
        countryCode: "UG",
        latitude: "1.03442740",
        longitude: "34.19768820",
        nameNative: null,
        label: "Mbale District",
        cityCode: "UG_209",
      },
      {
        name: "Mbarara District",
        countryCode: "UG",
        latitude: "-0.60715960",
        longitude: "30.65450220",
        nameNative: null,
        label: "Mbarara District",
        cityCode: "UG_410",
      },
      {
        name: "Mitooma District",
        countryCode: "UG",
        latitude: "-0.61932760",
        longitude: "30.02029640",
        nameNative: null,
        label: "Mitooma District",
        cityCode: "UG_423",
      },
      {
        name: "Mityana District",
        countryCode: "UG",
        latitude: "0.44548450",
        longitude: "32.08374450",
        nameNative: null,
        label: "Mityana District",
        cityCode: "UG_115",
      },
      {
        name: "Moroto District",
        countryCode: "UG",
        latitude: "2.61685450",
        longitude: "34.59713200",
        nameNative: null,
        label: "Moroto District",
        cityCode: "UG_308",
      },
      {
        name: "Moyo District",
        countryCode: "UG",
        latitude: "3.56964640",
        longitude: "31.67393710",
        nameNative: null,
        label: "Moyo District",
        cityCode: "UG_309",
      },
      {
        name: "Mpigi District",
        countryCode: "UG",
        latitude: "0.22735280",
        longitude: "32.32492360",
        nameNative: null,
        label: "Mpigi District",
        cityCode: "UG_106",
      },
      {
        name: "Mubende District",
        countryCode: "UG",
        latitude: "0.57727580",
        longitude: "31.53700030",
        nameNative: null,
        label: "Mubende District",
        cityCode: "UG_107",
      },
      {
        name: "Mukono District",
        countryCode: "UG",
        latitude: "0.28354760",
        longitude: "32.76330360",
        nameNative: null,
        label: "Mukono District",
        cityCode: "UG_108",
      },
      {
        name: "Nakapiripirit District",
        countryCode: "UG",
        latitude: "1.96061730",
        longitude: "34.59713200",
        nameNative: null,
        label: "Nakapiripirit District",
        cityCode: "UG_311",
      },
      {
        name: "Nakaseke District",
        countryCode: "UG",
        latitude: "1.22308480",
        longitude: "32.08374450",
        nameNative: null,
        label: "Nakaseke District",
        cityCode: "UG_116",
      },
      {
        name: "Nakasongola District",
        countryCode: "UG",
        latitude: "1.34897210",
        longitude: "32.44672380",
        nameNative: null,
        label: "Nakasongola District",
        cityCode: "UG_109",
      },
      {
        name: "Namayingo District",
        countryCode: "UG",
        latitude: "-0.28035750",
        longitude: "33.75177230",
        nameNative: null,
        label: "Namayingo District",
        cityCode: "UG_230",
      },
      {
        name: "Namisindwa District",
        countryCode: "UG",
        latitude: "0.90710100",
        longitude: "34.35740370",
        nameNative: null,
        label: "Namisindwa District",
        cityCode: "UG_234",
      },
      {
        name: "Namutumba District",
        countryCode: "UG",
        latitude: "0.84926100",
        longitude: "33.66233010",
        nameNative: null,
        label: "Namutumba District",
        cityCode: "UG_224",
      },
      {
        name: "Napak District",
        countryCode: "UG",
        latitude: "2.36299450",
        longitude: "34.24215970",
        nameNative: null,
        label: "Napak District",
        cityCode: "UG_327",
      },
      {
        name: "Nebbi District",
        countryCode: "UG",
        latitude: "2.44093920",
        longitude: "31.35416310",
        nameNative: null,
        label: "Nebbi District",
        cityCode: "UG_310",
      },
      {
        name: "Ngora District",
        countryCode: "UG",
        latitude: "1.49081150",
        longitude: "33.75177230",
        nameNative: null,
        label: "Ngora District",
        cityCode: "UG_231",
      },
      {
        name: "Northern Region",
        countryCode: "UG",
        latitude: "9.54392690",
        longitude: "-0.90566230",
        nameNative: null,
        label: "Northern Region",
        cityCode: "UG_N",
      },
      {
        name: "Ntoroko District",
        countryCode: "UG",
        latitude: "1.07881780",
        longitude: "30.38966510",
        nameNative: null,
        label: "Ntoroko District",
        cityCode: "UG_424",
      },
      {
        name: "Ntungamo District",
        countryCode: "UG",
        latitude: "-0.98073410",
        longitude: "30.25127280",
        nameNative: null,
        label: "Ntungamo District",
        cityCode: "UG_411",
      },
      {
        name: "Nwoya District",
        countryCode: "UG",
        latitude: "2.56244400",
        longitude: "31.90179540",
        nameNative: null,
        label: "Nwoya District",
        cityCode: "UG_328",
      },
      {
        name: "Omoro District",
        countryCode: "UG",
        latitude: "2.71522300",
        longitude: "32.49200880",
        nameNative: null,
        label: "Omoro District",
        cityCode: "UG_331",
      },
      {
        name: "Otuke District",
        countryCode: "UG",
        latitude: "2.52140590",
        longitude: "33.34861470",
        nameNative: null,
        label: "Otuke District",
        cityCode: "UG_329",
      },
      {
        name: "Oyam District",
        countryCode: "UG",
        latitude: "2.27762810",
        longitude: "32.44672380",
        nameNative: null,
        label: "Oyam District",
        cityCode: "UG_321",
      },
      {
        name: "Pader District",
        countryCode: "UG",
        latitude: "2.94306820",
        longitude: "32.80844960",
        nameNative: null,
        label: "Pader District",
        cityCode: "UG_312",
      },
      {
        name: "Pakwach District",
        countryCode: "UG",
        latitude: "2.46071410",
        longitude: "31.49417380",
        nameNative: null,
        label: "Pakwach District",
        cityCode: "UG_332",
      },
      {
        name: "Pallisa District",
        countryCode: "UG",
        latitude: "1.23242060",
        longitude: "33.75177230",
        nameNative: null,
        label: "Pallisa District",
        cityCode: "UG_210",
      },
      {
        name: "Rakai District",
        countryCode: "UG",
        latitude: "-0.70691350",
        longitude: "31.53700030",
        nameNative: null,
        label: "Rakai District",
        cityCode: "UG_110",
      },
      {
        name: "Rubanda District",
        countryCode: "UG",
        latitude: "-1.18611900",
        longitude: "29.84535760",
        nameNative: null,
        label: "Rubanda District",
        cityCode: "UG_429",
      },
      {
        name: "Rubirizi District",
        countryCode: "UG",
        latitude: "-0.26424100",
        longitude: "30.10840330",
        nameNative: null,
        label: "Rubirizi District",
        cityCode: "UG_425",
      },
      {
        name: "Rukiga District",
        countryCode: "UG",
        latitude: "-1.13263370",
        longitude: "30.04341200",
        nameNative: null,
        label: "Rukiga District",
        cityCode: "UG_431",
      },
      {
        name: "Rukungiri District",
        countryCode: "UG",
        latitude: "-0.75184900",
        longitude: "29.92779470",
        nameNative: null,
        label: "Rukungiri District",
        cityCode: "UG_412",
      },
      {
        name: "Sembabule District",
        countryCode: "UG",
        latitude: "0.06377150",
        longitude: "31.35416310",
        nameNative: null,
        label: "Sembabule District",
        cityCode: "UG_111",
      },
      {
        name: "Serere District",
        countryCode: "UG",
        latitude: "1.49940330",
        longitude: "33.54900780",
        nameNative: null,
        label: "Serere District",
        cityCode: "UG_232",
      },
      {
        name: "Sheema District",
        countryCode: "UG",
        latitude: "-0.55152980",
        longitude: "30.38966510",
        nameNative: null,
        label: "Sheema District",
        cityCode: "UG_426",
      },
      {
        name: "Sironko District",
        countryCode: "UG",
        latitude: "1.23022740",
        longitude: "34.24910640",
        nameNative: null,
        label: "Sironko District",
        cityCode: "UG_215",
      },
      {
        name: "Soroti District",
        countryCode: "UG",
        latitude: "1.72291170",
        longitude: "33.52800720",
        nameNative: null,
        label: "Soroti District",
        cityCode: "UG_211",
      },
      {
        name: "Tororo District",
        countryCode: "UG",
        latitude: "0.68709940",
        longitude: "34.06414190",
        nameNative: null,
        label: "Tororo District",
        cityCode: "UG_212",
      },
      {
        name: "Wakiso District",
        countryCode: "UG",
        latitude: "0.06301900",
        longitude: "32.44672380",
        nameNative: null,
        label: "Wakiso District",
        cityCode: "UG_113",
      },
      {
        name: "Western Region",
        countryCode: "UG",
        latitude: "40.76672150",
        longitude: "-111.88772030",
        nameNative: null,
        label: "Western Region",
        cityCode: "UG_W",
      },
      {
        name: "Yumbe District",
        countryCode: "UG",
        latitude: "3.46980230",
        longitude: "31.24832910",
        nameNative: null,
        label: "Yumbe District",
        cityCode: "UG_313",
      },
      {
        name: "Zombo District",
        countryCode: "UG",
        latitude: "2.55442930",
        longitude: "30.94173680",
        nameNative: null,
        label: "Zombo District",
        cityCode: "UG_330",
      },
    ],
    UA: [
      {
        name: "Autonomous Republic of Crimea",
        countryCode: "UA",
        latitude: "44.95211700",
        longitude: "34.10241700",
        nameNative: null,
        label: "Autonomous Republic of Crimea",
        cityCode: "UA_43",
      },
      {
        name: "Cherkaska oblast",
        countryCode: "UA",
        latitude: "49.44443300",
        longitude: "32.05976700",
        nameNative: null,
        label: "Cherkaska oblast",
        cityCode: "UA_71",
      },
      {
        name: "Chernihivska oblast",
        countryCode: "UA",
        latitude: "51.49820000",
        longitude: "31.28934990",
        nameNative: null,
        label: "Chernihivska oblast",
        cityCode: "UA_74",
      },
      {
        name: "Chernivetska oblast",
        countryCode: "UA",
        latitude: "48.29168300",
        longitude: "25.93521700",
        nameNative: null,
        label: "Chernivetska oblast",
        cityCode: "UA_77",
      },
      {
        name: "Dnipropetrovska oblast",
        countryCode: "UA",
        latitude: "48.46471700",
        longitude: "35.04618300",
        nameNative: null,
        label: "Dnipropetrovska oblast",
        cityCode: "UA_12",
      },
      {
        name: "Donetska oblast",
        countryCode: "UA",
        latitude: "48.01588300",
        longitude: "37.80285000",
        nameNative: null,
        label: "Donetska oblast",
        cityCode: "UA_14",
      },
      {
        name: "Ivano-Frankivska oblast",
        countryCode: "UA",
        latitude: "48.92263300",
        longitude: "24.71111700",
        nameNative: null,
        label: "Ivano-Frankivska oblast",
        cityCode: "UA_26",
      },
      {
        name: "Kharkivska oblast",
        countryCode: "UA",
        latitude: "49.99350000",
        longitude: "36.23038300",
        nameNative: null,
        label: "Kharkivska oblast",
        cityCode: "UA_63",
      },
      {
        name: "Khersonska oblast",
        countryCode: "UA",
        latitude: "46.63541700",
        longitude: "32.61686700",
        nameNative: null,
        label: "Khersonska oblast",
        cityCode: "UA_65",
      },
      {
        name: "Khmelnytska oblast",
        countryCode: "UA",
        latitude: "49.42298300",
        longitude: "26.98713310",
        nameNative: null,
        label: "Khmelnytska oblast",
        cityCode: "UA_68",
      },
      {
        name: "Kirovohradska oblast",
        countryCode: "UA",
        latitude: "48.50793300",
        longitude: "32.26231700",
        nameNative: null,
        label: "Kirovohradska oblast",
        cityCode: "UA_35",
      },
      {
        name: "Kyiv",
        countryCode: "UA",
        latitude: "50.45010000",
        longitude: "30.52340000",
        nameNative: null,
        label: "Kyiv",
        cityCode: "UA_30",
      },
      {
        name: "Kyivska oblast",
        countryCode: "UA",
        latitude: "50.05295060",
        longitude: "30.76671340",
        nameNative: null,
        label: "Kyivska oblast",
        cityCode: "UA_32",
      },
      {
        name: "Luhanska oblast",
        countryCode: "UA",
        latitude: "48.57404100",
        longitude: "39.30781500",
        nameNative: null,
        label: "Luhanska oblast",
        cityCode: "UA_09",
      },
      {
        name: "Lvivska oblast",
        countryCode: "UA",
        latitude: "49.83968300",
        longitude: "24.02971700",
        nameNative: null,
        label: "Lvivska oblast",
        cityCode: "UA_46",
      },
      {
        name: "Mykolaivska oblast",
        countryCode: "UA",
        latitude: "46.97503300",
        longitude: "31.99458290",
        nameNative: null,
        label: "Mykolaivska oblast",
        cityCode: "UA_48",
      },
      {
        name: "Odeska oblast",
        countryCode: "UA",
        latitude: "46.48458300",
        longitude: "30.73260000",
        nameNative: null,
        label: "Odeska oblast",
        cityCode: "UA_51",
      },
      {
        name: "Poltavska oblast",
        countryCode: "UA",
        latitude: "49.64291960",
        longitude: "32.66753390",
        nameNative: null,
        label: "Poltavska oblast",
        cityCode: "UA_53",
      },
      {
        name: "Rivnenska oblast",
        countryCode: "UA",
        latitude: "50.61990000",
        longitude: "26.25161700",
        nameNative: null,
        label: "Rivnenska oblast",
        cityCode: "UA_56",
      },
      {
        name: "Sumska oblast",
        countryCode: "UA",
        latitude: "50.90770000",
        longitude: "34.79810000",
        nameNative: null,
        label: "Sumska oblast",
        cityCode: "UA_59",
      },
      {
        name: "Ternopilska oblast",
        countryCode: "UA",
        latitude: "49.55351700",
        longitude: "25.59476700",
        nameNative: null,
        label: "Ternopilska oblast",
        cityCode: "UA_61",
      },
      {
        name: "Vinnytska oblast",
        countryCode: "UA",
        latitude: "49.23308300",
        longitude: "28.46821690",
        nameNative: null,
        label: "Vinnytska oblast",
        cityCode: "UA_05",
      },
      {
        name: "Volynska oblast",
        countryCode: "UA",
        latitude: "50.74723300",
        longitude: "25.32538300",
        nameNative: null,
        label: "Volynska oblast",
        cityCode: "UA_07",
      },
      {
        name: "Zakarpatska Oblast",
        countryCode: "UA",
        latitude: "48.62080000",
        longitude: "22.28788300",
        nameNative: null,
        label: "Zakarpatska Oblast",
        cityCode: "UA_21",
      },
      {
        name: "Zaporizka oblast",
        countryCode: "UA",
        latitude: "47.83880000",
        longitude: "35.13956700",
        nameNative: null,
        label: "Zaporizka oblast",
        cityCode: "UA_23",
      },
      {
        name: "Zhytomyrska oblast",
        countryCode: "UA",
        latitude: "50.25465000",
        longitude: "28.65866690",
        nameNative: null,
        label: "Zhytomyrska oblast",
        cityCode: "UA_18",
      },
    ],
    AE: [
      {
        name: "Abu Dhabi Emirate",
        countryCode: "AE",
        latitude: "24.45388400",
        longitude: "54.37734380",
        nameNative: "إمارة أبوظبي",
        label: "إمارة أبوظبي / Abu Dhabi Emirate",
        cityCode: "AE_AZ",
      },
      {
        name: "Ajman Emirate",
        countryCode: "AE",
        latitude: "25.40521650",
        longitude: "55.51364330",
        nameNative: "إمارة عجمان",
        label: "إمارة عجمان / Ajman Emirate",
        cityCode: "AE_AJ",
      },
      {
        name: "Dubai",
        countryCode: "AE",
        latitude: "25.20484930",
        longitude: "55.27078280",
        nameNative: "دبي",
        label: "دبي / Dubai",
        cityCode: "AE_DU",
      },
      {
        name: "Fujairah",
        countryCode: "AE",
        latitude: "25.12880990",
        longitude: "56.32648490",
        nameNative: "الفجيرة",
        label: "الفجيرة / Fujairah",
        cityCode: "AE_FU",
      },
      {
        name: "Ras al-Khaimah",
        countryCode: "AE",
        latitude: "25.67413430",
        longitude: "55.98041730",
        nameNative: "رأس الخيمة",
        label: "رأس الخيمة / Ras al-Khaimah",
        cityCode: "AE_RK",
      },
      {
        name: "Sharjah Emirate",
        countryCode: "AE",
        latitude: "25.07539740",
        longitude: "55.75784030",
        nameNative: "إمارة الشارقة",
        label: "إمارة الشارقة / Sharjah Emirate",
        cityCode: "AE_SH",
      },
      {
        name: "Umm al-Quwain",
        countryCode: "AE",
        latitude: "25.54263240",
        longitude: "55.54753480",
        nameNative: "أم القيوين",
        label: "أم القيوين / Umm al-Quwain",
        cityCode: "AE_UQ",
      },
    ],
    GB: [
      {
        name: "Aberdeen",
        countryCode: "GB",
        latitude: "57.14971700",
        longitude: "-2.09427800",
        nameNative: null,
        label: "Aberdeen",
        cityCode: "GB_ABE",
      },
      {
        name: "Aberdeenshire",
        countryCode: "GB",
        latitude: "57.28687230",
        longitude: "-2.38156840",
        nameNative: null,
        label: "Aberdeenshire",
        cityCode: "GB_ABD",
      },
      {
        name: "Angus",
        countryCode: "GB",
        latitude: "37.27578860",
        longitude: "-95.65010330",
        nameNative: null,
        label: "Angus",
        cityCode: "GB_ANS",
      },
      {
        name: "Antrim and Newtownabbey",
        countryCode: "GB",
        latitude: "54.69568870",
        longitude: "-5.94810690",
        nameNative: null,
        label: "Antrim and Newtownabbey",
        cityCode: "GB_ANN",
      },
      {
        name: "Antrim",
        countryCode: "GB",
        latitude: "54.71953380",
        longitude: "-6.20724980",
        nameNative: null,
        label: "Antrim",
        cityCode: "GB_ANT",
      },
      {
        name: "Ards and North Down",
        countryCode: "GB",
        latitude: "54.58996450",
        longitude: "-5.59849720",
        nameNative: null,
        label: "Ards and North Down",
        cityCode: "GB_AND",
      },
      {
        name: "Ards",
        countryCode: "GB",
        latitude: "42.13918510",
        longitude: "-87.86149720",
        nameNative: null,
        label: "Ards",
        cityCode: "GB_ARD",
      },
      {
        name: "Argyll and Bute",
        countryCode: "GB",
        latitude: "56.40062140",
        longitude: "-5.48074800",
        nameNative: null,
        label: "Argyll and Bute",
        cityCode: "GB_AGB",
      },
      {
        name: "Armagh City and District Council",
        countryCode: "GB",
        latitude: "54.39325920",
        longitude: "-6.45634010",
        nameNative: null,
        label: "Armagh City and District Council",
        cityCode: "GB_ARM",
      },
      {
        name: "Armagh, Banbridge and Craigavon",
        countryCode: "GB",
        latitude: "54.39325920",
        longitude: "-6.45634010",
        nameNative: null,
        label: "Armagh, Banbridge and Craigavon",
        cityCode: "GB_ABC",
      },
      {
        name: "Ascension Island",
        countryCode: "GB",
        latitude: "-7.94671660",
        longitude: "-14.35591580",
        nameNative: null,
        label: "Ascension Island",
        cityCode: "GB_SH-AC",
      },
      {
        name: "Ballymena Borough",
        countryCode: "GB",
        latitude: "54.86426000",
        longitude: "-6.27910740",
        nameNative: null,
        label: "Ballymena Borough",
        cityCode: "GB_BLA",
      },
      {
        name: "Ballymoney",
        countryCode: "GB",
        latitude: "55.07048880",
        longitude: "-6.51737080",
        nameNative: null,
        label: "Ballymoney",
        cityCode: "GB_BLY",
      },
      {
        name: "Banbridge",
        countryCode: "GB",
        latitude: "54.34872900",
        longitude: "-6.27048030",
        nameNative: null,
        label: "Banbridge",
        cityCode: "GB_BNB",
      },
      {
        name: "Barnsley",
        countryCode: "GB",
        latitude: "34.29949560",
        longitude: "-84.98458090",
        nameNative: null,
        label: "Barnsley",
        cityCode: "GB_BNS",
      },
      {
        name: "Bath and North East Somerset",
        countryCode: "GB",
        latitude: "51.32501020",
        longitude: "-2.47662410",
        nameNative: null,
        label: "Bath and North East Somerset",
        cityCode: "GB_BAS",
      },
      {
        name: "Bedford",
        countryCode: "GB",
        latitude: "32.84401700",
        longitude: "-97.14306710",
        nameNative: null,
        label: "Bedford",
        cityCode: "GB_BDF",
      },
      {
        name: "Belfast district",
        countryCode: "GB",
        latitude: "54.61703660",
        longitude: "-5.95318610",
        nameNative: null,
        label: "Belfast district",
        cityCode: "GB_BFS",
      },
      {
        name: "Birmingham",
        countryCode: "GB",
        latitude: "33.51858920",
        longitude: "-86.81035670",
        nameNative: null,
        label: "Birmingham",
        cityCode: "GB_BIR",
      },
      {
        name: "Blackburn with Darwen",
        countryCode: "GB",
        latitude: "53.69575220",
        longitude: "-2.46829850",
        nameNative: null,
        label: "Blackburn with Darwen",
        cityCode: "GB_BBD",
      },
      {
        name: "Blackpool",
        countryCode: "GB",
        latitude: "53.81750530",
        longitude: "-3.03567480",
        nameNative: null,
        label: "Blackpool",
        cityCode: "GB_BPL",
      },
      {
        name: "Blaenau Gwent County Borough",
        countryCode: "GB",
        latitude: "51.78757790",
        longitude: "-3.20439310",
        nameNative: null,
        label: "Blaenau Gwent County Borough",
        cityCode: "GB_BGW",
      },
      {
        name: "Bolton",
        countryCode: "GB",
        latitude: "44.37264760",
        longitude: "-72.87876250",
        nameNative: null,
        label: "Bolton",
        cityCode: "GB_BOL",
      },
      {
        name: "Bournemouth",
        countryCode: "GB",
        latitude: "50.71916400",
        longitude: "-1.88076900",
        nameNative: null,
        label: "Bournemouth",
        cityCode: "GB_BMH",
      },
      {
        name: "Bracknell Forest",
        countryCode: "GB",
        latitude: "51.41538280",
        longitude: "-0.75364950",
        nameNative: null,
        label: "Bracknell Forest",
        cityCode: "GB_BRC",
      },
      {
        name: "Bradford",
        countryCode: "GB",
        latitude: "53.79598400",
        longitude: "-1.75939800",
        nameNative: null,
        label: "Bradford",
        cityCode: "GB_BRD",
      },
      {
        name: "Bridgend County Borough",
        countryCode: "GB",
        latitude: "51.50831990",
        longitude: "-3.58120750",
        nameNative: null,
        label: "Bridgend County Borough",
        cityCode: "GB_BGE",
      },
      {
        name: "Brighton and Hove",
        countryCode: "GB",
        latitude: "50.82262880",
        longitude: "-0.13704700",
        nameNative: null,
        label: "Brighton and Hove",
        cityCode: "GB_BNH",
      },
      {
        name: "Buckinghamshire",
        countryCode: "GB",
        latitude: "51.80722040",
        longitude: "-0.81276640",
        nameNative: null,
        label: "Buckinghamshire",
        cityCode: "GB_BKM",
      },
      {
        name: "Bury",
        countryCode: "GB",
        latitude: "53.59334980",
        longitude: "-2.29660540",
        nameNative: null,
        label: "Bury",
        cityCode: "GB_BUR",
      },
      {
        name: "Caerphilly County Borough",
        countryCode: "GB",
        latitude: "51.66044650",
        longitude: "-3.21787240",
        nameNative: null,
        label: "Caerphilly County Borough",
        cityCode: "GB_CAY",
      },
      {
        name: "Calderdale",
        countryCode: "GB",
        latitude: "53.72478450",
        longitude: "-1.86583570",
        nameNative: null,
        label: "Calderdale",
        cityCode: "GB_CLD",
      },
      {
        name: "Cambridgeshire",
        countryCode: "GB",
        latitude: "52.20529730",
        longitude: "0.12181950",
        nameNative: null,
        label: "Cambridgeshire",
        cityCode: "GB_CAM",
      },
      {
        name: "Carmarthenshire",
        countryCode: "GB",
        latitude: "51.85723090",
        longitude: "-4.31159590",
        nameNative: null,
        label: "Carmarthenshire",
        cityCode: "GB_CMN",
      },
      {
        name: "Carrickfergus Borough Council",
        countryCode: "GB",
        latitude: "54.72568430",
        longitude: "-5.80937190",
        nameNative: null,
        label: "Carrickfergus Borough Council",
        cityCode: "GB_CKF",
      },
      {
        name: "Castlereagh",
        countryCode: "GB",
        latitude: "54.57567900",
        longitude: "-5.88840280",
        nameNative: null,
        label: "Castlereagh",
        cityCode: "GB_CSR",
      },
      {
        name: "Causeway Coast and Glens",
        countryCode: "GB",
        latitude: "55.04318300",
        longitude: "-6.67412880",
        nameNative: null,
        label: "Causeway Coast and Glens",
        cityCode: "GB_CCG",
      },
      {
        name: "Central Bedfordshire",
        countryCode: "GB",
        latitude: "52.00297440",
        longitude: "-0.46513890",
        nameNative: null,
        label: "Central Bedfordshire",
        cityCode: "GB_CBF",
      },
      {
        name: "Ceredigion",
        countryCode: "GB",
        latitude: "52.21914290",
        longitude: "-3.93212560",
        nameNative: null,
        label: "Ceredigion",
        cityCode: "GB_CGN",
      },
      {
        name: "Cheshire East",
        countryCode: "GB",
        latitude: "53.16104460",
        longitude: "-2.21859320",
        nameNative: null,
        label: "Cheshire East",
        cityCode: "GB_CHE",
      },
      {
        name: "Cheshire West and Chester",
        countryCode: "GB",
        latitude: "53.23029740",
        longitude: "-2.71511170",
        nameNative: null,
        label: "Cheshire West and Chester",
        cityCode: "GB_CHW",
      },
      {
        name: "City and County of Cardiff",
        countryCode: "GB",
        latitude: "51.48158100",
        longitude: "-3.17909000",
        nameNative: null,
        label: "City and County of Cardiff",
        cityCode: "GB_CRF",
      },
      {
        name: "City and County of Swansea",
        countryCode: "GB",
        latitude: "51.62144000",
        longitude: "-3.94364600",
        nameNative: null,
        label: "City and County of Swansea",
        cityCode: "GB_SWA",
      },
      {
        name: "City of Bristol",
        countryCode: "GB",
        latitude: "41.67352200",
        longitude: "-72.94653750",
        nameNative: null,
        label: "City of Bristol",
        cityCode: "GB_BST",
      },
      {
        name: "City of Derby",
        countryCode: "GB",
        latitude: "37.54837550",
        longitude: "-97.24851910",
        nameNative: null,
        label: "City of Derby",
        cityCode: "GB_DER",
      },
      {
        name: "City of Kingston upon Hull",
        countryCode: "GB",
        latitude: "53.76762360",
        longitude: "-0.32741980",
        nameNative: null,
        label: "City of Kingston upon Hull",
        cityCode: "GB_KHL",
      },
      {
        name: "City of Leicester",
        countryCode: "GB",
        latitude: "52.63687780",
        longitude: "-1.13975920",
        nameNative: null,
        label: "City of Leicester",
        cityCode: "GB_LCE",
      },
      {
        name: "City of London",
        countryCode: "GB",
        latitude: "51.51234430",
        longitude: "-0.09098520",
        nameNative: null,
        label: "City of London",
        cityCode: "GB_LND",
      },
      {
        name: "City of Nottingham",
        countryCode: "GB",
        latitude: "52.95478320",
        longitude: "-1.15810860",
        nameNative: null,
        label: "City of Nottingham",
        cityCode: "GB_NGM",
      },
      {
        name: "City of Peterborough",
        countryCode: "GB",
        latitude: "44.30936360",
        longitude: "-78.32015300",
        nameNative: null,
        label: "City of Peterborough",
        cityCode: "GB_PTE",
      },
      {
        name: "City of Plymouth",
        countryCode: "GB",
        latitude: "42.37089410",
        longitude: "-83.46971410",
        nameNative: null,
        label: "City of Plymouth",
        cityCode: "GB_PLY",
      },
      {
        name: "City of Portsmouth",
        countryCode: "GB",
        latitude: "36.83291500",
        longitude: "-76.29755490",
        nameNative: null,
        label: "City of Portsmouth",
        cityCode: "GB_POR",
      },
      {
        name: "City of Southampton",
        countryCode: "GB",
        latitude: "50.90970040",
        longitude: "-1.40435090",
        nameNative: null,
        label: "City of Southampton",
        cityCode: "GB_STH",
      },
      {
        name: "City of Stoke-on-Trent",
        countryCode: "GB",
        latitude: "53.00266800",
        longitude: "-2.17940400",
        nameNative: null,
        label: "City of Stoke-on-Trent",
        cityCode: "GB_STE",
      },
      {
        name: "City of Sunderland",
        countryCode: "GB",
        latitude: "54.88614890",
        longitude: "-1.47857970",
        nameNative: null,
        label: "City of Sunderland",
        cityCode: "GB_SND",
      },
      {
        name: "City of Westminster",
        countryCode: "GB",
        latitude: "39.57659770",
        longitude: "-76.99721260",
        nameNative: null,
        label: "City of Westminster",
        cityCode: "GB_WSM",
      },
      {
        name: "City of Wolverhampton",
        countryCode: "GB",
        latitude: "52.58891200",
        longitude: "-2.15646300",
        nameNative: null,
        label: "City of Wolverhampton",
        cityCode: "GB_WLV",
      },
      {
        name: "City of York",
        countryCode: "GB",
        latitude: "53.95996510",
        longitude: "-1.08729790",
        nameNative: null,
        label: "City of York",
        cityCode: "GB_YOR",
      },
      {
        name: "Clackmannanshire",
        countryCode: "GB",
        latitude: "56.10753510",
        longitude: "-3.75294090",
        nameNative: null,
        label: "Clackmannanshire",
        cityCode: "GB_CLK",
      },
      {
        name: "Coleraine Borough Council",
        countryCode: "GB",
        latitude: "55.14515700",
        longitude: "-6.67598140",
        nameNative: null,
        label: "Coleraine Borough Council",
        cityCode: "GB_CLR",
      },
      {
        name: "Conwy County Borough",
        countryCode: "GB",
        latitude: "53.29350130",
        longitude: "-3.72651610",
        nameNative: null,
        label: "Conwy County Borough",
        cityCode: "GB_CWY",
      },
      {
        name: "Cookstown District Council",
        countryCode: "GB",
        latitude: "54.64181580",
        longitude: "-6.74438950",
        nameNative: null,
        label: "Cookstown District Council",
        cityCode: "GB_CKT",
      },
      {
        name: "Cornwall",
        countryCode: "GB",
        latitude: "50.26604710",
        longitude: "-5.05271250",
        nameNative: null,
        label: "Cornwall",
        cityCode: "GB_CON",
      },
      {
        name: "County Durham",
        countryCode: "GB",
        latitude: "54.72940990",
        longitude: "-1.88115980",
        nameNative: null,
        label: "County Durham",
        cityCode: "GB_DUR",
      },
      {
        name: "Coventry",
        countryCode: "GB",
        latitude: "52.40682200",
        longitude: "-1.51969300",
        nameNative: null,
        label: "Coventry",
        cityCode: "GB_COV",
      },
      {
        name: "Craigavon Borough Council",
        countryCode: "GB",
        latitude: "54.39325920",
        longitude: "-6.45634010",
        nameNative: null,
        label: "Craigavon Borough Council",
        cityCode: "GB_CGV",
      },
      {
        name: "Cumbria",
        countryCode: "GB",
        latitude: "54.57723230",
        longitude: "-2.79748350",
        nameNative: null,
        label: "Cumbria",
        cityCode: "GB_CMA",
      },
      {
        name: "Darlington",
        countryCode: "GB",
        latitude: "34.29987620",
        longitude: "-79.87617410",
        nameNative: null,
        label: "Darlington",
        cityCode: "GB_DAL",
      },
      {
        name: "Denbighshire",
        countryCode: "GB",
        latitude: "53.18422880",
        longitude: "-3.42249850",
        nameNative: null,
        label: "Denbighshire",
        cityCode: "GB_DEN",
      },
      {
        name: "Derbyshire",
        countryCode: "GB",
        latitude: "53.10467820",
        longitude: "-1.56238850",
        nameNative: null,
        label: "Derbyshire",
        cityCode: "GB_DBY",
      },
      {
        name: "Derry City Council",
        countryCode: "GB",
        latitude: "54.96907780",
        longitude: "-7.19583510",
        nameNative: null,
        label: "Derry City Council",
        cityCode: "GB_DRY",
      },
      {
        name: "Derry City and Strabane",
        countryCode: "GB",
        latitude: "55.00474430",
        longitude: "-7.32092220",
        nameNative: null,
        label: "Derry City and Strabane",
        cityCode: "GB_DRS",
      },
      {
        name: "Devon",
        countryCode: "GB",
        latitude: "50.71555910",
        longitude: "-3.53087500",
        nameNative: null,
        label: "Devon",
        cityCode: "GB_DEV",
      },
      {
        name: "Doncaster",
        countryCode: "GB",
        latitude: "53.52282000",
        longitude: "-1.12846200",
        nameNative: null,
        label: "Doncaster",
        cityCode: "GB_DNC",
      },
      {
        name: "Dorset",
        countryCode: "GB",
        latitude: "50.74876350",
        longitude: "-2.34447860",
        nameNative: null,
        label: "Dorset",
        cityCode: "GB_DOR",
      },
      {
        name: "Down District Council",
        countryCode: "GB",
        latitude: "54.24342870",
        longitude: "-5.95779590",
        nameNative: null,
        label: "Down District Council",
        cityCode: "GB_DOW",
      },
      {
        name: "Dudley",
        countryCode: "GB",
        latitude: "42.04336610",
        longitude: "-71.92760330",
        nameNative: null,
        label: "Dudley",
        cityCode: "GB_DUD",
      },
      {
        name: "Dumfries and Galloway",
        countryCode: "GB",
        latitude: "55.07010730",
        longitude: "-3.60525810",
        nameNative: null,
        label: "Dumfries and Galloway",
        cityCode: "GB_DGY",
      },
      {
        name: "Dundee",
        countryCode: "GB",
        latitude: "56.46201800",
        longitude: "-2.97072100",
        nameNative: null,
        label: "Dundee",
        cityCode: "GB_DND",
      },
      {
        name: "Dungannon and South Tyrone Borough Council",
        countryCode: "GB",
        latitude: "54.50826840",
        longitude: "-6.76658910",
        nameNative: null,
        label: "Dungannon and South Tyrone Borough Council",
        cityCode: "GB_DGN",
      },
      {
        name: "East Ayrshire",
        countryCode: "GB",
        latitude: "55.45184960",
        longitude: "-4.26444780",
        nameNative: null,
        label: "East Ayrshire",
        cityCode: "GB_EAY",
      },
      {
        name: "East Dunbartonshire",
        countryCode: "GB",
        latitude: "55.97431620",
        longitude: "-4.20229800",
        nameNative: null,
        label: "East Dunbartonshire",
        cityCode: "GB_EDU",
      },
      {
        name: "East Lothian",
        countryCode: "GB",
        latitude: "55.94933830",
        longitude: "-2.77044640",
        nameNative: null,
        label: "East Lothian",
        cityCode: "GB_ELN",
      },
      {
        name: "East Renfrewshire",
        countryCode: "GB",
        latitude: "55.77047350",
        longitude: "-4.33598210",
        nameNative: null,
        label: "East Renfrewshire",
        cityCode: "GB_ERW",
      },
      {
        name: "East Riding of Yorkshire",
        countryCode: "GB",
        latitude: "53.84161680",
        longitude: "-0.43441060",
        nameNative: null,
        label: "East Riding of Yorkshire",
        cityCode: "GB_ERY",
      },
      {
        name: "East Sussex",
        countryCode: "GB",
        latitude: "50.90859550",
        longitude: "0.24941660",
        nameNative: null,
        label: "East Sussex",
        cityCode: "GB_ESX",
      },
      {
        name: "Edinburgh",
        countryCode: "GB",
        latitude: "55.95325200",
        longitude: "-3.18826700",
        nameNative: null,
        label: "Edinburgh",
        cityCode: "GB_EDH",
      },
      {
        name: "England",
        countryCode: "GB",
        latitude: "52.35551770",
        longitude: "-1.17431970",
        nameNative: null,
        label: "England",
        cityCode: "GB_ENG",
      },
      {
        name: "Essex",
        countryCode: "GB",
        latitude: "51.57424470",
        longitude: "0.48567810",
        nameNative: null,
        label: "Essex",
        cityCode: "GB_ESS",
      },
      {
        name: "Falkirk",
        countryCode: "GB",
        latitude: "56.00187750",
        longitude: "-3.78391310",
        nameNative: null,
        label: "Falkirk",
        cityCode: "GB_FAL",
      },
      {
        name: "Fermanagh District Council",
        countryCode: "GB",
        latitude: "54.34479780",
        longitude: "-7.63842180",
        nameNative: null,
        label: "Fermanagh District Council",
        cityCode: "GB_FER",
      },
      {
        name: "Fermanagh and Omagh",
        countryCode: "GB",
        latitude: "54.45135240",
        longitude: "-7.71250180",
        nameNative: null,
        label: "Fermanagh and Omagh",
        cityCode: "GB_FMO",
      },
      {
        name: "Fife",
        countryCode: "GB",
        latitude: "56.20820780",
        longitude: "-3.14951750",
        nameNative: null,
        label: "Fife",
        cityCode: "GB_FIF",
      },
      {
        name: "Flintshire",
        countryCode: "GB",
        latitude: "53.16686580",
        longitude: "-3.14189080",
        nameNative: null,
        label: "Flintshire",
        cityCode: "GB_FLN",
      },
      {
        name: "Gateshead",
        countryCode: "GB",
        latitude: "54.95268000",
        longitude: "-1.60341100",
        nameNative: null,
        label: "Gateshead",
        cityCode: "GB_GAT",
      },
      {
        name: "Glasgow",
        countryCode: "GB",
        latitude: "55.86423700",
        longitude: "-4.25180600",
        nameNative: null,
        label: "Glasgow",
        cityCode: "GB_GLG",
      },
      {
        name: "Gloucestershire",
        countryCode: "GB",
        latitude: "51.86421120",
        longitude: "-2.23803350",
        nameNative: null,
        label: "Gloucestershire",
        cityCode: "GB_GLS",
      },
      {
        name: "Gwynedd",
        countryCode: "GB",
        latitude: "52.92772660",
        longitude: "-4.13348360",
        nameNative: null,
        label: "Gwynedd",
        cityCode: "GB_GWN",
      },
      {
        name: "Halton",
        countryCode: "GB",
        latitude: "43.53253720",
        longitude: "-79.87448360",
        nameNative: null,
        label: "Halton",
        cityCode: "GB_HAL",
      },
      {
        name: "Hampshire",
        countryCode: "GB",
        latitude: "51.05769480",
        longitude: "-1.30806290",
        nameNative: null,
        label: "Hampshire",
        cityCode: "GB_HAM",
      },
      {
        name: "Hartlepool",
        countryCode: "GB",
        latitude: "54.69174500",
        longitude: "-1.21292600",
        nameNative: null,
        label: "Hartlepool",
        cityCode: "GB_HPL",
      },
      {
        name: "Herefordshire",
        countryCode: "GB",
        latitude: "52.07651640",
        longitude: "-2.65441820",
        nameNative: null,
        label: "Herefordshire",
        cityCode: "GB_HEF",
      },
      {
        name: "Hertfordshire",
        countryCode: "GB",
        latitude: "51.80978230",
        longitude: "-0.23767440",
        nameNative: null,
        label: "Hertfordshire",
        cityCode: "GB_HRT",
      },
      {
        name: "Highland",
        countryCode: "GB",
        latitude: "36.29675080",
        longitude: "-95.83803660",
        nameNative: null,
        label: "Highland",
        cityCode: "GB_HLD",
      },
      {
        name: "Inverclyde",
        countryCode: "GB",
        latitude: "55.93165690",
        longitude: "-4.68001580",
        nameNative: null,
        label: "Inverclyde",
        cityCode: "GB_IVC",
      },
      {
        name: "Isle of Wight",
        countryCode: "GB",
        latitude: "50.69384790",
        longitude: "-1.30473400",
        nameNative: null,
        label: "Isle of Wight",
        cityCode: "GB_IOW",
      },
      {
        name: "Isles of Scilly",
        countryCode: "GB",
        latitude: "49.92772610",
        longitude: "-6.32749660",
        nameNative: null,
        label: "Isles of Scilly",
        cityCode: "GB_IOS",
      },
      {
        name: "Kent",
        countryCode: "GB",
        latitude: "41.15366740",
        longitude: "-81.35788590",
        nameNative: null,
        label: "Kent",
        cityCode: "GB_KEN",
      },
      {
        name: "Kirklees",
        countryCode: "GB",
        latitude: "53.59334320",
        longitude: "-1.80095090",
        nameNative: null,
        label: "Kirklees",
        cityCode: "GB_KIR",
      },
      {
        name: "Knowsley",
        countryCode: "GB",
        latitude: "53.45459400",
        longitude: "-2.85290700",
        nameNative: null,
        label: "Knowsley",
        cityCode: "GB_KWL",
      },
      {
        name: "Lancashire",
        countryCode: "GB",
        latitude: "53.76322540",
        longitude: "-2.70440520",
        nameNative: null,
        label: "Lancashire",
        cityCode: "GB_LAN",
      },
      {
        name: "Larne Borough Council",
        countryCode: "GB",
        latitude: "54.85780030",
        longitude: "-5.82362240",
        nameNative: null,
        label: "Larne Borough Council",
        cityCode: "GB_LRN",
      },
      {
        name: "Leeds",
        countryCode: "GB",
        latitude: "53.80075540",
        longitude: "-1.54907740",
        nameNative: null,
        label: "Leeds",
        cityCode: "GB_LDS",
      },
      {
        name: "Leicestershire",
        countryCode: "GB",
        latitude: "52.77257100",
        longitude: "-1.20521260",
        nameNative: null,
        label: "Leicestershire",
        cityCode: "GB_LEC",
      },
      {
        name: "Limavady Borough Council",
        countryCode: "GB",
        latitude: "55.05168200",
        longitude: "-6.94919440",
        nameNative: null,
        label: "Limavady Borough Council",
        cityCode: "GB_LMV",
      },
      {
        name: "Lincolnshire",
        countryCode: "GB",
        latitude: "52.94518890",
        longitude: "-0.16012460",
        nameNative: null,
        label: "Lincolnshire",
        cityCode: "GB_LIN",
      },
      {
        name: "Lisburn City Council",
        countryCode: "GB",
        latitude: "54.49815840",
        longitude: "-6.13067910",
        nameNative: null,
        label: "Lisburn City Council",
        cityCode: "GB_LSB",
      },
      {
        name: "Lisburn and Castlereagh",
        countryCode: "GB",
        latitude: "54.49815840",
        longitude: "-6.13067910",
        nameNative: null,
        label: "Lisburn and Castlereagh",
        cityCode: "GB_LBC",
      },
      {
        name: "Liverpool",
        countryCode: "GB",
        latitude: "32.65649810",
        longitude: "-115.47632410",
        nameNative: null,
        label: "Liverpool",
        cityCode: "GB_LIV",
      },
      {
        name: "London Borough of Barking and Dagenham",
        countryCode: "GB",
        latitude: "51.55406660",
        longitude: "0.13401700",
        nameNative: null,
        label: "London Borough of Barking and Dagenham",
        cityCode: "GB_BDG",
      },
      {
        name: "London Borough of Barnet",
        countryCode: "GB",
        latitude: "51.60496730",
        longitude: "-0.20762950",
        nameNative: null,
        label: "London Borough of Barnet",
        cityCode: "GB_BNE",
      },
      {
        name: "London Borough of Bexley",
        countryCode: "GB",
        latitude: "51.45190210",
        longitude: "0.11717860",
        nameNative: null,
        label: "London Borough of Bexley",
        cityCode: "GB_BEX",
      },
      {
        name: "London Borough of Brent",
        countryCode: "GB",
        latitude: "51.56728080",
        longitude: "-0.27105680",
        nameNative: null,
        label: "London Borough of Brent",
        cityCode: "GB_BEN",
      },
      {
        name: "London Borough of Bromley",
        countryCode: "GB",
        latitude: "51.36797050",
        longitude: "0.07006200",
        nameNative: null,
        label: "London Borough of Bromley",
        cityCode: "GB_BRY",
      },
      {
        name: "London Borough of Camden",
        countryCode: "GB",
        latitude: "51.54547360",
        longitude: "-0.16279020",
        nameNative: null,
        label: "London Borough of Camden",
        cityCode: "GB_CMD",
      },
      {
        name: "London Borough of Croydon",
        countryCode: "GB",
        latitude: "51.38274460",
        longitude: "-0.09851630",
        nameNative: null,
        label: "London Borough of Croydon",
        cityCode: "GB_CRY",
      },
      {
        name: "London Borough of Ealing",
        countryCode: "GB",
        latitude: "51.52503660",
        longitude: "-0.34139650",
        nameNative: null,
        label: "London Borough of Ealing",
        cityCode: "GB_EAL",
      },
      {
        name: "London Borough of Enfield",
        countryCode: "GB",
        latitude: "51.66229090",
        longitude: "-0.11806510",
        nameNative: null,
        label: "London Borough of Enfield",
        cityCode: "GB_ENF",
      },
      {
        name: "London Borough of Hackney",
        countryCode: "GB",
        latitude: "51.57344500",
        longitude: "-0.07243760",
        nameNative: null,
        label: "London Borough of Hackney",
        cityCode: "GB_HCK",
      },
      {
        name: "London Borough of Hammersmith and Fulham",
        countryCode: "GB",
        latitude: "51.49901560",
        longitude: "-0.22915000",
        nameNative: null,
        label: "London Borough of Hammersmith and Fulham",
        cityCode: "GB_HMF",
      },
      {
        name: "London Borough of Haringey",
        countryCode: "GB",
        latitude: "51.59061130",
        longitude: "-0.11097090",
        nameNative: null,
        label: "London Borough of Haringey",
        cityCode: "GB_HRY",
      },
      {
        name: "London Borough of Harrow",
        countryCode: "GB",
        latitude: "51.58816270",
        longitude: "-0.34228510",
        nameNative: null,
        label: "London Borough of Harrow",
        cityCode: "GB_HRW",
      },
      {
        name: "London Borough of Havering",
        countryCode: "GB",
        latitude: "51.57792400",
        longitude: "0.21208290",
        nameNative: null,
        label: "London Borough of Havering",
        cityCode: "GB_HAV",
      },
      {
        name: "London Borough of Hillingdon",
        countryCode: "GB",
        latitude: "51.53518320",
        longitude: "-0.44813780",
        nameNative: null,
        label: "London Borough of Hillingdon",
        cityCode: "GB_HIL",
      },
      {
        name: "London Borough of Hounslow",
        countryCode: "GB",
        latitude: "51.48283580",
        longitude: "-0.38820620",
        nameNative: null,
        label: "London Borough of Hounslow",
        cityCode: "GB_HNS",
      },
      {
        name: "London Borough of Islington",
        countryCode: "GB",
        latitude: "51.54650630",
        longitude: "-0.10580580",
        nameNative: null,
        label: "London Borough of Islington",
        cityCode: "GB_ISL",
      },
      {
        name: "London Borough of Lambeth",
        countryCode: "GB",
        latitude: "51.45714770",
        longitude: "-0.12306810",
        nameNative: null,
        label: "London Borough of Lambeth",
        cityCode: "GB_LBH",
      },
      {
        name: "London Borough of Lewisham",
        countryCode: "GB",
        latitude: "51.44145790",
        longitude: "-0.01170060",
        nameNative: null,
        label: "London Borough of Lewisham",
        cityCode: "GB_LEW",
      },
      {
        name: "London Borough of Merton",
        countryCode: "GB",
        latitude: "51.40977420",
        longitude: "-0.21080840",
        nameNative: null,
        label: "London Borough of Merton",
        cityCode: "GB_MRT",
      },
      {
        name: "London Borough of Newham",
        countryCode: "GB",
        latitude: "51.52551620",
        longitude: "0.03521630",
        nameNative: null,
        label: "London Borough of Newham",
        cityCode: "GB_NWM",
      },
      {
        name: "London Borough of Redbridge",
        countryCode: "GB",
        latitude: "51.58861210",
        longitude: "0.08239820",
        nameNative: null,
        label: "London Borough of Redbridge",
        cityCode: "GB_RDB",
      },
      {
        name: "London Borough of Richmond upon Thames",
        countryCode: "GB",
        latitude: "51.46130540",
        longitude: "-0.30377090",
        nameNative: null,
        label: "London Borough of Richmond upon Thames",
        cityCode: "GB_RIC",
      },
      {
        name: "London Borough of Southwark",
        countryCode: "GB",
        latitude: "51.48805720",
        longitude: "-0.07628380",
        nameNative: null,
        label: "London Borough of Southwark",
        cityCode: "GB_SWK",
      },
      {
        name: "London Borough of Sutton",
        countryCode: "GB",
        latitude: "51.35737620",
        longitude: "-0.17527960",
        nameNative: null,
        label: "London Borough of Sutton",
        cityCode: "GB_STN",
      },
      {
        name: "London Borough of Tower Hamlets",
        countryCode: "GB",
        latitude: "51.52026070",
        longitude: "-0.02933960",
        nameNative: null,
        label: "London Borough of Tower Hamlets",
        cityCode: "GB_TWH",
      },
      {
        name: "London Borough of Waltham Forest",
        countryCode: "GB",
        latitude: "51.58863830",
        longitude: "-0.01176250",
        nameNative: null,
        label: "London Borough of Waltham Forest",
        cityCode: "GB_WFT",
      },
      {
        name: "London Borough of Wandsworth",
        countryCode: "GB",
        latitude: "51.45682740",
        longitude: "-0.18966380",
        nameNative: null,
        label: "London Borough of Wandsworth",
        cityCode: "GB_WND",
      },
      {
        name: "Magherafelt District Council",
        countryCode: "GB",
        latitude: "54.75532790",
        longitude: "-6.60774870",
        nameNative: null,
        label: "Magherafelt District Council",
        cityCode: "GB_MFT",
      },
      {
        name: "Manchester",
        countryCode: "GB",
        latitude: "53.48075930",
        longitude: "-2.24263050",
        nameNative: null,
        label: "Manchester",
        cityCode: "GB_MAN",
      },
      {
        name: "Medway",
        countryCode: "GB",
        latitude: "42.14176410",
        longitude: "-71.39672560",
        nameNative: null,
        label: "Medway",
        cityCode: "GB_MDW",
      },
      {
        name: "Merthyr Tydfil County Borough",
        countryCode: "GB",
        latitude: "51.74674740",
        longitude: "-3.38132750",
        nameNative: null,
        label: "Merthyr Tydfil County Borough",
        cityCode: "GB_MTY",
      },
      {
        name: "Metropolitan Borough of Wigan",
        countryCode: "GB",
        latitude: "53.51348120",
        longitude: "-2.61069990",
        nameNative: null,
        label: "Metropolitan Borough of Wigan",
        cityCode: "GB_WGN",
      },
      {
        name: "Mid Ulster",
        countryCode: "GB",
        latitude: "54.64113010",
        longitude: "-6.75225490",
        nameNative: null,
        label: "Mid Ulster",
        cityCode: "GB_MUL",
      },
      {
        name: "Mid and East Antrim",
        countryCode: "GB",
        latitude: "54.93993410",
        longitude: "-6.11374230",
        nameNative: null,
        label: "Mid and East Antrim",
        cityCode: "GB_MEA",
      },
      {
        name: "Middlesbrough",
        countryCode: "GB",
        latitude: "54.57422700",
        longitude: "-1.23495600",
        nameNative: null,
        label: "Middlesbrough",
        cityCode: "GB_MDB",
      },
      {
        name: "Midlothian",
        countryCode: "GB",
        latitude: "32.47533500",
        longitude: "-97.01031810",
        nameNative: null,
        label: "Midlothian",
        cityCode: "GB_MLN",
      },
      {
        name: "Milton Keynes",
        countryCode: "GB",
        latitude: "52.08520380",
        longitude: "-0.73331330",
        nameNative: null,
        label: "Milton Keynes",
        cityCode: "GB_MIK",
      },
      {
        name: "Monmouthshire",
        countryCode: "GB",
        latitude: "51.81161000",
        longitude: "-2.71634170",
        nameNative: null,
        label: "Monmouthshire",
        cityCode: "GB_MON",
      },
      {
        name: "Moray",
        countryCode: "GB",
        latitude: "57.64984760",
        longitude: "-3.31680390",
        nameNative: null,
        label: "Moray",
        cityCode: "GB_MRY",
      },
      {
        name: "Moyle District Council",
        countryCode: "GB",
        latitude: "55.20473270",
        longitude: "-6.25317400",
        nameNative: null,
        label: "Moyle District Council",
        cityCode: "GB_MYL",
      },
      {
        name: "Neath Port Talbot County Borough",
        countryCode: "GB",
        latitude: "51.59785190",
        longitude: "-3.78396680",
        nameNative: null,
        label: "Neath Port Talbot County Borough",
        cityCode: "GB_NTL",
      },
      {
        name: "Newcastle upon Tyne",
        countryCode: "GB",
        latitude: "54.97825200",
        longitude: "-1.61778000",
        nameNative: null,
        label: "Newcastle upon Tyne",
        cityCode: "GB_NET",
      },
      {
        name: "Newport",
        countryCode: "GB",
        latitude: "37.52782340",
        longitude: "-94.10438760",
        nameNative: null,
        label: "Newport",
        cityCode: "GB_NWP",
      },
      {
        name: "Newry and Mourne District Council",
        countryCode: "GB",
        latitude: "54.17425050",
        longitude: "-6.33919920",
        nameNative: null,
        label: "Newry and Mourne District Council",
        cityCode: "GB_NYM",
      },
      {
        name: "Newry, Mourne and Down",
        countryCode: "GB",
        latitude: "54.24342870",
        longitude: "-5.95779590",
        nameNative: null,
        label: "Newry, Mourne and Down",
        cityCode: "GB_NMD",
      },
      {
        name: "Newtownabbey Borough Council",
        countryCode: "GB",
        latitude: "54.67924220",
        longitude: "-5.95911020",
        nameNative: null,
        label: "Newtownabbey Borough Council",
        cityCode: "GB_NTA",
      },
      {
        name: "Norfolk",
        countryCode: "GB",
        latitude: "36.85076890",
        longitude: "-76.28587260",
        nameNative: null,
        label: "Norfolk",
        cityCode: "GB_NFK",
      },
      {
        name: "North Ayrshire",
        countryCode: "GB",
        latitude: "55.64167310",
        longitude: "-4.75946000",
        nameNative: null,
        label: "North Ayrshire",
        cityCode: "GB_NAY",
      },
      {
        name: "North Down Borough Council",
        countryCode: "GB",
        latitude: "54.65362970",
        longitude: "-5.67249250",
        nameNative: null,
        label: "North Down Borough Council",
        cityCode: "GB_NDN",
      },
      {
        name: "North East Lincolnshire",
        countryCode: "GB",
        latitude: "53.56682010",
        longitude: "-0.08150660",
        nameNative: null,
        label: "North East Lincolnshire",
        cityCode: "GB_NEL",
      },
      {
        name: "North Lanarkshire",
        countryCode: "GB",
        latitude: "55.86624320",
        longitude: "-3.96131440",
        nameNative: null,
        label: "North Lanarkshire",
        cityCode: "GB_NLK",
      },
      {
        name: "North Lincolnshire",
        countryCode: "GB",
        latitude: "53.60555920",
        longitude: "-0.55965820",
        nameNative: null,
        label: "North Lincolnshire",
        cityCode: "GB_NLN",
      },
      {
        name: "North Somerset",
        countryCode: "GB",
        latitude: "51.38790280",
        longitude: "-2.77810910",
        nameNative: null,
        label: "North Somerset",
        cityCode: "GB_NSM",
      },
      {
        name: "North Tyneside",
        countryCode: "GB",
        latitude: "55.01823990",
        longitude: "-1.48584360",
        nameNative: null,
        label: "North Tyneside",
        cityCode: "GB_NTY",
      },
      {
        name: "North Yorkshire",
        countryCode: "GB",
        latitude: "53.99150280",
        longitude: "-1.54120150",
        nameNative: null,
        label: "North Yorkshire",
        cityCode: "GB_NYK",
      },
      {
        name: "Northamptonshire",
        countryCode: "GB",
        latitude: "52.27299440",
        longitude: "-0.87555150",
        nameNative: null,
        label: "Northamptonshire",
        cityCode: "GB_NTH",
      },
      {
        name: "Northern Ireland",
        countryCode: "GB",
        latitude: "54.78771490",
        longitude: "-6.49231450",
        nameNative: null,
        label: "Northern Ireland",
        cityCode: "GB_NIR",
      },
      {
        name: "Northumberland",
        countryCode: "GB",
        latitude: "55.20825420",
        longitude: "-2.07841380",
        nameNative: null,
        label: "Northumberland",
        cityCode: "GB_NBL",
      },
      {
        name: "Nottinghamshire",
        countryCode: "GB",
        latitude: "53.10031900",
        longitude: "-0.99363060",
        nameNative: null,
        label: "Nottinghamshire",
        cityCode: "GB_NTT",
      },
      {
        name: "Oldham",
        countryCode: "GB",
        latitude: "42.20405980",
        longitude: "-71.20481190",
        nameNative: null,
        label: "Oldham",
        cityCode: "GB_OLD",
      },
      {
        name: "Omagh District Council",
        countryCode: "GB",
        latitude: "54.45135240",
        longitude: "-7.71250180",
        nameNative: null,
        label: "Omagh District Council",
        cityCode: "GB_OMH",
      },
      {
        name: "Orkney Islands",
        countryCode: "GB",
        latitude: "58.98094010",
        longitude: "-2.96052060",
        nameNative: null,
        label: "Orkney Islands",
        cityCode: "GB_ORK",
      },
      {
        name: "Outer Hebrides",
        countryCode: "GB",
        latitude: "57.75989180",
        longitude: "-7.01940340",
        nameNative: null,
        label: "Outer Hebrides",
        cityCode: "GB_ELS",
      },
      {
        name: "Oxfordshire",
        countryCode: "GB",
        latitude: "51.76120560",
        longitude: "-1.24646740",
        nameNative: null,
        label: "Oxfordshire",
        cityCode: "GB_OXF",
      },
      {
        name: "Pembrokeshire",
        countryCode: "GB",
        latitude: "51.67407800",
        longitude: "-4.90887850",
        nameNative: null,
        label: "Pembrokeshire",
        cityCode: "GB_PEM",
      },
      {
        name: "Perth and Kinross",
        countryCode: "GB",
        latitude: "56.39538170",
        longitude: "-3.42835470",
        nameNative: null,
        label: "Perth and Kinross",
        cityCode: "GB_PKN",
      },
      {
        name: "Poole",
        countryCode: "GB",
        latitude: "50.71505000",
        longitude: "-1.98724800",
        nameNative: null,
        label: "Poole",
        cityCode: "GB_POL",
      },
      {
        name: "Powys",
        countryCode: "GB",
        latitude: "52.64642490",
        longitude: "-3.32609040",
        nameNative: null,
        label: "Powys",
        cityCode: "GB_POW",
      },
      {
        name: "Reading",
        countryCode: "GB",
        latitude: "36.14866590",
        longitude: "-95.98400120",
        nameNative: null,
        label: "Reading",
        cityCode: "GB_RDG",
      },
      {
        name: "Redcar and Cleveland",
        countryCode: "GB",
        latitude: "54.59713440",
        longitude: "-1.07759970",
        nameNative: null,
        label: "Redcar and Cleveland",
        cityCode: "GB_RCC",
      },
      {
        name: "Renfrewshire",
        countryCode: "GB",
        latitude: "55.84665400",
        longitude: "-4.53312590",
        nameNative: null,
        label: "Renfrewshire",
        cityCode: "GB_RFW",
      },
      {
        name: "Rhondda Cynon Taf",
        countryCode: "GB",
        latitude: "51.64902070",
        longitude: "-3.42886920",
        nameNative: null,
        label: "Rhondda Cynon Taf",
        cityCode: "GB_RCT",
      },
      {
        name: "Rochdale",
        countryCode: "GB",
        latitude: "53.60971360",
        longitude: "-2.15610000",
        nameNative: null,
        label: "Rochdale",
        cityCode: "GB_RCH",
      },
      {
        name: "Rotherham",
        countryCode: "GB",
        latitude: "53.43260350",
        longitude: "-1.36350090",
        nameNative: null,
        label: "Rotherham",
        cityCode: "GB_ROT",
      },
      {
        name: "Royal Borough of Greenwich",
        countryCode: "GB",
        latitude: "51.48346270",
        longitude: "0.05862020",
        nameNative: null,
        label: "Royal Borough of Greenwich",
        cityCode: "GB_GRE",
      },
      {
        name: "Royal Borough of Kensington and Chelsea",
        countryCode: "GB",
        latitude: "51.49908050",
        longitude: "-0.19382530",
        nameNative: null,
        label: "Royal Borough of Kensington and Chelsea",
        cityCode: "GB_KEC",
      },
      {
        name: "Royal Borough of Kingston upon Thames",
        countryCode: "GB",
        latitude: "51.37811700",
        longitude: "-0.29270900",
        nameNative: null,
        label: "Royal Borough of Kingston upon Thames",
        cityCode: "GB_KTT",
      },
      {
        name: "Rutland",
        countryCode: "GB",
        latitude: "43.61062370",
        longitude: "-72.97260650",
        nameNative: null,
        label: "Rutland",
        cityCode: "GB_RUT",
      },
      {
        name: "Saint Helena",
        countryCode: "GB",
        latitude: "-15.96501040",
        longitude: "-5.70892410",
        nameNative: null,
        label: "Saint Helena",
        cityCode: "GB_SH-HL",
      },
      {
        name: "Salford",
        countryCode: "GB",
        latitude: "53.48752350",
        longitude: "-2.29012640",
        nameNative: null,
        label: "Salford",
        cityCode: "GB_SLF",
      },
      {
        name: "Sandwell",
        countryCode: "GB",
        latitude: "52.53616740",
        longitude: "-2.01079300",
        nameNative: null,
        label: "Sandwell",
        cityCode: "GB_SAW",
      },
      {
        name: "Scotland",
        countryCode: "GB",
        latitude: "56.49067120",
        longitude: "-4.20264580",
        nameNative: null,
        label: "Scotland",
        cityCode: "GB_SCT",
      },
      {
        name: "Scottish Borders",
        countryCode: "GB",
        latitude: "55.54856970",
        longitude: "-2.78613880",
        nameNative: null,
        label: "Scottish Borders",
        cityCode: "GB_SCB",
      },
      {
        name: "Sefton",
        countryCode: "GB",
        latitude: "53.50344490",
        longitude: "-2.97035900",
        nameNative: null,
        label: "Sefton",
        cityCode: "GB_SFT",
      },
      {
        name: "Sheffield",
        countryCode: "GB",
        latitude: "36.09507430",
        longitude: "-80.27884660",
        nameNative: null,
        label: "Sheffield",
        cityCode: "GB_SHF",
      },
      {
        name: "Shetland Islands",
        countryCode: "GB",
        latitude: "60.52965070",
        longitude: "-1.26594090",
        nameNative: null,
        label: "Shetland Islands",
        cityCode: "GB_ZET",
      },
      {
        name: "Shropshire",
        countryCode: "GB",
        latitude: "52.70636570",
        longitude: "-2.74178490",
        nameNative: null,
        label: "Shropshire",
        cityCode: "GB_SHR",
      },
      {
        name: "Slough",
        countryCode: "GB",
        latitude: "51.51053840",
        longitude: "-0.59504060",
        nameNative: null,
        label: "Slough",
        cityCode: "GB_SLG",
      },
      {
        name: "Solihull",
        countryCode: "GB",
        latitude: "52.41181100",
        longitude: "-1.77761000",
        nameNative: null,
        label: "Solihull",
        cityCode: "GB_SOL",
      },
      {
        name: "Somerset",
        countryCode: "GB",
        latitude: "51.10509700",
        longitude: "-2.92623070",
        nameNative: null,
        label: "Somerset",
        cityCode: "GB_SOM",
      },
      {
        name: "South Ayrshire",
        countryCode: "GB",
        latitude: "55.45889880",
        longitude: "-4.62919940",
        nameNative: null,
        label: "South Ayrshire",
        cityCode: "GB_SAY",
      },
      {
        name: "South Gloucestershire",
        countryCode: "GB",
        latitude: "51.52643610",
        longitude: "-2.47284870",
        nameNative: null,
        label: "South Gloucestershire",
        cityCode: "GB_SGC",
      },
      {
        name: "South Lanarkshire",
        countryCode: "GB",
        latitude: "55.67359090",
        longitude: "-3.78196610",
        nameNative: null,
        label: "South Lanarkshire",
        cityCode: "GB_SLK",
      },
      {
        name: "South Tyneside",
        countryCode: "GB",
        latitude: "54.96366930",
        longitude: "-1.44186340",
        nameNative: null,
        label: "South Tyneside",
        cityCode: "GB_STY",
      },
      {
        name: "Southend-on-Sea",
        countryCode: "GB",
        latitude: "51.54592690",
        longitude: "0.70771230",
        nameNative: null,
        label: "Southend-on-Sea",
        cityCode: "GB_SOS",
      },
      {
        name: "St Helens",
        countryCode: "GB",
        latitude: "45.85896100",
        longitude: "-122.82123560",
        nameNative: null,
        label: "St Helens",
        cityCode: "GB_SHN",
      },
      {
        name: "Staffordshire",
        countryCode: "GB",
        latitude: "52.87927450",
        longitude: "-2.05718680",
        nameNative: null,
        label: "Staffordshire",
        cityCode: "GB_STS",
      },
      {
        name: "Stirling",
        countryCode: "GB",
        latitude: "56.11652270",
        longitude: "-3.93690290",
        nameNative: null,
        label: "Stirling",
        cityCode: "GB_STG",
      },
      {
        name: "Stockport",
        countryCode: "GB",
        latitude: "53.41063160",
        longitude: "-2.15753320",
        nameNative: null,
        label: "Stockport",
        cityCode: "GB_SKP",
      },
      {
        name: "Stockton-on-Tees",
        countryCode: "GB",
        latitude: "54.57045510",
        longitude: "-1.32898210",
        nameNative: null,
        label: "Stockton-on-Tees",
        cityCode: "GB_STT",
      },
      {
        name: "Strabane District Council",
        countryCode: "GB",
        latitude: "54.82738650",
        longitude: "-7.46331030",
        nameNative: null,
        label: "Strabane District Council",
        cityCode: "GB_STB",
      },
      {
        name: "Suffolk",
        countryCode: "GB",
        latitude: "52.18724720",
        longitude: "0.97078010",
        nameNative: null,
        label: "Suffolk",
        cityCode: "GB_SFK",
      },
      {
        name: "Surrey",
        countryCode: "GB",
        latitude: "51.31475930",
        longitude: "-0.55995010",
        nameNative: null,
        label: "Surrey",
        cityCode: "GB_SRY",
      },
      {
        name: "Swindon",
        countryCode: "GB",
        latitude: "51.55577390",
        longitude: "-1.77971760",
        nameNative: null,
        label: "Swindon",
        cityCode: "GB_SWD",
      },
      {
        name: "Tameside",
        countryCode: "GB",
        latitude: "53.48058280",
        longitude: "-2.08098910",
        nameNative: null,
        label: "Tameside",
        cityCode: "GB_TAM",
      },
      {
        name: "Telford and Wrekin",
        countryCode: "GB",
        latitude: "52.74099160",
        longitude: "-2.48685860",
        nameNative: null,
        label: "Telford and Wrekin",
        cityCode: "GB_TFW",
      },
      {
        name: "Thurrock",
        countryCode: "GB",
        latitude: "51.49345570",
        longitude: "0.35291970",
        nameNative: null,
        label: "Thurrock",
        cityCode: "GB_THR",
      },
      {
        name: "Torbay",
        countryCode: "GB",
        latitude: "50.43923290",
        longitude: "-3.53698990",
        nameNative: null,
        label: "Torbay",
        cityCode: "GB_TOB",
      },
      {
        name: "Torfaen",
        countryCode: "GB",
        latitude: "51.70022530",
        longitude: "-3.04460150",
        nameNative: null,
        label: "Torfaen",
        cityCode: "GB_TOF",
      },
      {
        name: "Trafford",
        countryCode: "GB",
        latitude: "40.38562460",
        longitude: "-79.75893470",
        nameNative: null,
        label: "Trafford",
        cityCode: "GB_TRF",
      },
      {
        name: "United Kingdom",
        countryCode: "GB",
        latitude: "55.37805100",
        longitude: "-3.43597300",
        nameNative: null,
        label: "United Kingdom",
        cityCode: "GB_UKM",
      },
      {
        name: "Vale of Glamorgan",
        countryCode: "GB",
        latitude: "51.40959580",
        longitude: "-3.48481670",
        nameNative: null,
        label: "Vale of Glamorgan",
        cityCode: "GB_VGL",
      },
      {
        name: "Wakefield",
        countryCode: "GB",
        latitude: "42.50393950",
        longitude: "-71.07233910",
        nameNative: null,
        label: "Wakefield",
        cityCode: "GB_WKF",
      },
      {
        name: "Wales",
        countryCode: "GB",
        latitude: "52.13066070",
        longitude: "-3.78371170",
        nameNative: null,
        label: "Wales",
        cityCode: "GB_WLS",
      },
      {
        name: "Walsall",
        countryCode: "GB",
        latitude: "52.58621400",
        longitude: "-1.98291900",
        nameNative: null,
        label: "Walsall",
        cityCode: "GB_WLL",
      },
      {
        name: "Warrington",
        countryCode: "GB",
        latitude: "40.24927410",
        longitude: "-75.13406040",
        nameNative: null,
        label: "Warrington",
        cityCode: "GB_WRT",
      },
      {
        name: "Warwickshire",
        countryCode: "GB",
        latitude: "52.26713530",
        longitude: "-1.46752160",
        nameNative: null,
        label: "Warwickshire",
        cityCode: "GB_WAR",
      },
      {
        name: "West Berkshire",
        countryCode: "GB",
        latitude: "51.43082550",
        longitude: "-1.14449270",
        nameNative: null,
        label: "West Berkshire",
        cityCode: "GB_WBK",
      },
      {
        name: "West Dunbartonshire",
        countryCode: "GB",
        latitude: "55.94509250",
        longitude: "-4.56462590",
        nameNative: null,
        label: "West Dunbartonshire",
        cityCode: "GB_WDU",
      },
      {
        name: "West Lothian",
        countryCode: "GB",
        latitude: "55.90701980",
        longitude: "-3.55171670",
        nameNative: null,
        label: "West Lothian",
        cityCode: "GB_WLN",
      },
      {
        name: "West Sussex",
        countryCode: "GB",
        latitude: "50.92801430",
        longitude: "-0.46170750",
        nameNative: null,
        label: "West Sussex",
        cityCode: "GB_WSX",
      },
      {
        name: "Wiltshire",
        countryCode: "GB",
        latitude: "51.34919960",
        longitude: "-1.99271050",
        nameNative: null,
        label: "Wiltshire",
        cityCode: "GB_WIL",
      },
      {
        name: "Windsor and Maidenhead",
        countryCode: "GB",
        latitude: "51.47997120",
        longitude: "-0.62425650",
        nameNative: null,
        label: "Windsor and Maidenhead",
        cityCode: "GB_WNM",
      },
      {
        name: "Wirral",
        countryCode: "GB",
        latitude: "53.37271810",
        longitude: "-3.07375400",
        nameNative: null,
        label: "Wirral",
        cityCode: "GB_WRL",
      },
      {
        name: "Wokingham",
        countryCode: "GB",
        latitude: "51.41045700",
        longitude: "-0.83386100",
        nameNative: null,
        label: "Wokingham",
        cityCode: "GB_WOK",
      },
      {
        name: "Worcestershire",
        countryCode: "GB",
        latitude: "52.25452250",
        longitude: "-2.26683820",
        nameNative: null,
        label: "Worcestershire",
        cityCode: "GB_WOR",
      },
      {
        name: "Wrexham County Borough",
        countryCode: "GB",
        latitude: "53.03013780",
        longitude: "-3.02614870",
        nameNative: null,
        label: "Wrexham County Borough",
        cityCode: "GB_WRX",
      },
    ],
    US: [
      {
        name: "Alabama",
        countryCode: "US",
        latitude: "32.31823140",
        longitude: "-86.90229800",
        nameNative: null,
        label: "Alabama",
        cityCode: "US_AL",
      },
      {
        name: "Alaska",
        countryCode: "US",
        latitude: "64.20084130",
        longitude: "-149.49367330",
        nameNative: null,
        label: "Alaska",
        cityCode: "US_AK",
      },
      {
        name: "American Samoa",
        countryCode: "US",
        latitude: "-14.27097200",
        longitude: "-170.13221700",
        nameNative: null,
        label: "American Samoa",
        cityCode: "US_AS",
      },
      {
        name: "Arizona",
        countryCode: "US",
        latitude: "34.04892810",
        longitude: "-111.09373110",
        nameNative: null,
        label: "Arizona",
        cityCode: "US_AZ",
      },
      {
        name: "Arkansas",
        countryCode: "US",
        latitude: "35.20105000",
        longitude: "-91.83183340",
        nameNative: null,
        label: "Arkansas",
        cityCode: "US_AR",
      },
      {
        name: "Baker Island",
        countryCode: "US",
        latitude: "0.19362660",
        longitude: "-176.47690800",
        nameNative: null,
        label: "Baker Island",
        cityCode: "US_UM-81",
      },
      {
        name: "California",
        countryCode: "US",
        latitude: "36.77826100",
        longitude: "-119.41793240",
        nameNative: null,
        label: "California",
        cityCode: "US_CA",
      },
      {
        name: "Colorado",
        countryCode: "US",
        latitude: "39.55005070",
        longitude: "-105.78206740",
        nameNative: null,
        label: "Colorado",
        cityCode: "US_CO",
      },
      {
        name: "Connecticut",
        countryCode: "US",
        latitude: "41.60322070",
        longitude: "-73.08774900",
        nameNative: null,
        label: "Connecticut",
        cityCode: "US_CT",
      },
      {
        name: "Delaware",
        countryCode: "US",
        latitude: "38.91083250",
        longitude: "-75.52766990",
        nameNative: null,
        label: "Delaware",
        cityCode: "US_DE",
      },
      {
        name: "District of Columbia",
        countryCode: "US",
        latitude: "38.90719230",
        longitude: "-77.03687070",
        nameNative: null,
        label: "District of Columbia",
        cityCode: "US_DC",
      },
      {
        name: "Florida",
        countryCode: "US",
        latitude: "27.66482740",
        longitude: "-81.51575350",
        nameNative: null,
        label: "Florida",
        cityCode: "US_FL",
      },
      {
        name: "Georgia",
        countryCode: "US",
        latitude: "32.16562210",
        longitude: "-82.90007510",
        nameNative: null,
        label: "Georgia",
        cityCode: "US_GA",
      },
      {
        name: "Guam",
        countryCode: "US",
        latitude: "13.44430400",
        longitude: "144.79373100",
        nameNative: null,
        label: "Guam",
        cityCode: "US_GU",
      },
      {
        name: "Hawaii",
        countryCode: "US",
        latitude: "19.89676620",
        longitude: "-155.58278180",
        nameNative: null,
        label: "Hawaii",
        cityCode: "US_HI",
      },
      {
        name: "Howland Island",
        countryCode: "US",
        latitude: "0.81132190",
        longitude: "-176.61827360",
        nameNative: null,
        label: "Howland Island",
        cityCode: "US_UM-84",
      },
      {
        name: "Idaho",
        countryCode: "US",
        latitude: "44.06820190",
        longitude: "-114.74204080",
        nameNative: null,
        label: "Idaho",
        cityCode: "US_ID",
      },
      {
        name: "Illinois",
        countryCode: "US",
        latitude: "40.63312490",
        longitude: "-89.39852830",
        nameNative: null,
        label: "Illinois",
        cityCode: "US_IL",
      },
      {
        name: "Indiana",
        countryCode: "US",
        latitude: "40.26719410",
        longitude: "-86.13490190",
        nameNative: null,
        label: "Indiana",
        cityCode: "US_IN",
      },
      {
        name: "Iowa",
        countryCode: "US",
        latitude: "41.87800250",
        longitude: "-93.09770200",
        nameNative: null,
        label: "Iowa",
        cityCode: "US_IA",
      },
      {
        name: "Jarvis Island",
        countryCode: "US",
        latitude: "-0.37435030",
        longitude: "-159.99672060",
        nameNative: null,
        label: "Jarvis Island",
        cityCode: "US_UM-86",
      },
      {
        name: "Johnston Atoll",
        countryCode: "US",
        latitude: "16.72950350",
        longitude: "-169.53364770",
        nameNative: null,
        label: "Johnston Atoll",
        cityCode: "US_UM-67",
      },
      {
        name: "Kansas",
        countryCode: "US",
        latitude: "39.01190200",
        longitude: "-98.48424650",
        nameNative: null,
        label: "Kansas",
        cityCode: "US_KS",
      },
      {
        name: "Kentucky",
        countryCode: "US",
        latitude: "37.83933320",
        longitude: "-84.27001790",
        nameNative: null,
        label: "Kentucky",
        cityCode: "US_KY",
      },
      {
        name: "Kingman Reef",
        countryCode: "US",
        latitude: "6.38333300",
        longitude: "-162.41666700",
        nameNative: null,
        label: "Kingman Reef",
        cityCode: "US_UM-89",
      },
      {
        name: "Louisiana",
        countryCode: "US",
        latitude: "30.98429770",
        longitude: "-91.96233270",
        nameNative: null,
        label: "Louisiana",
        cityCode: "US_LA",
      },
      {
        name: "Maine",
        countryCode: "US",
        latitude: "45.25378300",
        longitude: "-69.44546890",
        nameNative: null,
        label: "Maine",
        cityCode: "US_ME",
      },
      {
        name: "Maryland",
        countryCode: "US",
        latitude: "39.04575490",
        longitude: "-76.64127120",
        nameNative: null,
        label: "Maryland",
        cityCode: "US_MD",
      },
      {
        name: "Massachusetts",
        countryCode: "US",
        latitude: "42.40721070",
        longitude: "-71.38243740",
        nameNative: null,
        label: "Massachusetts",
        cityCode: "US_MA",
      },
      {
        name: "Michigan",
        countryCode: "US",
        latitude: "44.31484430",
        longitude: "-85.60236430",
        nameNative: null,
        label: "Michigan",
        cityCode: "US_MI",
      },
      {
        name: "Midway Atoll",
        countryCode: "US",
        latitude: "28.20721680",
        longitude: "-177.37349260",
        nameNative: null,
        label: "Midway Atoll",
        cityCode: "US_UM-71",
      },
      {
        name: "Minnesota",
        countryCode: "US",
        latitude: "46.72955300",
        longitude: "-94.68589980",
        nameNative: null,
        label: "Minnesota",
        cityCode: "US_MN",
      },
      {
        name: "Mississippi",
        countryCode: "US",
        latitude: "32.35466790",
        longitude: "-89.39852830",
        nameNative: null,
        label: "Mississippi",
        cityCode: "US_MS",
      },
      {
        name: "Missouri",
        countryCode: "US",
        latitude: "37.96425290",
        longitude: "-91.83183340",
        nameNative: null,
        label: "Missouri",
        cityCode: "US_MO",
      },
      {
        name: "Montana",
        countryCode: "US",
        latitude: "46.87968220",
        longitude: "-110.36256580",
        nameNative: null,
        label: "Montana",
        cityCode: "US_MT",
      },
      {
        name: "Navassa Island",
        countryCode: "US",
        latitude: "18.41006890",
        longitude: "-75.01146120",
        nameNative: null,
        label: "Navassa Island",
        cityCode: "US_UM-76",
      },
      {
        name: "Nebraska",
        countryCode: "US",
        latitude: "41.49253740",
        longitude: "-99.90181310",
        nameNative: null,
        label: "Nebraska",
        cityCode: "US_NE",
      },
      {
        name: "Nevada",
        countryCode: "US",
        latitude: "38.80260970",
        longitude: "-116.41938900",
        nameNative: null,
        label: "Nevada",
        cityCode: "US_NV",
      },
      {
        name: "New Hampshire",
        countryCode: "US",
        latitude: "43.19385160",
        longitude: "-71.57239530",
        nameNative: null,
        label: "New Hampshire",
        cityCode: "US_NH",
      },
      {
        name: "New Jersey",
        countryCode: "US",
        latitude: "40.05832380",
        longitude: "-74.40566120",
        nameNative: null,
        label: "New Jersey",
        cityCode: "US_NJ",
      },
      {
        name: "New Mexico",
        countryCode: "US",
        latitude: "34.51994020",
        longitude: "-105.87009010",
        nameNative: null,
        label: "New Mexico",
        cityCode: "US_NM",
      },
      {
        name: "New York",
        countryCode: "US",
        latitude: "40.71277530",
        longitude: "-74.00597280",
        nameNative: null,
        label: "New York",
        cityCode: "US_NY",
      },
      {
        name: "North Carolina",
        countryCode: "US",
        latitude: "35.75957310",
        longitude: "-79.01929970",
        nameNative: null,
        label: "North Carolina",
        cityCode: "US_NC",
      },
      {
        name: "North Dakota",
        countryCode: "US",
        latitude: "47.55149260",
        longitude: "-101.00201190",
        nameNative: null,
        label: "North Dakota",
        cityCode: "US_ND",
      },
      {
        name: "Northern Mariana Islands",
        countryCode: "US",
        latitude: "15.09790000",
        longitude: "145.67390000",
        nameNative: null,
        label: "Northern Mariana Islands",
        cityCode: "US_MP",
      },
      {
        name: "Ohio",
        countryCode: "US",
        latitude: "40.41728710",
        longitude: "-82.90712300",
        nameNative: null,
        label: "Ohio",
        cityCode: "US_OH",
      },
      {
        name: "Oklahoma",
        countryCode: "US",
        latitude: "35.46756020",
        longitude: "-97.51642760",
        nameNative: null,
        label: "Oklahoma",
        cityCode: "US_OK",
      },
      {
        name: "Oregon",
        countryCode: "US",
        latitude: "43.80413340",
        longitude: "-120.55420120",
        nameNative: null,
        label: "Oregon",
        cityCode: "US_OR",
      },
      {
        name: "Palmyra Atoll",
        countryCode: "US",
        latitude: "5.88850260",
        longitude: "-162.07866560",
        nameNative: null,
        label: "Palmyra Atoll",
        cityCode: "US_UM-95",
      },
      {
        name: "Pennsylvania",
        countryCode: "US",
        latitude: "41.20332160",
        longitude: "-77.19452470",
        nameNative: null,
        label: "Pennsylvania",
        cityCode: "US_PA",
      },
      {
        name: "Puerto Rico",
        countryCode: "US",
        latitude: "18.22083300",
        longitude: "-66.59014900",
        nameNative: null,
        label: "Puerto Rico",
        cityCode: "US_PR",
      },
      {
        name: "Rhode Island",
        countryCode: "US",
        latitude: "41.58009450",
        longitude: "-71.47742910",
        nameNative: null,
        label: "Rhode Island",
        cityCode: "US_RI",
      },
      {
        name: "South Carolina",
        countryCode: "US",
        latitude: "33.83608100",
        longitude: "-81.16372450",
        nameNative: null,
        label: "South Carolina",
        cityCode: "US_SC",
      },
      {
        name: "South Dakota",
        countryCode: "US",
        latitude: "43.96951480",
        longitude: "-99.90181310",
        nameNative: null,
        label: "South Dakota",
        cityCode: "US_SD",
      },
      {
        name: "Tennessee",
        countryCode: "US",
        latitude: "35.51749130",
        longitude: "-86.58044730",
        nameNative: null,
        label: "Tennessee",
        cityCode: "US_TN",
      },
      {
        name: "Texas",
        countryCode: "US",
        latitude: "31.96859880",
        longitude: "-99.90181310",
        nameNative: null,
        label: "Texas",
        cityCode: "US_TX",
      },
      {
        name: "United States Minor Outlying Islands",
        countryCode: "US",
        latitude: "19.28231920",
        longitude: "166.64704700",
        nameNative: null,
        label: "United States Minor Outlying Islands",
        cityCode: "US_UM",
      },
      {
        name: "United States Virgin Islands",
        countryCode: "US",
        latitude: "18.33576500",
        longitude: "-64.89633500",
        nameNative: null,
        label: "United States Virgin Islands",
        cityCode: "US_VI",
      },
      {
        name: "Utah",
        countryCode: "US",
        latitude: "39.32098010",
        longitude: "-111.09373110",
        nameNative: null,
        label: "Utah",
        cityCode: "US_UT",
      },
      {
        name: "Vermont",
        countryCode: "US",
        latitude: "44.55880280",
        longitude: "-72.57784150",
        nameNative: null,
        label: "Vermont",
        cityCode: "US_VT",
      },
      {
        name: "Virginia",
        countryCode: "US",
        latitude: "37.43157340",
        longitude: "-78.65689420",
        nameNative: null,
        label: "Virginia",
        cityCode: "US_VA",
      },
      {
        name: "Wake Island",
        countryCode: "US",
        latitude: "19.27961900",
        longitude: "166.64993480",
        nameNative: null,
        label: "Wake Island",
        cityCode: "US_UM-79",
      },
      {
        name: "Washington",
        countryCode: "US",
        latitude: "47.75107410",
        longitude: "-120.74013850",
        nameNative: null,
        label: "Washington",
        cityCode: "US_WA",
      },
      {
        name: "West Virginia",
        countryCode: "US",
        latitude: "38.59762620",
        longitude: "-80.45490260",
        nameNative: null,
        label: "West Virginia",
        cityCode: "US_WV",
      },
      {
        name: "Wisconsin",
        countryCode: "US",
        latitude: "43.78443970",
        longitude: "-88.78786780",
        nameNative: null,
        label: "Wisconsin",
        cityCode: "US_WI",
      },
      {
        name: "Wyoming",
        countryCode: "US",
        latitude: "43.07596780",
        longitude: "-107.29028390",
        nameNative: null,
        label: "Wyoming",
        cityCode: "US_WY",
      },
    ],
    UY: [
      {
        name: "Artigas Department",
        countryCode: "UY",
        latitude: "-30.61751120",
        longitude: "-56.95945590",
        nameNative: null,
        label: "Artigas Department",
        cityCode: "UY_AR",
      },
      {
        name: "Canelones Department",
        countryCode: "UY",
        latitude: "-34.54087170",
        longitude: "-55.93076000",
        nameNative: null,
        label: "Canelones Department",
        cityCode: "UY_CA",
      },
      {
        name: "Cerro Largo Department",
        countryCode: "UY",
        latitude: "-32.44110320",
        longitude: "-54.35217530",
        nameNative: null,
        label: "Cerro Largo Department",
        cityCode: "UY_CL",
      },
      {
        name: "Colonia Department",
        countryCode: "UY",
        latitude: "-34.12946780",
        longitude: "-57.66051840",
        nameNative: null,
        label: "Colonia Department",
        cityCode: "UY_CO",
      },
      {
        name: "Durazno Department",
        countryCode: "UY",
        latitude: "-33.02324540",
        longitude: "-56.02846440",
        nameNative: null,
        label: "Durazno Department",
        cityCode: "UY_DU",
      },
      {
        name: "Flores Department",
        countryCode: "UY",
        latitude: "-33.57337530",
        longitude: "-56.89450280",
        nameNative: null,
        label: "Flores Department",
        cityCode: "UY_FS",
      },
      {
        name: "Florida Department",
        countryCode: "UY",
        latitude: "28.03594950",
        longitude: "-82.45792890",
        nameNative: null,
        label: "Florida Department",
        cityCode: "UY_FD",
      },
      {
        name: "Lavalleja Department",
        countryCode: "UY",
        latitude: "-33.92261750",
        longitude: "-54.97657940",
        nameNative: null,
        label: "Lavalleja Department",
        cityCode: "UY_LA",
      },
      {
        name: "Maldonado Department",
        countryCode: "UY",
        latitude: "-34.55979320",
        longitude: "-54.86285520",
        nameNative: null,
        label: "Maldonado Department",
        cityCode: "UY_MA",
      },
      {
        name: "Montevideo Department",
        countryCode: "UY",
        latitude: "-34.81815870",
        longitude: "-56.21382560",
        nameNative: null,
        label: "Montevideo Department",
        cityCode: "UY_MO",
      },
      {
        name: "Paysandú Department",
        countryCode: "UY",
        latitude: "-32.06673660",
        longitude: "-57.33647890",
        nameNative: null,
        label: "Paysandú Department",
        cityCode: "UY_PA",
      },
      {
        name: "Rivera Department",
        countryCode: "UY",
        latitude: "-31.48174210",
        longitude: "-55.24357590",
        nameNative: null,
        label: "Rivera Department",
        cityCode: "UY_RV",
      },
      {
        name: "Rocha Department",
        countryCode: "UY",
        latitude: "-33.96900810",
        longitude: "-54.02148500",
        nameNative: null,
        label: "Rocha Department",
        cityCode: "UY_RO",
      },
      {
        name: "Río Negro Department",
        countryCode: "UY",
        latitude: "-32.76763560",
        longitude: "-57.42952070",
        nameNative: null,
        label: "Río Negro Department",
        cityCode: "UY_RN",
      },
      {
        name: "Salto Department",
        countryCode: "UY",
        latitude: "-31.38802800",
        longitude: "-57.96124550",
        nameNative: null,
        label: "Salto Department",
        cityCode: "UY_SA",
      },
      {
        name: "San José Department",
        countryCode: "UY",
        latitude: "37.34929680",
        longitude: "-121.90560490",
        nameNative: null,
        label: "San José Department",
        cityCode: "UY_SJ",
      },
      {
        name: "Soriano Department",
        countryCode: "UY",
        latitude: "-33.51027920",
        longitude: "-57.74981030",
        nameNative: null,
        label: "Soriano Department",
        cityCode: "UY_SO",
      },
      {
        name: "Tacuarembó Department",
        countryCode: "UY",
        latitude: "-31.72068370",
        longitude: "-55.98598870",
        nameNative: null,
        label: "Tacuarembó Department",
        cityCode: "UY_TA",
      },
      {
        name: "Treinta y Tres Department",
        countryCode: "UY",
        latitude: "-33.06850860",
        longitude: "-54.28586270",
        nameNative: null,
        label: "Treinta y Tres Department",
        cityCode: "UY_TT",
      },
    ],
    UZ: [
      {
        name: "Andijan Region",
        countryCode: "UZ",
        latitude: "40.76859410",
        longitude: "72.23637900",
        nameNative: null,
        label: "Andijan Region",
        cityCode: "UZ_AN",
      },
      {
        name: "Bukhara Region",
        countryCode: "UZ",
        latitude: "40.25041620",
        longitude: "63.20321510",
        nameNative: null,
        label: "Bukhara Region",
        cityCode: "UZ_BU",
      },
      {
        name: "Fergana Region",
        countryCode: "UZ",
        latitude: "40.45680810",
        longitude: "71.28742090",
        nameNative: null,
        label: "Fergana Region",
        cityCode: "UZ_FA",
      },
      {
        name: "Jizzakh Region",
        countryCode: "UZ",
        latitude: "40.47064150",
        longitude: "67.57085360",
        nameNative: null,
        label: "Jizzakh Region",
        cityCode: "UZ_JI",
      },
      {
        name: "Karakalpakstan",
        countryCode: "UZ",
        latitude: "43.80413340",
        longitude: "59.44579880",
        nameNative: null,
        label: "Karakalpakstan",
        cityCode: "UZ_QR",
      },
      {
        name: "Namangan Region",
        countryCode: "UZ",
        latitude: "41.05100370",
        longitude: "71.09731700",
        nameNative: null,
        label: "Namangan Region",
        cityCode: "UZ_NG",
      },
      {
        name: "Navoiy Region",
        countryCode: "UZ",
        latitude: "42.69885750",
        longitude: "64.63376850",
        nameNative: null,
        label: "Navoiy Region",
        cityCode: "UZ_NW",
      },
      {
        name: "Qashqadaryo Region",
        countryCode: "UZ",
        latitude: "38.89862310",
        longitude: "66.04635340",
        nameNative: null,
        label: "Qashqadaryo Region",
        cityCode: "UZ_QA",
      },
      {
        name: "Samarqand Region",
        countryCode: "UZ",
        latitude: "39.62701200",
        longitude: "66.97497310",
        nameNative: null,
        label: "Samarqand Region",
        cityCode: "UZ_SA",
      },
      {
        name: "Sirdaryo Region",
        countryCode: "UZ",
        latitude: "40.38638080",
        longitude: "68.71549750",
        nameNative: null,
        label: "Sirdaryo Region",
        cityCode: "UZ_SI",
      },
      {
        name: "Surxondaryo Region",
        countryCode: "UZ",
        latitude: "37.94090050",
        longitude: "67.57085360",
        nameNative: null,
        label: "Surxondaryo Region",
        cityCode: "UZ_SU",
      },
      {
        name: "Tashkent Region",
        countryCode: "UZ",
        latitude: "41.22132340",
        longitude: "69.85974060",
        nameNative: null,
        label: "Tashkent Region",
        cityCode: "UZ_TO",
      },
      {
        name: "Tashkent",
        countryCode: "UZ",
        latitude: "41.29949580",
        longitude: "69.24007340",
        nameNative: null,
        label: "Tashkent",
        cityCode: "UZ_TK",
      },
      {
        name: "Xorazm Region",
        countryCode: "UZ",
        latitude: "41.35653360",
        longitude: "60.85666860",
        nameNative: null,
        label: "Xorazm Region",
        cityCode: "UZ_XO",
      },
    ],
    VU: [
      {
        name: "Malampa",
        countryCode: "VU",
        latitude: "-16.40114050",
        longitude: "167.60778650",
        nameNative: null,
        label: "Malampa",
        cityCode: "VU_MAP",
      },
      {
        name: "Penama",
        countryCode: "VU",
        latitude: "-15.37957580",
        longitude: "167.90531820",
        nameNative: null,
        label: "Penama",
        cityCode: "VU_PAM",
      },
      {
        name: "Sanma",
        countryCode: "VU",
        latitude: "-15.48400170",
        longitude: "166.91820970",
        nameNative: null,
        label: "Sanma",
        cityCode: "VU_SAM",
      },
      {
        name: "Shefa",
        countryCode: "VU",
        latitude: "32.80576500",
        longitude: "35.16997100",
        nameNative: null,
        label: "Shefa",
        cityCode: "VU_SEE",
      },
      {
        name: "Tafea",
        countryCode: "VU",
        latitude: "-18.72378270",
        longitude: "169.06450560",
        nameNative: null,
        label: "Tafea",
        cityCode: "VU_TAE",
      },
      {
        name: "Torba",
        countryCode: "VU",
        latitude: "37.07653000",
        longitude: "27.45657300",
        nameNative: null,
        label: "Torba",
        cityCode: "VU_TOB",
      },
    ],
    VE: [
      {
        name: "Amazonas",
        countryCode: "VE",
        latitude: "-3.41684270",
        longitude: "-65.85606460",
        nameNative: null,
        label: "Amazonas",
        cityCode: "VE_Z",
      },
      {
        name: "Anzoátegui",
        countryCode: "VE",
        latitude: "8.59130730",
        longitude: "-63.95861110",
        nameNative: null,
        label: "Anzoátegui",
        cityCode: "VE_B",
      },
      {
        name: "Apure",
        countryCode: "VE",
        latitude: "6.92694830",
        longitude: "-68.52471490",
        nameNative: null,
        label: "Apure",
        cityCode: "VE_C",
      },
      {
        name: "Aragua",
        countryCode: "VE",
        latitude: "10.06357580",
        longitude: "-67.28478750",
        nameNative: null,
        label: "Aragua",
        cityCode: "VE_D",
      },
      {
        name: "Barinas",
        countryCode: "VE",
        latitude: "8.62314980",
        longitude: "-70.23710450",
        nameNative: null,
        label: "Barinas",
        cityCode: "VE_E",
      },
      {
        name: "Bolívar",
        countryCode: "VE",
        latitude: "37.61448380",
        longitude: "-93.41047490",
        nameNative: null,
        label: "Bolívar",
        cityCode: "VE_F",
      },
      {
        name: "Carabobo",
        countryCode: "VE",
        latitude: "10.11764330",
        longitude: "-68.04775090",
        nameNative: null,
        label: "Carabobo",
        cityCode: "VE_G",
      },
      {
        name: "Cojedes",
        countryCode: "VE",
        latitude: "9.38166820",
        longitude: "-68.33392750",
        nameNative: null,
        label: "Cojedes",
        cityCode: "VE_H",
      },
      {
        name: "Delta Amacuro",
        countryCode: "VE",
        latitude: "8.84993070",
        longitude: "-61.14031960",
        nameNative: null,
        label: "Delta Amacuro",
        cityCode: "VE_Y",
      },
      {
        name: "Distrito Capital",
        countryCode: "VE",
        latitude: "41.26148460",
        longitude: "-95.93108070",
        nameNative: null,
        label: "Distrito Capital",
        cityCode: "VE_A",
      },
      {
        name: "Falcón",
        countryCode: "VE",
        latitude: "11.18106740",
        longitude: "-69.85974060",
        nameNative: null,
        label: "Falcón",
        cityCode: "VE_I",
      },
      {
        name: "Federal Dependencies of Venezuela",
        countryCode: "VE",
        latitude: "10.93770530",
        longitude: "-65.35695730",
        nameNative: null,
        label: "Federal Dependencies of Venezuela",
        cityCode: "VE_W",
      },
      {
        name: "Guárico",
        countryCode: "VE",
        latitude: "8.74893090",
        longitude: "-66.23671720",
        nameNative: null,
        label: "Guárico",
        cityCode: "VE_J",
      },
      {
        name: "La Guaira",
        countryCode: "VE",
        latitude: "29.30522680",
        longitude: "-94.79138540",
        nameNative: null,
        label: "La Guaira",
        cityCode: "VE_X",
      },
      {
        name: "Lara",
        countryCode: "VE",
        latitude: "33.98221650",
        longitude: "-118.13227470",
        nameNative: null,
        label: "Lara",
        cityCode: "VE_K",
      },
      {
        name: "Miranda",
        countryCode: "VE",
        latitude: "42.35193830",
        longitude: "-71.52907660",
        nameNative: null,
        label: "Miranda",
        cityCode: "VE_M",
      },
      {
        name: "Monagas",
        countryCode: "VE",
        latitude: "9.32416520",
        longitude: "-63.01475780",
        nameNative: null,
        label: "Monagas",
        cityCode: "VE_N",
      },
      {
        name: "Mérida",
        countryCode: "VE",
        latitude: "20.96737020",
        longitude: "-89.59258570",
        nameNative: null,
        label: "Mérida",
        cityCode: "VE_L",
      },
      {
        name: "Nueva Esparta",
        countryCode: "VE",
        latitude: "10.99707230",
        longitude: "-63.91132960",
        nameNative: null,
        label: "Nueva Esparta",
        cityCode: "VE_O",
      },
      {
        name: "Portuguesa",
        countryCode: "VE",
        latitude: "9.09439990",
        longitude: "-69.09702300",
        nameNative: null,
        label: "Portuguesa",
        cityCode: "VE_P",
      },
      {
        name: "Sucre",
        countryCode: "VE",
        latitude: "-19.03534500",
        longitude: "-65.25921280",
        nameNative: null,
        label: "Sucre",
        cityCode: "VE_R",
      },
      {
        name: "Trujillo",
        countryCode: "VE",
        latitude: "36.67343430",
        longitude: "-121.62875880",
        nameNative: null,
        label: "Trujillo",
        cityCode: "VE_T",
      },
      {
        name: "Táchira",
        countryCode: "VE",
        latitude: "7.91370010",
        longitude: "-72.14161320",
        nameNative: null,
        label: "Táchira",
        cityCode: "VE_S",
      },
      {
        name: "Yaracuy",
        countryCode: "VE",
        latitude: "10.33938900",
        longitude: "-68.81088490",
        nameNative: null,
        label: "Yaracuy",
        cityCode: "VE_U",
      },
      {
        name: "Zulia",
        countryCode: "VE",
        latitude: "10.29102370",
        longitude: "-72.14161320",
        nameNative: null,
        label: "Zulia",
        cityCode: "VE_V",
      },
    ],
    VN: [
      {
        name: "An Giang",
        countryCode: "VN",
        latitude: "10.52158360",
        longitude: "105.12589550",
        nameNative: "An Giang",
        label: "An Giang / An Giang",
        cityCode: "VN_44",
      },
      {
        name: "Bà Rịa-Vũng Tàu",
        countryCode: "VN",
        latitude: "10.54173970",
        longitude: "107.24299760",
        nameNative: "Bà Rịa-Vũng Tàu",
        label: "Bà Rịa-Vũng Tàu / Bà Rịa-Vũng Tàu",
        cityCode: "VN_43",
      },
      {
        name: "Bình Dương",
        countryCode: "VN",
        latitude: "11.32540240",
        longitude: "106.47701700",
        nameNative: "Bình Dương",
        label: "Bình Dương / Bình Dương",
        cityCode: "VN_57",
      },
      {
        name: "Bình Phước",
        countryCode: "VN",
        latitude: "11.75118940",
        longitude: "106.72346390",
        nameNative: "Bình Phước",
        label: "Bình Phước / Bình Phước",
        cityCode: "VN_58",
      },
      {
        name: "Bình Thuận",
        countryCode: "VN",
        latitude: "11.09037030",
        longitude: "108.07207810",
        nameNative: "Bình Thuận",
        label: "Bình Thuận / Bình Thuận",
        cityCode: "VN_40",
      },
      {
        name: "Bình Định",
        countryCode: "VN",
        latitude: "14.16653240",
        longitude: "108.90268300",
        nameNative: "Bình Định",
        label: "Bình Định / Bình Định",
        cityCode: "VN_31",
      },
      {
        name: "Bạc Liêu",
        countryCode: "VN",
        latitude: "9.29400270",
        longitude: "105.72156630",
        nameNative: "Bạc Liêu",
        label: "Bạc Liêu / Bạc Liêu",
        cityCode: "VN_55",
      },
      {
        name: "Bắc Giang",
        countryCode: "VN",
        latitude: "21.28199210",
        longitude: "106.19747690",
        nameNative: "Bắc Giang",
        label: "Bắc Giang / Bắc Giang",
        cityCode: "VN_54",
      },
      {
        name: "Bắc Kạn",
        countryCode: "VN",
        latitude: "22.30329230",
        longitude: "105.87600400",
        nameNative: "Bắc Kạn",
        label: "Bắc Kạn / Bắc Kạn",
        cityCode: "VN_53",
      },
      {
        name: "Bắc Ninh",
        countryCode: "VN",
        latitude: "21.12144400",
        longitude: "106.11105010",
        nameNative: "Bắc Ninh",
        label: "Bắc Ninh / Bắc Ninh",
        cityCode: "VN_56",
      },
      {
        name: "Bến Tre",
        countryCode: "VN",
        latitude: "10.24335560",
        longitude: "106.37555100",
        nameNative: "Bến Tre",
        label: "Bến Tre / Bến Tre",
        cityCode: "VN_50",
      },
      {
        name: "Cao Bằng",
        countryCode: "VN",
        latitude: "22.63568900",
        longitude: "106.25221430",
        nameNative: "Cao Bằng",
        label: "Cao Bằng / Cao Bằng",
        cityCode: "VN_04",
      },
      {
        name: "Cà Mau",
        countryCode: "VN",
        latitude: "9.15267280",
        longitude: "105.19607950",
        nameNative: "Cà Mau",
        label: "Cà Mau / Cà Mau",
        cityCode: "VN_59",
      },
      {
        name: "Cần Thơ",
        countryCode: "VN",
        latitude: "10.03418510",
        longitude: "105.72255070",
        nameNative: "Cần Thơ",
        label: "Cần Thơ / Cần Thơ",
        cityCode: "VN_CT",
      },
      {
        name: "Gia Lai",
        countryCode: "VN",
        latitude: "13.80789430",
        longitude: "108.10937500",
        nameNative: "Gia Lai",
        label: "Gia Lai / Gia Lai",
        cityCode: "VN_30",
      },
      {
        name: "Hà Giang",
        countryCode: "VN",
        latitude: "22.80255880",
        longitude: "104.97844940",
        nameNative: "Hà Giang",
        label: "Hà Giang / Hà Giang",
        cityCode: "VN_03",
      },
      {
        name: "Hà Nam",
        countryCode: "VN",
        latitude: "20.58351960",
        longitude: "105.92299000",
        nameNative: "Hà Nam",
        label: "Hà Nam / Hà Nam",
        cityCode: "VN_63",
      },
      {
        name: "Hà Nội",
        countryCode: "VN",
        latitude: "21.02776440",
        longitude: "105.83415980",
        nameNative: "Hà Nội",
        label: "Hà Nội / Hà Nội",
        cityCode: "VN_HN",
      },
      {
        name: "Hà Tĩnh",
        countryCode: "VN",
        latitude: "18.35595370",
        longitude: "105.88774940",
        nameNative: "Hà Tĩnh",
        label: "Hà Tĩnh / Hà Tĩnh",
        cityCode: "VN_23",
      },
      {
        name: "Hòa Bình",
        countryCode: "VN",
        latitude: "20.68612650",
        longitude: "105.31311850",
        nameNative: "Hòa Bình",
        label: "Hòa Bình / Hòa Bình",
        cityCode: "VN_14",
      },
      {
        name: "Hưng Yên",
        countryCode: "VN",
        latitude: "20.85257110",
        longitude: "106.01699710",
        nameNative: "Hưng Yên",
        label: "Hưng Yên / Hưng Yên",
        cityCode: "VN_66",
      },
      {
        name: "Hải Dương",
        countryCode: "VN",
        latitude: "20.93734130",
        longitude: "106.31455420",
        nameNative: "Hải Dương",
        label: "Hải Dương / Hải Dương",
        cityCode: "VN_61",
      },
      {
        name: "Hải Phòng",
        countryCode: "VN",
        latitude: "20.84491150",
        longitude: "106.68808410",
        nameNative: "Hải Phòng",
        label: "Hải Phòng / Hải Phòng",
        cityCode: "VN_HP",
      },
      {
        name: "Hậu Giang",
        countryCode: "VN",
        latitude: "9.75789800",
        longitude: "105.64125270",
        nameNative: "Hậu Giang",
        label: "Hậu Giang / Hậu Giang",
        cityCode: "VN_73",
      },
      {
        name: "Hồ Chí Minh",
        countryCode: "VN",
        latitude: "10.82309890",
        longitude: "106.62966380",
        nameNative: "Hồ Chí Minh",
        label: "Hồ Chí Minh / Hồ Chí Minh",
        cityCode: "VN_SG",
      },
      {
        name: "Khánh Hòa",
        countryCode: "VN",
        latitude: "12.25850980",
        longitude: "109.05260760",
        nameNative: "Khánh Hòa",
        label: "Khánh Hòa / Khánh Hòa",
        cityCode: "VN_34",
      },
      {
        name: "Kiên Giang",
        countryCode: "VN",
        latitude: "9.82495870",
        longitude: "105.12589550",
        nameNative: "Kiên Giang",
        label: "Kiên Giang / Kiên Giang",
        cityCode: "VN_47",
      },
      {
        name: "Kon Tum",
        countryCode: "VN",
        latitude: "14.34974030",
        longitude: "108.00046060",
        nameNative: "Kon Tum",
        label: "Kon Tum / Kon Tum",
        cityCode: "VN_28",
      },
      {
        name: "Lai Châu",
        countryCode: "VN",
        latitude: "22.38622270",
        longitude: "103.47026310",
        nameNative: "Lai Châu",
        label: "Lai Châu / Lai Châu",
        cityCode: "VN_01",
      },
      {
        name: "Long An",
        countryCode: "VN",
        latitude: "10.56071680",
        longitude: "106.64976230",
        nameNative: "Long An",
        label: "Long An / Long An",
        cityCode: "VN_41",
      },
      {
        name: "Lào Cai",
        countryCode: "VN",
        latitude: "22.48094310",
        longitude: "103.97549590",
        nameNative: "Lào Cai",
        label: "Lào Cai / Lào Cai",
        cityCode: "VN_02",
      },
      {
        name: "Lâm Đồng",
        countryCode: "VN",
        latitude: "11.57527910",
        longitude: "108.14286690",
        nameNative: "Lâm Đồng",
        label: "Lâm Đồng / Lâm Đồng",
        cityCode: "VN_35",
      },
      {
        name: "Lạng Sơn",
        countryCode: "VN",
        latitude: "21.85370800",
        longitude: "106.76151900",
        nameNative: "Lạng Sơn",
        label: "Lạng Sơn / Lạng Sơn",
        cityCode: "VN_09",
      },
      {
        name: "Nam Định",
        countryCode: "VN",
        latitude: "20.43882250",
        longitude: "106.16210530",
        nameNative: "Nam Định",
        label: "Nam Định / Nam Định",
        cityCode: "VN_67",
      },
      {
        name: "Nghệ An",
        countryCode: "VN",
        latitude: "19.23424890",
        longitude: "104.92003650",
        nameNative: "Nghệ An",
        label: "Nghệ An / Nghệ An",
        cityCode: "VN_22",
      },
      {
        name: "Ninh Bình",
        countryCode: "VN",
        latitude: "20.25061490",
        longitude: "105.97445360",
        nameNative: "Ninh Bình",
        label: "Ninh Bình / Ninh Bình",
        cityCode: "VN_18",
      },
      {
        name: "Ninh Thuận",
        countryCode: "VN",
        latitude: "11.67387670",
        longitude: "108.86295720",
        nameNative: "Ninh Thuận",
        label: "Ninh Thuận / Ninh Thuận",
        cityCode: "VN_36",
      },
      {
        name: "Phú Thọ",
        countryCode: "VN",
        latitude: "21.26844300",
        longitude: "105.20455730",
        nameNative: "Phú Thọ",
        label: "Phú Thọ / Phú Thọ",
        cityCode: "VN_68",
      },
      {
        name: "Phú Yên",
        countryCode: "VN",
        latitude: "13.08818610",
        longitude: "109.09287640",
        nameNative: "Phú Yên",
        label: "Phú Yên / Phú Yên",
        cityCode: "VN_32",
      },
      {
        name: "Quảng Bình",
        countryCode: "VN",
        latitude: "17.61027150",
        longitude: "106.34874740",
        nameNative: "Quảng Bình",
        label: "Quảng Bình / Quảng Bình",
        cityCode: "VN_24",
      },
      {
        name: "Quảng Nam",
        countryCode: "VN",
        latitude: "15.53935380",
        longitude: "108.01910200",
        nameNative: "Quảng Nam",
        label: "Quảng Nam / Quảng Nam",
        cityCode: "VN_27",
      },
      {
        name: "Quảng Ngãi",
        countryCode: "VN",
        latitude: "15.12138730",
        longitude: "108.80441450",
        nameNative: "Quảng Ngãi",
        label: "Quảng Ngãi / Quảng Ngãi",
        cityCode: "VN_29",
      },
      {
        name: "Quảng Ninh",
        countryCode: "VN",
        latitude: "21.00638200",
        longitude: "107.29251440",
        nameNative: "Quảng Ninh",
        label: "Quảng Ninh / Quảng Ninh",
        cityCode: "VN_13",
      },
      {
        name: "Quảng Trị",
        countryCode: "VN",
        latitude: "16.74030740",
        longitude: "107.18546790",
        nameNative: "Quảng Trị",
        label: "Quảng Trị / Quảng Trị",
        cityCode: "VN_25",
      },
      {
        name: "Sóc Trăng",
        countryCode: "VN",
        latitude: "9.60252100",
        longitude: "105.97390490",
        nameNative: "Sóc Trăng",
        label: "Sóc Trăng / Sóc Trăng",
        cityCode: "VN_52",
      },
      {
        name: "Sơn La",
        countryCode: "VN",
        latitude: "21.10222840",
        longitude: "103.72891670",
        nameNative: "Sơn La",
        label: "Sơn La / Sơn La",
        cityCode: "VN_05",
      },
      {
        name: "Thanh Hóa",
        countryCode: "VN",
        latitude: "19.80669200",
        longitude: "105.78518160",
        nameNative: "Thanh Hóa",
        label: "Thanh Hóa / Thanh Hóa",
        cityCode: "VN_21",
      },
      {
        name: "Thái Bình",
        countryCode: "VN",
        latitude: "20.44634710",
        longitude: "106.33658280",
        nameNative: "Thái Bình",
        label: "Thái Bình / Thái Bình",
        cityCode: "VN_20",
      },
      {
        name: "Thái Nguyên",
        countryCode: "VN",
        latitude: "21.56715590",
        longitude: "105.82520380",
        nameNative: "Thái Nguyên",
        label: "Thái Nguyên / Thái Nguyên",
        cityCode: "VN_69",
      },
      {
        name: "Thừa Thiên-Huế",
        countryCode: "VN",
        latitude: "16.46739700",
        longitude: "107.59053260",
        nameNative: "Thừa Thiên-Huế",
        label: "Thừa Thiên-Huế / Thừa Thiên-Huế",
        cityCode: "VN_26",
      },
      {
        name: "Tiền Giang",
        countryCode: "VN",
        latitude: "10.44933240",
        longitude: "106.34205040",
        nameNative: "Tiền Giang",
        label: "Tiền Giang / Tiền Giang",
        cityCode: "VN_46",
      },
      {
        name: "Trà Vinh",
        countryCode: "VN",
        latitude: "9.81274100",
        longitude: "106.29929120",
        nameNative: "Trà Vinh",
        label: "Trà Vinh / Trà Vinh",
        cityCode: "VN_51",
      },
      {
        name: "Tuyên Quang",
        countryCode: "VN",
        latitude: "21.77672460",
        longitude: "105.22801960",
        nameNative: "Tuyên Quang",
        label: "Tuyên Quang / Tuyên Quang",
        cityCode: "VN_07",
      },
      {
        name: "Tây Ninh",
        countryCode: "VN",
        latitude: "11.33515540",
        longitude: "106.10988540",
        nameNative: "Tây Ninh",
        label: "Tây Ninh / Tây Ninh",
        cityCode: "VN_37",
      },
      {
        name: "Vĩnh Long",
        countryCode: "VN",
        latitude: "10.23957400",
        longitude: "105.95719280",
        nameNative: "Vĩnh Long",
        label: "Vĩnh Long / Vĩnh Long",
        cityCode: "VN_49",
      },
      {
        name: "Vĩnh Phúc",
        countryCode: "VN",
        latitude: "21.36088050",
        longitude: "105.54743730",
        nameNative: "Vĩnh Phúc",
        label: "Vĩnh Phúc / Vĩnh Phúc",
        cityCode: "VN_70",
      },
      {
        name: "Yên Bái",
        countryCode: "VN",
        latitude: "21.71676890",
        longitude: "104.89858780",
        nameNative: "Yên Bái",
        label: "Yên Bái / Yên Bái",
        cityCode: "VN_06",
      },
      {
        name: "Điện Biên",
        countryCode: "VN",
        latitude: "21.80423090",
        longitude: "103.10765250",
        nameNative: "Điện Biên",
        label: "Điện Biên / Điện Biên",
        cityCode: "VN_71",
      },
      {
        name: "Đà Nẵng",
        countryCode: "VN",
        latitude: "16.05440680",
        longitude: "108.20216670",
        nameNative: "Đà Nẵng",
        label: "Đà Nẵng / Đà Nẵng",
        cityCode: "VN_DN",
      },
      {
        name: "Đắk Lắk",
        countryCode: "VN",
        latitude: "12.71001160",
        longitude: "108.23775190",
        nameNative: "Đắk Lắk",
        label: "Đắk Lắk / Đắk Lắk",
        cityCode: "VN_33",
      },
      {
        name: "Đắk Nông",
        countryCode: "VN",
        latitude: "12.26464760",
        longitude: "107.60980600",
        nameNative: "Đắk Nông",
        label: "Đắk Nông / Đắk Nông",
        cityCode: "VN_72",
      },
      {
        name: "Đồng Nai",
        countryCode: "VN",
        latitude: "11.06863050",
        longitude: "107.16759760",
        nameNative: "Đồng Nai",
        label: "Đồng Nai / Đồng Nai",
        cityCode: "VN_39",
      },
      {
        name: "Đồng Tháp",
        countryCode: "VN",
        latitude: "10.49379890",
        longitude: "105.68817880",
        nameNative: "Đồng Tháp",
        label: "Đồng Tháp / Đồng Tháp",
        cityCode: "VN_45",
      },
    ],
    YE: [
      {
        name: "'Adan Governorate",
        countryCode: "YE",
        latitude: "12.82574810",
        longitude: "44.79438040",
        nameNative: null,
        label: "'Adan Governorate",
        cityCode: "YE_AD",
      },
      {
        name: "'Amran Governorate",
        countryCode: "YE",
        latitude: "16.25692140",
        longitude: "43.94367880",
        nameNative: null,
        label: "'Amran Governorate",
        cityCode: "YE_AM",
      },
      {
        name: "Abyan Governorate",
        countryCode: "YE",
        latitude: "13.63434130",
        longitude: "46.05632120",
        nameNative: null,
        label: "Abyan Governorate",
        cityCode: "YE_AB",
      },
      {
        name: "Al Bayda' Governorate",
        countryCode: "YE",
        latitude: "14.35886620",
        longitude: "45.44980650",
        nameNative: null,
        label: "Al Bayda' Governorate",
        cityCode: "YE_BA",
      },
      {
        name: "Al Hudaydah Governorate",
        countryCode: "YE",
        latitude: "15.30530720",
        longitude: "43.01948970",
        nameNative: null,
        label: "Al Hudaydah Governorate",
        cityCode: "YE_HU",
      },
      {
        name: "Al Jawf Governorate",
        countryCode: "YE",
        latitude: "16.79018190",
        longitude: "45.29938620",
        nameNative: null,
        label: "Al Jawf Governorate",
        cityCode: "YE_JA",
      },
      {
        name: "Al Mahrah Governorate",
        countryCode: "YE",
        latitude: "16.52384230",
        longitude: "51.68342750",
        nameNative: null,
        label: "Al Mahrah Governorate",
        cityCode: "YE_MR",
      },
      {
        name: "Al Mahwit Governorate",
        countryCode: "YE",
        latitude: "15.39632290",
        longitude: "43.56069460",
        nameNative: null,
        label: "Al Mahwit Governorate",
        cityCode: "YE_MW",
      },
      {
        name: "Dhamar Governorate",
        countryCode: "YE",
        latitude: "14.71953440",
        longitude: "44.24790150",
        nameNative: null,
        label: "Dhamar Governorate",
        cityCode: "YE_DH",
      },
      {
        name: "Hadhramaut Governorate",
        countryCode: "YE",
        latitude: "16.93041350",
        longitude: "49.36531490",
        nameNative: null,
        label: "Hadhramaut Governorate",
        cityCode: "YE_HD",
      },
      {
        name: "Hajjah Governorate",
        countryCode: "YE",
        latitude: "16.11806310",
        longitude: "43.32946600",
        nameNative: null,
        label: "Hajjah Governorate",
        cityCode: "YE_HJ",
      },
      {
        name: "Ibb Governorate",
        countryCode: "YE",
        latitude: "14.14157170",
        longitude: "44.24790150",
        nameNative: null,
        label: "Ibb Governorate",
        cityCode: "YE_IB",
      },
      {
        name: "Lahij Governorate",
        countryCode: "YE",
        latitude: "13.14895880",
        longitude: "44.85054950",
        nameNative: null,
        label: "Lahij Governorate",
        cityCode: "YE_LA",
      },
      {
        name: "Ma'rib Governorate",
        countryCode: "YE",
        latitude: "15.51588800",
        longitude: "45.44980650",
        nameNative: null,
        label: "Ma'rib Governorate",
        cityCode: "YE_MA",
      },
      {
        name: "Raymah Governorate",
        countryCode: "YE",
        latitude: "14.62776820",
        longitude: "43.71424840",
        nameNative: null,
        label: "Raymah Governorate",
        cityCode: "YE_RA",
      },
      {
        name: "Saada Governorate",
        countryCode: "YE",
        latitude: "16.84765280",
        longitude: "43.94367880",
        nameNative: null,
        label: "Saada Governorate",
        cityCode: "YE_SD",
      },
      {
        name: "Sana'a Governorate",
        countryCode: "YE",
        latitude: "15.31689130",
        longitude: "44.47480180",
        nameNative: null,
        label: "Sana'a Governorate",
        cityCode: "YE_SN",
      },
      {
        name: "Sana'a",
        countryCode: "YE",
        latitude: "15.36944510",
        longitude: "44.19100660",
        nameNative: null,
        label: "Sana'a",
        cityCode: "YE_SA",
      },
      {
        name: "Shabwah Governorate",
        countryCode: "YE",
        latitude: "14.75463030",
        longitude: "46.51626200",
        nameNative: null,
        label: "Shabwah Governorate",
        cityCode: "YE_SH",
      },
      {
        name: "Socotra Governorate",
        countryCode: "YE",
        latitude: "12.46342050",
        longitude: "53.82373850",
        nameNative: null,
        label: "Socotra Governorate",
        cityCode: "YE_SU",
      },
      {
        name: "Ta'izz Governorate",
        countryCode: "YE",
        latitude: "13.57758860",
        longitude: "44.01779890",
        nameNative: null,
        label: "Ta'izz Governorate",
        cityCode: "YE_TA",
      },
    ],
    ZM: [
      {
        name: "Central Province",
        countryCode: "ZM",
        latitude: "7.25649960",
        longitude: "80.72144170",
        nameNative: null,
        label: "Central Province",
        cityCode: "ZM_02",
      },
      {
        name: "Copperbelt Province",
        countryCode: "ZM",
        latitude: "-13.05700730",
        longitude: "27.54958460",
        nameNative: null,
        label: "Copperbelt Province",
        cityCode: "ZM_08",
      },
      {
        name: "Eastern Province",
        countryCode: "ZM",
        latitude: "23.16696880",
        longitude: "49.36531490",
        nameNative: null,
        label: "Eastern Province",
        cityCode: "ZM_03",
      },
      {
        name: "Luapula Province",
        countryCode: "ZM",
        latitude: "-11.56483100",
        longitude: "29.04599270",
        nameNative: null,
        label: "Luapula Province",
        cityCode: "ZM_04",
      },
      {
        name: "Lusaka Province",
        countryCode: "ZM",
        latitude: "-15.36571290",
        longitude: "29.23207840",
        nameNative: null,
        label: "Lusaka Province",
        cityCode: "ZM_09",
      },
      {
        name: "Muchinga Province",
        countryCode: "ZM",
        latitude: "-15.38219300",
        longitude: "28.26158000",
        nameNative: null,
        label: "Muchinga Province",
        cityCode: "ZM_10",
      },
      {
        name: "Northern Province",
        countryCode: "ZM",
        latitude: "8.88550270",
        longitude: "80.27673270",
        nameNative: null,
        label: "Northern Province",
        cityCode: "ZM_05",
      },
      {
        name: "Northwestern Province",
        countryCode: "ZM",
        latitude: "-13.00502580",
        longitude: "24.90422080",
        nameNative: null,
        label: "Northwestern Province",
        cityCode: "ZM_06",
      },
      {
        name: "Southern Province",
        countryCode: "ZM",
        latitude: "6.23737500",
        longitude: "80.54384500",
        nameNative: null,
        label: "Southern Province",
        cityCode: "ZM_07",
      },
      {
        name: "Western Province",
        countryCode: "ZM",
        latitude: "6.90160860",
        longitude: "80.00877460",
        nameNative: null,
        label: "Western Province",
        cityCode: "ZM_01",
      },
    ],
    ZW: [
      {
        name: "Bulawayo Province",
        countryCode: "ZW",
        latitude: "-20.14895050",
        longitude: "28.53310380",
        nameNative: null,
        label: "Bulawayo Province",
        cityCode: "ZW_BU",
      },
      {
        name: "Harare Province",
        countryCode: "ZW",
        latitude: "-17.82162880",
        longitude: "31.04922590",
        nameNative: null,
        label: "Harare Province",
        cityCode: "ZW_HA",
      },
      {
        name: "Manicaland",
        countryCode: "ZW",
        latitude: "-18.92163860",
        longitude: "32.17460500",
        nameNative: null,
        label: "Manicaland",
        cityCode: "ZW_MA",
      },
      {
        name: "Mashonaland Central Province",
        countryCode: "ZW",
        latitude: "-16.76442950",
        longitude: "31.07937050",
        nameNative: null,
        label: "Mashonaland Central Province",
        cityCode: "ZW_MC",
      },
      {
        name: "Mashonaland East Province",
        countryCode: "ZW",
        latitude: "-18.58716420",
        longitude: "31.26263660",
        nameNative: null,
        label: "Mashonaland East Province",
        cityCode: "ZW_ME",
      },
      {
        name: "Mashonaland West Province",
        countryCode: "ZW",
        latitude: "-17.48510290",
        longitude: "29.78892480",
        nameNative: null,
        label: "Mashonaland West Province",
        cityCode: "ZW_MW",
      },
      {
        name: "Masvingo Province",
        countryCode: "ZW",
        latitude: "-20.62415090",
        longitude: "31.26263660",
        nameNative: null,
        label: "Masvingo Province",
        cityCode: "ZW_MV",
      },
      {
        name: "Matabeleland North Province",
        countryCode: "ZW",
        latitude: "-18.53315660",
        longitude: "27.54958460",
        nameNative: null,
        label: "Matabeleland North Province",
        cityCode: "ZW_MN",
      },
      {
        name: "Matabeleland South Province",
        countryCode: "ZW",
        latitude: "-21.05233700",
        longitude: "29.04599270",
        nameNative: null,
        label: "Matabeleland South Province",
        cityCode: "ZW_MS",
      },
      {
        name: "Midlands Province",
        countryCode: "ZW",
        latitude: "-19.05520090",
        longitude: "29.60354950",
        nameNative: null,
        label: "Midlands Province",
        cityCode: "ZW_MI",
      },
    ],
    XK: [
      {
        name: "Gjilan District",
        countryCode: "XK",
        latitude: "42.46352060",
        longitude: "21.46940110",
        nameNative: null,
        label: "Gjilan District",
        cityCode: "XK_XGJ",
      },
      {
        name: "Kosovska Mitrovica District",
        countryCode: "XK",
        latitude: "42.89139090",
        longitude: "20.86599950",
        nameNative: null,
        label: "Kosovska Mitrovica District",
        cityCode: "XK_XKM",
      },
      {
        name: "Peć District",
        countryCode: "XK",
        latitude: "42.65921550",
        longitude: "20.28876240",
        nameNative: null,
        label: "Peć District",
        cityCode: "XK_XPE",
      },
      {
        name: "Pristina (Priştine)",
        countryCode: "XK",
        latitude: "42.66291380",
        longitude: "21.16550280",
        nameNative: null,
        label: "Pristina (Priştine)",
        cityCode: "XK_XPI",
      },
      {
        name: "Prizren District",
        countryCode: "XK",
        latitude: "42.21525220",
        longitude: "20.74147720",
        nameNative: null,
        label: "Prizren District",
        cityCode: "XK_XPR",
      },
      {
        name: "Uroševac District (Ferizaj)",
        countryCode: "XK",
        latitude: "42.37018440",
        longitude: "21.14832810",
        nameNative: null,
        label: "Uroševac District (Ferizaj)",
        cityCode: "XK_XUF",
      },
      {
        name: "Đakovica District (Gjakove)",
        countryCode: "XK",
        latitude: "42.43757560",
        longitude: "20.37854380",
        nameNative: null,
        label: "Đakovica District (Gjakove)",
        cityCode: "XK_XDG",
      },
    ],
  },
};
