export const errorMessageEmailNotFound = "emailNotFound";
export const ERROR_MESSAGE_SHOULD_LOGIN_AGAIN =
  "ERROR_MESSAGE_SHOULD_LOGIN_AGAIN";
export const ERROR_MESSAGE_FREEUSERS_TRANSLATION_LIMIT =
  "ERROR_MESSAGE_FREEUSERS_TRANSLATION_LIMIT";
export const ERROR_MESSAGE_ALL_TRANSLATION_LIMIT =
  "ERROR_MESSAGE_ALL_TRANSLATION_LIMIT";
export const ERROR_MESSAGE_INSUFFICIENT_RICE =
  "ERROR_MESSAGE_INSUFFICIENT_RICE";
export const ERROR_MESSAGE_USER_ALREADY_EXISTS =
  "ERROR_MESSAGE_USER_ALREADY_EXISTS";
