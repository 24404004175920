import { postData } from "./apiGeneral";

export const sendErrorInfoToServer = (
  error,
  componentStack = null,
  signal = null
) => {
  if (!error) {
    return;
  }
  const errorMessage = error.message;
  const errorStack = error.stack;

  const {
    appCodeName,
    appName,
    appVersion,
    bluetooth,
    clipboard,
    connection,
    cookieEnabled,
    credentials,
    deviceMemory,
    doNotTrack,
    geolocation,
    gpu,
    hardwareConcurrency,
    hid,
    ink,
    keyboard,
    language,
    languages,
    locks,
    managed,
    maxTouchPoints,
    mediaCapabilities,
    mediaDevices,
    mediaSession,
    mimeTypes,
    onLine,
    pdfViewerEnabled,
    permissions,
    platform,
    plugins,
    presentation,
    product,
    productSub,
    scheduling,
    serial,
    serviceWorker,
    storage,
    usb,
    userActivation,
    userAgent,
    userAgentData,
    vendor,
    vendorSub,
    virtualKeyboard,
    wakeLock,
    webdriver,
    webkitPersistentStorage,
    webkitTemporaryStorage,
    windowControlsOverlay,
    xr,
  } = navigator;
  const errDataObj = {
    // error,
    errorMessage,
    errorStack,
    componentStack,
    navigator: {
      appCodeName,
      appName,
      appVersion,
      bluetooth,
      clipboard,
      connection,
      cookieEnabled,
      credentials,
      deviceMemory,
      doNotTrack,
      geolocation,
      gpu,
      hardwareConcurrency,
      hid,
      ink,
      keyboard,
      language,
      languages,
      locks,
      managed,
      maxTouchPoints,
      mediaCapabilities,
      mediaDevices,
      mediaSession,
      mimeTypes,
      onLine,
      pdfViewerEnabled,
      permissions,
      platform,
      plugins,
      presentation,
      product,
      productSub,
      scheduling,
      serial,
      serviceWorker,
      storage,
      usb,
      userActivation,
      userAgent,
      userAgentData,
      vendor,
      vendorSub,
      virtualKeyboard,
      wakeLock,
      webdriver,
      webkitPersistentStorage,
      webkitTemporaryStorage,
      windowControlsOverlay,
      xr,
    },
  };

  return postData(
    null,
    { errDataObj },
    "errorClient/logErrorFromClient",
    signal
  );
};
